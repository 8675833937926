import React, { useState, useEffect, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
import EpcBars from "./EpcBars";
import EpcValue from "./EpcValue";
import { EPCTableValues } from "../../../common/Constants";
import "../../../../_metronic/_assets/sass/UserList.css";

function EpcTable({ yearsTableState }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [curPos, setcurPos] = useState(0);
  const [prevPos, setprevPos] = useState(0);
  const [prev2Pos, setprev2Pos] = useState(0);

  const [curColor, setcurColor] = useState("");
  const [prevColor, setprevColor] = useState("");
  const [prev2Color, setprev2Color] = useState("");

  useEffect(() => {
    EPCTableValues.map(value => {
      if (yearsTableState.current.value > value.min) {
        setcurColor(value.color);
        setcurPos(value.position);
      }
      if (yearsTableState.prev1.value > value.min) {
        setprevColor(value.color);
        setprevPos(value.position);
      }
      if (yearsTableState.prev2.value > value.min) {
        setprev2Color(value.color);
        setprev2Pos(value.position);
      }
      return 0;
    });
  }, [yearsTableState]);

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="table-div" style={{ minWidth: 0 }}>
          {/* begin::Header */}
          <div className="table-title">
            <h3>{gettext("Energy rating")}</h3>
          </div>
          {/* end::Header */}
          <div className="card-body epc-table">
            <table>
              <thead>
                <tr key="header">
                  <th></th>
                  <th>{yearsTableState.current.name}</th>
                  <th>{yearsTableState.prev1.name}</th>
                  <th>{yearsTableState.prev2.name}</th>
                </tr>
              </thead>
              <tbody>
                <tr key="row1">
                  <td>
                    <EpcBars EPCTableValues={EPCTableValues} />
                  </td>
                  <td>
                    <EpcValue
                      value={yearsTableState.current.value}
                      position={curPos}
                      name={"current"}
                      color={curColor}
                    />
                  </td>
                  <td>
                    <EpcValue
                      value={yearsTableState.prev1.value}
                      position={prevPos}
                      name={"prev1"}
                      color={prevColor}
                    />
                  </td>
                  <td>
                    <EpcValue
                      value={yearsTableState.prev2.value}
                      position={prev2Pos}
                      name={"prev2"}
                      color={prev2Color}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(EpcTable);
