import React, { useState, useCallback, useEffect, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import download from "downloadjs";
import { Button, Spinner } from "react-bootstrap";
import { parse } from "papaparse";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Typography,
  LinearProgress,
  Collapse,
  Alert,
  AlertTitle
} from "@mui/material";
import { useAddMeterData } from "hooks";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress {...props} />
      </Box>
      {props.value && (
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
}

export function UploadCSVMeterDataForm({
  type,
  customer,
  meterNo,
  building_id
}) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [highlighted, setHighlighted] = useState(false);

  const [inputduration, setinputduration] = useState("");

  const [inputunit, setinputunit] = useState("MWh");

  const [inputisData, setinputisData] = useState("Estimated");

  const [loading, setloading] = useState(false);

  const [myFiles, setMyFiles] = useState([]);

  const [progress, setProgress] = useState(null);

  const [showSuccessMessage, setshowSuccessMessage] = useState(false);

  const { addMeterData } = useAddMeterData();

  const onDrop = useCallback(
    acceptedFiles => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "text/csv"
  });

  const removeAll = () => {
    setMyFiles([]);
  };

  const files = myFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes{" "}
    </li>
  ));

  const uploadCsvData = async (meterNo, files, duration, unit, datatype) => {
    let ref = { total: 0, completed: 0 };

    for await (const file of files) {
      const text = await file.text();

      const result = parse(text, { header: true });

      const resultData = result?.data ? result?.data : [];
      resultData.forEach(async data => {
        ref.total++;
        setloading(true);

        await addMeterData({
          buildingId: building_id,
          data: {
            meterNo,
            customer,
            cvr: customer,
            type,
            dataDuration: duration,
            unit,
            dataType: datatype,
            value: data?.Value,
            price: data?.Price,
            datetime: data?.DateTime
          }
        });

        ref.completed++;
        setProgress((ref.completed / ref.total) * 100);
      });
    }
  };

  useEffect(() => {
    if (progress === 100) {
      setloading(false);
      setshowSuccessMessage(true);
      setTimeout(() => {
        setshowSuccessMessage(false);
        setProgress(null);
        removeAll();
      }, 5000);
    }
  }, [progress]);

  const submitHandler = e => {
    e.preventDefault();

    uploadCsvData(meterNo, myFiles, inputduration, inputunit, inputisData);
  };

  return (
    <div className="card card-custom example example-compact">
      <form onSubmit={submitHandler}>
        <div className="table-div">
          {/* begin::Header */}

          <div className="table-title">
            <h3 className="col-xs-6">
              {gettext("Add Meter Data")}
              {` ( ${meterNo} ) `}
            </h3>

            <div className="col-xs-6 d-flex align-items-center">
              <button
                className="btn btn-primary mx-2"
                type="button"
                onClick={() => download("/data/SampleCSV/SampleCSV.csv")}
              >
                {gettext("Download Sample CSV")}
              </button>

              <button
                className="btn btn-primary mx-2"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  <>{gettext("Upload")}</>
                )}
              </button>
            </div>
          </div>

          {/* end::Header */}
          {
            <Collapse in={showSuccessMessage}>
              <Alert severity="success">
                <AlertTitle>{gettext("Success")}</AlertTitle>

                {gettext("Data Has Been Added Successfully")}
              </Alert>
            </Collapse>
          }

          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>{gettext("Data Duration")}</label>

                <select
                  className="form-control"
                  id="Duration"
                  name="Duration"
                  required
                  value={inputduration}
                  onChange={e => setinputduration(e.target.value)}
                >
                  <option value="">{gettext("Select...")}</option>

                  <option value="hourly">{gettext("Hourly")}</option>

                  <option value="daily">{gettext("Daily")}</option>

                  <option value="monthly">{gettext("Monthly")}</option>

                  <option value="yearly">{gettext("Yearly")}</option>
                </select>
              </div>

              <div className="form-group col-md-4">
                <label>{gettext("Unit")}</label>

                <select
                  className="form-control"
                  id="Unit"
                  name="Unit"
                  required
                  value={inputunit}
                  onChange={e => setinputunit(e.target.value)}
                >
                  <option value="kWh">kWh</option>

                  <option value="MWh">MWh</option>

                  <option value="m3">m3</option>
                </select>
              </div>

              <div className="form-group col-md-4">
                <label>{gettext("Is Data")}</label>

                <select
                  className="form-control"
                  id="Type"
                  name="Type"
                  required
                  value={inputisData}
                  onChange={e => setinputisData(e.target.value)}
                >
                  <option value="Estimated">{gettext("Estimated")}</option>

                  <option value="Measured">{gettext("Measured")}</option>
                </select>
              </div>
            </div>

            <div
              {...getRootProps({
                className: `p-16 my-10 border text-center ${
                  highlighted ? "border-primary" : "border-secondary "
                }`
              })}
              onDragEnter={() => {
                setHighlighted(true);
              }}
              onDragLeave={() => {
                setHighlighted(false);
              }}
            >
              <input {...getInputProps()} />

              <p>{gettext("Drag 'n' drop CSV files here")}</p>

              <p>{gettext("OR")}</p>

              <Button variant="outline-primary">{gettext("Browse")}</Button>
            </div>
            <Collapse in={myFiles}>
              {myFiles?.length > 0 && (
                <aside>
                  <h4>Files</h4>

                  <ul>{files}</ul>
                </aside>
              )}
            </Collapse>
            <Collapse in={loading}>
              {
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    variant={progress ? "determinate" : "indeterminate"}
                    value={progress}
                  />
                </Box>
              }
            </Collapse>
          </div>
        </div>
      </form>
    </div>
  );
}
