import { FilterSelectButton } from "app/components";
import { useEpcFilter, useTextdomainContext } from "app/providers";
import React from "react";

export function SelectBuilding() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const {
    buildings,
    filtersLoading,
    selectedBuilding,
    setSelectedBuilding
  } = useEpcFilter();
  return (
    <FilterSelectButton
      searchable={true}
      label={gettext("Building")}
      options={buildings}
      loading={filtersLoading}
      getOptionLabel={option => option.address}
      value={selectedBuilding}
      onChange={newValue => setSelectedBuilding(newValue)}
    />
  );
}
