import { gql } from "@apollo/client";

export const GET_DATAHUB_BUILDING_METERS = gql`
  query datahubBuildingMeters($meterIdList: [String]) {
    datahubBuildingMeters(meterIdList: $meterIdList) {
      meteringPointId
      meterNumber
      typeOfMP
      meterReadingOccurrence
      authorization
      lastData
      lastSeen
    }
  }
`;
