import { Button, Grid, Stack, Typography } from "@mui/material";
import { useDebounce } from "hooks";
import { useFindCompanies, useSubmit } from "./hooks";
import React, { useCallback, useContext, useState } from "react";
import { CompanyInput, CountryDropdown } from "./components";
import { useStep, useTextdomainContext, useUser } from "app/providers";

export const RegistrationFlowAddCompanyForm = props => {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { refreshStep } = useStep();

  const { user } = useUser();

  const {
    name,
    countryCode,
    customerType,
    teams: [team]
  } = user || {};

  const [companyName, setCompanyName] = useState(
    customerType === "private" ? name : team?.name || ""
  );

  const [companyCountryValue, setCompanyCountryValue] = useState(countryCode);

  const debouncedValue = useDebounce(companyName, 500);

  const { matchedCompanies, companyCountry, loading } = useFindCompanies(
    debouncedValue,
    ({ comGetCustomerDetails }) => {
      comGetCustomerDetails &&
        setCompanyCountryValue(comGetCustomerDetails?.country);
    }
  );
  const [compmanyError, setCompanyError] = useState(false);

  const { submit, submiting } = useSubmit(
    () => {
      refreshStep();
    },
    ({ message }) => {
      if (message === "405") {
        setCompanyError(
          gettext(
            "This Company already has a Owner. Unable to add this Company"
          )
        );
      }
    }
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      if (companyName) {
        submit({
          customer: {
            customerName: companyName,
            customerCountry: companyCountryValue
          }
        });
      } else {
        setCompanyError(gettext("Please select company name"));
      }
    },
    [submit, companyName, companyCountryValue, gettext, setCompanyError]
  );

  // const openDataHub = useCallback(() => {
  //   window.open(
  //     `${REACT_APP_URL}r/gg/${customerKey}`,
  //     "",
  //     "noopener,noreferre"
  //   );
  // }, [customerKey]);
  return (
    <form noValidate onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <Typography fontSize={32} fontWeight={700}>
          {gettext("Add Company")}
        </Typography>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CountryDropdown
                value={companyCountryValue}
                onChange={value => setCompanyCountryValue(value)}
                disabled={Boolean(
                  companyCountry ? companyCountry : companyCountryValue
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <CompanyInput
                matchedCompanies={matchedCompanies}
                loading={loading}
                companyName={companyName}
                setCompanyName={setCompanyName}
                compmanyError={compmanyError}
                setCompanyError={setCompanyError}
              />
            </Grid>
          </Grid>
        </Grid>

        <Button
          disableElevation
          sx={{ py: 1.5 }}
          disabled={!companyName || loading || submiting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="secondary"
        >
          {gettext("Next")}
        </Button>
      </Stack>
    </form>
  );
};
