import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";
import CustomTable from "./CustomTable";
import { useLang, useTextdomainContext } from "app/providers";

const SectionTable = props => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { getDropDownValues, topSectionHeading } = props;
  const [month, setMonth] = useState(null);
  const [quarter, setQuarter] = useState(null);
  const [year, setYear] = useState(null);
  const locale = useLang();

  let monthList = [
    { value: "All", label: "All" },
    { value: "January", label: "January" },
    { value: "February", label: "February" },
    { value: "March", label: "March" },
    { value: "April", label: "April" },
    { value: "May", label: "May" },
    { value: "June", label: "June" },
    { value: "July", label: "July" },
    { value: "August", label: "August" },
    { value: "September", label: "September" },
    { value: "October", label: "October" },
    { value: "November", label: "November" },
    { value: "December", label: "December" }
  ];

  let quarterList = [
    { value: "All", label: "All" },
    { value: "First Quarter", label: "First Quarter" },
    { value: "Second Quarter", label: "Second Quarter" },
    { value: "Third Quarter", label: "Third Quarter" },
    { value: "Fourth Quarter", label: "Fourth Quarter" }
  ];

  let yearList = [
    { value: "All", label: "All" },
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" }
  ];

  useEffect(() => {
    getDropDownValues(month, quarter, year);
  }, [getDropDownValues, month, quarter, year]);

  const handleDropdown = (val, type) => {
    if (type === "month") {
      setMonth(val);
      setQuarter(monthList[0]);
      getDropDownValues(val, monthList[0], monthList[0]);
    } else if (type === "quarter") {
      setMonth(val);
      setQuarter(monthList[0]);
      getDropDownValues(monthList[0], val, monthList[0]);
    } else {
      setYear(val);
      getDropDownValues(monthList[0], monthList[0], val);
    }
  };

  //top section with dropdown(s)
  const renderTopSection = () => {
    return (
      <div className="top-section-dropdown">
        <div>{topSectionHeading ? topSectionHeading : "Bygning"}</div>

        {/* Month dropdown */}
        <div style={{ flex: 1, marginLeft: 100, height: 50, paddingTop: 6 }}>
          <Select
            value={month}
            placeholder="Month"
            onChange={val => handleDropdown(val, "month")}
            options={monthList}
          />
        </div>

        {/* Quarter dropdown */}
        <div style={{ flex: 1, marginLeft: 100, height: 50, paddingTop: 6 }}>
          <Select
            value={quarter}
            placeholder="Quarter"
            onChange={val => handleDropdown(val, "quarter")}
            options={quarterList}
          />
        </div>

        {/* Year dropdown */}
        <div
          style={{
            flex: 1,
            marginRight: 30,
            marginLeft: 100,
            height: 50,
            paddingTop: 6
          }}
        >
          <Select
            value={year}
            placeholder="Year"
            onChange={val => handleDropdown(val, "year")}
            options={props.yearList ? props.yearList : yearList}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="app-table">
      {renderTopSection()}
      <CustomTable
        locale={locale}
        energySource="all"
        tableData={props.tableData}
        gettext={gettext}
      />
    </div>
  );
};

export default SectionTable;
