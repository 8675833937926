import { gql } from "@apollo/client";

export const ADD_NEW_ROLE = gql`
  mutation comAddRole($key: String!, $role: String!) {
    comAddRole(key: $key, role: $role) {
      success
      message
    }
  }
`;
