import React, { useContext, useState, useCallback, useEffect } from "react";
import { useTextdomainContext } from "app/providers";
import {
  Grid,
  TextField,
  Stack,
  Box,
  Button,
  Collapse,
  Alert,
  AlertTitle,
  MenuItem,
  Autocomplete
} from "@mui/material";
import { Modal, Spinner } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Toast from "app/components/toast/Toast";
import { changeHandler } from "app/pages/admin/service-providers/utils";
import { useAddProvider } from "app/pages/admin/service-providers/hooks";
import { useCountries } from "hooks";

const formInitialStage = {
  name: "",
  category: "",
  country: null,
  api: "",
  url: ""
};

function AddServiceProviderModal(props) {
  const { providerModal, setProviderModal, category } = props;
  const [form, setForm] = useState(formInitialStage);
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [toastList, setToastList] = useState([]);
  const [error, setError] = useState(null);
  const { countries } = useCountries();

  useEffect(() => {
    if (category) {
      setForm({ ...formInitialStage, category: category });
    }
  }, [category, setForm]);

  const handleChange = e => {
    changeHandler(e, form, setForm, error, setError);
  };

  const { create, creating } = useAddProvider();

  const submitHandler = useCallback(
    async e => {
      e.preventDefault();
      let formData = { ...form };
      delete formData.country;
      formData["countryCode"] = form?.country?.code || "";
      const result = await create(formData);
      if (result.data.addServiceProvider.success) {
        setForm(formInitialStage);
        const toastArray = [
          {
            title: gettext("Provider Added Successfully!"),
            type: "success"
          }
        ];
        setToastList(toastArray);
        setProviderModal(false);
      } else {
        setError(result.data.addServiceProvider.message[0]);
      }
    },
    [create, form, gettext, setProviderModal]
  );
  return (
    <>
      <Modal
        show={providerModal}
        animation
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          <Modal.Title>{gettext("Add a New Service Provider")}</Modal.Title>
        </ModalHeader>
        <form onSubmit={submitHandler}>
          <div className="card-body">
            <Collapse in={error ? true : false}>
              <Alert severity="error">
                <AlertTitle>{gettext("Error")}</AlertTitle>
                {gettext(error)}
              </Alert>
            </Collapse>
            <Stack spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="text"
                          label="Name"
                          size="small"
                          name="name"
                          value={form.name}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          disabled={category ? true : false}
                          label="Category"
                          name="category"
                          onChange={handleChange}
                          value={form.category}
                          select
                          fullWidth
                          size="small"
                        >
                          <MenuItem value={"energy"}>Energy</MenuItem>
                          <MenuItem value={"electricity"}>Electricity</MenuItem>
                          <MenuItem value={"heating"}>Heatiing</MenuItem>
                          <MenuItem value={"water"}>Water</MenuItem>
                          <MenuItem value={"gas"}>Gas</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          disablePortal
                          options={countries}
                          getOptionLabel={option => option.name || ""}
                          value={form.country}
                          onChange={(e, newValue) => {
                            if (
                              error &&
                              error.toLowerCase().includes("country") &&
                              newValue
                            ) {
                              setError(null);
                            }
                            setForm({ ...form, country: newValue });
                          }}
                          size="small"
                          fullWidth
                          isOptionEqualToValue={(option, value) =>
                            option.value === value
                          }
                          renderInput={params => (
                            <TextField {...params} label={gettext("Country")} />
                          )}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="text"
                          label="Url"
                          size="small"
                          name="url"
                          value={form.url}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="text"
                          label="API"
                          name="api"
                          value={form.api}
                          onChange={handleChange}
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box className="ml-auto mr-11">
                <Button
                  className="mr-2 btn btn-primary"
                  type="submit"
                  disabled={creating}
                >
                  {creating ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    <>{gettext("Save")}</>
                  )}
                </Button>
                <Button
                  className="btn btn-secondary"
                  onClick={() => setProviderModal(false)}
                >
                  {gettext("Cancel")}
                </Button>
              </Box>
            </Stack>
          </div>
        </form>
      </Modal>
      <Toast toastList={toastList} />
    </>
  );
}

export default AddServiceProviderModal;
