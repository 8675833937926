import { useMutation, useQuery } from "@apollo/client";
import { ADD_PARTNER_MEMBER } from "GraphQL/Mutations";
import { GET_NOT_MEMBERS } from "GraphQL/Queries";
import { useState } from "react";

export function useFetchNewMembers(customerKey) {
  const { data, loading } = useQuery(GET_NOT_MEMBERS, {
    variables: { customerKey: customerKey }
  });
  return { newMembers: data ? data.comGetNotMembers : [], loading };
}

export function useSubmit(callback) {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [addMemberToPartner, { loading }] = useMutation(ADD_PARTNER_MEMBER, {
    onCompleted: response => {
      // onCompleted();
      if (response.comAddPartnerMember?.success) {
        setSuccess(true);
        setError(false);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      } else {
        setSuccess(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 5000);
      }
    },
    onError: error => {
      // onError();
      setSuccess(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  });

  return { success, error, loading, addMemberToPartner };
}
