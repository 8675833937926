import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useLang, useTextdomainContext } from "app/providers";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import CustomTable from "../../components/CustomTable";
import { useFetchPlainLinks, useUpdatePlanLinkStatus } from "./hooks";

export function AccountLinkListTable() {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const navigate = useNavigate();
  const locale = useLang();

  const location = useLocation();

  const { planLinks, refetch } = useFetchPlainLinks();

  const { updateStatus } = useUpdatePlanLinkStatus();

  const [tableData, settableData] = useState({
    title: gettext("Account Link"),
    heading: [gettext("Name"), gettext("Created"), gettext("Updated"), "", ""],
    content: []
  });

  const [showActivePopup, setshowActivePopup] = useState("Hide");

  const [selectedPlanIndex, setselectedPlanIndex] = useState([]);

  useEffect(() => {
    refetch();

    return () => {};
  }, [refetch]);

  useEffect(() => {
    settableData(prev => ({
      ...prev,
      content: []
    }));
    planLinks &&
      planLinks.map(plan => {
        settableData(prev => ({
          ...prev,
          content: [
            ...prev.content,
            [
              gettext(plan.name),
              moment(plan.createdAt).format("DD/MM/YYYY"),
              moment(plan.updatedAt).format("DD/MM/YYYY"),
              plan.Id,
              plan.Id,
              plan.status
            ]
          ]
        }));
        return 0;
      });
  }, [planLinks, gettext]);

  const onAddTap = () => {
    navigate({
      pathname: `${location.pathname}/account-link-edit`,
      state: {
        type: "add",
        data: ["", "", "", "View", "Edit", "Activate"],
        index: 0
      } // your data array of objects
    });
  };

  const onActiveTap = (data, index) => {
    setselectedPlanIndex(data);
    setshowActivePopup("Active");
  };

  const onViewTap = (data, index) => {
    navigate(`${location.pathname}/${data[3]}/account-link-view`);
  };

  const onEditTap = (data, index) => {
    navigate(`${location.pathname}/${data[4]}/account-link-edit`);
  };

  const onTickTap = (data, index) => {
    setselectedPlanIndex(index);
    setshowActivePopup("InActive");
  };

  // const hideModal = () => {
  //   this.fileInput.value = null;
  //   setshowModal (false);
  //   setpercentage (0);
  //   setfileName ("");
  // };

  const handleActiveClose = type => {
    if (type === "Y") {
      updateStatus(selectedPlanIndex[3], !selectedPlanIndex[5], refetch);
    }
    setshowActivePopup("Hide");
  };

  const renderActivePopup = () => {
    const heading =
      showActivePopup === "Active"
        ? gettext("Are you sure you want to change the active account plan?")
        : showActivePopup === "InActive"
        ? gettext("Are you sure you want to inactive the account plan?")
        : "";
    return (
      <Modal
        show={showActivePopup === "Hide" ? false : true}
        onHide={() => this.handleActiveClose("N")}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Title
          style={{ marginLeft: 15, marginTop: 15, marginBottom: -10 }}
        >
          {selectedPlanIndex[0]}
        </Modal.Title>
        <Modal.Body>{heading}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleActiveClose("N")}>
            No
          </Button>
          <Button
            style={{
              backgroundColor: "#46763c",
              color: "white",
              border: 0
            }}
            variant="primary"
            onClick={() => handleActiveClose("Y")}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="account-link-container">
      <div className="card card-custom gutter-b example example-compact">
        <div className="row">
          <button className="account-table-btn mr53" onClick={onAddTap}>
            {gettext("Add")} +
          </button>
        </div>

        <div className="table-shift-up">
          <CustomTable
            energySource="all"
            locale={locale}
            type="account-plan"
            onViewTap={(data, index) => onViewTap(data, index)}
            onEditTap={(data, index) => onEditTap(data, index)}
            onActiveTap={(data, index) => onActiveTap(data, index)}
            onTickTap={(data, index) => onTickTap(data, index)}
            tableData={tableData}
            gettext={gettext}
          />
        </div>

        {renderActivePopup()}
      </div>
    </div>
  );
}
