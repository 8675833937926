import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Spinner, Modal, Button } from "react-bootstrap";
import { useAddRole } from "../hooks";
import { Collapse, Alert, AlertTitle } from "@mui/material";
import Toast from "app/components/toast/Toast";

export default function AddNewModel({ show, onHide }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [toastList, setToastList] = useState([]);
  const { create, creating } = useAddRole();
  const [error, setError] = useState(null);
  const [customerKey, setCustomerKey] = useState("");
  const [role, setRole] = useState("");

  const handleChange = e => {
    setError(null);
    let val = e.target.value;
    if (e.target.name === "key") {
      val = val.toLowerCase();
      setCustomerKey(val);
    }
    if (e.target.name === "role") {
      setRole(val);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const form = {
      key: customerKey,
      role: role
    };
    const result = await create(form);
    if (
      result.data.comAddRole.success &&
      result.data.comAddRole.success !== "false"
    ) {
      const toastArray = [
        {
          title: gettext("Role Added Successfully!"),
          type: "success"
        }
      ];
      setToastList(toastArray);
      onHide(true);
      setError(null);
    } else {
      setError(result.data.comAddRole.message);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        animation
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{gettext("Add New Role")}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="card-body">
              <Collapse in={error ? true : false}>
                <Alert severity="error">
                  <AlertTitle>{gettext("Error")}</AlertTitle>
                  {gettext(error)}
                </Alert>
              </Collapse>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>{gettext("Company Key")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="key"
                    name="key"
                    required
                    placeholder="Enter key"
                    value={customerKey}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>{gettext("Role")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="CustomerKey"
                    name="role"
                    required
                    placeholder="Enter Role"
                    value={role}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              {gettext("Cancel")}
            </Button>
            <Button type="submit" variant="primary">
              {creating ? (
                <Spinner animation="border" variant="light" />
              ) : (
                <>{gettext("Add Role")}</>
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Toast toastList={toastList} />
    </>
  );
}
