import { gql } from "@apollo/client";

export const GET_CUSTOMER_USERS = gql`
  query getCustomerUsers($customerName: String) {
    comGetTeamMembers(customerName: $customerName) {
      name
      email
      role
      read
      write
      owner
    }
  }
`;
