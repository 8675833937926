import { gql } from "@apollo/client";

export const DELETE_METER_DATA_ADMIN = gql`
  mutation deleteMeterDataAdmin(
    $fromDate: DateTime!
    $meterNo: String!
    $meterType: String!
    $resolution: String!
    $toDate: DateTime!
  ) {
    deleteMeterDataAdmin(
      fromDate: $fromDate
      meterNo: $meterNo
      meterType: $meterType
      resolution: $resolution
      toDate: $toDate
    ) {
      success
      message
    }
  }
`;
