import { useEffect, useState } from "react";
import {
  Button,
  Menu,
  Chip,
  Stack,
  InputAdornment,
  OutlinedInput,
  Typography
} from "@mui/material";
import { IoChevronDown, IoCheckmark } from "react-icons/io5";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: `#E4E4E7`
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#27272A",
      outline: `none`
    }
  }
}));

const CountrySelection = props => {
  const { countries, selectedCountry, selectCountry, gettext } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");

  const [srchCountries, setCountries] = useState([]);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!open) setTimeout(() => setSearch(""), 1000);
  }, [open]);

  useEffect(() => {
    const filteredCountries = countries.filter(cont => {
      return cont.label.toLowerCase().includes(search.toLowerCase());
    });
    setCountries(filteredCountries);
  }, [countries, search]);

  // useMemo(()=>{
  //     handleChangeCountries(selectedCountry);
  // }, [handleChangeCountries, selectedCountry]);

  return (
    <>
      <Button
        id="basic-button"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<IoChevronDown size={12} />}
        color="secondary"
        onClick={handleClick}
        sx={{
          "&:hover": {
            background: "#E6EEEA",
            border: "1px solid #B3CDC1"
          },
          border: "1px solid transparent",
          borderRadius: "8px",
          padding: "0 8px 0 8px"
        }}
      >
        {gettext("Country")}
        {selectedCountry && (
          <Chip
            sx={{
              ml: 0.5,
              backgroundColor: "primary.50",
              color: "primary.main"
            }}
            size="small"
            label={gettext(selectedCountry.label)}
          />
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { p: 0 }
        }}
        PaperProps={{
          sx: {
            p: 1,
            borderRadius: "8px",
            marginTop: "8px",
            overflow: "hidden"
          }
        }}
      >
        <Stack
          spacing={2}
          sx={{
            "&:focus": {
              outline: "none"
            }
          }}
        >
          <OutlinedInput
            sx={{ color: "secondary.main" }}
            size="small"
            placeholder={gettext("Search")}
            value={search}
            onChange={e => setSearch(e.target.value)}
            startAdornment={
              <InputAdornment
                position={"start"}
                style={{ color: "secondary.300" }}
              >
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Box
            className={classes.root}
            sx={{ maxHeight: "300px", overflowY: "auto" }}
          >
            {srchCountries.length ? (
              srchCountries.map(country => (
                <Box
                  component="li"
                  sx={{
                    "& > img": {
                      mr: 2,
                      flexShrink: 0
                    },
                    marginTop: "0px!important",
                    margin: 0,
                    padding: "14px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#F4F4F5"
                    },
                    backgroundColor:
                      selectedCountry.label === country.label && "#F4F4F5"
                  }}
                  {...props}
                  onClick={() => {
                    selectCountry(country);
                    setAnchorEl(null);
                  }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  <Typography>{country.label}</Typography>
                  {selectedCountry.label === country.label && (
                    <IoCheckmark
                      style={{ marginLeft: "auto" }}
                      color="#015731"
                    />
                  )}
                </Box>
              ))
            ) : (
              <Box
                component="li"
                sx={{
                  "& > img": {
                    mr: 2,
                    margin: 0,
                    flexShrink: 0
                  },
                  paddingLeft: "14px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Typography sx={{ marginLeft: "8px" }}>Not Found</Typography>
              </Box>
            )}
          </Box>
        </Stack>
      </Menu>
    </>
  );
};

export default CountrySelection;
