import React from "react";
import { MixedWidget3 } from "../../../widgets";
import { emptyBarChart } from "../../../common/Constants";

function Co2SectionCol1Row2(props) {
  return (
    <>
      <div className={"row"}>
        <div className={"col-sm-12"}>
          <div className="MuiPaper-root MuiCard-root jss1 MuiPaper-elevation1 MuiPaper-rounded">
            <div className="MuiCardContent-root">
              {props.data1 && (
                <MixedWidget3
                  className="card-stretch gutter-b"
                  data={props.data1 ? props.data1 : emptyBarChart}
                  imageUrl={uri => {
                    props.imageUrl1(uri);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={"row"}>
        <div className={"col-sm-12"}>
          <div className="MuiPaper-root MuiCard-root jss1 MuiPaper-elevation1 MuiPaper-rounded">
            <div className="MuiCardContent-root">
              {props.data2 && (
                <MixedWidget3
                  className="card-stretch gutter-b"
                  data={props.data2 ? props.data2 : emptyBarChart}
                  imageUrl={uri => {
                    props.imageUrl2(uri);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Co2SectionCol1Row2;
