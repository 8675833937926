import { merge } from "lodash";
import Alert from "./Alert";
import AppBar from "./AppBar";
import Button from "./Button";
import Chip from "./Chip";
import InputLabel from "./InputLabel";
import OutlinedInput from "./OutlinedInput";

export default function ComponentsOverrides(theme) {
  return merge(
    Alert(theme),
    AppBar(theme),
    Button(theme),
    Chip(theme),
    InputLabel(theme),
    OutlinedInput(theme)
  );
}
