export default function Button(theme) {
  const disabledStyle = {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.grey[200]
    }
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontWeight: 500,
          textTransform: "none",
          transition: "transform .2s",
          transform: "translateZ(0)",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "none"
          }
        },
        contained: {
          ...disabledStyle
        },
        outlined: {
          ...disabledStyle
        }
      }
    }
  };
}
