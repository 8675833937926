import { Button } from "@mui/material";
import { useCallback, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Modal } from "react-bootstrap";
import { CascadeDropdownForm } from "../CascadeDropdownForm";

export function LinkModal({ show, onHide, editableRow, fileId, onSave }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [dropdownValues, setdropdownValues] = useState({});

  const [textboxValues, settextboxValues] = useState({});

  const [Level1Value, setLevel1Value] = useState();

  const [Level2Value, setLevel2Value] = useState();

  const [Level3Value, setLevel3Value] = useState();

  const [Level4Value, setLevel4Value] = useState();

  const handleSave = useCallback(() => {
    onSave(
      fileId,
      {
        level1: Level1Value,
        level2: Level2Value,
        level3: Level3Value,
        level4: Level4Value,
        account_number: editableRow?.accountNumber,
        account_name: editableRow?.accountName,
        year_before: editableRow?.yearBefore,
        period: editableRow?.period,
        ...dropdownValues,
        ...textboxValues
      },
      editableRow?.accountNumber
    );
  }, [
    fileId,
    Level1Value,
    Level2Value,
    Level3Value,
    Level4Value,
    dropdownValues,
    textboxValues,
    editableRow,
    onSave
  ]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{gettext("Update Account Data")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CascadeDropdownForm
          editableRow={editableRow}
          dropdownValues={dropdownValues}
          setdropdownValues={setdropdownValues}
          textboxValues={textboxValues}
          settextboxValues={settextboxValues}
          setLevel1Value={setLevel1Value}
          setLevel2Value={setLevel2Value}
          setLevel3Value={setLevel3Value}
          setLevel4Value={setLevel4Value}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={onHide}>
          {gettext("Cancel")}
        </Button>
        <Button type="submit" variant="contained" onClick={handleSave}>
          {gettext("Save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
