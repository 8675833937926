import { gql } from "@apollo/client";

export const GET_DATAHUB_METER_DETAILS = gql`
  query datahubMeteringdataDaily($meteringPointId: String, $daily: String) {
    datahubMeteringdataDaily(meteringPointId: $meteringPointId, daily: $daily) {
      co2ConversionRate
      co2
      co2PerM2
      q
      ts
      v
      vPerM2
      price
    }
  }
`;

export const GET_DATAHUB_METER_DETAILS_MONTHLY = gql`
  query datahubMeteringdataTs($meteringPointId: String, $month: String) {
    datahubMeteringdataTs(meteringPointId: $meteringPointId, month: $month) {
      period {
        dataFrom
        end
        month
      }
      dailyData {
        co2ConversionRate
        co2
        co2PerM2
        q
        ts
        v
        vPerM2
        price
      }
    }
  }
`;

export const GET_DATAHUB_METER_DETAILS_YEARLY = gql`
  query datahubMeteringdataTs($meteringPointId: String, $year: String) {
    datahubMeteringdataTs(meteringPointId: $meteringPointId, year: $year) {
      period {
        dataFrom
        end
        month
      }
      monthlyData {
        co2ConversionRate
        co2
        co2PerM2
        q
        ts
        v
        vPerM2
        price
      }
    }
  }
`;
