import { styled, TableCell } from "@mui/material";

export const EpcBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 0,
  padding: 0,
  fontSize: "14px",
  fontWeight: 500,
  color: "white",
  whiteSpace: "nowrap",
  position: "relative",
  zIndex: 1,
  "&:before": {
    content: '""',
    position: "absolute",
    right: "0%",
    top: "50%",
    width: "100%",
    height: "1px",
    borderTop: "1px dashed",
    borderColor: theme.palette.secondary[200],
    zIndex: -1
  }
}));
