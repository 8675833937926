import { Grid } from "@mui/material";
import React from "react";
import { EpcChart, EpcNotice, EpcTable } from "./components";

export function EpcBody() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EpcNotice />
      </Grid>
      <Grid item xs={12}>
        <EpcTable />
      </Grid>
      <Grid item xs={12}>
        <EpcChart />
      </Grid>
    </Grid>
  );
}
