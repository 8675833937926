import React, { useState, useEffect } from "react";
import detectBrowserLanguage from "detect-browser-language";
import { IntlProvider } from "react-intl";
import { useUser } from "../UserProvider";
import { useLocale } from "../LocaleProvider";
import { useAuth } from "../AuthProvider";
import { TextdomainContext, buildTextdomain } from "../../../modules/src";

import daMessages from "_metronic/i18n/messages/da";
import deMessages from "_metronic/i18n/messages/de";
import enMessages from "_metronic/i18n/messages/en";
import esMessages from "_metronic/i18n/messages/es";
import frMessages from "_metronic/i18n/messages/fr";
import jaMessages from "_metronic/i18n/messages/ja";
import zhMessages from "_metronic/i18n/messages/zh";
import noMessages from "_metronic/i18n/messages/no";
import svMessages from "_metronic/i18n/messages/sv";

const allMessages = {
  da: daMessages,
  de: deMessages,
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  zh: zhMessages,
  no: noMessages,
  sv: svMessages
};

export let LANG = "en";

export function useLang() {
  const { user } = useUser();

  const { isAuthenticated } = useAuth();

  const { locale } = useLocale();

  const { lang } = user || {};

  const language =
    (isAuthenticated ? lang : locale) ||
    localStorage.getItem("languageSelect") ||
    detectBrowserLanguage()?.split("-")[0] ||
    "en";

  LANG = language;

  return language;
}

export default function I18nProvider({ children }) {
  const locale = useLang();
  const [textDomain, setTextdomain] = useState(buildTextdomain());
  useEffect(() => {
    let pofile = {
      catalog: {},
      plural: "n != 1"
    };

    try {
      pofile = require(`../../../../languages/LC_MESSAGES/${locale}.mo.json`);
    } catch (e) {
      // do nothing, default pofile will be used
    }
    setTextdomain(buildTextdomain(pofile.catalog, pofile.plural));
  }, [children, locale]);

  const messages = allMessages[locale];

  return (
    <TextdomainContext.Provider value={textDomain}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </TextdomainContext.Provider>
  );
}
