import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import Select from "react-select";
import { useQuery } from "@apollo/client";
import { GET_ACCOUNT_GROUPS } from "../../../../GraphQL/Queries";

const AccountChartForm = props => {
  const {
    nameDa,
    nameDe,
    nameEn,
    accountNumber,
    accountGroupId,
    tab,
    category,
    setNameDa,
    setNameDe,
    setNameEn,
    setAccountNumber,
    setAccountGroupId,
    setTab,
    setCategory
  } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [accountGroupData, setAccountGroupData] = useState([]);
  const [selectedAccountLabel, setSelectedAccountLabel] = useState("");
  const [selectedAccountValue, setSelectedAccountValue] = useState("");

  const { data } = useQuery(GET_ACCOUNT_GROUPS, {
    variables: {}
  });
  useEffect(() => {
    if (data) {
      let { accountGroups } = data;
      let accountData = [];
      accountGroups.map(item => {
        accountData.push({
          label: item.nameDa,
          value: item.Id
        });
        return true;
      });
      setAccountGroupData(accountData);
    }
  }, [data]);

  useEffect(() => {
    if (accountGroupData.length > 0) {
      const findObject = accountGroupData.find(
        item => item.value === accountGroupId
      );
      if (findObject) {
        setSelectedAccountLabel(findObject.label);
        setSelectedAccountValue(findObject.value);
        setAccountGroupId(findObject.value);
      } else {
        setSelectedAccountLabel(accountGroupData[0].label);
        setSelectedAccountValue(accountGroupData[0].value);
        setAccountGroupId(accountGroupData[0].value);
      }
    }
  }, [setAccountGroupId, accountGroupId, accountGroupData]);

  const handleChangeGroupId = option => {
    setSelectedAccountLabel(option.label);
    setSelectedAccountValue(option.value);
    setAccountGroupId(option.value);
  };

  return (
    <div className="row m-0 border-top-row">
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Account Group Id")}</label>
          <Select
            options={accountGroupData}
            onChange={handleChangeGroupId}
            value={[
              {
                value: selectedAccountValue,
                label: selectedAccountLabel
              }
            ]}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Account Number")}</label>
          <input
            type="number"
            className="form-control input"
            id="accountNumber"
            name="accountNumber"
            required
            placeholder={gettext("Enter Account Number")}
            value={accountNumber}
            onChange={e => setAccountNumber(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Danish Name")}</label>
          <input
            type="text"
            className="form-control input"
            id="nameDa"
            name="nameDa"
            required
            placeholder={gettext("Enter Danish Name")}
            value={nameDa}
            onChange={e => setNameDa(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("German Name")}</label>
          <input
            type="text"
            className="form-control input"
            id="nameDe"
            name="nameDe"
            required
            placeholder={gettext("Enter German Name")}
            value={nameDe}
            onChange={e => setNameDe(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("English Name")}</label>
          <input
            type="text"
            className="form-control input"
            id="nameEn"
            name="nameEn"
            required
            placeholder={gettext("Enter English Name")}
            value={nameEn}
            onChange={e => setNameEn(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Tab")}</label>
          <input
            type="text"
            className="form-control input"
            id="tab"
            name="tab"
            required
            placeholder={gettext("Enter Tab Name")}
            value={tab}
            onChange={e => setTab(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Category")}</label>
          <input
            type="text"
            className="form-control input"
            id="category"
            name="category"
            required
            placeholder={gettext("Enter Category")}
            value={category}
            onChange={e => setCategory(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountChartForm;
