import { gql } from "@apollo/client";

export const INSERT_ACCOUNTS_DATA = gql`
  mutation insertAccountData(
    $fromDate: Date
    $toDate: Date
    $customerId: String
    $accountData: [AccountDataInput]
  ) {
    insertAccountData(
      fromDate: $fromDate
      toDate: $toDate
      customerId: $customerId
      accountData: $accountData
    ) {
      message
      success
      accountDataId
    }
  }
`;
