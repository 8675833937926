import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { MixedWidget3 } from "../../../widgets";

function EpcChart({ yearsTableState, selectedColor }) {
  const { current, prev1, prev2 } = yearsTableState;
  const [data, setData] = useState(undefined);

  useEffect(() => {
    if (current && prev1 && prev2) {
      let dataObject = {
        categories: [prev2.name, prev1.name, parseInt(current.name)],
        chart_title: "EPC",
        colors: [selectedColor],
        data: [
          {
            name: "EPC",
            data: [prev2.value, prev1.value, current.value]
          }
        ],
        dataLabelsEnabled: false,
        plotOptionsBarDistributed: false,
        stacked: true,
        type: "bar",
        unique_id: "epc-chart-year",
        yaxisTitleText: "EPC"
      };
      setData(dataObject);
    }
  }, [current, prev1, prev2, selectedColor]);

  return (
    <div className="card card-custom gutter-b example example-compact d-flex flex-column mw-0 p-4">
      {data && (
        <MixedWidget3
          className=""
          data={data}
          imageUrl={uri => {
            // props.imageUrl1(uri);
          }}
        />
      )}
    </div>
  );
}

export default injectIntl(EpcChart);
