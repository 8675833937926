import { Container, Grid, Stack, Typography } from "@mui/material";
import { LoginForm } from "app/features";
import { useTextdomainContext } from "app/providers";
import React, { useContext } from "react";
// import { Link } from "react-router-dom";

export function Login() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  return (
    <Container maxWidth="xs">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography fontSize={36} fontWeight={700} color="#27272A">
            {gettext("Sign in")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="baseline"
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            {/* <Typography fontSize={16} fontWeight={500} color="#3F3F46">
              {gettext("New to fabeke?")}
            </Typography>
            <Typography fontSize={16} fontWeight={500}>
              &nbsp;
            </Typography> */}
            {/* <Typography
              component={Link}
              to="/auth/registration"
              fontSize={16}
              fontWeight={500}
              sx={{ textDecoration: "none" }}
              color="primary"
            >
              {gettext("Sign up for an account.")}
            </Typography> */}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <LoginForm />
        </Grid>
      </Grid>
    </Container>
  );
}
