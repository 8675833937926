import { useQuery } from "@apollo/client";
import { GET_COUNTRIES } from "./commands";

export function useCountries() {
  const { data, loading } = useQuery(GET_COUNTRIES);

  return {
    countries: data?.mongoCountries || [],
    loading
  };
}
