import React, { Component } from "react";
import "../../index.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ButtonGroup, Button } from "react-bootstrap";

class TopSectionDatePicker extends Component {
  customInputStyle = {
    height: 40,
    borderRadius: 4,
    width: 200,
    borderColor: "rgb(204,204,204)",
    borderWidth: 1.8,
    paddingLeft: 10,
    marginTop: 3,
    outlineColor: "rgb(46, 135,252)"
  };
  appGreenColor = "rgb(67, 105, 50)";

  constructor(props) {
    super(props);
    let localFilters;
    if (this?.props?.sectionDatePicker) {
      localFilters = JSON.parse(localStorage.getItem("benchmarksFilters"));
    } else {
      localFilters = JSON.parse(localStorage.getItem("energyFilters"));
    }

    let oneYearOldDate =
      localFilters && localFilters.date
        ? new Date(localFilters.date)
        : new Date();
    // console.log(localFilters.date);

    if (!localFilters || (localFilters && !localFilters.date)) {
      oneYearOldDate.setFullYear(oneYearOldDate.getFullYear() - 1);
    }

    this.state = {
      selectedDate: oneYearOldDate,
      selectedSegment: localFilters ? parseInt(localFilters.selectedSegment) : 2
    };
  }

  customInput = () => {
    return <input style={this.customInputStyle} />;
  };

  onSegmentClick = selectedSegment => {
    this.setState({ selectedSegment: selectedSegment });
    this.props.onSegmentChange(selectedSegment);
  };

  //top section with dropdown(s)
  renderTopSection = () => {
    const { selectedDate, selectedSegment } = this.state;
    const { dateFormat, getSelectedDate } = this.props;

    return (
      <div className="top-section-datepicker">
        <div className="button-group">
          <ButtonGroup size="lg" className="mb-2">
            {!this.props.sectionDatePicker && (
              <Button
                onClick={() => this.onSegmentClick(0)}
                style={{
                  backgroundColor:
                    selectedSegment === 0 ? this.appGreenColor : "white",
                  color: selectedSegment === 0 ? "white" : "black"
                }}
              >
                {this.props.gettext("Day")}
              </Button>
            )}
            <Button
              onClick={() => this.onSegmentClick(1)}
              style={{
                backgroundColor:
                  selectedSegment === 1 ? this.appGreenColor : "white",
                color: selectedSegment === 1 ? "white" : "black"
              }}
            >
              {this.props.gettext("Month")}
            </Button>
            <Button
              onClick={() => this.onSegmentClick(2)}
              style={{
                backgroundColor:
                  selectedSegment === 2 ? this.appGreenColor : "white",
                color: selectedSegment === 2 ? "white" : "black"
              }}
            >
              {this.props.gettext("Year")}
            </Button>
          </ButtonGroup>
        </div>

        <div className="date-picker">
          <DatePicker
            customInput={this.customInput()}
            selected={selectedDate}
            onChange={date => {
              this.setState({ selectedDate: date });
              getSelectedDate(date, selectedSegment);
            }}
            dateFormat={
              selectedSegment === 2
                ? "yyy"
                : selectedSegment === 1
                ? "MMM YYY"
                : dateFormat
                ? dateFormat
                : "dd/MM/yyy"
            }
            showMonthYearPicker={selectedSegment === 1}
            showYearPicker={selectedSegment === 2}
          />
        </div>
      </div>
    );
  };

  render() {
    return <div className="text-center">{this.renderTopSection()}</div>;
  }
}

export default TopSectionDatePicker;
