import React from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import ComponentsOverrides from "./overrides";
import { palette } from "./palette";
// import Typography from './typography';
// import CustomShadows from './shadows';

export default function MaterialThemeProvider(props) {
  const { children } = props;

  // const typography = Typography(`Aeonik`);

  // const customShadows = React.useMemo(() => CustomShadows(theme), [theme]);

  const themeOptions = React.useMemo(
    () => ({
      // direction: "rtl",
      zIndex: {
        mobileStepper: 1000,
        fab: 1010,
        speedDial: 1010,
        appBar: 1100,
        drawer: 1200,
        model: 1300,
        snackbar: 1400,
        tooltip: 1500
      },
      props: {
        MuiButtonBase: {
          disableRipple: false
        },
        MuiPopover: {
          elevation: 1
        }
      },
      palette,
      typography: {
        fontFamily: "Aeonik"
      }
      // customShadows,
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  return (
    <ThemeProvider theme={theme}>
      {" "}
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
