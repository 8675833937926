import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useRoleModules, useUpdateRoleModule } from "./hooks";
import { DataGridComponent } from "app/components";

export function PermissionManagementTable() {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const navigate = useNavigate();

  const { roleName, rolekey } = useParams();

  const { roleModules, loading } = useRoleModules(rolekey);

  const { update, updating } = useUpdateRoleModule();

  const handleChange = (e, row, type) => {
    const checked = e.target.checked;

    let updateData = {
      read: row.read,
      write: row.write,
      delete: row.delete
    };

    switch (type) {
      case "read":
        updateData.read = checked;

        if (!checked) {
          updateData.write = false;
          updateData.delete = false;
        }
        break;

      case "write":
        if (checked) {
          updateData.read = checked;
        } else updateData.delete = checked;

        updateData.write = checked;
        break;

      case "delete":
        if (checked) {
          updateData.read = checked;
          updateData.write = checked;
        }
        updateData.delete = checked;
        break;

      default:
        break;
    }

    update({
      role: roleName,
      module: row?.code.toLowerCase(),
      updateData
    });
  };

  function columnFormater(row, type) {
    let checked;

    switch (type) {
      case "read":
        checked = row.read;
        break;
      case "write":
        checked = row.write;
        break;
      case "delete":
        checked = row.delete;
        break;
      default:
        break;
    }
    return (
      <>
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          disabled={updating}
          onChange={e => handleChange(e, row, type)}
          checked={checked}
          id={`${type}_ChkbxID${row.name}`}
          name={`${type}_ChkbxID${row.name}`}
        />
      </>
    );
  }

  const columns = [
    {
      field: "name",
      headerName: gettext("Name"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "code",
      headerName: gettext("Code"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "read",
      headerName: gettext("Read"),
      flex: 0.1,
      headerAlign: "left",
      renderCell: params => columnFormater(params.row, "read")
    },
    {
      field: "write",
      headerName: gettext("Write"),
      flex: 0.1,
      headerAlign: "left",
      renderCell: params => columnFormater(params.row, "write")
    },
    {
      field: "delete",
      headerName: gettext("Delete"),
      flex: 0.1,
      headerAlign: "left",
      renderCell: params => columnFormater(params.row, "delete")
    }
  ];

  return (
    <>
      <div className="btn-group mt-3 mb-3">
        <button
          type="button"
          className="btn btn-outline-info w-10 d-flex justify-content-center align-items-center"
          onClick={() => navigate("/admin/system-role-management")}
        >
          <AiOutlineArrowLeft title="back" size={18} className="mr-2" />
          {gettext("Back")}
        </button>
      </div>
      <div className="card card-custom gutter-b example example-compact">
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3>
              {gettext("Permissions Management")}
              <span>&nbsp;{`For ${roleName}`}</span>
            </h3>
            <div className="w-100 min-w-100">
              <span className="font-size-sm">
                {gettext("Roles Permissions over Modules")}
              </span>
            </div>
          </div>
          <div className="card-body">
            <DataGridComponent
              rows={roleModules}
              columns={columns}
              loading={loading | updating}
              getRowId={row => row.name}
            />
          </div>
        </div>
      </div>
    </>
  );
}
