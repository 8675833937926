import {
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { IoMdColorFill } from "react-icons/io";

export function FieldColorInput({
  colorpickerValue,
  setColors,
  fieldName,
  field,
  gettext
}) {
  const title = useMemo(() => {
    return fieldName === "electricity"
      ? gettext("Electricity")
      : fieldName === "heating"
      ? gettext("Heating")
      : fieldName === "water"
      ? gettext("Water")
      : fieldName === "gas"
      ? gettext("Gas")
      : fieldName === "energy"
      ? gettext("Energy")
      : fieldName === "co2"
      ? gettext("CO₂")
      : fieldName === "green_electricity"
      ? gettext("Green Electricity")
      : fieldName === "compensation"
      ? gettext("Compensation")
      : fieldName === "consumption"
      ? gettext("Consumption")
      : fieldName === "production"
      ? gettext("Production")
      : null;
  }, [fieldName, gettext]);

  const handleBackgroundColorChange = useCallback(
    value => {
      setColors(prev => {
        prev[fieldName].background = value;
        return { ...prev };
      });
    },
    [setColors, fieldName]
  );

  const handleTextColorChange = useCallback(
    value => {
      setColors(prev => {
        prev[fieldName].text = value;
        return { ...prev };
      });
    },
    [setColors, fieldName]
  );

  return (
    <Grid container sx={{ marginY: 1 }} spacing={1}>
      <Grid item xs={12}>
        <Typography fontSize={18} fontWeight={700}>
          {title}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Stack spacing={1}>
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={12} md={12} lg={4} xl={3}>
              <Typography fontSize={11} fontWeight={300}>
                {gettext("Background")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={8} xl={9}>
              <Stack direction={"row"} spacing={{ xs: 1, md: 2, lg: 1, xl: 4 }}>
                <Box
                  sx={{
                    minWidth: 35,
                    width: 35,
                    height: 35,
                    minHeight: 35,
                    borderRadius: 2,
                    backgroundColor: field.background
                  }}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  value={field.background}
                  onChange={e => handleBackgroundColorChange(e.target.value)}
                />
                <Tooltip title="Fill" arrow>
                  <IconButton
                    onClick={() =>
                      handleBackgroundColorChange(colorpickerValue)
                    }
                  >
                    <IoMdColorFill />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item xs={12} md={12} lg={4} xl={3}>
              <Typography fontSize={11} fontWeight={300}>
                {gettext("Text")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={8} xl={9}>
              <Stack direction={"row"} spacing={{ xs: 1, md: 2, lg: 1, xl: 4 }}>
                <Box
                  sx={{
                    minWidth: 35,
                    width: 35,
                    height: 35,
                    minHeight: 35,
                    borderRadius: 2,
                    backgroundColor: field.text
                  }}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  value={field.text}
                  onChange={e => handleTextColorChange(e.target.value)}
                />
                <Tooltip title="Fill" arrow>
                  <IconButton
                    onClick={() => handleTextColorChange(colorpickerValue)}
                  >
                    <IoMdColorFill />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
}
