import React, { Suspense, useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import client from "services/apiClient";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { Navigate, Routes } from "react-router-dom";
import {
  REFRESH_AUTH_DATA,
  REFRESH_TEAM_AUTH_DATA
} from "../../../common/Constants";
import { useUser } from "app/providers";

let redirectUrl = "/buildings/administration";

const RedirectFlow = props => {
  const { user } = useUser();

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    client.post(REFRESH_AUTH_DATA, { refresh_type: "auth" }).then(response => {
      client
        .get(`${REFRESH_TEAM_AUTH_DATA}?user_id=${user.id}`)
        .then(response => {
          if (user && user.customerType === "company") {
            redirectUrl = "/user-profile/customers";
          }
          setRedirect(true);
        });
    });

    // const customerId = user.current_team_id;
    // client.get(COMPANY_ADDRESSES + '?team_id=' + customerId).then(response => {
    //   const { data }  = response.data;
    //   if (data.length > 0) {
    //     window.location.href = REACT_APP_URL + 'settings/buildings';
    //   } else {
    //     // setRedirect(true)
    //   }
    // });
  }, [user]);

  return (
    redirect && (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Routes>
          <Navigate to={redirectUrl} />
        </Routes>
      </Suspense>
    )
  );
};

export default injectIntl(RedirectFlow);
