import { gql } from "@apollo/client";

export const GET_USERS_LIST = gql`
  query comMyUsers($searchString: String, $statusFilter: String) {
    comMyUsers(searchString: $searchString, statusFilter: $statusFilter) {
      id
      name
      email
      countryCode
      timezone
      status
      lang
      roles
    }
  }
`;
