import React, { useEffect, useState, useContext } from "react";

import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
// import {MixedWidget3} from "../../../widgets";
import CardHeader from "./CardHeader";
import Co2SectionCol1Row1 from "./Co2SectionCol1Row1";
import { localeNumberFormat } from "../../../common/Functions";
import { DECIMAL } from "../../../common/Constants";
import { totalEnergyCo2, dataEnergyGraph1 } from "../partials/Functions";

function Co2Section2(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [data1, changeData1] = useState(null);
  const [totalEnergyCo2Year1, changeTotalEnergyCo2Year1] = useState(0);
  const [totalEnergyCo2Year3, changeTotalEnergyCo2Year3] = useState(0);
  const year1 = props.data.to_year ? props.data.to_year : "";
  const year2 = props.data.second_year ? props.data.second_year : "";
  const year3 = props.data.from_year ? props.data.from_year : "";
  const chartId = "co2-chart-1";

  useEffect(() => {
    if (props.data.electricity || props.data.heating || props.data.water) {
      changeTotalEnergyCo2Year1(totalEnergyCo2(props.data, year1));
      changeTotalEnergyCo2Year3(totalEnergyCo2(props.data, year3));
      changeData1(dataEnergyGraph1(chartId, props.data, gettext));
    }
  }, [props.data, year1, year3, gettext]);

  let totalEnergyCo2Year1Formatted = localeNumberFormat(
    totalEnergyCo2Year1,
    DECIMAL
  );
  let totalEnergyCo2Year3Formatted = localeNumberFormat(
    totalEnergyCo2Year3,
    DECIMAL
  );

  return (
    <>
      <div className={"card card-custom gutter-b"}>
        <CardHeader
          title={`${gettext("CO₂ accounts for")} ${year3}, ${year2} ${gettext(
            "and"
          )} ${year1}`}
        />
        <div className={"card-body"}>
          <div className={"row"}>
            {totalEnergyCo2Year1 < totalEnergyCo2Year3 &&
              `${gettext(
                "Total CO₂ emissions fell from"
              )} ${totalEnergyCo2Year3Formatted} ${gettext(
                "tonnes of CO₂ in"
              )} ${year3} ${gettext(
                "to"
              )} ${totalEnergyCo2Year1Formatted} ${gettext(
                "tonnes of CO₂ in"
              )} ${year1}.`}

            {totalEnergyCo2Year1 > totalEnergyCo2Year3 &&
              `${gettext(
                "Total CO₂ emissions increased from"
              )} ${totalEnergyCo2Year3Formatted} ${gettext(
                "tonnes of CO₂ in"
              )} ${year3} ${gettext(
                "to"
              )} ${totalEnergyCo2Year1Formatted} ${gettext(
                "tonnes of CO₂ in"
              )} ${year1}`}
            {totalEnergyCo2Year1 === totalEnergyCo2Year3 &&
              `${gettext(
                "The total CO₂ emissions have been constant at"
              )} ${totalEnergyCo2Year3Formatted} ${gettext(
                "tonnes of CO₂ in the period"
              )} ${year3} ${gettext("to")} ${year1}`}
          </div>
          <div className={"separator separator-dashed my-7"}></div>
          {data1 && (
            <Co2SectionCol1Row1
              data1={data1}
              imageUrl={uri => {
                if (typeof props.graph1imageUrl === "function") {
                  props.graph1imageUrl(uri);
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default injectIntl(Co2Section2);
