import React from "react";
import Select from "react-select";
// import publicIp from "public-ip";
import { getGeoInfo } from "../common/Functions";
import client from "services/apiClient";

const { REACT_APP_API_URL } = process.env;
const COUNTRY_ALL_URL = REACT_APP_API_URL + "api/countries/all/dropdown";

export default class Countries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      countryName: "",
      countryCode: "",
      selectedOption: null
    };
  }

  componentDidMount() {
    this.fillDropdown();
    this.getGeoInfo();
    // getGeoInfo().then(data => {
    //   console.log(data);
    // })
  }

  fillDropdown = () => {
    client.get(COUNTRY_ALL_URL).then(res => {
      // console.log(res);
      this.setState({ ...this.state, options: res.data });
    });
  };
  //
  getGeoInfo = () => {
    getGeoInfo().then(data => {
      this.setState({
        countryName: data.country_name,
        countryCode: data.country.toLowerCase()
      });
    });
  };

  // getGeoInfo = () => {
  //   publicIp.v4().then(ip => {
  //     let ipapiKey = "ipapi.co-" + ip;
  //     // localStorage.removeItem(ipapiKey);
  //     if (localStorage.getItem(ipapiKey) === null) {
  //       client
  //         .get("https://ipapi.co/" + ip + "/json")
  //         .then(response => {
  //           let data = response.data;
  //           localStorage.setItem(ipapiKey, JSON.stringify(data));
  //           console.log("live: ", data);
  //           this.setState({
  //             countryName: data.country_name,
  //             countryCode: data.country.toLowerCase()
  //           });
  //         })
  //         .catch(error => {
  //           console.log(error);
  //         });
  //     } else {
  //       let data = JSON.parse(localStorage.getItem(ipapiKey));
  //       console.log("cache: ", data);
  //       this.setState({
  //         countryName: data.country_name,
  //         countryCode: data.country.toLowerCase()
  //       });
  //     }
  //   });
  // };

  handleChange = selectedOption => {
    // this.setState({ selectedOption });
    this.setState({
      countryName: selectedOption.label,
      countryCode: selectedOption.value
    });
    // console.log(this.state);
    // console.log(this.props)
  };

  setValue = () => {
    return [{ value: this.state.countryCode, label: this.state.countryName }];
  };

  // defaultValue={[
  //         { value: this.state.countryCode, label: this.state.countryName }
  //         ]}

  render() {
    return (
      <>
        <Select
          name={"country"}
          value={[
            { value: this.state.countryCode, label: this.state.countryName }
          ]}
          options={this.state.options}
          onChange={this.handleChange}
          {...this.props}
          {...this.props.field}
        />
      </>
    );
  }
}
