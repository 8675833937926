import { useQuery } from "@apollo/client";
import { GET_COUNTRY_ADDRESSES } from "./commands";

export function useCountryAddresses(customerName, country) {
  const { data, loading } = useQuery(GET_COUNTRY_ADDRESSES, {
    variables: {
      customerName,
      country
    }
  });

  return {
    buildings: data?.getCountryAddresses || [],
    loading
  };
}
