import React, { useState, useContext } from "react";
import client from "services/apiClient";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DataGridComponent } from "../../components";
import { MdDelete } from "react-icons/md";
import { apiRoutes } from "../../../utils";
import { confirmAlert } from "react-confirm-alert";
import { Alert, AlertTitle, Collapse, IconButton } from "@mui/material";
import { useUser, useTextdomainContext } from "app/providers";
import "../../../_metronic/_assets/sass/UserList.css";

export function CustomersList(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [errorMessage, setErrorMessage] = useState(false);

  const { user } = useUser();

  const { teams } = user || {};

  // const fetchCustomerListCallback = useCallback(
  //   async (user_id = user.id) => {
  //     setLoading(true);
  //     setTeamMembersData([]);
  //     client.get(GET_CUSTOMERS_URL + "/" + user_id).then(
  //       response => {
  //         setTeamMembersData(team => team.concat(response.data.teams));
  //         setLoading(false);
  //       },
  //       () => setLoading(false)
  //     );

  //     user?.roles?.find(role => role.name === "Partner") &&
  //       client
  //         .get(apiRoutes.getMembers.path + `?customer_key=${user?.customerKey}`)
  //         .then(
  //           response => {
  //             setTeamMembersData(team => team.concat(response?.data?.members));
  //           },
  //           () => {}
  //         );
  //   },
  //   [user.id, user.customerKey, user.roles]
  // );

  const handleDeleteCustomer = customer => {
    client
      .delete(
        apiRoutes.DeleteCustomerFromUser.path +
          `?team_id=${customer.id}&&user_id=${user.id}`
      )
      .then(data => {
        if (data?.toString().includes(400)) {
          setErrorMessage(true);
          setTimeout(() => {
            setErrorMessage(false);
          }, 5000);
        } else {
          // fetchCustomerListCallback(user.id);
        }
      })
      .catch(err => {});
  };

  const handleConfirmDialog = customer => {
    confirmAlert({
      title: gettext("Confirmation"),
      message: `${gettext("Are you sure you want to Delete")}${" " +
        customer.name}`,
      buttons: [
        {
          label: gettext("Yes"),
          onClick: () => handleDeleteCustomer(customer)
        },
        {
          label: gettext("No")
          // onClick: () => alert('Click No')
        }
      ]
    });
  };

  function columnFormater(params) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <OverlayTrigger
          // disabled
          overlay={
            <Tooltip>
              <strong>Delete</strong>
            </Tooltip>
          }
        >
          <IconButton disabled={user.demo}>
            <MdDelete
              // title="Delete"

              size={20}
              onClick={() => handleConfirmDialog(params.row)}
            />
          </IconButton>
        </OverlayTrigger>
      </div>
    );
  }

  const columns = [
    {
      field: "name",
      headerName: gettext("Name"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "label",
      headerName: gettext("Label"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      flex: 0.2,
      headerAlign: "center",
      renderCell: params => columnFormater(params)
    }
  ];

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3 className="custom-header-list">
              <span>{gettext("Companies")}</span>
            </h3>
            <div className="card-toolbar"></div>
            <span className="font-size-sm w-100">{gettext("Companies")}</span>
          </div>
          {/* end::Header */}

          <div className="card-body">
            {
              <Collapse in={errorMessage}>
                <Alert severity="error">
                  <AlertTitle>{gettext("Error")}</AlertTitle>
                  {gettext("The company is already deleted")}
                </Alert>
              </Collapse>
            }
            <DataGridComponent
              rows={teams}
              columns={columns}
              getRowId={row => row.name}
              disableSelectionOnClick={true}
              autoHeight
              initialState={{
                sorting: {
                  sortModel: [{ field: "name", sort: "asc" }]
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default CustomersList;
