import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
import BuildingDropdown from "../../../components/BuildingDropdown";
import CustomerDropdown from "../../../components/CustomerDropdown";
// import Co2DatePicker from "../components/Co2DatePicker";
import { Grid, Typography } from "@mui/material";
import NewDatePicker from "app/components/NewDatePicker";

function Co2ReportFilterComponent(props) {
  const { setFromDate, setToDate, toDate } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [selectedTeams, updateSelectedTeams] = useState("");

  // const maxDate = new Date();

  const handleChangeCustomers = selectedValue => {
    let selectedCustomers = [];
    if (selectedValue) {
      selectedCustomers = selectedValue.map(selectedRow => {
        return selectedRow.value;
      });
    }

    props.onChangeCustomer &&
      props.onChangeCustomer(selectedCustomers.toString());
    updateSelectedTeams(selectedCustomers.toString());
  };

  const handleChangeBuildings = selectedValue => {
    let selectedBuildings = [];

    if (selectedValue) {
      selectedBuildings = selectedValue.map(selectedRow => {
        return selectedRow.value;
      });
    }

    props.onChangeBuilding &&
      props.onChangeBuilding(selectedBuildings.join("|"));
  };

  return (
    <>
      <Grid
        container
        // spacing={3}
        justifyContent={"center"}
        alignItems="center"
        sx={{
          p: 1,
          backgroundColor: "secondary.50",
          border: "1px solid",
          minHeight: "52px",
          borderColor: "secondary.200",
          borderRadius: "8px"
        }}
      >
        <Grid item xs={4}>
          <CustomerDropdown
            onChange={handleChangeCustomers}
            gettext={gettext}
          />
        </Grid>

        <Grid item xs={4} textAlign="center">
          <BuildingDropdown
            teams={selectedTeams}
            onChange={handleChangeBuildings}
            gettext={gettext}
          />
        </Grid>

        {setFromDate && setToDate && (
          // <Grid item md={18}>
          //     <Co2DatePicker
          //         date={toDate}
          //         maxDate={maxDate}
          //         selectedSegment={1}
          //         getSelectedDate={(date) => {
          //             setToDate(date);
          //         }}
          //     />
          // </Grid>
          <Grid item xs={4} sm textAlign="end">
            <NewDatePicker
              selectededDate={toDate}
              segment={1}
              getSelectedDate={date => {
                setToDate(date);
              }}
              // onSegmentChange={(selectedSegment) => {
              //     setToDate(date);
              //     setSelectedSegment(selectedSegment);
              // }}
              gettext={gettext}
              disableSegments={true}
            />
          </Grid>
        )}
      </Grid>
      <Typography
        pt={2}
        pl={1}
        sx={{
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "14px",
          letterSpacing: "0em",
          textAlign: "left",
          color: "#71717A"
        }}
      >
        {gettext(
          "User will see the CO₂ report of previous three years from this selected date"
        )}
      </Typography>
    </>
  );
}

export default injectIntl(Co2ReportFilterComponent);
