import React, { useState, useEffect, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Spinner, Modal, Button } from "react-bootstrap";
import { useCountries, useTimezones } from "hooks";
import { useUpdateUser } from "../hooks";

export default function EditModel({ show, onHide, editableRow }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [inputName, setinputName] = useState(editableRow.name);

  const [inputEmail, setinputEmail] = useState(editableRow.email);

  const [inputLang, setinputLang] = useState(editableRow.lang);

  const [inputCountry, setinputCountry] = useState(editableRow.countryCode);

  const [inputTimezone, setinputTimezone] = useState(editableRow.timezone);

  const [inputStatus, setinputStatus] = useState(editableRow.status);

  const [inputCustomerKey, setinputCustomerKey] = useState(
    editableRow.customerKkey
  );

  const [timezonesList, settimezonesList] = useState([]);

  const { countries, loading: countriesLoading } = useCountries();

  const { timezones, loading: timezonesLoading } = useTimezones();

  useEffect(() => {
    setinputName(editableRow.name);

    setinputEmail(editableRow.email);

    setinputLang(editableRow.lang);

    countries?.length > 0 &&
      setinputCountry(
        editableRow.countryCode &&
          countries.find(obj => obj.code === editableRow.countryCode)?.code
      );

    setinputTimezone(editableRow.timezone);

    setinputStatus(editableRow.status);
  }, [editableRow, countries]);

  useEffect(() => {
    let timezonesTemp = [];
    timezones &&
      timezones.forEach(timezone => {
        inputCountry === timezone.countryCode && timezonesTemp.push(timezone);
      });
    settimezonesList(timezonesTemp);
  }, [inputCountry, timezones]);

  const reset = () => {
    setinputName("");
    setinputEmail("");
    setinputLang("");
    setinputCountry("");
    setinputTimezone("");
    setinputStatus("");
    setinputCustomerKey("");
  };

  const { updateUser, updating } = useUpdateUser(() => {
    reset();
    onHide();
  });

  const submitHandler = e => {
    e.preventDefault();

    updateUser({
      userId: editableRow.id,
      name: inputName,
      email: inputEmail,
      lang: inputLang,
      countryCode: inputCountry,
      timezone: inputTimezone === "" ? null : inputTimezone,
      status: inputStatus,
      customerKey: inputCustomerKey
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{gettext("Edit User")}</Modal.Title>
      </Modal.Header>
      <form onSubmit={submitHandler}>
        <Modal.Body>
          <div className="card-body">
            <div className="form-group">
              <label>{gettext("Name")}</label>
              <input
                type="text"
                className="form-control"
                id="Name"
                name="Name"
                required
                placeholder="Enter name"
                value={inputName}
                onChange={e => setinputName(e.target.value)}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Email")}</label>
                <input
                  type="email"
                  className="form-control"
                  id="Email"
                  name="Email"
                  required
                  placeholder="Enter email"
                  value={inputEmail}
                  onChange={e => setinputEmail(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("Language")}</label>
                <select
                  className="form-control"
                  id="Language"
                  name="Language"
                  required
                  value={inputLang && inputLang}
                  onChange={e => setinputLang(e.target.value)}
                >
                  <option value="">Select...</option>
                  <option value="en">English</option>
                  <option value="da">Danish</option>
                  <option value="de">Germen</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Country")}</label>
                <select
                  className="form-control"
                  id="Country"
                  name="Country"
                  required
                  value={inputCountry && inputCountry}
                  onChange={e => setinputCountry(e.target.value)}
                >
                  <option value="">Select...</option>
                  {countries &&
                    countries.map(country => (
                      <option key={`select_${country.id}`} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("Timezone")}</label>
                <select
                  className="form-control"
                  id="Timezone"
                  name="Timezone"
                  required
                  value={inputTimezone && inputTimezone}
                  onChange={e => setinputTimezone(e.target.value)}
                >
                  <option value="">Select...</option>
                  {timezonesList &&
                    timezonesList.map(timezone => (
                      <option value={timezone.zoneName}>
                        {timezone.zoneName}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Status")}</label>
                <select
                  className="form-control"
                  id="Status"
                  name="Status"
                  required
                  value={inputStatus && inputStatus}
                  onChange={e => setinputStatus(e.target.value)}
                >
                  <option value="">Select...</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Deleted">Deleted</option>
                  <option value="Blocked">Blocked</option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("Company Key")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="CustomerKey"
                  name="CustomerKey"
                  placeholder="Enter Company Key"
                  value={inputCustomerKey}
                  onChange={e => setinputCustomerKey(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {gettext("Close")}
          </Button>
          <Button type="submit" variant="primary">
            {gettext("Save Changes")}
          </Button>
        </Modal.Footer>
      </form>
      {updating || countriesLoading || timezonesLoading ? (
        <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : null}
    </Modal>
  );
}
