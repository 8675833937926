import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import { usePagePrivileges } from "hooks/privileges";
import { isLocationActive } from "app/common/Methods";
import { alpha } from "@mui/material";

export default function Level1MenuItem({ link, label, icon, code }) {
  const location = useLocation();

  const [_read, _write] = usePagePrivileges(code);

  const [displayOption, setDisplayOption] = useState(false);

  const [activeColor, setactiveColor] = useState("");

  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    setisActive(isLocationActive(location, link));
  }, [location, link]);

  useEffect(() => {
    setactiveColor(isActive ? "white" : "primary.200");
  }, [isActive]);

  useEffect(() => {
    if (_read || _write) {
      setDisplayOption(true);
    } else if (code === undefined) {
      setDisplayOption(true);
    } else {
      setDisplayOption(false);
    }
  }, [_read, _write, code]);

  return (
    <>
      {displayOption && (
        <NavLink to={link}>
          <ListItem disablePadding sx={{ color: activeColor }}>
            <ListItemButton
              disableGutters
              sx={{
                borderRadius: "8px",
                backgroundColor: isActive
                  ? alpha("#FFFFFF", 0.28)
                  : "transparent"
              }}
            >
              {icon}
              <Typography variant="h6" color="inherit">
                {label}
              </Typography>
            </ListItemButton>
          </ListItem>
        </NavLink>
      )}
    </>
  );
}
