const countries = [
  { code: "DK", label: "Denmark", currency: "DKK" },
  { code: "SE", label: "Sweden", currency: "SEK" },
  { code: "AT", label: "Austria", currency: "ATS" },
  { code: "BE", label: "Belgium", currency: "BEF" },
  { code: "EE", label: "Estonia", currency: "EEK" },
  { code: "FI", label: "Finland", currency: "FIM" },
  { code: "LT", label: "Lithuania", currency: "LTL" },
  { code: "NO", label: "Norway", currency: "NOK" },
  { code: "FR", label: "France", currency: "FRF" },
  { code: "NL", label: "Netherlands", currency: "NLG" }
];

export default countries;
