import { useQuery } from "@apollo/client";
import { GET_USERS_LIST } from "../commands";

export function useUsersList(searchString, statusFilter) {
  const { data, loading } = useQuery(GET_USERS_LIST, {
    variables: {
      searchString,
      statusFilter
    },
    fetchPolicy: "no-cache"
  });

  return {
    usersList: data?.comMyUsers || [],
    loading
  };
}
