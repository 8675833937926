import { useQuery } from "@apollo/client";
import { GET_DROPDOWN_OPTIONS } from "./commands";

export function useDropdownOptions(
  collection,
  level1Key,
  Type,
  level2Key,
  level3Key
) {
  const { data, loading, error, refetch } = useQuery(GET_DROPDOWN_OPTIONS, {
    fetchPolicy: "no-cache",
    variables: {
      collection,
      level1Key,
      Type,
      level2Key,
      level3Key
    }
  });

  return {
    options: data?.dropdowns || [],
    loading,
    error,
    refetch
  };
}
