import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useTextdomainContext } from "app/providers";

export function GoBack({ prevUrl }) {
  const navigate = useNavigate();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  return (
    <div className="btn-group mt-3 mb-3">
      <button
        type="button"
        className="btn btn-outline-info w-10 d-flex justify-content-center align-items-center"
        onClick={() => (prevUrl ? navigate(prevUrl) : navigate(-3))}
      >
        <AiOutlineArrowLeft title="back" size={18} className="mr-2" />
        {gettext("Back")}
      </button>
    </div>
  );
}
