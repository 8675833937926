import React from "react";
import client from "services/apiClient";

const { REACT_APP_API_URL } = process.env;

const API_URL = REACT_APP_API_URL;

export const UPDATE_USER_URL = API_URL + "user/update";
export const RESET_PASSWORD_URL = API_URL + "user/reset_password";
// export const CREATE_TEAM_URL = API_URL + "user/create-team";
export const CREATE_TEAM_URL = API_URL + "teams";
export const UPDATE_TEAM_URL = API_URL + "user/update-team";
export const SWITCH_TEAM_URL = API_URL + "user/switch_team";
export const GET_ROLES_URL = API_URL + "user/get-roles";
export const GET_TEAMS_URL = API_URL + "user/get-teams";

export const ADD_TEAM_USER_URL = API_URL + "user/add_team_user";
export const GET_TEAM_MEMBERS_URL = API_URL + "user/get_team_members";
export const UPDATE_TEAM_MEMBERS_ROLE_URL = API_URL + "v1/team_users";
export const DELETE_TEAM_MEMBERS_ROLE_URL = API_URL + "v1/team_users";

export const ADD_USER_URL = API_URL + "/v1/users";
export const GET_USER_URL = API_URL + "v1/users/get_my_users";
export const UPDATE_MY_USERS_URL = API_URL + "/v1/users";
export const DELETE_MY_USER_URL = API_URL + "/v1/users";

export const GET_ALLROLES_URL = API_URL + "v1/roles";

export const GET_USER_ROLE_URL = API_URL + "v1/user_roles/";
export const GET_ROLE_USERS_URL = API_URL + "v1/user_roles";
export const UPDATE_USER_ROLE_URL = API_URL + "v1/user_roles/";
export const DELETE_USER_ROLE_URL = API_URL + "v1/user_roles";

export const GET_CUSTOMERS_URL = API_URL + "user/teams";
export function updateUser(data) {
  return client.post(UPDATE_USER_URL, data).catch(err => {
    if (err.response) {
      throw err.response;
    } else if (err.request) {
      throw err.request;
    } else {
      throw err;
    }
  });
}

export function updatePassword(
  current_password,
  password,
  password_confirmation,
  email
) {
  return client
    .put(RESET_PASSWORD_URL, {
      current_password: current_password,
      password: password,
      password_confirmation: password_confirmation,
      email: email
    })
    .catch(err => {
      if (err.response) {
        throw err.response;
      } else if (err.request) {
        throw err.request;
      } else {
        throw err;
      }
    });
}

export function createTeam(
  type,
  pnumber,
  name,
  cvr,
  address,
  userId,
  isNew = false
) {
  return client
    .post(CREATE_TEAM_URL, {
      type: type,
      pnumber: pnumber,
      name: name,
      cvr: cvr,
      address: address,
      isNew: isNew,
      user_id: userId
    })
    .then(response => {
      let data = response.data;
      if (!data.status) {
        // console.log(response);
      }
      if (data.status && data.status !== 200) {
        // console.log(data.body);
      }

      return data;
    })
    .catch(err => {
      // console.log(err);
      if (err.response) {
        throw err.response;
      } else if (err.request) {
        throw err.request;
      } else {
        throw err;
      }
    });
}

export function updateTeam(id, type, pnumber, name, cvr, address, user_id) {
  return client
    .put(CREATE_TEAM_URL, {
      id: id,
      type: type,
      pnumber: pnumber,
      name: name,
      cvr: cvr,
      address: address,
      user_id: user_id
    })
    .then(response => {
      let data = response.data;
      if (response.status === 200) {
        return data.action;
      } else {
        let errorMessage = data;
        if (data.body) {
          errorMessage = [data.body];
        }
        throw errorMessage;
      }
    })
    .catch(err => {
      // console.log(err);
      if (err.response) {
        let errorMessage = err.response;
        if (err.response.data.message) {
          errorMessage = [err.response.data.message];
        }
        throw errorMessage;
      } else if (err.request) {
        let errorMessage = err.response;
        if (err.request.data) {
          errorMessage = err.request.data;
        }
        throw errorMessage;
      } else {
        let errorMessage = err;
        if (err.data) {
          errorMessage = err.data;
        }
        throw errorMessage;
      }
    });
}

export function deleteTeam(id, user_id) {
  return client
    .delete(CREATE_TEAM_URL + "/" + id, {
      id: id,
      user_id: user_id
    })
    .then(response => {
      let data = response.data;
      if (response.status === 200) {
        return data.action;
      } else {
        let errorMessage = data;
        if (data.body) {
          errorMessage = [data.body];
        }
        throw errorMessage;
      }
    })
    .catch(err => {
      // console.log(err);
      if (err.response) {
        let errorMessage = err.response;
        if (err.response.data.message) {
          errorMessage = [err.response.data.message];
        }
        throw errorMessage;
      } else if (err.request) {
        let errorMessage = err.response;
        if (err.request.data) {
          errorMessage = err.request.data;
        }
        throw errorMessage;
      } else {
        let errorMessage = err;
        if (err.data) {
          errorMessage = err.data;
        }
        throw errorMessage;
      }
    });
}

export function addUserInTeam(email, team_id, read, write, deleteval) {
  return client
    .post(ADD_TEAM_USER_URL, {
      email,
      team_id,
      read,
      write,
      delete: deleteval
    })
    .catch(err => {
      if (err.response) {
        throw err.response;
      } else if (err.request) {
        throw err.request;
      } else {
        throw err;
      }
    });
}

export function switchTeam(id) {
  return client
    .post(SWITCH_TEAM_URL, {
      team_id: id
    })
    .catch(err => {
      if (err.response) {
        throw err.response;
      } else if (err.request) {
        throw err.request;
      } else {
        throw err;
      }
    });
}

export function convertErrorObject(obj) {
  return Object.values(obj).map(key => {
    return key.map(err => <div key={err}>{err}</div>);
  });
}
