import React, { useState, useEffect, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Spinner, Modal, Button } from "react-bootstrap";
import { useUpdateRole } from "../hooks";

export default function EditModel({ show, onHide, editableRow }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [customerKey, setCustomerKey] = useState(editableRow.key);
  const [role, setRole] = useState(editableRow.role);

  useEffect(() => {
    setCustomerKey(editableRow.key);

    setRole(editableRow.role);
  }, [editableRow]);
  const reset = () => {
    setRole("");
    setCustomerKey("");
  };

  const { updateRole, updating } = useUpdateRole(() => {
    onHide("success");
    reset();
  });

  const submitHandler = e => {
    e.preventDefault();

    updateRole({
      key: customerKey,
      role: role
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        animation
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{gettext("Edit Role")}</Modal.Title>
        </Modal.Header>
        <form onSubmit={submitHandler}>
          <Modal.Body>
            <div className="card-body">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>{gettext("Company Key")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="key"
                    name="key"
                    required
                    placeholder="Enter key"
                    value={customerKey}
                    onChange={e => setCustomerKey(e.target.value)}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>{gettext("Role")}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="CustomerKey"
                    name="CustomerKey"
                    placeholder="Enter Role"
                    value={role}
                    onChange={e => setRole(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              {gettext("Close")}
            </Button>
            <Button type="submit" variant="primary" onClick={onHide}>
              {gettext("Save Changes")}
            </Button>
          </Modal.Footer>
        </form>
        {updating ? (
          <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="light" />
          </div>
        ) : null}
      </Modal>
    </>
  );
}
