import { useQuery, useMutation } from "@apollo/client";
import {
  GET_PROVIDERS,
  ADD_PROVIDER,
  DELETE_PROVIDER,
  EDIT_PROVIDER
} from "./commands";

export function useProvidersList(searchQuery) {
  const { data, loading } = useQuery(GET_PROVIDERS, {
    fetchPolicy: "cache-and-network",
    variables: searchQuery
  });

  return {
    providersList: data?.comServiceProviders || [],
    loading
  };
}

export function useAddProvider(onCompleted = () => {}, onError = () => {}) {
  const [addProvider, { loading }] = useMutation(ADD_PROVIDER, {
    onCompleted,
    onError
  });

  const create = variables =>
    addProvider({ variables, refetchQueries: ["comServiceProviders"] });

  return {
    create,
    creating: loading
  };
}

export function useDeleteProvider(onCompleted = () => {}, onError = () => {}) {
  const [deleteProvider, { loading }] = useMutation(DELETE_PROVIDER, {
    onCompleted,
    onError
  });

  const del = variables =>
    deleteProvider({
      variables,
      refetchQueries: ["comServiceProviders"]
    });

  return {
    del,
    deleting: loading
  };
}

export function useEditProvider(onCompleted = () => {}, onError = () => {}) {
  const [editProvider, { loading }] = useMutation(EDIT_PROVIDER, {
    onCompleted,
    onError
  });

  const update = variables =>
    editProvider({ variables, refetchQueries: ["comServiceProviders"] });

  return {
    update,
    updating: loading
  };
}
