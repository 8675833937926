import { DASBOARD_FILTER_LS_KEY } from "app/common/Constants";
import { useCompanyBuildings } from "hooks";
import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useCallback
} from "react";
import { useTextdomainContext, useUser } from "../UserProvider";
import { usePublicAccess } from "./hooks";
import { getDashboardDates } from "app/common/Methods";

const DashboardFilterContext = createContext();

export function useDashboardFilter() {
  return useContext(DashboardFilterContext);
}

export default function DashboardFilterProvider({ children }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { user } = useUser();

  const { teams, currentTeam } = user || {};

  const dashboards = React.useMemo(
    () => [
      {
        catagory: gettext("Aggregated"),
        key: "1001",
        label: gettext("Total kWh")
      },
      {
        catagory: gettext("Aggregated"),
        key: "1002",
        label: gettext("Total kWh per energy source")
      },
      {
        catagory: gettext("Aggregated"),
        key: "1003",
        label: gettext("Total kWh/m2")
      },
      {
        catagory: gettext("Aggregated"),
        key: "1004",
        label: gettext("Total kWh per energy source/m2")
      },
      {
        catagory: gettext("Broadcasting"),
        key: "1005",
        label: gettext("Broadcasting")
      }
    ],
    [gettext]
  );

  const lsDasboardFilters = JSON.parse(
    localStorage.getItem(DASBOARD_FILTER_LS_KEY)
  );

  const [selectedDashboard, setselectedDashboard] = React.useState(
    lsDasboardFilters?.selectedDashboard || dashboards[0]
  );

  const [selectedCompanies, setSelectedCompanies] = React.useState(
    lsDasboardFilters?.selectedCompanies ||
      (!currentTeam
        ? [teams[0]]
        : teams.length
        ? [teams.find(team => team.name === currentTeam)]
        : [])
  );

  const selectedCompaniesString = selectedCompanies
    .map(item => item?.name)
    .join(",");

  const [selectedBuildings, setSelectedBuildings] = React.useState(
    lsDasboardFilters?.selectedBuildings || []
  );

  const selectedBuildingsString =
    selectedBuildings.length &&
    selectedBuildings.map(item => item?.address).join("|");

  const { buildings, loading } = useCompanyBuildings(
    selectedCompaniesString,
    buildings => {
      setSelectedBuildings(prevSelectedBuildings => {
        if (!buildings.length) return [];

        if (!prevSelectedBuildings.length) return [buildings[0]];

        const newSelectedBuildings = prevSelectedBuildings.filter(element => {
          const index = buildings.findIndex(
            building => building.address === element.address
          );
          if (index === -1) return false;
          return true;
        });

        const lsDasboardFilters = JSON.parse(
          localStorage.getItem(DASBOARD_FILTER_LS_KEY)
        );

        localStorage.setItem(
          DASBOARD_FILTER_LS_KEY,
          JSON.stringify({
            ...(lsDasboardFilters ? lsDasboardFilters : {}),
            selectedBuildings: newSelectedBuildings
          })
        );

        return newSelectedBuildings;
      });
    }
  );

  const changeDashboard = React.useCallback(dashboard => {
    setselectedDashboard(dashboard);

    const lsDasboardFilters = JSON.parse(
      localStorage.getItem(DASBOARD_FILTER_LS_KEY)
    );

    localStorage.setItem(
      DASBOARD_FILTER_LS_KEY,
      JSON.stringify({
        ...(lsDasboardFilters ? lsDasboardFilters : {}),
        selectedDashboard: dashboard
      })
    );
  }, []);

  const handleAddCompanies = React.useCallback(companies => {
    setSelectedCompanies(prevCompanies => {
      const newSelectedCompanies = [...prevCompanies, ...companies];

      const lsDasboardFilters = JSON.parse(
        localStorage.getItem(DASBOARD_FILTER_LS_KEY)
      );

      localStorage.setItem(
        DASBOARD_FILTER_LS_KEY,
        JSON.stringify({
          ...(lsDasboardFilters ? lsDasboardFilters : {}),
          selectedCompanies: newSelectedCompanies
        })
      );

      return newSelectedCompanies;
    });
  }, []);

  const handleRemoveCompany = React.useCallback(company => {
    setSelectedCompanies(prevCompanies => {
      const index = prevCompanies.findIndex(item => item.Id === company.Id);

      const newSelectedCompanies = [
        ...prevCompanies.slice(0, index),
        ...prevCompanies.slice(index + 1)
      ];

      const lsDasboardFilters = JSON.parse(
        localStorage.getItem(DASBOARD_FILTER_LS_KEY)
      );

      localStorage.setItem(
        DASBOARD_FILTER_LS_KEY,
        JSON.stringify({
          ...(lsDasboardFilters ? lsDasboardFilters : {}),
          selectedCompanies: newSelectedCompanies
        })
      );

      return newSelectedCompanies;
    });
  }, []);

  const handleAddBuildings = React.useCallback(buildings => {
    setSelectedBuildings(prevBuildings => {
      const newSelectedBuildings = [...prevBuildings, ...buildings];

      const lsDasboardFilters = JSON.parse(
        localStorage.getItem(DASBOARD_FILTER_LS_KEY)
      );

      localStorage.setItem(
        DASBOARD_FILTER_LS_KEY,
        JSON.stringify({
          ...(lsDasboardFilters ? lsDasboardFilters : {}),
          selectedBuildings: newSelectedBuildings
        })
      );

      return newSelectedBuildings;
    });
  }, []);

  const handleRemoveBuildings = React.useCallback(building => {
    setSelectedBuildings(prevBuildings => {
      const index = prevBuildings.findIndex(
        item => item.address === building.address
      );
      const newSelectedBuildings = [
        ...prevBuildings.slice(0, index),
        ...prevBuildings.slice(index + 1)
      ];

      const lsDasboardFilters = JSON.parse(
        localStorage.getItem(DASBOARD_FILTER_LS_KEY)
      );

      localStorage.setItem(
        DASBOARD_FILTER_LS_KEY,
        JSON.stringify({
          ...(lsDasboardFilters ? lsDasboardFilters : {}),
          selectedBuildings: newSelectedBuildings
        })
      );

      return newSelectedBuildings;
    });
  }, []);

  const {
    isDashboardPublic,
    publicURL,
    loading: publicLoading
  } = usePublicAccess(selectedDashboard);

  const wrapperRef = useRef(null);

  const { fromDate, toDate } = getDashboardDates();

  const value = {
    loading,
    wrapperRef,
    dashboards,
    selectedDashboard,
    changeDashboard,
    isDashboardPublic,
    publicURL,
    selectedCompanies,
    selectedCompaniesString,
    handleAddCompanies,
    handleRemoveCompany,
    buildings,
    selectedBuildings,
    selectedBuildingsString,
    handleAddBuildings,
    handleRemoveBuildings,
    publicLoading,
    fromDate,
    toDate
  };

  const resetFiltersOnLoginAs = useCallback(() => {
    setSelectedCompanies(
      !currentTeam
        ? [teams[0]]
        : teams.length
        ? [teams.find(team => team.name === currentTeam)]
        : []
    );

    setSelectedBuildings(buildings.length ? buildings[0] : []);
  }, [teams, buildings, currentTeam]);

  useEffect(() => {
    let selectedComExistInTeams = selectedCompanies.some(company =>
      teams.some(team => company.Id === team.Id)
    );
    if (!selectedComExistInTeams) {
      resetFiltersOnLoginAs();
    }
  }, [teams, selectedCompanies, resetFiltersOnLoginAs]);

  return (
    <DashboardFilterContext.Provider value={value}>
      {children}
    </DashboardFilterContext.Provider>
  );
}
