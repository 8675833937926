import { gql } from "@apollo/client";

export const GET_TEAM_ID = gql`
  query getTeamId($sef: String!) {
    getTeamId(sef: $sef) {
      teamId
    }
  }
`;

export const GET_ALL_TEAM_SETTINGS = gql`
  query getAllTeamSettings($teamId: String!) {
    getAllTeamSettings(teamId: $teamId) {
      teamId
      values {
        var
        value
      }
    }
  }
`;

export const GET_GRAPH_IMAGES = gql`
  query comGetGraphImages($customerId: String, $dashboard: String) {
    comGetGraphImages(customerId: $customerId, dashboard: $dashboard) {
      id
      customerId
      dashboard
      imageName
      title
      fileUrl
      dashboardName
      companies
      buildings
    }
  }
`;

export const ADD_ALL_TEAM_SETTINGS = gql`
  mutation addAllTeamSettings($teamId: String!, $values: [TeamSettingsInput]) {
    addAllTeamSettings(teamId: $teamId, values: $values) {
      message
      success
    }
  }
`;
