import { useQuery } from "@apollo/client";
import { GET_ALL_ROLES } from "../commands";

export function useAllRoles(role) {
  const { data, loading } = useQuery(GET_ALL_ROLES, {
    variables: {
      role
    }
  });
  return {
    allRoles: data?.comRoles || [],
    loading
  };
}
