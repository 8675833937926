import { gql } from "@apollo/client";

export const GET_ENERGY_PRICES = gql`
  query dataResourcePrice($dataDate: Date, $country: String!) {
    dataResourcePrice(dataDate: $dataDate, country: $country) {
      data {
        values
        yesterday
        today
        tomorrow
        countryCode
        message
      }
    }
  }
`;

export const GET_ENERGY_PRICES_FILE = gql`
  query dataResourcePrice(
    $dataDate: Date
    $country: String!
    $fileType: String
    $currency: String
  ) {
    dataResourcePrice(
      dataDate: $dataDate
      country: $country
      fileType: $fileType
      currency: $currency
    ) {
      fileUrl
    }
  }
`;

export const GET_BUILDING_ENERGY_PRICES = gql`
  query meterDataWithTaxes($date: Date, $buildingName: String!) {
    meterDataWithTaxes(date: $date, buildingName: $buildingName) {
      data {
        ts
        consumption
        serviceProviderFee
        price
        taxes
        totalPrice
        subscriptions
      }
    }
  }
`;

export const GET_BUILDING_ENERGY_PRICES_FILE = gql`
  query meterDataWithTaxes(
    $date: Date
    $buildingName: String!
    $fileType: String
  ) {
    meterDataWithTaxes(
      date: $date
      buildingName: $buildingName
      fileType: $fileType
    ) {
      fileUrl
    }
  }
`;
