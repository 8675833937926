import React, { useState, useEffect, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Modal, Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER } from "GraphQL/Mutations";

export default function EditCustomerForm({ show, onHide, editableRow }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [inputName, setinputName] = useState(editableRow.name);

  const [inputLabel, setinputLabel] = useState(editableRow.label);

  const [inputCVR, setinputCVR] = useState(editableRow.cvr);

  useEffect(() => {
    setinputName(editableRow.name);

    setinputLabel(editableRow.label);

    setinputCVR(editableRow.cvr);
  }, [editableRow]);

  const [updateCustomer, { loading }] = useMutation(UPDATE_CUSTOMER);

  const submitHandler = e => {
    e.preventDefault();

    updateCustomer({
      variables: {
        customerId: editableRow.Id,
        customerData: {
          name: inputName,
          label: inputLabel,
          cvr: inputCVR
        }
      },
      refetchQueries: ["getUserCustomers"]
    }).then(response => {
      if (response.data.comUpdateCustomer.success) {
        onHide();
      }
    });
  };

  return (
    <Modal
      show={true}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{gettext("Edit Company")}</Modal.Title>
      </Modal.Header>
      <form onSubmit={submitHandler}>
        <Modal.Body>
          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>{gettext("Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="Name"
                  name="Name"
                  required
                  placeholder="Enter Name"
                  value={inputName}
                  onChange={e => setinputName(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("Label")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="label"
                  name="label"
                  required
                  placeholder="Enter label"
                  value={inputLabel}
                  onChange={e => setinputLabel(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("CVR")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="CVR"
                  name="CVR"
                  required
                  placeholder="Enter CVR"
                  value={inputCVR}
                  onChange={e => setinputCVR(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {gettext("Close")}
          </Button>
          <Button type="submit" variant="primary" disabled={loading}>
            {gettext("Save Changes")}
          </Button>
        </Modal.Footer>
      </form>
      {/* {loading || countriesLoading || timezonesLoading ? (
        <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : null} */}
    </Modal>
  );
}
