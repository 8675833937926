import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Modal, Button, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js";
import { useUpdateUser } from "../hooks";

export default function EditModel({ show, onHide, editableRow }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [Password, setPassword] = useState();

  const [passwordShown, setPasswordShown] = useState(false);

  const { updateUser, updating } = useUpdateUser(() => {
    onHide("success");
  });

  const handleClick = e => {
    e.preventDefault();
    updateUser({
      userId: editableRow.id,
      password: Password
    });
  };

  const togglePassword = () => {
    setPasswordShown(prev => !prev);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={handleClick}>
        <Modal.Header closeButton>
          <Modal.Title>
            {gettext("Change @user's Password").replace(
              "@user",
              editableRow.name
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card-body">
            {/* <form onSubmit={handleClick}> */}
            <div className="form-group  col-md-12">
              <label>{gettext("New Password")}</label>
              <div className="form-group fv-plugins-icon-container">
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form-control"
                  id="Password"
                  name="Password"
                  required
                  placeholder={gettext("New Password")}
                  onChange={e => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="show-hide-password user"
                  onClick={togglePassword}
                >
                  <Icon
                    path={passwordShown ? mdiEyeOutline : mdiEyeOffOutline}
                    title="Password"
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="#7a6969"
                  />
                </button>
              </div>
            </div>
            {/* </form> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {gettext("Close")}
          </Button>
          <Button variant="primary" type="submit">
            {gettext("Save Changes")}
          </Button>
        </Modal.Footer>
      </form>
      {updating ? (
        <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : null}
    </Modal>
  );
}
