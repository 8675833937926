/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { SubMenuItem } from "./SubMenuItem";
import { useUser, useTextdomainContext } from "app/providers";
import { CanDisplay } from "app/common/Methods";

export function ProfileCard() {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [role, setRole] = useState("");
  const [photo, setPhoto] = useState("");

  const { user } = useUser();

  useEffect(() => {
    if (user.profilePhotoUrl) {
      // setPhoto(process.env.REACT_APP_API_URL + "user" + user.profilePhotoUrl);
      setPhoto(user.profilePhotoUrl);
    }
    setRole(user.roles?.map(role => role.name).join(", "));
  }, [user]);

  return (
    <>
      {user && (
        <div
          className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
          id="kt_profile_aside"
        >
          <div className="card card-custom card-stretch">
            <div className="card-body pt-4">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-150 symbol-xxl-100 mx-auto align-self-start align-self-xxl-center">
                  <div
                    className="symbol-label"
                    style={{
                      backgroundImage: `url(${photo})`
                    }}
                  ></div>
                  <i className="symbol-badge bg-success"></i>
                </div>
                <div></div>
              </div>
              <div className="py-9">
                <div className="d-flex align-items-center mb-2">
                  <span className="w-50 font-weight-bold mr-2">
                    {gettext("Name")}:
                  </span>
                  <span className="w-75 text-muted text-right text-break">
                    {user.name}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="w-50 font-weight-bold mr-2">
                    {gettext("Email")}:
                  </span>
                  <span className="w-75 text-muted text-right text-break">
                    {user.email}
                  </span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="w-50 font-weight-bold mr-1">
                    {gettext("Company")}:
                  </span>
                  <span className="w-75 text-muted text-right text-break text-hover-primary">
                    <Link to={"/user-profile/customers"}>
                      {user.currentTeam}
                    </Link>
                  </span>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <span className="w-50 font-weight-bold mr-2">
                    {gettext("Role")}:
                  </span>
                  <span className="w-100 text-muted text-right">{role}</span>
                </div>
              </div>
              <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                <SubMenuItem
                  link={"/user-profile/personal-information"}
                  svg={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  label={gettext("Profile")}
                  code="my-profile"
                />

                <SubMenuItem
                  link={"/user-profile/change-password"}
                  svg={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  label={gettext("Change Password")}
                  code="my-password"
                />

                <SubMenuItem
                  link={"/user-profile/customers"}
                  svg={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  label={gettext("Companies")}
                  code="my-customers"
                />

                {CanDisplay("TEAM_SETTING") && (
                  <SubMenuItem
                    link={"/user-profile/customer-roles"}
                    svg={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                    label={gettext("Company Roles")}
                  />
                )}

                <SubMenuItem
                  link={"/user-profile/api-key"}
                  svg={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  label={gettext("API KEY")}
                  code="api-key"
                />

                <SubMenuItem
                  link={"/user-profile/settings"}
                  svg={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  label={gettext("Settings")}
                  code="settings"
                />

                <SubMenuItem
                  link={"/logout"}
                  svg={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  label={gettext("Sign Out")}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
