import React, { useEffect } from "react";
import { LayoutSplashScreen } from "_metronic/layout";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "app/providers";

export function PartnerAccess() {
  const { token } = useParams();
  const { setToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      setToken(token);
      navigate("/");
    } else {
      navigate("/auth/login", {
        replace: true
      });
    }
  }, [navigate, setToken, token]);

  return <LayoutSplashScreen />;
}
