import { gql } from "@apollo/client";

export const GET_ROLE_MODULES = gql`
  query getRoleModules($role: String) {
    comRoles(role: $role) {
      id
      role
      isSystem
      modules {
        name
        code
        read
        write
        delete
      }
      createdAt
      updatedAt
    }
  }
`;
