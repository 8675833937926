import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { usePagePrivileges } from "../../../../hooks/privileges";

export function SubMenuItem(props) {
  const { link, svg, label, code } = props;
  const [displayOption, setDisplayOption] = useState(false);
  const [_read, _write] = usePagePrivileges(code);

  let active = "";
  if (window.location.pathname === link) {
    active = "active";
  }

  useEffect(() => {
    if (_read || _write) {
      setDisplayOption(true);
    } else if (code === undefined) {
      setDisplayOption(true);
    } else {
      setDisplayOption(false);
    }
  }, [_read, _write, code]);

  return (
    <>
      {displayOption && (
        <div className="navi-item mb-2">
          <NavLink
            to={link}
            className="navi-link py-4"
            activeClassName={active}
          >
            <span className="navi-icon mr-2">
              <span className="svg-icon">
                <SVG src={svg}></SVG>
              </span>
            </span>
            <span className="navi-text font-size-lg">{label}</span>
          </NavLink>
        </div>
      )}
    </>
  );
}
