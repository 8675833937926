import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import { Spinner, Modal, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useUpdateMeter } from "./hooks";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { useProvidersList } from "app/pages/admin/service-providers/hooks";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { useCustomersBuildings } from "app/modules/RegistrationFlow/hooks";
import { useMutation } from "@apollo/client";
import { MOVE_METER } from "./commands";

export function EditMeter({
  show,
  onHide,
  editableRow,
  editCustomer,
  buildingAddress
}) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);
  const [oldBuildingAddress, setOldBuildingAddress] = useState(buildingAddress);

  const [customerBuilding, setCustomerBuilding] = useState([]);

  const [buildingName, setBuildingName] = useState(buildingAddress);
  const { buildings } = useCustomersBuildings(editCustomer);

  const { type, bid } = useParams();

  const [moveMeter] = useMutation(MOVE_METER, {
    onCompleted() {},
    refetchQueries: ["getBuildingMeters"]
  });

  const [inputMeterNumber, setinputMeterNumber] = useState("");
  // const [selectMeterType, setSelectMeterType] = useState("heating");

  const [inputMeterLabel, setinputMeterLabel] = useState("");
  const [provider, setProvider] = useState("");
  const [providerUsername, setProviderUsername] = useState("");
  const [providerPassword, setProviderPassword] = useState("");

  const { providersList } = useProvidersList({ category: type });

  const [showErrorMessage, setshowErrorMessage] = useState(false);

  const { update, loading } = useUpdateMeter(
    () => {
      setshowErrorMessage(false);
      setOldBuildingAddress("");
      onHide();
    },
    () => {
      setshowErrorMessage(true);
      setTimeout(() => {
        setshowErrorMessage(false);
      }, 5000);
    }
  );

  //   Select Buildings    //

  useEffect(() => {
    let data = [];
    buildings.forEach(item => {
      data.push({
        value: item.address
      });
    });
    data.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });
    setCustomerBuilding(data);
  }, [buildings]);

  const handleChangeBuilding = event => {
    setBuildingName(event.target.value);
  };

  useEffect(() => {
    if (editableRow !== null && Object.keys(editableRow).length) {
      setinputMeterNumber(editableRow?.meterNo);
      // setSelectMeterType(editableRow?.meterType);
      setinputMeterLabel(editableRow?.label);
      if (editableRow.supplier) {
        const supplier = providersList.filter(
          p => p.name === editableRow.supplier
        )[0];
        if (supplier) {
          setProvider(supplier);
          setProviderUsername(editableRow?.providerUsername);
          setProviderPassword(editableRow?.providerPassword);
        }
      } else {
        setProvider(null);
        setProviderUsername("");
        setProviderPassword("");
      }
    }

    // setinputSupplier(
    //   supplierOptions?.find(element => element?.name === editableRow?.supplier)
    // );
  }, [editableRow, providersList]);

  // const handleOptionSelect = (event) => {
  //     setSelectMeterType(event.target.value);
  // };

  const submitHandler = e => {
    e.preventDefault();
    setshowErrorMessage(false);
    let dt = {
      type: editableRow?.meterType,
      supplier: provider?.name || null,
      meterNo: inputMeterNumber?.toString(),
      meterId: editableRow?.meterId,
      oldMeterno: editableRow?.meterNo,
      label: inputMeterLabel,
      providerUsername: providerUsername,
      providerPassword: providerPassword
    };
    update({
      buildingId: bid,
      data: dt
    });
    moveMeter({
      variables: {
        addressName: oldBuildingAddress,
        data: {
          targetAddressName: buildingName,
          // meterType: type === "heating" ? selectMeterType : type,
          meterType: type,
          meterNo: inputMeterNumber?.toString()
        }
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{gettext("Edit Meter")}</Modal.Title>
      </Modal.Header>
      <form onSubmit={submitHandler}>
        <Modal.Body>
          {
            <Collapse in={showErrorMessage}>
              <Alert severity="error">
                <AlertTitle>{gettext("Prohibited")}</AlertTitle>
                {gettext("Data Exists for this Meter")}
              </Alert>
            </Collapse>
          }
          <div className="card-body">
            <div className="form-group">
              <label>{gettext("Meter No")}</label>
              <input
                type="text"
                className="form-control"
                id="meterNumber"
                name="meterNumber"
                required
                placeholder="Enter Meter Numer"
                value={inputMeterNumber}
                onChange={e => setinputMeterNumber(e.target.value)}
                autoComplete="off"
                disabled
              />
            </div>
            <div className="form-group">
              <label>{gettext("Meter Label")}</label>
              <input
                type="text"
                className="form-control"
                id="meterLabel"
                name="meterLabel"
                required
                placeholder={gettext("Enter Meter Label")}
                value={inputMeterLabel}
                onChange={e => setinputMeterLabel(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="form-group">
              <label>{gettext("Service Provider")}</label>
              <Autocomplete
                disablePortals
                id="providers-selection"
                getOptionLabel={option => option.name || ""}
                value={provider}
                isOptionEqualToValue={(option, value) => {
                  return option.Id === value.Id;
                }}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === "clear") {
                    setProvider(null);
                    setProviderUsername("");
                    setProviderPassword("");
                    return;
                  } else {
                    const supplier = providersList.filter(
                      p => p.name === newInputValue
                    )[0];
                    if (supplier) {
                      setProvider(supplier);
                    }
                  }
                }}
                inputProps={{
                  form: {
                    autoComplete: "off"
                  }
                }}
                options={providersList}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ backgroundColor: "white" }}
                  />
                )}
              />
              <span id="providerSelection" class="form-text">
                Want to add a new provider?{" "}
                <Link to={`/admin/service-providers`}>click here</Link>
              </span>
            </div>
            <div className="form-group">
              <label>{gettext("Provider Username")}</label>
              <input
                type="text"
                disabled={(providersList && !providersList.length) || !provider}
                className="form-control"
                id="providerUsername"
                name="providerUsername"
                placeholder={gettext("Enter Provider Username")}
                value={providerUsername}
                onChange={e => setProviderUsername(e.target.value)}
                required={provider ? true : false}
                autoComplete="off"
              />
            </div>
            <div className="form-group">
              <label>{gettext("Provider Password")}</label>
              <input
                type="password"
                disabled={(providersList && !providersList.length) || !provider}
                className="form-control"
                id="providerPassword"
                name="providerPassword"
                placeholder={gettext("Enter Provider Password")}
                value={providerPassword}
                onChange={e => setProviderPassword(e.target.value)}
                required={provider ? true : false}
                autoComplete="new-password"
              />
            </div>
            {/*S
            {type === "heating" && (
              <div className="form-group">
                <label>{gettext("Select Meter")}</label>
                <Select
                  fullWidth
                  className="meter-dropdown"
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={selectMeterType}
                  onChange={handleOptionSelect}
                >
                  <MenuItem value={"heating"}>
                    {gettext("District  Heating")}
                  </MenuItem>
                  <MenuItem value={"gas"}>{gettext("Natural Gas")}</MenuItem>
                </Select>
              </div>
            )} */}
            <div className="form-group">
              <label>{gettext("Select Building")}</label>
              <Box>
                <FormControl fullWidth>
                  <Select
                    style={{
                      height: 38,
                      backgroundColor: "white"
                    }}
                    displayEmpty
                    input={<OutlinedInput />}
                    value={buildingName}
                    onChange={handleChangeBuilding}
                    MenuProps={{
                      PaperProps: {
                        sx: { maxHeight: 400 }
                      }
                    }}
                    inputProps={{
                      "aria-label": "Without label"
                    }}
                    required
                  >
                    {customerBuilding.map(e => {
                      return <MenuItem value={e.value}>{e.value}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>

            {/* <div className="form-group">
              <label>
                {gettext('Supplier')}
              </label>
              <Select
                options={supplierOptions}
                isLoading={supplierLoading}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.name}
                value={inputSupplier}
                onChange={opt => setinputSupplier(opt)}
                required
              />
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {gettext("Close")}
          </Button>
          <Button type="submit" variant="primary">
            {loading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              gettext("Save Changes")
            )}
          </Button>
        </Modal.Footer>
      </form>
      {/* {updating || countriesLoading || timezonesLoading ? (
        <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : null} */}
    </Modal>
  );
}
