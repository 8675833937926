import { Box, Button } from "@mui/material";
import { useTextdomainContext } from "app/providers";
import React from "react";
import { useBuildingStep } from "../../providers";

export function Footer() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { skip, next } = useBuildingStep();

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        disableElevation
        sx={{ py: 1.5, minWidth: "200px" }}
        disabled={false}
        size="large"
        type="submit"
        variant="outlined"
        color="secondary"
        onClick={() => skip()}
      >
        {gettext("Skip")}
      </Button>

      <Button
        disableElevation
        sx={{ py: 1.5, minWidth: "200px" }}
        disabled={false}
        size="large"
        variant="contained"
        color="secondary"
        onClick={() => next()}
      >
        {gettext("Done")}
      </Button>
    </Box>
  );
}
