import React, { useState, useContext, useEffect } from "react";
import { useLang, useTextdomainContext } from "app/providers";
import { withRouter } from "../../../../utils";
// import { Modal, Button } from "react-bootstrap";
import CustomTable from "../../../components/CustomTable";
// import { NavLink } from "react-router-dom";

const Transactions = props => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const locale = useLang();
  const sd = sessionStorage.getItem("transaction");
  const plan = JSON.parse(sd);
  const td = plan
    ? plan
    : {
        title: "Transactions",
        heading: ["Name", "From", "To", "Created", "Changed", "", ""],
        content: [
          [
            "increment year from 2016 through 2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit"
          ],
          [
            "increment year from 2016 through 2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit"
          ],
          [
            "increment year from 2016 through 2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit"
          ],
          [
            "increment year from 2016 through 2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit"
          ],
          [
            "increment year from 2016 through 2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit"
          ],
          [
            "increment year from 2016 through 2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit"
          ],
          [
            "increment year from 2016 through 2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit"
          ]
        ]
      };

  const [tableData, setTableData] = useState(td);
  // const [showModal, setShowModal] = useState(false);
  // const [showActivePopup, setShowActivePopup] = useState('Hide');
  // const [selectedPlanIndex, setSelectedPlanIndex] = useState('');
  // const [fileName, setFileName] = useState('');
  // const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    sessionStorage.setItem("transaction", JSON.stringify(tableData));

    const sd = sessionStorage.getItem("transaction");
    const plan = JSON.parse(sd);

    if (plan) {
      setTableData(plan);
    }
  }, [tableData]);

  const onAddTap = () => {
    props.navigate("/corporate-co2/transactions-edit", {
      state: {
        type: "add",
        data: ["", "", "", "", "", "View", "Edit"],
        index: 0
      } // your data array of objects
    });
  };

  // onActiveTap = (data, index) => {
  //     this.setState({ selectedPlanIndex: index, showActivePopup: 'Active' })
  // }

  const onViewTap = (data, index) => {
    // console.log('View, ', data, index)
    props.navigate("/corporate-co2/transactions-edit", {
      state: { type: "view", data: data, index: index }
    });
  };

  const onEditTap = (data, index) => {
    // console.log('edit ', data, index)
    props.navigate("/corporate-co2/transactions-edit", {
      state: { type: "edit", data: data, index: index } // your data array of objects
    });
  };

  // onTickTap = (data, index) => {
  //     this.setState({ selectedPlanIndex: index, showActivePopup: 'InActive' })
  // }

  // hideModal = () => {
  //     this.fileInput.value = null
  //     this.setState({ showModal: false, percentage: 0, fileName: '' })
  // }

  // onFileSelect = (e) => {
  //     let files = e.target.files;
  //     console.log('file-reader ', files[0])
  //     this.setState({ fileName: files.name })
  // }

  // onUpload = () => {
  //     const random = Math.floor(Math.random() * (70 - 30 + 1)) + 30;
  //     this.setState({ percentage: random }, () => {
  //         setTimeout(() => {
  //             this.setState({ percentage: 100 })
  //         }, 800)
  //     })
  // }

  // handleActiveClose = (type) => {
  //     const { selectedPlanIndex, showActivePopup } = this.state;
  //     if (type === 'Y') {
  //         let updatedData = this.state.tableData
  //         updatedData.content.forEach((d, index) => {
  //             // d[0] = (index === selectedPlanIndex)
  //             d[5] = (index === selectedPlanIndex) ? (showActivePopup === 'InActive' ? 'Activate' : "InActivated") : "Activate"
  //         })
  //         this.setState({ tableData: updatedData })
  //     }
  //     this.setState({ showActivePopup: 'Hide' })
  // }

  // renderActivePopup = () => {
  //     const { showActivePopup, selectedPlanIndex, tableData } = this.state;
  //     const heading = (showActivePopup === 'Active') ? 'Are you sure you want to change the active account plan?' : (showActivePopup === 'InActive') ? 'Are you sure you want to inactive the account plan?' : ''
  //     return (
  //         <Modal show={showActivePopup === 'Hide' ? false : true} onHide={() => this.handleActiveClose('N')}
  //             size="lg"
  //             aria-labelledby="contained-modal-title-vcenter"
  //             centered
  //         >
  //             <Modal.Title style={{ marginLeft: 15, marginTop: 15, marginBottom: -10 }}>{selectedPlanIndex !== '' ? tableData.content[selectedPlanIndex][0] : ''}</Modal.Title>
  //             <Modal.Body>{heading}</Modal.Body>
  //             <Modal.Footer>
  //                 <Button variant="secondary" onClick={() => this.handleActiveClose('N')}>
  //                     No
  //     </Button>
  //                 <Button style={{ backgroundColor: '#46763c', color: 'white', border: 0 }} variant="primary" onClick={() => this.handleActiveClose('Y')}>
  //                     Yes
  //     </Button>
  //             </Modal.Footer>
  //         </Modal>
  //     )
  // }

  return (
    <div className="account-link-container">
      <div className="card card-custom gutter-b example example-compact">
        <div className="row">
          <button className="account-table-btn mr53" onClick={onAddTap}>
            {gettext("Add")} +
          </button>
        </div>

        <div className="table-shift-up">
          <CustomTable
            energySource="all"
            locale={locale}
            type="account-plan"
            onViewTap={(data, index) => onViewTap(data, index)}
            onEditTap={(data, index) => onEditTap(data, index)}
            // onActiveTap={(data, index) => this.onActiveTap(data, index)}
            // onTickTap={(data, index) => this.onTickTap(data, index)}
            tableData={tableData}
            gettext={gettext}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Transactions);
