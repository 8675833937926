import React, { useEffect } from "react";
import { injectIntl } from "react-intl";

function EpcValue({ value, position, name, color }) {
  useEffect(() => {
    document.querySelector(
      `.js-box-${name}`
    ).style.transform = `translate(${0}%, ${position}%)`;
  }, [position, name]);

  return (
    <>
      <div className={`d-flex mw-0 text-white js-box-${name}`}>
        {value !== "0.00" && (
          <div
            className="col-12 p-2 m-2 h-100 text-center arrowhead"
            style={{ background: color }}
          >
            <div>{value}</div>
          </div>
        )}
      </div>
    </>
  );
}

export default injectIntl(EpcValue);
