import React from "react";
import { EmissionGroupsListTable } from "../../../features";

export function EmissionGroups() {
  return (
    <>
      <EmissionGroupsListTable />
    </>
  );
}
