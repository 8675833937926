import { useQuery } from "@apollo/client";
import { GET_COMPANIES_BUILDINGS } from "./commands";

export function useCompanyBuildings(
  customerNamesString,
  onCompleted = () => {},
  onError = () => {}
) {
  const { data, loading } = useQuery(GET_COMPANIES_BUILDINGS, {
    variables: {
      customerName: customerNamesString
    },
    onCompleted: ({ comCustomerAddresses }) => {
      onCompleted(comCustomerAddresses || []);
    },
    onError
  });

  return {
    buildings: data?.comCustomerAddresses || [],
    loading
  };
}
