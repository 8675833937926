import { gql } from "@apollo/client";

export const ME_TYPE_FRAGMENT = gql`
  fragment MeType on GGMeType {
    id
    name
    email
    status
    partner
    demo
    currentTeamId
    currentTeam
    customerKey
    facebookLink
    twitterLink
    lang
    profilePhotoPath
    profilePhotoUrl
    provider
    countryCode
    timezone
    customerType
    roles
    teams {
      Id
      name
      role
      label
      cvr
      type
      address
      read
      write
      owner
    }
    modulePermissions {
      name
      code
      read
      write
      delete
    }
    registrationStep
    isRegistered
    isVerified
    isSuperAdmin
    colorSettings
    __typename
  }
`;
