import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton
} from "@mui/material";

export const BenchmarkTablesLoading = () => {
  // Define the number of rows and columns for your table
  const numRows = 6;
  const numCols = 3;

  // Render a single table cell with Skeleton
  const renderTableCell = () => {
    return (
      <TableCell>
        <Skeleton animation="wave" />
      </TableCell>
    );
  };

  // Render a single table row with multiple cells
  const renderTableRow = () => {
    const cells = [];

    for (let i = 0; i < numCols; i++) {
      cells.push(renderTableCell());
    }

    return <TableRow>{cells}</TableRow>;
  };

  // Render the entire loading table
  const renderLoadingTable = () => {
    const rows = [];

    for (let i = 0; i < numRows; i++) {
      rows.push(renderTableRow());
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            {<TableCell></TableCell>}
            {renderTableCell()}
            {renderTableCell()}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  };

  return renderLoadingTable();
};
