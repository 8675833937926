import { BarChart } from "app/components";
import { useTextdomainContext } from "app/providers";
import React from "react";
import { useEpcData } from "../../hooks";

export function EpcChart() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { catagories, values, colors, loading } = useEpcData();

  return (
    <BarChart
      title={gettext("EPC")}
      catagories={catagories}
      data={[
        {
          name: gettext("EPC"),
          data: values
        }
      ]}
      colors={colors}
      loading={loading}
    />
  );
}
