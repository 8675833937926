import { Autocomplete, TextField } from "@mui/material";
import { useCountries } from "hooks";
import React, { useEffect, useState } from "react";

export default function CountryDropdown(props) {
  const { value, onChange, placeholder, disabled } = props;

  const { countries, loading: countriesLoading } = useCountries();

  const [companyCountryValue, setCompanyCountryValue] = useState(null);

  useEffect(() => {
    if (Boolean(value))
      setCompanyCountryValue(
        countries.find(country => {
          return value === country.code;
        })
      );
    else setCompanyCountryValue(null);
  }, [value, countries]);

  return (
    <Autocomplete
      disablePortal
      fullWidth
      size="small"
      disabled={disabled}
      options={countries}
      getOptionLabel={option => option.name}
      loading={countriesLoading}
      value={companyCountryValue}
      onChange={(e, newValue) => onChange(newValue.code)}
      renderInput={params => (
        <TextField {...params} required label={placeholder} />
      )}
    />
  );
}
