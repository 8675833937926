import { Box, Button, OutlinedInput, Stack } from "@mui/material";
import {
  useDashboardFilter,
  useTextdomainContext,
  useUser
} from "app/providers";
import React from "react";
import { IoAdd } from "react-icons/io5";
import { useAddDescription } from "./hook";

export function DescriptionControl({ isPublic }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { user } = useUser();

  const { currentTeamId } = user || {};

  const [description, setDescription] = React.useState("");

  const [isAdding, setisAdding] = React.useState(false);

  const { isDashboardPublic } = useDashboardFilter();

  const { addDescription } = useAddDescription();

  const addHandler = React.useCallback(() => {
    addDescription({
      teamId: currentTeamId,
      values: [
        {
          var: "description",
          value: description
        }
      ]
    });
  }, [addDescription, currentTeamId, description]);

  return isAdding ? (
    <Stack spacing={2}>
      <OutlinedInput
        sx={{ color: "secondary.main" }}
        fullWidth
        multiline
        rows={5}
        size="small"
        placeholder={gettext("Add Description")}
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={() => setisAdding(false)}
        >
          {gettext("Close")}
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={addHandler}
        >
          {gettext("Add")}
        </Button>
      </Box>
    </Stack>
  ) : (
    <Box sx={{ display: "flex" }}>
      <Button
        variant="text"
        color="secondary"
        startIcon={<IoAdd />}
        disabled={!isDashboardPublic}
        onClick={() => setisAdding(true)}
      >
        {gettext("Add Description")}
      </Button>
    </Box>
  );
}
