import { Button, Chip, Menu, Skeleton, Typography } from "@mui/material";
import React from "react";
import { IoChevronDown } from "react-icons/io5";
import { MenuContent } from "./components";

export function FilterSelectButton(props) {
  const {
    label,
    loading,
    startIcon,
    options,
    getOptionLabel,
    value,
    onChange,
    searchable
  } = props;

  const selectOptionLabel = React.useCallback(
    option => {
      if (typeof getOptionLabel === "undefined") {
        return option;
      } else return getOptionLabel(option);
    },
    [getOptionLabel]
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {loading ? (
        <Skeleton animation="pulse" width={150} />
      ) : (
        <Button
          id="fabeke-select-component"
          size="small"
          aria-controls={open ? "fabeke-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          startIcon={startIcon}
          endIcon={<IoChevronDown size={12} />}
          color="secondary"
          onClick={handleClick}
        >
          {label}
          <Chip
            sx={{
              ml: 0.5,
              maxWidth: "100px"
            }}
            color={"primary"}
            size="small"
            label={
              <Typography noWrap maxLines={1}>
                {selectOptionLabel(value)}
              </Typography>
            }
          />
        </Button>
      )}

      <Menu
        id="fabeke-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "fabeke-select-component",
          sx: { p: 0 }
        }}
        PaperProps={{
          sx: { p: 1, borderRadius: "8px", minWidth: "150px" }
        }}
      >
        <MenuContent
          searchable={searchable}
          value={value}
          onchange={newValue => onChange(newValue)}
          options={options}
          selectOptionLabel={selectOptionLabel}
        />
      </Menu>
    </>
  );
}
