const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles(theme => ({
  skeleton: {
    minHeight: "600px"
  },
  currencyDropdown: {
    float: "right",
    marginTop: "-25px",
    fontWeight: 550,
    "&:hover": {
      background: "#E6EEEA!important",
      border: "1px solid #B3CDC1!important"
    },
    border: "1px solid transparent!important",
    borderRadius: "8px!important",
    padding: "0 8px 0 8px!important",
    fontSize: "14px!important"
  }
}));

export default useStyles;
