import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
  circularProgressClasses
} from "@mui/material";
import { useEffect, useState } from "react";
import { IoCheckmark, IoChevronDown } from "react-icons/io5";

const NewTableActions = props => {
  const {
    gettext,
    title,
    data,
    onClick,
    bytype,
    width,
    loading,
    showExported
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedValue, setSelectedValue] = useState(bytype || "");

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = option => {
    setSelectedValue(option.value);
    onClick(option);
    // if (loading) {
    //   handleClose();
    // }
  };
  useEffect(() => {
    if (!loading) {
      handleClose();
    }
  }, [loading]);

  return (
    <Box pl={2}>
      <Button
        id="basic-button"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<IoChevronDown size={12} />}
        onClick={handleClick}
        sx={{
          "&:hover": {
            backgroundColor: "#E6EEEA",
            color: "#015731",
            border: "1px solid #B3CDC1"
          },
          backgroundColor: open ? "#E6EEEA" : null,
          color: open ? "#015731" : "#27272A",
          border: open ? "1px solid #B3CDC1" : "none"
        }}
      >
        <Typography fontSize={14} fontWeight={500}>
          {title}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{
          "& .MuiMenu-paper": {
            backgroundColor: "#FFFFFF",
            padding: 0,
            margin: 0.5,
            minWidth: width ? width : "151px",
            borderRadius: "8px",
            boxShadow:
              "0px 10px 15px -3px #0000001A ,0px 4px 6px -2px #0000000F"
            // boxShadow: "0px 4px 6px -2px #0000000F"
          },
          "& .MuiMenuItem-root": {
            fontWeight: 400,
            fontSize: "14px",
            height: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          },
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: "#FFFFFF",
            fontWeight: 400,
            fontSize: "14px",
            color: "#015731"
          }
        }}
      >
        {data.map(option => (
          <MenuItem
            key={option.value}
            selected={bytype && selectedValue === option.value}
            value={option.value}
            onClick={() => handleMenuItemClick(option)}
          >
            {gettext(option.label)}
            {bytype && selectedValue === option.value && (
              <IoCheckmark size={16} color="#015731" />
            )}
            {showExported && loading && selectedValue === option.value && (
              <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                  color: theme =>
                    theme.palette.mode === "light" ? "#015731" : "#015731",
                  animationDuration: "600ms",
                  position: "absolute",
                  left: 125,
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round"
                  }
                }}
                size={15}
                thickness={4}
                {...props}
              />
            )}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
export default NewTableActions;
