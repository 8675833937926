import { styled, TableBody } from "@mui/material";

export const BorderedTableBody = styled(TableBody)(({ theme }) => ({
  position: "relative",
  "&:after": {
    content: '""',
    backgroundColor: theme.palette.secondary[200],
    position: "absolute",
    right: "100%",
    top: "15px",
    bottom: "15px",
    width: "1px"
  }
}));
