import React, { Component } from "react";
import { Table } from "react-bootstrap";
import "../../../../../index.scss";
import "react-datepicker/dist/react-datepicker.css";
// import Select from "react-select";
import download from "downloadjs";
import client from "services/apiClient";
import NewTableActions from "./components/NewTableActions";

const { REACT_APP_EXCEL_END_URL } = process.env;

// const selectStyles = {
//   container: base => ({
//     ...base,
//     minWidth: 120,
//     fontSize: 14,
//     fontWeight: "600",
//     flex: 1,
//     color: "black"
//   }),
//   control: (baseStyles, state) => ({
//     ...baseStyles,
//     color: state.isFocused || state.isSelected ? "#015731" : "black",
//     borderStyle:
//       state.isFocused || state.isSelected ? "border-green-200" : "none",
//     backgroundColor: state.isFocused || state.isSelected ? "#e6eeea" : "none"
//   })
// };

class NewEnergyCharts extends Component {
  active = "Activate";
  inActive = "InActivated";

  constructor(props) {
    super(props);
    let exportFilename = "table-data-" + Date.now();

    // let energySource = props.energySource;
    if (props.exportFilename) {
      exportFilename = props.exportFilename;
    }

    let benchmarkDropDown = [
      {
        value: "excel",
        label: this.props.gettext("Excel")
      },
      {
        value: "csv",
        label: this.props.gettext("CSV")
      }
    ];

    let electricityDropDown = [
      {
        value: "excel",
        label: this.props.gettext("Excel")
      },
      {
        value: "csv",
        label: this.props.gettext("CSV")
      },
      {
        value: "excel-hourly",
        label: this.props.gettext("Excel Hourly")
      },
      {
        value: "csv-hourly",
        label: this.props.gettext("CSV Hourly")
      }
    ];

    let typeDropDown = [
      {
        value: "consumption",
        label: this.props.gettext("Consumption")
      },
      {
        value: "compensation",
        label: this.props.gettext("Compensation")
      }
    ];
    let energyTypeDropDown = [
      {
        value: "consumption",
        label: this.props.gettext("Consumption")
      },
      {
        value: "production",
        label: this.props.gettext("Production")
      },
      {
        value: "compensation",
        label: this.props.gettext("Compensation")
      }
    ];

    this.state = {
      // download: electricityDropDown, // energySource === 'electricity' ? electricityDropDown : allDropDown,
      typeDropDown: typeDropDown,
      energyTypeDropDown: energyTypeDropDown,
      byType: "consumption",
      tableData: props.tableData["consumption"],
      download:
        props.energySource === "all" ? benchmarkDropDown : electricityDropDown,
      downloadCode: "download",
      downloadName: this.props.gettext("Download"),
      byTypeName: this.props.gettext("By Type"),
      exportFilename: exportFilename,
      tableDataHourly: { title: "", heading: [], content: [] }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.exportFilename !== this.props.exportFilename) {
      this.setState({ exportFilename: this.props.exportFilename });
    }

    if (prevProps.tableDataHourly !== this.props.tableDataHourly) {
      this.setState({
        tableDataHourly: this.props.tableDataHourly
      });
    }

    if (prevProps.tableData !== this.props.tableData) {
      this.setState({
        tableData: this.props.tableData[this.state.byType]
      });
    }

    if (
      prevProps.gettext("Download") !== this.props.gettext("Download") ||
      prevProps.gettext("By Type") !== this.props.gettext("By Type")
    ) {
      this.setState({
        downloadName: this.props.gettext("Download"),
        byTypeName: this.props.gettext("By Type")
      });
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   this.setState({ exportFilename: nextProps.exportFilename });
  //   if (this.state.tableDataHourly !== nextProps.tableDataHourly) {
  //     this.setState({
  //       ...this.state,
  //       tableDataHourly: nextProps.tableDataHourly
  //     });
  //   }

  //   if (this.state.tableData !== nextProps.tableData) {
  //     this.setState({
  //       ...this.state,
  //       tableData: nextProps.tableData[this.state.byType]
  //     });
  //   }
  //   this.setState({
  //     ...this.state,
  //     downloadName: this.props.gettext("Download"),
  //     byTypeName: this.props.gettext("By Type")
  //   });
  // }

  handleChangeType = selectedOption => {
    if (selectedOption.value) {
      this.setState({
        ...this.state,
        tableData: this.props?.tableData[selectedOption.value],
        byType: selectedOption.value
      });
    }
  };

  handleChangeDownload = (selectedOption, title) => {
    const { handleDownloadFile } = this.props;
    if (handleDownloadFile) {
      handleDownloadFile(`${this.state.byType}_${selectedOption.value}`);
      return;
    }
    // else if (
    //   (handleDownloadFile && !title.includes("Consumption")) ||
    //   !title.includes("Production") ||
    //   !title.includes("Compensation")
    // ) {
    //   handleDownloadFile(selectedOption.value);
    // }
    else {
      let ext = "xlsx";
      let pageName = "excel";
      if (
        selectedOption.value === "csv" ||
        selectedOption.value === "csv-hourly"
      ) {
        ext = pageName = "csv";
      }

      let data4post = "";

      if (
        selectedOption.value === "excel-hourly" ||
        selectedOption.value === "csv-hourly"
      ) {
        data4post = this.state.tableDataHourly;
      } else {
        data4post = this.state.tableData;
      }

      data4post["building"] = this.props.buildingCode;

      client
        .post(REACT_APP_EXCEL_END_URL + pageName, data4post, {
          headers: {
            "Content-Type": "application/json"
            // 'Accept': 'application/vnd.ms-excel'
          },
          responseType: "blob"
        })
        .then(response => {
          const filename = this.state.exportFilename + "." + ext;
          const content = response.headers["content-type"];
          download(response.data, filename, content);
        })
        .catch(error => {});
    }
  };

  getHeadingRowClassName = index => {
    if (
      (this.props?.segment === "m" && this.props?.energySource === "heating") ||
      this.props?.energySource === "gas" ||
      this.props?.energySource === "water"
    ) {
      switch (index) {
        case 0:
          return "table-heading-left";
        case 1:
          return "table-heading-center";
        case 2:
          return "table-heading-right";
        default:
          return "table-heading-center";
      }
    } else {
      switch (index) {
        case 0:
          return "table-heading-left";
        case 1:
          return "table-heading-center";
        case 2:
          return "table-heading-center";
        default:
          return "table-heading-center";
      }
    }
    // return "";
  };

  //Table heading
  renderTableHeading = () => {
    const list = this.state.tableData;
    const { type } = this.props;
    if (type === "account-plan" || type === "building") {
      return (
        <thead
          style={{
            background: "white",
            borderLeft: 0,
            borderRight: 0
          }}
        >
          <tr style={{ background: "white !important" }}>
            {list?.heading?.map((item, index) => (
              <span
                className={
                  index === 1 || index === 2
                    ? "table-heading-center"
                    : "table-heading-left"
                }
                key={index}
              >
                {item ? item : ""}
              </span>
            ))}
          </tr>
        </thead>
      );
    } else if (type === "building-edit") {
      return (
        <thead>
          <tr style={{ background: "white !important" }}>
            {list?.heading?.map((item, index) => (
              <span
                className={
                  index === 0 ? "table-heading-left" : "table-heading-center"
                }
                key={index}
              >
                {item ? item : ""}
              </span>
            ))}
          </tr>
        </thead>
      );
    } else {
      return (
        <thead>
          <tr>
            {list?.heading?.map((item, index) => {
              return (
                <th className={this.getHeadingRowClassName(index)} key={index}>
                  {item ? item : ""}
                </th>
              );
            })}
          </tr>
        </thead>
      );
    }
  };

  onButtonsTap = (type, data, i) => {
    if (type === "View") {
      this.props.onViewTap(data, i);
    } else if (type === "Edit") {
      this.props.onEditTap(data, i);
    } else if (type === this.active || type === this.inActive) {
      this.props.onActiveTap(data, i);
    } else if (type === "Tick") {
      this.props.onTickTap(data, i);
    } else if (type === "energyEdit" || type === "energyCheck") {
      this.props.onCheckorEditTap(type, data, i);
    }
  };

  isEnergyAvailable = (energy, contentIndex) => {
    let isAvailable = false;

    if (energy === "Electricity") {
      isAvailable = true;
    } else if (energy === "Heat" || energy === "Water") {
      // list.energyList.forEach((e) => {
      //     if (e.content[0] === energy) {
      //         isAvailable = true
      //     }
      // })
    }

    return isAvailable;
  };

  getEnergyList = item => {
    let electricity = item.meter.electricity ? item.meter.electricity : [];
    let water = item.meter.water ? item.meter.water : [];
    let heating = item.meter.heating ? item.meter.heating : [];
    return [...electricity, ...water, ...heating];
  };

  getEnergyName = (name, type) => {
    if (type === "heating") {
      return "Heating";
    }
    return name;
  };

  getRowClassName = index => {
    if (
      (this.props?.segment === "m" && this.props?.energySource === "heating") ||
      this.props?.energySource === "gas" ||
      this.props?.energySource === "water"
    ) {
      switch (index) {
        case 0:
          return "table-row-left table-data";
        case 1:
          return "table-row-center table-data";
        case 2:
          return "table-row-right table-data";
        default:
          return "table-row-center table-data";
      }
    } else {
      switch (index) {
        case 0:
          return "table-row-left table-data";
        case 1:
          return "table-row-center table-data";
        case 2:
          return "table-row-center table-data";
        default:
          return "table-row-center table-data";
      }
    }
    // return "";
  };

  //Table row
  renderTableRow = () => {
    const list = this.state.tableData;
    const { type, hideButton } = this.props;
    if (type === "account-plan") {
      return (
        <tbody
          style={{
            background: "white",
            borderLeft: 0,
            borderRight: 0
          }}
        >
          {list?.content?.map((row, i) => (
            <tr key={i} style={{ background: "white !important" }}>
              <td className={"table-row-right"} key={0}>
                <div className="table-row-text">{row[0]}</div>
              </td>
              <td className={"table-row-center"} key={1}>
                <div className="table-row-text">{row[1]}</div>
              </td>
              <td className={"table-row-center"} key={2}>
                <div className="table-row-text">{row[2]}</div>
              </td>
              <td className={"table-row-center"} key={3}>
                <div className="row">
                  <button
                    disabled={false}
                    className={"account-table-view"}
                    onClick={() => this.onButtonsTap("View", row, i)}
                  >
                    {row[3] ? this.props.gettext("View") : ""}
                    {/*  */}
                  </button>
                </div>
              </td>
              <td className={"table-row-center"} key={4}>
                <div className="row">
                  <button
                    disabled={false}
                    className={"account-table-edit"}
                    onClick={() => this.onButtonsTap("Edit", row, i)}
                  >
                    {row[4] ? this.props.gettext("Edit") : ""}
                    {/*  */}
                  </button>
                </div>
              </td>
              <td className={"table-row-center"} key={5}>
                <div className="row">
                  {!row[5] && (
                    <button
                      disabled={false}
                      className={"account-table-btn1"}
                      onClick={() => this.onButtonsTap("Activate", row, i)}
                    >
                      {!row[5] ? this.props.gettext("Activate") : ""}
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (type === "building") {
      return (
        <tbody
          style={{
            background: "white",
            borderLeft: 0,
            borderRight: 0
          }}
        >
          {list?.content?.map((item, index) => (
            <tr
              key={`building_${index}`}
              style={{ background: "white !important" }}
            >
              <td className="table-row-right">
                <div className="table-row-text">{item.address}</div>
              </td>
              <td className={"table-row-center"}>
                <button
                  className="plain-btn"
                  onClick={() =>
                    this.onButtonsTap(
                      this.isEnergyAvailable(item, index)
                        ? "energyCheck"
                        : "energyEdit",
                      item,
                      index
                    )
                  }
                >
                  <img
                    className="active-check"
                    src={
                      "/media/svg/icons/Navigation/checkmark-green.svg"
                      // !this.isEnergyAvailable(item, index) ? '/media/svg/icons/Navigation/' + (item === 'Electricity' ? 'checkmark-green'
                      // : (item === 'Heat' ? 'checkmark-heat' : 'checkmark-water')) + '.svg'
                      // : '/media/svg/icons/Navigation/' + (item === 'Heat' ? 'edit-heat'
                      // : 'edit-water') + '.svg'
                    }
                    alt={"."}
                  />
                </button>
              </td>
              <td className={"table-row-center"}>
                <button
                  className="plain-btn"
                  onClick={() =>
                    this.onButtonsTap(
                      this.isEnergyAvailable(item, index)
                        ? "energyCheck"
                        : "energyEdit",
                      item,
                      index
                    )
                  }
                >
                  <img
                    className="active-check"
                    src={
                      "/media/svg/icons/Navigation/edit-heat.svg"
                      // this.isEnergyAvailable(item, index) ? '/media/svg/icons/Navigation/' + (item === 'Electricity' ? 'checkmark-green'
                      // : (item === 'Heat' ? 'checkmark-heat' : 'checkmark-water')) + '.svg'
                      // : '/media/svg/icons/Navigation/' + (item === 'Heat' ? 'edit-heat' : 'edit-water') + '.svg'
                    }
                    alt={"."}
                  />
                </button>
              </td>
              <td className={"table-row-right"}>
                <button
                  className="plain-btn"
                  onClick={() =>
                    this.onButtonsTap(
                      this.isEnergyAvailable(item, index)
                        ? "energyCheck"
                        : "energyEdit",
                      item,
                      index
                    )
                  }
                >
                  <img
                    className="active-check"
                    src={
                      "/media/svg/icons/Navigation/edit-water.svg"
                      // this.isEnergyAvailable(item, index) ? '/media/svg/icons/Navigation/' + (item === 'Electricity' ? 'checkmark-green' : (item === 'Heat' ? 'checkmark-heat' : 'checkmark-water')) + '.svg' : '/media/svg/icons/Navigation/' + (item === 'Heat' ? 'edit-heat' : 'edit-water') + '.svg'
                    }
                    alt={"."}
                  />
                </button>
              </td>
              <td
                className={
                  index === 1 || index === 2
                    ? "table-row-center"
                    : "table-row-right"
                }
              >
                <div className="row">
                  <button
                    // disabled={item === this.inActive}
                    className="account-table-view"
                    // className={(item === 'Edit') ? 'account-table-edit' : (item === 'View') ? 'account-table-view' : 'account-table-btn1'}
                    onClick={() =>
                      this.onButtonsTap(
                        this.isEnergyAvailable(item, index)
                          ? "energyCheck"
                          : "energyEdit",
                        item,
                        index
                      )
                    }
                  >
                    {this.props.gettext("View")}
                  </button>
                  <button
                    // disabled={item === this.inActive}
                    className="account-table-edit"
                    // className={(item === 'Edit') ? 'account-table-edit' : (item === 'View') ? 'account-table-view' : 'account-table-btn1'}
                    onClick={() =>
                      this.onButtonsTap(
                        this.isEnergyAvailable(item, index)
                          ? "energyCheck"
                          : "energyEdit",
                        item,
                        index
                      )
                    }
                  >
                    {this.props.gettext("Edit")}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (type === "building-edit") {
      return (
        <tbody
          style={{
            background: "white",
            borderLeft: 0,
            borderRight: 0
          }}
        >
          {list?.content?.map((item, i) => (
            <tr
              key={`edit-energy_${i}`}
              style={{ background: "white !important" }}
            >
              <td className="table-row-right">
                {this.getEnergyName(item.name, item.type)}
              </td>
              <td className="table-row-center">{item.unit}</td>
              <td className="table-row-center">{item.meterNo}</td>
              <td className="table-row-center">{item.serialNo}</td>
              <td className="table-row-center">{item.supplier}</td>
              {!hideButton && (
                <td className="table-row-center">
                  <div className="row">
                    <button
                      className="account-table-edit"
                      onClick={() => this.onButtonsTap("Edit", item, i)}
                    >
                      {this.props.gettext("Edit")}
                    </button>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      );
    } else {
      return (
        <tbody
          style={{
            background: "white",
            borderLeft: 0,
            borderRight: 0
          }}
        >
          {list?.content?.map((row, index) => (
            <tr key={index} style={{ background: "white !important" }}>
              {row.map((item, index) => {
                const newItem = Number(item)
                  ? parseFloat(item)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  : item.toString();
                return (
                  <td className={this.getRowClassName(index)} key={index}>
                    {item === "Total"
                      ? this.props.gettext("Total")
                      : this.props.locale === "da"
                      ? newItem
                          .replace(/\./g, "#")
                          .replace(/,/g, ".")
                          .replace(/#/g, ",")
                      : newItem}
                  </td>
                );
              })}
              {/* {row.map((item, index) => (
                <td
                  className={
                    index === 0
                      ? "table-row-left table-data"
                      : "table-row-center table-data"
                  }
                  key={index}
                >
                  {item === "Total" ? this.props.gettext("Total") : item}
                </td>
              ))} */}
            </tr>
          ))}
        </tbody>
      );
    }
  };

  renderHorizontalTable = () => {
    const list = this.state.tableData;

    return (
      <tbody style={{ background: "white", borderLeft: 0, borderRight: 0 }}>
        {/* {list?.heading?.map((rowHeading, headIndex) => (
                    <tr key={headIndex}>
                        <th>{rowHeading}</th>

                        {list.content.map((rowData, index) => (
                            <>
                                <td
                                    className={
                                        index === 0
                                            ? "table-row-right"
                                            : "table-row-right"
                                    }
                                    key={index}
                                >
                                    {rowData === "Total"
                                        ? this.props.gettext("Total")
                                        : rowData[headIndex]}
                                </td>
                            </>
                        ))}
                    </tr>
                ))} */}

        {list?.heading?.length &&
          list?.heading?.map((rowHeading, headIndex) => (
            <tr key={headIndex} style={{ background: "white !important" }}>
              <th>{rowHeading ? rowHeading : ""}</th>

              {list.content &&
                list.content[rowHeading].map((rowData, index) => (
                  <>
                    <td
                      className={
                        index === 0 ? "table-row-right" : "table-row-right"
                      }
                      key={index}
                    >
                      {rowData === "Total"
                        ? this.props.gettext("Total")
                        : rowData
                        ? rowData
                        : 0}
                    </td>
                  </>
                ))}
            </tr>
          ))}
      </tbody>
    );
  };

  //Table
  renderSection = () => {
    // const { tableData, tableDataHourly } = this.props;
    const { exportDisabled } = this.props;
    return (
      <div
        className="energy-table-div bg-white tablenew"
        style={{
          backgroundColor: "white !important",
          border: "1px solid #E4E4E7",
          borderRadius: 12,
          padding: "15px"
        }}
      >
        <div className="table-title-new" style={{ background: "white" }}>
          <h3
            className="text-black"
            style={{
              color: "#27272A",
              fontSize: "20px",
              fontWeight: "700"
            }}
          >
            {this.state.tableData &&
              this.props.gettext(this.state.tableData?.title)}
          </h3>
          <div className="table-toolbar">
            {this.props.showExport && (
              <>
                <NewTableActions
                  bytype={this.state.byType}
                  gettext={this.props.gettext}
                  title={this.state.byTypeName}
                  data={
                    this.props.energySource === "energy" ||
                    this.props.energySource === "electricity"
                      ? this.state.energyTypeDropDown
                      : this.state.typeDropDown
                  }
                  onClick={e => this.handleChangeType(e)}
                  width="164px"
                />
                {this.props.page ? null : (
                  <NewTableActions
                    gettext={this.props.gettext}
                    title="Download"
                    data={this.state.download}
                    onClick={e =>
                      this.handleChangeDownload(e, this.state.tableData?.title)
                    }
                    width="151px"
                    loading={exportDisabled}
                    showExported={this.props.showExport}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <Table responsive>
          {this.props.tableView ? null : this.renderTableHeading()}
          {this.props.tableView ? null : this.renderTableRow()}

          {this.props.tableView ? this.renderHorizontalTable() : null}
        </Table>
      </div>
    );
  };

  render() {
    return <div>{this.renderSection()}</div>;
  }
}

export default NewEnergyCharts;
