import { useQuery } from "@apollo/client";
import { GET_USER_CUTOMERS } from "./commands";

export function useUserCustomers(userId) {
  const { data, loading } = useQuery(GET_USER_CUTOMERS, {
    variables: {
      userId
    }
  });

  return {
    customers: data?.comGetUser.teams || [],
    userName: data?.comGetUser.name || "",
    loading
  };
}
