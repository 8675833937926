import { gql } from "@apollo/client";

export const ADD_NEW_METER = gql`
  mutation addNewMeter($buildingId: String!, $data: AddMeterInput) {
    addCustomerMeter(buildingId: $buildingId, data: $data) {
      success
      message
    }
  }
`;
