import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { UPDATE_USER_PERMISSION } from "../commands";

export function useUpdatePermissions(
  onCompleted = () => {},
  onError = () => {}
) {
  const [UpdateUserPermission, { loading }] = useMutation(
    UPDATE_USER_PERMISSION,
    {
      onCompleted,
      onError,
      refetchQueries: ["getCustomerUsers"]
    }
  );

  const update = useCallback(
    variables => {
      UpdateUserPermission({
        variables
      });
    },
    [UpdateUserPermission]
  );

  return {
    update,
    updating: loading
  };
}
