import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useBuildingFilter } from "hooks";
import { useLocation } from "react-router";
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { IoCheckmark, IoChevronDown } from "react-icons/io5";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    "&::-webkit-scrollbar": {
      width: "3px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: `#E4E4E7`
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#27272A",
      outline: `none`
    }
  }
}));

function BuildingDropdown(props) {
  const { teams, onChange, gettext } = props;
  const [selectedBuilding, setSelectedBuilding] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const classes = useStyles();
  const { buildings } = useBuildingFilter(teams);
  const [filteredBuildings, setFilteredBuildings] = useState(buildings);
  const location = useLocation();
  const key = location.pathname + "selectedBuildingDropdown";
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue("");
    setFilteredBuildings(buildings);
  };

  const handleSearchValues = value => {
    setSearchValue(value);
    if (!value) {
      setFilteredBuildings(buildings);
    } else {
      const filteredData = buildings.filter(item => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilteredBuildings(filteredData);
    }
  };

  useEffect(() => {
    // console.log('localStorage',JSON.parse(localStorage.getItem(key)))
    // const currentBuildings =
    //   localStorage.getItem(key) && JSON.parse(localStorage.getItem(key));
    // if (currentBuildings && currentBuildings.length && buildings) {
    //   const building = currentBuildings.filter(x =>
    //     buildings.some(y => y.value === x.value)
    //   );

    //   setSelectedBuilding(building);
    //   localStorage.setItem(key, JSON.stringify(building));
    // }
    if (buildings && buildings.length) {
      setFilteredBuildings(buildings);
      const building = [buildings[0]];

      setSelectedBuilding(building);
      localStorage.setItem(key, JSON.stringify(building));
    } else {
      setSelectedBuilding([]);
      setFilteredBuildings([]);
      localStorage.removeItem(key);
    }
  }, [buildings, key]);

  useEffect(() => {
    const selectedBuildingList = selectedBuilding ? selectedBuilding : [];
    onChange && onChange(selectedBuildingList);
  }, [onChange, selectedBuilding]);

  const handleChangeBuilding = selectedOption => {
    const selectedOptionList = [selectedOption] || [];
    setSelectedBuilding(selectedOptionList);
    localStorage.setItem(key, JSON.stringify(selectedOptionList));
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<IoChevronDown size={12} />}
        color="secondary"
        onClick={handleClick}
        sx={{
          "&:hover": {
            background: "#E6EEEA",
            border: "1px solid #B3CDC1"
          },
          border: "1px solid transparent",
          borderRadius: "8px",
          padding: "0 8px 0 8px"
        }}
      >
        <Typography
          color="secondary.800"
          fontWeight="fontWeightMedium"
          fontSize="14px"
        >
          {gettext("Building")}
        </Typography>
        {buildings && selectedBuilding && selectedBuilding.length ? (
          <Chip
            sx={{
              ml: 0.5,
              backgroundColor: "primary.50",
              color: "primary.main",
              fontSize: "14px"
            }}
            size="small"
            label={<Typography>{selectedBuilding[0].label}</Typography>}
          />
        ) : null}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { p: 0 }
        }}
        PaperProps={{
          sx: {
            p: 1,
            borderRadius: "8px",
            marginTop: "8px",
            overflow: "hidden"
          }
        }}
        sx={{
          "& .MuiMenuItem-root": {
            fontWeight: 400,
            fontSize: "14px",
            height: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "4px",
            mx: 1
          },
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: "#FFFFFF",
            color: "#19384d"
          }
        }}
      >
        <Stack
          spacing={2}
          sx={{
            "&:focus": {
              outline: "none"
            }
          }}
        >
          <OutlinedInput
            sx={{
              color: "secondary.main",
              mx: 1,
              "& .css-hq24ym-MuiOutlinedInput-notchedOutline": {
                borderRadius: "4px"
              }
            }}
            size="small"
            value={searchValue}
            onChange={e => handleSearchValues(e.target.value)}
            // onFocus={() => setAddMode(true)}
            placeholder={gettext("Search")}
            startAdornment={
              <InputAdornment
                position={"start"}
                style={{
                  color: "secondary.300"
                }}
              >
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Box
            className={classes.root}
            sx={{ maxHeight: "300px", overflowY: "auto" }}
          >
            {filteredBuildings && filteredBuildings.length > 0 ? (
              filteredBuildings?.map(({ value, label, Id }, index) => (
                <MenuItem
                  key={Id}
                  selected={selectedBuilding[0].value === value}
                  onClick={() => {
                    handleChangeBuilding(filteredBuildings[index]);
                  }}
                >
                  {label}
                  {selectedBuilding[0].value === value && (
                    <IoCheckmark size={16} color="#19384d" />
                  )}
                </MenuItem>
              ))
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  margin: "12px"
                }}
              >
                {gettext("Not Found")}
              </Typography>
            )}
          </Box>
        </Stack>
      </Menu>
    </>
  );
}

export default injectIntl(BuildingDropdown);
