import React, { useState, useContext, useEffect } from "react";
import { useLang, useTextdomainContext } from "app/providers";
import { Modal, Button } from "react-bootstrap";
import CustomTable from "../../../components/CustomTable";
import { withRouter } from "../../../../utils";

const AccountLink = props => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const locale = useLang();
  const sd = sessionStorage.getItem("accountPlan");
  const plan = JSON.parse(sd);
  const td = plan
    ? plan
    : {
        title: gettext("Account Link"),
        heading: [
          gettext("Name"),
          gettext("Created"),
          gettext("Changed"),
          "",
          ""
        ],
        content: [
          [
            gettext("Content"),
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit",
            "Activate"
          ],
          [
            gettext("Content"),
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit",
            "Activate"
          ],
          [
            gettext("Content"),
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit",
            "Activate"
          ],
          [
            gettext("Content"),
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit",
            "Activate"
          ],
          [
            gettext("Content"),
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit",
            "Activate"
          ],
          [
            gettext("Content"),
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit",
            "Activate"
          ],
          [
            gettext("Content"),
            "01/03/2021",
            "01/03/2021",
            "View",
            "Edit",
            "Activate"
          ]
        ]
      };
  const [tableData, setTableData] = useState(td);
  // const [showModal, setShowModal] = useState(false);
  const [showActivePopup, setShowActivePopup] = useState("Hide");
  const [selectedPlanIndex, setSelectedPlanIndex] = useState("");
  // const [fileName, setFileName] = useState('');
  // const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    sessionStorage.setItem("accountPlan", JSON.stringify(tableData));

    const sd = sessionStorage.getItem("accountPlan");
    const plan = JSON.parse(sd);

    if (plan) {
      setTableData(plan);
    }
  }, [tableData]);

  const onAddTap = () => {
    props.navigate(`${props.location.pathname}/account-link-edit`, {
      state: {
        type: "add",
        data: ["", "", "", "View", "Edit", "Activate"],
        index: 0
      }
    });
  };

  const onActiveTap = (data, index) => {
    setSelectedPlanIndex(index);
    setShowActivePopup("Active");
  };

  const onViewTap = (data, index) => {
    props.navigate("/settings/account-link-view", {
      state: { data: data }
    });
  };

  const onEditTap = (data, index) => {
    props.navigate(`${props.location.pathname}/account-link-edit`, {
      state: { type: "edit", data: data, index: index }
    });
  };

  const onTickTap = (data, index) => {
    setSelectedPlanIndex(index);
    setShowActivePopup("InActive");
  };

  // const hideModal = () => {
  //   // setShowModal(false);
  //   setPercentage(0);
  //   setFileName("");
  // };

  // const onFileSelect = e => {
  //   let files = e.target.files;
  //   setFileName(files.name);
  // };

  // const onUpload = () => {
  //   const random = Math.floor(Math.random() * (70 - 30 + 1)) + 30;
  //   setPercentage((random), () => {
  //     setTimeout(() => {
  //       setPercentage(100);
  //     }, 800);
  //   });
  // };

  const handleActiveClose = type => {
    if (type === "Y") {
      let updatedData = tableData;
      updatedData.content.forEach((d, index) => {
        // d[0] = (index === selectedPlanIndex)
        d[5] =
          index === selectedPlanIndex
            ? showActivePopup === "InActive"
              ? "Activate"
              : "InActivated"
            : "Activate";
      });
      setTableData(updatedData);
    }
    setShowActivePopup("Hide");
  };

  const renderActivePopup = () => {
    const heading =
      showActivePopup === "Active"
        ? gettext("Are you sure you want to change the active account plan?")
        : showActivePopup === "InActive"
        ? gettext("Are you sure you want to inactive the account plan?")
        : "";
    return (
      <Modal
        show={showActivePopup === "Hide" ? false : true}
        onHide={() => handleActiveClose("N")}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Title
          style={{ marginLeft: 15, marginTop: 15, marginBottom: -10 }}
        >
          {selectedPlanIndex !== ""
            ? tableData.content[selectedPlanIndex][0]
            : ""}
        </Modal.Title>
        <Modal.Body>{heading}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleActiveClose("N")}>
            No
          </Button>
          <Button
            style={{ backgroundColor: "#46763c", color: "white", border: 0 }}
            variant="primary"
            onClick={() => handleActiveClose("Y")}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="account-link-container">
      <div className="card card-custom gutter-b example example-compact">
        <div className="row">
          <button className="account-table-btn mr53" onClick={onAddTap}>
            {gettext("Add")} +
          </button>
        </div>

        <div className="table-shift-up">
          <CustomTable
            energySource="all"
            type="account-plan"
            locale={locale}
            onViewTap={(data, index) => onViewTap(data, index)}
            onEditTap={(data, index) => onEditTap(data, index)}
            onActiveTap={(data, index) => onActiveTap(data, index)}
            onTickTap={(data, index) => onTickTap(data, index)}
            tableData={tableData}
            gettext={gettext}
          />
        </div>

        {renderActivePopup()}
      </div>
    </div>
  );
};

export default withRouter(AccountLink);
