import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  Grid,
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack
} from "@mui/material";
// import { injectIntl } from "react-intl";
import { IoCheckmark, IoChevronDown } from "react-icons/io5";
import StatusMessage from "app/components/StatusMessage";
import slug from "slug";
// import { getSelectedSegment } from "./Constant";
import {
  ExportDataHourly,
  useReportConsumerEngergyData
} from "./GExportDataHourly";
import { ChartContainer, ShowGreenElectricity } from "app/components";
import DownloadFile from "./downloadFile";
import { useUser, useTextdomainContext } from "app/providers";
import { useBuildingFilter, useColorSettings } from "hooks";
import { getOr } from "lodash/fp";
import { NewEnergyGraphs } from "./Graph/NewEnergyGraphs";
import NewDatePicker from "../../../components/NewDatePicker";
import { LineChartLoadingState } from "app/components/LineChart/components";
import NewEnergyCharts from "./Table/NewEnergyCharts";
import SearchIcon from "@mui/icons-material/Search";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
import { NewEnergyLineGraph } from "./Graph/NewEnergyLineGraph";

const PredictionElectricity = () => {
  const type = "electricity";
  const sourceType = "Electricity";
  const page = "prediction";

  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { user } = useUser();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = event => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  //     //

  const { currentTeam } = user || {};

  const localFilters = JSON.parse(localStorage.getItem("energyPredFilters"));

  const [selectedSegment, setSelectedSegment] = useState(
    localFilters ? parseInt(localFilters.selectedSegment) : 1
  );

  const [buildings, setBuildings] = useState([]);

  const [newBuildingsData, setNewBuildingsData] = useState([]);

  const [date, setDate] = useState(
    localFilters ? new Date(localFilters.date) : new Date()
  );

  const [chartDataFlag, setChartDataFlag] = useState({});

  const [isError, setIsError] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const [statusMessage, setStatusMessage] = useState("");

  const [buildingCode, setBuildingCode] = useState(
    localFilters ? localFilters.buildingCode : "All"
  );

  const [allMeterNumbers, setAllMeterNumbers] = useState([]);
  // const [allMeterNumbersOnly, setAllMeterNumbersOnly] = useState([
  //     { value: "all", label: gettext("All") },
  // ]);
  const [allMeterNumbersOnly, setAllMeterNumbersOnly] = useState([]);
  const [addressesList, setAddressesList] = useState("");

  const [buildingName, setBuildingName] = useState(
    localFilters ? localFilters.buildingName : "All"
  );

  const [exportFilename, setExportFilename] = useState(
    `${type}-consumption-day`
  );

  const [buildingmeterNumbers, setBuildingmeterNumbers] = useState([]);

  const [newBuildingMetersData, setNewBuildingMetersData] = useState([]);

  const [meterValue, setMeterValue] = useState(
    // localFilters ? localFilters.buildingMeterValue : gettext("all")
    "all"
  );

  const [meterLabel, setMeterLabel] = useState(
    localFilters ? localFilters.buildingMeterLabel : gettext("All")
  );

  const [selectedFileType, setSelectedFileType] = useState("");

  const [exportDisabled, setExportDisabled] = useState(false);

  const [timeStamp, setTimeStamp] = useState("");

  const segment =
    selectedSegment === 0 ? "h" : selectedSegment === 1 ? "d" : "m";

  const { buildings: buildingData } = useBuildingFilter(currentTeam);

  const { colorSettings } = useColorSettings();

  const [searchMeterValue, setSearchMeterValue] = useState("");
  const [searchBuildingValue, setSearchBuildingValue] = useState("");

  const energyParams = {
    customers: currentTeam,
    buildingCode: buildingCode.toLowerCase() === "all" ? "all" : buildingCode,
    meter: meterValue,
    type: type,
    segment: segment,
    date: date,
    addressesList: addressesList,
    selectedSegment,
    sourceType
  };
  const {
    // data: tableDataHourly,
    // exportDisabled,
    unitStart,
    unitEnd
    // electricityGreen
  } = ExportDataHourly(energyParams);
  const {
    data: { tableData, graphs },
    loading,
    greenElectricity: eGreen
  } = useReportConsumerEngergyData(energyParams, colorSettings, page);

  if (graphs && Object.keys(graphs).length) {
    graphs.chartData3.colors[0] = getOr(
      "#c0c7b5",
      "co2.shade1.background",
      colorSettings
    );

    graphs.comparisonKWHData.colors = ["#4D81FF", "#FF4D4D"];
  }

  const showLoader = () => {
    return (
      <Grid container flexDirection="column" rowSpacing={2} pt={2}>
        {graphs !== undefined && graphs && Object.keys(graphs).length
          ? Object.keys(graphs).map((graph, index) => {
              return (
                <Grid item>
                  <ChartContainer>
                    <LineChartLoadingState />
                  </ChartContainer>
                </Grid>
              );
            })
          : null}
        {/* <Grid item>
                    <ChartContainer>
                        <tableLoadingState />
                    </ChartContainer>
                </Grid> */}
      </Grid>
    );
  };

  const handleDownloadFile = type => {
    const newDate = new Date();
    setTimeStamp(newDate);
    setExportDisabled(true);
    setSelectedFileType(type);
  };

  const checkChartData = useCallback(
    graphData => {
      let data = {};
      if (!loading && graphData) {
        Object.keys(graphData).forEach((graph, index) => {
          data[graph] = graphData[graph]?.data?.map((key, index) => {
            if (key?.data) {
              if (key?.data?.length) {
                if (key.data[0] === 0 && key?.data?.length === 1) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return false;
              }
            }
            return false;
          });
        });
      } else {
        data = {};
      }

      return data;
    },
    [loading]
  );

  const displayChart = chart => {
    return (
      // typeof chartDataFlag[chart] &&

      chartDataFlag[chart] !== undefined &&
      chartDataFlag[chart].find(data => data === true)
    );
  };

  const handleSearchBuildingValues = value => {
    setSearchBuildingValue(value);
    if (!value) {
      setNewBuildingsData(buildings);
    } else {
      const filteredData = buildings.filter(item => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setNewBuildingsData(filteredData);
    }
  };
  const handleSearchMeterValues = value => {
    setSearchMeterValue(value);
    if (!value) {
      setNewBuildingMetersData(buildingmeterNumbers);
    } else {
      const filteredData = buildingmeterNumbers.filter(item => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setNewBuildingMetersData(filteredData);
    }
  };

  // methods//

  useEffect(() => {
    if (buildingData && buildingData.length > 0) {
      let buildingsArray = [{ label: gettext("All"), value: "all" }];
      if (buildingData && buildingData.length > 0) {
        buildingData.map(building => {
          buildingsArray.push(building);
          return true;
        });
        setBuildings(buildingsArray);
        setNewBuildingsData(buildingsArray);
      }

      if (!(buildingCode && buildingName) && buildingData[0]) {
        setBuildingCode(buildingData[0].value);
        setBuildingName(buildingData[0].value);
        // setBuildingLabel(buildingData[0].label)
      }

      let meterNumbers = [];
      // let allMetersNumbers = [{ value: "all", label: gettext("All") }];
      let allMetersNumbers = [];
      buildingData.forEach(resp => {
        let buldingMeters = {
          title: resp.value,
          // meterNumbers: [{ value: "all", label: gettext("All") }],
          meterNumbers: []
        };

        // const meters =
        //     resp.meters && resp.meters[type] ? resp.meters[type] : [];
        const meters = resp && resp[type] ? resp[type] : [];

        if (type === "energy") {
          resp &&
            resp.electricity &&
            resp.electricity.map(item => {
              const meter = meters.find(
                meter => meter?.meterNo === item?.meterNo
              );
              allMetersNumbers.push({
                value: item?.meterNo,
                label: meter && meter.label ? meter.label : item?.meterNo
              });
              buldingMeters.meterNumbers.push({
                value: item?.meterNo,
                label: meter && meter.label ? meter.label : item?.meterNo
              });
              return true;
            });
          resp.heating &&
            resp.heating.map(item => {
              allMetersNumbers.push({
                value: item?.meterNo,
                label: item?.label
              });
              buldingMeters.meterNumbers.push({
                value: item?.meterNo,
                label: item?.label
              });
              return true;
            });
          resp.water &&
            resp.water.map(item => {
              allMetersNumbers.push({
                value: item?.meterNo,
                label: item?.label
              });
              buldingMeters.meterNumbers.push({
                value: item?.meterNo,
                label: item?.label
              });
              return true;
            });
        } else {
          resp[type] &&
            resp[type].map(item => {
              allMetersNumbers.push({
                value: item?.meterNo,
                label: item?.label
              });
              buldingMeters.meterNumbers.push({
                value: item?.meterNo,
                label: item?.label
              });
              return true;
            });
        }

        meterNumbers.push(buldingMeters);
        return true;
      });
      if (allMetersNumbers.length) {
        allMetersNumbers.unshift({
          value: "all",
          label: gettext("All")
        });
      }
      if (meterNumbers.length) {
        meterNumbers.unshift({ value: "all", label: gettext("All") });
      }
      setAllMeterNumbers(meterNumbers);
      setAllMeterNumbersOnly(allMetersNumbers);

      setMeterValue(meterValue);
      setMeterLabel(gettext(meterLabel));
      if (buildingCode === "all" || buildingCode === "All") {
        setBuildingmeterNumbers(allMetersNumbers);
        setNewBuildingMetersData(allMetersNumbers);
      } else {
        let currentBuldingInfo = meterNumbers.find(
          el => el.title === buildingName
        );
        if (currentBuldingInfo?.meterNumbers.length) {
          currentBuldingInfo?.meterNumbers.unshift({
            value: "all",
            label: gettext("All")
          });
        }

        setBuildingmeterNumbers(
          currentBuldingInfo ? currentBuldingInfo.meterNumbers : []
        );
        setNewBuildingMetersData(
          currentBuldingInfo ? currentBuldingInfo.meterNumbers : []
        );
      }
      let addressArray = buildingData.map((obj, index) => {
        return obj.value;
      });
      setAddressesList(addressArray.join("|"));
    }
  }, [
    buildingData,
    buildingName,
    buildingCode,
    gettext,
    type,
    meterValue,
    meterLabel
  ]);

  useEffect(() => {
    const fileSegment =
      selectedSegment === 0 ? "day" : selectedSegment === 1 ? "month" : "year";
    setExportFilename(
      `${type}-consumption-${fileSegment}-${slug(
        buildingCode
      )}-${unitStart}-${unitEnd}`
    );
  }, [unitStart, unitEnd, selectedSegment, buildingCode, type]);

  useEffect(() => {
    localStorage.setItem(
      "energyPredFilters",
      JSON.stringify({
        date: date,
        selectedSegment: selectedSegment,
        buildingCode: buildingCode,
        buildingName: buildingName,
        buildingNameLabel:
          buildingName === "all" || buildingName === "All"
            ? gettext("All")
            : gettext(buildingName),
        buildingMeterLabel:
          meterValue === "all" ? gettext("All") : gettext(meterLabel)
        // gettext(meterLabel),
      })
    );
  }, [
    date,
    meterValue,
    selectedSegment,
    buildingCode,
    gettext,
    meterLabel,
    buildingName
  ]);

  useEffect(() => {
    if (!loading) {
      setChartDataFlag(checkChartData(graphs));
    }
  }, [graphs, loading, checkChartData]);

  return (
    <>
      {/* // New Filter  // */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              p: 1,
              backgroundColor: "secondary.50",
              border: "1px solid",
              minHeight: "52px",
              borderColor: "secondary.200",
              borderRadius: "8px"
            }}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={"auto"}>
                <Button
                  id="basic-button"
                  size="small"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  endIcon={<IoChevronDown size={12} />}
                  color="secondary"
                  onClick={handleClick}
                >
                  <Typography
                    color="secondary.800"
                    fontWeight="fontWeightMedium"
                    fontSize="14px"
                  >
                    {gettext("Building")}
                  </Typography>
                  {buildingName && (
                    <Chip
                      sx={{
                        ml: 0.5,
                        backgroundColor: "primary.50",
                        color: "primary.main",
                        fontSize: "14px"
                      }}
                      size="small"
                      label={<Typography>{gettext(buildingName)}</Typography>}
                    />
                  )}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  sx={{
                    "& .MuiMenu-paper": {
                      boxSizing: "borderBox",
                      position: "relative",
                      width: "282px",
                      maxHeight: "264px",
                      left: "6403px",
                      top: "310px",
                      background: "#FFFFFF",
                      border: "1px solid #E4E4E7",
                      boxShadow:
                        "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      "*::-webkit-scrollbar": {
                        position: "absolute",
                        width: "0.1em",
                        height: "240px",
                        left: "274px",
                        top: "8px"
                      },
                      "*::-webkit-scrollbar-track": {
                        backgroundColor: "#E4E4E7"
                      },
                      "*::-webkit-scrollbar-thumb": {
                        backgroundColor: "#27272A",
                        outline: "2px solid #27272A",
                        height: "64px"
                      }
                    },
                    "& .MuiMenuItem-root": {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "4px",
                      mx: 1
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "#FFFFFF",
                      color: "#19384d"
                    }
                  }}
                >
                  <Stack spacing={1}>
                    <OutlinedInput
                      sx={{
                        color: "secondary.main",
                        mx: 1,
                        "& .css-hq24ym-MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px"
                        }
                      }}
                      size="small"
                      value={searchBuildingValue}
                      onChange={e => handleSearchBuildingValues(e.target.value)}
                      // onFocus={() => setAddMode(true)}
                      placeholder={gettext("Search")}
                      startAdornment={
                        <InputAdornment
                          position={"start"}
                          style={{
                            color: "secondary.300"
                          }}
                        >
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                    <Box
                      sx={{
                        height: "195px",
                        overflow: "auto",
                        mx: 1
                      }}
                    >
                      {newBuildingsData && newBuildingsData.length > 0 ? (
                        newBuildingsData?.map(building => (
                          <MenuItem
                            key={building.name}
                            selected={buildingName === building.label}
                            onClick={() => {
                              const selectedBuildingName = building.label;
                              setBuildingCode(building.value);
                              setBuildingName(selectedBuildingName);
                              if (building.value === "all") {
                                setBuildingmeterNumbers(allMeterNumbersOnly);
                                setNewBuildingMetersData(allMeterNumbersOnly);
                              } else {
                                let currentBuldingInfo = allMeterNumbers.find(
                                  el => el.title === selectedBuildingName
                                );

                                if (currentBuldingInfo?.meterNumbers.length) {
                                  currentBuldingInfo?.meterNumbers.unshift({
                                    value: "all",
                                    label: "All"
                                  });
                                }

                                setBuildingmeterNumbers(
                                  currentBuldingInfo
                                    ? currentBuldingInfo.meterNumbers
                                    : []
                                );
                                setNewBuildingMetersData(
                                  currentBuldingInfo
                                    ? currentBuldingInfo.meterNumbers
                                    : []
                                );
                              }
                              setMeterValue("all");
                              setMeterLabel("All");
                              setIsError(false);
                              setIsSuccess(false);
                              setStatusMessage("");
                              handleClose();
                            }}
                          >
                            {gettext(building.label)}
                            {buildingName === building.label && (
                              <IoCheckmark size={16} color="#19384d" />
                            )}
                          </MenuItem>
                        ))
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            margin: "12px"
                          }}
                        >
                          {gettext("Not Found")}
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                </Menu>
              </Grid>

              <Grid item xs={12} sm={"auto"}>
                <Button
                  id="basic-button"
                  size="small"
                  aria-controls={open2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  endIcon={<IoChevronDown size={12} />}
                  color="secondary"
                  onClick={handleClick2}
                >
                  <Typography
                    color="secondary.800"
                    fontWeight="fontWeightMedium"
                    fontSize="14px"
                  >
                    {gettext("Meter")}
                  </Typography>
                  {meterLabel && (
                    <Chip
                      sx={{
                        ml: 0.5,
                        backgroundColor: "primary.50",
                        color: "primary.main",
                        fontSize: "14px"
                      }}
                      size="small"
                      label={
                        <Typography>
                          {localFilters?.buildingMeterLabel
                            ? localFilters?.buildingMeterLabel
                            : gettext("All")}
                          {/* {gettext(meterLabel)} */}
                        </Typography>
                      }
                    />
                  )}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleClose2}
                  sx={{
                    "& .MuiMenu-paper": {
                      boxSizing: "borderBox",
                      position: "relative",
                      width: "282px",
                      maxHeight: "264px",
                      left: "6403px",
                      top: "310px",
                      background: "#FFFFFF",
                      border: "1px solid #E4E4E7",
                      boxShadow:
                        "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      "*::-webkit-scrollbar": {
                        position: "absolute",
                        width: "0.1em",
                        height: "240px",
                        left: "224px",
                        top: "8px"
                      },
                      "*::-webkit-scrollbar-track": {
                        backgroundColor: "#E4E4E7"
                      },
                      "*::-webkit-scrollbar-thumb": {
                        backgroundColor: "#27272A",
                        outline: "2px solid #27272A",
                        height: "64px"
                      }
                    },
                    "& .MuiMenuItem-root": {
                      fontWeight: 400,
                      fontSize: "14px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "4px",
                      mx: 1
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "#FFFFFF",
                      color: "#19384d"
                    }
                  }}
                >
                  <Stack spacing={1}>
                    <OutlinedInput
                      sx={{
                        color: "secondary.main",
                        mx: 1,

                        "& .css-hq24ym-MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px"
                        }
                      }}
                      size="small"
                      value={searchMeterValue}
                      onChange={e => handleSearchMeterValues(e.target.value)}
                      // onFocus={() => setAddMode(true)}
                      placeholder={gettext("Search")}
                      startAdornment={
                        <InputAdornment
                          position={"start"}
                          style={{
                            color: "secondary.300"
                          }}
                        >
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                    <Box
                      sx={{
                        height: "265px",
                        overflow: "auto",
                        mx: 1
                      }}
                    >
                      {newBuildingMetersData &&
                      newBuildingMetersData.length > 0 ? (
                        newBuildingMetersData?.map(meter => (
                          <MenuItem
                            key={meter.meterNo}
                            selected={meterValue === meter.value}
                            onClick={() => {
                              setMeterValue(meter.value);
                              setMeterLabel(meter.label);
                              handleClose2();
                            }}
                          >
                            {gettext(meter.label)}
                            {meterValue === meter.value && (
                              <IoCheckmark size={16} color="#19384d" />
                            )}
                          </MenuItem>
                        ))
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "400",
                            margin: "12px"
                          }}
                        >
                          {gettext("Not Found")}
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                </Menu>
              </Grid>

              {/* <FormGroup>
                <FormControlLabel
                  sx={{ pt: "10px", pl: "10px" }}
                  control={<Switch />}
                  label="Weather Prediction"
                />
              </FormGroup> */}

              <Grid item xs={4} sm textAlign="end">
                <NewDatePicker
                  selectededDate={localFilters}
                  futureDates={false}
                  segment={selectedSegment}
                  getSelectedDate={(date, selectedSegment) => {
                    setDate(date);
                  }}
                  onSegmentChange={selectedSegment => {
                    setDate(date);
                    setSelectedSegment(selectedSegment);
                  }}
                  gettext={gettext}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <StatusMessage
        isError={isError}
        isSussess={isSuccess}
        statusMessage={statusMessage}
      />
      <Box pl={2} pr={1}>
        {loading ? (
          showLoader()
        ) : (
          <>
            <ShowGreenElectricity greenStatus={eGreen} />
            {// consumerData.chartData1.data[0].data.length > 1 &&
            // consumerData.chartData3.data[0].data.length > 1
            Object.keys(chartDataFlag).length &&
            (displayChart("chartData1") || displayChart("chartData3")) ? (
              <>
                {graphs !== undefined && graphs && Object.keys(graphs).length
                  ? Object.keys(graphs).map((graph, index) => {
                      return (
                        <div className="chart-layout">
                          {graph !== "Cost" && graph !== "price" ? (
                            graph === "comparisonKWHData" ? (
                              <NewEnergyLineGraph
                                data={graphs[graph]}
                                selectedSegment={selectedSegment}
                                gettext={gettext}
                              />
                            ) : (
                              <NewEnergyGraphs
                                data={graphs[graph]}
                                selectedSegment={selectedSegment}
                              />
                            )
                          ) : null}
                        </div>
                      );
                    })
                  : null}

                {tableData !== undefined && tableData && (
                  <NewEnergyCharts
                    page={page}
                    energySource={type}
                    segment={segment}
                    tableData={tableData}
                    // tableDataHourly={tableDataHourly}
                    showExport={true}
                    exportFilename={exportFilename}
                    exportDisabled={exportDisabled}
                    buildingCode={buildingCode}
                    gettext={gettext}
                    handleDownloadFile={handleDownloadFile}
                  />
                )}

                <DownloadFile
                  energyParams={energyParams}
                  selectedFileType={selectedFileType}
                  exportDisabled={exportDisabled}
                  setExportDisabled={setExportDisabled}
                  timeStamp={timeStamp}
                />
              </>
            ) : (
              <>
                <Box component="div" whiteSpace="normal" textAlign="center">
                  <img
                    src="/media/NoGraphData.png"
                    alt="No Data Found"
                    width="150"
                    height="40"
                    className="pt-5"
                  />

                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{
                      mt: 1,
                      fontSize: 24,
                      fontWeight: 500,
                      lineheight: 24
                    }}
                    textOverflow="hidden"
                  >
                    {gettext("No data found for selected date")}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="gray"
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      lineheight: 18
                    }}
                  >
                    {gettext("Please select another date.")}
                  </Typography>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};
export default PredictionElectricity;
