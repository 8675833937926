import { styled, TableRow } from "@mui/material";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FAFAFA"
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));
