import { gql } from "@apollo/client";

export const GET_TIMEZONES = gql`
  query mongoTimezones {
    mongoTimezones {
      id
      countryId
      countryCode
      zoneName
      gmtOffset
      dstOffset
      rawOffset
    }
  }
`;
