import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../_metronic/layout";
import { localeNumberFormat } from "../../common/Functions";
import { DECIMAL } from "../../common/Constants";
import { getOr } from "lodash/fp";
import * as Constants from "../../common/Constants";
import { useLang } from "app/providers";
import { Box, Grid, Typography } from "@mui/material";

let variant1Styles = {
  offsetY: 0,
  style: {
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "20px",
    colors: "#A1A1AA"
  }
};

let variant2Styles = {
  offsetY: 4,
  style: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    colors: ["#27272A"]
  }
};

export function NewMixedWidget3(props) {
  const { data, imageUrl, label } = props;
  let E1Color = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade1.background",
    props.propsColors
  );
  let E2Color = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade2.background",
    props.propsColors
  );
  let E3Color = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade3.background",
    props.propsColors
  );
  const randomString = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 10);
  let chartTitle = props?.data?.chart_title
    ? props?.data?.chart_title.replace("CO2", "CO₂")
    : "";
  let uniqueId = props?.data?.unique_id ? props?.data?.unique_id : randomString;
  let media_type = props?.data?.media_type ? props?.data?.media_type : "web";
  let objectId = "kt_mixed_widget_1_chart" + uniqueId + "_" + media_type;

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);
  const locale = useLang();

  useEffect(() => {
    if (data) {
      const element = document.getElementById(objectId);
      if (!element) {
        return;
      }
      const options = getChartOptions(
        data,
        E1Color,
        E2Color,
        E3Color,
        label,
        locale,
        chartTitle
      );

      const chart = new ApexCharts(element, options);
      chart
        .render()
        .then(() => {
          if (typeof imageUrl === "function") {
            chart.dataURI().then(uri => {
              imageUrl(uri.imgURI);
            });
          }
        })
        .catch(err => {
          return true;
        });
      return function cleanUp() {
        chart.destroy();
      };
    }
  }, [
    data,
    imageUrl,
    layoutProps,
    objectId,
    E1Color,
    E2Color,
    E3Color,
    label,
    locale,
    chartTitle
  ]);

  return (
    <>
      {/* Header */}
      {/* {chartTitle && (
                <Typography
                    sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "20px",
                        color: "#27272A",
                    }}
                >
                    {chartTitle === "Gas CO₂ measured in Tons"
                ? "Natural Gas CO₂ measured in Tons"
                : chartTitle ===
                  "Gas consumption and compensation measured in kWh"
                ? "Natural Gas consumption and compensation measured in kWh"
                : chartTitle}
                    {chartTitle}
                </Typography>
            )} */}
      {/* Body */}
      {data?.legend === "bottomLeft" ? (
        <Grid container xs={12} rowGap={4} pt={1}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                color: "#27272A"
              }}
            >
              {chartTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              p={1.5}
              pb={2.5}
              id={objectId}
              sx={{
                backgroundColor:
                  data?.background === "colored" ? "#f2f5f7" : null,
                borderRadius: "14px"
              }}
              style={{
                height: "100%"
              }}
            ></Box>
          </Grid>
        </Grid>
      ) : (
        <Box
          pt={1}
          ml={-1}
          id={objectId}
          sx={{
            backgroundColor: data?.background === "colored" ? "#f2f5f7" : null
          }}
          style={{
            height: "100%"
          }}
        ></Box>
      )}
    </>
  );
}

export function getChartOptions(
  data,
  E1Color,
  E2Color,
  E3Color,
  label,
  locale,
  chartTitle
) {
  // const strokeColor = "#D13647";
  // const strokeColor = "#426a2f";
  const typeChecked = data.data.findIndex(item => item.type === "column");

  const options = {
    series: data.data.filter(
      graphData => Array.isArray(graphData.data) && graphData.name
    ),

    chart: {
      type: typeChecked !== -1 ? "line" : "bar",
      height: 270,
      stacked: data.stacked,
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      }
    },
    title: {
      text: data.legend === "bottomLeft" ? "" : chartTitle ? chartTitle : " ",
      align: "left",
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "20px",
        color: "#27272A"
      }
    },
    grid: {
      strokeDashArray: data.grid === "dashed" ? 7 : 0,
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        columnWidth: typeChecked !== -1 ? "100%" : "40%",
        dataLabels: {
          position: "top" // top, center, bottom
        },
        distributed: data.plotOptionsBarDistributed
        // endingShape: 'rounded',
      }
    },
    dataLabels: {
      enabled: data.dataLabelsEnabled,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"]
      },
      formatter: function(val) {
        return localeNumberFormat(val, DECIMAL);
      },
      rotate: -90
    },
    stroke: {
      show: false,
      width: 0,
      colors: ["transparent"]
    },
    xaxis: {
      title: { text: data.xaxisTitleText },
      axisTicks: { show: false },
      categories: data.categories,
      logarithmic: true,
      tooltip: {
        enabled: false
        // offsetY: -10,
        // formatter: function(val, opts) {
        //     return null;
        // },
      },
      labels: data.legend === "bottomLeft" ? variant1Styles : variant2Styles
    },

    yaxis: {
      title: {
        text: data.yaxisTitleText
          ? data.yaxisTitleText.replace("CO2", "CO₂")
          : "",
        // rotate: -90,
        style: {
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "20px",
          color: "#27272A"
        }
      },

      axisTicks: { show: false },

      labels: {
        formatter: function(value) {
          if (locale === "en") {
            return localeNumberFormat(value, DECIMAL);
          } else if (locale === "da") {
            const numValue = Number(value);
            const newStringValue = numValue.toFixed(2).toLocaleString();
            const updatedValue = newStringValue
              .replace(/\./g, "#")
              .replace(/,/g, ".")
              .replace(/#/g, ",");
            return updatedValue;
          } else {
            return localeNumberFormat(value, DECIMAL);
          }
        },

        style: {
          fontSize: "10px",
          fontWeight: 500,
          lineHeight: "20px",
          colors: ["#A1A1AA "]
        }
      },
      forceNiceScale: true

      // logarithmic: true,
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function(value) {
          if (locale === "en") {
            return localeNumberFormat(value, DECIMAL);
          } else if (locale === "da") {
            const numValue = Number(value);
            const newStringValue = numValue.toFixed(2).toLocaleString();
            const updatedValue = newStringValue
              .replace(/\./g, "#")
              .replace(/,/g, ".")
              .replace(/#/g, ",");
            return updatedValue;
          } else {
            return localeNumberFormat(value, DECIMAL);
          }
        }
      },
      markers: {
        show: false
      }
    },
    legend: {
      show: true,
      position: data.legend === "bottomLeft" ? "bottom" : "top",
      horizontalAlign: data.legend === "bottomLeft" ? "left" : "right",
      offsetY: data.legend === "bottomLeft" ? 5 : -30,
      markers: {
        width: 18,
        height: 8
      }
    },
    markers: {
      show: false
    },
    colors: data.colors
  };

  return options;
}
