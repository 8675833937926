export const apiRoutes = {
  PartnersList: {
    path: "/v1/partners/"
  },
  PartnerMembers: {
    path: "/v1/partners/users/"
  },
  AddRemoveMembers: {
    path: "/v1/users/"
  },
  GetNewCustomers: {
    path: "/v1/users/get_not_my_teams"
  },
  AddNewCustomerToUser: {
    path: "/user/add_into_my_teams"
  },
  GetUserCustomers: {
    path: "user/teams/"
  },
  CreateDeleteCustomers: {
    path: "teams/"
  },
  DeleteCustomerFromUser: {
    path: "v1/users/delete_from_my_teams"
  },
  GetCustomerBuildings: {
    path: "v1/buildings/"
  },
  GetCustomer: {
    path: "v1/teams/"
  },
  Meters: {
    path: "/v1/buildings/meters"
  },
  AdminEnergySupplir: {
    path: "/v1/energy_suppliers"
  },
  getMembers: {
    path: "/v1/teams/get_members"
  },
  getNewMembers: {
    path: "/v1/teams/get_members_null"
  },
  addMemberToPartner: {
    path: "/v1/teams/set_team_customer"
  },
  removeMembers: {
    path: "v1/teams/set_team_null"
  }
};
