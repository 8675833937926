import {
  Autocomplete,
  InputLabel,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useTextdomainContext } from "app/providers";
import { useCountries } from "hooks";
import React, { useEffect, useState, useContext } from "react";
import { FiChevronDown } from "react-icons/fi";

export default function CountryDropdown(props) {
  const { value, onChange, disabled } = props;

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { countries, loading: countriesLoading } = useCountries();

  const [companyCountryValue, setCompanyCountryValue] = useState(null);

  useEffect(() => {
    if (Boolean(value))
      setCompanyCountryValue(
        countries.find(country => {
          return value === country.code;
        })
      );
    else setCompanyCountryValue(null);
  }, [value, countries]);

  return (
    <Stack>
      <InputLabel htmlFor="country-input">
        <Typography fontWeight={500} color="#3F3F46">
          {gettext("Country")}
        </Typography>
      </InputLabel>
      <Autocomplete
        id="country-input"
        disablePortal
        disableClearable
        fullWidth
        disabled={disabled}
        options={countries}
        getOptionLabel={option => option.name}
        loading={countriesLoading}
        value={companyCountryValue}
        onChange={(e, newValue) => onChange(newValue.code)}
        popupIcon={<FiChevronDown />}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={gettext("Select country")}
            required
          />
        )}
      />
    </Stack>
  );
}
