import React from "react";
import { Outlet } from "react-router-dom";
import { ProfileCard } from "./components/ProfileCard";
import { injectIntl } from "react-intl";

function UserProfileLayout(props) {
  return (
    <div className="d-flex flex-row">
      <ProfileCard />

      <div className="flex-row-fluid ml-lg-8">
        <Outlet />
      </div>
    </div>
  );
}

export default injectIntl(UserProfileLayout);
