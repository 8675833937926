import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addUserInTeam } from "../_redux/userProfileCrud";
import { convertErrorObject } from "../../Auth/_redux/authCrud";
import { injectIntl } from "react-intl";
import Select from "react-select";
import { useUser, useTextdomainContext } from "app/providers";

export function AddTeamMember(props) {
  const { intl, setTeam: setParentTeam } = props;

  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [isError, setisError] = useState(false);

  const [isSuccess, setisSuccess] = useState(false);

  const [_read, setRead] = useState(true);

  const [_write, setWrite] = useState(false);

  const [team, setTeam] = useState(null);

  const { user } = useUser();

  const { teams, currentTeam } = user || {};

  useEffect(() => {
    setTeam(prevTeam => {
      if (prevTeam === null) return { id: currentTeam, name: currentTeam };
      else return prevTeam;
    });

    setParentTeam({ id: currentTeam, name: currentTeam });
  }, [setParentTeam, currentTeam]);

  // UI Helpers
  const initialValues = {
    team_name: currentTeam,
    email: "",
    role: "editor"
  };

  const Schema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL_INVALID" }))
      .min(3, "Minimum 3 symbols")
      .max(255, "Maximum 255 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    role: Yup.string().required("Role is required")
  });
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const chkbxToPermissions = (value, type) => {
    switch (type) {
      case "write":
        if (value) {
          setRead(true);
          setWrite(true);
        } else {
          setWrite(false);
        }

        break;
      case "read":
        if (value) {
          setRead(true);
        } else {
          setRead(false);
          setWrite(false);
        }
        break;

      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(() => {
        addUserInTeam(values.email, team.id, _read, _write, false)
          .then(({ data: { message } }) => {
            setisError(false);
            setisSuccess(true);
            setSubmitting(false);
            setStatus(message);
            props.refresh();
            // props.login(accessToken);
          })
          .catch(err => {
            setisError(true);
            setisSuccess(false);
            setSubmitting(false);
            setStatus(convertErrorObject(err.data));
          });
      }, 1000);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    }
  });

  const onChangeCustomerDD = team => {
    setTeam({ id: team.name, name: team.name });
    setParentTeam({ id: team.name, name: team.name });
  };

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      <div className="card card-custom switch-customer gutter-b example example-compact">
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <div className="col-7 p-0">
              <h3>{gettext("Add User")}</h3>
              <span className="font-size-sm w-100">
                {gettext(
                  "Add a new user to the company, allowing the user to view company information."
                )}
              </span>
            </div>
            {/* <div className="col-5 p-0">
              <div className="action-alignment">
                <button
                  type="submit"
                  className="btn btn-success mr-2"
                  disabled={
                    formik.isSubmitting || (formik.touched && !formik.isValid)
                  }
                >
                  {gettext("Save Changes")}
                  {formik.isSubmitting}
                </button>
                <Link
                  to="/user-profile/profile-overview"
                  className="btn btn-secondary"
                >
                  {gettext("Cancel")}
                </Link>
              </div>
            </div> */}
          </div>
          {/* end::Header */}
          <div className="card-body">
            {/* begin::Alert */}
            {isError && (
              <div
                className="alert alert-custom alert-light-danger fade show mb-10"
                role="alert"
              >
                <div className="alert-icon">
                  <span className="svg-icon svg-icon-3x svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    ></SVG>{" "}
                  </span>
                </div>
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
                <div className="alert-close" onClick={() => setisError(false)}>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i className="ki ki-close"></i>
                    </span>
                  </button>
                </div>
              </div>
            )}

            {isSuccess && (
              <div
                className="alert alert-custom alert-light-success fade show mb-10"
                role="alert"
              >
                <div className="alert-icon">
                  <span className="svg-icon svg-icon-3x svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    ></SVG>{" "}
                  </span>
                </div>
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
                <div
                  className="alert-close"
                  onClick={() => setisSuccess(false)}
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i className="ki ki-close"></i>
                    </span>
                  </button>
                </div>
              </div>
            )}
            {/* end::Alert */}

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                {gettext("Company")}
              </label>
              <div className="col-lg-9 col-xl-6">
                <Select
                  value={team}
                  onChange={onChangeCustomerDD}
                  options={teams}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder={gettext("Company")}
                  {...props}
                  {...props.field}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                {gettext("Email")}
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.PLACEHOLDER.CUSTOMER.EMAIL"
                  })}
                  className={`form-control form-control-lg form-control-solid bg-gray-200 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label">
                {gettext("Permissions")}
              </label>
              <div className="row col-lg-9 col-xl-6 ">
                <div className="col-6">
                  <div className="text-center">{gettext("Read")}</div>
                  <div className="text-center">
                    <input
                      type="checkbox"
                      id="role_chkbx_read"
                      name="role_chkbx_read"
                      value="Read"
                      checked={_read}
                      onChange={e =>
                        chkbxToPermissions(e.target.checked, "read")
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="text-center">{gettext("Write")}</div>
                  <div className="text-center">
                    <input
                      type="checkbox"
                      id="role_chkbx_write"
                      name="role_chkbx_write"
                      value="Write"
                      checked={_write}
                      onChange={e =>
                        chkbxToPermissions(e.target.checked, "write")
                      }
                    />
                  </div>
                </div>
                {formik.touched.role && formik.errors.role ? (
                  <div className="invalid-feedback">{formik.errors.role}</div>
                ) : null}
              </div>
            </div>

            {/* begin::Header */}
            {/* end::Header */}
          </div>
        </div>
        {/* start::footer */}
        <div className="table-footer">
          <div className="col-12 p-0">
            <div className="action-alignment">
              <button
                type="submit"
                className="btn btn-success mr-2"
                disabled={
                  formik.isSubmitting || (formik.touched && !formik.isValid)
                }
              >
                {gettext("Save Changes")}
                {formik.isSubmitting}
              </button>
              <Link
                to="/user-profile/profile-overview"
                className="btn btn-secondary"
              >
                {gettext("Cancel")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default injectIntl(AddTeamMember);
