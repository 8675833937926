import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import LoadingBar from "../../../components/LoadingBar";
import FilterComponent from "../components/Co2ReportFilterComponent";
import Co2FirstSection from "../components/Co2FirstSection";
import { setCacheWithExpiry } from "../../../common/Functions";
import Co2Section2 from "../components/Co2Section2";
import Co2Section3 from "../components/Co2Section3";
import Co2Section4 from "../components/Co2Section4";
import Co2Section5 from "../components/Co2Section5";
import { useCO2Report, useCustomerURL } from "../../../../hooks/useCO2Report";
import { ShowGreenElectricity } from "../../../components";
import { useUser } from "app/providers";

function Co2Report() {
  const currentTime = new Date();
  const currentYear = currentTime.getFullYear();
  const toYear = currentYear - 1;
  const secondYear = toYear - 1;
  const fromYear = toYear - 2;
  const fromDate = fromYear + "-01-01";
  const toDate = currentYear + "-01-01";
  const { user } = useUser();
  const [selectedCustomer, changeSelectedCustomer] = useState("");
  const [selectedBuilding, changeSelectedBuilding] = useState("");
  // const [loadingCount, changeLoadingCount] = useState(0);

  const [showProgress, changeShowProgress] = useState(false);
  const [data, changeData] = useState([]);

  const { co2Report: resources, isLoading } = useCO2Report({
    customer: selectedCustomer,
    addresses: selectedBuilding,
    from: fromDate,
    to: toDate,
    resolution: "m"
  });
  const { teams } = useCustomerURL({ user });

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (resources !== null) {
      let customerNames = "";
      let customerNamesArray = [];
      let customersArray = selectedCustomer.split(",").map(Number);
      teams.map(customer => {
        if (
          customersArray.find(id => {
            return id === customer.id;
          })
        ) {
          customerNamesArray.push(customer.name);
          return false;
        }
        return false;
      });
      customerNames = customerNamesArray.join(", ");
      resources.customers = selectedCustomer;
      resources.customers_names = customerNames;
      resources.buildings = selectedBuilding;
      resources.user_id = user.id;
      resources.team_id = user.currentTeamId;
      resources.from_year = fromYear;
      resources.second_year = secondYear;
      resources.to_year = toYear;

      changeShowProgress(false);
      changeData(resources);
    }
  }, [
    resources,
    teams,
    selectedCustomer,
    selectedBuilding,
    user,
    fromYear,
    secondYear,
    toYear,
    isLoading
  ]);

  let handleChangeCustomer = selectedValue => {
    changeSelectedCustomer(selectedValue);
  };

  let handleChangeBuilding = selectedValue => {
    changeSelectedBuilding(selectedValue);
  };

  return (
    <>
      <LoadingBar show={showProgress} />
      <FilterComponent
        onChangeCustomer={handleChangeCustomer}
        onChangeBuilding={handleChangeBuilding}
      />

      <ShowGreenElectricity greenStatus={resources?.elctricityGreen} />

      <Co2FirstSection
        data={data}
        showProgress={showProgress}
        isLoading={isLoading}
      />

      <Co2Section2
        data={data}
        graph1imageUrl={uri => {
          setCacheWithExpiry("chart1uri", uri);
        }}
      />

      <Co2Section3
        data={data}
        year={toYear}
        graph1imageUrl={uri => {
          setCacheWithExpiry("chart2uri", uri);
        }}
        graph2imageUrl={uri => {
          setCacheWithExpiry("chart3uri", uri);
        }}
      />

      <Co2Section3
        data={data}
        year={secondYear}
        graph1imageUrl={uri => {
          setCacheWithExpiry("chart4uri", uri);
        }}
        graph2imageUrl={uri => {
          setCacheWithExpiry("chart5uri", uri);
        }}
      />

      <Co2Section3
        data={data}
        year={fromYear}
        graph1imageUrl={uri => {
          setCacheWithExpiry("chart6uri", uri);
        }}
        graph2imageUrl={uri => {
          setCacheWithExpiry("chart7uri", uri);
        }}
      />

      <Co2Section4
        data={data}
        type={"electricity"}
        graph1imageUrl={uri => {
          setCacheWithExpiry("chart8uri", uri);
        }}
        graph2imageUrl={uri => {
          setCacheWithExpiry("chart9uri", uri);
        }}
      />

      <Co2Section4
        data={data}
        type={"heating"}
        graph1imageUrl={uri => {
          setCacheWithExpiry("chart10uri", uri);
        }}
        graph2imageUrl={uri => {
          setCacheWithExpiry("chart11uri", uri);
        }}
      />

      <Co2Section4
        data={data}
        type={"water"}
        graph1imageUrl={uri => {
          setCacheWithExpiry("chart12uri", uri);
        }}
        graph2imageUrl={uri => {
          setCacheWithExpiry("chart13uri", uri);
        }}
      />

      <Co2Section5 />
    </>
  );
}

export default injectIntl(Co2Report);
