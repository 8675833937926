import React, { useContext } from "react";
import EnergyCollectionTypes from "./EnergyCollectionTypes";
import { useTextdomainContext } from "app/providers";

const Energy = props => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  return (
    <>
      <EnergyCollectionTypes
        type="energy"
        sourceType="Energy"
        gettext={gettext}
      />
    </>
  );
};

export default Energy;
