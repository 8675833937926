import { gql } from "@apollo/client";

export const UPDATE_COLORS = gql`
  mutation updateUserColor(
    $key: String
    $updateUserSettings: Boolean
    $settings: JSONString
  ) {
    updateUserColor(
      key: $key
      updateUserSettings: $updateUserSettings
      settings: $settings
    ) {
      success
      message
    }
  }
`;

export const DO_VERSION = gql`
  mutation doVersioning($Type: String!) {
    doVersioning(Type: $Type) {
      success
      message
    }
  }
`;
