import React from "react";
import { PerEnergySource } from "../PerEnergySource";

function TotalKwhPerEnergySource(props) {
  const kwhGraph = {
    id: "consumptionKwh",
    title: "Energy consumption measured in kWh",
    fieldName: "consumptionV"
  };

  const Co2Graph = {
    id: "consumptionCo2",
    title: "CO₂ eq measured in tons",
    fieldName: "consumptionCo2"
  };

  return (
    <PerEnergySource
      Co2Graph={Co2Graph}
      kwhGraph={kwhGraph}
      labels={["electricity", "water", "heating"]}
    />
  );
}

export default TotalKwhPerEnergySource;
