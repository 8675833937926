import React, { useState, useContext } from "react";
import { useCustomerPermission, useTextdomainContext } from "app/providers";
import { DataGridComponent } from "app/components";
import { translate } from "app/common/Functions";
import { AiFillEdit } from "react-icons/ai";
import { MdAdd, MdDelete, MdNoteAdd } from "react-icons/md";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetBuildingMeters } from "./hooks";
import { IconButton, Tooltip } from "@mui/material";
import { DeleteMeter, EditMeter } from "./components";
import { DeleteMeterData } from "./components/DeleteMeterData";

export function MetersListTable(props) {
  const { type, building, writeAccess, buildingAddress } = props;
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { canDelete } = useCustomerPermission();

  const navigate = useNavigate();

  const location = useLocation();

  const { meters, loading } = useGetBuildingMeters(building, type);

  const [editableRow, seteditableRow] = useState({});

  const [edit_show, setedit] = useState(false);

  const [delete_show, setdelete] = useState(false);

  const [deleteData_show, setDeleteData] = useState(false);

  const gotoAddMeter = () => {
    navigate(`${location.pathname}/add`);
  };

  const handleClose_edit = () => setedit(false);

  const handleShow_edit = () => setedit(true);

  const handleClose_delete = () => setdelete(false);

  const handleShow_delete = () => setdelete(true);

  const handleClose_deleteData = () => setDeleteData(false);

  const handleShow_deleteData = () => setDeleteData(true);

  function edit_functionality(row) {
    seteditableRow(row);

    handleShow_edit();
  }

  function addData_functionality(row) {
    navigate(`${location.pathname}/${row?.meterNo}/add-data`);
  }

  function delete_functionality(row) {
    seteditableRow(row);

    handleShow_delete();
  }
  function deleteData_functionality(row) {
    seteditableRow(row);
    handleShow_deleteData();
  }

  //--------------Formatters----------------//

  function actionsColumnFormater(row) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Tooltip title={gettext("Add Data")} arrow>
          <IconButton onClick={() => addData_functionality(row)}>
            <MdNoteAdd size={20} />
          </IconButton>
        </Tooltip>

        <Tooltip title={gettext("Edit")} arrow>
          <IconButton onClick={() => edit_functionality(row)}>
            <AiFillEdit size={20} />
          </IconButton>
        </Tooltip>

        {canDelete("administration") && (
          <Tooltip title={gettext("Delete")} arrow>
            <IconButton onClick={() => delete_functionality(row)}>
              <MdDelete size={20} />
            </IconButton>
          </Tooltip>
        )}

        {canDelete("administration") && (
          <Tooltip title={gettext("Delete Meter")} arrow>
            <IconButton onClick={() => deleteData_functionality(row)}>
              <DeleteForeverIcon size={20} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  }

  //---------------------------------------------//

  //-----------------DataGrid Columns-------------//
  let columns = [
    {
      field: "meterNo",
      headerName: gettext("Meter No"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "label",
      headerName: gettext("Meter Label"),
      flex: 1,
      headerAlign: "left"
    },
    // type === "heating"
    //     ? {
    //           field: "meterType",
    //           headerName: gettext("Meter Type"),
    //           flex: 1,
    //           headerAlign: "left",
    //           renderCell: (params) =>
    //               params.row.meterType === "heating"
    //                   ? gettext("District Heating")
    //                   : gettext("Gas"),
    //       }
    //     : null,
    {
      field: "meter_id",
      headerName: gettext("Actions"),
      flex: 1,
      hide: !writeAccess,
      headerAlign: "center",
      renderCell: params => actionsColumnFormater(params.row)
    }
  ];
  // if (type === "heating") {
  //   let meterType = {
  //     field: "meterType",
  //     headerName: gettext("Meter Type"),
  //     flex: 1,
  //     headerAlign: "left",
  //     renderCell: params =>
  //       params.row.meterType === "heating"
  //         ? gettext("District Heating")
  //         : gettext("Natural Gas")
  //   };
  //   columns.splice(2, 0, meterType);
  // }

  return (
    <>
      <div className="card card-custom example example-compact">
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <div>
              <h3 className="custom-header-list">
                {/* {'Will do'} */}
                <span>
                  {`${translate(
                    `USER.LIST.CUSTOMERS.BUILDING.METERS.${type?.toUpperCase()}`
                  )} ${gettext("Meters List")}`}
                </span>
              </h3>
              <div className="card-toolbar"></div>
              <span className="font-size-sm w-100">{gettext("Meters")}</span>
            </div>
            <div>
              {writeAccess && (
                <button
                  className="btn btn-primary d-flex align-items-center mt-3"
                  type="submit"
                  onClick={gotoAddMeter}
                  // disabled={loading}
                >
                  <>
                    <MdAdd size={20} />
                    {gettext("Add")}
                  </>
                </button>
              )}
            </div>
          </div>
          {/* end::Header */}

          <div className="card-body customer-list">
            <DataGridComponent
              rows={meters}
              columns={columns}
              loading={loading}
              getRowId={row => row?.meterNo}
              disableSelectionOnClick={true}
            />
          </div>
        </div>
      </div>

      <EditMeter
        show={edit_show}
        onHide={handleClose_edit}
        editableRow={editableRow}
        editCustomer={props.customer}
        buildingAddress={buildingAddress}
      />
      <DeleteMeter
        show={delete_show}
        onHide={handleClose_delete}
        editableRow={editableRow}
      />
      <DeleteMeterData
        show={deleteData_show}
        onHide={handleClose_deleteData}
        editableRow={editableRow}
      />
    </>
  );
}
