import { Autocomplete, Grid, IconButton, TextField } from "@mui/material";
import { AiOutlineMinusCircle } from "react-icons/ai";
import React, { useCallback, useState } from "react";
import { useDebounce } from "hooks";
import { useFindAddresses } from "../../hooks";

export default function AddressesInput(props) {
  const {
    gettext,
    address,
    onChangeAddress,
    // country,
    // onChangeCountry,
    disabled,
    canReduce,
    required,
    removeBuildingInput,
    index,
    showReduceIcon
  } = props;

  // const handleCountryChange = useCallback(
  //   value => {
  //     onChangeCountry(index, value);
  //   },
  //   [index, onChangeCountry]
  // );

  const [addressValue, setAddressValue] = useState("");

  const debounceAddressValue = useDebounce(addressValue, 500);

  const { addresses: addressesOptions, loading } = useFindAddresses(
    debounceAddressValue
  );

  const handleAddressChange = useCallback(
    (index, value) => {
      onChangeAddress(index, value);

      setAddressValue(value);
    },
    [onChangeAddress]
  );

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                disableClearable
                freeSolo
                fullWidth
                loading={loading}
                disabled={disabled}
                options={addressesOptions}
                getOptionLabel={option => option.tekst || ""}
                inputValue={address || ""}
                onInputChange={(e, newValue) =>
                  handleAddressChange(index, newValue)
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label={gettext("Building Address")}
                    size="small"
                    required={required}
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <CountryDropdown
                placeholder="Building Country"
                required={required}
                disabled={disabled}
                value={country}
                onChange={handleCountryChange}
              />
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item>
          <IconButton
            disabled={disabled || !canReduce}
            onClick={() => removeBuildingInput(index)}
          >
            {showReduceIcon && (
              <AiOutlineMinusCircle
                color={disabled || !canReduce ? "gray" : "red"}
              />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
