import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { IoCloseOutline } from "react-icons/io5";
import { useDashboardFilter, useTextdomainContext } from "app/providers";
import { useDebounce } from "hooks";

export function MenuContent(props) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const [addMode, setAddMode] = React.useState(false);

  const {
    buildings,
    selectedBuildings,
    handleAddBuildings,
    handleRemoveBuildings
  } = useDashboardFilter();

  const [buildingOptions, setbuildingOptions] = React.useState([]);

  const [searchValue, setSearchValue] = React.useState("");

  const debounceSearchValue = useDebounce(searchValue, 500);

  React.useEffect(() => {
    setbuildingOptions(
      buildings.filter(
        building =>
          building.address
            .toLowerCase()
            .includes(debounceSearchValue.toLowerCase()) &&
          !selectedBuildings.some(obj => obj.address === building.address)
      )
    );
  }, [buildings, selectedBuildings, debounceSearchValue]);

  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleItemClick = React.useCallback(building => {
    setSelectedOptions(prevSelectedOption => {
      const index = prevSelectedOption.findIndex(
        item => item.address === building.address
      );
      if (index === -1) {
        return [...prevSelectedOption, building];
      } else {
        return [
          ...prevSelectedOption.slice(0, index),
          ...prevSelectedOption.slice(index + 1)
        ];
      }
    });
  }, []);

  return (
    <Stack spacing={2}>
      <OutlinedInput
        sx={{ color: "secondary.main" }}
        size="small"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        onFocus={() => setAddMode(true)}
        placeholder={gettext("Search")}
        startAdornment={
          <InputAdornment position={"start"} style={{ color: "secondary.300" }}>
            <SearchIcon />
          </InputAdornment>
        }
      />

      <Box
        sx={{
          height: 170,
          width: 250,
          maxHeight: 180,
          maxWidth: 280,
          overflow: "auto"
        }}
      >
        {addMode ? (
          buildingOptions.map((building, index) => (
            <ListItem key={building.address} disablePadding disableGutters>
              <ListItemButton
                role={undefined}
                onClick={() => handleItemClick(building)}
                dense
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Checkbox
                    edge="start"
                    checked={Boolean(
                      selectedOptions.find(
                        item => item.address === building.address
                      )
                    )}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": building.address }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={building.address}
                  primary={building.address}
                />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <Stack spacing={1}>
            <Typography fontSize={13} fontWeight={400} color="secondary.600">
              {gettext("Selected")}
            </Typography>

            <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}>
              {selectedBuildings.map((building, index) => (
                <Chip
                  key={building.address}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #B3CDC1",
                    backgroundColor: "primary.50",
                    color: "primary.main"
                  }}
                  size="small"
                  label={
                    <Typography fontWeight={500}>{building.address}</Typography>
                  }
                  onDelete={() => handleRemoveBuildings(building)}
                  deleteIcon={<IoCloseOutline color="inherit" />}
                />
              ))}
            </Box>
          </Stack>
        )}
      </Box>

      {addMode ? (
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={() => {
              setAddMode(false);
              setSelectedOptions([]);
            }}
          >
            {gettext("Cancel")}
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => {
              handleAddBuildings(selectedOptions);
              setAddMode(false);
              setSelectedOptions([]);
              props?.onClose();
            }}
          >
            {gettext("Add")}
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          {/* <Button size='small' color='secondary' variant='outlined'>
                            Cancel
                        </Button>
                        <Button size='small' color='secondary' variant='contained'>
                            Set
                        </Button> */}
        </Box>
      )}
    </Stack>
  );
}
