import Moment from "moment";
import dateFormat from "dateformat";

export const getUnitDateOption = (selectedSegment, startDate, endDate) => {
  if (!startDate) startDate = new Date();
  if (!endDate) endDate = new Date();
  let now = new Date(startDate);
  let end = new Date(endDate);
  let fromDate = "";
  let yearStart = startDate.getFullYear();
  let monthStart = startDate.getMonth() + 1;
  let dayStart = startDate.getDate();
  let selectedDateStart = yearStart + "-" + monthStart + "-" + dayStart;
  let selectedMonthStart = yearStart + "-" + monthStart;
  let selectedYearStart = yearStart;
  let yearEnd = endDate.getFullYear();
  let monthEnd = endDate.getMonth() + 1;
  let dayEnd = endDate.getDate();
  let selectedDateEnd = yearEnd + "-" + monthEnd + "-" + dayEnd;
  let selectedMonthEnd = yearEnd + "-" + monthEnd;
  let selectedYearEnd = yearEnd;
  let toDate = "";
  let unitStart = "";
  let unitEnd = "";
  // let segment = "";
  let toDateObject = "";
  let fromDateObject = "";

  // console.log(selectedSegment, startDate, endDate);

  if (selectedSegment === "h") {
    // segment = "day";
    unitStart = selectedDateStart;
    unitEnd = selectedDateEnd;
    fromDate = Moment(startDate).format("YYYY-MM-DD");
    toDate = Moment(endDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
  } else if (selectedSegment === "d") {
    // segment = "month";
    unitStart = selectedMonthStart;
    unitEnd = selectedMonthEnd;
    fromDateObject = new Date(now.setMonth(now.getMonth() + 0));
    toDateObject = new Date(end.setMonth(end.getMonth() + 1));
    fromDate = dateFormat(fromDateObject, "yyyy-mm-01");
    toDate = dateFormat(toDateObject, "yyyy-mm-01");
  } else if (selectedSegment === "m") {
    // segment = "year";
    unitStart = selectedYearStart;
    unitEnd = selectedYearEnd;
    let now1 = new Date(now.setMonth(0));
    let now2 = new Date(end.setMonth(0));
    fromDateObject = new Date(now1.setFullYear(now1.getFullYear() + 0));
    toDateObject = new Date(now2.setFullYear(now2.getFullYear() + 1));
    fromDate = dateFormat(fromDateObject, "yyyy-mm-01");
    toDate = dateFormat(toDateObject, "yyyy-mm-01");
  }

  // console.log(fromDate, toDate, unitStart, unitEnd);

  return { fromDate, toDate, unitStart, unitEnd };
};

export const getHourlyExportDates = (
  segment,
  // unit,
  unitStart,
  unitEnd,
  type = "yyyy-mm-dd'T'HH:MM:ss"
) => {
  // console.log(unitStart, unitEnd);

  let dateFromObject = new Date();
  let dateToObject = new Date();

  let fromDate = dateFormat(dateFromObject, type);
  let toDate = dateFormat(dateToObject, type);
  switch (segment) {
    case "h":
      // console.log(unitStart);
      dateFromObject = new Date(unitStart);
      dateFromObject.setHours(0, 0, 0, 0);
      fromDate = dateFormat(dateFromObject, type);

      dateToObject = new Date(unitEnd);
      dateToObject.setHours(0, 0, 0, 0);
      dateToObject.setDate(dateToObject.getDate() + 1);
      toDate = dateFormat(dateToObject, type);

      // dateToObject = new Date(
      //   dateFromObject.setDate(dateFromObject.getDate() + 1)
      // );
      // toDate = dateFormat(dateFromObject, type);
      break;
    case "d":
      dateFromObject = new Date(unitStart + "-01");
      dateFromObject.setHours(0, 0, 0, 0);
      fromDate = dateFormat(dateFromObject, type);

      dateToObject = new Date(unitEnd + "-01");
      dateToObject.setHours(0, 0, 0, 0);
      dateToObject.setMonth(dateToObject.getMonth() + 1);
      toDate = dateFormat(dateToObject, type);

      // dateToObject = new Date(
      //   dateFromObject.setMonth(dateFromObject.getMonth() + 1)
      // );
      // toDate = dateFormat(dateFromObject, type);
      break;
    case "m":
      dateFromObject = new Date(unitStart + "-01-01");
      dateFromObject.setHours(0, 0, 0, 0);
      fromDate = dateFormat(dateFromObject, type);

      dateToObject = new Date(unitEnd + "-01-01");
      dateToObject.setHours(0, 0, 0, 0);
      dateToObject.setYear(dateToObject.getFullYear() + 1);
      toDate = dateFormat(dateToObject, type);

      // dateToObject = new Date(
      //   dateFromObject.setYear(dateFromObject.getFullYear() + 1)
      // );
      // toDate = dateFormat(dateFromObject, type);
      break;
    default:
      break;
  }

  // console.log(fromDate, toDate);
  return { fromDate, toDate };
};

export const calculateExportData = (res, meter) => {
  let finalObject = {
    title: "Hourly Data",
    heading: [
      "Time",
      "Consumption (kWh)",
      "Tons carbon emission",
      "Production (kWh)",
      "Production CO2"
    ],
    content: []
  };

  let tmpObject = {};
  let tmpObjectProd = {};
  res.map((obj, index) => {
    obj.data.map((obj2, index2) => {
      if (meter === obj.meter || meter === "all" || meter === "All") {
        if (!tmpObject[obj2.ts]) {
          tmpObject[obj2.ts] = {
            v: 0,
            co2: 0
          };
        }
        if (!tmpObjectProd[obj2.ts]) {
          tmpObjectProd[obj2.ts] = {
            v: 0,
            co2: 0
          };
        }
        if (obj.type_of_mp === "E18") {
          tmpObjectProd[obj2.ts]["v"] += obj2.v;
          tmpObjectProd[obj2.ts]["co2"] += obj2.co2;
        } else {
          tmpObject[obj2.ts]["v"] += obj2.v;
          tmpObject[obj2.ts]["co2"] += obj2.co2;
        }
      }
      return false;
    });
    return false;
  });

  for (let key in tmpObject) {
    finalObject.content.push([
      key,
      tmpObject[key].v,
      tmpObject[key].co2,
      tmpObjectProd[key].v,
      tmpObjectProd[key].co2
    ]);
  }
  return finalObject;
};

export const getFileDataType = (selectedFileType, segment) => {
  let fileType = selectedFileType;

  let segmentType = segment;
  if (selectedFileType === "consumption_excel") {
    fileType = "consumption_excel";
  } else if (selectedFileType === "consumption_excel-hourly") {
    fileType = "consumption_excel";
    segmentType = "h";
  } else if (selectedFileType === "consumption_csv") {
    fileType = "consumption_csv";
  } else if (selectedFileType === "consumption_csv-hourly") {
    fileType = "consumption_csv";
    segmentType = "h";
  } else if (selectedFileType === "production_excel") {
    fileType = "production_excel";
  } else if (selectedFileType === "production_excel-hourly") {
    fileType = "production_excel";
    segmentType = "h";
  } else if (selectedFileType === "production_csv") {
    fileType = "production_csv";
  } else if (selectedFileType === "production_csv-hourly") {
    fileType = "production_csv";
    segmentType = "h";
  } else if (selectedFileType === "compensation_excel") {
    fileType = "compensation_excel";
  } else if (selectedFileType === "compensation_excel-hourly") {
    fileType = "compensation_excel";
    segmentType = "h";
  } else if (selectedFileType === "compensation_csv") {
    fileType = "compensation_csv";
  } else if (selectedFileType === "compensation_csv-hourly") {
    fileType = "compensation_csv";
    segmentType = "h";
  }
  return { fileType, segmentType };
};
