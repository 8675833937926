import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { DeleteConfirmation } from "../../components";

const ConfirmationContext = createContext();

export function useConfirmation({ loading }) {
  const { confirm, setLoading, closeConfirmation } = useContext(
    ConfirmationContext
  );

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return {
    confirm,
    closeConfirmation
  };
}

export default function ConfirmationProvider({ children }) {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [options, setoptions] = useState();

  const closeConfirmation = useCallback(() => {
    setIsConfirmationOpen(false);
  }, []);

  const confirm = useCallback(options => {
    setIsConfirmationOpen(true);
    setoptions(options);
  }, []);

  const value = {
    confirm,
    setLoading,
    closeConfirmation
  };

  return (
    <ConfirmationContext.Provider value={value}>
      <DeleteConfirmation
        show={isConfirmationOpen}
        onHide={closeConfirmation}
        confirmationText={options?.confirmationText}
        loading={loading}
        onConfirmed={options?.onConfirm}
      />

      {children}
    </ConfirmationContext.Provider>
  );
}
