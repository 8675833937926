import React, { useContext } from "react";
import Alert from "@mui/material/Alert";
import { FaLeaf } from "react-icons/fa";
import { useTextdomainContext } from "app/providers";

export function ShowGreenElectricity({ greenStatus }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  return (
    <div className="chart-layout px-3">
      {greenStatus === true ? (
        <Alert icon={<FaLeaf fontSize="inherit" />} severity="success">
          {gettext("We are using green electricity")}
        </Alert>
      ) : null}
    </div>
  );
}
