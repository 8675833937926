import { useQuery } from "@apollo/client";
import { GET_COMPANY_ADDRESSES } from "../commands";

export function useCompanyBuildings(
  customerName,
  onCompleted = () => {},
  onError = () => {}
) {
  const { data, loading } = useQuery(GET_COMPANY_ADDRESSES, {
    variables: {
      customerName
    },
    onCompleted: ({ comGetCustomerDetails }) => {
      onCompleted(
        comGetCustomerDetails?.addresses || [],
        comGetCustomerDetails?.country
      );
    },
    onError,
    fetchPolicy: "network-only"
  });

  return {
    country: data?.comGetCustomerDetails?.country || "",
    addresses: data?.comGetCustomerDetails?.addresses || [],
    loading
  };
}
