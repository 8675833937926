import React, { useEffect, useCallback, useState, useContext } from "react";
import { injectIntl } from "react-intl";
import { useTextdomainContext } from "app/providers";
import {
  Button,
  Grid,
  Skeleton,
  TableContainer,
  Typography
} from "@mui/material";
import EnergyPricesCustomTable from "app/components/EnergyPricesCustomTable";
import {
  useEnergyPrices,
  useBuildingEnergyPrices
} from "hooks/EnergyPrices/useEnergyPrices";
import DatePicker from "react-datepicker";
import Box from "@mui/material/Box";
import CountrySelection from "../../../../pages/energyPrices/components/CountrySelection";
import { IoArrowBackSharp, IoCalendar } from "react-icons/io5";
import moment from "moment";
import { Stack } from "@mui/system";
import { useCountryPublicAddresses } from "hooks";
import BuildingSelection from "../../../../pages/energyPrices/components/BuildingSelection";
import EnergyPricesBuildingCustomTable from "app/components/EnergyPricesBuildingCustomTable";
import { hourlyConsumptionChart } from "../../../../pages/energyPrices/hourlyConsumptionChart";
import ApexCharts from "apexcharts";
import { capitalizeFirstLetter } from "hooks/useCommon";
import countries from "../../../../pages/energyPrices/countries";
import useStyles from "./styles";
import { DatePickerStyles } from "./globalStyles";
import CurrencySelection from "app/pages/energyPrices/components/CurrencySelection";
import { useNavigate } from "react-router-dom";
import FileSelection from "app/pages/energyPrices/components/FileSelection";

const consumptionPricesChartId = "chart-consumption-prices-hourly";
const energyType = "electricity";
const date = new Date();

const PublicFacingEnergyPricesPage = props => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [tableData, setTableData] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    // new Date().toISOString().split("T")[0]
    null
  );
  const [datePickerDate, setDatePicker] = useState(new Date());
  const [countryCode, setCountryCode] = useState("DK");
  const [selectedBuilding, selectBuilding] = useState(null);
  const [country, setCountry] = useState("");
  const [selectedCurrency, selectCurrency] = useState("DKK");
  const classes = useStyles();
  const navigate = useNavigate();

  const { buildings } = useCountryPublicAddresses(countryCode);
  const { data: pricedata, loading: priceDataLoading } = useEnergyPrices(
    selectedDate,
    country?.label?.toLowerCase() || "denmark"
  );

  const {
    data: buildingPriceData,
    loading: buildingPriceLoading
  } = useBuildingEnergyPrices(
    selectedDate,
    selectedBuilding?.name || selectedBuilding || ""
  );

  useEffect(() => {
    setCountry(countries[0]);
  }, []);

  useEffect(() => {
    if (country) setCountryCode(country.code);
  }, [country]);

  // useEffect(() => {
  //     if (datePickerDate) {
  //         setSelectedDate(
  //             new Date(datePickerDate).toISOString().split("T")[0]
  //         );
  //     }
  //     //  else {
  //     //     setSelectedDate(new Date().toISOString().split("T")[0]);
  //     // }
  // }, [datePickerDate]);

  useEffect(() => {
    if (buildingPriceData?.meterDataWithTaxes?.data && !buildingPriceLoading) {
      const chartDt = hourlyConsumptionChart(
        buildingPriceData.meterDataWithTaxes.data,
        gettext(capitalizeFirstLetter(energyType)) +
          " " +
          gettext("prices for consumption") +
          " DKK/kWh",
        gettext
      );
      const chartEl = document.getElementById(consumptionPricesChartId);
      if (!chartEl) return;
      const chart = new ApexCharts(chartEl, chartDt.options);
      chart.render();
      return () => chart.destroy();
    }
  }, [buildingPriceData, buildingPriceLoading, gettext]);

  const getTableHeadings = useCallback(
    tableData => {
      // let data = tableData[0];
      let tableHeading = [""];
      tableHeading.push(`${gettext("Yesterday")}`);
      tableHeading.push(`${gettext("Today")}`);

      tableHeading.push(`${gettext("Tomorrow")}`);
      return tableHeading;
    },
    [gettext]
  );

  const setPickerDate = useCallback(date => {
    let formatedDate = date.split(/\D/);
    let newDate = new Date(formatedDate.reverse().join("-"));
    setDatePicker(newDate);
  }, []);

  useEffect(() => {
    if (
      selectedBuilding &&
      !buildings.some(bd => selectedBuilding?.name === bd.name)
    ) {
      selectBuilding(null);
      localStorage.removeItem("pricesSelectedBuilding");
    } else if (localStorage.getItem("pricesSelectedBuilding")) {
      selectBuilding(
        JSON.parse(localStorage.getItem("pricesSelectedBuilding"))
      );
    }
  }, [buildings, selectedBuilding]);

  useEffect(() => {
    let data = [];
    let rowData = [];
    let subHeading = [""];
    let tableHeading = [];
    let countryCode = [];
    let days = ["Yesterday", "Today", "Tomorrow"];
    if (
      pricedata &&
      pricedata?.dataResourcePrice?.data?.length &&
      !priceDataLoading
    ) {
      pricedata?.dataResourcePrice?.data?.forEach((time, index) => {
        if (selectedDate === null) {
          setPickerDate(time.today);
        }
        let tableValues = JSON.parse(time.values);
        tableValues.forEach((time, index1) => {
          rowData = [];
          subHeading = ["Hours"];
          rowData.push(time?.ts);
          days.forEach((day, index) => {
            let dayPrice = time?.[day]?.[selectedCurrency];
            dayPrice &&
              Object.keys(dayPrice).forEach((currency, index) => {
                rowData.push(
                  parseFloat(
                    dayPrice[currency] ? dayPrice[currency] : 0
                  ).toFixed(2)
                );
              });
          });
          data.push(rowData);
        });
      });
      tableHeading = getTableHeadings(pricedata?.dataResourcePrice?.data);

      countryCode = pricedata?.dataResourcePrice?.data[0].countryCode.split(
        ","
      );

      countryCode = countryCode.concat(countryCode).concat(countryCode);
      setTableData({
        tableHeading: tableHeading,
        subHeading: subHeading.concat(countryCode),
        content: data,
        totalCountryCodes: pricedata?.dataResourcePrice?.data[0].countryCode.split(
          ","
        ).length
      });
    } else {
      setTableData({});
    }
  }, [
    selectedDate,
    pricedata,
    priceDataLoading,
    getTableHeadings,
    setPickerDate,
    selectedCurrency
  ]);

  const customDateFormat = date => {
    const dt = moment(date);
    return dt.format("DD MMM YYYY");
  };

  return (
    <Stack spacing={4} className={classes.root}>
      <Box className={classes.mainTitle}>
        <Box className={classes.backNavButton} onClick={() => navigate(-1)}>
          <IoArrowBackSharp fontSize={20} />
          <Typography className={classes.backButtonText}>
            {gettext("Back")}
          </Typography>
        </Box>
        <Typography className={classes.title}>
          {gettext(`Electricity Prices Page`)}
        </Typography>
      </Box>
      <Box className={classes.searchFilter}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={"auto"}>
            <CountrySelection
              countries={countries}
              selectCountry={setCountry}
              selectedCountry={country}
              gettext={gettext}
            />
          </Grid>
          <Grid item xs={12} sm={"auto"}>
            <BuildingSelection
              buildings={buildings}
              selectBuilding={selectBuilding}
              selectedBuilding={selectedBuilding}
              gettext={gettext}
            />
          </Grid>
          <Grid item sm textAlign="end">
            <Box
              sx={{
                float: "right",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <DatePicker
                customInput={
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    endIcon={<IoCalendar />}
                  >
                    {customDateFormat(datePickerDate || new Date())}
                  </Button>
                }
                selected={datePickerDate}
                maxDate={new Date()}
                popperClassName={"date-picker-popper"}
                wrapperClassName={"date-picker-wrapper"}
                calendarClassName={"date-picker-calendar"}
                calendarStartDay={1}
                onChange={date => {
                  setDatePicker(date);
                  setSelectedDate(new Date(date).toISOString().split("T")[0]);
                }}
                dateFormat={"dd/MM/yyy"}
              />
              <DatePickerStyles />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {!selectedBuilding ? (
        <>
          {priceDataLoading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="100%"
              height="100%"
            />
          ) : (
            <>
              {Object.keys(tableData)?.length && tableData.content.length ? (
                <>
                  <EnergyPricesCustomTable
                    energyType={energyType}
                    tableData={tableData}
                    gettext={gettext}
                    date={date}
                    selectedCurrency={selectedCurrency}
                    currencySelectionComponent={
                      <CurrencySelection
                        selectedCurrency={selectedCurrency}
                        selectCurrency={selectCurrency}
                        gettext={gettext}
                      />
                    }
                    fileSelectionComponent={
                      <FileSelection
                        selectedDate={selectedDate}
                        selectedCountry={country?.label}
                        selectedCurrency={selectedCurrency}
                        type="country"
                        gettext={gettext}
                      />
                    }
                  />
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    minHeight: "350px",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                  }}
                >
                  <img
                    src="/media/NoGraphData.png"
                    alt="No Data Found"
                    width="150"
                    height="40"
                    className="pt-5"
                  />

                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{
                      mt: 1,
                      fontSize: 24,
                      fontWeight: 500,
                      lineheight: 24
                    }}
                    textOverflow="hidden"
                  >
                    {gettext("No data found for selected date!")}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="gray"
                    sx={{
                      fontSize: 18,
                      fontWeight: 400,
                      lineheight: 18
                    }}
                  >
                    {gettext("Please select another date.")}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </>
      ) : buildingPriceData?.meterDataWithTaxes?.data &&
        buildingPriceData?.meterDataWithTaxes?.data.length ? (
        <>
          <TableContainer
            sx={{
              minHeight: "380px",
              borderRadius: "12px",
              padding: "24px",
              border: "1px solid #E4E4E7"
            }}
          >
            <div id={consumptionPricesChartId} />
          </TableContainer>
          <EnergyPricesBuildingCustomTable
            energyType={energyType}
            tableData={buildingPriceData?.meterDataWithTaxes?.data || []}
            gettext={gettext}
            country={country}
            date={date}
            currencyCode={country?.currency}
            fileSelectionComponent={
              <FileSelection
                selectedDate={selectedDate}
                selectedBuilding={selectedBuilding}
                type="building"
                gettext={gettext}
              />
            }
          />
        </>
      ) : !buildingPriceLoading ? (
        <Box
          sx={{
            display: "flex",
            minHeight: "350px",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <img
            src="/media/NoGraphData.png"
            alt="No Data Found"
            width="150"
            height="40"
            className="pt-5"
          />

          <Typography
            variant="body1"
            color="primary"
            textOverflow="hidden"
            className={classes.primaryMessage}
          >
            {gettext("No data found for selected date!")}
          </Typography>
          <Typography
            variant="caption"
            color="gray"
            className={classes.secondaryMessage}
          >
            {gettext("Please select another date.")}
          </Typography>
        </Box>
      ) : (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height="100%"
        />
      )}
    </Stack>
  );
};

export default injectIntl(PublicFacingEnergyPricesPage);
