import { gql } from "@apollo/client";

export const FORGOT_PASSWORD = gql`
  mutation comForgetPassword($email: String!) {
    comForgetPassword(email: $email) {
      success
      message
      userNotFound
    }
  }
`;
