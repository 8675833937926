import {
  Button,
  Chip,
  ListItemText,
  Menu,
  MenuItem,
  ListSubheader
} from "@mui/material";
import { useDashboardFilter, useTextdomainContext } from "app/providers";
import React from "react";
import { IoCheckmark, IoChevronDown } from "react-icons/io5";
import { MdSpaceDashboard } from "react-icons/md";

export function SelectDashboard() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    dashboards,
    selectedDashboard,
    changeDashboard
  } = useDashboardFilter();

  const handleSelect = React.useCallback(
    dashboard => {
      changeDashboard(dashboard);
      handleClose();
    },
    [changeDashboard]
  );

  return (
    <>
      <Button
        id="basic-button"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        startIcon={<MdSpaceDashboard />}
        endIcon={<IoChevronDown size={12} />}
        color="secondary"
        onClick={handleClick}
      >
        {gettext("Consumption")}
        <Chip
          sx={{
            ml: 0.5,
            backgroundColor: "primary.50",
            color: "primary.main"
          }}
          size="small"
          label={selectedDashboard.label}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        PaperProps={{
          sx: { borderRadius: "8px", width: 320 }
        }}
      >
        <ListSubheader>
          <ListItemText>You can choose between :</ListItemText>
        </ListSubheader>
        {dashboards.map(dashboard => (
          <MenuItem
            key={dashboard.key}
            onClick={() => handleSelect(dashboard)}
            sx={{
              color:
                selectedDashboard.key === dashboard.key
                  ? "primary.main"
                  : "secondary.main"
            }}
          >
            <ListItemText>{dashboard.label}</ListItemText>
            {selectedDashboard.key === dashboard.key && (
              <IoCheckmark size={16} />
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
