import {
  // api,
  useAPI,
  useAPIGraph,
  useAPIGraphPost
} from "./apiClient";

import {
  GET_CUSTOMERS_URL,
  REPORT_CONSUMER_URL,
  GET_CUSTOMER_AGGREGATED_DATA
} from "./Constant";

export function useGetByAddresses(params) {
  return useAPIGraphPost(`data/v2/api/by_addresses`, params);
}

export function useGetByAddress(params) {
  return useAPIGraph(`${REPORT_CONSUMER_URL}`, params);
}

export function useGetCustomerUrl(userId) {
  return useAPI(`${GET_CUSTOMERS_URL}/${userId}`);
}

export function useAggregatedData(params) {
  return useAPIGraph(`${GET_CUSTOMER_AGGREGATED_DATA}`, params);
}
