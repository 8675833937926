import React, { useEffect, useState, useContext, useMemo } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useUser, useTextdomainContext, useLang } from "app/providers";
import { translate } from "app/common/Functions";
import slug from "slug";
import {
  YEAR1_ELECTRICITY_COLOR,
  YEAR2_ELECTRICITY_COLOR,
  YEAR1_HEAT_COLOR,
  YEAR2_HEAT_COLOR,
  YEAR1_WATER_COLOR,
  YEAR2_WATER_COLOR
} from "../../../common/Constants";
import {
  useBuildingFilter
  // useMetersFilter
} from "hooks";
import CustomTable from "../../../components/CustomTable";
import { customInputStyle, DEFAULT_TABLE } from "./EnergyComparisonData";
import { useEnergyComparisonRequest } from "./EnergyComparisonRequest";
import StatusMessage from "../../../components/StatusMessage";
import DownloadBenchMark from "./downloadBenchMark";
import { Spinner } from "react-bootstrap";

const EnergyComparison = props => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { user } = useUser();
  const { currentTeam } = user || {};

  const energyList = useMemo(() => {
    return [
      {
        value: "all",
        label: gettext("All")
      },
      {
        value: "electricity",
        label: gettext("Electricity")
      },
      {
        value: "benchmark_heating",
        label: gettext("Heat")
      },
      {
        value: "water",
        label: gettext("Water")
      }
    ];
  }, [gettext]);

  let oneYearOldDateFrom = new Date();
  let oneYearOldDateTo = new Date();
  oneYearOldDateFrom.setFullYear(oneYearOldDateFrom.getFullYear() - 1);
  oneYearOldDateTo.setFullYear(oneYearOldDateTo.getFullYear() - 1);
  oneYearOldDateTo.setMonth(oneYearOldDateTo.getMonth() + 1);

  const [tableDataElectricity, setTableDataElectricity] = useState(
    DEFAULT_TABLE
  );
  const [tableDataHeat, setTableDataHeat] = useState(DEFAULT_TABLE);
  const [tableDataWater, setTableDataWater] = useState(DEFAULT_TABLE);

  const [isElectricity, setIsElectricity] = useState(false);
  const [isHeating, setIsHeating] = useState(false);
  const [isWater, setIsWater] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [exportDisabled, setExportDisabled] = useState(false);
  const [timeStamp, setTimeStamp] = useState("");

  const getChartData = options => {
    return {
      unique_id: options.unique_id,
      type: "bar",
      stacked: false,
      dataLabelsEnabled: false,
      yaxisTitleText: options.yaxisTitleText,
      xaxisTitleText: options.xaxisTitleText,
      colors: options.colors,
      plotOptionsBarDistributed: false,
      chart_title: options.chart_title,
      categories: options.categories,
      data: options.data
    };
  };

  const getChartOptions = (
    unique_id,
    yaxisTitleText,
    chart_title,
    categories,
    data,
    name,
    colors
  ) => {
    const options = {
      unique_id: unique_id,
      yaxisTitleText: yaxisTitleText,
      chart_title: chart_title,
      categories: categories,
      data: data,
      name: name,
      colors: colors
    };
    return options;
  };

  // const [chartData1Electricity, setChartData1Electricity] = useState(getChartData(
  //   getChartOptions("chartData1Electricity", "", "", [], [], "")
  // ));

  // const [chartData2Electricity, setChartData2Electricity] = useState(getChartData(
  //   getChartOptions("chartData2Electricity", "", "", [], [], "")
  // ));

  // const [chartData3Electricity, setChartData3Electricity] = useState(getChartData(
  //   getChartOptions("chartData3Electricity", "", "", [], [], "")
  // ));

  // const [chartData1Heat, setChartData1Heat] = useState(getChartData(
  //   getChartOptions("chartData1Heat", "", "", [], [], "")
  // ));

  // const [chartData2Heat, setChartData2Heat] = useState(getChartData(
  //   getChartOptions("chartData2Heat", "", "", [], [], "")
  // ));

  // const [chartData3Heat, setChartData3Heat] = useState(getChartData(
  //   getChartOptions("chartData3Heat", "", "", [], [], "")
  // ));

  // const [chartData1Water, setChartData1Water] = useState(getChartData(
  //   getChartOptions("chartData1Water", "", "", [], [], "")
  // ));

  // const [chartData2Water, setChartData2Water] = useState(getChartData(
  //   getChartOptions("chartData2Water", "", "", [], [], "")
  // ));

  // const [chartData3Water, setChartData3Water] = useState(getChartData(
  //   getChartOptions("chartData3Water", "", "", [], [], "")
  // ));
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [buildings, setBuildings] = useState([]);
  const [buildingCode, setBuildingCode] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [energyCode, setEnergyCode] = useState("all");
  const [energyName, setEnergyName] = useState(gettext("All"));
  const [selectedDateFrom, setSelectedDateFrom] = useState(oneYearOldDateFrom);
  const locale = useLang();
  const [selectedDateTo, setSelectedDateTo] = useState(oneYearOldDateTo);
  const [exportFilenameElectricity, setExportFilenameElectricity] = useState(
    "energy-comparison-electricity"
  );
  const [exportFilenameHeat, setExportFilenameHeat] = useState(
    "energy-comparison-heat"
  );
  const [exportFilenameWater, setExportFilenameWater] = useState(
    "energy-comparison-water"
  );
  const [loading, setLoading] = useState("Loading...");

  const getComparisonParams = () => {
    let yearFrom = selectedDateFrom.getFullYear();
    let monthFrom = selectedDateFrom.getMonth() + 1;
    let dayFrom = selectedDateFrom.getDate();
    dayFrom = dayFrom > 9 ? dayFrom : "0" + dayFrom;
    monthFrom = monthFrom > 9 ? monthFrom : "0" + monthFrom;
    let selectedFrom = yearFrom + "-" + monthFrom + "-" + dayFrom;
    selectedFrom = `${selectedFrom}T00:00:00`;
    let yearTo = selectedDateTo.getFullYear();
    let monthTo = selectedDateTo.getMonth() + 1;
    let dayTo = selectedDateTo.getDate();
    dayTo = dayTo > 9 ? dayTo : "0" + dayTo;
    monthTo = monthTo > 9 ? monthTo : "0" + monthTo;
    let selectedTo = yearTo + "-" + monthTo + "-" + dayTo;
    selectedTo = `${selectedTo}T00:00:00`;

    return {
      addresses: buildingCode,
      fromDate: selectedFrom,
      toDate: selectedTo,
      sourceType: energyCode
    };
  };
  const [selectedSourceType, setSelectedSourceType] = useState("");

  const {
    buildings: buildingData,
    loading: buildingLoading
  } = useBuildingFilter(currentTeam);
  const {
    energy,
    electricity,
    heating,
    water,
    loadingData
  } = useEnergyComparisonRequest(getComparisonParams());

  useEffect(() => {
    if (!buildingLoading && buildingData && buildingData.length > 0) {
      setBuildings(buildingData);
      setBuildingCode(buildingData[0].value);
      setBuildingName(buildingData[0].label);
    }
  }, [buildingLoading, buildingData]);

  useEffect(() => {
    let yearFrom = selectedDateFrom.getFullYear();
    let monthFrom = selectedDateFrom.getMonth() + 1;
    let dayFrom = selectedDateFrom.getDate();
    let from = yearFrom + "-" + monthFrom + "-" + dayFrom;

    let yearTo = selectedDateTo.getFullYear();
    let monthTo = selectedDateTo.getMonth() + 1;
    let dayTo = selectedDateTo.getDate();
    let to = yearTo + "-" + monthTo + "-" + dayTo;

    setExportFilenameElectricity(
      `energy-comparison-electricity-${slug(buildingCode)}-${from}-to-${to}`
    );
    setExportFilenameHeat(
      `energy-comparison-heating-${slug(buildingCode)}-${from}-to-${to}`
    );
    setExportFilenameWater(
      `energy-comparison-water-${slug(buildingCode)}-${from}-to-${to}`
    );
  }, [selectedDateTo, selectedDateFrom, buildingCode]);

  const handleChangeBuildings = selectedOption => {
    setBuildingCode(selectedOption.value);
    setBuildingName(selectedOption.label);
  };

  const handleChangeEnergy = selectedOption => {
    // setEnergyCode(selectedOption.value);
    setEnergyCode(selectedOption.value);
    setEnergyName(selectedOption.label);
  };

  useEffect(() => {
    let year1 = "current";
    let year2 = "previous";

    let header1 = `${selectedDateFrom.getDate()}-${selectedDateFrom.getMonth() +
      1}-${selectedDateFrom.getFullYear()} to 
        ${selectedDateTo.getDate()}-${selectedDateTo.getMonth() +
      1}-${selectedDateTo.getFullYear()}`;

    let header2 = `${selectedDateFrom.getDate()}-${selectedDateFrom.getMonth() +
      1}-${selectedDateFrom.getFullYear() - 1} to 
        ${selectedDateTo.getDate()}-${selectedDateTo.getMonth() +
      1}-${selectedDateTo.getFullYear() - 1}`;
    // let header2 =`${selectedDateFrom.getDate()}-${selectedDateFrom.getMonth()}-${selectedDateFrom.getFullYear()}`;

    let tableTitleElectricity = gettext("Electricity Source");
    let tableTitleHeat = gettext("Heat Source");
    let tableTitleWater = gettext("Water Source");
    let tableHeading = [
      `${gettext("Date")}`,
      `kWh (${header1})`,
      `kWh (${header2})`,
      `${gettext("Tons carbon emission")} (${header1})`,
      `${gettext("Tons carbon emission")} (${header2})`
    ];
    let tableContentElectricity = [];
    let tableContentHeat = [];
    let tableContentWater = [];

    let isElectricity = false;
    let isHeat = false;
    let isWater = false;

    // let counterElectricity = 0;
    // let counterHeat = 0;
    // let counterWater = 0;

    let consumptionElectricity = [];
    let consumptionHeat = [];
    let consumptionWater = [];

    let co2Electricity = [];
    let co2Heat = [];
    let co2Water = [];

    let daysElectricity = [];
    let daysHeat = [];
    let daysWater = [];

    let dataConsumptionYear1Electricity = [];
    let dataConsumptionYear1Heat = [];
    let dataConsumptionYear1Water = [];

    let dataConsumptionYear2Electricity = [];
    let dataConsumptionYear2Heat = [];
    let dataConsumptionYear2Water = [];

    let dataCo2Year1Electricity = [];
    let dataCo2Year1Heat = [];
    let dataCo2Year1Water = [];

    let dataCo2Year2Electricity = [];
    let dataCo2Year2Heat = [];
    let dataCo2Year2Water = [];

    let consumption_1_tot = 0;
    let co2_1_tot = 0;
    let consumption_2_tot = 0;
    let co2_2_tot = 0;

    if (
      electricity &&
      electricity.electricity &&
      electricity.electricity[0][year1] !== "undefined" &&
      (energyCode === "electricity" || energyCode === "all")
    ) {
      isElectricity = true;
      consumption_1_tot = 0;
      co2_1_tot = 0;
      consumption_2_tot = 0;
      co2_2_tot = 0;
      for (let row in electricity.electricity[0][year1]["days"]) {
        // row = null;
        let day = `${
          electricity.electricity[0][year1]["days"][row].split(" ")[0]
        } ${translate(
          electricity.electricity[0][year1]["days"][row].split(" ")[1]
        )}`;
        let consumption_1 = 0;
        let co2_1 = 0;
        let consumption_2 = 0;
        let co2_2 = 0;

        if (
          typeof electricity.electricity[0][year1]["consumption"][row] !==
          "undefined"
        ) {
          consumption_1 = electricity.electricity[0][year1]["consumption"][row];
        }
        if (
          typeof electricity.electricity[0][year1]["co2"][row] !== "undefined"
        ) {
          co2_1 = electricity.electricity[0][year1]["co2"][row];
        }
        if (
          typeof electricity.electricity[1][year2]["consumption"][row] !==
          "undefined"
        ) {
          consumption_2 = electricity.electricity[1][year2]["consumption"][row];
        }
        if (
          typeof electricity.electricity[1][year2]["co2"][row] !== "undefined"
        ) {
          co2_2 = electricity.electricity[1][year2]["co2"][row];
        }

        consumption_1_tot += consumption_1;
        co2_1_tot += co2_1;
        consumption_2_tot += consumption_2;
        co2_2_tot += co2_2;

        daysElectricity.push(day);
        dataConsumptionYear1Electricity.push(consumption_1);
        dataConsumptionYear2Electricity.push(consumption_2);
        dataCo2Year1Electricity.push(co2_1);
        dataCo2Year2Electricity.push(co2_2);

        tableContentElectricity.push([
          day,
          consumption_1.toFixed(2),
          consumption_2.toFixed(2),
          co2_1.toFixed(2),
          co2_2.toFixed(2)
        ]);
        // counterElectricity++;
      }
      tableContentElectricity.push([
        "Total",
        consumption_1_tot.toFixed(2),
        consumption_2_tot.toFixed(2),
        co2_1_tot.toFixed(2),
        co2_2_tot.toFixed(2)
      ]);
    }

    if (
      heating &&
      heating.heating &&
      heating.heating[0][year1] !== "undefined" &&
      (energyCode === "benchmark_heating" || energyCode === "all")
    ) {
      isHeat = true;
      consumption_1_tot = 0;
      co2_1_tot = 0;
      consumption_2_tot = 0;
      co2_2_tot = 0;

      for (let row in heating.heating[0][year1]["days"]) {
        let day = `${
          heating.heating[0][year1]["days"][row].split(" ")[0]
        } ${translate(heating.heating[0][year1]["days"][row].split(" ")[1])}`;
        let consumption_1 = 0;
        let co2_1 = 0;
        let consumption_2 = 0;
        let co2_2 = 0;

        if (
          typeof heating.heating[0][year1]["consumption"][row] !== "undefined"
        ) {
          consumption_1 = heating.heating[0][year1]["consumption"][row];
        }
        if (typeof heating.heating[0][year1]["co2"][row] !== "undefined") {
          co2_1 = heating.heating[0][year1]["co2"][row];
        }
        if (
          typeof heating.heating[1][year2]["consumption"][row] !== "undefined"
        ) {
          consumption_2 = heating.heating[1][year2]["consumption"][row];
        }
        if (typeof heating.heating[1][year2]["co2"][row] !== "undefined") {
          co2_2 = heating.heating[1][year2]["co2"][row];
        }

        consumption_1_tot += consumption_1;
        co2_1_tot += co2_1;
        consumption_2_tot += consumption_2;
        co2_2_tot += co2_2;

        daysHeat.push(day);
        dataConsumptionYear1Heat.push(consumption_1);
        dataConsumptionYear2Heat.push(consumption_2);
        dataCo2Year1Heat.push(co2_1);
        dataCo2Year2Heat.push(co2_2);

        tableContentHeat.push([
          day,
          consumption_1.toFixed(2),
          consumption_2.toFixed(2),
          co2_1.toFixed(2),
          co2_2.toFixed(2)
        ]);
        // counterHeat++;
      }
      tableContentHeat.push([
        "Total",
        consumption_1_tot.toFixed(2),
        consumption_2_tot.toFixed(2),
        co2_1_tot.toFixed(2),
        co2_2_tot.toFixed(2)
      ]);
    }

    if (
      water &&
      water.water &&
      water.water[0][year1] !== "undefined" &&
      (energyCode === "water" || energyCode === "all")
    ) {
      isWater = true;
      consumption_1_tot = 0;
      co2_1_tot = 0;
      consumption_2_tot = 0;
      co2_2_tot = 0;
      for (let row in water.water[0][year1]["days"]) {
        let day = `${
          water.water[0][year1]["days"][row].split(" ")[0]
        } ${translate(water.water[0][year1]["days"][row].split(" ")[1])}`;
        let consumption_1 = 0;
        let co2_1 = 0;
        let consumption_2 = 0;
        let co2_2 = 0;

        if (typeof water.water[0][year1]["consumption"][row] !== "undefined") {
          consumption_1 = water.water[0][year1]["consumption"][row];
        }
        if (typeof water.water[0][year1]["co2"][row] !== "undefined") {
          co2_1 = water.water[0][year1]["co2"][row];
        }
        if (typeof water.water[1][year2]["consumption"][row] !== "undefined") {
          consumption_2 = water.water[1][year2]["consumption"][row];
        }
        if (typeof water.water[1][year2]["co2"][row] !== "undefined") {
          co2_2 = water.water[1][year2]["co2"][row];
        }

        consumption_1_tot += consumption_1;
        co2_1_tot += co2_1;
        consumption_2_tot += consumption_2;
        co2_2_tot += co2_2;

        daysWater.push(day);
        dataConsumptionYear1Water.push(consumption_1);
        dataConsumptionYear2Water.push(consumption_2);
        dataCo2Year1Water.push(co2_1);
        dataCo2Year2Water.push(co2_2);

        tableContentWater.push([
          day,
          consumption_1.toFixed(2),
          consumption_2.toFixed(2),
          co2_1.toFixed(2),
          co2_2.toFixed(2)
        ]);
        // counterWater++;
      }
      tableContentWater.push([
        "Total",
        consumption_1_tot.toFixed(2),
        consumption_2_tot.toFixed(2),
        co2_1_tot.toFixed(2),
        co2_2_tot.toFixed(2)
      ]);
    }

    let tableDataElectricity = {
      title: tableTitleElectricity,
      heading: tableHeading,
      content: tableContentElectricity
    };
    let tableDataHeat = {
      title: tableTitleHeat,
      heading: tableHeading,
      content: tableContentHeat
    };
    let tableDataWater = {
      title: tableTitleWater,
      heading: tableHeading,
      content: tableContentWater
    };

    consumptionElectricity = [
      {
        name: year1,
        data: dataConsumptionYear1Electricity
      },
      {
        name: year2,
        data: dataConsumptionYear2Electricity
      }
    ];

    consumptionHeat = [
      {
        name: year1,
        data: dataConsumptionYear1Heat
      },
      {
        name: year2,
        data: dataConsumptionYear2Heat
      }
    ];

    consumptionWater = [
      {
        name: year1,
        data: dataConsumptionYear1Water
      },
      {
        name: year2,
        data: dataConsumptionYear2Water
      }
    ];

    co2Electricity = [
      {
        name: year1,
        data: dataCo2Year1Electricity
      },
      {
        name: year2,
        data: dataCo2Year2Electricity
      }
    ];

    co2Heat = [
      {
        name: year1,
        data: dataCo2Year1Heat
      },
      {
        name: year2,
        data: dataCo2Year2Heat
      }
    ];

    co2Water = [
      {
        name: year1,
        data: dataCo2Year1Water
      },
      {
        name: year2,
        data: dataCo2Year2Water
      }
    ];

    // let chartData1Electricity =
    getChartData(
      getChartOptions(
        "chartData1Electricity",
        "kWh",
        "Consumption (kWh) for Electricity",
        daysElectricity,
        consumptionElectricity,
        "Consumption (kWh) for Electricity",
        [YEAR1_ELECTRICITY_COLOR, YEAR2_ELECTRICITY_COLOR]
      )
    );

    // let chartData1Heat =
    getChartData(
      getChartOptions(
        "chartData1Heat",
        "kWh",
        "Consumption (kWh) for Heating",
        daysHeat,
        consumptionHeat,
        "Consumption (kWh) for Heating",
        [YEAR1_HEAT_COLOR, YEAR2_HEAT_COLOR]
      )
    );

    //let chartData1Water =
    getChartData(
      getChartOptions(
        "chartData1Water",
        "kWh",
        "Consumption (kWh) for Water",
        daysWater,
        consumptionWater,
        "Consumption (kWh) for Water",
        [YEAR1_WATER_COLOR, YEAR2_WATER_COLOR]
      )
    );

    // let chartData3Electricity =
    getChartData(
      getChartOptions(
        "chartData3Electricity",
        "CO2 in Tons",
        "Tons CO2 for Electricity",
        daysElectricity,
        co2Electricity,
        "Tons CO2 for Electricity",
        [YEAR1_ELECTRICITY_COLOR, YEAR2_ELECTRICITY_COLOR]
      )
    );

    // let chartData3Heat =
    getChartData(
      getChartOptions(
        "chartData3Heat",
        "CO2 in Tons",
        "Tons CO2 for Heating",
        daysHeat,
        co2Heat,
        "Tons CO2 for Heating",
        [YEAR1_HEAT_COLOR, YEAR2_HEAT_COLOR]
      )
    );

    // let chartData3Water =
    getChartData(
      getChartOptions(
        "chartData3Water",
        "CO2 in Tons",
        "Tons CO2 for Water",
        daysWater,
        co2Water,
        "Tons CO2 for Water",
        [YEAR1_WATER_COLOR, YEAR2_WATER_COLOR]
      )
    );

    setTableDataElectricity(tableDataElectricity);
    setTableDataHeat(tableDataHeat);
    setTableDataWater(tableDataWater);
    setIsElectricity(isElectricity);
    setIsHeating(isHeat);
    setIsWater(isWater);
    // setChartData1Electricity(chartData1Electricity);
    // setChartData1Heat(chartData1Heat);
    // setChartData1Water(chartData1Water);
    // setChartData3Electricity(chartData3Electricity);
    // setChartData3Heat(chartData3Heat);
    // setChartData3Water(chartData3Water);
    setLoading("");
    setStatusMessage("");
    setIsError(false);
    setIsSuccess(false);
  }, [
    energy,
    electricity,
    heating,
    water,
    energyCode,
    selectedDateFrom,
    selectedDateTo,
    user,
    gettext
  ]);

  const getEnergyParams = () => {
    let energyParams = {
      customers: currentTeam,
      buildingCode: buildingCode,
      meter: "all",
      type: "",
      segment: "d",
      fromDate: getComparisonParams().fromDate,
      toDate: getComparisonParams().toDate
    };
    return energyParams;
  };

  const handleDownloadFile = (fileType, type) => {
    const newDate = new Date();
    setTimeStamp(newDate);
    setExportDisabled(true);
    setSelectedFileType(fileType);
    setSelectedSourceType(type);
  };

  return (
    <>
      <div className={"card card-custom gutter-b example example-compact"}>
        <div className="card-body">
          <StatusMessage
            isError={isError}
            isSussess={isSuccess}
            statusMessage={statusMessage}
            setState={state => {
              // this.setState(state);
            }}
          />
          <div className={"row top-section-filters"}>
            <div className={"col-xl-1 col-sm-2 py-md-3"}>
              {gettext("Building")}
            </div>
            <div className={"col-xl-2 col-sm-4 py-2 py-md-0"}>
              <Select
                options={buildings}
                onChange={handleChangeBuildings}
                value={[
                  {
                    value: buildingCode,
                    label: buildingName
                  }
                ]}
              />
              {loading}
            </div>
            <div className={"col-xl-1 col-sm-2 py-md-3"}>
              {gettext("Energy source")}
            </div>
            <div className={"col-xl-2 col-sm-4 py-2 py-md-0"}>
              <Select
                options={energyList}
                onChange={handleChangeEnergy}
                value={[
                  {
                    value: energyCode,
                    label: energyName
                  }
                ]}
              />
            </div>

            <div className={"col-xl-1 col-sm-2 py-md-3"}>{gettext("From")}</div>
            <div className={"col-xl-2 col-sm-4 py-md-3"}>
              <div className="date-picker2">
                <DatePicker
                  customInput={<input style={customInputStyle} />}
                  selected={selectedDateFrom}
                  onChange={date => {
                    setSelectedDateFrom(date);
                  }}
                  dateFormat={"dd/MM/yyy"}
                />
              </div>
            </div>
            <div className={"col-xl-1 col-sm-2 py-md-3"}>{gettext("To")}</div>
            <div className={"col-xl-2 col-sm-4 py-2 py-md-0"}>
              <div className="date-picker2">
                <DatePicker
                  customInput={<input style={customInputStyle} />}
                  selected={selectedDateTo}
                  onChange={date => {
                    setSelectedDateTo(date);
                  }}
                  dateFormat={"dd/MM/yyy"}
                />
              </div>
            </div>
          </div>

          <div className="card card-custom gutter-b mw-0">
            <p>
              {gettext(
                "Note: The benchmark is the comparison with the previous year."
              )}
            </p>
          </div>
          {loadingData ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <>
              {isElectricity && (
                <CustomTable
                  energySource="all"
                  locale={locale}
                  tableData={tableDataElectricity}
                  showExport={true}
                  exportFilename={exportFilenameElectricity}
                  // tableDataHourly={tableDataElectricityHourly}
                  exportDisabled={selectedSourceType === "electricity"}
                  buildingCode={buildingCode}
                  gettext={gettext}
                  handleDownloadFile={type =>
                    handleDownloadFile(type, "electricity")
                  }
                />
              )}
              {isHeating && (
                <CustomTable
                  energySource="all"
                  locale={locale}
                  tableData={tableDataHeat}
                  showExport={true}
                  exportFilename={exportFilenameHeat}
                  // tableDataHourly={tableDataHeatingHourly}
                  exportDisabled={selectedSourceType === "heating"}
                  buildingCode={buildingCode}
                  gettext={gettext}
                  handleDownloadFile={type =>
                    handleDownloadFile(type, "heating")
                  }
                />
              )}
              {isWater && (
                <CustomTable
                  energySource="all"
                  locale={locale}
                  tableData={tableDataWater}
                  showExport={true}
                  exportFilename={exportFilenameWater}
                  // tableDataHourly={tableDataWaterHourly}
                  exportDisabled={selectedSourceType === "water"}
                  buildingCode={buildingCode}
                  gettext={gettext}
                  handleDownloadFile={type => handleDownloadFile(type, "water")}
                />
              )}
              <DownloadBenchMark
                energyParams={getEnergyParams()}
                selectedSourceType={selectedSourceType}
                selectedFileType={selectedFileType}
                exportDisabled={exportDisabled}
                setExportDisabled={setExportDisabled}
                setSelectedSourceType={setSelectedSourceType}
                timeStamp={timeStamp}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EnergyComparison;
