import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GoBack } from "app/components";
import { MetersListTable } from "app/features";

export function BuildingMeters() {
  const { cid, bid, type } = useParams();

  const location = useLocation();

  const [writeAccess, setWriteAccess] = useState(false);

  useEffect(() => {
    setWriteAccess(location.pathname.split("/")[1] !== "partner");
  }, [location]);

  return (
    <>
      <GoBack
        prevUrl={
          "/buildings" + location.pathname.split("/buildings")[1] + "/buildings"
        }
      />

      {/* {writeAccess && (
        <AddMeterForm type={type} building={bid} refresh={setRefresh} />
      )} */}

      <MetersListTable
        type={type}
        building={bid}
        customer={cid}
        writeAccess={writeAccess}
        buildingAddress={location?.state?.buildingAddress}
      />
    </>
  );
}
