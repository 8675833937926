import { useQuery } from "@apollo/client";
import { GET_CUSTOMERS_ROLES } from "../commands";

export function useCustomerRoles() {
  const { data, loading } = useQuery(GET_CUSTOMERS_ROLES);

  return {
    customerRoles: data?.comCustomerRoles || [],
    loading
  };
}
