import React, { useEffect, useState, useContext, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_DEFAULT_SETTINGS } from "GraphQL/Queries";
import { useUser, useTextdomainContext } from "app/providers";
import { Button, Typography, IconButton, Tooltip } from "@mui/material";
import { IoMdColorFill } from "react-icons/io";
import { styled } from "@mui/material/styles";
import { useUpdateColors } from "app/pages/admin/default-settings/hooks";
import { ColorPicker } from "app/features/BuildingsListTable/components";
import { DataGridComponent } from "app/components";

const ColorButton = styled(Button)(({ customColor }) => ({
  textTransform: "none",
  color: customColor,
  backgroundColor: customColor,
  "&:hover": {
    backgroundColor: customColor
  }
}));

function UserSettings() {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { user } = useUser();
  const [fetchSettings, { data: settings }] = useLazyQuery(
    GET_DEFAULT_SETTINGS
  );
  const [colorMenuOpen, setColorMenuOpen] = useState(false);
  const { update, updating } = useUpdateColors();
  const [selectedColorSetting, setSelectedColorSetting] = useState({});
  const [colorSettings, setColorSettings] = useState(null);
  const [shade, setShade] = useState("shade1");

  const separateColorsByShade = (colors, shade) => {
    let separatedColors = {};
    Object.keys(colors).forEach(colorKey => {
      separatedColors[colorKey] = colors[colorKey][shade];
    });
    return separatedColors;
  };

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    if (settings) {
      const userColors = user.colorSettings
        ? JSON.parse(user.colorSettings)
        : null;
      const gColors =
        !userColors || !userColors["graph-colors"]
          ? JSON.parse(settings.allSettings[0].value)
          : userColors["graph-colors"];
      const gShade1 = separateColorsByShade(gColors, "shade1");
      const gShade2 = separateColorsByShade(gColors, "shade2");
      const gShade3 = separateColorsByShade(gColors, "shade3");
      setColorSettings([
        {
          name: gettext("Graph Colors(shade1)"),
          key: "graph-colors-(shade1)",
          value: gShade1
        },
        {
          name: gettext("Graph Colors(shade2)"),
          key: "graph-colors-(shade2)",
          value: gShade2
        },
        {
          name: gettext("Graph Colors(shade3)"),
          key: "graph-colors-(shade3)",
          value: gShade3
        }
      ]);
    }
  }, [user, settings, gettext]);

  function colorButtonFormatter(row, type) {
    return (
      <ColorButton
        variant="contained"
        size="small"
        customColor={row.value[type].background}
      >
        <Typography color={row.value[type].text}>
          {type === "co2" ? gettext("CO₂") : gettext(type)}
        </Typography>
      </ColorButton>
    );
  }

  const colorSettingsFormater = useCallback(
    row => {
      return (
        <div className="w-100 d-flex justify-content-center">
          <Tooltip title={gettext("Colors")} arrow>
            <IconButton
              disabled={user.demo}
              onClick={() => {
                const [colSettings] = colorSettings.filter(
                  col => col.key === row.key
                );
                setSelectedColorSetting(colSettings.value);
                if (row.key.includes("shade")) {
                  setShade(
                    row.key.includes("shade1")
                      ? "shade1"
                      : row.key.includes("shade2")
                      ? "shade2"
                      : row.key.includes("shade3")
                      ? "shade3"
                      : "shade1"
                  );
                }
                setColorMenuOpen(true);
              }}
            >
              <IoMdColorFill />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
    [gettext, colorSettings, user]
  );

  const columns = [
    {
      field: "Colors Type",
      headerName: gettext("Colors Type"),
      width: 150,
      headerAlign: "left",
      renderCell: params => <Typography>{params.row.name}</Typography>
    },
    {
      field: "electricity",
      headerName: gettext("Electricity"),
      headerAlign: "left",
      renderCell: params => colorButtonFormatter(params.row, "electricity")
    },
    {
      field: "heating",
      headerName: gettext("Heating"),
      headerAlign: "left",
      renderCell: params => colorButtonFormatter(params.row, "heating")
    },
    {
      field: "water",
      headerName: gettext("Water"),
      headerAlign: "left",
      renderCell: params => colorButtonFormatter(params.row, "water")
    },
    {
      field: "gas",
      headerName: gettext("Gas"),
      headerAlign: "left",
      renderCell: params => colorButtonFormatter(params.row, "gas")
    },
    {
      field: "energy",
      headerName: gettext("Energy"),
      headerAlign: "left",
      renderCell: params => colorButtonFormatter(params.row, "energy")
    },
    {
      field: "compensation",
      headerName: gettext("Compensation"),
      headerAlign: "left",
      width: 150,
      renderCell: params => colorButtonFormatter(params.row, "compensation")
    },
    {
      field: "production",
      headerName: gettext("Production"),
      headerAlign: "left",
      renderCell: params => colorButtonFormatter(params.row, "production")
    },
    {
      field: "green_electricity",
      headerName: gettext("Green Electricity"),
      headerAlign: "left",
      width: 200,
      renderCell: params =>
        colorButtonFormatter(params.row, "green_electricity")
    },
    {
      field: "co2",
      headerName: gettext("CO₂"),
      headerAlign: "left",
      renderCell: params => colorButtonFormatter(params.row, "co2")
    },
    {
      field: "colorSettings",
      headerName: gettext("Colors Settings"),
      headerAlign: "center",
      renderCell: params => colorSettingsFormater(params.row)
    }
  ];

  return (
    <form className="card card-custom">
      <div className="card card-custom switch-customer gutter-b example example-compact">
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <div className="col-7 p-0">
              <h3>{gettext("Settings")}</h3>
              <span className="font-size-sm w-100">
                {gettext("User Color Settings")}
              </span>
            </div>
          </div>
          <div className="card-body">
            <DataGridComponent
              rows={colorSettings}
              columns={columns}
              loading={updating}
              getRowId={row => row.key}
              disableSelectionOnClick={true}
            />
          </div>
        </div>
      </div>
      <ColorPicker
        open={colorMenuOpen}
        onClose={() => setColorMenuOpen(false)}
        settingsKey={"graph-colors"}
        colorSettings={selectedColorSetting}
        update={update}
        updating={updating}
        targetBuildingColors={false}
        shade={shade}
        updateUserSettings={true}
        gettext={gettext}
      />
    </form>
  );
}

export default UserSettings;
