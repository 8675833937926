import { FilterSelectButton } from "app/components";
import { useBenchmarkFilter, useTextdomainContext } from "app/providers";
import React from "react";

export function SelectBuildingOne() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const {
    buildings,
    selectedBuildingOne,
    setSelectedBuildingOne,
    filtersLoading
  } = useBenchmarkFilter();

  return (
    <FilterSelectButton
      searchable={true}
      label={gettext("Building 1")}
      options={buildings}
      loading={filtersLoading}
      getOptionLabel={option => option.address}
      value={selectedBuildingOne}
      onChange={newValue => setSelectedBuildingOne(newValue)}
    />
  );
}
