import { useMutation } from "@apollo/client";
import { useAuth } from "app/providers";
import { useCallback } from "react";
import ReactGA from "react-ga4";
import { REGISTER } from "./commands";

export function useSubmit(onCompleted = () => null, onError = () => null) {
  const { setToken } = useAuth();

  const [comRegister, { loading }] = useMutation(REGISTER, {
    onCompleted: ({ comRegister }) => {
      localStorage.clear();

      setToken(comRegister?.token);
    },
    onError: error => {
      onError(error);
    }
  });

  const submit = useCallback(
    async credentials => {
      try {
        await comRegister({
          variables: credentials
        });

        ReactGA.event({
          category: "Authentication",
          action: "SignUp",
          label: credentials?.email.replace("@", "[at]")
        });
      } catch (error) {}
    },
    [comRegister]
  );

  return {
    submit,
    isSubmitting: loading
  };
}
