import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { InformationCircle } from "comundo-icons";
import { useTextdomainContext } from "app/providers";

export function EpcNotice() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid",
        borderColor: "secondary.200",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "row",
        gap: 2
      }}
    >
      <IconButton disabled>
        <InformationCircle />
      </IconButton>

      <Typography fontSize={14} fontWeight={400}>
        {gettext(
          "Please note that the statistic shown are only for an estimate and can’t be used as an energy performance certificate for now."
        )}
      </Typography>
    </Box>
  );
}
