import { gql } from "@apollo/client";
import { ME_TYPE_FRAGMENT } from "GraphQL/Fragments";

export const SUBMIT_METERS_REGISTRATIONFLOW = gql`
  ${ME_TYPE_FRAGMENT}
  mutation submitMeters($data: [MultipleAddressesInput], $skip: Boolean) {
    addCustomerMeterRegistrationFlow(data: $data, skip: $skip) {
      success
      message
      user {
        ...MeType
      }
    }
  }
`;
