import { Grid, Typography } from "@mui/material";
import CompanyCustomerDropdown from "app/components/CompanyCustomerDropdown";
import { useTextdomainContext } from "app/providers";
import React, { useState, useContext } from "react";
import { AccountsDataFileListTable } from "../../../features";
import { FileInputButton, FileUpload } from "./components";
import { useUpload } from "./hooks";

export function AddAccountsData() {
  const [file, setFile] = useState();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomersIds, setSelectedCustomersIds] = useState(null);

  const [fileName, setFileName] = useState();

  const [fileData, setFileData] = useState();

  const [refresh, setRefresh] = useState(false);

  const { upload } = useUpload(() => {
    setFile(null);
    setFileName(null);
    setFileData(null);
    setRefresh(prev => !prev);
  });

  const handleChangeCustomers = selectedValue => {
    if (selectedValue) {
      setSelectedCustomer(selectedValue?.value);
      setSelectedCustomersIds(selectedValue?.id);
    } else {
      setSelectedCustomer(null);
      setSelectedCustomersIds(null);
    }
  };

  return (
    <Grid container spacing={4}>
      {/* <Grid item xs={12}> */}
      <Grid item container justifyContent="space-between" xs={12}>
        <Grid item xs={4}>
          <Typography variant="h5" fontWeight={"bold"}>
            {gettext("Data")}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid
            item
            container
            direction="row"
            columnSpacing={4}
            justifyContent="flex-end"
          >
            <Grid item xs={3}>
              <CompanyCustomerDropdown
                onChange={handleChangeCustomers}
                propFlag={true}
              />
            </Grid>
            <Grid item>
              <FileInputButton
                refresh={refresh}
                setFile={setFile}
                selectedCustomer={selectedCustomer}
                selectedCustomersIds={selectedCustomersIds}
                setFileName={setFileName}
                setFileData={setFileData}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* </Grid> */}
      </Grid>

      <Grid item xs={12}>
        <FileUpload
          fileName={fileName}
          fileData={fileData}
          selectedCustomer={selectedCustomer}
          onUpload={() => upload(file, fileData)}
        />
      </Grid>

      <Grid item xs={12}>
        <AccountsDataFileListTable
          refresh={refresh}
          selectedCustomersIds={selectedCustomersIds}
        />
      </Grid>
    </Grid>
  );
}
