import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Spinner } from "react-bootstrap";
import {
  GET_ACCOUNT_GROUP,
  GET_ACCOUNT_GROUPS
} from "../../../../GraphQL/Queries";
import { UPDATE_ACCOUNT_GROUP } from "../../../../GraphQL/Mutations";
import AccountGroupForm from "./account-group-form";
import { GoBackButton, AccountChartHeader } from "./groupStyle";

const AccountGroupEdit = () => {
  const { id } = useParams();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const navigate = useNavigate();
  const [nameDa, setNameDa] = useState("");
  const [nameDe, setNameDe] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [type, setType] = useState("");

  const { data } = useQuery(GET_ACCOUNT_GROUP, {
    variables: {
      id: id
    }
  });

  useEffect(() => {
    if (data) {
      const { accountGroup } = data;
      setNameDa(accountGroup.nameDa);
      setNameDe(accountGroup.nameDe);
      setNameEn(accountGroup.nameEn);
      setType(accountGroup.type);
    }
  }, [data]);

  const [updateAccountGroup, { loading: updateLoading }] = useMutation(
    UPDATE_ACCOUNT_GROUP,
    {
      onCompleted() {
        // const {
        //   insertAccountGroup: { success }
        // } = data;
        navigate(`/admin/account-groups`);
      },
      onError(error) {
        // error.graphQLErrors.map(({ message }) => {
        //   // console.log("Error =>", error);
        // });
      }
    }
  );

  const handleUpdateAccountGroup = () => {
    updateAccountGroup({
      variables: {
        id: id,
        nameDa: nameDa,
        nameDe: nameDe,
        nameEn: nameEn,
        type: type
      },
      refetchQueries: [
        { query: GET_ACCOUNT_GROUPS },
        {
          query: GET_ACCOUNT_GROUP,
          variables: {
            id: id
          }
        }
      ]
    });
  };

  const hanleGoBack = () => {
    navigate(`/admin/account-groups`);
  };

  const validForm = () => nameDa && nameDe && nameEn && type;

  return (
    <>
      <GoBackButton className="row m-0">
        <div className="col-sm-12 p-0">
          <div className="form-group col-md-2 p-0">
            <button
              type="button"
              className="btn btn-outline-info"
              onClick={hanleGoBack}
            >
              <AiOutlineArrowLeft title="back" size={18} className="mr-2" />
              {gettext("Back")}
            </button>
          </div>
        </div>
      </GoBackButton>
      <AccountChartHeader className="card card-custom gutter-b example example-compact">
        <div className="row m-0 table-title">
          <div className="col-sm-12 title">
            <h3>{gettext("Edit Account Group")}</h3>
          </div>
        </div>
        <>
          <AccountGroupForm
            nameDa={nameDa}
            nameDe={nameDe}
            nameEn={nameEn}
            type={type}
            setNameDa={setNameDa}
            setNameDe={setNameDe}
            setNameEn={setNameEn}
            setType={setType}
          />

          <div className="row m-0 border-top-row">
            <div className="col-sm-12 create-action">
              <div className="form-group col-md-12">
                <button
                  type="submit"
                  onClick={handleUpdateAccountGroup}
                  disabled={!validForm() || updateLoading}
                >
                  {gettext("Update Account Group")} &nbsp;
                  {updateLoading && (
                    <Spinner
                      className="btn-spinner-delete"
                      animation="border"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      </AccountChartHeader>
    </>
  );
};

export default AccountGroupEdit;
