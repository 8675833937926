import { Stack } from "@mui/material";
import React from "react";
import { Footer, Header, MainBody } from "./components";
import { BuildingStepProvider } from "./providers";

export const RegistrationFlowAddMeterForm = props => {
  return (
    <BuildingStepProvider>
      <Stack spacing={4}>
        <Header />

        <MainBody />

        <Footer />
      </Stack>
    </BuildingStepProvider>
  );
};
