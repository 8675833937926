import { Button, Stack, Typography } from "@mui/material";
import { useBuildingStep } from "app/features/RegistrationFlowAddMeterForm/providers";
import { useTextdomainContext } from "app/providers";
import React from "react";

export function Mode({ type }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const question = React.useMemo(() => {
    return {
      electricity: {
        ques: gettext(
          "Do you want to connect the consumption automatically or manually to building?"
        )
      },
      heating: {
        ques: gettext(
          "Do you want to connect the consumption automatically or manually to building?"
        )
      },
      gas: {
        ques: gettext(
          "Do you want to connect the consumption automatically or manually to building?"
        )
      },
      water: {
        ques: gettext(
          "Do you want to connect the consumption automatically or manually to building?"
        )
      }
    };
  }, [gettext]);

  const { setMode } = useBuildingStep();
  return (
    <Stack spacing={2} marginX={10} textAlign="center">
      <Typography fontSize={18} fontWeight={500} color="#3F3F46">
        {question[type].ques}
      </Typography>
      <Stack direction={"row"} spacing={2} justifyContent="center">
        <Button
          disableElevation
          sx={{ py: 1.2, minWidth: "100px" }}
          disabled={false}
          size="large"
          variant="outlined"
          color="secondary"
          onClick={() => setMode(type, "manual")}
        >
          {gettext("Manual")}
        </Button>

        <Button
          disableElevation
          sx={{ py: 1.2, minWidth: "100px" }}
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => setMode(type, "auto")}
        >
          {gettext("Auto")}
        </Button>
      </Stack>
    </Stack>
  );
}
