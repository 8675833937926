import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../_metronic/layout";
import { localeNumberFormat } from "../../common/Functions";
import { DECIMAL } from "../../common/Constants";
import { getOr } from "lodash/fp";
import * as Constants from "../../common/Constants";
import { useLang } from "app/providers";

export function MixedWidget3(props) {
  const { data, imageUrl, label } = props;
  let E1Color = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade1.background",
    props.propsColors
  );
  let E2Color = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade2.background",
    props.propsColors
  );
  let E3Color = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade3.background",
    props.propsColors
  );
  const randomString = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 10);
  let className = props.className;
  let chartTitle = props?.data?.chart_title
    ? props?.data?.chart_title.replace("CO2", "CO₂")
    : "";
  let uniqueId = props?.data?.unique_id ? props?.data?.unique_id : randomString;
  let media_type = props?.data?.media_type ? props?.data?.media_type : "web";
  let objectId = "kt_mixed_widget_1_chart" + uniqueId + "_" + media_type;

  const title = props?.data?.chart_title;

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);
  const locale = useLang();

  useEffect(() => {
    if (data) {
      const element = document.getElementById(objectId);
      if (!element) {
        return;
      }
      const options = getChartOptions(
        data,
        E1Color,
        E2Color,
        E3Color,
        label,
        locale
      );

      const chart = new ApexCharts(element, options);
      chart
        .render()
        .then(() => {
          if (typeof imageUrl === "function") {
            chart.dataURI().then(uri => {
              imageUrl(uri.imgURI);
            });
          }
        })
        .catch(err => {
          return true;
        });
      return function cleanUp() {
        chart.destroy();
      };
    }
  }, [
    data,
    imageUrl,
    title,
    layoutProps,
    objectId,
    E1Color,
    E2Color,
    E3Color,
    label,
    locale
  ]);

  return (
    <>
      <div className={`card card-custom bg-gray-100 ${className}`}>
        {/* Header */}
        {chartTitle && (
          <div className="card-header border-0 bg-gray-100 py-5">
            <h3 className="card-title font-weight-bolder text-black">
              {/* {chartTitle === "Gas CO₂ measured in Tons"
                ? "Natural Gas CO₂ measured in Tons"
                : chartTitle ===
                  "Gas consumption and compensation measured in kWh"
                ? "Natural Gas consumption and compensation measured in kWh"
                : chartTitle} */}
              {chartTitle}
            </h3>
          </div>
        )}
        {/* Body */}
        <div className="card-body p-0 position-relative overflow-hidden">
          {/* Chart */}
          <div
            id={objectId}
            className="card-rounded-bottom bg-gray-100"
            style={{ height: "200px" }}
          ></div>

          {/* Resize */}
          <div className="resize-triggers">
            <div className="expand-trigger">
              <div style={{ width: "411px", height: "461px" }} />
            </div>
            <div className="contract-trigger" />
          </div>
        </div>
      </div>
    </>
  );
}

export function getChartOptions(
  data,
  E1Color,
  E2Color,
  E3Color,
  label,
  locale
) {
  // const strokeColor = "#D13647";
  // const strokeColor = "#426a2f";
  const typeChecked = data.data.findIndex(item => item.type === "column");

  const options = {
    series: data.data.filter(
      graphData => Array.isArray(graphData.data) && graphData.name
    ),

    chart: {
      type: typeChecked !== -1 ? "line" : "bar",
      height: 250,
      stacked: data.stacked,
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: typeChecked !== -1 ? "100%" : "55%",
        dataLabels: {
          position: "top" // top, center, bottom
        },
        distributed: data.plotOptionsBarDistributed
        // endingShape: 'rounded',
      }
    },
    dataLabels: {
      enabled: data.dataLabelsEnabled,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"]
      },
      formatter: function(val) {
        return localeNumberFormat(val, DECIMAL);
      },
      rotate: -90
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"]
    },
    xaxis: {
      title: { text: data.xaxisTitleText },
      categories: data.categories,
      logarithmic: true,
      tooltip: {
        enabled: false
        // offsetY: -10,
        // formatter: function(val, opts) {
        //     return null;
        // },
      }
    },

    yaxis: {
      title: {
        text: data.yaxisTitleText
          ? data.yaxisTitleText.replace("CO2", "CO₂")
          : ""
        // rotate: -90,
      },

      axisTicks: { show: true },

      labels: {
        formatter: function(value) {
          if (locale === "en") {
            return localeNumberFormat(value, DECIMAL);
          } else if (locale === "da") {
            const numValue = Number(value);
            // const newStringValue = numValue.toFixed(2).toLocaleString();
            const newStringValue = numValue
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            const updatedValue = newStringValue
              .replace(/\./g, "#")
              .replace(/,/g, ".")
              .replace(/#/g, ",");
            return updatedValue;
          } else {
            return localeNumberFormat(value, DECIMAL);
          }
        }
      },
      forceNiceScale: true

      // logarithmic: true,
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function(value) {
          if (locale === "en") {
            const numValue = Number(value);
            const newStringValue = numValue
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return newStringValue;
          } else if (locale === "da") {
            const numValue = Number(value);

            const newStringValue = numValue
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            const updatedValue = newStringValue
              .replace(/\./g, "#")
              .replace(/,/g, ".")
              .replace(/#/g, ",");
            return updatedValue;
          } else {
            const numValue = Number(value);
            const newStringValue = numValue
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return newStringValue;
          }
        }
      },
      markers: {
        show: false
      }
    },
    legend: {
      markers: {
        width: 30
      }
    },
    markers: {
      show: false
    },
    colors: data.colors
  };

  return options;
}
