import { Grid } from "@mui/material";
import { useUser } from "app/providers";
import React from "react";
import { CodeBlock, HtmlWebPreview } from "./components";

export function CodePreviewTab({ htmlFilePath }) {
  const [htmlString, setHtmlString] = React.useState("");

  const { user } = useUser();

  const { customerKey } = user || {};

  React.useEffect(() => {
    const fetchHtmlFile = async () => {
      const response = await fetch(htmlFilePath);
      const htmlString = await response.text();

      setHtmlString(
        htmlString.replace(
          /fabekebackendurl|fabekefrontendurl|loggedinusercustomerkey/g,
          function(match) {
            if (match === "fabekebackendurl") {
              return process.env.REACT_APP_API_URL;
            } else if (match === "fabekefrontendurl") {
              return process.env.REACT_APP_URL;
            } else if (match === "loggedinusercustomerkey") {
              return customerKey;
            }
          }
        )
      );
    };
    fetchHtmlFile();
  }, [htmlFilePath, customerKey]);

  return (
    <Grid
      container
      spacing={0}
      sx={{ height: "65vh", maxHeight: 700, backgroundColor: "secondary.100" }}
    >
      <Grid item xs={12} sm={6} sx={{ height: "100%" }}>
        <HtmlWebPreview htmlCode={htmlString} />
      </Grid>

      <Grid item xs={12} sm={6} sx={{ height: "100%" }}>
        <CodeBlock language={"html"}>{htmlString}</CodeBlock>
      </Grid>
    </Grid>
  );
}
