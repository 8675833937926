import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth, useUser } from "app/providers";
import { useMe } from "hooks";
import { LayoutSplashScreen } from "_metronic/layout";

export function AcquireLogin() {
  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useMe();

  const { user } = useUser();

  const { isRegistered, teams } = user || {};

  useEffect(() => {
    if (isRegistered !== undefined) {
      if (!isRegistered || !teams.length) {
        navigate("/registration-flow");
      } else navigate(pathname);
    }
  }, [isRegistered, navigate, pathname, teams]);

  return isAuthenticated ? (
    Boolean(user) ? (
      <Outlet />
    ) : (
      <LayoutSplashScreen />
    )
  ) : (
    <Navigate to="/auth/login" />
  );
}
