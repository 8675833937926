import { gql } from "@apollo/client";
import { ME_TYPE_FRAGMENT } from "GraphQL/Fragments";

export const UPDATE_CURRENT_USER_PROFILE = gql`
  ${ME_TYPE_FRAGMENT}
  mutation comUpdateAccount(
    $countryCode: String
    $currentTeam: String
    $currentTeamId: String
    $customerType: String
    $facebookLink: String
    $file: String
    $lang: String
    $name: String
    $profilePhotoPath: String
    $provider: String
    $registrationStep: Int
    $timezone: String
    $twitterLink: String
  ) {
    comUpdateAccount(
      countryCode: $countryCode
      currentTeam: $currentTeam
      currentTeamId: $currentTeamId
      customerType: $customerType
      facebookLink: $facebookLink
      file: $file
      lang: $lang
      name: $name
      profilePhotoPath: $profilePhotoPath
      provider: $provider
      registrationStep: $registrationStep
      timezone: $timezone
      twitterLink: $twitterLink
    ) {
      success
      message
      user {
        ...MeType
      }
    }
  }
`;
