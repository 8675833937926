import React, { forwardRef } from "react";
import { AppBar as AppBarTop, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { CustomerSelector, Notification, Profile } from "./components";
import { LanguageSelector } from "app/components";
import { Menu } from "comundo-icons";

export const AppBar = forwardRef((props, ref) => {
  const { drawerWidth, toggleDrawer } = props;

  return (
    <AppBarTop
      ref={ref}
      position={"fixed"}
      color="inherit"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        boxShadow: "none",
        borderBottom: "1px solid #E4E4E7"
      }}
    >
      <Toolbar>
        <IconButton
          color="secondary"
          edge="start"
          sx={{ display: { xs: "flex", sm: "none" }, justifyContent: "center" }}
          onClick={toggleDrawer}
        >
          <Menu color={"inherit"} />
        </IconButton>

        {/* <OutlinedInput
          sx={{ color: "#D4D4D8", backgroundColor: "#FAFAFA", width: "356px" }}
          size="small"
          placeholder={gettext("Search")}
          startAdornment={
            <InputAdornment position="start" style={{ color: "#D4D4D8" }}>
              <SearchIcon />
            </InputAdornment>
          }
        /> */}

        <Box sx={{ flexGrow: 1 }} />

        <Notification />

        <CustomerSelector />

        <LanguageSelector />

        <Profile />
      </Toolbar>
    </AppBarTop>
  );
});
