import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useUser } from "../UserProvider";

const CustomerPermissionContext = createContext();

const CUSTOMER_USER = "Customer User";
const CUSTOMER_ADMIN = "Customer Admin";
const CUSTOMER_OWNER = "Customer Owner";

export function useCustomerPermission() {
  const { canRead, canEdit, canDelete, canAccessPage } = useContext(
    CustomerPermissionContext
  );

  return {
    canRead,
    canEdit,
    canDelete,
    canAccessPage
  };
}

export default function CustomerPermissionProvider({ children }) {
  const { user } = useUser();

  const { isSuperAdmin, currentTeamId, teams, demo } = user || {};

  const role = useMemo(() => {
    const team = teams?.find(team => {
      return team.Id === currentTeamId;
    });

    return team?.role;
  }, [currentTeamId, teams]);

  const canAccessPage = useCallback(
    module => {
      if (isSuperAdmin) return true;

      switch (module) {
        case "prediction":
          // const module = user?.modulePermissions.find(
          //   item => item.code === "prediction"
          // );
          // if (module?.read || module?.write) {
          //   return true;
          // }
          return isSuperAdmin;

        // break;

        default:
          break;
      }
      return true;
    },
    [isSuperAdmin]
  );

  const canRead = useCallback(
    module => {
      if (isSuperAdmin) return true;

      switch (module) {
        case "changePassword":
          if (demo) {
            return false;
          } else if (!demo) {
            return true;
          }

          break;

        case "prediction":
          if (role === CUSTOMER_USER) {
            return true;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "customer-roles":
          if (role === CUSTOMER_USER) {
            return false;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "api-key":
          if (demo) {
            return false;
          } else {
            if (role === CUSTOMER_USER) {
              return true;
            } else if (role === CUSTOMER_ADMIN) {
              return true;
            } else if (role === CUSTOMER_OWNER) {
              return true;
            }
          }

          break;

        case "public-page":
          if (role === CUSTOMER_USER) {
            return true;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "administration":
          if (demo) {
            return false;
          } else {
            if (role === CUSTOMER_USER) {
              return false;
            } else if (role === CUSTOMER_ADMIN) {
              return true;
            } else if (role === CUSTOMER_OWNER) {
              return true;
            }
          }

          break;

        case "map":
          if (role === CUSTOMER_USER) {
            return true;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        default:
          break;
      }
      return true;
    },
    [isSuperAdmin, role, demo]
  );

  const canEdit = useCallback(
    module => {
      if (isSuperAdmin) return true;

      switch (module) {
        case "customer-roles":
          if (role === CUSTOMER_USER) {
            return false;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "prediction":
          if (role === CUSTOMER_USER) {
            return true;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "api-key":
          if (role === CUSTOMER_USER) {
            return false;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "public-page":
          if (role === CUSTOMER_USER) {
            return false;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "administration":
          if (demo) {
            return false;
          } else {
            if (role === CUSTOMER_USER) {
              return false;
            } else if (role === CUSTOMER_ADMIN) {
              return true;
            } else if (role === CUSTOMER_OWNER) {
              return true;
            }
          }

          break;

        case "map":
          if (role === CUSTOMER_USER) {
            return true;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        default:
          break;
      }
      return true;
    },
    [isSuperAdmin, role, demo]
  );

  const canDelete = useCallback(
    module => {
      if (isSuperAdmin) return true;

      switch (module) {
        case "customer-roles":
          if (role === CUSTOMER_USER) {
            return false;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "prediction":
          if (role === CUSTOMER_USER) {
            return true;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "api-key":
          if (role === CUSTOMER_USER) {
            return false;
          } else if (role === CUSTOMER_ADMIN) {
            return true;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "public-page":
          if (role === CUSTOMER_USER) {
            return false;
          } else if (role === CUSTOMER_ADMIN) {
            return false;
          } else if (role === CUSTOMER_OWNER) {
            return true;
          }

          break;

        case "administration":
          if (demo) {
            return false;
          } else {
            if (role === CUSTOMER_USER) {
              return false;
            } else if (role === CUSTOMER_ADMIN) {
              return false;
            } else if (role === CUSTOMER_OWNER) {
              return true;
            }
          }

          break;

        case "map":
          if (role === CUSTOMER_USER) {
            return false;
          } else if (role === CUSTOMER_ADMIN) {
            return false;
          } else if (role === CUSTOMER_OWNER) {
            return false;
          }

          break;

        default:
          break;
      }
      return true;
    },
    [isSuperAdmin, role, demo]
  );

  const value = {
    canRead,
    canEdit,
    canDelete,
    canAccessPage
  };

  return (
    <CustomerPermissionContext.Provider value={value}>
      {children}
    </CustomerPermissionContext.Provider>
  );
}
