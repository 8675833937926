import { useState, useEffect, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  getUnitDateOption,
  getHourlyExportDates
  // calculateExportData
} from "../../../../hooks/useGCommon";
import {
  // useGetByAddresses,
  useGetByAddress
  // useAggregatedData
} from "../../../../services/dashboardService";
import {
  getTransformEnergyCalculation,
  getChartData,
  getChartOptions,
  getEnergyPageTransformCalculation
} from "./GConstant";
import {
  GET_PREDICTION_DATA_QUERY,
  GET_WATER_QUERY
} from "../../../../GraphQL/Queries";

export const ExportDataHourly = props => {
  const {
    // customers,
    // buildingCode,
    meter,
    // type,
    segment,
    // date
    startDate,
    endDate
    // addressesList
  } = props;
  const { unitStart, unitEnd } = getUnitDateOption(segment, startDate, endDate);
  const [exportedData, setExportedData] = useState({
    title: "",
    heading: [],
    content: []
  });

  const { fromDate, toDate } = getHourlyExportDates(
    segment,
    // unit,
    unitStart,
    unitEnd,
    "yyyy-mm-dd"
  );
  let exportData = { isLoading: false, data: [] };
  // const { data: exportData } = useGetByAddresses({
  //   type,
  //   customer: customers,
  //   addresses: buildingCode === "all" ? addressesList : buildingCode,
  //   from: fromDate,
  //   to: toDate,
  //   resolution: "h",
  //   unit: "all"
  // });
  useEffect(() => {
    // if (!exportData.isLoading && exportData.data.data) {
    //   const res = calculateExportData(exportData.data.data, meter);
    //   setExportedData(res);
    // }
    setExportedData({
      title: "",
      heading: [],
      content: []
    });
  }, [meter, fromDate, toDate]);

  return {
    data: exportedData,
    exportDisabled: exportData.isLoading,
    unitStart,
    unitEnd,
    electricityGreen: exportData?.data?.electricity_green
  };
};

export const useReportConsumerEngergyData = (props, colorSettings, page) => {
  const {
    customers,
    buildingCode,
    meter,
    type,
    segment,
    // date,
    startDate,
    endDate,
    selectedSegment,
    sourceType
  } = props;

  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { unitStart, unitEnd } = getUnitDateOption(segment, startDate, endDate);
  // console.log(segment, startDate, endDate);

  // const [greenStatus, setGreenStatus] = useState(false);

  const [energyData, setEnergyData] = useState({
    tableData: { title: "", heading: [], content: [] },
    chartData1: getChartData(
      getChartOptions("chartData1", "", "", [], [], "", [], ""),
      colorSettings,
      type
    ),
    chartData2: getChartData(
      getChartOptions("chartData2", "", "", [], [], "", [], ""),
      colorSettings,
      type
    ),
    chartData3: getChartData(
      getChartOptions("chartData3", "", "", [], [], "", [], ""),
      colorSettings,
      type,
      "co2"
    ),
    rateCo2ChartData: getChartData(
      getChartOptions("rateCo2ChartData", "", "", [], [], "", [], ""),
      colorSettings,
      type
    ),
    priceKWHChartData: getChartData(
      getChartOptions("priceKWHChartData", "", "", [], [], "", [], ""),
      colorSettings,
      type
    ),
    costDKKChartData: getChartData(
      getChartOptions("costDKKChartData", "", "", [], [], "", [], ""),
      colorSettings,
      type
    )
  });
  const { fromDate, toDate } = getHourlyExportDates(
    segment,
    unitStart,
    unitEnd
  );
  const { data: energiesData, loading } = useQuery(GET_WATER_QUERY, {
    variables: {
      meterType: type,
      meteringPointId: meter,
      address: buildingCode,
      customer: customers,
      fromDate: fromDate,
      toDate: toDate,
      resolution: segment,
      fileType: "excel"
    }
  });

  const [getPredictionData, { data: energiesPredictionData }] = useLazyQuery(
    GET_PREDICTION_DATA_QUERY,
    {
      variables: {
        meterType: type,
        meteringPointId: meter,
        address: buildingCode,
        customer: customers,
        fromDate: fromDate,
        toDate: toDate,
        resolution: segment,
        fileType: "excel"
      }
    }
  );

  // const { data: energiesData, loading } = useQuery(
  //     GET_PREDICTION_DATA_QUERY,
  //     {
  //         variables: {
  //             meterType: type,
  //             meteringPointId: meter,
  //             address: buildingCode,
  //             customer: customers,
  //             fromDate: fromDate,
  //             toDate: toDate,
  //             resolution: segment,
  //             fileType: "excel",
  //         },
  //     }
  // );

  const [greenElectricity, setGreenElectricity] = useState(false);

  useEffect(() => {
    if (page) {
      getPredictionData();
    }
  }, [getPredictionData, page]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!energiesData) {
      setEnergyData({
        tableData: { title: "", heading: [], content: [] },
        chartData1: getChartData(
          getChartOptions("chartData1", "", "", [], [], "", [], ""),
          colorSettings,
          type
        ),
        chartData2: getChartData(
          getChartOptions("chartData2", "", "", [], [], "", [], ""),
          colorSettings,
          type
        ),
        chartData3: getChartData(
          getChartOptions("chartData3", "", "", [], [], "", [], ""),
          colorSettings,
          type,
          "co2"
        ),
        rateCo2ChartData: getChartData(
          getChartOptions("rateCo2ChartData", "", "", [], [], "", [], ""),
          colorSettings,
          type
        ),
        priceKWHChartData: getChartData(
          getChartOptions("priceKWHChartData", "", "", [], [], "", [], ""),
          colorSettings,
          type
        ),
        costDKKChartData: getChartData(
          getChartOptions("costDKKChartData", "", "", [], [], "", [], ""),
          colorSettings,
          type
        )
      });
    } else {
      let metersData;
      let metersDataPredicted = null;
      if (page) {
        metersDataPredicted = energiesPredictionData?.metersDataPredicted;
      }
      metersData = energiesData?.metersData;

      if (metersData) {
        // energyResposne && energyResposne.length > 0
        const chartData = getTransformEnergyCalculation(
          metersData,
          metersDataPredicted,
          type,
          selectedSegment,
          // unit,
          sourceType,
          // date,
          startDate,
          endDate,
          fromDate,
          toDate,
          colorSettings,
          gettext,
          page
        );
        if (chartData) {
          setEnergyData(chartData);
        }
        setGreenElectricity(metersData.electricityGreen);
      }
    }
  }, [
    meter,
    energiesPredictionData,
    page,
    selectedSegment,
    sourceType,
    // date,
    startDate,
    endDate,
    type,
    // unit,
    unitStart,
    unitEnd,
    energiesData,
    loading,
    fromDate,
    toDate,
    colorSettings,
    gettext
  ]);

  return { data: energyData, loading: loading, greenElectricity };
};

export const GetEnergyPageReportConsumerData = props => {
  const {
    customers,
    buildingCode,
    meter,
    type,
    segment,
    // date,
    startDate,
    endDate,
    selectedSegment,
    sourceType
  } = props;
  const { unit } = getUnitDateOption(segment, startDate, endDate);
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  // const [greenStatus, setGreenStatus] = useState(false);

  const [energyData, setEnergyData] = useState({
    tableData: { title: "", heading: [], content: [] },
    chartData1: getChartData(
      getChartOptions("chartData1", "", "", [], [], "", [], ""),
      type
    ),
    chartData2: getChartData(
      getChartOptions("chartData2", "", "", [], [], "", [], ""),
      type
    ),
    chartData3: getChartData(
      getChartOptions("chartData3", "", "", [], [], "", [], ""),
      type,
      "co2"
    )
  });
  const { fromDate, toDate } = getHourlyExportDates(segment, unit);

  const { data: response1 } = useGetByAddress({
    type: "electricity",
    customer: customers,
    address: buildingCode,
    meter: meter,
    from: fromDate,
    to: toDate,
    resolution: segment,
    unit: unit
  });
  const { data: response2 } = useGetByAddress({
    type: "heating",
    customer: customers,
    address: buildingCode,
    meter: meter,
    from: fromDate,
    to: toDate,
    resolution: segment,
    unit: unit
  });
  const { data: response3 } = useGetByAddress({
    type: "water",
    customer: customers,
    address: buildingCode,
    meter: meter,
    from: fromDate,
    to: toDate,
    resolution: segment,
    unit: unit
  });

  useEffect(() => {
    if (!response1.isLoading && !response2.isLoading && !response3.isLoading) {
      const responseParams1 = {
        data: response1,
        type: "electricity"
      };
      const responseParams2 = {
        data: response2,
        type: "heating"
      };
      const responseParams3 = {
        data: response3,
        type: "water"
      };
      const energyList = [responseParams1, responseParams2, responseParams3];
      const chartData = getEnergyPageTransformCalculation(
        energyList,
        selectedSegment,
        unit,
        sourceType,
        // date,
        startDate,
        endDate,
        gettext
      );
      if (chartData) {
        setEnergyData(chartData);
      }
    }
  }, [
    response1,
    response2,
    response3,
    meter,
    selectedSegment,
    sourceType,
    // date,
    startDate,
    endDate,
    type,
    unit,
    gettext
  ]);

  return { data: energyData, loading: response1.isLoading };
};
