export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function changeHandler(event, form, setForm, error, setError) {
  if (
    error &&
    error.toLowerCase().includes(event.target.name) &&
    event.target.value
  ) {
    setError(null);
  }
  setForm({ ...form, [event.target.name]: event.target.value });
}
