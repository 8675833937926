import { Button, Stack, Typography } from "@mui/material";
import { useStep, useTextdomainContext } from "app/providers";
import React, { useCallback, useContext, useState } from "react";
import { AddressesInput } from "./components";
import { useSubmit } from "./hooks";

export const RegistrationFlowAddBuildingForm = props => {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { refreshStep, handleBack } = useStep();

  const [newBuildings, setNewBuildings] = useState([]);

  const [buildingError, setBuildingError] = useState(false);

  const { submit, submiting } = useSubmit(() => {
    refreshStep();
  });

  const handleSubmit = useCallback(
    e => {
      if (newBuildings.length) {
        submit({
          buildings: newBuildings
        });
      } else {
        setBuildingError(gettext("Please select building address"));
      }
    },
    [submit, newBuildings, setBuildingError, gettext]
  );

  return (
    <Stack spacing={4}>
      <Typography fontSize={32} fontWeight={700}>
        {gettext("Add Building")}
      </Typography>

      <AddressesInput
        setNewBuildings={setNewBuildings}
        newBuildings={newBuildings}
        buildingError={buildingError}
        setBuildingError={setBuildingError}
      />

      <Stack direction={"row"} spacing={4}>
        <Button
          disableElevation
          sx={{ py: 1.5 }}
          disabled={submiting}
          fullWidth
          size="large"
          onClick={handleBack}
          variant="outlined"
          color="secondary"
        >
          {gettext("Back")}
        </Button>

        <Button
          disableElevation
          sx={{ py: 1.5 }}
          disabled={!newBuildings.length || submiting}
          onClick={handleSubmit}
          fullWidth
          size="large"
          variant="contained"
          color="secondary"
        >
          {gettext("Next")}
        </Button>
      </Stack>
    </Stack>
  );
};
