import React, { useState } from "react";
import { DataGrid, deDE, daDK, enUS } from "@mui/x-data-grid";
import {
  createTheme,
  LinearProgress,
  ThemeProvider,
  useTheme
} from "@mui/material";
import { useLang } from "app/providers";

const useGridTheme = () => {
  const locale = useLang();
  const theme = useTheme();
  return createTheme(
    theme,
    locale === "de" ? deDE : locale === "da" ? daDK : enUS
  );
};

export function DataGridComponent(props) {
  const theme = useGridTheme();
  const {
    rows,
    columns,
    loading,
    getRowId,
    disableSelectionOnClick,
    CustomToolbar
  } = props;

  const [pageSize, setPageSize] = useState(10);

  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <DataGrid
          style={{ zIndex: 0 }}
          sx={{
            backgroundColor: "rgb(247, 252, 250)",

            ml: "10px",
            mr: "5px",
            border: "none"
            // ".MuiDataGrid-columnSeparator": {
            //   display: "none"
            // }
          }}
          {...props}
          rows={rows ? rows : []}
          columns={columns}
          loading={loading}
          getRowId={getRowId}
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 50, 100]}
          disableSelectionOnClick={disableSelectionOnClick}
          components={{
            Toolbar: CustomToolbar ? CustomToolbar : null,
            LoadingOverlay: LinearProgress
          }}
          autoHeight
          disableColumnMenu
        />
      </ThemeProvider>
    </div>
  );
}
