import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  from
} from "@apollo/client";
import React, { useMemo } from "react";
// import { useTextdomainContext } from "app/providers";
import { onError } from "@apollo/client/link/error";
import { useAuth } from "../AuthProvider";
import { useNavigate } from "react-router-dom";
// import { useSnackbar } from "notistack";

const { REACT_APP_API_URL_GRAPHQL } = process.env;

export default function GraphQLProvider({ children }) {
  const { token } = useAuth();
  // const { TextdomainContext } = useTextdomainContext();
  // const { gettext } = useContext(TextdomainContext);

  const navigate = useNavigate();

  // const { enqueueSnackbar } = useSnackbar();

  const authMiddleware = authToken =>
    new ApolloLink((operation, forward) => {
      if (authToken) {
        operation.setContext({
          headers: {
            authorization: `Bearer ${authToken}`
          }
        });
      }

      return forward(operation);
    });

  const Client = useMemo(() => {
    const httpLink = new createHttpLink({
      uri: REACT_APP_API_URL_GRAPHQL
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          switch (message) {
            case "440":
              // enqueueSnackbar(
              //   translate("NOTIFICATION.WARNING.SESSION_EXPIRED"),
              //   {
              //     variant: "warning"
              //   }
              // );
              navigate("/logout");
              break;

            default:
              break;
          }
        });
    });

    return new ApolloClient({
      link: authMiddleware(token).concat(from([errorLink, httpLink])),
      cache: new InMemoryCache({
        addTypename: false
      })
    });
  }, [token, navigate]);

  return <ApolloProvider client={Client}>{children}</ApolloProvider>;
}
