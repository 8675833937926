export const warning = {
  50: "#FFF9EC",
  100: "#FFF3D9",
  200: "#FFEDC6",
  300: "#FFE7B3",
  400: "#FFE2A1",
  500: "#FFDC8E",
  600: "#FFD67B",
  700: "#FFD068",
  800: "#FFCA55",
  900: "#FFC442"
};
