import { Grid } from "@mui/material";
import DataHubTable from "./components/DataHubTable";
import { useDataHubMeterDetailsYearly } from "./hooks";

const MeterDetailsYearly = props => {
  const {
    view,
    setView,
    meteringPointId,
    date,
    setDate,
    selectedSegment,
    setSelectedSegment,
    gettext
  } = props;
  const { meterDetailYearly, loading } = useDataHubMeterDetailsYearly(
    meteringPointId,
    date
  );

  return (
    <Grid item>
      <DataHubTable
        title={view}
        setView={setView}
        view={view}
        rows={meterDetailYearly}
        selectededDate={date}
        segment={selectedSegment}
        getSelectedDate={(date, selectedSegment) => {
          setDate(date);
        }}
        onSegmentChange={selectedSegment => {
          setDate(date);
          setSelectedSegment(selectedSegment);
        }}
        gettext={gettext}
        loading={loading}
      />
    </Grid>
  );
};
export default MeterDetailsYearly;
