const SCOPE_TYPE = `
  scope1
  scope2
  scope3
  outOfScope
`;

const SHADE_TYPE = `
  shade1{
    background 
    text 
  }
  shade2{
    background 
    text 
  }
  shade3{
    background 
    text 
  }
`;

export { SCOPE_TYPE, SHADE_TYPE };
