import { Box, Grid } from "@mui/material";
import React from "react";
import {
  SelectBuildingOne,
  SelectBuildingTwo,
  SelectDate,
  SelectEnergySource
} from "./components";

export function BenchmarkFilters() {
  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: "secondary.50",
        border: "1px solid",
        borderColor: "secondary.200",
        borderRadius: "8px"
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={"auto"}>
          <SelectBuildingOne />
        </Grid>

        <Grid item xs={12} sm={"auto"}>
          <SelectBuildingTwo />
        </Grid>

        <Grid item xs={12} sm={"auto"}>
          <SelectEnergySource />
        </Grid>

        <Grid item xs={12} sm textAlign="end">
          <SelectDate />
        </Grid>
      </Grid>
    </Box>
  );
}
