import React, { useState, useEffect, useMemo, useContext } from "react";
import { injectIntl } from "react-intl";
import Select from "react-select";
import { Paper } from "@mui/material";
import { useLang, useTextdomainContext } from "app/providers";

function EpcFilters({ buildingOptions, buildingsLoading, applyFilers }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const lang = useLang();
  const [buildingValue, setbuildingValue] = useState(buildingOptions[0]);
  const [typeValue, settypeValue] = useState("");
  const [yearValue, setyearValue] = useState("");
  const typeOptions = useMemo(() => {
    if (lang) {
      return [
        { value: "energy", label: gettext("Energy") },
        {
          value: "electricity",
          label: gettext("Electricity")
        },
        { value: "heating", label: gettext("Heating") },
        { value: "water", label: gettext("Water") },
        { value: "gas", label: gettext("Natural Gas") }
      ];
    }
  }, [lang, gettext]);
  const YearOptions = useMemo(() => {
    let years = [];
    const lastYear = 2016;
    let currentYear = new Date().getFullYear() - 1;
    while (lastYear < currentYear) {
      years.push({ value: `${currentYear}`, label: `${currentYear}` });
      currentYear--;
    }
    return years;
  }, []);

  useEffect(() => {
    buildingOptions && setbuildingValue(buildingOptions[0]);
    typeOptions && settypeValue(typeOptions[0]);
    YearOptions && setyearValue(YearOptions[0]);
  }, [buildingOptions, typeOptions, YearOptions]);

  useEffect(() => {
    if (buildingValue && typeValue && yearValue) {
      applyFilers(buildingValue.value, typeValue.value, yearValue.value);
    }
  }, [buildingValue, typeValue, yearValue, applyFilers]);

  function handleBuildingChange(value) {
    setbuildingValue(value);
  }

  function handleTypeChange(value) {
    settypeValue(value);
  }

  function handleYearChange(value) {
    setyearValue(value);
  }

  return (
    <>
      <Paper elevation={22} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <div className="row">
          <div className="my-2 col-sm-12 col-lg-4">
            <label className="form-label">{gettext("Building")}</label>
            <Select
              options={buildingOptions}
              value={buildingValue}
              onChange={value => handleBuildingChange(value)}
              isLoading={buildingsLoading}
            />
          </div>
          <div className="my-2 col-sm-12 col-lg-4">
            <label className="form-label">{gettext("Type")}</label>
            <Select
              options={typeOptions}
              value={typeValue}
              onChange={value => handleTypeChange(value)}
            />
          </div>
          <div className="my-2 col-sm-12 col-lg-4">
            <label className="form-label">{gettext("Year")}</label>
            <Select
              options={YearOptions}
              value={yearValue}
              onChange={value => handleYearChange(value)}
            />
          </div>
        </div>
      </Paper>
    </>
  );
}

export default injectIntl(EpcFilters);
