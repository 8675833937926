import React, { useState } from "react";
import { injectIntl } from "react-intl";
import Select from "react-select";
import { useUser } from "app/providers";

function CustomerDropdown(props) {
  const { user } = useUser();
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const { teams } = user || {};

  // let selectedCustomer = useMemo(() => {
  //     return [{ value: currentTeam, label: currentTeam, id: currentTeamId }];
  // }, [currentTeam, currentTeamId]);

  // let key = "selectedCustomerDropdown";

  let handleChangeDashboards = selectedOption => {
    setSelectedCustomer(selectedOption);
    props.onChange && props.onChange(selectedOption);
  };

  // React.useEffect(() => {
  //     let resultFilters = localStorage.getItem("resultFilters");
  //     console.log("her eis the sss,re", resultFilters);
  //     if (props.sessionStorage) {
  //         setSelectedCustomer(resultFilters?.customerId);
  //     }
  // }, []);

  return (
    <Select
      options={teams?.map(({ name, Id }) => ({
        label: name,
        value: name,
        id: Id
      }))}
      onChange={handleChangeDashboards}
      value={selectedCustomer}
      fullWidth
      sx={{ zIndex: 111111 }}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isSearchable={true}
      isClearable={true}
    />
  );
}

export default injectIntl(CustomerDropdown);
