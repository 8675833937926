import React from "react";
import { Table } from "react-bootstrap";
import "../../index.scss";
import "react-datepicker/dist/react-datepicker.css";

const GhgCustomTable = props => {
  const { tableData, gettext, tableTitle } = props;

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const TableHeading = tableData => {
    return (
      <thead>
        <tr>
          {tableData.tableHeadings.map((item, index) => {
            return (
              <th
                className={
                  index === 0 ? "table-heading-left" : "table-heading-center "
                }
                colSpan={index === 0 ? "5" : tableData.totalCountryCodes}
                key={index}
              >
                {item ? item : ""}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  // const TableRowslevel3 = level3Data => {
  //   return (
  //     <>
  //       <tr>
  //         {level3Data.data.map((item, index) => (
  //           <td
  //             className={
  //               index === 0 ? "table-data-left-level3" : "table-data-center"
  //             }
  //             colSpan={index === 0 ? "5" : tableData.totalCountryCodes}
  //             key={index}
  //           >
  //             {item ? item : ""}
  //           </td>
  //         ))}
  //       </tr>
  //     </>
  //   );
  // };

  // const TableRowslevel2 = level2Data => {
  //   return (
  //     <>
  //       <tr>
  //         {level2Data.data.map((item, index) => (
  //           <td
  //             className={
  //               index === 0 ? "table-data-left-level2" : "table-data-center"
  //             }
  //             colSpan={index === 0 ? "5" : tableData.totalCountryCodes}
  //             key={index}
  //           >
  //             {item ? item : ""}
  //           </td>
  //         ))}
  //       </tr>

  //       {level2Data.level3.length &&
  //         level2Data.level3.map((level3item, index) =>
  //           TableRowslevel3(level3item)
  //         )}
  //     </>
  //   );
  // };

  const TableRowslevel1 = tableData => {
    return (
      <tbody>
        {tableData.content.map((row, index) => (
          <>
            <tr
              key={index}
              className={row[0] === 1 && "table-data-left-level1"}
            >
              {row.map((item, index) =>
                // console.log(
                //     "66666666666666",
                //     row[0],
                //     row[0] === 2
                // )
                index !== 0 ? (
                  <td
                    // className={
                    //     (row[0] === 1
                    //         ? "table-data-left-level1 "
                    //         : row[0] === 2
                    //         ? "table-data-left-level2"
                    //         : row[0] === 3
                    //         ? "table-data-left-level3"
                    //         : " table-level1",
                    //     index !== 1 && "table-data-center")
                    // }

                    className={
                      index === 1
                        ? row[0] === 2
                          ? "table-data-left-level2"
                          : row[0] === 3
                          ? "table-data-left-level3"
                          : row[0] === 1
                          ? "table-data-left-level1"
                          : row[0] === 4
                          ? "table-data-left-level4"
                          : "table-data-left-level1"
                        : "table-data-center"
                    }
                    colSpan={index === 1 ? "5" : "1"}
                    key={index}
                  >
                    {item}
                  </td>
                ) : null
              )}
            </tr>

            {/* {row.level2.map((level2item, index) => TableRowslevel2(level2item))} */}
          </>
        ))}
      </tbody>
    );
  };

  // const TableRowslevel1 = tableData => {
  //   return (
  //     <tbody>
  //       {tableData.level1.map((row, index) => (
  //         <>
  //           <tr key={index} className="table-level1-row">
  //             {row.data.map((item, index) => (
  //               <td
  //                 className={
  //                   index === 0 ? "table-data-left-level1 " : " table-level1"
  //                 }
  //                 colSpan={index === 0 ? "5" : tableData.totalCountryCodes}
  //                 key={index}
  //               >
  //                 {item ? item : ""}
  //               </td>
  //             ))}
  //           </tr>

  //           {row.level2.map((level2item, index) => TableRowslevel2(level2item))}
  //         </>
  //       ))}
  //     </tbody>
  //   );
  // };

  return (
    <div className="table-div">
      <div className="table-title">
        <h3>{capitalizeFirstLetter(gettext(tableTitle))}</h3>
      </div>

      <Table responsive>
        {TableHeading(tableData)}
        {TableRowslevel1(tableData)}
      </Table>
    </div>
  );
};

export default GhgCustomTable;
