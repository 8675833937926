import { gql } from "@apollo/client";
import { ME_TYPE_FRAGMENT } from "GraphQL/Fragments";

export const SUBMIT_BUILDING = gql`
  ${ME_TYPE_FRAGMENT}
  mutation comCustomerAddBuildings($buildings: [RegisterFlowBuildingInput]) {
    comCustomerAddBuildings(buildings: $buildings) {
      success
      message
      user {
        ...MeType
      }
    }
  }
`;
