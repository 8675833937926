import { gql } from "@apollo/client";

export const GET_USER_CUTOMERS = gql`
  query getUserCustomers($userId: String) {
    comGetUser(userId: $userId) {
      name
      teams {
        Id
        name
        label
        cvr
      }
    }
  }
`;
export const DELETE_ADMIN_CUSTOMER = gql`
  mutation comDeleteUsersCustomerAdmin(
    $customerName: String!
    $userId: String!
  ) {
    comDeleteUsersCustomerAdmin(customerName: $customerName, userId: $userId) {
      success
      message
    }
  }
`;
