import {
  Avatar,
  CircularProgress,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip
} from "@mui/material";
import {
  useAuth,
  useLang,
  useLocale,
  useTextdomainContext
} from "app/providers";
import { useUpdateProfile } from "hooks";
import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback
} from "react";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { useApolloClient } from "@apollo/client";

export default function LanguageSelector() {
  const { TextdomainContext } = useTextdomainContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const { gettext } = useContext(TextdomainContext);

  const languages = useMemo(() => {
    return [
      {
        lang: "en",
        name: gettext("English"),
        flag: toAbsoluteUrl("/media/svg/flags/012-uk.svg")
      },
      // {
      //   lang: "zh",
      //   name: "Mandarin",
      //   flag: toAbsoluteUrl("/media/svg/flags/015-china.svg"),
      // },
      // {
      //   lang: "es",
      //   name: "Spanish",
      //   flag: toAbsoluteUrl("/media/svg/flags/128-spain.svg"),
      // },
      // {
      //   lang: "ja",
      //   name: "Japanese",
      //   flag: toAbsoluteUrl("/media/svg/flags/063-japan.svg"),
      // },

      /////////////////////////start//////////////////////////
      // {
      //   lang: "de",
      //   name: gettext("German"),
      //   flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg")
      // },

      // ////////////////////////end ////////////////////////////
      // {
      //   lang: "fr",
      //   name: "French",
      //   flag: toAbsoluteUrl("/media/svg/flags/195-france.svg")
      // },

      // ///////////////////////start//////////////////////////
      {
        lang: "da",
        name: gettext("Danish"),
        flag: toAbsoluteUrl("/media/svg/flags/174-denmark.svg")
      }
      // {
      //   lang: "es",
      //   name: gettext("Spanish"),
      //   flag: toAbsoluteUrl("/media/svg/flags/016-spain.svg")
      // },
      // {
      //   lang: "sv",
      //   name: gettext("Swedish"),
      //   flag: toAbsoluteUrl("/media/svg/flags/184-sweden.svg")
      // },
      // {
      //   lang: "no",
      //   name: gettext("Norwegian"),
      //   flag: toAbsoluteUrl("/media/svg/flags/143-norway.svg")
      // }
      //////////////////////////end ////////////////////////////
    ];
  }, [gettext]);

  const open = Boolean(anchorEl);

  const lang = useLang();

  const { isAuthenticated } = useAuth();

  const { setLocale } = useLocale();

  const client = useApolloClient();

  const { updateProfile, loading } = useUpdateProfile(
    async () => {
      await client.refetchQueries({
        include: "active"
      });
    },
    () => {}
  );

  const [selectedLanguage, setSelectedLanguage] = useState({});

  useEffect(() => {
    setSelectedLanguage(
      languages.find(language => {
        return language.lang === lang;
      })
    );
    localStorage.setItem("languageSelect", lang);
  }, [lang, languages]);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLangChange = useCallback(
    lang => {
      if (isAuthenticated) {
        updateProfile({ lang });
      } else {
        setLocale(lang);
      }

      localStorage.setItem("languageSelect", lang);

      handleClose();
    },
    [handleClose, updateProfile, setLocale, isAuthenticated]
  );

  return (
    <div>
      <Tooltip title="Change Language">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Avatar alt={selectedLanguage?.lang} src={selectedLanguage?.flag}>
              {selectedLanguage?.lang}
            </Avatar>
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {languages.map((language, index) => (
          <ListItemButton
            key={index}
            onClick={() => {
              handleLangChange(language.lang);
            }}
          >
            <ListItemIcon>
              <Avatar alt={language.lang} src={language.flag}>
                {language.lang}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary={language.name} />
          </ListItemButton>
        ))}
      </Menu>
    </div>
  );
}
