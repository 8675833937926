import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { GET_BUILDINGS, UPDATE_BUILDING } from "./commands";

export function useGetMyBuildings(cutomer) {
  const { data, loading } = useQuery(GET_BUILDINGS, {
    variables: {
      customerName: cutomer
    },
    fetchPolicy: "no-cache"
  });

  return {
    buildings: data?.customersBuildings || [],
    loading
  };
}

export function useUpdateBuilding(onCompleted = () => {}, onError = () => {}) {
  const [updateCustomerBuilding, { loading }] = useMutation(UPDATE_BUILDING, {
    onCompleted,
    onError
  });

  const update = useCallback(
    variables => {
      updateCustomerBuilding({
        variables,
        refetchQueries: ["getCustomerBuildings"]
      });
    },
    [updateCustomerBuilding]
  );

  return {
    update,
    updating: loading
  };
}
