import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useMemo
} from "react";
import { injectIntl } from "react-intl";
import { useTextdomainContext, useUser } from "app/providers";
import {
  Button,
  Grid,
  Skeleton,
  TableContainer,
  Typography
} from "@mui/material";
import EnergyPricesCustomTable from "app/components/EnergyPricesCustomTable";
import {
  useEnergyPrices,
  useBuildingEnergyPrices
} from "hooks/EnergyPrices/useEnergyPrices";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CountrySelection from "./components/CountrySelection";
import { IoCalendar } from "react-icons/io5";
import moment from "moment";
import { Stack } from "@mui/system";
import { createGlobalStyle } from "styled-components";
import { useCountryAddresses } from "hooks";
import BuildingSelection from "./components/BuildingSelection";
import EnergyPricesBuildingCustomTable from "app/components/EnergyPricesBuildingCustomTable";
import { hourlyConsumptionChart } from "./hourlyConsumptionChart";
import ApexCharts from "apexcharts";
import { capitalizeFirstLetter } from "hooks/useCommon";
import countries from "./countries";
import AccessSelection from "./components/AccessSelection";
import CurrencySelection from "./components/CurrencySelection";
import useStyles from "./styles";
import FileSelection from "./components/FileSelection";

const DatePickerStyles = createGlobalStyle`
      .react-datepicker__triangle::before {
        display: none;
      }
      .react-datepicker__triangle::after {
        display: none;
      }
      .react-datepicker__header {
        border: none;
        background: white;
      }
      .react-datepicker.date-picker-calendar {
        border-radius: 8px;
        border: 1px solid #FAFAFA;
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      }
      .react-datepicker__day{
        border-radius: 0;
      }
      .react-datepicker__day.react-datepicker__day--selected {
        background-color: #015731;
      }
`;

const consumptionPricesChartId = "chart-consumption-prices-hourly";

const EnergyHourlyPrice = props => {
  //constants
  const { energyType, date, country } = props;
  const navigate = useNavigate();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [tableData, setTableData] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    // new Date().toISOString().split("T")[0]
    null
  );
  const [datePickerDate, setDatePicker] = useState(new Date());
  const { user } = useUser();
  const { currentTeam } = user || {};
  const [countryCode, setCountryCode] = useState("");
  const { buildings, loading: loadingBuildings } = useCountryAddresses(
    currentTeam,
    countryCode
  );
  const [selectedBuilding, selectBuilding] = useState(null);
  const [buildingAccess, setAccess] = useState(false);
  const [selectedCurrency, selectCurrency] = useState("DKK");
  const classes = useStyles();

  const { data: pricedata, loading: priceDataLoading } = useEnergyPrices(
    selectedDate,
    country
  );

  const {
    data: buildingPriceData,
    loading: buildingPriceLoading
  } = useBuildingEnergyPrices(selectedDate, selectedBuilding?.name || "");

  const [inputValue, setInputValue] = React.useState("");

  useEffect(() => {
    if (country) {
      const code = countries.filter(
        con => con.label.toLowerCase() === country
      )[0]?.code;
      setCountryCode(code);
    }
  }, [country]);

  useMemo(() => {
    if (date) {
      if (Date.parse(date)) {
        setSelectedDate(date);
        setDatePicker(new Date(date));
      }
    }
    // else if (!selectedDate) {
    //     setSelectedDate(new Date().toISOString().split("T")[0]);
    //     setDatePicker(new Date());
    // }
  }, [date]);

  useEffect(() => {
    if (country && !inputValue) {
      const [selCountry] = countries.filter(
        cont => cont.label.toLowerCase() === country
      );
      if (selCountry) setInputValue(selCountry);
    }
  }, [country, inputValue]);

  useEffect(() => {
    if (buildingPriceData?.meterDataWithTaxes?.data && !buildingPriceLoading) {
      const chartDt = hourlyConsumptionChart(
        buildingPriceData.meterDataWithTaxes.data,
        gettext(capitalizeFirstLetter(energyType)) +
          " " +
          gettext("prices for consumption") +
          " DKK/kWh",
        gettext
      );
      const chartEl = document.getElementById(consumptionPricesChartId);
      if (!chartEl) return;
      const chart = new ApexCharts(chartEl, chartDt.options);
      chart.render();
      return () => chart.destroy();
    }
  }, [buildingPriceData, buildingPriceLoading, energyType, gettext]);

  const getTableHeadings = useCallback(
    tableData => {
      // let data = tableData[0];
      let tableHeading = [""];
      tableHeading.push(`${gettext("Yesterday")}`);
      tableHeading.push(`${gettext("Today")}`);

      tableHeading.push(`${gettext("Tomorrow")}`);
      return tableHeading;
    },
    [gettext]
  );

  const setPickerDate = useCallback(date => {
    let formatedDate = date.split(/\D/);
    let newDate = new Date(formatedDate.reverse().join("-"));
    setDatePicker(newDate);
  }, []);

  useEffect(() => {
    if (
      !buildings.some(bd => selectedBuilding?.name === bd.name) &&
      !loadingBuildings
    ) {
      selectBuilding(null);
    }
  }, [loadingBuildings, buildings, inputValue, selectedBuilding?.name]);

  useEffect(() => {
    if (selectedBuilding && !loadingBuildings) {
      const access = buildings.filter(
        bd => bd.name === selectedBuilding?.name
      )[0]?.isPublic;
      setAccess(access);
    }
  }, [buildings, selectedBuilding, loadingBuildings]);

  useEffect(() => {
    if (inputValue) {
      if (
        selectedDate === null &&
        datePickerDate.toDateString() === new Date().toDateString()
      ) {
        navigate(
          `/budget/${energyType}/prices/${inputValue.label.toLowerCase()}/`
        );
      } else {
        navigate(
          `/budget/${energyType}/prices/${inputValue.label.toLowerCase()}/${
            new Date(datePickerDate).toISOString().split("T")[0]
          }`
        );
      }
    }
  }, [datePickerDate, selectedDate, energyType, inputValue, navigate]);

  useEffect(() => {
    let data = [];
    let rowData = [];
    let subHeading = [""];
    let tableHeading = [];
    let countryCode = [];
    let days = ["Yesterday", "Today", "Tomorrow"];
    if (
      pricedata?.dataResourcePrice?.data &&
      pricedata?.dataResourcePrice?.data?.length &&
      !priceDataLoading
    ) {
      pricedata?.dataResourcePrice?.data?.forEach(time => {
        if (!date) {
          setPickerDate(time.today);
        }
        let tableValues = JSON.parse(time.values);
        tableValues.forEach((time, index1) => {
          rowData = [];
          subHeading = ["Hours"];
          rowData.push(time?.ts);
          days.forEach((day, index) => {
            let dayPrice = time?.[day]?.[selectedCurrency];
            dayPrice &&
              Object.keys(dayPrice).forEach((currency, index) => {
                rowData.push(
                  parseFloat(
                    dayPrice[currency] ? dayPrice[currency] : 0
                  ).toFixed(2)
                );
              });
          });
          data.push(rowData);
        });
      });
      tableHeading = getTableHeadings(pricedata?.dataResourcePrice?.data);

      countryCode = pricedata?.dataResourcePrice?.data[0].countryCode.split(
        ","
      );

      countryCode = countryCode.concat(countryCode).concat(countryCode);
      setTableData({
        tableHeading: tableHeading,
        subHeading: subHeading.concat(countryCode),
        content: data,
        totalCountryCodes: pricedata?.dataResourcePrice?.data[0].countryCode.split(
          ","
        ).length
      });
    } else {
      setTableData({});
    }
  }, [
    pricedata,
    priceDataLoading,
    getTableHeadings,
    setPickerDate,
    date,
    selectedCurrency
  ]);

  const customDateFormat = date => {
    const dt = moment(date);
    return dt.format("DD MMM YYYY");
  };

  return (
    <>
      <Stack spacing={4}>
        <Box
          sx={{
            p: 1,
            backgroundColor: "secondary.50",
            border: "1px solid",
            borderColor: "secondary.200",
            borderRadius: "8px"
          }}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={"auto"}>
              <CountrySelection
                countries={countries}
                selectCountry={setInputValue}
                selectedCountry={inputValue}
                gettext={gettext}
              />
            </Grid>
            <Grid item xs={12} sm={"auto"}>
              <BuildingSelection
                buildings={buildings}
                selectBuilding={selectBuilding}
                selectedBuilding={selectedBuilding}
                gettext={gettext}
              />
            </Grid>
            <Grid item xs={12} sm={"auto"}>
              <AccessSelection
                buildingName={selectedBuilding?.name || ""}
                buildingAccess={buildingAccess}
                gettext={gettext}
              />
            </Grid>
            <Grid item sm textAlign="end">
              <Box
                sx={{
                  float: "right",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <DatePicker
                  customInput={
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      endIcon={<IoCalendar />}
                    >
                      {customDateFormat(datePickerDate || new Date())}
                    </Button>
                  }
                  selected={datePickerDate}
                  maxDate={new Date()}
                  popperClassName={"date-picker-popper"}
                  wrapperClassName={"date-picker-wrapper"}
                  calendarClassName={"date-picker-calendar"}
                  calendarStartDay={1}
                  onChange={date => {
                    setDatePicker(date);
                  }}
                  dateFormat={"dd/MM/yyy"}
                />
                <DatePickerStyles />
              </Box>
            </Grid>
          </Grid>
        </Box>
        {!selectedBuilding ? (
          <>
            {priceDataLoading ? (
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height="100%"
                className={classes.skeleton}
              />
            ) : (
              <>
                {Object.keys(tableData)?.length && tableData.content.length ? (
                  <>
                    <EnergyPricesCustomTable
                      energyType={energyType}
                      tableData={tableData}
                      gettext={gettext}
                      date={date}
                      selectedCurrency={selectedCurrency}
                      currencySelectionComponent={
                        <CurrencySelection
                          selectCurrency={selectCurrency}
                          selectedCurrency={selectedCurrency}
                          gettext={gettext}
                        />
                      }
                      fileSelectionComponent={
                        <FileSelection
                          type={"country"}
                          selectedCountry={country}
                          selectedDate={selectedDate}
                          selectedBuilding={selectedBuilding}
                          selectedCurrency={selectedCurrency}
                          gettext={gettext}
                        />
                      }
                    />
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      minHeight: "350px",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column"
                    }}
                  >
                    <img
                      src="/media/NoGraphData.png"
                      alt="No Data Found"
                      width="150"
                      height="40"
                      className="pt-5"
                    />

                    <Typography
                      variant="body1"
                      color="primary"
                      fontFamily="Inter"
                      sx={{
                        mt: 1,
                        fontSize: 24,
                        fontWeight: 500,
                        lineheight: 24
                      }}
                      textOverflow="hidden"
                    >
                      {gettext("No data found for selected date!")}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="gray"
                      fontFamily="Inter"
                      sx={{
                        fontSize: 18,
                        fontWeight: 400,
                        lineheight: 18
                      }}
                    >
                      {gettext("Please select another date.")}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </>
        ) : buildingPriceData?.meterDataWithTaxes?.data &&
          buildingPriceData?.meterDataWithTaxes?.data?.length ? (
          <>
            <TableContainer
              sx={{
                minHeight: "380px",
                borderRadius: "12px",
                padding: "24px",
                border: "1px solid #E4E4E7"
              }}
            >
              <div id={consumptionPricesChartId} />
            </TableContainer>
            <EnergyPricesBuildingCustomTable
              energyType={energyType}
              tableData={buildingPriceData?.meterDataWithTaxes?.data || []}
              gettext={gettext}
              country={country}
              date={date}
              currencyCode={inputValue?.currency}
              fileSelectionComponent={
                <FileSelection
                  type={"building"}
                  selectedDate={selectedDate}
                  selectedBuilding={selectedBuilding}
                  gettext={gettext}
                />
              }
            />
          </>
        ) : !buildingPriceLoading ? (
          <Box
            sx={{
              display: "flex",
              minHeight: "350px",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <img
              src="/media/NoGraphData.png"
              alt="No Data Found"
              width="150"
              height="40"
              className="pt-5"
            />

            <Typography
              variant="body1"
              color="primary"
              textOverflow="hidden"
              className={classes.primaryMessage}
            >
              {gettext("No data found for selected date!")}
            </Typography>
            <Typography
              variant="caption"
              color="gray"
              className={classes.secondaryMessage}
            >
              {gettext("Please select another date.")}
            </Typography>
          </Box>
        ) : (
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height="100%"
          />
        )}
      </Stack>
    </>
  );
};

export default injectIntl(EnergyHourlyPrice);
