import { useState, useCallback } from "react";
import {
  getAdminEnergySupplierList,
  addAdminEnergySupplier,
  editAdminEnergySupplier,
  deleteAdminEnergySupplierSvc
} from "../services/adminESSvc";

export const useAdminEnergySupplier = props => {
  const [energySuppliersList, setEnergySuppliersList] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [options, setoptions] = useState({
    noDataText: "No Data In Table"
  });

  const getAdminEnergySuppliersList = useCallback(energyType => {
    setUpdating(true);
    getAdminEnergySupplierList(energyType).then(
      response => {
        setEnergySuppliersList(response.data);
        setUpdating(false);
      },
      () => {
        setoptions({
          noDataText: "No Response from server. Try again!"
        });
        setUpdating(false);
      }
    );
  }, []);

  return {
    energySuppliersList,
    getAdminEnergySuppliersList,
    updating,
    options
  };
};

export const useEditEnergySupplier = props => {
  const [updating, setUpdating] = useState(false);
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);
  const submitAdminEnergySupplier = (type, params) => {
    setUpdating(true);
    editAdminEnergySupplier(type, params).then(
      response => {
        response.status === 200 && setMessage("User Updated Successfully");
        setSuccess(true);
        setUpdating(false);
        props.resetForm();
        props.callback(type);
        props.onHide();
        setTimeout(() => {
          setMessage(null);
        }, 5000);
      },
      err => {
        setMessage(err.response.data.message);
        setSuccess(false);
        setUpdating(false);
      }
    );
  };
  return { submitAdminEnergySupplier, updating, message, success };
};

export const useAddEnergySupplier = props => {
  const [updating, setUpdating] = useState(false);
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);
  const submitAdminEnergySupplier = (type, params) => {
    setUpdating(true);
    addAdminEnergySupplier(type, params).then(
      response => {
        response.status === 200 && setMessage("User Added Successfully");
        setSuccess(true);
        setUpdating(false);
        props.resetForm();
        props.callback(type);
        setTimeout(() => {
          setMessage(null);
        }, 5000);
      },
      err => {
        setMessage(err.response.data.message);
        setSuccess(false);
        setUpdating(false);
      }
    );
  };
  return { submitAdminEnergySupplier, updating, message, success };
};

export const useDeleteEnergySupplier = props => {
  const [updating, setUpdating] = useState(false);
  const [message, setMessage] = useState();
  const [success, setSuccess] = useState(false);
  const deleteAdminEnergySupplier = params => {
    setUpdating(true);
    deleteAdminEnergySupplierSvc(params).then(
      response => {
        response.status === 200 && setMessage("User Updated Successfully");
        setSuccess(true);
        setUpdating(false);
        props.callback(params.type);
        setTimeout(() => {
          setMessage(null);
        }, 5000);
      },
      err => {
        setMessage(err.response.data.message);
        setSuccess(false);
        setUpdating(false);
      }
    );
  };
  return { deleteAdminEnergySupplier, updating, message, success };
};
