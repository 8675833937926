import React, { useEffect, useMemo, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
import { MixedWidget1 } from "../../../widgets";
import { Spinner } from "react-bootstrap";

function OneColumnWidget1(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const initialData = useMemo(() => {
    return {
      unique_id: props.uniqueId,
      type: "area",
      chart_title: "...",
      yaxisTitleText: "...",
      categories: ["..."],
      data: [
        {
          name: gettext("Electricity"),
          data: [0]
        },
        {
          // name: gettext("District Heating"),
          name: gettext("Heating"),
          data: [0]
        },
        {
          name: gettext("Water"),
          data: [0]
        }
      ]
    };
  }, [props.uniqueId, gettext]);
  const [data, updateData] = useState(initialData);

  useEffect(() => {
    if (props.data.type) {
      updateData(props.data);
    } else {
      updateData(initialData);
    }
  }, [props.data, initialData]);
  return (
    <div className={"card card-custom example example-compact"}>
      <div className={"card-body"}>
        <div className={"row"}>
          <div className={"col-md-12"}>
            <div className="chart-layout">
              <MixedWidget1
                className="card-stretch gutter-b"
                data={data}
                getUriDetails={props.getUriDetails}
                label={props?.label}
              />
            </div>
          </div>
        </div>
      </div>
      {props.loading ? (
        <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : null}
    </div>
  );
}

export default injectIntl(OneColumnWidget1);
