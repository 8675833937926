export function isLocationActive(location, url) {
  const current = location.pathname.split(/[?#]/)[0];

  if (!current || !url) {
    return false;
  }
  if (current === url) {
    return true;
  }
  if (current.match(new RegExp(url + "/", "gi"))) {
    return true;
  }

  return false;
}
