import { Grid } from "@mui/material";
import DataHubTable from "./components/DataHubTable";
import { useDataHubMeterDetailsMonthly } from "./hooks";

const MeterDetailsMonthly = props => {
  const {
    view,
    setView,
    meteringPointId,
    date,
    setDate,
    selectedSegment,
    setSelectedSegment,
    gettext
  } = props;
  const { meterDetailMonthly, period, loading } = useDataHubMeterDetailsMonthly(
    meteringPointId,
    date
  );

  return (
    <Grid item>
      <DataHubTable
        title={view}
        setView={setView}
        view={view}
        rows={meterDetailMonthly}
        selectededDate={date}
        segment={selectedSegment}
        period={period}
        getSelectedDate={(date, selectedSegment) => {
          setDate(date);
        }}
        onSegmentChange={selectedSegment => {
          setDate(date);
          setSelectedSegment(selectedSegment);
        }}
        gettext={gettext}
        loading={loading}
      />
    </Grid>
  );
};
export default MeterDetailsMonthly;
