import { useQuery } from "@apollo/client";
import { GET_PARTNERS_LIST } from "./commands";

export function useFetchPartners() {
  const { data, loading } = useQuery(GET_PARTNERS_LIST);

  return {
    partners: data?.comPartners || [],
    loading
  };
}
