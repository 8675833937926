import React, { useEffect, useState } from "react";
import OpenStreetMap from "../../../components/OpenStreetMap";
import { injectIntl } from "react-intl";
import { useCustomerAddresses } from "../../../../services/userService";
import { useUser } from "app/providers";

const tempData = {
  result: "Success",
  version: "1.4.0",
  data: [
    // {
    //   title: "Grenåvej 107D, 8240 Risskov",
    //   latlon: [56.18420534, 10.21744119],
    //   text: ["Main headquarters"],
    //   layer: "hq"
    // },
    // {
    //   title: "Buegade 11, 6100 Haderslev",
    //   latlon: [55.24545475, 9.48847176],
    //   text: ["Somewhere over the rainbow", "The sun always shines"],
    //   layer: "school"
    // },
    // {
    //   title: "",
    //   latlon: [54.2454547, 9.4884717],
    //   text: ["testing HQ"],
    //   layer: "hq"
    // }
  ],
  additional: {},
  timestamp: "2021-02-12T09:54:49.207472"
};

const Map = () => {
  const { user } = useUser();
  // const [suggestions, setSuggestions] = useState({});
  const [data, setData] = useState(tempData);
  const { data: addresses } = useCustomerAddresses(user.currentTeamId);
  useEffect(() => {
    if (addresses.isLoading) {
      return;
    }
    const list = addresses.data[0];
    let locationData = [];
    if (list && list.length > 0) {
      list.map(function(item) {
        let access = item.access ? item.access[0] : [];
        if (
          access &&
          access.coordinates &&
          access.coordinates[0] &&
          access.coordinates[1]
        ) {
          let locationPoint = {
            title: item.address,
            latlon: access.coordinates,
            // latlon: [accessRow.coordinates[1], accessRow.coordinates[0]],
            text: access.coordinates[1] + ", " + access.coordinates[0],
            // layer: "all"
            layer: item.address
          };
          locationData.push(locationPoint);
        }
        return false;
      });
    }

    let mapData = {
      result: "Success",
      version: "1.4.0",
      data: locationData,
      additional: {},
      timestamp: "2021-02-12T09:54:49.207472"
    };
    setData(mapData);
  }, [addresses]);

  // const getSuggestions = e => {
  //   let value = e.target.value;
  //   const inputValue = deburr(value.trim()).toLowerCase();
  //   if (inputValue.length >= 3) {
  //     axios
  //       .get("https://dawa.aws.dk/adresser/autocomplete", {
  //         params: {
  //           q: inputValue
  //         }
  //       })
  //       .then(response => {
  //         setSuggestions(response.data)
  //       });
  //   }
  // };

  return (
    <>
      <OpenStreetMap data={data} />
    </>
  );
};

export default injectIntl(Map);
