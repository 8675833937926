import { Stack, Typography } from "@mui/material";
import { useTextdomainContext } from "app/providers";
import React from "react";
import { BenchmarkTable, DownloadButton } from "./components";

export function BenchmarkBody() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);
  return (
    <Stack
      spacing={2}
      sx={{
        p: 2,
        border: "1px solid",
        borderColor: "secondary.200",
        borderRadius: "8px"
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ p: 1 }}>
        <Typography fontSize={20} fontWeight={700} color={"secondary"}>
          {gettext("Benchmark")}
        </Typography>

        <DownloadButton />
      </Stack>

      <BenchmarkTable />
    </Stack>
  );
}
