import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const AUTH_TOKEN_KEY = "userAuthtoken";
export function Token() {
  const [searchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();

  const AuthToken = cookies[AUTH_TOKEN_KEY];
  let token = searchParams.get("id");
  try {
    setCookie(AUTH_TOKEN_KEY, token, {
      path: "/"
    });
    navigate("/dashboard");
    window.location.href = "/dashboard";
  } catch (error) {}
  return (
    <>
      <p>
        <a href="/dashboard">Click here to move to the dashboard</a>
        {AuthToken}
      </p>
    </>
  );
}
