import { useQuery } from "@apollo/client";
import { useBenchmarkFilter, useUser } from "app/providers";
import { useCallback, useMemo } from "react";
import { GET_BENCHMARK_DATA } from "../../commands";

export function useBenchmarkData(fileType) {
  const {
    selectedBuildingOne,
    selectedBuildingTwo,
    selectedEnergySource,
    selectedDate,
    resolution
  } = useBenchmarkFilter();

  const fromDate = useMemo(() => {
    const fromDate = new Date(selectedDate);

    fromDate.setUTCHours(0, 0, 0, 0);

    fromDate.setDate(fromDate.getDate() + 1);

    return fromDate.toISOString().slice(0, 19);
  }, [selectedDate]);

  const toDate = useMemo(() => {
    const toDate = new Date(fromDate);

    switch (resolution) {
      case "h":
        toDate.setDate(toDate.getDate() + 1);
        break;
      case "d":
        toDate.setMonth(toDate.getMonth() + 1);
        break;
      case "m":
        toDate.setFullYear(toDate.getFullYear() + 1);
        break;
      default:
        break;
    }

    toDate.setUTCHours(0, 0);

    toDate.setDate(toDate.getDate() + 1);

    return toDate.toISOString().slice(0, 19);
  }, [fromDate, resolution]);

  const currentYear = useMemo(() => {
    const currentYear = new Date(fromDate);

    return currentYear.getFullYear();
  }, [fromDate]);

  const prevYear = useMemo(() => {
    const prevYear = new Date(new Date(fromDate).getFullYear() - 1, 0, 1);

    return prevYear.getFullYear();
  }, [fromDate]);

  const { user } = useUser();

  const { currentTeam } = user || {};

  const { data, loading, refetch } = useQuery(GET_BENCHMARK_DATA, {
    variables: {
      firstBuilding: selectedBuildingOne.address,
      secondBuilding: selectedBuildingTwo.address,
      meterType: selectedEnergySource.value,
      fromDate,
      toDate,
      customer: currentTeam,
      resolution
    },
    onCompleted: ({ comBenchmarkData }) => {
      if (comBenchmarkData?.fileUrl) {
        window.open(comBenchmarkData?.fileUrl, "_blank");
      }
    }
  });

  const downloadFile = useCallback(
    fileType => {
      refetch({
        firstBuilding: selectedBuildingOne.address,
        secondBuilding: selectedBuildingTwo.address,
        meterType: selectedEnergySource.value,
        fromDate,
        toDate,
        customer: currentTeam,
        resolution,
        fileType
      });
    },
    [
      refetch,
      currentTeam,
      fromDate,
      resolution,
      selectedBuildingOne,
      selectedBuildingTwo,
      selectedEnergySource,
      toDate
    ]
  );

  const buildingOneData =
    data?.comBenchmarkData?.data?.find(function(building) {
      return building.address === selectedBuildingOne.address;
    }) || {};

  const buildingTwoData =
    data?.comBenchmarkData?.data?.find(function(building) {
      return building.address === selectedBuildingTwo.address;
    }) || {};

  return {
    loading,
    currentYear,
    prevYear,
    buildingOneData,
    buildingTwoData,
    labels: buildingOneData?.data?.map(data => data?.currentData?.ts) || [],
    fileUrl: data?.comBenchmarkData?.fileUrl || "",
    downloadFile
  };
}
