import React, { useEffect, useState, useContext } from "react";
import "../../../../_metronic/_assets/sass/pages/publicfacing/publicfacing.scss";
import { Spinner } from "react-bootstrap";
import { InlineShareButtons } from "sharethis-reactjs";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import { usePublicGraphData } from "./hooks";
import { Box, Typography } from "@mui/material";
// import { DASBOARD_FILTER_LS_KEY } from "app/common/Constants";
import { useTextdomainContext } from "app/providers";

// const checkIcon = require('../../../../../public/media/checkIcon.png');

const { REACT_APP_URL } = process.env;

const PublicFacingPage = props => {
  const params = useParams();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [selectedGraphImage, setSelectedGraphImage] = useState([]);
  const [dashboardType, setDashboardType] = useState("");
  const [loading, setLoading] = useState(true);
  const [publicPageValues, setPublicPageValues] = useState({
    PageName: "",
    description: "",
    facebook: "",
    twitter: "",
    status: false
  });
  const { customerId, dashboardKey } = params;

  const { publicGraph, settings } = usePublicGraphData(
    customerId,
    dashboardKey
  );

  useEffect(() => {
    if (settings && settings.length > 0) {
      const obj = settings.find(item => item.var === "description");
      if (obj) {
        setPublicPageValues({
          PageName: "",
          description: obj.value,
          facebook: "",
          twitter: "",
          status: false
        });
      }
    }
  }, [settings, setPublicPageValues]);

  useEffect(() => {
    if (publicGraph && publicGraph.length > 0) {
      publicGraph.map(imageData => {
        setDashboardType(imageData.dashboard);
        setLoading(false);
        setSelectedGraphImage(prev => [
          ...prev,
          {
            name: imageData.imageName,
            title: imageData.title,
            fileUrl: imageData.fileUrl
          }
        ]);
        return 0;
      });
    } else {
      setLoading(false);
    }
  }, [publicGraph, setSelectedGraphImage]);
  // const buildingNames = publicGraph && publicGraph[0].buildings.map((building) => {
  //   const startIndex = building.indexOf("'") + 1;
  //   const endIndex = building.lastIndexOf("'");
  //   return {
  //     address: building.slice(startIndex, endIndex)
  //   };
  // });

  // useEffect(() => {
  //   customer &&
  //     getTeamId(customer).then(res => {
  //       getPublicPageData(res.data.team_id).then(res => {
  //         setPublicPageValues(res.data.values);
  //       });
  //       getGraphNames(res.data.team_id).then(res => {
  //         res.data.data &&
  //           res.data.data.map(imageData => {
  //             setDashboardType(imageData.dashboard);
  //             setLoading(false);
  //             setSelectedGraphImage(prev => [
  //               ...prev,
  //               {
  //                 name: imageData.image_name,
  //                 title: imageData.title
  //               }
  //             ]);
  //             return 0;
  //           });
  //       });
  //     });
  // }, [customer]);

  const getDashboardType = () => {
    let classType = "col-md-12 col-lg-12";
    // if (dashboardType === "Dashboard1") {
    //   classType = "col-md-6 col-lg-6";
    // } else if (dashboardType === "Dashboard2") {
    //   classType = "col-md-6 col-lg-6";
    // } else if (dashboardType === "Dashboard3") {
    //   classType = "col-md-12 col-lg-9";
    // } else if (dashboardType === "Dashboard4") {
    //   classType = "col-md-12 col-lg-9";
    // } else if (dashboardType === "Dashboard5") {
    //   classType = "col-sm-12 col-md-6 col-lg-6";
    // }
    return classType;
  };

  return (
    <div className="public">
      {/* {loading ? (
        <div className="container-fluid py-5">
          <div className="row" id={`${dashboardType}`}>
            <div className="col-lg-12 text-center py-3">
              <Spinner animation="border" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container-fluid py-2">
            <div className="row">
            </div>
            <div className="row justifu-content-center ">
              {selectedGraphImage.map((item, index) => (
                <Paper
                  className={`${getDashboardType()} mx-auto my-4`}
                  key={index}
                >
                  <Card>
                    <CardMedia
                      sx={{ width: "100%", transition: "transform .2s" }}
                      component="img"
                      height="200"
                      image={item.fileUrl}
                      alt="graph image"
                    />
                  </Card>
                </Paper>
              ))}
            </div>
          </div>
          {publicGraph && publicGraph.length > 0 ? (
            <div className="container-fluid mt-5">
              <div className="row d-flex flex-column justify-content-center align-items-center py-4">
                <div className="col-lg-6 text-center d-flex justify-content-center align-items-center">
                  <div className="display-4 xl:w-50 lg:w-50 w-full">
                    {publicPageValues.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: publicPageValues.description,
                        }}
                      />
                    )}
                  </div>
                </div>
                <hr className="bg-gray-400 w-50" />
              </div>
              <div className="row d-flex justify-content-center align-items-center flex-column">
                <div className="col-lg-4 col-sm-4 col-md-6 d-flex justify-content-center py-3 my-1">
                  <h4>Share this page</h4>
                </div>
                <div className="col-lg-12 col-sm-12 col-md-12 d-flex justify-content-center py-3 my-1">
                  <InlineShareButtons
                    config={{
                      alignment: "center",
                      color: "social",
                      enabled: true,
                      font_size: 16,
                      labels: "cta",
                      language: "en",
                      networks: [
                        "facebook",
                        "twitter",
                        "linkedin",
                        "messenger",
                      ],
                      padding: 12,
                      radius: 4,
                      show_total: true,
                      size: 40,

                      url: `${REACT_APP_URL}public/${customerId}/dashboard/${dashboardKey}`,
                      image: `${REACT_APP_URL}public/${customerId}/dashboard/${dashboardKey}`,
                      description: "Public Facing Page",
                      title: "My Public Facing Page",
                      message: "custom email text",
                      subject: "My Public Facing Page",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="container-fluid mt-5">
              <h3>No DATA FOUND</h3>
            </div>
          )}
        </>
      )} */}
      {loading ? (
        <div className="container-fluid py-5">
          <div className="row" id={`${dashboardType}`}>
            <div className="col-lg-12 text-center py-3">
              <Spinner animation="border" />
            </div>
          </div>
        </div>
      ) : (
        <Box
          sx={{
            height: "448px",
            top: 0,
            position: "relative",
            backgroundColor: "#E6EEEA",
            // justifyContent: "center",
            // textAlign: "center",
            alignItems: "center",
            paddingTop: "128px",
            paddingX: "158px",
            marginBottom: "20%"
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              color: "#4D896F",
              fontStyle: "normal",
              textAlign: "center"
            }}
          >
            {gettext("PRESENTED BY")}
          </Typography>
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: "900",
              color: "#015731",
              fontStyle: "normal",
              marginTop: "24px",
              textAlign: "center"
            }}
          >
            {gettext("FABEKE")}
          </Typography>
          <Box
            sx={{
              paddingTop: "80px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            {publicGraph && publicGraph.length > 0 ? (
              <>
                <Box
                  sx={{
                    width: "364px",
                    height: "230px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #E4E4E7",
                    borderRadius: "12px",
                    boxShadow:
                      "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
                    padding: "12px",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Box
                    sx={{
                      height: "86px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#E6EEEA",
                      justifyContent: "center"
                    }}
                  >
                    <img
                      src="/media/businessIcon.png"
                      width="20"
                      height="20"
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                      alt="gridIcon"
                    />
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#27272A",
                        textAlign: "center",
                        marginTop: "10px"
                      }}
                    >
                      {gettext("Dashboard")}
                    </Typography>
                  </Box>
                  <div
                    style={{ marginTop: "16px", border: "1px solid #E4E4E7" }}
                  ></div>
                  <Box
                    sx={{
                      marginTop: "16px",
                      display: "inline-flex",
                      minHeight: "32px",
                      minWidth: "103px",
                      padding: "9px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      backgroundColor: "#E6EEEA",
                      border: "1px solid #B3CDC1",
                      borderRadius: "8px",
                      alignItems: "center",
                      paddingY: "auto",
                      marginX: "auto"
                    }}
                  >
                    <img
                      src="/media/checkIcon.png"
                      width="13"
                      height="13"
                      alt="checkIcon"
                    />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#015731",
                        marginLeft: "5.5px"
                      }}
                    >
                      {gettext(
                        `${publicGraph && publicGraph[0]?.dashboardName}`
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "364px",
                    minHeight: "230px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #E4E4E7",
                    borderRadius: "12px",
                    boxShadow:
                      "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
                    padding: "12px",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "16px"
                  }}
                >
                  <Box
                    sx={{
                      height: "86px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#E6EEEA",
                      justifyContent: "center"
                    }}
                  >
                    <img
                      src="/media/companyIcon.png"
                      width="20"
                      height="20"
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                      alt="gridIcon"
                    />
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#27272A",
                        textAlign: "center",
                        marginTop: "10px"
                      }}
                    >
                      {gettext("Buildings")}
                    </Typography>
                  </Box>
                  <div
                    style={{ marginTop: "16px", border: "1px solid #E4E4E7" }}
                  ></div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap"
                    }}
                  >
                    {publicGraph &&
                      publicGraph[0]?.buildings.map(building => {
                        return (
                          <Box
                            sx={{
                              marginTop: "16px",
                              display: "inline-flex",
                              minHeight: "32px",
                              minWidth: "103px",
                              padding: "9px",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              backgroundColor: "#E6EEEA",
                              border: "1px solid #B3CDC1",
                              borderRadius: "8px",
                              alignItems: "center",
                              paddingY: "auto",
                              marginX: "auto"
                            }}
                          >
                            <img
                              src="/media/checkIcon.png"
                              width="13"
                              height="13"
                              alt="checkIcon"
                            />
                            {/* {console.log('buildings',building)} */}
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#015731",
                                marginLeft: "5.5px"
                              }}
                            >
                              {gettext(`${building}`)}
                            </Typography>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "364px",
                    minHeight: "230px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #E4E4E7",
                    borderRadius: "12px",
                    boxShadow:
                      "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
                    padding: "12px",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "16px"
                  }}
                >
                  <Box
                    sx={{
                      height: "86px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#E6EEEA",
                      justifyContent: "center"
                    }}
                  >
                    <img
                      src="/media/gridIcon.png"
                      width="20"
                      height="20"
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                      alt="gridIcon"
                    />
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#27272A",
                        textAlign: "center",
                        marginTop: "10px"
                      }}
                    >
                      {gettext("Companies")}
                    </Typography>
                  </Box>
                  <div
                    style={{ marginTop: "16px", border: "1px solid #E4E4E7" }}
                  ></div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap"
                    }}
                  >
                    {publicGraph &&
                      publicGraph[0]?.companies.map(company => {
                        return (
                          <Box
                            sx={{
                              marginTop: "16px",
                              display: "inline-flex",
                              minHeight: "32px",
                              minWidth: "103px",
                              padding: "9px",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              backgroundColor: "#E6EEEA",
                              border: "1px solid #B3CDC1",
                              borderRadius: "8px",
                              alignItems: "center",
                              paddingY: "auto",
                              marginX: "auto"
                            }}
                          >
                            <img
                              src="/media/checkIcon.png"
                              width="13"
                              height="13"
                              alt="checkIcon"
                            />
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#015731",
                                marginLeft: "5.5px"
                              }}
                            >
                              {gettext(`${company}`)}
                            </Typography>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  width: "364px",
                  height: "324px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #E4E4E7",
                  borderRadius: "12px",
                  boxShadow:
                    "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
                  padding: "8px",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Box
                  sx={{
                    height: "28px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#E6EEEA",
                    alignItems: "center",
                    padding: "8px",
                    borderRadius: "4px"
                  }}
                >
                  <Box
                    sx={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px"
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px",
                      marginLeft: "8px"
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px",
                      marginLeft: "8px"
                    }}
                  ></Box>
                </Box>
                <Typography
                  sx={{
                    marginTop: "85px",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#27272A"
                  }}
                >
                  {gettext("No Data Found")}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "16px",
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#52525B"
                  }}
                >
                  {gettext("The copied link seems to be")}
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#52525B" }}
                >
                  {gettext("empty, please try again")}
                </Typography>
              </Box>
            )}
          </Box>
          {publicGraph &&
            publicGraph.length > 0 &&
            publicPageValues?.description && (
              <Box
                sx={{
                  marginTop: "80px",
                  padding: "32px",
                  height: "142px",
                  width: "100%",
                  background: "#FFFFFF",
                  border: "1px solid #E4E4E7",
                  borderRadius: "12px"
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#3F3F46"
                  }}
                >
                  {gettext(`${publicPageValues?.description}`)}
                </Typography>
              </Box>
            )}

          {publicGraph &&
            publicGraph.length > 0 &&
            selectedGraphImage.map((item, index) => (
              <Paper
                className={`${getDashboardType()} mx-auto my-4`}
                key={index}
                sx={{
                  boxShadow: 0,
                  width: "100%",
                  padding: 0,
                  marginTop: "48px"
                }}
              >
                <Card
                  sx={{ boxShadow: 0, width: "100%", marginBottom: "20px" }}
                >
                  <CardMedia
                    sx={{
                      width: "100%",
                      marginBottom: "20px",
                      borderRadius: "12px"
                    }}
                    component="img"
                    height="200"
                    image={item.fileUrl}
                    alt="graph image"
                  />
                </Card>
              </Paper>
            ))}
          {publicGraph && publicGraph.length > 0 && (
            <div className="col-lg-12 col-sm-12 col-md-12 d-flex justify-content-center py-3 my-1">
              <InlineShareButtons
                config={{
                  alignment: "center",
                  color: "social",
                  enabled: true,
                  font_size: 16,
                  labels: "cta",
                  language: "en",
                  networks: ["facebook", "twitter", "linkedin", "messenger"],
                  padding: 12,
                  radius: 4,
                  show_total: true,
                  size: 40,

                  url: `${REACT_APP_URL}public/${customerId}/dashboard/${dashboardKey}`,
                  image: `${REACT_APP_URL}public/${customerId}/dashboard/${dashboardKey}`,
                  description: "Public Facing Page",
                  title: "My Public Facing Page",
                  message: "custom email text",
                  subject: "My Public Facing Page"
                }}
              />
            </div>
          )}
        </Box>
      )}
    </div>
  );
};

export default PublicFacingPage;
