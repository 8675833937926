import React, { useState, useEffect, useContext, useMemo } from "react";
// import { getOr } from "lodash/fp";
import ApexCharts from "apexcharts";
import {
  MainResultSection1,
  MainResultTable,
  MainResultHead
} from "./GhgStyle";
import { useTextdomainContext } from "app/providers";
import * as XLSX from "xlsx/xlsx.mjs";
import Select from "react-select";

const ResultSection1 = props => {
  const {
    data,
    getUriDetails,
    setTable1PdfData,
    setPageUri1,
    setPageUri2
  } = props;

  const figure1Title = "Overview of the company's total CO₂e emissionsi";
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [totalScope1, setTotalScope1] = useState(0.0);
  const [totalScope2, setTotalScope2] = useState(0.0);
  const [totalScope3, setTotalScope3] = useState(0.0);
  const [totalOutOfScope, setTotalOutOfScope] = useState(0.0);

  const [totalScope1Percentage, setTotalScope1Percentage] = useState(0.0);
  const [totalScope2Percentage, setTotalScope2Percentage] = useState(0.0);
  const [totalScope3Percentage, setTotalScope3Percentage] = useState(0.0);

  const [csvData, setCSVData] = useState(null);
  const [totalOutOfScopePercentage, setTotalOutOfScopePercentage] = useState(
    0.0
  );

  let consumptionUniqueId = useMemo(() => "dashboardConsumptionChart", []); //useMemo

  let objectId = "result_1_chart";
  let objectId2 = "result_2_chart";
  let downloadOptions = [
    {
      value: "download",
      label: gettext("Download")
    },
    {
      value: "excel",
      label: gettext("Excel")
    },
    {
      value: "csv",
      label: gettext("CSV")
    }
  ];
  const selectStyles = {
    container: base => ({
      ...base,
      minWidth: 200,
      fontSize: 14,
      fontWeight: "normal",
      flex: 1,
      color: "black"
    })
  };
  const CSVHeadings = [
    "",
    gettext("Ton CO₂-e"),
    gettext("Distribution of tons of CO₂-e (%)")
  ];

  const handleDownloadFile = selectedOption => {
    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [CSVHeadings]);
    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, csvData.data, {
      origin: "A2",
      skipHeader: true
    });
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    if (selectedOption.value === "excel") {
      XLSX.writeFile(wb, "downlaod-" + new Date() + ".xlsx");
    } else if (selectedOption.value === "csv") {
      XLSX.utils.sheet_to_csv(wb);
      XLSX.writeFile(wb, "downlaod-" + new Date() + ".csv");
    }
  };

  useEffect(() => {
    if (getUriDetails) getUriDetails([objectId, objectId2]);
  }, [getUriDetails, objectId, objectId2]);

  useEffect(() => {
    if (totalScope1 || totalScope2 || totalScope3) {
      const element = document.getElementById(objectId);
      if (!element) {
        return;
      }
      const options = {
        chart: {
          type: "bar",
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"]
          },
          formatter: function(val) {
            return "";
          },
          rotate: -90
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        yaxis: {
          title: {
            text: "Ton CO₂-e"
          },
          forceNiceScale: true
        },
        legend: {
          markers: {
            width: 30
          }
        },
        series: [
          {
            name: "scope",
            data: [totalScope1, totalScope2, totalScope3]
          }
        ],
        colors: ["#4472C4"],
        xaxis: {
          categories: ["Scope 1", "Scope 2", "Scope 3"]
        }
      };

      const chart = new ApexCharts(element, options);

      chart.render().then(tmp => {
        // window.setTimeout(function() {
        chart.dataURI().then(uri => {
          if (typeof setPageUri1 === "function") {
            setPageUri1(uri.imgURI);
            const chartSrc = uri.imgURI.replace(
              /^data:image\/(png|jpg);base64,/,
              ""
            );
            if (chartSrc) {
            }
          }
        });
        // }, 1000);
      });

      return function cleanUp() {
        chart.destroy();
      };
    }
  }, [objectId, totalScope1, totalScope2, totalScope3, setPageUri1]);

  // const getPercentage = useCallback(
  //     (value) => {
  //         const total =
  //             parseFloat(totalScope1) +
  //             parseFloat(totalScope2) +
  //             parseFloat(totalScope3);
  //         return value && total ? ((value / total) * 100).toFixed(2) : 0;
  //     },
  //     [totalScope1, totalScope2, totalScope3]
  // );

  // const getTablePdfData = useCallback(() => {
  //     let jsonData = {
  //         headings: {
  //             h1: "",
  //             h2: gettext("Ton CO2-e"),
  //             h3: gettext("Distribution of tons of CO2-e (%)"),
  //         },
  //         data: [
  //             {
  //                 scope: gettext("Scope 1"),
  //                 value: totalScope1,
  //                 percentage: getPercentage(totalScope1),
  //             },
  //             {
  //                 scope: gettext("Scope 2"),
  //                 value: totalScope2,
  //                 percentage: getPercentage(totalScope2),
  //             },
  //             {
  //                 scope: gettext("Scope 3"),
  //                 value: totalScope3,
  //                 percentage: getPercentage(totalScope3),
  //             },
  //             {
  //                 scope: gettext("Total"),
  //                 value: totalScope1 + totalScope2 + totalScope3,
  //                 percentage: "100.0%",
  //             },
  //         ],
  //     };
  //     setTable1PdfData(jsonData);
  // }, [
  //     gettext,
  //     totalScope1,
  //     totalScope2,
  //     totalScope3,
  //     setTable1PdfData,
  //     getPercentage,
  // ]);

  useEffect(() => {
    // if (totalScope1 && totalScope2 && totalScope3) {
    const element = document.getElementById(objectId2);
    if (!element) {
      return;
    }

    //   const options = {
    //     chart: {
    //         type: "bar",
    //         toolbar: {
    //             show: false,
    //         },
    //         animations: {
    //             enabled: false,
    //         },
    //     },
    //     dataLabels: {
    //         enabled: true,
    //         offsetY: -20,
    //         style: {
    //             fontSize: "12px",
    //             colors: ["#304758"],
    //         },
    //         formatter: function(val) {
    //             return "";
    //         },
    //         rotate: -90,
    //     },
    //     stroke: {
    //         show: true,
    //         width: 2,
    //         colors: ["transparent"],
    //     },
    //     yaxis: {
    //         title: {
    //             text: "Ton CO2-e",
    //         },
    //         forceNiceScale: true,
    //     },
    //     legend: {
    //         markers: {
    //             width: 30,
    //         },
    //     },
    //     series: [
    //         {
    //             name: "scope",
    //             data: [totalScope1, totalScope2, totalScope3],
    //         },
    //     ],
    //     colors: ["#4472C4"],
    //     xaxis: {
    //         categories: ["Scope 1", "Scope 2", "Scope 3"],
    //     },
    // };

    const options = {
      chart: {
        height: 300,
        type: "pie",
        animations: {
          enabled: false
        }
      },
      colors: ["#426a2f", "#dc8d07", "#1c2d58"],
      // fill: {
      //     colors: ["#F44336", "#E91E63", "#9C27B0"],
      // },
      // colors: ["#4472C4"],

      series: [
        parseFloat(totalScope1),
        parseFloat(totalScope2),
        parseFloat(totalScope3)
      ],
      labels: ["Scope 1", "Scope 2", "Scope 3"],
      xaxis: {
        categories: ["Scope 1", "Scope 2", "Scope 3"]
      }
    };

    const chart = new ApexCharts(element, options);

    chart.render().then(tmp => {
      // window.setTimeout(function() {
      chart.dataURI().then(uri => {
        // setCacheWithExpiry("ghgpage2Uri", uri.imgURI);
        if (typeof setPageUri2 === "function") {
          const chartSrc = uri.imgURI.replace(
            /^data:image\/(png|jpg);base64,/,
            ""
          );
          if (chartSrc) {
          }
          setPageUri2(uri.imgURI);
        }
      });
      // }, 3000);
    });

    return function cleanUp() {
      chart.destroy();
    };
    // }
  }, [
    objectId2,
    // getPercentage,
    // setTable1PdfData,
    totalScope1,
    totalScope2,
    totalScope3,
    gettext,
    setPageUri2
    // getTablePdfData,
  ]);

  useEffect(() => {
    let jsonData = {
      headings: {
        h1: "",
        h2: gettext("Ton CO₂-e"),
        h3: gettext("Distribution of tons of CO₂-e (%)")
      },
      data: [
        {
          scope: gettext("Scope 1"),
          value: totalScope1,
          percentage: totalScope1Percentage
        },
        {
          scope: gettext("Scope 2"),
          value: totalScope2,
          percentage: totalScope2Percentage
        },
        {
          scope: gettext("Scope 3"),
          value: totalScope3,
          percentage: totalScope3Percentage
        },
        {
          scope: gettext("Total"),
          value: parseFloat(totalScope1 + totalScope2 + totalScope3).toFixed(2),
          percentage: parseFloat(
            totalScope1Percentage +
              totalScope2Percentage +
              totalScope3Percentage
          ).toFixed(2)
        },
        {
          scope: gettext("Out of Scope"),
          value: totalOutOfScope,
          percentage: totalOutOfScopePercentage
        }
      ]
    };
    setCSVData(jsonData);
    setTable1PdfData(jsonData);
  }, [
    totalScope1,
    totalScope2,
    totalScope3,
    totalOutOfScope,
    setTable1PdfData,
    gettext,
    totalScope1Percentage,
    totalScope2Percentage,
    totalScope3Percentage,
    totalOutOfScopePercentage
  ]);

  const getValue = value => {
    if (value) {
      return value;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (data) {
      setTotalScope1(getValue(data?.scope1?.value));
      setTotalScope1Percentage(
        // getOr(0, "scope1", data.scope1.percentage)
        getValue(data?.scope1?.percentage)
      );

      setTotalScope2(
        // getOr(0, "scope2", data.scope2.value)
        getValue(data?.scope2?.value)
      );
      setTotalScope2Percentage(
        // getOr(0, "scope2", data.scope2.percentage)
        getValue(data?.scope2?.percentage)
      );

      setTotalScope3(
        // getOr(0, "scope3", data.scope3.value)
        getValue(data?.scope3?.value)
      );
      setTotalScope3Percentage(
        // getOr(0, "scope3", data.scope3.percentage)
        getValue(data?.scope3?.percentage)
      );

      setTotalOutOfScope(
        // getOr(0, "outOfScope", data.scope1.value)
        getValue(data?.outOfScope?.value)
      );
      setTotalOutOfScopePercentage(
        // getOr(0, "outOfScope", data.outOfScope.percentage)
        getValue(data?.outOfScope?.percentage)
      );
    }
  }, [data]);

  return (
    <MainResultSection1 className="card card-custom gutter-b example example-compact">
      <MainResultHead className="row m-0" id={consumptionUniqueId}>
        <div className="col-sm-12 p-0">
          <h3 className="pl-2">{gettext("Main Results")}</h3>
          <hr />
          <p className="pl-2 pt-2">
            {gettext(
              "The company's total CO₂e emissions are stated in table 1 and are based on emission factors from 2019. The CO₂e emissions are stated in tons of CO₂ equivalents, and the table shows the emissions divided by scope 1, 2 and 3 cf. The GHG protocols (see any guidance). The discharge outside scopes is not included, cf. The GHG protocols."
            )}
          </p>
        </div>
      </MainResultHead>
      <br />
      <MainResultTable className="row m-0">
        <div className="col-sm-12 p-0 result-title">
          <p className="pl-2"> {gettext("Table 1")}</p>
        </div>
        <div className="table-title col-sm-12 pl-0">
          <p className=" col-xs-6 col-sm-6 col-md-6 col-lg-6 table-description">
            {gettext(figure1Title)}
          </p>
          <div className="table-toolbar pr-1">
            <Select
              options={downloadOptions}
              onChange={handleDownloadFile}
              value={[
                {
                  value: "download",
                  label: "Download"
                }
              ]}
              styles={selectStyles}
              isDisabled={false}
            />
          </div>
        </div>
        <div className="col-sm-12 p-0">
          <div className="row m-0 custom-row">
            <div className="col-sm-8"></div>
            <div className="col-sm-2">{gettext("Ton CO₂-e")}</div>
            <div className="col-sm-2">
              {gettext("Distribution of tons of CO₂-e (%)")}
            </div>
          </div>
          <div className="row m-0 border-top-row">
            <div className="col-sm-8">{gettext("Scope 1")}</div>
            <div className="col-sm-2">{totalScope1}</div>
            <div className="col-sm-2">{totalScope1Percentage}</div>
          </div>
          <div className="row m-0 border-top-row">
            <div className="col-sm-8">{gettext("Scope 2")}</div>
            <div className="col-sm-2">{totalScope2}</div>
            <div className="col-sm-2">{totalScope2Percentage}</div>
          </div>
          <div className="row m-0 border-top-row">
            <div className="col-sm-8">{gettext("Scope 3")}</div>
            <div className="col-sm-2">{totalScope3}</div>
            <div className="col-sm-2">{totalScope3Percentage}</div>
          </div>
          <div className="row m-0 border-top-row">
            <div className="col-sm-8">
              <strong>{gettext("Total")}</strong>
            </div>
            <div className="col-sm-2">
              <strong>
                {parseFloat(totalScope1 + totalScope2 + totalScope3).toFixed(2)}
              </strong>
            </div>
            <div className="col-sm-2">
              <strong>
                {parseFloat(
                  totalScope1Percentage +
                    totalScope2Percentage +
                    totalScope3Percentage
                ).toFixed(2)}
              </strong>
            </div>
          </div>
          <div className="row m-0 border-bottom-row border-total-row custom-row">
            <div className="col-sm-8">{gettext("Out of scope")}</div>
            <div className="col-sm-2">{totalOutOfScope}</div>
            <div className="col-sm-2">{totalOutOfScopePercentage}</div>
          </div>
        </div>
      </MainResultTable>

      <MainResultTable className="row m-0">
        <div className="col-sm-12 p-0">
          <p className="mt-4 mb-4 pl-2">
            {gettext(
              "The figures below show graphical representations of the company's CO₂e emissions. Figure 1 indicates the company's absolute CO₂e emissions in tonnes CO₂ equivalents divided by scopes. Figure 2 shows it percentage distribution of the CO₂e emission divided by scope 1, 2 and 3"
            )}
          </p>
        </div>
        <div className="col-sm-6 pl-0">
          <div className="row m-0">
            <div className="col-sm-12 p-0 result-title">
              {/* <p className="pl-2 pb-2">
                                {gettext(
                                    "Figure 1 - Distribution of CO2e emissions in tonnes"
                                )}
                            </p> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0 table-description">
              <p className="pl-2">{gettext(figure1Title)}</p>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-sm-12 p-0">
              <div
                id={objectId}
                className="card-rounded-bottom bg-gray-100"
                style={{ height: "200px" }}
              ></div>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="row m-0">
            <div className="col-sm-12 p-0 result-title">
              {/* <p className="pl-2 pb-2">
                                {gettext(
                                    "Figure 2 - Percentage distribution of CO2e emissions"
                                )}
                            </p> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0 table-description">
              <p className="pl-2">
                {gettext("Overview of the company's total CO₂e emissions")}
              </p>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-sm-12 p-0">
              <div
                id={objectId2}
                className="card-rounded-bottom bg-gray-100"
                style={{ height: "200px" }}
              ></div>
            </div>
          </div>
        </div>
      </MainResultTable>
    </MainResultSection1>
  );
};

export default ResultSection1;
