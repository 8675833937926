import React, { useContext } from "react";
import { Spinner, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import {
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { GoBack } from "app/components";
import { useGetUserName, useUpdateRoles, useUserRoles } from "./hooks";
import { useTextdomainContext } from "app/providers";
// import {
//   useAllRoles,

// } from "./hooks";

export function UserRoleUpdate() {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { uid } = useParams();

  const { userRoles } = useUserRoles(uid);

  const { userName } = useGetUserName(uid);

  // const { allRoles } = useAllRoles();

  const { update, loading } = useUpdateRoles();

  // const [selectedRole, setSelectedRole] = useState();

  // const handleClick = () => {
  //     if (Boolean(selectedRole)) {
  //         update({
  //             roles: [...userRoles, selectedRole],
  //             userId: uid,
  //         });
  //     }
  // };

  const handleDelete = role => {
    update({
      roles: userRoles.filter(userRole => userRole !== role),
      userId: uid
    });
  };

  function columnFormater(role) {
    return (
      <>
        <Tooltip title={gettext("Delete")} arrow>
          <IconButton
            onClick={() => handleDelete(role)}
            disabled={role === "Super Admin" || role === "Customer"}
          >
            <MdDelete size={18} />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <GoBack />

      <div className="card card-custom gutter-b example example-compact">
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3>{gettext("Roles")}</h3>
            <span className="font-size-sm w-100">
              {`${gettext("View or Update")} ${userName + "s "}${gettext(
                "Roles"
              )}`}
            </span>
          </div>

          {/* {
            <Collapse in={roleLimitMessage}>
                <Alert severity="error">
                <AlertTitle>
                    {gettext('WARNING')}
                </AlertTitle>
                {gettext('A user should have atleast one role assigned')}
                </Alert>
            </Collapse>
            } */}

          <div className="card-body">
            <div className="card">
              <div className="card-body">
                {userRoles?.length > 0 ? (
                  <>
                    <h3 className="card-title">
                      <strong>{userName}</strong>
                    </h3>

                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" width={"100%"}>
                              <Typography
                                color={"#fff"}
                                fontSize={13}
                                fontWeight={600}
                              >
                                {gettext("Name")}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                color={"#fff"}
                                fontSize={13}
                                fontWeight={600}
                              >
                                {gettext("Actions")}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userRoles?.map((role, index) => (
                            <TableRow key={index}>
                              <TableCell align="left" width={"100%"}>
                                <Typography fontSize={13} fontWeight={400}>
                                  {role}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography fontSize={13} fontWeight={400}>
                                  {columnFormater(role)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <p>{`Unable to find any Role assigned to ${userName}`}</p>
                )}

                {/* <select
                  className="custom-select mt-4 mb-2"
                  id="change_roles"
                  value={selectedRole}
                  onChange={e => setSelectedRole(e.target.value)}
                >
                  <option value={null}>Select...</option>
                  {allRoles &&
                    allRoles.map(role => {
                      if (!userRoles.includes(role.role)) {
                        // hiiding elements from drop down menu
                        if (
                          role.role !== "Service Provider" &&
                          role.role !== "Partner"
                        ) {
                          return (
                            <option value={role.role} key={role.role}>
                              {role.role}
                            </option>
                          );
                        } else return null;
                      } else return null;
                    })}
                </select>

                <button
                  className="btn btn-primary mt-3"
                  onClick={handleClick}
                  disabled={!Boolean(selectedRole)}
                >
                  {gettext("New Role")}
                </button>
                </button> */}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="light" />
          </div>
        ) : null}
      </div>
    </>
  );
}
