import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Modal, Button } from "react-bootstrap";
// import { useUpdateUser } from "../hooks";

export default function TrackModel({ show, onHide, editableRow }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  //   const { updateUser, updating } = useUpdateUser(() => {
  //     onHide();
  //   });
  //   const handleDelete = async () => {
  //     updateUser({
  //       userId: editableRow?.id,
  //       status: "Deleted"
  //     });
  //   };
  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{gettext("Track User")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {gettext("Are you sure you want to Track")}
        {/* {" " + editableRow.name} */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {gettext("Cancel")}
        </Button>
        <Button variant="primary">{gettext("Track")}</Button>
      </Modal.Footer>
      {/* {updating ? (
        <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : null} */}
    </Modal>
  );
}
