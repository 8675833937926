import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { UPDATE_USER_ROLES } from "../commands";

export function useUpdateRoles() {
  const [comUpdateUserAdmin, { loading }] = useMutation(UPDATE_USER_ROLES);

  const update = useCallback(
    variables => {
      comUpdateUserAdmin({
        variables,
        refetchQueries: ["getUserRoles"]
      });
    },
    [comUpdateUserAdmin]
  );

  return {
    update,
    loading
  };
}
