import React, { useMemo, useEffect, useContext } from "react";
import { useLang, useTextdomainContext, useUser } from "app/providers";
import objectPath from "object-path";
import { getOr } from "lodash/fp";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../_metronic/layout";
import { localeNumberFormat } from "app/common/Functions";

let defaultColors = ["#adb7c9", "#dc562b", "#004bff", "#c0c7b5"];
let defaultColorsElectricity = ["#adb7c9", "#dc562b", "#004bff", "#c0c7b5"];
let colorsElectricity = ["#adb7c9"];
let colorsHeat = ["#dc562b"];
let colorsWater = ["#004bff"];
let colorsEnergy = ["#004bff"];
let colorsCO2 = ["#c0c7b5"];

export function MixedWidget1(props) {
  const { colors } = useUser();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  let className = props.className;
  let chartTitle = props?.data?.chart_title
    ? props?.data?.chart_title.replace("CO2", "CO₂ measured in tons")
    : "";
  let uniqueId = props?.data?.unique_id;
  let media_type = props?.data?.media_type ? props?.data?.media_type : "web";
  let objectId = "kt_mixed_widget_1_chart" + uniqueId + "_" + media_type;

  const uiService = useHtmlClassService();

  useEffect(() => {
    if (colors) {
      const EColor = getOr("#adb7c9", "electricity.shade1.background", colors);
      const HColor = getOr("#dc562b", "heating.shade1.background", colors);
      const WColor = getOr("#004bff", "water.shade1.background", colors);
      const EnergyColor = getOr("#004bff", "energy.shade1.background", colors);
      const CO2Color = getOr("#c0c7b5", "co2.shade1.background", colors);

      defaultColors = [EColor, HColor, WColor];
      defaultColorsElectricity = [EColor, HColor, WColor];
      colorsElectricity = [EColor];
      colorsHeat = [HColor];
      colorsWater = [WColor];
      colorsEnergy = [EnergyColor];
      colorsCO2 = [CO2Color];
    }
  }, [colors]);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);
  const locale = useLang();

  useEffect(() => {
    const element = document.getElementById(objectId);
    if (!element) {
      return;
    }

    const options = getChartOptions(
      layoutProps,
      props.data,
      props?.data?.unique_id,
      props.addressColors,
      props?.label,
      locale
    );

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [props, layoutProps, objectId, locale]);

  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-gray-100 py-5">
        <h3 className="card-title font-weight-bolder text-black">
          {chartTitle !== "CO₂ measured in tons" && chartTitle !== "..."
            ? chartTitle
            : gettext("CO₂ eq measured in tons")}
        </h3>
      </div>
      {/* Body DASHBOARD.GRAPH.TITLE. */}
      <div className="card-body p-0 position-relative overflow-hidden">
        {/* Chart */}
        <div
          id={objectId}
          className="card-rounded-bottom bg-gray-100"
          style={{ height: "200px" }}
        ></div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}

function getChartOptions(
  layoutProps,
  data,
  uniqueId,
  addressColors,
  label,
  locale
) {
  const options = {
    series: data.data,
    chart: {
      type: "line",
      height: 250,
      id: uniqueId,
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: false
      },
      animations: {
        enabled: false
      }
    },
    legend: {
      show: true,
      showForSingleSeries: true,

      markers: {
        width: 30
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2
    },
    xaxis: {
      categories: data.categories
    },
    yaxis: {
      title: {
        text: data.yaxisTitleText
          ? data.yaxisTitleText.replace("CO2", "CO₂")
          : ""
      },
      labels: {
        formatter: function(val) {
          if (locale === "da") {
            const numValue = Number(val);
            const newStringValue = numValue.toFixed(2).toString();
            const updatedValue = newStringValue
              .replace(/\./g, "#")
              .replace(/,/g, ".")
              .replace(/#/g, ",");
            return updatedValue;
          } else {
            return localeNumberFormat(val, 2);
          }
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          if (locale === "da") {
            const numValue = Number(val);
            const newStringValue = numValue.toString();
            const updatedValue = newStringValue
              .replace(/\./g, "#")
              .replace(/,/g, ".")
              .replace(/#/g, ",");
            return updatedValue;
          } else {
            return val;
          }
        }
      },
      marker: {
        show: true
      }
    },
    colors:
      addressColors && addressColors.length > 0
        ? addressColors
        : getColors(data.chart_title, label),
    markers: {
      size: 3
    }
  };
  return options;
}

function getColors(chart_title, label) {
  switch ((chart_title, label)) {
    case "ELECTRICITY":
      return colorsElectricity;
    case "Energy":
      return colorsEnergy;
    case "CO2":
      return colorsCO2;
    case "ELECTRICITY_M2":
      return colorsElectricity;
    case "HEAT":
      return colorsHeat;
    case "HEAT_M2":
      return colorsHeat;
    case "WATER":
      return colorsWater;
    case "WATER_M2":
      return colorsWater;
    case "ELECTRICITY_CONSUMPTION":
      return defaultColorsElectricity;
    default:
      return defaultColors;
  }
}
