import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { GoBack } from "../../components";
import { Grid } from "@mui/material";
import { AddCustomerForm, CustomerListTable } from "../../features";

export function UserCustomer() {
  const { uid } = useParams();

  const [userName, setUserName] = useState(null);

  return (
    <>
      <GoBack />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AddCustomerForm userId={uid} userName={userName} />
        </Grid>
        <Grid item xs={12}>
          <CustomerListTable userId={uid} setUserName={setUserName} />{" "}
        </Grid>
      </Grid>
    </>
  );
}
