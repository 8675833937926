import React, { useEffect, useState, useContext, useMemo } from "react";
import { useTextdomainContext } from "app/providers";
import NewCo2Section2 from "./NewCo2Section2";
// import Co2SectionCol1Row1 from "../components/Co2SectionCol1Row1";
import NewCo2Section3 from "./NewCo2Section3";
import { setCacheWithExpiry } from "../../../common/Functions";
import {
  totalEnergyValue
  // dataEnergyGraph
} from "../partials/Co2Function";
import { Skeleton } from "@mui/material";

const Co2EnergySection = props => {
  const {
    params,
    // getMainTitle,
    // getMainDescription,
    data,
    energyData,
    electricityData,
    gasData,
    heatingData,
    waterData,
    energyLoading,
    electricityLoading,
    gasLoading,
    heatingLoading,
    waterLoading,
    sourceType,
    consumptionType,
    productionType,
    compensationType,
    setPage4MainTitle,
    setPage4MainTitleDescription,
    setPage5MainTitle,
    setPage5MainTitleDescription,
    setPage6MainTitle,
    setPage6MainTitleDescription,
    setPage7MainTitle,
    setPage7MainTitleDescription,
    // setPage8MainTitle,
    // setPage8MainTitleDescription,
    setPage9MainTitle,
    setPage9MainTitleDescription,
    setPage10MainTitle,
    setPage10MainTitleDescription,
    setPage11MainTitle,
    setPage11MainTitleDescription,
    // setPage12MainTitle,
    // setPage12MainTitleDescription,
    setPage13MainTitle,
    setPage13MainTitleDescription,
    setPage14MainTitle,
    setPage14MainTitleDescription,
    setPage18MainTitle,
    setPage18MainTitleDescription,
    // setPage15MainTitle,
    // setPage15MainTitleDescription,
    // setPage16MainTitle,
    // setPage16MainTitleDescription,
    // setPage17MainTitle,
    // setPage17MainTitleDescription,

    colors
  } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { fromYear, thirdYear, secondYear, toYear } = params;
  const [totalConsumption, setTotalConsumption] = useState(0);
  const [totalProduction, setTotalProduction] = useState(0);
  const [totalCompensation, setTotalCompensation] = useState(0);

  // const tableDropdownOptions = [
  //     { label: `${thirdYear}-${toYear}`, value: thirdYear },
  //     { label: `${secondYear}-${thirdYear} `, value: secondYear },
  //     { label: `${fromYear}-${secondYear}`, value: fromYear },
  // ];

  const tableDropdownOptions = useMemo(() => {
    return [
      { label: `${thirdYear}-${toYear}`, value: thirdYear },
      { label: `${secondYear}-${thirdYear} `, value: secondYear },
      { label: `${fromYear}-${secondYear}`, value: fromYear }
    ];
  }, [thirdYear, toYear, secondYear, fromYear]);

  const [selectedTableOption, setSelectedTableOption] = useState(
    tableDropdownOptions[0]
  );

  useEffect(() => {
    setSelectedTableOption(tableDropdownOptions[0]);
  }, [thirdYear, toYear, secondYear, fromYear, tableDropdownOptions]);

  // const [consumptionData, setConsumptionData] = useState(0);
  // const [productionData, setProductionData] = useState(0);
  // const [compensationData, setCompensationData] = useState(0);
  useEffect(() => {
    if (energyData && !energyLoading) {
      const result1 = totalEnergyValue(energyData, fromYear);
      const result2 = totalEnergyValue(energyData, secondYear);
      const result3 = totalEnergyValue(energyData, thirdYear);
      const conCo2 = result3[1] + result2[1] + result1[1];
      const prodCo2 = result3[3] + result2[3] + result1[3];
      setTotalConsumption(conCo2);
      setTotalProduction(prodCo2);
      setTotalCompensation(conCo2 - prodCo2);

      // const CData = dataEnergyGraph(
      //   [result1[1], result2[1], result3[1]],
      //   [fromYear, secondYear, toYear],
      //   consumptionType,
      //   "consumption",
      //   sourceType,
      //   colors,
      //   gettext,
      //   "co2"
      // );

      // setConsumptionData(CData ? null : null);

      // const PData = dataEnergyGraph(
      //   [result1[3], result2[3], result3[3]],
      //   [fromYear, secondYear, toYear],
      //   productionType,
      //   "production",
      //   sourceType,
      //   colors,
      //   gettext
      // );

      // setProductionData(PData ? null : null);

      // const CMData = dataEnergyGraph(
      //   [
      //     result1[1] - result1[3],
      //     result2[1] - result2[3],
      //     result3[1] - result3[3]
      //   ],
      //   [fromYear, secondYear, toYear],
      //   compensationType,
      //   "compensation",
      //   sourceType,
      //   colors,
      //   gettext
      // );
      // setCompensationData(CMData ? null : null);
    }
  }, [
    fromYear,
    consumptionType,
    productionType,
    compensationType,
    sourceType,
    secondYear,
    toYear,
    thirdYear,
    energyData,
    energyLoading,
    colors,
    gettext
  ]);
  // useEffect(() => {
  //   if (electricityData && !electricityLoading && !heatingLoading && !waterLoading) {
  //     const eFromYear = totalEnergyValue(electricityData, fromYear);
  //     const eSecondYear = totalEnergyValue(electricityData, secondYear);
  //     const eToYear = totalEnergyValue(electricityData, toYear);

  //     const hFromYear = totalEnergyValue(heatingData, fromYear);
  //     const hSecondYear = totalEnergyValue(heatingData, secondYear);
  //     const hToYear = totalEnergyValue(heatingData, toYear);

  //     const wFromYear = totalEnergyValue(waterData, fromYear);
  //     const wSecondYear = totalEnergyValue(waterData, secondYear);
  //     const wToYear = totalEnergyValue(waterData, toYear);

  //     const CData = divide3SourceGraph(
  //       [eToYear[1], eSecondYear[1], eFromYear[1]],
  //       [hToYear[1], hSecondYear[1], hFromYear[1]],
  //       [wToYear[1], wSecondYear[1], wFromYear[1]],
  //       [fromYear, secondYear, toYear],
  //       consumptionType,
  //       "consumption",
  //       sourceType
  //     );
  //       setData1(CData)
  //   }
  // }, [electricityData, heatingData, waterData, electricityLoading, heatingLoading, waterLoading, fromYear, secondYear, toYear]);

  return (
    <>
      {energyLoading ? (
        <>
          <Skeleton
            animation="wave"
            variant="rounded"
            height="10vh"
            sx={{ marginY: 3 }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height="10vh"
            sx={{ marginY: 2 }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height=" 35vh"
            sx={{ marginY: 2 }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height=" 35vh"
            sx={{ marginY: 2 }}
          />
        </>
      ) : (
        <>
          {totalConsumption > 0 && (
            <>
              {/* <Co2SectionCol1Row1
                data1={consumptionData}
                imageUrl={uri => {
                  setCacheWithExpiry("page3Uri", uri);
                  if (typeof props.setPage3Title === "function") {
                    props.setPage3Title(consumptionData.chart_title);
                  }
                }}
              />  */}
              {/* //page 4 */}
              <NewCo2Section2
                data={data}
                params={params}
                energyData={energyData}
                electricityData={electricityData}
                gasData={gasData}
                heatingData={heatingData}
                waterData={waterData}
                energyLoading={energyLoading}
                electricityLoading={electricityLoading}
                gasLoading={gasLoading}
                heatingLoading={heatingLoading}
                waterLoading={waterLoading}
                sourceType={sourceType}
                consumptionType={consumptionType}
                chartId="co2-chart-engery-consumption"
                chartType="Consumption"
                sourceIndex={1}
                setPageUri={uri => {
                  setCacheWithExpiry("page3Uri", uri);
                }}
                setMainPageTitle={setPage4MainTitle}
                setMainPageDescription={setPage4MainTitleDescription}
                setPageTitle={props?.setPage4Title}
                colors={colors}
                stacked={false}
              />

              {/* third year */}
              <div
                style={{
                  position: "absolute",
                  top: "-9999px",
                  left: "-9999px"
                }}
              >
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  gasData={gasData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  gasLoading={gasLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={thirdYear}
                  sourceIndex={2}
                  chartType="Consumption"
                  chartId1={"co2-chart-1-consumption-3y" + thirdYear}
                  chartId2={"co2-chart-2-consumption-3y" + thirdYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page11Uri", uri);
                  }}
                  setMainPageTitle1={setPage11MainTitle}
                  setMainPageDescription={setPage11MainTitleDescription}
                  setPageTitle1={props?.setPage11Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page11Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage11Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              </div>

              {/* second year */}
              <div
                style={{
                  position: "absolute",
                  top: "-9999px",
                  left: "-9999px"
                }}
              >
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  gasData={gasData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  gasLoading={gasLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={secondYear}
                  sourceIndex={2}
                  chartType="Consumption"
                  chartId1={"co2-chart-1-consumption-2y" + secondYear}
                  chartId2={"co2-chart-2-consumption-2y" + secondYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page6Uri", uri);
                  }}
                  setMainPageTitle1={setPage6MainTitle}
                  setMainPageDescription={setPage6MainTitleDescription}
                  setPageTitle1={props?.setPage6Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page6Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage6Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              </div>

              {/* from year */}
              <div
                style={{
                  position: "absolute",
                  top: "-9999px",
                  left: "-9999px"
                }}
              >
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  gasData={gasData}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  gasLoading={gasLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={fromYear}
                  sourceIndex={2}
                  chartType="Consumption"
                  chartId1={"co2-chart-1-consumption-1y" + fromYear}
                  chartId2={"co2-chart-2-consumption-1y" + fromYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page7Uri", uri);
                  }}
                  setMainPageTitle1={setPage7MainTitle}
                  setMainPageDescription={setPage7MainTitleDescription}
                  setPageTitle1={props?.setPage7Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page7Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage7Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              </div>

              {/* page 5 */}
              {selectedTableOption.value === thirdYear ? (
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  gasData={gasData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  gasLoading={gasLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={thirdYear}
                  sourceIndex={2}
                  chartType="Consumption"
                  chartId1={"co2-chart-1-consumption" + thirdYear}
                  chartId2={"co2-chart-2-consumption" + thirdYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page5Uri", uri);
                  }}
                  setMainPageTitle1={setPage5MainTitle}
                  setMainPageDescription={setPage5MainTitleDescription}
                  setPageTitle1={props?.setPage5Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page5Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage5Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              ) : selectedTableOption.value === secondYear ? (
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  gasData={gasData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  gasLoading={gasLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={secondYear}
                  sourceIndex={2}
                  chartType="Consumption"
                  chartId1={"co2-chart-1-consumption" + secondYear}
                  chartId2={"co2-chart-2-consumption" + secondYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page5Uri", uri);
                  }}
                  setMainPageTitle1={setPage5MainTitle}
                  setMainPageDescription={setPage5MainTitleDescription}
                  setPageTitle1={props?.setPage5Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page5Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage5Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              ) : (
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  gasData={gasData}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  gasLoading={gasLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={fromYear}
                  sourceIndex={2}
                  chartType="Consumption"
                  chartId1={"co2-chart-1-consumption" + fromYear}
                  chartId2={"co2-chart-2-consumption" + fromYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page5Uri", uri);
                  }}
                  setMainPageTitle1={setPage5MainTitle}
                  setMainPageDescription={setPage5MainTitleDescription}
                  setPageTitle1={props?.setPage5Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page5Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage5Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              )}
            </>
          )}
          {totalProduction > 0 && (
            <>
              {/* page 9 */}

              <NewCo2Section2
                data={data}
                params={params}
                energyData={energyData}
                gasData={gasData}
                gasLoading={gasLoading}
                electricityData={electricityData}
                heatingData={heatingData}
                waterData={waterData}
                energyLoading={energyLoading}
                electricityLoading={electricityLoading}
                heatingLoading={heatingLoading}
                waterLoading={waterLoading}
                sourceType={sourceType}
                consumptionType={productionType}
                chartId="co2-chart-engery-production"
                chartType="Production"
                sourceIndex={3}
                setMainPageTitle={setPage9MainTitle}
                setMainPageDescription={setPage9MainTitleDescription}
                setPageUri={uri => {
                  setCacheWithExpiry("page9Uri", uri);
                }}
                setPageTitle={props?.setPage9Title}
                colors={colors}
                stacked={false}
              />

              {/* third year */}
              <div
                style={{
                  position: "absolute",
                  top: "-9999px",
                  left: "-9999px"
                }}
              >
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  gasData={gasData}
                  gasLoading={gasLoading}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={thirdYear}
                  sourceIndex={4}
                  chartType="Production"
                  chartId1={"co2-chart-1-production-3y" + thirdYear}
                  chartId2={"co2-chart-2-production-3y" + thirdYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page13Uri", uri);
                  }}
                  setMainPageTitle1={setPage13MainTitle}
                  setMainPageDescription={setPage13MainTitleDescription}
                  setPageTitle1={props?.setPage13Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page13Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage13Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              </div>

              {/* second year */}

              <div
                style={{
                  position: "absolute",
                  top: "-9999px",
                  left: "-9999px"
                }}
              >
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  gasData={gasData}
                  gasLoading={gasLoading}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={secondYear}
                  sourceIndex={4}
                  chartType="Production"
                  chartId1={"co2-chart-1-production-2y" + secondYear}
                  chartId2={"co2-chart-2-production-2y" + secondYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page14Uri", uri);
                  }}
                  setMainPageTitle1={setPage14MainTitle}
                  setMainPageDescription={setPage14MainTitleDescription}
                  setPageTitle1={props.setPage14Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page14Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage14Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              </div>

              {/* to year */}

              <div
                style={{
                  position: "absolute",
                  top: "-9999px",
                  left: "-9999px"
                }}
              >
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  gasData={gasData}
                  gasLoading={gasLoading}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={fromYear}
                  sourceIndex={4}
                  chartType="Production"
                  chartId1={"co2-chart-1-production-1y" + fromYear}
                  chartId2={"co2-chart-2-production-1y" + fromYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page18Uri", uri);
                  }}
                  setMainPageTitle1={setPage18MainTitle}
                  setMainPageDescription={setPage18MainTitleDescription}
                  setPageTitle1={props?.setPage18Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page18Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage18Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              </div>

              {selectedTableOption.value === thirdYear ? (
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  gasData={gasData}
                  gasLoading={gasLoading}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={thirdYear}
                  sourceIndex={4}
                  chartType="Production"
                  chartId1={"co2-chart-1-production" + thirdYear}
                  chartId2={"co2-chart-2-production" + thirdYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page10Uri", uri);
                  }}
                  setMainPageTitle1={setPage10MainTitle}
                  setMainPageDescription={setPage10MainTitleDescription}
                  setPageTitle1={props?.setPage10Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page10Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage10Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              ) : selectedTableOption.value === secondYear ? (
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  gasData={gasData}
                  gasLoading={gasLoading}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={secondYear}
                  sourceIndex={4}
                  chartType="Production"
                  chartId1={"co2-chart-1-production" + secondYear}
                  chartId2={"co2-chart-2-production" + secondYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page10Uri", uri);
                  }}
                  setMainPageTitle1={setPage10MainTitle}
                  setMainPageDescription={setPage10MainTitleDescription}
                  setPageTitle1={props.setPage10Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page10Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage10Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              ) : (
                <NewCo2Section3
                  data={data}
                  params={params}
                  energyData={energyData}
                  gasData={gasData}
                  gasLoading={gasLoading}
                  electricityData={electricityData}
                  heatingData={heatingData}
                  waterData={waterData}
                  energyLoading={energyLoading}
                  electricityLoading={electricityLoading}
                  heatingLoading={heatingLoading}
                  waterLoading={waterLoading}
                  sourceType={sourceType}
                  consumptionType={consumptionType}
                  year={fromYear}
                  sourceIndex={4}
                  chartType="Production"
                  chartId1={"co2-chart-1-production" + fromYear}
                  chartId2={"co2-chart-2-production" + fromYear}
                  setPageUri1={uri => {
                    setCacheWithExpiry("page10Uri", uri);
                  }}
                  setMainPageTitle1={setPage10MainTitle}
                  setMainPageDescription={setPage10MainTitleDescription}
                  setPageTitle1={props?.setPage10Title}
                  setPageUri2={uri => {
                    setCacheWithExpiry("page10Uri2", uri);
                  }}
                  setPageTitle2={props?.setPage10Title2}
                  colors={colors}
                  grid="dashed"
                  background="colored"
                  legend="bottomLeft"
                  setSelectedTableOption={setSelectedTableOption}
                  tableDropdownOptions={tableDropdownOptions}
                  selectedTableOption={selectedTableOption}
                />
              )}
            </>
          )}
          {totalCompensation > 0 && (
            <>
              {/* <NewCo2Section2
                data={data}
                params={params}
                energyData={energyData}
                electricityData={electricityData}
                heatingData={heatingData}
                waterData={waterData}
                energyLoading={energyLoading}
                electricityLoading={electricityLoading}
                heatingLoading={heatingLoading}
                waterLoading={waterLoading}
                sourceType={sourceType}
                sourceIndex={3}
                consumptionType={compensationType}
                chartId="co2-chart-engery-compensation"
                chartType="Compensation"
                setPageUri={uri => {
                  setCacheWithExpiry("page14Uri", uri);
                }}
                setMainPageTitle={setPage14MainTitle}
                setMainPageDescription={setPage14MainTitleDescription}
                setPageTitle={props?.setPage14Title}
                colors={colors}
              /> */}
              {/* <NewCo2Section3
                data={data}
                params={params}
                energyData={energyData}
                electricityData={electricityData}
                heatingData={heatingData}
                waterData={waterData}
                energyLoading={energyLoading}
                electricityLoading={electricityLoading}
                heatingLoading={heatingLoading}
                waterLoading={waterLoading}
                sourceType={sourceType}
                consumptionType={consumptionType}
                year={toYear}
                sourceIndex={4}
                chartType="Compensation"
                chartId1={"co2-chart-1-compensation" + toYear}
                chartId2={"co2-chart-2-compensation" + toYear}
                setPageUri1={uri => {
                  setCacheWithExpiry("page15Uri", uri);
                }}
                setMainPageTitle1={setPage15MainTitle}
                setMainPageDescription={setPage15MainTitleDescription}
                setPageTitle1={props?.setPage15Title}
                setPageUri2={uri => {
                  setCacheWithExpiry("page15Uri2", uri);
                }}
                setPageTitle2={props?.setPage15Title2}
                colors={colors}
              />
              <NewCo2Section3
                data={data}
                params={params}
                energyData={energyData}
                electricityData={electricityData}
                heatingData={heatingData}
                waterData={waterData}
                energyLoading={energyLoading}
                electricityLoading={electricityLoading}
                heatingLoading={heatingLoading}
                waterLoading={waterLoading}
                sourceType={sourceType}
                consumptionType={consumptionType}
                year={secondYear}
                sourceIndex={4}
                chartType="Compensation"
                chartId1={"co2-chart-1-compensation" + secondYear}
                chartId2={"co2-chart-2-compensation" + secondYear}
                setPageUri1={uri => {
                  setCacheWithExpiry("page16Uri", uri);
                }}
                setMainPageTitle1={setPage16MainTitle}
                setMainPageDescription={setPage16MainTitleDescription}
                setPageTitle1={props?.setPage16Title}
                setPageUri2={uri => {
                  setCacheWithExpiry("page16Uri2", uri);
                }}
                setPageTitle2={props?.setPage16Title2}
                colors={colors}
              />
              <NewCo2Section3
                data={data}
                params={params}
                energyData={energyData}
                electricityData={electricityData}
                heatingData={heatingData}
                waterData={waterData}
                energyLoading={energyLoading}
                electricityLoading={electricityLoading}
                heatingLoading={heatingLoading}
                waterLoading={waterLoading}
                sourceType={sourceType}
                consumptionType={consumptionType}
                year={fromYear}
                sourceIndex={4}
                chartType="Compensation"
                chartId1={"co2-chart-1-compensation" + fromYear}
                chartId2={"co2-chart-2-compensation" + fromYear}
                setPageUri1={uri => {
                  setCacheWithExpiry("page17Uri", uri);
                }}
                setMainPageTitle1={setPage17MainTitle}
                setMainPageDescription={setPage17MainTitleDescription}
                setPageTitle1={props?.setPage17Title}
                setPageUri2={uri => {
                  setCacheWithExpiry("page17Uri2", uri);
                }}
                setPageTitle2={props?.setPage17Title2}
                colors={colors}
              /> */}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Co2EnergySection;
