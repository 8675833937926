import { gql } from "@apollo/client";

export const GET_ACTIVE_USERS = gql`
  query comActiveUsers($searchString: String) {
    comActiveUsers(searchString: $searchString) {
      id
      name
      email
      country
      company
    }
  }
`;
