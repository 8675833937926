import { gql } from "@apollo/client";

export const UPDATE_ROLE_MODULES = gql`
  mutation updateRoleModule(
    $module: String
    $role: String
    $updateData: GenericScalar
  ) {
    comUpdateRoleModule(module: $module, role: $role, updateData: $updateData) {
      success
      message
    }
  }
`;
