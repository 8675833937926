import React from "react";
import { injectIntl } from "react-intl";

function EpcBars({ EPCTableValues }) {
  return (
    <>
      <div className="d-flex flex-column mw-0 text-white">
        {EPCTableValues &&
          EPCTableValues.map((item, index) => (
            <div
              className={`col-${item.len} p-2 m-2 d-flex justify-content-between`}
              style={{ background: item.color }}
              key={`bar${index}`}
            >
              <div>{item.min + "-" + item.max}</div>
              <div>{item.name}</div>
            </div>
          ))}
      </div>
    </>
  );
}

export default injectIntl(EpcBars);
