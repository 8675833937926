import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const StatusMessage = props => {
  const { isError, isSuccess, statusMessage } = props;

  return (
    <>
      {isError && (
        <div
          className="alert alert-custom alert-light-danger fade show mb-10"
          role="alert"
        >
          <div className="alert-icon">
            <span className="svg-icon svg-icon-3x svg-icon-danger">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              ></SVG>{" "}
            </span>
          </div>
          <div className="alert-text font-weight-bold">{statusMessage}</div>
          <div
            className="alert-close"
            // onClick={() => this.props.setState({ isError: false })}
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
      )}

      {isSuccess && (
        <div
          className="alert alert-custom alert-light-success fade show mb-10"
          role="alert"
        >
          <div className="alert-icon">
            <span className="svg-icon svg-icon-3x svg-icon-success">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              ></SVG>{" "}
            </span>
          </div>
          <div className="alert-text font-weight-bold">{statusMessage}</div>
          <div
            className="alert-close"
            // onClick={() => this.props.setState({ isSuccess: false })}
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="ki ki-close"></i>
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default StatusMessage;
