import React, { useContext, useEffect, useState } from "react";
import { useTextdomainContext } from "app/providers";
import {
  Grid,
  TextField,
  Stack,
  Box,
  Button,
  Collapse,
  Alert,
  AlertTitle,
  MenuItem,
  Autocomplete
} from "@mui/material";
import { Spinner, Modal } from "react-bootstrap";
import { useEditProvider } from "../hooks";
import { changeHandler } from "../utils";
import Toast from "app/components/toast/Toast";

function EditProvider(props) {
  const {
    providerId,
    editModal,
    setEditModal,
    currentProvider,
    countries
  } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [form, setForm] = useState({
    name: "",
    category: "",
    country: "",
    api: "",
    url: ""
  });
  const [error, setError] = useState(null);
  const [toastList, setToastList] = useState([]);

  const { update, updating } = useEditProvider();

  const handleChange = e => {
    changeHandler(e, form, setForm, error, setError);
  };

  const submitHandler = async e => {
    e.preventDefault();
    let formData = { ...form };
    delete formData.country;
    formData["countryCode"] = form?.country?.code || "";
    const result = await update({ ...formData, providerId });
    if (result.data.editServiceProvider.success) {
      const toastArray = [
        {
          title: gettext("Provider Updated Successfully!"),
          type: "success"
        }
      ];
      setToastList(toastArray);
      setEditModal(false);
    } else {
      setError(result.data.editServiceProvider.message[0]);
    }
  };

  useEffect(() => {
    if (currentProvider) {
      let [selCountry] = countries.filter(
        con => con.code === currentProvider.countryCode
      );
      let formState = { ...currentProvider };
      delete formState.countryCode;
      setForm({ ...formState, country: selCountry });
    }
  }, [countries, currentProvider]);

  return (
    <>
      <Modal
        show={editModal}
        animation
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{gettext("Edit Provider")}</Modal.Title>
        </Modal.Header>
        <form onSubmit={submitHandler}>
          <div className="card-body">
            <Collapse in={error ? true : false}>
              <Alert severity="error">
                <AlertTitle>{gettext("Error")}</AlertTitle>
                {gettext(error)}
              </Alert>
            </Collapse>
            <Stack spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      type="text"
                      label={gettext("Name")}
                      size="small"
                      name="name"
                      inputProps={{ maxLength: 50 }}
                      value={form.name}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      label={gettext("Category")}
                      name="category"
                      onChange={handleChange}
                      value={form.category}
                      select
                      fullWidth
                      size="small"
                    >
                      <MenuItem value={"energy"}>Energy</MenuItem>
                      <MenuItem value={"electricity"}>Electricity</MenuItem>
                      <MenuItem value={"heating"}>Heatiing</MenuItem>
                      <MenuItem value={"water"}>Water</MenuItem>
                      <MenuItem value={"gas"}>Gas</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      disablePortal
                      options={countries}
                      getOptionLabel={option => option.name || ""}
                      value={form.country}
                      onChange={(e, newValue) => {
                        if (
                          error &&
                          error.toLowerCase().includes("country") &&
                          newValue
                        ) {
                          setError(null);
                        }
                        setForm({ ...form, country: newValue });
                      }}
                      size="small"
                      fullWidth
                      isOptionEqualToValue={(option, value) =>
                        option.value === value
                      }
                      renderInput={params => (
                        <TextField {...params} label={gettext("Country")} />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      type="text"
                      label={gettext("Url")}
                      size="small"
                      name="url"
                      value={form.url}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      type="text"
                      label={gettext("API")}
                      name="api"
                      value={form.api}
                      onChange={handleChange}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box className="ml-auto mr-11">
                <Button
                  className="mr-2 btn btn-primary"
                  type="submit"
                  disabled={updating}
                >
                  {updating ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    <>{gettext("Update")}</>
                  )}
                </Button>
                <Button
                  className="btn btn-secondary"
                  onClick={() => {
                    setEditModal(false);
                  }}
                >
                  {gettext("Cancel")}
                </Button>
              </Box>
            </Stack>
          </div>
        </form>
      </Modal>
      <Toast toastList={toastList} />
    </>
  );
}

export default EditProvider;
