import { Grid } from "@mui/material";
import DataHubTable from "./components/DataHubTable";
import { useDataHubMeterDetailsDaily } from "./hooks";

const MeterDetailsDaily = props => {
  const {
    view,
    setView,
    meteringPointId,
    date,
    setDate,
    selectedSegment,
    setSelectedSegment,
    gettext
  } = props;

  const { meterDetailDaily, loading } = useDataHubMeterDetailsDaily(
    meteringPointId,
    date
  );

  return (
    <Grid item>
      <DataHubTable
        title={view}
        setView={setView}
        view={view}
        rows={meterDetailDaily}
        selectededDate={date}
        segment={selectedSegment}
        getSelectedDate={(date, selectedSegment) => {
          setDate(date);
        }}
        onSegmentChange={selectedSegment => {
          setDate(date);
          setSelectedSegment(selectedSegment);
        }}
        gettext={gettext}
        loading={loading}
      />
    </Grid>
  );
};
export default MeterDetailsDaily;
