import { useUser } from "app/providers";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export function RequirePermission({ code }) {
  const location = useLocation();

  const { user } = useUser();
  const { isSuperAdmin } = user || {};

  const { modulePermissions } = user || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (code && modulePermissions && modulePermissions.length > 0) {
      const module = modulePermissions.find(item => item.code === code);

      if (!(module?.read || module?.write) && !isSuperAdmin) {
        navigate("/error/error-v3", {
          replace: true,
          state: { from: location }
        });
      }
    }
  }, [modulePermissions, code, location, navigate, isSuperAdmin]);

  return <Outlet />;
}
