import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { getOr } from "lodash/fp";
import {
  getUnitDateOption,
  getHourlyExportDates,
  getFileDataType
} from "../../../../hooks/useGCommon";
import { GET_WATER_QUERY } from "../../../../GraphQL/Queries";

const DownloadFile = props => {
  const {
    selectedFileType,
    energyParams,
    setExportDisabled,
    timeStamp,
    exportDisabled
  } = props;
  const {
    customers,
    buildingCode,
    meter,
    type,
    segment,
    date
    // addressesList
  } = energyParams;
  const { unitStart, unitEnd } = getUnitDateOption(segment, date);
  const { fromDate, toDate } = getHourlyExportDates(
    segment,
    unitStart,
    unitEnd
  );
  const { fileType, segmentType } = getFileDataType(selectedFileType, segment);
  // const [oldFileType, setOldFileType] = useState("");
  const [
    fetchListVoxes,
    { data: fileMeterData, refetch: refetchNewListVoxes }
  ] = useLazyQuery(GET_WATER_QUERY, {
    variables: {
      meterType: type === "gas" ? "natural_gas" : type,
      meteringPointId: meter,
      address: buildingCode,
      customer: customers,
      fromDate: fromDate,
      toDate: toDate,
      resolution: segmentType,
      fileType: fileType
    }
  });
  useEffect(() => {
    if (fileMeterData) {
      const fileUrl = getOr(null, "metersData.fileUrl", fileMeterData);
      if (fileUrl) {
        window.open(fileUrl);
      }
      setExportDisabled(false);
      // setOldFileType(selectedFileType);
    }
  }, [fileMeterData, setExportDisabled, timeStamp]);

  useEffect(() => {
    if (selectedFileType && selectedFileType !== "download" && exportDisabled) {
      fetchListVoxes();
      refetchNewListVoxes();
    }
  }, [
    selectedFileType,
    fetchListVoxes,
    refetchNewListVoxes,
    exportDisabled,
    fileType
    // timeStamp
    // fileMeterData
  ]);

  return true;
};

export default DownloadFile;
