import { gql } from "@apollo/client";

export const SCRAPPER_LOGS = gql`
  query scrapperLogs($search: String, $queryFilter: FilterInput) {
    scrapperLogs(search: $search, queryFilter: $queryFilter) {
      Id
      autoId0
      createdAt
      description
      loginId
      scrapperName
      status
      updatedAt
      url
    }
  }
`;
