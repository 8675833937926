import { gql } from "@apollo/client";

export const GET_ACCOUNT_DATA_BY_ID = gql`
  query accountData($id: String!) {
    accountData(id: $id) {
      Id
      createdAt
      fileName
      data {
        accountNumber
        accountName
        period
        yearBefore
      }
    }
  }
`;

export const UPDATE_ACCOUNT_DATA_ELEMENT = gql`
  mutation updateAccountData(
    $id: String!
    $data: AccountDataUpdateInput!
    $accountNumber: Int!
  ) {
    updateAccountData(id: $id, data: $data, accountNumber: $accountNumber) {
      message
      success
    }
  }
`;

export const DELETE_ACCOUNT_DATA_ELEMENT = gql`
  mutation deleteAccountDataElement($id: String!, $accountNumber: Int!) {
    deleteAccountDataElement(id: $id, accountNumber: $accountNumber) {
      message
      success
    }
  }
`;
