import { useQuery } from "@apollo/client";
import { GET_BUILDING_METERS } from "../commands";

export function useGetBuildingMeters(buildingId, sourceType) {
  const { data, loading } = useQuery(GET_BUILDING_METERS, {
    variables: {
      buildingId,
      sourceType
    },
    fetchPolicy: "no-cache"
  });

  return {
    meters: data?.customersMeters || [],
    loading
  };
}
