import useTimeout from "hooks/useTimeout";
import { useEffect, useState } from "react";

export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const { reset, clear } = useTimeout(() => setDebouncedValue(value), delay);
  useEffect(reset, [value, reset]);
  useEffect(clear, [clear]);

  return debouncedValue;
}
