import React, { useState, useContext, useEffect } from "react";
import { useTextdomainContext } from "app/providers";
import { Modal, Button, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDeleteMeterData } from "./hooks";
import { useParams } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import moment from "moment";

export function DeleteMeterData({ show, onHide, editableRow }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [resolution, setResolution] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showErrorMessage, setshowErrorMessage] = useState(null);
  const [showSuccessMessage, setshowSuccessMessage] = useState(false);
  const { type } = useParams();

  const reset = () => {
    setResolution("");
    setStartDate("");
    setEndDate("");
  };

  const { delMeterData, loading } = useDeleteMeterData(
    () => {
      setshowSuccessMessage(true);

      setTimeout(() => {
        setshowSuccessMessage(false);
      }, 5000);

      reset();
    },
    () => {
      setshowErrorMessage(true);
      setTimeout(() => {
        setshowErrorMessage(false);
      }, 5000);
    }
  );

  const setNewEndDate = date => {
    setEndDate(date);
  };
  const setNewStartDate = date => {
    setStartDate(date);
  };

  const submitHandler = e => {
    e.preventDefault();

    setshowSuccessMessage(false);
    setshowErrorMessage(false);

    const newEndDate = moment(endDate).format("YYYY/MM/DD");
    const newStartDate = moment(startDate).format("YYYY/MM/DD");
    const endUtcDate = moment.utc(newEndDate).format();
    const startUtcDate = moment.utc(newStartDate).format();

    delMeterData({
      fromDate: startUtcDate,
      meterNo: editableRow?.meterNo,
      // meterType: editableRow?.meterType,
      meterType: type === "heating" ? editableRow?.meterType : type,
      resolution: resolution,
      toDate: endUtcDate
    });
  };

  useEffect(() => {
    setshowErrorMessage(null);
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        animation
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Meter </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            <Collapse in={showSuccessMessage}>
              <Alert severity="success">
                <AlertTitle>{gettext("Success")}</AlertTitle>
                {gettext("Meter Data has been deleted Successfully")}
              </Alert>
            </Collapse>
          }
          {
            <Collapse in={showErrorMessage}>
              <Alert severity="error">
                <AlertTitle>{gettext("Error")}</AlertTitle>
                {gettext("There was a problem deleting Meter Data")}
              </Alert>
            </Collapse>
          }
          <form onSubmit={submitHandler}>
            <div className="card-body">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>{gettext("Resolution")}</label>

                  <select
                    className="form-control"
                    id="Duration"
                    name="Duration"
                    required
                    value={resolution}
                    onChange={e => setResolution(e.target.value)}
                  >
                    <option value="">{gettext("Select...")}</option>
                    <option value="daily">{gettext("Daily")}</option>
                    <option value="monthly">{gettext("Monthly")}</option>
                  </select>
                </div>

                <div className="form-group col-md-6">
                  <label>{gettext("Start Date")}</label>

                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={date => setNewStartDate(date)}
                    placeholderText={gettext("Select Date/Time")}
                    required
                    showYearPicker={resolution === "yearly"}
                    showMonthYearPicker={resolution === "monthly"}
                    dateFormat={
                      resolution === "yearly"
                        ? "yyyy"
                        : resolution === "monthly"
                        ? "MMMM yyyy"
                        : "MMMM d, yyyy"
                    }
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>{gettext("End Date")}</label>

                  <DatePicker
                    className="form-control"
                    selected={endDate}
                    onChange={date => setNewEndDate(date)}
                    placeholderText={gettext("Select Date/Time")}
                    required
                    showYearPicker={resolution === "yearly"}
                    showTimeSelect={resolution === "hourly"}
                    showMonthYearPicker={resolution === "monthly"}
                    dateFormat={
                      resolution === "yearly"
                        ? "yyyy"
                        : resolution === "monthly"
                        ? "MMMM yyyy"
                        : "MMMM d, yyyy"
                    }
                  />
                </div>
                {startDate > endDate ? (
                  <p style={{ color: "red" }}>Select Valid Date</p>
                ) : (
                  <p>{null}</p>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {gettext("Close")}
          </Button>

          {loading ? (
            <Spinner animation="border" variant="dark" />
          ) : (
            <Button onClick={submitHandler} variant="primary">
              {gettext("Delete Meter")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
