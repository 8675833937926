import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useMutation, useQuery } from "@apollo/client";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";
import { GET_ACCOUNT_CHARTS } from "../../../../GraphQL/Queries";
import { DELETE_ACCOUNT_CHART } from "../../../../GraphQL/Mutations";
import { ChartList, TableList } from "./chartStyle";
import { IconButton, Tooltip } from "@mui/material";
import { MdModeEdit, MdDelete } from "react-icons/md";

const AccountChartList = () => {
  const navigate = useNavigate();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [accountChartData, setAccountChartData] = useState([]);
  // const [deletedId, setDeletedId] = useState("");
  const { data, loading } = useQuery(GET_ACCOUNT_CHARTS, {
    variables: {}
  });

  useEffect(() => {
    if (data) {
      const { chartOfAccounts } = data;
      setAccountChartData(chartOfAccounts);
    }
  }, [data]);

  const handleNewAccountChart = () => {
    navigate("/admin/account-charts/new");
  };

  const handleEditAccountChart = item => {
    navigate(`/admin/account-charts/${item.Id}/edit`);
  };

  const [deleteAccountGroup] = useMutation(DELETE_ACCOUNT_CHART, {
    onCompleted(data) {
      // setDeletedId("");
    },
    onError(error) {
      // setDeletedId("");
      // error.graphQLErrors.map(({ message }) => {
      //   // console.log("Error =>", error);
      // });
    }
  });

  const handleDeleteAccountChartMutation = item => {
    deleteAccountGroup({
      variables: {
        id: item.Id
      },
      refetchQueries: [{ query: GET_ACCOUNT_CHARTS }]
    });
  };

  const handleDeleteAccountChart = item => {
    confirmAlert({
      title: gettext("Confirmation"),
      message: gettext("Are you sure you want to Delete ") + item.nameDa,
      buttons: [
        {
          label: gettext("Yes"),
          onClick: () => {
            // setDeletedId(item.Id);
            handleDeleteAccountChartMutation(item);
          }
        },
        {
          label: gettext("No"),
          onClick: () => {
            // setDeletedId("");
          }
        }
      ]
    });
  };

  return (
    <ChartList className="card card-custom gutter-b example example-compact">
      <div className="row m-0">
        <div className="col-sm-10 pt20 pb8 title">
          {gettext("Account Charts")}
        </div>
        <div className="col-sm-2 pt20 pb8 new-account-chart">
          <button type="button" onClick={handleNewAccountChart}>
            {gettext("Create Account Chart")}
          </button>
        </div>
      </div>
      {/* <hr /> */}
      {loading ? (
        <>
          <p>Loading</p>
        </>
      ) : (
        <TableList>
          <div className="row m-0 table-heading">
            <div className="col-sm-1">{gettext("Id")}</div>
            <div className="col-sm-2">{gettext("Account Number")}</div>
            <div className="col-sm-3">{gettext("Name")}</div>
            <div className="col-sm-4">{gettext("Category")}</div>
            <div className="col-sm-2 action-button">{gettext("Action")}</div>
          </div>
          {accountChartData.map((item, index) => (
            <div className="row m-0 top-border" key={"corporate_" + index}>
              <div className="col-sm-1">{index + 1}</div>
              <div className="col-sm-2">{item.accountNumber}</div>
              <div className="col-sm-3">{item.name}</div>
              <div className="col-sm-4">{item.category}</div>
              <div className="col-sm-1 action-button">
                <Tooltip title={gettext("Edit")} arrow>
                  <IconButton
                    className="edit"
                    onClick={() => handleEditAccountChart(item)}
                  >
                    <MdModeEdit size={18} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="col-sm-1 action-button">
                <Tooltip title={gettext("Delete")} arrow>
                  <IconButton
                    className="delete"
                    onClick={() => handleDeleteAccountChart(item)}
                  >
                    <MdDelete size={18} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ))}
        </TableList>
      )}
    </ChartList>
  );
};

export default AccountChartList;
