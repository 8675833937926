import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useMutation, useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GET_EMISSION_FACTOR_LIST } from "../../../../GraphQL/Queries";
import { DELETE_EMISSION_FACTOR } from "../../../../GraphQL/Mutations";
import { ChartList, TableList } from "./emission-factors-style";
import EmissionDatePicker from "./EmissionDatePicker";
import { IconButton, Tooltip } from "@mui/material";
import { MdModeEdit, MdDelete } from "react-icons/md";

const EmissionFactorsList = () => {
  const navigate = useNavigate();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const localFilters = JSON.parse(localStorage.getItem("emissionFilters"));
  const value =
    localFilters && localFilters.year
      ? new Date(localFilters.year)
      : new Date();
  const [date, setDate] = useState(value);
  const [emissionFactorData, setEmissionFactorData] = useState([]);
  // const [deletedId, setDeletedId] = useState("");
  const [
    fetchEmissionFactors,
    { data, loading, refetch: refetchEmissionFactors }
  ] = useLazyQuery(GET_EMISSION_FACTOR_LIST, {
    variables: {
      year: format(date, "yyyy")
    }
  });

  useEffect(() => {
    if (data) {
      const { emissionFactors } = data;
      if (emissionFactors) {
        setEmissionFactorData(emissionFactors);
      } else {
        setEmissionFactorData([]);
      }
    }
  }, [data]);

  useEffect(() => {
    fetchEmissionFactors();
    refetchEmissionFactors();
  }, [date, fetchEmissionFactors, refetchEmissionFactors]);

  const handleNewAccountChart = () => {
    navigate("/admin/emission-factors/new");
  };

  const handleEditAccountChart = item => {
    navigate(`/admin/emission-factors/${item.Id}/edit`);
  };

  const [deleteEmissionFactor] = useMutation(DELETE_EMISSION_FACTOR, {
    onCompleted(data) {
      // setDeletedId("");
    },
    onError(error) {
      // setDeletedId("");
      // error.graphQLErrors.map(({ message }) => {
      //   // console.log("Error =>", error);
      // });
    }
  });

  const handleDeleteAccountChartMutation = item => {
    deleteEmissionFactor({
      variables: {
        id: item.Id,
        year: format(date, "yyyy")
      },
      refetchQueries: [
        {
          query: GET_EMISSION_FACTOR_LIST,
          variables: {
            year: format(date, "yyyy")
          }
        }
      ]
    });
  };

  const handleDeleteAccountChart = item => {
    confirmAlert({
      title: gettext("Confirmation"),
      message: `${gettext("Are you sure you want to Delete")}${" " + item.key}`,
      buttons: [
        {
          label: gettext("Yes"),
          onClick: () => {
            // setDeletedId(item.Id);
            handleDeleteAccountChartMutation(item);
          }
        },
        {
          label: gettext("No"),
          onClick: () => {
            // setDeletedId("");
          }
        }
      ]
    });
  };

  useEffect(() => {
    localStorage.setItem(
      "emissionFilters",
      JSON.stringify({
        year: date
      })
    );
  }, [date]);

  return (
    <ChartList className="card card-custom gutter-b example example-compact">
      <div className="row m-0">
        <div className="col-sm-8 pt20 pb8 title">
          {gettext("List of Emission Factor")}
        </div>
        <div className={"col-sm-2 pt20 pb8"}>
          <EmissionDatePicker
            filterType="emissionFilters"
            selectedSegment={2}
            getSelectedDate={date => {
              setDate(date);
            }}
          />
        </div>
        <div className="col-sm-2 pt20 pb8 new-account-chart">
          <button type="button" onClick={handleNewAccountChart}>
            {gettext("Add Emission Factor")}
          </button>
        </div>
      </div>
      {/* <hr /> */}
      {loading ? (
        <div className="row m-0">
          <div className="col-sm-12 list-load">
            <Spinner
              // className="btn-spinner-delete"
              animation="border"
            />
          </div>
        </div>
      ) : (
        <TableList>
          <div className="row m-0 table-heading">
            <div className="col-sm-1">{gettext("Id")}</div>
            <div className="col-sm-2">{gettext("Key")}</div>
            <div className="col-sm-2">{gettext("Group")}</div>
            <div className="col-sm-2">{gettext("Unit")}</div>
            <div className="col-sm-2">{gettext("Source")}</div>
            <div className="col-sm-1">{gettext("Year")}</div>
            <div className="col-sm-2 action-button">{gettext("Action")}</div>
          </div>
          {emissionFactorData.map((item, index) => (
            <div className="row m-0 top-border" key={`corporate_${index}`}>
              <div className="col-sm-1">{index + 1}</div>
              <div className="col-sm-2">{item.key}</div>
              <div className="col-sm-2">{item.group}</div>
              <div className="col-sm-2">{item.unit}</div>
              <div className="col-sm-2">
                {item.data ? item.data[0].source : ""}
              </div>
              <div className="col-sm-1">
                {item.data ? item.data[0].year : ""}
              </div>
              <div className="col-sm-1 action-button">
                <Tooltip title={gettext("Edit")} arrow>
                  <IconButton
                    className="edit"
                    onClick={() => handleEditAccountChart(item)}
                  >
                    <MdModeEdit size={18} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="col-sm-1 action-button">
                <Tooltip title={gettext("Delete")} arrow>
                  <IconButton
                    className="delete"
                    onClick={() => handleDeleteAccountChart(item)}
                  >
                    <MdDelete size={18} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ))}
        </TableList>
      )}
    </ChartList>
  );
};

export default EmissionFactorsList;
