import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GoBack } from "../../components";
import { BuildingListTable } from "../../features";

export function CustomerBuildings() {
  const { cid } = useParams();

  const customer = decodeURIComponent(cid);

  const location = useLocation();

  const [writeAccess, setWriteAccess] = useState(false);

  useEffect(() => {
    setWriteAccess(location.pathname.split("/")[1] !== "partner");
  }, [location]);

  return (
    <>
      <GoBack />

      <BuildingListTable customer={customer} writeAccess={writeAccess} />
    </>
  );
}
