import { useQuery } from "@apollo/client";
import { useUser } from "app/providers";
import { GET_BUILDING_COORDINATES } from "../commands";

export function useCoordinates(onCompleted = () => {}, onError = () => {}) {
  const { user } = useUser();

  const { currentTeam } = user || {};

  const { data, loading } = useQuery(GET_BUILDING_COORDINATES, {
    onCompleted: ({ comGetBuildingDetails }) => {
      onCompleted(comGetBuildingDetails || []);
    },
    onError,
    variables: {
      customerName: currentTeam
    }
  });

  return {
    coordiantesList: data?.comGetBuildingDetails || [],
    loading
  };
}
