import React from "react";
// import { Table } from "react-bootstrap";
import "../../index.scss";
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Typography,
  Box,
  Tooltip
} from "@mui/material";
import { capitalizeFirstLetter } from "hooks/useCommon";

const EnergyPricesBuildingCustomTable = props => {
  const { energyType, fileSelectionComponent, tableData, gettext } = props;

  return (
    <TableContainer
      sx={{
        borderRadius: "12px",
        padding: "24px",
        border: "1px solid #E4E4E7"
      }}
    >
      <Box sx={{ padding: "15px 0 25px 0" }}>
        <Typography
          variant="h6"
          fontFamily="Aeonik"
          sx={{ fontWeight: "550", fontSize: "20px", color: "black" }}
        >
          {gettext(capitalizeFirstLetter(energyType)) +
            " " +
            gettext("prices for consumption") +
            " " +
            "DKK/kWh"}
        </Typography>
        {fileSelectionComponent}
      </Box>
      <Table sx={{ backgroundColor: "white" }}>
        <TableHead sx={{ backgroundColor: "white", border: "none" }}>
          <TableRow sx={{ borderBottom: "1px solid #E4E4E7" }}>
            <TableCell
              sx={{ backgroundColor: "white", border: "none", fontWeight: 500 }}
              colSpan={2}
            >
              {gettext("Hours")}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "white",
                border: "none",
                fontWeight: 500,
                textAlign: "center"
              }}
              colSpan={2}
            >
              {gettext("Consumption(kWh)")}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "white",
                border: "none",
                fontWeight: 500,
                textAlign: "center"
              }}
              colSpan={2}
            >
              <Tooltip title="Spot price are Nordpool prices" placement="right">
                <span>{gettext("Spot price*")}</span>
              </Tooltip>
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "white",
                border: "none",
                fontWeight: 500,
                textAlign: "center"
              }}
              colSpan={2}
            >
              {gettext("Tarif/Tax")}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "white",
                border: "none",
                fontWeight: 500,
                textAlign: "center"
              }}
              colSpan={2}
            >
              {gettext("Service provider fee")}
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "white",
                border: "none",
                fontWeight: 500,
                textAlign: "center"
              }}
              colSpan={2}
            >
              {gettext("Total Price (incl. Tarif/Tax)")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow
              index={index}
              sx={{
                "&:nth-child(even)": { backgroundColor: "#FAFAFA" },
                "&:hover": { backgroundColor: "#FAFAFA" }
              }}
            >
              <TableCell
                colSpan={2}
                sx={{ border: "none", fontWeight: 500, fontSize: "14px" }}
              >
                {row.ts}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{ border: "none", color: "#71717A", textAlign: "center" }}
              >
                {row.consumption.toFixed(2)}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{ border: "none", color: "#71717A", textAlign: "center" }}
              >
                {row.price.toFixed(2)}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{ border: "none", color: "#71717A", textAlign: "center" }}
              >
                {row.taxes.toFixed(2)}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{ border: "none", color: "#71717A", textAlign: "center" }}
              >
                {row.serviceProviderFee.toFixed(2)}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{ border: "none", color: "#71717A", textAlign: "center" }}
              >
                {row.totalPrice.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EnergyPricesBuildingCustomTable;
