import React from "react";
import GEnergyTypes from "./GEnergyTypes";

const GWater = () => {
  return (
    <>
      <GEnergyTypes type="water" sourceType="Water" />
    </>
  );
};
export default GWater;
