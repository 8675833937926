import { capitalizeFirstLetter } from "hooks/useCommon";
import Moment from "moment";
// import { ELECTRICITY_FORMULA } from "./Constants";

export const transformResponse = (
  res,
  predRes,
  type,
  segment_type,
  // unit,
  pageType,
  fromDate,
  toDate,
  gettext,
  page
) => {
  let segment =
    segment_type === 0 ? "day" : segment_type === 1 ? "month" : "year";
  const resType = segment_type === 0 ? "h" : segment_type === 1 ? "d" : "m";
  const data = res?.data ? res?.data : [];
  let result;
  let fromDateObj = {};
  let toDateObj = {};
  let allChartData = {};
  let allTableData = {};

  if (data && data.length > 0) {
    allChartData = processResults2(
      res,
      predRes,
      type,
      segment,
      fromDate,
      toDate,
      // unit,
      // res.data?.electricity_green
      res?.electricityGreen,
      page
    );
    allTableData = allTablesData(
      page ? predRes : res,
      type,
      segment,
      fromDateObj,
      toDateObj,
      pageType,
      resType,
      res?.electricityGreen,
      gettext,
      page
    );
  }

  result = {
    status: "success",
    allTableData: allTableData,
    allChartData: allChartData
  };
  return result;
};

let processResults2 = (
  res,
  predRes,
  type,
  segment,
  fromDateObj,
  toDateObj,
  // unit,
  electricityGreen,
  page
) => {
  let graphsData = {};
  let charts = ["consumption", "compensation"];

  let predictionComparisionChart = ["consumption"];

  if (type === "electricity" || type === "energy") {
    charts.splice(1, 0, "production");
  }
  graphsData[segment] = {};

  let orignalKWHData;
  let predictedKWHData;
  let comparisonKWHData = {};
  orignalKWHData = processResults1(
    res,

    type,
    segment,
    fromDateObj,
    toDateObj,
    // unit,
    electricityGreen,
    predictionComparisionChart,
    "V"
  );

  if (page) {
    predictedKWHData = processResults1(
      predRes,
      type,
      segment,
      fromDateObj,
      toDateObj,
      // unit,
      electricityGreen,
      predictionComparisionChart,
      "V"
    );

    comparisonKWHData["predictedKWHData"] = predictedKWHData.consumption;
    comparisonKWHData["orignalKWHData"] = orignalKWHData.consumption;
    graphsData[segment]["comparisonKWHData"] = comparisonKWHData;
  }

  let chartData1 = processResults1(
    page ? predRes : res,
    type,
    segment,
    fromDateObj,
    toDateObj,
    // unit,
    electricityGreen,
    charts,
    "V"
  );

  let chartData3 = processResults1(
    page ? predRes : res,
    type,
    segment,
    fromDateObj,
    toDateObj,
    // unit,
    electricityGreen,
    charts,
    "Co2"
  );

  graphsData[segment]["chartData1"] = chartData1;

  graphsData[segment]["chartData3"] = chartData3;
  if (type === "energy" || type === "electricity") {
    let price = processResults1(
      page ? predRes : res,
      type,
      segment,
      fromDateObj,
      toDateObj,
      // unit,
      electricityGreen,
      charts,
      "Dkk"
    );

    let Cost = processResults1(
      page ? predRes : res,
      type,
      segment,
      fromDateObj,
      toDateObj,
      // unit,
      electricityGreen,
      charts,
      "CostDkk"
    );
    let conversionRate = processResults1(
      page ? predRes : res,
      type,
      segment,
      fromDateObj,
      toDateObj,
      // unit,
      electricityGreen,
      charts,
      "ConversionRate"
    );

    graphsData[segment]["conervsionRate"] = conversionRate;
    graphsData[segment]["price"] = price;
    graphsData[segment]["Cost"] = Cost;
  }

  return graphsData;
};

// let getPriceorConversionRate = (chartData1, chartData2) => {
//     let chartData = {};
//     Object.keys(chartData1).forEach((title, titleIndex) => {
//         let data = {};
//         Object.keys(chartData1[title]).forEach((date, dateIndex) => {
//             if (chartData2[title][date] || chartData1[title][date]) {
//                 data[date] = chartData2[title][date] * chartData1[title][date];
//             } else {
//                 data[date] = 0;
//             }
//         });
//         chartData[title] = data;
//     });
//     return chartData;
// };

let processResults1 = (
  res,
  type,
  segment,
  fromDateObj,
  toDateObj,
  // unit,
  electricityGreen,
  chartTypes,
  fieldName
) => {
  let consumptionArr = {};
  let chartData = {};
  // const { data } = res;

  chartTypes?.forEach((chartType, index) => {
    consumptionArr = {};
    res?.data.forEach((item, index) => {
      let dateKey = "";
      if (segment === "day") {
        dateKey = item.ts.split("T");
        let timeKey = dateKey[1].split(":");
        dateKey = `${item.ts.split("T")[0]} ${timeKey[0]}:${timeKey[1]}`;
      } else {
        dateKey = item.ts.split("T")[0];
      }
      //put 0 value first
      if (!consumptionArr[dateKey]) {
        consumptionArr[dateKey] = 0;
      }

      //get specific index value
      if (
        chartType === "consumption" &&
        electricityGreen &&
        fieldName !== "Co2"
      ) {
        consumptionArr[dateKey] += 0;
      } else if (chartType === "compensation" && electricityGreen) {
        if (fieldName === "Co2") {
          consumptionArr[dateKey] += 0;
        } else
          consumptionArr[dateKey] += item[`consumption${fieldName}`]
            ? item[`consumption${fieldName}`]
            : 0;
      } else if (chartType === "compensation" && !electricityGreen) {
        consumptionArr[dateKey] += 0;
      } else {
        consumptionArr[dateKey] += item[`${chartType}${fieldName}`]
          ? item[`${chartType}${fieldName}`]
          : 0;
      }
    });
    chartData[chartType] = consumptionArr;
  });

  return chartData;
};

let allTablesData = (
  res,
  type,
  segment,
  fromDateObj,
  toDateObj,
  pageType,
  resType,
  electricityGreen,
  gettext,
  page
) => {
  // let allData = {};
  let allTables = {};
  let contentList = [];
  let tables = ["consumption", "compensation"];
  let heading;

  if (type === "electricity" || type === "energy") {
    tables.splice(1, 0, "production");
  }

  tables.forEach((tableType, index) => {
    heading = tableDataHeading1(segment, type, gettext, tableType, page);
    contentList = dailyAllTablesData1(
      res,
      type,
      segment,
      pageType,
      resType,
      electricityGreen,
      gettext,
      tableType,
      page
    );

    allTables[tableType] = {
      title: type.charAt(0).toUpperCase() + type.slice(1),
      heading: heading,
      content: contentList
    };
  });

  return allTables;
};

let dailyAllTablesData1 = (
  res,
  type,
  segment,
  pageType,
  resType,
  electricityGreen,
  gettext,
  chartType,
  page
) => {
  let chartV = {};
  let cost = {};
  let price = {};
  let conversionRate = {};
  let chartCO2 = {};
  let contentData = {};
  let contentList = [];
  let totalCon = 0;
  let totalCost = 0;
  // let totalCo2 = 0;
  let totalPrice = 0;
  let totalConversionRate = 0;

  const data = res?.data ? res?.data : [];
  data.forEach(item => {
    // let tsDayName = "";
    let dateKey = "";
    if (segment === "day") {
      dateKey = `${item.ts.split("T")[0]} ${item.ts.split("T")[1]}`;
    } else {
      dateKey = item.ts.split("T")[0];
    }
    let tsDayDate = "";
    let res = getDayAndMonthName(dateKey, segment, gettext);
    // tsDayName = res[0];

    tsDayDate = res[1];

    if (!chartV[tsDayDate]) {
      chartV[tsDayDate] = 0;
    }

    if (!chartCO2[tsDayDate]) {
      chartCO2[tsDayDate] = 0;
    }

    if (!cost[tsDayDate]) {
      cost[tsDayDate] = 0;
    }

    if (!price[tsDayDate]) {
      price[tsDayDate] = 0;
    }

    if (!conversionRate[tsDayDate]) {
      conversionRate[tsDayDate] = 0;
    }

    if (chartType !== "compensation") {
      chartV[tsDayDate] += item[`${chartType}V`];
      price[tsDayDate] += item[`${chartType}Dkk`];

      cost[tsDayDate] += item[`${chartType}CostDkk`];
      conversionRate[tsDayDate] += item[`${chartType}ConversionRate`];

      switch (type) {
        case "electricity":
          // chartCO2[tsDayDate] += item.consumptionCo2;
          chartCO2[tsDayDate] += item[`${chartType}Co2`]
            ? item[`${chartType}Co2`]
            : 0;
          break;

        default:
          chartCO2[tsDayDate] += item[`${chartType}Co2`]
            ? item[`${chartType}Co2`]
            : 0;
      }
    }

    if (chartType === "consumption" && electricityGreen) {
      chartV[tsDayDate] = 0;
    } else if (chartType === "compensation" && electricityGreen) {
      chartV[tsDayDate] = item[`consumptionV`]
        ? parseFloat(item[`consumptionV`]).toFixed(2)
        : 0;
      chartCO2[tsDayDate] += 0;
    }
    contentData[tsDayDate] = res; //[tsDayName, tsDayDate];

    return false;
  });

  Object.keys(contentData).forEach((key, index) => {
    let item = contentData[key];

    let tempList = [
      chartV[key] ? parseFloat(chartV[key]).toFixed(2) : 0 // Consumption
      // conversionRate[key].toFixed(2), //conversion rate
      // chartCO2[key] ? parseFloat(chartCO2[key]).toFixed(2) : 0 //Tons CO2
      // price[key].toFixed(2), //price
      // cost[key].toFixed(2), //cost
    ];

    if (segment === "year") {
      tempList.splice(0, 0, item[2]);
    } else {
      tempList.splice(0, 0, item[0]);
      tempList.splice(1, 0, item[1]);
    }

    totalCon += chartV[key];
    // totalCo2 += chartCO2[key];

    if (type === "energy" || type === "electricity") {
      if (page) {
        if (segment === "year") {
          tempList.splice(2, 0, parseFloat(conversionRate[key]).toFixed(2));
          // tempList.splice(4, 0, parseFloat(price[key]).toFixed(2));
          // tempList.splice(5, 0, parseFloat(cost[key]).toFixed(2));
        } else {
          tempList.splice(3, 0, parseFloat(conversionRate[key]).toFixed(2));
          // tempList.splice(5, 0, parseFloat(price[key]).toFixed(2));
          // tempList.splice(6, 0, parseFloat(cost[key]).toFixed(2));
        }
      } else {
        if (segment === "year") {
          // tempList.splice(2, 0, parseFloat(conversionRate[key]).toFixed(2));
          tempList.splice(4, 0, parseFloat(price[key]).toFixed(2));
          tempList.splice(5, 0, parseFloat(cost[key]).toFixed(2));
        } else {
          // tempList.splice(3, 0, parseFloat(conversionRate[key]).toFixed(2));
          tempList.splice(5, 0, parseFloat(price[key]).toFixed(2));
          tempList.splice(6, 0, parseFloat(cost[key]).toFixed(2));
        }
      }

      totalConversionRate += conversionRate[key];

      totalPrice += price[key];
      totalCost = totalCost + cost[key];
    }
    contentList.push(tempList);
  });

  let list = [
    gettext("Total"),
    totalCon ? parseFloat(totalCon).toFixed(2) : 0
    // totalConversionRate.toFixed(2),
    // totalCo2 ? parseFloat(totalCo2).toFixed(2) : 0
    // totalPrice.toFixed(2),
    // totalCost.toFixed(2),
  ];
  if (segment !== "year") {
    list.splice(1, 0, "");
  }
  if (type === "energy" || type === "electricity") {
    if (page) {
      if (segment === "year") {
        list.splice(2, 0, parseFloat(totalConversionRate).toFixed(2));
        // list.splice(4, 0, parseFloat(totalPrice).toFixed(2));
        // list.splice(5, 0, parseFloat(totalCost).toFixed(2));
      } else {
        list.splice(3, 0, parseFloat(totalConversionRate).toFixed(2));
        // list.splice(5, 0, parseFloat(totalPrice).toFixed(2));
        // list.splice(6, 0, parseFloat(totalCost).toFixed(2));
      }
    } else {
      if (segment === "year") {
        // list.splice(2, 0, parseFloat(totalConversionRate).toFixed(2));
        list.splice(4, 0, parseFloat(totalPrice).toFixed(2));
        list.splice(5, 0, parseFloat(totalCost).toFixed(2));
      } else {
        // list.splice(3, 0, parseFloat(totalConversionRate).toFixed(2));
        list.splice(5, 0, parseFloat(totalPrice).toFixed(2));
        list.splice(6, 0, parseFloat(totalCost).toFixed(2));
      }
    }
  }

  contentList.push(list);

  return contentList;
};

//     res,
//     type,
//     segment,
//     pageType,
//     resType,
//     electricityGreen,
//     gettext
// ) => {
//     //cost
//     let prodCost = {};
//     let consumptionCost = {};
//     let compensationCost = {};

//     let chartV = {};
//     let chartCO2 = {};
//     let productionChartV = {};
//     let productionChartCO2 = {};
//     let greenElectricity = {};
//     let greenCO2 = {};
//     let contentData = {};
//     let contentList = [];
//     let totalCon = 0;
//     let totalCo2 = 0;
//     let totalProduction = 0;
//     let totalProductionCo2 = 0;
//     let totalGreenCo2 = 0;
//     let totalGreenElectricity = 0;
//     //cost
//     let totalConsumptionCost = 0;
//     let totalprodCost = 0;
//     let totalCompensationCost = 0;
//     const { data } = res;
//     data.forEach((item) => {
//         // let tsDayName = "";
//         let dateKey = "";
//         if (segment === "day") {
//             dateKey = `${item.ts.split("T")[0]} ${item.ts.split("T")[1]}`;
//         } else {
//             dateKey = item.ts.split("T")[0];
//         }
//         let tsDayDate = "";
//         let res = getDayAndMonthName(dateKey, segment, gettext);
//         // tsDayName = res[0];
//         tsDayDate = res[1];

//         //cost
//         if (!compensationCost[tsDayDate]) {
//             compensationCost[tsDayDate] = 0;
//         }

//         if (!prodCost[tsDayDate]) {
//             prodCost[tsDayDate] = 0;
//         }

//         if (!consumptionCost[tsDayDate]) {
//             consumptionCost[tsDayDate] = 0;
//         }

//         if (!chartV[tsDayDate]) {
//             chartV[tsDayDate] = 0;
//         }
//         if (!greenElectricity[tsDayDate]) {
//             greenElectricity[tsDayDate] = 0;
//         }
//         if (!chartCO2[tsDayDate]) {
//             chartCO2[tsDayDate] = 0;
//         }
//         if (!productionChartV[tsDayDate]) {
//             productionChartV[tsDayDate] = 0;
//         }
//         if (!productionChartCO2[tsDayDate]) {
//             productionChartCO2[tsDayDate] = 0;
//         }
//         if (!greenCO2[tsDayDate]) {
//             greenCO2[tsDayDate] = 0;
//         }

//         //cost
//         consumptionCost[tsDayDate] += item.productionV;
//         prodCost[tsDayDate] += item.productionV;
//         compensationCost[tsDayDate] += item.productionV;

//         productionChartV[tsDayDate] += item.productionV;
//         switch (type) {
//             case "electricity":
//                 productionChartCO2[tsDayDate] += item.productionCo2;
//                 break;

//             default:
//                 productionChartCO2[tsDayDate] += item.productionCo2;
//         }
//         chartV[tsDayDate] += item.consumptionV;
//         switch (type) {
//             case "electricity":
//                 chartCO2[tsDayDate] += item.consumptionCo2;
//                 break;

//             default:
//                 chartCO2[tsDayDate] += item.consumptionCo2;
//         }

//         if (electricityGreen) {
//             chartV[tsDayDate] = 0;
//             greenElectricity[tsDayDate] += item.consumptionV;
//             greenCO2[tsDayDate] = 0;
//         }

//         contentData[tsDayDate] = res; //[tsDayName, tsDayDate];

//         return false;
//     });
//     Object.keys(contentData).forEach((key, index) => {
//         let item = contentData[key];
//         let tempList = [];
//         if (segment === "year") {
//             if (type === "electricity" || type === "energy") {
//                 tempList = [
//                     item[2],
//                     chartV[key].toFixed(2), // Consumption
//                     chartCO2[key].toFixed(2), //Tons CO2
//                     productionChartV[key].toFixed(2), //production
//                     productionChartCO2[key].toFixed(2), // production CO2
//                     greenElectricity[key].toFixed(2), //Green Electricity
//                     greenCO2[key].toFixed(2),
//                     consumptionCost[key].toFixed(2),
//                     prodCost[key].toFixed(2),
//                     compensationCost[key].toFixed(2),
//                 ];
//             } else {
//                 tempList = [
//                     item[2],
//                     chartV[key].toFixed(2),
//                     chartCO2[key].toFixed(2),
//                     // productionChartV[key].toFixed(2),
//                     // productionChartCO2[key].toFixed(2),
//                     greenElectricity[key].toFixed(2), //Green Electricity
//                     greenCO2[key].toFixed(2),
//                     consumptionCost[key].toFixed(2),
//                     prodCost[key].toFixed(2),
//                     compensationCost[key].toFixed(2),
//                 ];
//             }
//         } else {
//             if (type === "electricity" || type === "energy") {
//                 tempList = [
//                     item[0],
//                     item[1],
//                     chartV[key].toFixed(2), // Consumption
//                     chartCO2[key].toFixed(2), //Tons CO2
//                     productionChartV[key].toFixed(2), //production
//                     productionChartCO2[key].toFixed(2), // production CO2
//                     greenElectricity[key].toFixed(2), //Green Electricity
//                     greenCO2[key].toFixed(2),
//                     consumptionCost[key].toFixed(2),
//                     prodCost[key].toFixed(2),
//                     compensationCost[key].toFixed(2),
//                 ];
//             } else {
//                 tempList = [
//                     item[0],
//                     item[1],
//                     chartV[key].toFixed(2),
//                     chartCO2[key].toFixed(2),
//                     // productionChartV[key].toFixed(2),
//                     // productionChartCO2[key].toFixed(2),
//                     greenElectricity[key].toFixed(2), //Green Electricity
//                     greenCO2[key].toFixed(2),
//                     consumptionCost[key].toFixed(2),
//                     prodCost[key].toFixed(2),
//                     compensationCost[key].toFixed(2),
//                 ];
//             }
//         }
//         totalCon += chartV[key];
//         totalGreenElectricity += greenElectricity[key];
//         totalCo2 += chartCO2[key];
//         totalProduction += productionChartV[key];
//         totalProductionCo2 += productionChartCO2[key];
//         totalGreenCo2 += greenCO2[key];

//         //cost
//         totalConsumptionCost += consumptionCost[key];
//         totalprodCost += prodCost[key];
//         totalCompensationCost += compensationCost[key];

//         contentList.push(tempList);
//     });

//     if (type === "electricity" || type === "energy") {
//         if (segment === "year") {
//             contentList.push([
//                 gettext("Total"),
//                 totalCon.toFixed(2),
//                 totalCo2.toFixed(2),
//                 totalProduction.toFixed(2),
//                 totalProductionCo2.toFixed(2),
//                 totalGreenElectricity.toFixed(2),
//                 totalGreenCo2.toFixed(2),
//                 //cost
//                 totalConsumptionCost.toFixed(2),
//                 totalprodCost.toFixed(2),
//                 totalCompensationCost.toFixed(2),
//             ]);
//         } else {
//             contentList.push([
//                 gettext("Total"),
//                 "",
//                 totalCon.toFixed(2),
//                 totalCo2.toFixed(2),
//                 totalProduction.toFixed(2),
//                 totalProductionCo2.toFixed(2),
//                 totalGreenElectricity.toFixed(2),
//                 totalGreenCo2.toFixed(2),
//                 //cost
//                 totalConsumptionCost.toFixed(2),
//                 totalprodCost.toFixed(2),
//                 totalCompensationCost.toFixed(2),
//             ]);
//         }
//     } else {
//         if (segment === "year") {
//             contentList.push([
//                 gettext("Total"),
//                 totalCon.toFixed(2),
//                 totalCo2.toFixed(2),
//                 // totalProduction.toFixed(2),
//                 // totalProductionCo2.toFixed(2),
//                 totalGreenElectricity.toFixed(2),
//                 totalGreenCo2.toFixed(2),
//                 //cost
//                 totalConsumptionCost.toFixed(2),
//                 totalprodCost.toFixed(2),
//                 totalCompensationCost.toFixed(2),
//             ]);
//         } else {
//             contentList.push([
//                 gettext("Total"),
//                 "",
//                 totalCon.toFixed(2),
//                 totalCo2.toFixed(2),
//                 // totalProduction.toFixed(2),
//                 // totalProductionCo2.toFixed(2),
//                 totalGreenElectricity.toFixed(2),
//                 totalGreenCo2.toFixed(2),
//                 //cost
//                 totalConsumptionCost.toFixed(2),
//                 totalprodCost.toFixed(2),
//                 totalCompensationCost.toFixed(2),
//             ]);
//         }
//     }

//     return contentList;
// };

let getDayAndMonthName = (ts, segment, gettext) => {
  let tsDayName = "";
  let tsDayDate = "";
  let tsDayYear = "";
  if (segment === "year") {
    let date = new Date(ts);
    date = Moment(date);
    tsDayName = getMonthNames(date.format("MMM"), gettext);
    tsDayDate = date.format("MM-YYYY");
    tsDayYear = date.format("MMM YYYY");
    tsDayYear =
      getMonthNames(tsDayYear.split(" ")[0], gettext) +
      " " +
      tsDayYear.split(" ")[1];
  } else if (segment === "month") {
    let date = new Date(ts);
    date = Moment(date);
    tsDayName = getWeekNames(date.format("dddd"), gettext);
    tsDayDate = date.format("DD-MM-YYYY");
  } else {
    // let dateKey = ts.split(" ");
    // let timeKey = dateKey[1].split(":");
    // let date = getDateObject(`${dateKey[0]} ${timeKey[0]}:${timeKey[1]}`);
    // tsDayName = getdaysNames(date.format("ddd"), gettext);
    // tsDayDate = date.format("HH:mm");

    let date = new Date(ts);
    date = Moment(date);
    tsDayName = getWeekNames(date.format("dddd"), gettext);
    tsDayDate = date.format("DD-MM-YYYY HH:mm");
  }
  return [tsDayName, tsDayDate, tsDayYear];
};

// let getdaysNames = (name, gettext) => {
//   if (name === "Mon") {
//     return gettext("Mon");
//   } else if (name === "Tue") {
//     return gettext("Tue");
//   } else if (name === "Wed") {
//     return gettext("Wed");
//   } else if (name === "Thu") {
//     return gettext("Thu");
//   } else if (name === "Fri") {
//     return gettext("Fri");
//   } else if (name === "Sat") {
//     return gettext("Sat");
//   } else if (name === "Sun") {
//     return gettext("Sun");
//   }
//   return "";
// };

let getWeekNames = (name, gettext) => {
  if (name === "Monday") {
    return gettext("Monday");
  } else if (name === "Tuesday") {
    return gettext("Tuesday");
  } else if (name === "Wednesday") {
    return gettext("Wednesday");
  } else if (name === "Thursday") {
    return gettext("Thursday");
  } else if (name === "Friday") {
    return gettext("Friday");
  } else if (name === "Saturday") {
    return gettext("Saturday");
  } else if (name === "Sunday") {
    return gettext("Sunday");
  }
  return "";
};

let getMonthNames = (name, gettext) => {
  if (name === "Jan") {
    return gettext("Jan");
  } else if (name === "Feb") {
    return gettext("Feb");
  } else if (name === "Mar") {
    return gettext("Mar");
  } else if (name === "Apr") {
    return gettext("Apr");
  } else if (name === "May") {
    return gettext("May");
  } else if (name === "Jun") {
    return gettext("Jun");
  } else if (name === "Jul") {
    return gettext("Jul");
  } else if (name === "Aug") {
    return gettext("Aug");
  } else if (name === "Sep") {
    return gettext("Sep");
  } else if (name === "Oct") {
    return gettext("Oct");
  } else if (name === "Nov") {
    return gettext("Nov");
  } else if (name === "Dec") {
    return gettext("Dec");
  }
  return "";
};

export const getTableType = (tableType, gettext) => {
  if (tableType === "consumption") {
    return gettext("Consumption");
  } else if (tableType === "production") {
    return gettext("Production");
  } else if (tableType === "compensation") {
    return gettext("Compensation");
  }
};

let tableDataHeading1 = (segment, type, gettext, chartType, page) => {
  let param1,
    param2 = null;
  if (segment === "day") {
    param1 = gettext("Name");
    param2 = gettext("Time");
  } else if (segment === "month") {
    param1 = gettext("Name");
    param2 = gettext("Date");
  } else {
    param1 = gettext("Name");
    param2 = gettext("Month");
  }
  if (
    type === "electricity" ||
    type === "heating" ||
    type === "water" ||
    type === "gas" ||
    type === "energy"
  ) {
    let headings = [
      param2,
      gettext(
        `${capitalizeFirstLetter(
          chartType === "gas" ? "Natural Gas" : chartType
        )} (kWh)`
      )
      // gettext(
      //     `${capitalizeFirstLetter(
      //         chartType
      //     )} Conversion Rate (Co2/kWh)`
      // ),

      // gettext(`CO₂ (Tons)`)

      // gettext(`${capitalizeFirstLetter(chartType)} Price (Dkk/kWh)`),
      // gettext(`${capitalizeFirstLetter(chartType)} Cost (Dkk)`),
    ];

    if (segment !== "year") {
      headings.splice(0, 0, param1);
    }

    if (type === "energy" || type === "electricity") {
      if (page) {
        if (segment === "year") {
          // headings.splice(
          //   2,
          //   0,
          //
          //   gettext("Conversion Rate (g CO₂/kWh)")
          // );
          // headings.splice(4, 0, gettext("Price (Dkk/kWh)"));
          // headings.splice(
          //   5,
          //   0,
          //   // getTableType(chartType, gettext) + " " + gettext("Cost (Dkk)")
          //   gettext("Cost (Dkk)")
          // );
        } else {
          // headings.splice(
          //   3,
          //   0,
          //
          //   gettext("Conversion Rate (g CO₂/kWh)")
          // );
          // headings.splice(5, 0, gettext("Price (Dkk/kWh)"));
          // headings.splice(6, 0, gettext("Cost (Dkk)"));
        }
      } else {
        if (segment === "year") {
          // headings.splice(
          //   2,
          //   0,
          //
          //   gettext("Conversion Rate (g CO₂/kWh)")
          // );
          headings.splice(4, 0, gettext("Price (Dkk/kWh)"));
          headings.splice(
            5,
            0,
            // getTableType(chartType, gettext) + " " + gettext("Cost (Dkk)")
            gettext("Cost (Dkk)")
          );
        } else {
          // headings.splice(
          //   3,
          //   0,
          //
          //   gettext("Conversion Rate (g CO₂/kWh)")
          // );
          headings.splice(5, 0, gettext("Price (Dkk/kWh)"));
          headings.splice(6, 0, gettext("Cost (Dkk)"));
        }
      }
    }

    return headings;
  }
  return [
    param1,
    param2,
    gettext("Consumption (kWh)"),
    gettext("Consumption CO₂ (Tons)")
  ];
};

// let tableDataHeading = (segment, type, gettext) => {
//     let param1,
//         param2 = null;
//     if (segment === "day") {
//         param1 = gettext("Day name");
//         param2 = gettext("Hour");
//     } else if (segment === "month") {
//         param1 = gettext("Day name");
//         param2 = gettext("Date");
//     } else {
//         param1 = gettext("Day name");
//         param2 = gettext("Month");
//     }
//     if (
//         type === "electricity" ||
//         type === "heating" ||
//         type === "water" ||
//         type === "gas" ||
//         type === "energy"
//     ) {
//         if (type === "electricity" || type === "energy") {
//             if (segment === "year") {
//                 return [
//                     param2,
//                     gettext("Consumption (kWh)"),
//                     gettext("Consumption CO₂ (Tons)"),
//                     gettext("Production (kWh)"),
//                     gettext("Production CO₂ (Tons)"),
//                     gettext("Compensation (kWh)"),
//                     gettext("Compensation CO₂ (Tons)"),

//                     //cost
//                     gettext("Production Cost (Tons)"),
//                     gettext("Consumption Cost (Tons)"),
//                     gettext("Compensation Cost (Tons)"),
//                 ];
//             } else {
//                 return [
//                     param1,
//                     param2,
//                     gettext("Consumption (kWh)"),
//                     gettext("Consumption CO₂ (Tons)"),
//                     gettext("Production (kWh)"),
//                     gettext("Production CO₂ (Tons)"),
//                     gettext("Compensation (kWh)"),
//                     gettext("Compensation CO₂ (Tons)"),

//                     //cost
//                     gettext("Production Cost (Tons)"),
//                     gettext("Consumption Cost (Tons)"),
//                     gettext("Compensation Cost (Tons)"),
//                 ];
//             }
//         } else {
//             if (segment === "year") {
//                 return [
//                     param2,
//                     gettext("Consumption (kWh)"),
//                     gettext("Consumption CO₂ (Tons)"),
//                     // gettext("Production (kWh)"),
//                     // gettext("Production CO₂ (Tons)"),
//                     gettext("Compensation (kWh)"),
//                     gettext("Compensation CO₂ (Tons)"),

//                     //cost
//                     gettext("Production Cost (Tons)"),
//                     gettext("Consumption Cost (Tons)"),
//                     gettext("Compensation Cost (Tons)"),
//                 ];
//             } else {
//                 return [
//                     param1,
//                     param2,
//                     gettext("Consumption (kWh)"),
//                     gettext("Consumption CO₂ (Tons)"),
//                     // gettext("Production (kWh)"),
//                     // gettext("Production CO₂ (Tons)"),
//                     gettext("Compensation (kWh)"),
//                     gettext("Compensation CO₂ (Tons)"),

//                     //cost
//                     gettext("Production Cost (Tons)"),
//                     gettext("Consumption Cost (Tons)"),
//                     gettext("Compensation Cost (Tons)"),
//                 ];
//             }
//         }
//     }
//     return [
//         param1,
//         param2,
//         gettext("Consumption (kWh)"),
//         gettext("Consumption CO₂ (Tons)"),
//     ];
// };

// let getDateObject = ts => {
//   let splitTime = ts.split(" ");
//   let dt = splitTime[0].split("-");
//   let time = splitTime[1].split(":");
//   let date = new Date(dt[0], dt[1] - 1, dt[2], time[0], time[1]);
//   return Moment(date);
// };
