import styled from "styled-components";

export const MainResultSection1 = styled.div`
  box-shadow: none !important;
  p {
    margin: 0px;
  }
  hr {
    margin: 0px !important;
    border: 1px solid #33415c !important;
  }
  .custom-row {
    line-height: 25px;
  }
  #result_2_chart {
    min-height: 360px !important;
    padding-top: 30px !important;
  }
`;

export const MainResultHead = styled.div``;

export const MainResultTable = styled.div`
  .result-title {
  }
  .table-description {
    background-color: #33415c;
    color: white;
    font-weight: bold;
    line-height: 40px;
  }
  .border-top-row {
    border-top: 0.5px solid black;
    line-height: 25px;
  }
  .border-total-row {
    border-top: 1px solid black;
  }
  .border-bottom-row {
    border-bottom: 0.4px solid grey;
  }
  .row-heading {
    background: lightgrey;
  }
`;

export const MainResult = styled.div`
  .list-load {
    text-align: center;
  }
  .top-section-filters {
    justify-content: flex-start;
  }
`;
