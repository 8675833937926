export const success = {
  50: "#E6FAF5",
  100: "#CDF5EB",
  200: "#B4F0E0",
  300: "#9BEBD6",
  400: "#82E6CC",
  500: "#69E1C2",
  600: "#50DCB8",
  700: "#37D7AD",
  800: "#1ED2A3",
  900: "#05CD99"
};
