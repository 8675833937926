import React, { useState, useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams } from "react-router-dom";
import { GoBack } from "../../components";
import { AddManualMeterDataForm, UploadCSVMeterDataForm } from "../../features";
import { useUser, useTextdomainContext } from "app/providers";

export function AddMeterData() {
  const { cid, bid, type, meterNo } = useParams();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { user } = useUser();

  const { currentTeam } = user || {};

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <GoBack />

      <Tabs value={value} onChange={handleChange} variant="fullWidth" centered>
        <Tab label={gettext("ADD DATA MANUALLY")} />

        <Tab label={gettext("UPLOAD CSV")} />
      </Tabs>

      <div hidden={value !== 0}>
        <AddManualMeterDataForm
          type={type}
          customer={cid ? cid : currentTeam}
          building_id={bid}
          meterNo={meterNo}
        />
      </div>

      <div hidden={value !== 1}>
        <UploadCSVMeterDataForm
          type={type}
          customer={cid ? cid : currentTeam}
          building_id={bid}
          meterNo={meterNo}
        />
      </div>
    </>
  );
}
