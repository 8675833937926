import dateFormat from "dateformat";
import Moment from "moment";

export const getComparisonTable = metersData => {
  let tmpObject = {};
  let finalObject = {
    title: "Hourly Data",
    heading: [
      "Time",
      "Consumption (kWh)",
      "Tons carbon emission",
      "Production (kWh)",
      "Production CO2"
    ],
    content: []
  };
  const { data } = metersData;
  data &&
    data.forEach(item => {
      const date = new Date(item.ts);
      const toDate = dateFormat(date, "yyyy-mm-dd");
      if (!tmpObject[toDate]) {
        tmpObject[toDate] = {
          consumptionV: 0,
          consumptionCo2: 0,
          productionV: 0,
          productionCo2: 0
        };
      }
      tmpObject[toDate]["consumptionV"] += item.consumptionV;
      tmpObject[toDate]["consumptionCo2"] += item.consumptionCo2;
      tmpObject[toDate]["productionV"] += item.productionV;
      tmpObject[toDate]["productionCo2"] += item.productionCo2;
    });

  for (let key in tmpObject) {
    finalObject.content.push([
      key,
      tmpObject[key].consumptionV,
      tmpObject[key].consumptionCo2,
      tmpObject[key].productionV,
      tmpObject[key].productionCo2
    ]);
  }
  return finalObject;
};

export const requestForAllEnergySourch = (current, previous, energyType) => {
  let results = {};
  let tc, tp;
  // if (current.length && previous.length) {
  tc = transformEnergyResponse(current, "current");
  tp = transformEnergyResponse(previous, "previous");
  // }

  results[energyType] = [];
  results[energyType][0] = tc;
  results[energyType][1] = tp;

  if (energyType === "all") {
    let energyResponse = requestForTotalEnergy(results);
    results["energy"] = [];
    results["energy"][0] = energyResponse;
  }
  return results;
};

let requestForTotalEnergy = data => {
  let totalEnergy = {};
  Object.keys(data).forEach(key => {
    totalEnergy[key] = {};
    totalEnergy[key]["current"] = {};
    totalEnergy[key]["previous"] = {};
    let energyData = data[key];
    let current = energyData[0]["current"];
    let previous = energyData[1]["previous"];
    totalEnergy[key]["current"]["total_co2"] = getTotalValueOfEnergy(
      current.co2
    );
    totalEnergy[key]["current"]["total_consumption"] = getTotalValueOfEnergy(
      current.consumption
    );
    totalEnergy[key]["previous"]["total_co2"] = getTotalValueOfEnergy(
      current.co2
    );
    totalEnergy[key]["previous"]["total_consumption"] = getTotalValueOfEnergy(
      previous.consumption
    );
  });
  return totalEnergy;
};

let getTotalValueOfEnergy = list => {
  let emptyList = 0;
  list.forEach(item => {
    emptyList += item;
  });
  return emptyList;
};

let transformEnergyResponse = (res, year_type) => {
  let allListedData = {};
  let co2List = [];
  let consumptionArray = [];
  let daysArray = [];
  let co2Object = {};
  let consumptionObject = {};
  let daysObject = {};
  res.data.forEach((item, index) => {
    const date = new Date(item.ts);
    let dateKey = dateFormat(date, "yyyy-mm-dd");

    if (!consumptionObject[dateKey]) {
      consumptionObject[dateKey] = 0;
    }

    if (!co2Object[dateKey]) {
      co2Object[dateKey] = 0;
    }

    if (!daysObject[dateKey]) {
      daysObject[dateKey] = "";
    }

    daysObject[dateKey] = Moment(new Date(item.ts)).format("DD MMM");
    consumptionObject[dateKey] += item.consumptionV;
    co2Object[dateKey] += item.consumptionCo2; // item.v * 212 * 0.001 * 0.001;
  });

  allListedData[year_type] = {};
  Object.keys(co2Object).forEach(key => {
    co2List.push(co2Object[key]);
  });
  Object.keys(consumptionObject).forEach(key => {
    consumptionArray.push(consumptionObject[key]);
  });
  Object.keys(daysObject).forEach(key => {
    daysArray.push(daysObject[key]);
  });
  allListedData[year_type]["co2"] = co2List;
  allListedData[year_type]["consumption"] = consumptionArray;
  allListedData[year_type]["days"] = daysArray;
  return allListedData;
};

export const customInputStyle = {
  height: 35,
  width: "100%",
  borderRadius: 4,
  borderColor: "rgb(204,204,204)",
  borderWidth: 1.8,
  paddingLeft: 10,
  outlineColor: "rgb(46, 135,252)"
};

export const DEFAULT_TABLE = { title: "", heading: [], content: [] };
