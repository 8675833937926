import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
import CardHeader from "./CardHeader";
// import Co2SectionCol1Row1 from "./Co2SectionCol1Row1";
// import { localeNumberFormat } from "../../../common/Functions";
// import { DECIMAL } from "../../../common/Constants";
import {
  // totalEnergyCo2,
  // dataEnergyGraph2,
  // dataEnergyGraph3,
  totalYearCo2,
  dataTypeGraphYearly,
  dataTypeGraphMonthly
} from "../partials/Functions";
import Co2SectionCol1Row2 from "./Co2SectionCol1Row2";

function Co2Section4(props) {
  let title = "";
  // let desc1 = "";
  // let desc2 = "";
  // let desc3 = "";
  let chartTitle1 = "";
  let chartTitle2 = "";
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const selectedType = props.type ? props.type : "";
  const [data1, changeData1] = useState(null);
  const [data2, changeData2] = useState(null);
  const year1 = props.data.to_year ? props.data.to_year : "";
  const year2 = props.data.second_year ? props.data.second_year : "";
  const year3 = props.data.from_year ? props.data.from_year : "";
  const [totalTypeCo2Year1, changeTotalTypeCo2Year1] = useState(0);
  const [totalTypeCo2Year2, changeTotalTypeCo2Year2] = useState(0);
  const [totalTypeCo2Year3, changeTotalTypeCo2Year3] = useState(0);

  // const [totalEnergyCo2Year, changeTotalEnergyCo2Year] = useState(0);
  // const [totalEnergyCo2Year2, changeTotalEnergyCo2Year2] = useState(0);
  // const [totalEnergyCo2Year3, changeTotalEnergyCo2Year3] = useState(0);
  // const year1 = props.data.to_year?props.data.to_year:'';
  // const year2 = props.data.second_year?props.data.second_year:'';
  // const year3 = props.data.from_year?props.data.from_year:'';
  const chartId1 = "co2-chart-4-" + selectedType;
  const chartId2 = "co2-chart-5-" + selectedType;

  switch (selectedType) {
    case "electricity":
      title = "REPORT.TITLE.3";
      // desc1 = "REPORT.DESCRIPTION.8";
      // desc2 = "REPORT.DESCRIPTION.9";
      // desc3 = "REPORT.DESCRIPTION.10";
      chartTitle1 = "REPORT.GRAPH.TITLE.4";
      chartTitle2 = "REPORT.GRAPH.TITLE.5";
      break;
    case "heating":
      title = "REPORT.TITLE.4";
      // desc1 = "REPORT.DESCRIPTION.11";
      // desc2 = "REPORT.DESCRIPTION.12";
      // desc3 = "REPORT.DESCRIPTION.13";
      chartTitle1 = "REPORT.GRAPH.TITLE.6";
      chartTitle2 = "REPORT.GRAPH.TITLE.7";
      break;
    case "water":
      title = "REPORT.TITLE.5";
      // desc1 = "REPORT.DESCRIPTION.14";
      // desc2 = "REPORT.DESCRIPTION.15";
      // desc3 = "REPORT.DESCRIPTION.16";
      chartTitle1 = "REPORT.GRAPH.TITLE.8";
      chartTitle2 = "REPORT.GRAPH.TITLE.9";
      break;
    default:
      break;
  }

  useEffect(() => {
    if (props.data.electricity || props.data.heating || props.data.water) {
      changeTotalTypeCo2Year1(
        totalYearCo2(props.data, selectedType, year1, "cons")
      );
      changeTotalTypeCo2Year2(
        totalYearCo2(props.data, selectedType, year2, "cons")
      );
      changeTotalTypeCo2Year3(
        totalYearCo2(props.data, selectedType, year3, "cons")
      );
      // changeTotalEnergyCo2Year1(totalEnergyCo2(props.data, year1));
      // changeTotalEnergyCo2Year2(totalEnergyCo2(props.data, year2));
      // changeTotalEnergyCo2Year3(totalEnergyCo2(props.data, year3));
      changeData1(
        dataTypeGraphYearly(
          chartId1,
          chartTitle1,
          selectedType,
          props.data,
          gettext
        )
      );
      changeData2(
        dataTypeGraphMonthly(
          chartId2,
          chartTitle2,
          selectedType,
          props.data,
          gettext
        )
      );
      // changeData2(dataEnergyGraph3(chartId2, selectedYear, props.data));
    }
  }, [
    props,
    chartId1,
    chartId2,
    chartTitle1,
    chartTitle2,
    selectedType,
    year1,
    year2,
    year3,
    gettext
  ]);

  // let totalTypeCo2Year1Formatted = localeNumberFormat(
  //   totalTypeCo2Year1,
  //   DECIMAL
  // );
  // let totalTypeCo2Year3Formatted = localeNumberFormat(
  //   totalTypeCo2Year3,
  //   DECIMAL
  // );

  // let totalEnergyCo2Year1Formatted = localeNumberFormat(totalEnergyCo2Year1, DECIMAL);
  // let totalEnergyCo2Year2Formatted = localeNumberFormat(totalEnergyCo2Year2, DECIMAL);
  // let totalEnergyCo2YearFormatted = localeNumberFormat(totalEnergyCo2Year, DECIMAL);

  return (
    <>
      {totalTypeCo2Year1 + totalTypeCo2Year2 + totalTypeCo2Year3 > 0 && (
        <div className={"card card-custom gutter-b"}>
          <CardHeader title={gettext(title)} />
          <div className={"card-body"}>
            <div className={"row"}>
              {/* {totalTypeCo2Year1 < totalTypeCo2Year3 && (
                <11FormattedMessage
                  id={desc1}
                  values={{
                    year1: year1,
                    year3: year3,
                    totalTypeCo2Year1: totalTypeCo2Year1Formatted,
                    totalTypeCo2Year3: totalTypeCo2Year3Formatted
                  }}
                />
              )} */}

              {/* {totalTypeCo2Year1 > totalTypeCo2Year3 && (
                <11FormattedMessage
                  id={desc2}
                  values={{
                    year1: year1,
                    year3: year3,
                    totalTypeCo2Year1: totalTypeCo2Year1Formatted,
                    totalTypeCo2Year3: totalTypeCo2Year3Formatted
                  }}
                />
              )} */}
              {/* {totalTypeCo2Year1 === totalTypeCo2Year3 && (
                <11FormattedMessage
                  id={desc3}
                  values={{
                    year1: year1,
                    year3: year3,
                    totalTypeCo2Year1: totalTypeCo2Year1Formatted,
                    totalTypeCo2Year3: totalTypeCo2Year3Formatted
                  }}
                />
              )} */}
            </div>
            <div className={"separator separator-dashed my-7"}></div>
            {data1 && data2 && (
              <Co2SectionCol1Row2
                data1={data1}
                data2={data2}
                imageUrl1={uri => {
                  if (typeof props.graph1imageUrl === "function") {
                    props.graph1imageUrl(uri);
                  }
                }}
                imageUrl2={uri => {
                  if (typeof props.graph2imageUrl === "function") {
                    props.graph2imageUrl(uri);
                  }
                }}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default injectIntl(Co2Section4);
