/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider as NotificationProvider } from "notistack";
import {
  AuthProvider,
  ConfirmationProvider,
  CustomerPermissionProvider,
  GraphQLProvider,
  I18nProvider,
  LocaleProvider,
  MaterialThemeProvider,
  UserProvider
} from "./providers";
import ErrorBoundary from "./components/ErrorBoundary";
import {
  LayoutSplashScreen,
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "_metronic/layout";
import VersionControl from "./components/VersionControl";
import AppRoutes from "./Routes";
// import { hotjar } from "react-hotjar";
import Hotjar from "@hotjar/browser";

const { PUBLIC_URL, REACT_APP_NODE_ENV } = process.env;
let siteId = 3692176;
const hotjarVersion = 6;

switch (REACT_APP_NODE_ENV) {
  case "production":
    siteId = 3692176;
    break;
  case "staging":
    siteId = 3692279;
    break;
  default:
    siteId = 3692282;
    break;
}

export default function App() {
  useEffect(() => {
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  // // Staging
  // useEffect(() => {
  //   hotjar.initialize(3692279, 6);
  //
  //   // Identify the user
  //   hotjar.identify("USER_ID", { userProperty: "value" });
  //
  //   // Update SPA state
  //   hotjar.stateChange("/dashboard");
  //
  //   // Check if Hotjar has been initialized before calling its methods
  //   if (hotjar.initialized()) {
  //     hotjar.identify("USER_ID", { userProperty: "value" });
  //   }
  // }, []);
  // // Dev
  // useEffect(() => {
  //   hotjar.initialize(3692282, 6);
  //
  //   // Identify the user
  //   hotjar.identify("USER_ID", { userProperty: "value" });
  //
  //   // Update SPA state
  //   hotjar.stateChange("/dashboard");
  //
  //   // Check if Hotjar has been initialized before calling its methods
  //   if (hotjar.initialized()) {
  //     hotjar.identify("USER_ID", { userProperty: "value" });
  //   }
  // }, []);
  // // Production
  // useEffect(() => {
  //   hotjar.initialize(3692176, 6);
  //
  //   // Identify the user
  //   hotjar.identify("USER_ID", { userProperty: "value" });
  //
  //   // Update SPA state
  //   hotjar.stateChange("/dashboard");
  //
  //   // Check if Hotjar has been initialized before calling its methods
  //   if (hotjar.initialized()) {
  //     hotjar.identify("USER_ID", { userProperty: "value" });
  //   }
  // }, []);

  const notificationPosition = {
    vertical: "top",
    horizontal: "right"
  };
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <NotificationProvider
        anchorOrigin={notificationPosition}
        autoHideDuration={2500}
      >
        <AuthProvider>
          <UserProvider>
            <LocaleProvider>
              <GraphQLProvider>
                <ErrorBoundary>
                  <MetronicLayoutProvider>
                    <MetronicSubheaderProvider>
                      <MetronicSplashScreenProvider>
                        <VersionControl>
                          <React.Suspense fallback={<LayoutSplashScreen />}>
                            <CustomerPermissionProvider>
                              <MaterialThemeProvider>
                                <I18nProvider>
                                  <ConfirmationProvider>
                                    <AppRoutes />
                                  </ConfirmationProvider>
                                </I18nProvider>
                              </MaterialThemeProvider>
                            </CustomerPermissionProvider>
                          </React.Suspense>
                        </VersionControl>
                      </MetronicSplashScreenProvider>
                    </MetronicSubheaderProvider>
                  </MetronicLayoutProvider>
                </ErrorBoundary>
              </GraphQLProvider>
            </LocaleProvider>
          </UserProvider>
        </AuthProvider>
      </NotificationProvider>
    </BrowserRouter>
  );
}
