import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { DataGridComponent } from "app/components";
import { useConfirmation, useTextdomainContext, useUser } from "app/providers";
import React, { useState, useContext, useCallback } from "react";
import { MdDelete } from "react-icons/md";
import {
  useCustomerRoles,
  useCustomerUsers,
  useDeleteTeamMember,
  useUpdatePermissions
} from "./hooks";
import { BiChevronDown } from "react-icons/bi";
import { useSnackbar } from "notistack";

export function CustomerRolesTable({ selectedCustomer }) {
  const { enqueueSnackbar } = useSnackbar();
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { user } = useUser();

  const { email, isSuperAdmin } = user || {};

  const { customerRoles } = useCustomerRoles();

  const { customerUsers, loading } = useCustomerUsers(selectedCustomer?.name);

  const { update, updating } = useUpdatePermissions(
    () => {
      enqueueSnackbar(gettext("Company roles has been changed successfully"), {
        variant: "success"
      });
    },
    error => {
      enqueueSnackbar(
        gettext("Company is already owned, change its role to User/Admin!"),
        {
          variant: "error"
        }
      );
    }
  );

  const { deleteMember, deleting } = useDeleteTeamMember(
    () => {
      closeConfirmation();
    },
    () => {}
  );

  const { confirm, closeConfirmation } = useConfirmation({
    loading: deleting
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedData, setSelectedData] = useState(null);

  const handleClick = useCallback((event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(rowData);
  }, []);

  function handleClose() {
    setAnchorEl(null);
  }

  const handleEdit = useCallback(
    newValue => {
      update({
        permissionData: {
          role: newValue
        },
        userEmail: selectedData.email,
        customerId: selectedCustomer?.Id
      });

      handleClose();
    },
    [selectedData, selectedCustomer, update]
  );

  const RoleFormatter = useCallback(
    row => {
      const isDisabled = true;
      const givenEmail = email;
      const userEmail = customerUsers.find(user => user.email === givenEmail);
      return isSuperAdmin === true || userEmail.role === "Customer Owner" ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            variant="text"
            disabled={user.demo}
            size="small"
            sx={{ textDecoration: "none" }}
            endIcon={<BiChevronDown />}
            onClick={event => handleClick(event, row)}
          >
            {row.role}
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            variant="text"
            disabled={row.role === "Customer Owner" ? isDisabled : user.demo}
            size="small"
            sx={{ textDecoration: "none" }}
            endIcon={<BiChevronDown />}
            onClick={event => handleClick(event, row)}
          >
            {row.role}
          </Button>
        </Box>
      );
    },
    [handleClick, user, isSuperAdmin, email, customerUsers]
  );

  const DeleteFormatter = useCallback(
    row => {
      return (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <IconButton
            disabled={row.email === email || user.demo}
            onClick={() =>
              confirm({
                confirmationText:
                  gettext("Are you sure you want to romove ") + row?.name + "?",
                onConfirm: () =>
                  deleteMember({
                    customerName: selectedCustomer?.name,
                    email: row?.email
                  })
              })
            }
          >
            <MdDelete />
          </IconButton>
        </Box>
      );
    },
    [email, confirm, deleteMember, gettext, selectedCustomer, user]
  );

  const columns = [
    {
      field: "name",
      headerName: gettext("Users"),
      flex: 1,
      minWidth: 150,
      headerAlign: "left"
    },
    {
      field: "email",
      headerName: gettext("Email"),
      flex: 1,
      minWidth: 150,
      headerAlign: "left"
    },
    {
      field: "role",
      headerName: gettext("Role"),
      flex: 1,
      headerAlign: "center",
      renderCell: params => RoleFormatter(params.row)
    },
    {
      field: "delete",
      headerName: gettext("Delete"),
      headerAlign: "center",
      renderCell: params => DeleteFormatter(params.row)
    }
  ];

  return (
    <>
      <DataGridComponent
        rows={customerUsers}
        columns={columns}
        loading={loading || updating}
        getRowId={row => row.email}
        disableSelectionOnClick={false}
        autoHeight
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {customerRoles?.map(customerRole => (
          <MenuItem
            value={customerRole.name}
            onClick={() => handleEdit(customerRole.name)}
          >
            {customerRole.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
