import React, { useEffect, useState, useCallback, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
// import Co2SectionCol1Row1 from "./Co2SectionCol1Row1";
import { localeNumberFormat } from "../../../common/Functions";
import { DECIMAL } from "../../../common/Constants";
import Co2SectionCol2Row1 from "../components/Co2SectionCol2Row1";
import {
  totalEnergyValue,
  co2ReportLeftGraph,
  co2ReportRightGraph,
  perMonthEnergyValue
} from "../partials/Co2Function";
import { translateMonth } from "app/common/BroadCastFunction";
import { Grid, Typography } from "@mui/material";
import TableDropDown from "app/components/TableDropDown";

function NewCo2Section3(props) {
  const {
    data,
    energyData,
    electricityData,
    heatingData,
    waterData,
    gasData,
    electricityLoading,
    heatingLoading,
    gasLoading,
    sourceType,
    consumptionType,
    waterLoading,
    year,
    chartType,
    chartId1,
    chartId2,
    colors,
    setMainPageTitle1,
    setMainPageDescription,
    sourceIndex,
    grid,
    background,
    legend,
    selectedTableOption,
    setSelectedTableOption,
    tableDropdownOptions
  } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const selectedYear = year ? year : "";
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [totalEnergyCo2Year, setTotalEnergyCo2Year] = useState(0);
  // const [totalElectricityCo2Year, setTotalElectricityCo2Year] = useState(0);
  // const [totalHeatingCo2Year, setTotalHeatingCo2Year] = useState(0);
  // const [totalWaterCo2Year, setTotalWaterCo2Year] = useState(0);
  // const [perElectricityCo2Year, setPerElectricityCo2Year] = useState(0);
  // const [perHeatingCo2Year, setPerHeatingCo2Year] = useState(0);
  // const [perWaterCo2Year, setPerWaterCo2Year] = useState(0);

  const chartValues = useCallback(
    valueEnergy => {
      if (chartType === "Consumption") {
        return valueEnergy[1];
      } else if (chartType === "Production") {
        return valueEnergy[3];
      } else if (chartType === "Compensation") {
        return valueEnergy[1] - valueEnergy[3];
      }
    },
    [chartType]
  );

  useEffect(() => {
    if (data1?.chart_title) props.setPageTitle1(data1?.chart_title);
  }, [data1, props]);

  useEffect(() => {
    if (data2?.chart_title) props.setPageTitle1(data2?.chart_title);
  }, [data2, props]);

  useEffect(() => {
    if (
      electricityData &&
      heatingData &&
      waterData &&
      gasData &&
      energyData &&
      !gasLoading &&
      !electricityLoading &&
      !heatingLoading &&
      !waterLoading
    ) {
      const estimateTimeFrame = () => {
        if (data.month > 0) {
          return `${translateMonth(
            data.month + 1,
            "long"
          )}, ${selectedYear} to ${translateMonth(
            data.month,
            "long"
          )}, ${selectedYear + 1}`;
        } else {
          return `${selectedYear}`;
        }
      };
      const valueEnergy = totalEnergyValue(energyData, selectedYear);
      const valueElectricity = totalEnergyValue(electricityData, selectedYear);
      const valueHeating = totalEnergyValue(heatingData, selectedYear);
      const valueGas = totalEnergyValue(gasData, selectedYear);

      const valueWater = totalEnergyValue(waterData, selectedYear);
      const { values: eValues, months } = perMonthEnergyValue(
        electricityData,
        selectedYear,
        chartType,
        data.month,
        gettext
      );
      const { values: hValues } = perMonthEnergyValue(
        heatingData,
        selectedYear,
        chartType,
        data.month,
        gettext
      );

      const { values: gValues } = perMonthEnergyValue(
        gasData,
        selectedYear,
        chartType,
        data.month,
        gettext
      );
      const { values: wValues } = perMonthEnergyValue(
        waterData,
        selectedYear,
        chartType,
        data.month,
        gettext
      );
      chartValues(valueEnergy);
      setTotalEnergyCo2Year(chartValues(valueEnergy));
      // setTotalElectricityCo2Year(chartValues(valueElectricity));
      // setTotalHeatingCo2Year(chartValues(valueHeating));
      // setTotalWaterCo2Year(chartValues(valueWater));
      // setPerElectricityCo2Year(
      //     Number(
      //         (chartValues(valueElectricity) * 100) /
      //             chartValues(valueEnergy)
      //     )
      // );
      // setPerHeatingCo2Year(
      //     Number(
      //         (chartValues(valueHeating) * 100) / chartValues(valueEnergy)
      //     )
      // );
      // setPerWaterCo2Year(
      //     Number(
      //         (chartValues(valueWater) * 100) / chartValues(valueEnergy)
      //     )
      // );

      setData1(
        co2ReportLeftGraph(
          eValues,
          hValues,
          gValues,
          wValues,
          months,
          selectedYear,
          consumptionType,
          "consumption",
          sourceType,
          chartId1,
          chartType,
          colors,
          gettext,
          // "Monthly CO₂ production graph",
          `${gettext("Monthly CO₂")} ${
            sourceIndex === 4 ? gettext("reductions") : gettext("emissions")
          } ${gettext("measured in tonnes for")} ${gettext(
            estimateTimeFrame()
          )}`,
          sourceIndex,
          grid,
          background,
          legend
        )
      );
      const chartData2 = co2ReportRightGraph(
        chartValues(valueElectricity),
        chartValues(valueHeating),
        chartValues(valueGas),
        chartValues(valueWater),
        selectedYear,
        consumptionType,
        "consumption",
        sourceType,
        chartId2,
        chartType,
        colors,
        gettext,
        `${gettext("Energy sources share of CO₂")} ${
          sourceIndex === 4 ? gettext("reductions") : gettext("emissions")
        } ${gettext("for")} ${gettext(estimateTimeFrame())}`
      );
      setData2(chartData2);
    }
  }, [
    electricityData,
    energyData,
    heatingData,
    gasData,
    waterData,
    electricityLoading,
    gasLoading,
    heatingLoading,
    waterLoading,
    selectedYear,
    chartId1,
    chartId2,
    chartType,
    chartValues,
    consumptionType,
    sourceType,
    colors,
    gettext,
    sourceIndex,
    data.month,
    background,
    grid,
    legend
  ]);

  const co2Formated = type => {
    return localeNumberFormat(type, DECIMAL);
  };

  // const getSourceType = () => {
  //   if (sourceIndex === 2) {
  //     return "emissions";
  //   } else if (sourceIndex === 4) {
  //     return "reduction";
  //   } else {
  //     return "emissions";
  //   }
  // };

  const getSourceTitle = () => {
    let title;
    if (sourceIndex === 2) {
      title = `${gettext(
        `The total CO₂ emissions measured in tonnes for each energy source for`
      )} ${selectedYear}`;
    } else if (sourceIndex === 4) {
      title = `${gettext(
        "CO₂ reductions measured in tonnes for"
      )} ${selectedYear} ${gettext("from electricity production")}`;
    } else {
      title = `${gettext("CO₂ accounts for")} ${selectedYear}`;
    }
    return title;
  };

  const getDescription = () => {
    // setMainPageDescription && setMainPageDescription(description);
    // return description;
    let description = "";
    if (sourceIndex === 4) {
      description = `${gettext(
        "Electricity production reduced CO₂ emissions by"
      )} ${co2Formated(totalEnergyCo2Year)} ${gettext(
        "tonnes in"
      )} ${selectedYear}`;
      return (
        <div className={"row"}>
          {description}
          <br />
        </div>
      );
    } else {
      description = `${gettext("In")} ${selectedYear}, ${gettext(
        "The total CO₂ emissions were"
      )} ${co2Formated(totalEnergyCo2Year)} ${gettext("tonnes of CO₂")}`;
      return (
        <div className={"row"}>
          {`${gettext("In")} ${selectedYear}, ${gettext(
            "the total CO₂ emissions were"
          )} ${co2Formated(totalEnergyCo2Year)} ${gettext("tonnes of CO₂")}`}
          <br />
        </div>
      );
    }
  };

  useEffect(() => {
    let description = "";
    let title = "";
    if (sourceIndex === 4) {
      title = `${gettext(
        "CO₂ reductions measured in tonnes for"
      )} ${selectedYear} ${gettext("from electricity production")}`;
      description = `${gettext(
        "Electricity production reduced CO₂ emissions by"
      )} ${co2Formated(totalEnergyCo2Year)} ${gettext(
        "tonnes in"
      )} ${selectedYear}`;
      setMainPageTitle1 && setMainPageTitle1(title);
      setMainPageDescription && setMainPageDescription(description);
    } else if (sourceIndex === 2) {
      title = `${gettext(
        `The total CO₂ emissions measured in tonnes for each energy source for`
      )} ${selectedYear}`;
      description = `${gettext("In")} ${selectedYear}, ${gettext(
        "The total CO₂ emissions were"
      )} ${co2Formated(totalEnergyCo2Year)} ${gettext("tonnes of CO₂")}`;
      setMainPageTitle1 && setMainPageTitle1(title);
      setMainPageDescription && setMainPageDescription(description);
    } else {
      title = `${gettext("CO₂ accounts for")} ${selectedYear}`;
      description = `${gettext("In")} ${selectedYear}, ${gettext(
        "The total CO₂ emissions were"
      )} ${co2Formated(totalEnergyCo2Year)} ${gettext("tonnes of CO₂")}`;
      setMainPageTitle1 && setMainPageTitle1(title);
      setMainPageDescription && setMainPageDescription(description);
    }
  }, [
    sourceIndex,
    setMainPageTitle1,
    setMainPageDescription,
    gettext,
    totalEnergyCo2Year,
    selectedYear
  ]);

  return (
    <>
      <Grid
        container
        display="flex"
        flexDirection="column"
        rowGap={2}
        sx={{
          backgroundColor: "#ffffff",
          p: 3,
          borderRadius: "8px",
          my: 3
        }}
      >
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{ borderBottom: "1px solid #A1A1AA5E", pb: 2.5 }}
        >
          <Grid item>
            <Grid item>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#27272A"
                }}
              >
                {getSourceTitle()}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                sx={{
                  pl: 2,
                  pt: 1.5,
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#27272A"
                }}
              >
                {getDescription()}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <TableDropDown
              onChange={setSelectedTableOption}
              options={tableDropdownOptions}
              selectedOption={selectedTableOption}
              gettext={gettext}
            />
          </Grid>
        </Grid>
        <Grid item>
          {data1 && data2 && (
            <Co2SectionCol2Row1
              data1={data1}
              data2={sourceIndex === 2 ? data2 : null}
              imageUrl1={uri => {
                if (typeof props.setPageUri1 === "function") {
                  props.setPageUri1(uri);
                }
              }}
              imageUrl2={uri => {
                if (typeof props.setPageUri2 === "function") {
                  props.setPageUri2(uri);
                }
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default injectIntl(NewCo2Section3);
