import { useQuery } from "@apollo/client";
import { GET_CUSTOMERS_BUILDINGS } from "../commands";

export function useCustomersBuildings(customerName) {
  const { data, loading } = useQuery(GET_CUSTOMERS_BUILDINGS, {
    variables: {
      customerName
    },
    fetchPolicy: "network-only"
  });

  return {
    buildings: data?.comCustomerAddresses || [],
    loading
  };
}
