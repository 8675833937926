import { gql } from "@apollo/client";

export const LOGIN_AS = gql`
  mutation comLoginAs($email: String) {
    comLoginAs(email: $email) {
      success
      message
      token
    }
  }
`;
