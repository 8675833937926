import React from "react";
import { Grid } from "@mui/material";
import { useTotalKwhM2DasboardData } from "./hooks";
import { LineChart, NoBuildingWarning, NoCompanyWarning } from "app/components";
import { useDashboardFilter, useTextdomainContext } from "app/providers";

export function TotalKwhM2Dashboard() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const {
    loading,
    catagories,
    EcInKwhM2Series,
    Co2InKwhM2Series,
    EcInKwhM2Colors,
    Co2InKwhM2Colors
  } = useTotalKwhM2DasboardData();

  const { selectedCompanies, selectedBuildings } = useDashboardFilter();

  if (!selectedCompanies.length) return <NoCompanyWarning />;

  if (!loading && !selectedBuildings.length) return <NoBuildingWarning />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LineChart
          title={gettext("Energy consumption measured in kWh/m2")}
          catagories={catagories}
          data={EcInKwhM2Series}
          colors={EcInKwhM2Colors}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <LineChart
          title={gettext("CO₂ eq measured in tons per m2")}
          catagories={catagories}
          data={Co2InKwhM2Series}
          colors={Co2InKwhM2Colors}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}
