import { gql } from "@apollo/client";

export const GET_NEW_NOTIFICATIONS = gql`
  query comGetNewNotifications {
    comGetNewNotifications {
      id
      email
      Subject
      Message
      Read
      date
    }
  }
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query comGetAllNotifications {
    comGetAllNotifications {
      id
      email
      Subject
      Message
      Read
      date
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation comDeleteNotification($notificationId: String!) {
    comDeleteNotification(notificationId: $notificationId) {
      success
      message
    }
  }
`;
export const READ_NOTIFICATION = gql`
  mutation comReadNotification($notificationId: String!) {
    comReadNotification(notificationId: $notificationId) {
      success
      message
    }
  }
`;
export const GetUserDocument = gql`
  query userDocuments {
    userDocuments {
      id
      userId
      fileName
      fileUrl
      createdAt
      updatedAt
    }
  }
`;
export const DeleteUserDocument = gql`
  mutation deleteUserDocument($documentId: String) {
    deleteUserDocument(documentId: $documentId) {
      success
      message
    }
  }
`;
