import { gql } from "@apollo/client";

export const ADD_USER_CUSTOMER = gql`
  mutation comAddCustomerUser(
    $customerName: String
    $read: Boolean
    $role: String
    $userId: String
    $write: Boolean
  ) {
    comAddCustomerUser(
      customerName: $customerName
      read: $read
      role: $role
      userId: $userId
      write: $write
    ) {
      success
      message
    }
  }
`;

export const GET_NEW_CUSTOMERS = gql`
  query comNotMyCustomers($userId: String!) {
    comNotMyCustomers(userId: $userId)
  }
`;

export const GET_CUSTOMERS_ROLES = gql`
  query getCustomerRoles {
    comCustomerRoles {
      name
    }
  }
`;
