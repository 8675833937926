import { gql } from "@apollo/client";

export const GET_ENERGY_SUPPLIERS = gql`
  query getEnergySuppliers($Type: String!) {
    comEnergySuppliers(Type: $Type) {
      id
      type
      name
      address
      zipcode
      city
      country
      phone
      email
    }
  }
`;
