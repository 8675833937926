import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation comLogin($email: String, $password: String, $rememberMe: Boolean) {
    comLogin(email: $email, password: $password, rememberMe: $rememberMe) {
      success
      message
      token
    }
  }
`;
