import { useQuery } from "@apollo/client";
import { GET_ROLE_MODULES } from "../commands";

export function useRoleModules(role) {
  const { data, loading } = useQuery(GET_ROLE_MODULES, {
    variables: {
      role
    }
  });

  return {
    roleModules: data?.comRoles[0].modules || [],
    loading
  };
}
