import client, {
  // api,
  useAPI,
  useAPIGraph
} from "./apiClient";
import { GET_TEAM_USERS_USERS, CUSTOMER_ADDRESSES } from "./Constant";

export function useTeamUsers(userId) {
  return useAPI(`${GET_TEAM_USERS_USERS}${userId}`);
}

export function useCustomerAddresses(teamId) {
  return useAPIGraph(`${CUSTOMER_ADDRESSES}${teamId}`);
}

export function getCustomerAddresses(teamId) {
  return client.get(`${CUSTOMER_ADDRESSES}${teamId}`);
}
