import { useState } from "react";
import { Button, Menu, Chip, Stack, MenuItem } from "@mui/material";
import { IoChevronDown, IoCheckmark } from "react-icons/io5";
import { useBuildingAccess } from "./hooks";

const AccessSelection = props => {
  const { buildingAccess, buildingName, gettext } = props;
  const { updateAccess, updating } = useBuildingAccess();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccess = async access => {
    setAnchorEl(null);
    await updateAccess({
      buildingName,
      isPublic: access
    });
  };

  if (!buildingName) return null;

  return (
    <>
      <Button
        id="basic-button"
        size="small"
        disabled={updating}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<IoChevronDown size={12} color={"white"} />}
        color="secondary"
        onClick={handleClick}
        sx={{
          "&:hover": {
            background: "#E6EEEA",
            border: "1px solid #B3CDC1"
          },
          border: "1px solid transparent",
          borderRadius: "8px",
          padding: "0 8px 0 8px",
          backgroundColor: buildingAccess ? "primary.800" : "black"
        }}
      >
        <Chip
          sx={{ ml: 0.5, color: "white", backgroundColor: "transparent" }}
          size="small"
          label={gettext(buildingAccess ? "Public" : "Private")}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { p: 0 }
        }}
        PaperProps={{
          sx: {
            p: 1,
            borderRadius: "8px",
            marginTop: "8px",
            overflow: "hidden"
          }
        }}
      >
        <Stack
          spacing={2}
          sx={{
            "&:focus": {
              outline: "none"
            },
            minWidth: "165px"
          }}
        >
          <MenuItem onClick={() => handleAccess(true)}>
            {gettext("Public")}
            {buildingAccess && !updating ? (
              <IoCheckmark style={{ marginLeft: "auto" }} color="#015731" />
            ) : null}
          </MenuItem>
          <MenuItem onClick={() => handleAccess(false)}>
            {gettext("Private")}
            {!buildingAccess && !updating ? (
              <IoCheckmark style={{ marginLeft: "auto" }} color="#015731" />
            ) : null}
          </MenuItem>
        </Stack>
      </Menu>
    </>
  );
};

export default AccessSelection;
