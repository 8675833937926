import { Collapse, Divider, Grid, Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTabGroups } from "./hooks";
import {
  Level1Tabs,
  Level2Tabs,
  Level3Tabs,
  Level4Tabs,
  Dropdown,
  Textbox
} from "./components";
import { useUser } from "app/providers";

export function CascadeDropdownForm(props) {
  const {
    editableRow,
    dropdownValues,
    setdropdownValues,
    textboxValues,
    settextboxValues,
    setLevel1Value,
    setLevel2Value,
    setLevel3Value,
    setLevel4Value
  } = props;
  const { tabGroups } = useTabGroups();

  const { user } = useUser();

  const [name, setName] = useState(null);

  const [Level1SelectedTab, setLevel1SelectedTab] = useState(null);

  const [Level2SelectedTab, setLevel2SelectedTab] = useState(null);

  const [Level3SelectedTab, setLevel3SelectedTab] = useState(null);

  const [Level4SelectedTab, setLevel4SelectedTab] = useState(null);

  const [Level1TabGroups, setLevel1TabGroups] = useState(null);

  const [Level2TabGroups, setLevel2TabGroups] = useState(null);

  const [Level3TabGroups, setLevel3TabGroups] = useState(null);

  const [Level4TabGroups, setLevel4TabGroups] = useState(null);

  const [dropdowns, setdropdowns] = useState();

  const [textboxes, settextboxes] = useState();

  useEffect(() => {
    if (user?.lang === "en") {
      setName("nameEn");
    } else {
      setName("nameDa");
    }
  }, [user]);

  useEffect(() => {
    if (tabGroups && editableRow) {
      tabGroups.map(level1 => {
        if (level1[name] === editableRow?.level1) {
          setLevel1SelectedTab(level1["key"]);

          if (level1?.level2) {
            setLevel2TabGroups(level1?.level2);

            level1?.level2?.map(level2 => {
              if (level2[name] === editableRow?.level2) {
                setLevel2SelectedTab(level2["key"]);

                if (level2?.level3?.length > 0) {
                  setLevel3TabGroups(level2?.level3);

                  level2?.level3?.map(level3 => {
                    if (level3[name] === editableRow?.level3) {
                      setLevel3SelectedTab(level3["key"]);

                      if (level3?.level4?.length > 0) {
                        setLevel4TabGroups(level3?.level4);

                        level3?.level4?.map(level4 => {
                          if (level4[name] === editableRow?.level4) {
                            setLevel4SelectedTab(level4["key"]);

                            if (level4?.dropdowns?.length > 0) {
                              setdropdowns(level4.dropdowns);

                              level4.dropdowns?.map(dropdown => {
                                // dropdown = humanize(
                                //     dropdown
                                // );
                                if (Boolean(editableRow[dropdown["key"]])) {
                                  setdropdownValues(prev => {
                                    prev[dropdown["key"]] = JSON.parse(
                                      editableRow[dropdown["key"]]
                                    );
                                    return prev;
                                  });
                                }
                                return false;
                              });
                            }
                            if (level4?.textboxes?.length > 0) {
                              settextboxes(level4.textboxes);

                              level4.textboxes?.map(textbox => {
                                if (Boolean(editableRow[textbox["key"]])) {
                                  settextboxValues(prev => {
                                    prev[textbox["key"]] =
                                      editableRow[textbox["key"]];
                                    return prev;
                                  });
                                }
                                return false;
                              });
                            }
                          }
                          return false;
                        });
                      } else {
                        if (level3?.dropdowns?.length > 0) {
                          setdropdowns(level3.dropdowns);

                          level3.dropdowns?.map(dropdown => {
                            // dropdown = humanize(
                            //     dropdown
                            // );
                            if (Boolean(editableRow[dropdown["key"]])) {
                              setdropdownValues(prev => {
                                prev[dropdown["key"]] = JSON.parse(
                                  editableRow[dropdown["key"]]
                                );
                                return prev;
                              });
                            }
                            return false;
                          });
                        }
                        if (level3?.textboxes?.length > 0) {
                          settextboxes(level3.textboxes);

                          level3.textboxes?.map(textbox => {
                            if (Boolean(editableRow[textbox["key"]])) {
                              settextboxValues(prev => {
                                prev[textbox["key"]] =
                                  editableRow[textbox["key"]];
                                return prev;
                              });
                            }
                            return false;
                          });
                        }
                      }
                    }
                    return false;
                  });
                } else {
                  if (level2?.dropdowns?.length > 0) {
                    setdropdowns(level2.dropdowns);

                    level2.dropdowns?.map(dropdown => {
                      // dropdown = humanize(dropdown);
                      if (Boolean(editableRow[dropdown["key"]])) {
                        setdropdownValues(prev => {
                          prev[dropdown["key"]] = JSON.parse(
                            editableRow[dropdown["key"]]
                          );
                          return prev;
                        });
                      }
                      return false;
                    });
                  }
                  if (level2?.textboxes?.length > 0) {
                    settextboxes(level2.textboxes);

                    level2.textboxes?.map(textbox => {
                      if (Boolean(editableRow[textbox["key"]])) {
                        settextboxValues(prev => {
                          prev[textbox["key"]] = editableRow[textbox["key"]];
                          return prev;
                        });
                      }
                      return false;
                    });
                  }
                }
              }
              return false;
            });
          } else {
            if (level1?.dropdowns?.length > 0) {
              setdropdowns(level1.dropdowns);

              level1.dropdowns?.map(dropdown => {
                // dropdown = humanize(dropdown);
                if (Boolean(editableRow[dropdown["key"]])) {
                  setdropdownValues(prev => {
                    prev[dropdown["key"]] = JSON.parse(
                      editableRow[dropdown["key"]]
                    );
                    return prev;
                  });
                }
                return false;
              });
            }
            if (level1?.textboxes?.length > 0) {
              settextboxes(level1.textboxes);

              level1.textboxes?.map(textbox => {
                if (Boolean(editableRow[textbox["key"]])) {
                  settextboxValues(prev => {
                    prev[textbox["key"]] = editableRow[textbox["key"]];
                    return prev;
                  });
                }
                return false;
              });
            }
          }
        }
        return false;
      });
    }
  }, [editableRow, tabGroups, setdropdownValues, settextboxValues, name]);

  // function humanize(str) {
  //     let i,
  //         frags = str.split("_");
  //     for (i = 1; i < frags.length; i++) {
  //         frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  //     }
  //     return frags.join("");
  // }

  useEffect(() => {
    if (tabGroups) setLevel1TabGroups(tabGroups);
  }, [tabGroups]);

  useEffect(() => {
    setLevel1Value(Level1SelectedTab);
  }, [Level1SelectedTab, setLevel1Value]);

  useEffect(() => {
    setLevel2Value(Level2SelectedTab);
  }, [Level2SelectedTab, setLevel2Value]);

  useEffect(() => {
    setLevel3Value(Level3SelectedTab);
  }, [Level3SelectedTab, setLevel3Value]);

  useEffect(() => {
    setLevel4Value(Level4SelectedTab);
  }, [Level4SelectedTab, setLevel4Value]);

  const handleLevel1TabChange = useCallback(tab => {
    setLevel1SelectedTab(tab["key"]);

    if (tab?.level2 && tab?.level2.length > 0) {
      setLevel2TabGroups(tab?.level2);

      setdropdowns(null);
      settextboxes(null);
    } else {
      setLevel2TabGroups(null);
      setdropdowns(tab?.dropdowns);
      settextboxes(tab?.textboxes);
    }

    setLevel3TabGroups(null);
    setLevel4TabGroups(null);
  }, []);

  const handleLevel2TabChange = useCallback(tab => {
    setLevel2SelectedTab(tab["key"]);

    if (tab?.level3 && tab?.level3.length > 0) {
      setLevel3TabGroups(tab?.level3);

      setdropdowns(null);
      settextboxes(null);
    } else {
      setLevel3TabGroups(null);

      setdropdowns(tab?.dropdowns);
      settextboxes(tab?.textboxes);
    }
    setLevel4TabGroups(null);
  }, []);

  const handleLevel3TabChange = useCallback(tab => {
    setLevel3SelectedTab(tab["key"]);

    if (tab?.level4 && tab?.level4.length > 0) {
      setLevel4TabGroups(tab?.level4);

      setdropdowns(null);
      settextboxes(null);
    } else {
      setLevel4TabGroups(null);
      setdropdowns(tab?.dropdowns);
      settextboxes(tab?.textboxes);
    }
  }, []);

  const handleLevel4TabChange = useCallback(tab => {
    setLevel4SelectedTab(tab["key"]);

    setdropdowns(tab?.dropdowns);
    settextboxes(tab?.textboxes);
  }, []);

  return (
    <Paper sx={{ padding: 4 }}>
      <Level1Tabs
        Level1SelectedTab={Level1SelectedTab}
        Level1TabGroups={Level1TabGroups}
        handleLevel1TabChange={handleLevel1TabChange}
        name={name}
      />

      <Level2Tabs
        Level2SelectedTab={Level2SelectedTab}
        Level2TabGroups={Level2TabGroups}
        handleLevel2TabChange={handleLevel2TabChange}
        name={name}
      />

      <Level3Tabs
        Level3SelectedTab={Level3SelectedTab}
        Level3TabGroups={Level3TabGroups}
        handleLevel3TabChange={handleLevel3TabChange}
        name={name}
      />

      <Level4Tabs
        Level4SelectedTab={Level4SelectedTab}
        Level4TabGroups={Level4TabGroups}
        handleLevel4TabChange={handleLevel4TabChange}
        name={name}
      />

      <Collapse in={Boolean(dropdowns)}>
        <Divider sx={{ marginY: 1 }} variant="middle" />
        <Grid container spacing={2}>
          {dropdowns?.map((type, index) => (
            <Grid item lg={6} xs={12} key={index}>
              <Dropdown
                // type={humanize(type)}
                name={name}
                type={type}
                dropdownValues={dropdownValues}
                setdropdownValues={setdropdownValues}
                Level1SelectedTab={Level1SelectedTab}
                Level2SelectedTab={Level2SelectedTab}
                Level3SelectedTab={Level3SelectedTab}
                // dropdownkeys={dropdowns[index]}
              />
            </Grid>
          ))}
        </Grid>
      </Collapse>

      <Collapse in={Boolean(textboxes)}>
        <Divider sx={{ marginY: 1 }} variant="middle" />
        <Grid container spacing={2}>
          {textboxes?.map((type, index) => (
            <Grid item lg={6} xs={12} key={index}>
              <Textbox
                name={name}
                type={type}
                textboxValues={textboxValues}
                settextboxValues={settextboxValues}
              />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Paper>
  );
}
