import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { REDIRECT_STATS } from "./commands";

export function useRedirectStats(onCompleted = () => {}, onError = () => {}) {
  const [comRedirectStats, { loading }] = useMutation(REDIRECT_STATS, {
    onCompleted: ({ comRedirectStats }) => {
      if (comRedirectStats.success) {
        onCompleted(comRedirectStats.result);
      }
    },
    onError
  });

  const redirectStats = useCallback(
    async variables => {
      await comRedirectStats({
        variables
      });
    },
    [comRedirectStats]
  );

  return {
    redirectStats,
    loading
  };
}
