import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useUser } from "../UserProvider";
import { GET_PUBLIC_DASHBOARD_DATA } from "./commands";

export function usePublicAccess(selectedDashboard) {
  const { user } = useUser();

  const { currentTeamId } = user || {};

  const [isDashboardPublic, setIsDashboardPublic] = useState(false);

  const { data, loading } = useQuery(GET_PUBLIC_DASHBOARD_DATA, {
    variables: {
      customerId: currentTeamId
    }
  });

  useEffect(() => {
    if (Array.isArray(data?.comGetGraphImages)) {
      const index = data?.comGetGraphImages.findIndex(
        publicData => publicData.dashboard === selectedDashboard.key
      );
      if (index === -1) {
        setIsDashboardPublic(false);
      } else setIsDashboardPublic(true);
    }
  }, [data, selectedDashboard]);

  return {
    isDashboardPublic,
    publicDashboardsData: data?.comGetGraphImages || [],
    publicURL: `${
      window.location.href.split("/d")[0]
    }/public/${currentTeamId}/dashboard/${selectedDashboard.key}`,
    loading
  };
}
