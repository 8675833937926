import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { DELETE_METER_DATA_ADMIN } from "./commands";

export function useDeleteMeterData(onCompleted = () => {}, onError = () => {}) {
  const [deleteMeterDataAdmin, { loading }] = useMutation(
    DELETE_METER_DATA_ADMIN,
    {
      onCompleted: () => {
        onCompleted();
      },
      onError: () => {
        onError();
      }
    }
  );
  const delMeterData = useCallback(
    ({ fromDate, meterNo, meterType, resolution, toDate }) => {
      deleteMeterDataAdmin({
        variables: {
          fromDate,
          toDate,
          meterNo,
          meterType,
          resolution
        }
      });
    },
    [deleteMeterDataAdmin]
  );

  return {
    delMeterData,
    loading
  };
}
