import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { SUBMIT_BUILDING } from "../commands";

export default function useSubmit(onCompleted = () => {}, onError = () => {}) {
  const [comCustomerAddBuildings, { loading }] = useMutation(SUBMIT_BUILDING, {
    onCompleted,
    onError
  });

  const submit = useCallback(
    variables => {
      comCustomerAddBuildings({
        variables
      });
    },
    [comCustomerAddBuildings]
  );

  return {
    submit,
    submiting: loading
  };
}
