import { gql } from "@apollo/client";

export const GET_METERS_DATA_QUERY = gql`
  query metersData(
    $meterType: String!
    $meteringPointId: String
    $address: String
    $customer: String!
    $fromDate: DateTime!
    $toDate: DateTime!
    $resolution: String!
  ) {
    metersData(
      meterType: $meterType
      meteringPointId: $meteringPointId
      address: $address
      customer: $customer
      fromDate: $fromDate
      toDate: $toDate
      resolution: $resolution
    ) {
      Id
      electricityGreen
      data {
        ts
        consumptionV
        consumptionCo2
        vPerM2
        co2PerM2
        productionV
        productionCo2
        productionEur
        productionNok
        productionSek
        productionDkk
        consumptionEur
        consumptionNok
        consumptionSek
        consumptionDkk
      }
      fileUrl
    }
  }
`;
