import { Box, Grid } from "@mui/material";
import { useStep } from "app/providers";
import React from "react";
import { RFstepper, StepForm } from "../components";

export function Core({ offsetHeight }) {
  const { currentStep } = useStep();
  return (
    <Grid container sx={{ minHeight: `calc(100% - ${offsetHeight || 76}px )` }}>
      <Grid item xs={currentStep === 2 ? 4 : 6}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FAFAFA"
          }}
        >
          <RFstepper />
        </Box>
      </Grid>

      <Grid item xs={currentStep === 2 ? 8 : 6}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFF",
            boxShadow: "0px 20px 25px -5px #0000001A"
          }}
        >
          <StepForm />
        </Box>
      </Grid>
    </Grid>
  );
}
