import { FilterSelectButton } from "app/components";
import { useEpcFilter, useTextdomainContext } from "app/providers";
import React from "react";

export function SelectYear() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { yearsList, selectedYear, setSelectedYear } = useEpcFilter();

  return (
    <FilterSelectButton
      searchable={false}
      label={gettext("Years")}
      options={yearsList}
      getOptionLabel={option => option.label}
      value={selectedYear}
      onChange={newValue => setSelectedYear(newValue)}
    />
  );
}
