import { useCompanyBuildings, useEnergySources } from "hooks";
import React, { createContext } from "react";
import { useUser } from "../UserProvider";

const BenchmarkFilterContext = createContext();

export function useBenchmarkFilter() {
  return React.useContext(BenchmarkFilterContext);
}

export default function BenchmarkFilterProvider({ children }) {
  const allEnergySources = useEnergySources();

  const [selectedBuildingOne, setSelectedBuildingOne] = React.useState({});

  const [selectedBuildingTwo, setSelectedBuildingTwo] = React.useState({});

  const [selectedEnergySource, setSelectedEnergySource] = React.useState(
    allEnergySources[0]
  );

  const [selectedDate, setSelectedDate] = React.useState(
    new Date(new Date().getFullYear(), 0, 1)
  );

  const [resolution, setResolution] = React.useState("m");

  const { user } = useUser();

  const { currentTeam } = user || {};

  const { buildings, loading } = useCompanyBuildings(currentTeam, buildings => {
    if (buildings.length) {
      setSelectedBuildingOne(buildings[0] || {});

      setSelectedBuildingTwo(buildings[1] || {});
    }
  });

  const value = {
    buildings,
    allEnergySources,
    selectedBuildingOne,
    setSelectedBuildingOne,
    selectedBuildingTwo,
    setSelectedBuildingTwo,
    selectedEnergySource,
    setSelectedEnergySource,
    selectedDate,
    setSelectedDate,
    resolution,
    setResolution,
    filtersLoading: loading
  };

  return (
    <BenchmarkFilterContext.Provider value={value}>
      {children}
    </BenchmarkFilterContext.Provider>
  );
}
