import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Spinner } from "react-bootstrap";
import {
  GET_ACCOUNT_CHART,
  GET_ACCOUNT_CHARTS
} from "../../../../GraphQL/Queries";
import { UPDATE_ACCOUNT_CHART } from "../../../../GraphQL/Mutations";
import AccountChartForm from "./account-chart-form";
import { GoBackButton, AccountChartHeader } from "./chartStyle";

const AccountChartEdit = () => {
  const { id } = useParams();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const navigate = useNavigate();
  const [nameDa, setNameDa] = useState("");
  const [nameDe, setNameDe] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountGroupId, setAccountGroupId] = useState("");
  const [tab, setTab] = useState("");
  const [category, setCategory] = useState("");

  const { data } = useQuery(GET_ACCOUNT_CHART, {
    variables: {
      id: id
    }
  });

  useEffect(() => {
    if (data) {
      const { chartOfAccount } = data;
      setNameDa(chartOfAccount.nameDa);
      setNameDe(chartOfAccount.nameDe);
      setNameEn(chartOfAccount.nameEn);
      setTab(chartOfAccount.tab);
      setCategory(chartOfAccount.category);
      setAccountNumber(chartOfAccount.accountNumber);
      setAccountGroupId(chartOfAccount.accountGroupId);
    }
  }, [data]);

  const [updateAccountGroup, { loading: updateLoading }] = useMutation(
    UPDATE_ACCOUNT_CHART,
    {
      onCompleted() {
        // const {
        //   insertAccountGroup: { success }
        // } = data;
        navigate(`/admin/account-charts`);
      },
      onError(error) {
        // error.graphQLErrors.map(({ message }) => {
        //   // console.log("Error =>", error);
        // });
      }
    }
  );

  const handleUpdateAccountChart = () => {
    updateAccountGroup({
      variables: {
        id: id,
        nameDa: nameDa,
        nameDe: nameDe,
        nameEn: nameEn,
        tab: tab,
        category: category,
        accountNumber: parseInt(accountNumber),
        accountGroupId: accountGroupId
      },
      refetchQueries: [
        { query: GET_ACCOUNT_CHARTS },
        {
          query: GET_ACCOUNT_CHART,
          variables: {
            id: id
          }
        }
      ]
    });
  };

  const hanleGoBack = () => {
    navigate(`/admin/account-charts`);
  };

  const validForm = () =>
    nameDa && nameDe && nameEn && accountNumber && accountGroupId;

  return (
    <>
      <GoBackButton className="row m-0">
        <div className="col-sm-12 p-0">
          <div className="form-group col-md-2 p-0">
            <button
              type="button"
              className="btn btn-outline-info"
              onClick={hanleGoBack}
            >
              <AiOutlineArrowLeft title="back" size={18} className="mr-2" />
              {gettext("Back")}
            </button>
          </div>
        </div>
      </GoBackButton>
      <AccountChartHeader className="card card-custom gutter-b example example-compact">
        <div className="row m-0 table-title">
          <div className="col-sm-12 title">
            <h3>{gettext("Edit Account Chart")}</h3>
          </div>
        </div>
        <>
          <AccountChartForm
            nameDa={nameDa}
            nameDe={nameDe}
            nameEn={nameEn}
            accountNumber={accountNumber}
            accountGroupId={accountGroupId}
            tab={tab}
            category={category}
            setNameDa={setNameDa}
            setNameDe={setNameDe}
            setNameEn={setNameEn}
            setAccountNumber={setAccountNumber}
            setAccountGroupId={setAccountGroupId}
            setTab={setTab}
            setCategory={setCategory}
          />

          <div className="row m-0 border-top-row">
            <div className="col-sm-12 create-action">
              <div className="form-group col-md-12">
                <button
                  type="button"
                  onClick={handleUpdateAccountChart}
                  disabled={!validForm() || updateLoading}
                >
                  {gettext("Update Account Chart")} &nbsp;
                  {updateLoading && (
                    <Spinner
                      className="btn-spinner-delete"
                      animation="border"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      </AccountChartHeader>
    </>
  );
};

export default AccountChartEdit;
