import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Spinner } from "react-bootstrap";
import { DECIMAL } from "../../../common/Constants";
import { Button, Grid, Snackbar, styled, Typography } from "@mui/material";
import Alert from "../components/Alert";
// import { totalEnergyCo2 } from "../partials/Functions";
import { localeNumberFormat } from "../../../common/Functions";
// import { GENERATE_PDF_REPORT } from "../../../../GraphQL/Mutations";
import { totalEnergyValue } from "../partials/Co2Function";
import CollapseCard from "./CollapseCard";

const Text = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#27272A"
}));

const introContent = (company, gettext) => {
  return (
    <>
      <Text>
        {company &&
          company +
            "'s " +
            gettext(
              "CO₂ report 2020 describes the CO₂ emissions for all the company's sources of supply: water, wastewater, heat and electricity. The CO₂ report makes it possible to follow the CO₂ emissions, and contributes to making decisions on an informed and database basis."
            )}{" "}
      </Text>
      <Text>
        {gettext(
          "The basis for this report is The Greenhouse Gas Protocol, which is an internationally recognized tool for calculating and reporting greenhouse gas emissions. By using The Greenhouse Gas Protocol it is possible to compare with other similar companies, properties and public institutions."
        )}
      </Text>
      <Text>
        {gettext(
          "The last page lists the assumptions and sources on which the calculation of CO₂ consumption is based in this report."
        )}
      </Text>
      <Text>
        {gettext(
          "The report uses the 125% method for emissions, which is prescribed by the Danish Energy Agency."
        )}
      </Text>
    </>
  );
};

const Co2Introduction = props => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { params, energyData, energyLoading } = props;
  const { fromYear, toYear } = params;
  const { getPdfJsonData, isLoading, pdfLoading } = props;
  let company = props.data.customers_names ? props.data.customers_names : "...";
  let address = props.data.buildings ? props.data.buildings : "...";

  const [totalEnergyCo2Year1, setTotalEnergyCo2Year1] = useState(0);
  const [totalEnergyCo2Year3, setTotalEnergyCo2Year3] = useState(0);

  const [snackbarOpen, changeSnackbarOpen] = useState(false);
  const [pdfButtonDisabled, changePdfButtonDisabled] = useState(true);

  useEffect(() => {
    if (energyData && !energyLoading) {
      const result3 = totalEnergyValue(energyData, toYear);
      const result1 = totalEnergyValue(energyData, fromYear);
      setTotalEnergyCo2Year1(result3[1]);
      setTotalEnergyCo2Year3(result1[1]);
    }
  }, [fromYear, toYear, energyData, energyLoading]);

  let totalEnergyCo2Year1Formatted = localeNumberFormat(
    totalEnergyCo2Year1,
    DECIMAL
  );
  let totalEnergyCo2Year3Formatted = localeNumberFormat(
    totalEnergyCo2Year3,
    DECIMAL
  );

  address = "";

  const summaryContent = () => {
    return (
      <>
        <Text>{gettext("Below are the 3 previous years.")}</Text>
        <Text>{gettext("The total CO₂ emissions")}</Text>
        {totalEnergyCo2Year1 < totalEnergyCo2Year3 && (
          <Text>
            {gettext("Total CO₂ emissions fell from")}
            {" " + totalEnergyCo2Year3Formatted + " "}
            {gettext("tonnes of CO₂ in ")}
            {" " + fromYear + " "}
            {gettext("to")}
            {" " + totalEnergyCo2Year1Formatted + " "}
            {gettext("tonnes of CO₂ in ")}
            {toYear}
          </Text>
        )}
        {totalEnergyCo2Year1 > totalEnergyCo2Year3 && (
          <Text>
            {gettext("Total CO₂ emissions increased from")}
            {" " + totalEnergyCo2Year3Formatted + " "}
            {gettext("tonnes of CO₂ in ")}
            {" " + fromYear + " "}
            {gettext("to")}
            {" " + totalEnergyCo2Year1Formatted + " "}
            {gettext("tonnes of CO₂ in ")}
            {" " + toYear}
          </Text>
        )}
        {totalEnergyCo2Year1 === totalEnergyCo2Year3 && (
          <Text>
            {gettext("The total CO₂ emissions have been constant at")}
            {" " + totalEnergyCo2Year3Formatted + " "}
            {gettext("tonnes of CO₂ in the period")}
            {" " + fromYear + " "}
            {gettext("to")}
            {" " + toYear}
          </Text>
        )}
        <Text>
          {gettext(
            "The CO₂ emissions emitted by the individual supply sources are stated under the individual sections for water, electricity, and heat."
          )}
        </Text>
      </>
    );
  };

  useEffect(() => {
    changePdfButtonDisabled(props.showProgress);
  }, [props.showProgress]);

  useEffect(() => {
    changePdfButtonDisabled(pdfLoading);
  }, [pdfLoading]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    changeSnackbarOpen(false);
  };

  const generatePdf = () => {
    getPdfJsonData();
  };

  return (
    <>
      <Grid item container rowGap={3} xs={12}>
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "20px",
                color: "#27272A",
                pl: 1
              }}
            >
              {company + address}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => generatePdf()}
              disabled={pdfButtonDisabled || isLoading}
            >
              <img src="/media/Pdf.png" alt="pdf" />
              <Typography px={1} sx={{ fontWeight: 500, fontSize: "14px" }}>
                Download PDF
              </Typography>

              {(pdfButtonDisabled || isLoading) && (
                <Spinner className="btn-spinner-co2" animation="border" />
              )}
            </Button>
          </Grid>
        </Grid>
        {!isLoading && (
          <>
            <CollapseCard
              title={gettext("Introduction")}
              content={introContent(company, gettext)}
            />

            <CollapseCard
              title={gettext("Summary")}
              content={summaryContent()}
            />
          </>
        )}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Record has saved
        </Alert>
      </Snackbar>
    </>
  );
};

export default Co2Introduction;
