import axios from "axios";
import useSWR from "swr";
import useApiResponse from "../utils/useApiResponse";
import { Cookies } from "react-cookie";

const URI = require("uri-js");

const { REACT_APP_API_URL } = process.env;

const client = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 30000
});

function getHeader(config) {
  const cookies = new Cookies();
  const token = cookies.get("userAuthtoken");

  let urlList = ["/user/login", "/user/register", "/user/forget_password"];
  const currentURL = URI.parse(config.url).path;
  const basicAuthCredentials = btoa("globalgoals:goglobal!");
  if (urlList.includes(currentURL)) {
    return `Basic ${basicAuthCredentials}`;
  } else if (token) {
    return `Bearer ${token}`;
  }
}

client.interceptors.request.use(function(config) {
  config.headers.Authorization = getHeader(config);
  return config;
});

client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      // const cookies = new Cookies();
      // cookies.remove("userAuthtoken");
    }
    return error;
  }
);

export default client;

export function getCacheKey(url, params) {
  const cacheKey = [url];
  if (params) {
    cacheKey.push(JSON.stringify(params));
  }
  return cacheKey;
}

export function useAPIGraph(url, params, config) {
  const { data, mutate } = useSWR(
    getCacheKey(url, params),
    async () => {
      const { data: output } = await client.get(url, { params, ...config });
      output.isLoading = false;
      return output;
    },
    {
      // revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  const cachedResponse = useApiResponse(data);
  return {
    data: cachedResponse || { isLoading: true },
    mutate
  };
}

export function useAPIGraphPost(url, params, config) {
  const { data, mutate } = useSWR(
    getCacheKey(url, params),
    async () => {
      const { data: output } = await client.post(url, {
        addresses: params.addresses,
        customer: params.customer,
        from: params.from,
        resolution: params.resolution,
        to: params.to,
        type: params.type,
        unit: params.unit
      });
      output.isLoading = false;
      return output;
    },
    {
      // revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  const cachedResponse = useApiResponse(data);
  return {
    data: cachedResponse || { isLoading: true },
    mutate
  };
}

export function useAPI(url, params, config) {
  const { data, mutate } = useSWR(getCacheKey(url, params), async () => {
    const { data: output } = await client.get(url, { params, ...config });
    output.isLoading = false;
    return output;
  });

  const cachedResponse = useApiResponse(data);
  return {
    data: cachedResponse || { isLoading: true },
    mutate
  };
}
