import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { headCells } from "./ScrapperLogTable";
import { IoChevronDown } from "react-icons/io5";

export function FilterModal({ open, handleClose, setSeletedFilters }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const [field, setField] = useState("scrapper_name");

  const [operator, setOperator] = useState({
    label: "Contains"
  });
  const [value, setValue] = useState("");

  const containOptions = [
    {
      id: "contains",
      label: "Contains"
    },
    {
      id: "equals",
      label: "Equals"
    },
    {
      id: "startswith",
      label: "Starts With"
    },
    {
      id: "endswith",
      label: "Ends With"
    },
    {
      id: "is_empty",
      label: "Is Empty"
    },
    {
      id: "is_not_empty",
      label: "Is Not Empty"
    },
    {
      id: "is_any_of",
      label: "Is Any Of"
    }
  ];

  const handleSubmit = e => {
    e.preventDefault();
    const filterCriteria = {
      field,
      operator,
      value
    };
    setSeletedFilters(filterCriteria);
    handleClose();
  };
  const handleReset = e => {
    setField("");
    setOperator({});
    setValue("");
    setSeletedFilters(null);
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: theme => theme.zIndex.appBar + 1
      }}
    >
      <IconButton
        style={{
          width: "auto",
          marginLeft: "30px",
          marginTop: "15px",
          marginBottom: "15px",
          marginRight: "auto",
          padding: "6px"
        }}
        onClick={() => handleClose()}
      >
        <ArrowForwardIcon size={16} />
      </IconButton>
      <Divider />
      <Box sx={{ width: "auto", padding: 4 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4} sx={{ height: "100%" }}>
            <Typography fontSize={22} fontWeight={700} marginBottom={5}>
              {gettext("Filters")}
            </Typography>
          </Stack>
          <Stack spacing={4} sx={{ height: "100%" }}>
            <Box sx={{ height: "100%", width: "260px" }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <Typography fontSize={14} fontWeight={500} marginBottom={1}>
                      {gettext("Columns")}
                    </Typography>

                    <Select
                      labelId="column-select-label"
                      id="column-select"
                      IconComponent={IoChevronDown}
                      label=""
                      value={field}
                      displayEmpty={true}
                      fullWidth
                      onChange={e => setField(e.target.value)}
                    >
                      {headCells
                        ?.slice(1) // create a new array that starts from the second element
                        .map(headCell => (
                          <MenuItem key={headCell.id} value={headCell.id}>
                            {headCell.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <Typography fontSize={14} fontWeight={500} marginBottom={1}>
                      {gettext("Operator")}
                    </Typography>
                    <Select
                      labelId="column-select-label"
                      id="column-select"
                      IconComponent={IoChevronDown}
                      label=""
                      displayEmpty={true}
                      value={operator}
                      onChange={e => {
                        setOperator(e.target.value);
                      }}
                      fullWidth

                      // label={gettext("ID")}
                    >
                      {containOptions?.map(containOptions => (
                        <MenuItem value={containOptions.id}>
                          {containOptions.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  hidden={
                    operator === "is_empty" || operator === "is_not_empty"
                  }
                >
                  <FormControl fullWidth size="small">
                    <Typography fontSize={14} fontWeight={500} marginBottom={1}>
                      {gettext("Value")}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        size="small"
                        required={
                          operator !== "is_empty" && operator !== "is_not_empty"
                        }
                        fullWidth
                        autoComplete="off"
                        value={value}
                        onChange={e => {
                          setValue(e.target.value);
                        }}
                      />
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Paper elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Button
                    sx={{
                      color: "#fff",
                      backgroundColor: "#27272A",
                      fontSize: "14px",
                      fontWeight: "500",
                      height: "32px",
                      width: "87px",
                      "&:hover": {
                        backgroundColor: "#27272A"
                      }
                    }}
                    type="submit"
                    variant="outlined"
                  >
                    {gettext("Apply")}
                  </Button>
                </Grid>
                <Grid item xs={4} textAlign="left">
                  <Button
                    sx={{
                      color: "#27272A",
                      borderColor: "#27272A",
                      fontSize: "14px",
                      fontWeight: "500",
                      height: "32px",
                      width: "87px",
                      "&:hover": {
                        borderColor: "#27272A"
                      }
                    }}
                    variant="outlined"
                    onClick={() => handleReset()}
                  >
                    {gettext("Reset")}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
}
