import React, { useEffect, useState } from "react";
import { PerEnergySource } from "./PerEnergySource";
import { useDashboardFilter } from "app/providers";
export function KwhM2() {
  const [buildingString, selectedBuildingString] = useState("");
  const { selectedBuildingsString } = useDashboardFilter();

  useEffect(() => {
    if (selectedBuildingsString.length > 0) {
      selectedBuildingString(selectedBuildingsString);
    }
  }, [selectedBuildingsString]);

  const engConskwhGraph = {
    id: "engConskwhGraph",
    title: "Energy consumption measured in kWh/m2",
    fieldName: "vPerM2"
  };

  const engProkwhGraph = {
    id: "engProkwhGraph",
    title: "Energy production measured in kWh/m2",
    fieldName: "vPerM2"
  };
  const cokwhGraph = {
    id: "cokwhGraph",
    title: "CO₂ eq measured in tons/m2",
    fieldName: "co2PerM2"
  };
  const eleckwhGraph = {
    id: "eleckwhGraph",
    title: "Electricity consumption measured in kWh/m2",
    fieldName: "vPerM2"
  };
  const heatingkwhGraph = {
    id: "heatingkwhGraph",
    title: "Heating consumption measured in kWh/m2",
    fieldName: "vPerM2"
  };
  const waterkwhGraph = {
    id: "waterkwhGraph",
    title: "Water consumption measured in kWh/m2",
    fieldName: "vPerM2"
  };

  return (
    <PerEnergySource
      engConskwhGraph={engConskwhGraph}
      engProkwhGraph={engProkwhGraph}
      cokwhGraph={cokwhGraph}
      heatingkwhGraph={heatingkwhGraph}
      eleckwhGraph={eleckwhGraph}
      waterkwhGraph={waterkwhGraph}
      labels={buildingString.split("|")}
    />
  );
}
