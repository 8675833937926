import { useEpcFilter, useUser } from "app/providers";
import { useMetersData } from "hooks";
import { useState, useCallback } from "react";

export function useEpcData() {
  const [currentYearValue, setCurrentYearValue] = useState(0);

  const [prevYearValue, setPrevYearValue] = useState(0);

  const [prev2YearValue, setPrev2YearValue] = useState(0);

  const {
    selectedBuilding,
    selectedEnergySource,
    selectedYear
  } = useEpcFilter();

  const { user, colors } = useUser();

  const { currentTeam } = user ?? {};

  const getOptions = useCallback(
    year => {
      const getDate = year => {
        const date = new Date(year, 0, 2);
        date.setUTCHours(0, 0);
        return date.toISOString().slice(0, 19);
      };

      return {
        meterType: selectedEnergySource.value,
        meteringPointId: "all",
        address: selectedBuilding.address,
        customer: currentTeam,
        fromDate: getDate(year),
        toDate: getDate(year + 1),
        resolution: "m"
      };
    },
    [currentTeam, selectedBuilding, selectedEnergySource]
  );

  const { loading: currentDataLoading } = useMetersData(
    getOptions(selectedYear.value),
    data => {
      setCurrentYearValue(
        data?.data
          ?.reduce((sum, meterData) => sum + meterData.vPerM2, 0)
          .toFixed(2) ?? 0
      );
    }
  );

  const { loading: prevDataLoading } = useMetersData(
    getOptions(selectedYear.value - 1),
    data => {
      setPrevYearValue(
        data?.data
          ?.reduce((sum, meterData) => sum + meterData.vPerM2, 0)
          .toFixed(2) ?? 0
      );
    }
  );

  const { loading: prev2DataLoading } = useMetersData(
    getOptions(selectedYear.value - 2),
    data => {
      setPrev2YearValue(
        data?.data
          ?.reduce((sum, meterData) => sum + meterData.vPerM2, 0)
          .toFixed(2) ?? 0
      );
    }
  );

  return {
    loading: currentDataLoading || prevDataLoading || prev2DataLoading,
    catagories: [
      selectedYear.value,
      selectedYear.value - 1,
      selectedYear.value - 2
    ],
    values: [currentYearValue, prevYearValue, prev2YearValue],
    colors: [colors[selectedEnergySource.value]?.shade1?.background] ?? [
      "#485561"
    ]
  };
}
