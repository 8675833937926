import {
  Avatar,
  Box,
  Chip,
  Divider,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useContext } from "react";
import ProfileMenu from "./components/profileMenu";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import { useUser, useTextdomainContext } from "app/providers";

export function Profile() {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { user } = useUser();
  const { isVerified } = user || {};

  const { profilePhotoUrl } = user || {};

  const [photo, setPhoto] = React.useState("");

  React.useEffect(() => {
    profilePhotoUrl && setPhoto(profilePhotoUrl);
  }, [profilePhotoUrl]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box>
        <Tooltip title="Account settings">
          <ListItemButton onClick={handleClick}>
            <Avatar alt="" src={photo} />

            <div className="d-flex flex-column mx-2 text-left">
              <Typography
                variant="body1"
                color="grey"
                className="font-weight-bold"
              >
                {user?.name}
              </Typography>
            </div>
          </ListItemButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          style: {
            width: 400
          },
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0,
            "& .MuiAvatar-root": {
              width: 82,
              height: 82,
              ml: 0,
              mr: 0
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 100,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={{
            height: 150,
            // width: 330,
            overflow: "auto",
            cursor: "auto",
            background: "#F5F7F9",
            display: "block"
          }}
        >
          <div className="d-flex flex-row ">
            <Toolbar>
              <Box>
                <Avatar alt="avatar" src={photo} />
              </Box>
            </Toolbar>

            <Box>
              <Typography
                variant="h5"
                color="grey"
                className="font-weight-bold"
              >
                {user?.name}
              </Typography>
              <div className="d-flex flex-wrap">
                {user?.roles?.map((role, index) => (
                  <Chip
                    className="m-1"
                    variant="outlined"
                    size="small"
                    label={role}
                    key={`Chip-${role}`}
                  />
                ))}
              </div>
              {isVerified ? (
                <Box>
                  <ListItemIcon>
                    <EmailIcon fontSize="small" className="mt-3" />
                  </ListItemIcon>
                  {user?.email}
                </Box>
              ) : (
                <>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ListItemIcon>
                      <EmailIcon fontSize="small" />
                    </ListItemIcon>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography>{user?.email}</Typography>
                      <Typography sx={{ color: "red", fontSize: 12 }}>
                        {gettext("Email Not Verified")}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}

              <Box>
                <ListItemIcon>
                  <LocationOnIcon fontSize="small" />
                </ListItemIcon>
                {user?.countryCode}
              </Box>
            </Box>
          </div>
        </MenuItem>

        <Divider />

        <ProfileMenu />
      </Menu>
    </>
  );
}
