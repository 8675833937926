import React, { useContext } from "react";
import PersonIcon from "@mui/icons-material/Person";
import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import LockResetIcon from "@mui/icons-material/LockReset";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import { useTextdomainContext } from "app/providers";
import { CanDisplay } from "app/common/Methods";
import { ProfileMenuItem } from "./components";

export default function ProfileMenu() {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  return (
    <>
      <ProfileMenuItem
        link={"/user-profile/personal-information"}
        icon={<PersonIcon />}
        label={gettext("Profile")}
        desc={gettext("My information")}
        code="my-profile"
      />

      <ProfileMenuItem
        link={"/user-profile/change-password"}
        icon={<LockResetIcon />}
        label={gettext("Change Password")}
        desc={gettext("You can change your password here")}
        code="my-password"
      />

      <ProfileMenuItem
        link={"/user-profile/customers"}
        icon={<PeopleAltIcon />}
        label={gettext("Companies")}
        desc={gettext("Companies")}
        code="my-customers"
      />

      {CanDisplay("TEAM_SETTING") && (
        <ProfileMenuItem
          link={"/user-profile/customer-roles"}
          icon={<SettingsAccessibilityIcon />}
          label={gettext("Company Roles")}
          desc={gettext(
            "Add, edit, delete users from the current organization"
          )}
        />
      )}

      <ProfileMenuItem
        link={"/user-profile/settings"}
        icon={<Settings />}
        label={gettext("Settings")}
        desc={"You can change user color settings"}
      />

      <ProfileMenuItem
        link={"/logout"}
        icon={<Logout />}
        label={gettext("Sign Out")}
        desc={null}
      />
    </>
  );
}
