import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTextdomainContext } from "app/providers";
import { useGetPlanLinkCsvById } from "./hooks";

export function ViewAccountPlan() {
  const { planId } = useParams();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { plainLinkCsvURL, plainLinkName } = useGetPlanLinkCsvById(planId);

  const [planName, setplanName] = useState("");

  useEffect(() => {
    setplanName(plainLinkName);
  }, [plainLinkName]);

  const getCSVPlan = () => {
    plainLinkCsvURL && window.open(plainLinkCsvURL);
  };

  return (
    <div className="card card-custom gutter-b example example-compact">
      <div className="add-plan-container">
        {/* <CustomModal show={this.state.showModal} handleClose={this.hideModal} > */}
        <div className="modal-title">{gettext("View Plan")}</div>
        <div className="modal-name-tf-container">
          <input
            disabled
            placeholder={gettext("Enter Name")}
            className={"modal-name-tf"}
            name="Name"
            value={gettext(planName)}
          />
        </div>

        <div>
          <div>{gettext("CSV file")}</div>
          <button
            className={"modal-upload"}
            onClick={getCSVPlan}
            disabled={!plainLinkCsvURL}
          >
            {gettext("Get CSV file")}
          </button>
        </div>
      </div>
    </div>
  );
}
