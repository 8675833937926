import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { injectIntl } from "react-intl";
import Icon from "@mdi/react";
import {
  mdiKeyVariant,
  mdiEmail,
  mdiAccount,
  mdiEyeOffOutline,
  mdiEyeOutline
} from "@mdi/js";
import { convertErrorObject } from "../_redux/authCrud";
import ReactGA from "react-ga4";
// import { PublicClientApplication } from "@azure/msal-browser";
// import { useAuth } from "app/providers";
import { useSubmit } from "./hooks";
import { useCountries, useTimezones } from "hooks";
import { getGeoInfo } from "app/common/Functions";
import detectBrowserLanguage from "detect-browser-language";

const { REACT_APP_URL } = process.env;

const initialValues = {
  name: "",
  email: "",
  // username: "",
  password: "",
  password_confirmation: "",
  acceptTerms: false
};

export const MicrosoftConfig = {
  clientId: "76a50c64-cedf-4e7f-922f-16151c244a04",
  authority: "https://login.microsoftonline.com/organizations",
  redirectUri: REACT_APP_URL,
  scopes: ["user.read"]
};

function Registration(props) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { intl } = props;

  const location = useLocation();

  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const [status, setStatus] = useState(null);

  const { submit, isSubmitting } = useSubmit(
    () => {},
    error => {
      setStatus(convertErrorObject(error.data));
    }
  );

  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [props, location.pathname]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MIN_LENGTH" },
          { name: gettext("Full Name"), min: 3 }
        )
      )
      .max(
        255,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH" },
          { name: gettext("Full Name"), max: 255 }
        )
      )
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.REQUIRED" },
          { name: gettext("Full Name") }
        )
      ),
    email: Yup.string()
      .email(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.INVALID" },
          { name: gettext("Email") }
        )
      )
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.REQUIRED" },
          { name: gettext("Email") }
        )
      ),
    password: Yup.string()
      .min(
        3,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MIN_LENGTH" },
          { name: gettext("Password"), min: 3 }
        )
      )
      .max(
        50,
        intl.formatMessage(
          { id: "AUTH.VALIDATION.MAX_LENGTH" },
          { name: gettext("Password"), max: 50 }
        )
      )
      .required(
        intl.formatMessage(
          { id: "AUTH.VALIDATION.REQUIRED" },
          { name: gettext("Password") }
        )
      ),
    password_confirmation: Yup.string().test(
      "passwords-match",
      gettext("Passwords must match"),
      function(value) {
        return this.parent.password === value;
      }
    )
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const { countries } = useCountries();

  const { timezones } = useTimezones();

  const [inputCountry, setinputCountry] = useState("DK");

  const [inputTimezone, setinputTimezone] = useState("Europe/Copenhagen");

  useEffect(() => {
    getGeoInfo().then(ipData => {
      const country = countries.find(country => {
        return country.code === ipData.country_code;
      });

      if (country) {
        setinputCountry(country.code);
      } else {
        setinputCountry("DK");
      }

      const timezone = timezones.find(timezone => {
        return timezone.zoneName === ipData.timezone;
      });

      if (timezone) {
        setinputTimezone(timezone.zoneName);
      } else {
        setinputTimezone("Europe/Copenhagen");
      }
    });
  }, [countries, timezones]);

  const formik = useFormik({
    initialValues,
    validationSchema, //: RegistrationSchema,

    onSubmit: (values, { setStatus, setSubmitting }) => {
      submit({
        email: values.email,
        name: values.name,
        password: values.password,
        countryCode: inputCountry,
        timezone: inputTimezone,
        lang: detectBrowserLanguage()?.split("-")[0] || "en",
        customerType: "company",
        token
      });
    }
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <div className="login-form login-signin" id="kt_login_signin_form">
        {/* begin::Head */}
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-sm-12 col-md-12">
            <div className="text-center login-title p-4">
              <h3 className="font-size-h1">{gettext("Sign up to Fabeke")}</h3>
            </div>
          </div>
          <div className="col-sm-12 col-md-5 email-login">
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework"
            >
              {status ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{status}</div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="form-group fv-plugins-icon-container">
                    <div className="email-icon">
                      <Icon
                        path={mdiAccount}
                        title={gettext("Full Name")}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#7a6969"
                      />
                    </div>
                    <input
                      placeholder={gettext("Full Name")}
                      type="text"
                      className={
                        "form-control form-control-solid input-special h-auto py-2 px-6 " +
                        getInputClasses("name")
                      }
                      name="name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.name}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="form-group fv-plugins-icon-container">
                    <div className="email-icon">
                      <Icon
                        path={mdiEmail}
                        title={gettext("Email")}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#7a6969"
                      />
                    </div>
                    <input
                      placeholder={gettext("Email")}
                      type="email"
                      className={
                        "form-control form-control-solid input-special h-auto py-2 px-6 " +
                        getInputClasses("email")
                      }
                      name="email"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.email}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="form-group fv-plugins-icon-container">
                    <div className="email-icon">
                      <Icon
                        path={mdiKeyVariant}
                        title={gettext("Password")}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#7a6969"
                      />
                    </div>
                    <input
                      placeholder={gettext("Password")}
                      type={passwordShown ? "text" : "password"}
                      className={
                        "form-control form-control-solid input-special h-auto py-2 px-6 " +
                        getInputClasses("password")
                      }
                      name="password"
                      {...formik.getFieldProps("password")}
                    />
                    <button
                      type="button"
                      className="show-hide-password"
                      onClick={togglePassword}
                    >
                      <Icon
                        path={passwordShown ? mdiEyeOutline : mdiEyeOffOutline}
                        title="Password"
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#7a6969"
                      />
                    </button>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.password}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="form-group fv-plugins-icon-container">
                    <div className="email-icon">
                      <Icon
                        path={mdiKeyVariant}
                        title={gettext("Confirm Password")}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#7a6969"
                      />
                    </div>
                    <input
                      placeholder={gettext("Confirm Password")}
                      type={passwordShown ? "text" : "password"}
                      className={
                        "form-control form-control-solid input-special h-auto py-2 px-6 " +
                        getInputClasses("password_confirmation")
                      }
                      name="password_confirmation"
                      {...formik.getFieldProps("password_confirmation")}
                    />
                    {formik.touched.password_confirmation &&
                    formik.errors.password_confirmation ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.password_confirmation}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <div>
                      <input
                        type="checkbox"
                        name="acceptTerms"
                        className="m-1"
                        {...formik.getFieldProps("acceptTerms")}
                      />
                      {gettext("I agree with the")}{" "}
                      <a href="/terms" target="_blank">
                        {gettext("Terms & Conditions")}
                      </a>{" "}
                      {gettext("and")}{" "}
                      <a href="/privacy" target="_blank">
                        {gettext("privacy policy")}
                      </a>
                    </div>
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !formik.isValid ||
                        !formik.values.acceptTerms
                      }
                      className={`btn btn-primary font-weight-bold px-9 my-3 btn-block`}
                    >
                      <span>{gettext("Sign Up")}</span>
                      {/* {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )} */}
                    </button>
                  </div>
                  <hr />
                  <div>
                    {gettext("Already have an account?")}
                    <Link
                      to="/auth/login"
                      className="text-dark-50 text-primary my-5 mr-2"
                      id="kt_signup_submit"
                    >
                      &nbsp;
                      {gettext("Sign In")}
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* Commented Code below is for options to Sign up with OAUTH , Facebook , Google , MicrosoftAccount */}

          {/* <div className="col-sm-12 col-md-1 seperator">
            <img
              src="/media/bg/login_or.png"
              alt="OR"
              className="float-right"
            />
          </div>
          <div className="col-sm-12 col-md-5 social-login">
            <div className={"facebook-btn-container"}>
              <FacebookLogin
                appId={REACT_APP_APP_ID_FACEBOOK}
                onSuccess={responseFacebook}
                btnText={gettext("Sign in with Facebook")}
                // onFailure={onFailure}
              />
            </div>
            <div>
              <img
                src="/media/social/google.png"
                className="img-responsive float-left google-icon"
                alt="Google"
              />
              <GoogleLogin
                clientId={REACT_APP_CLIENT_ID_GOOGLE}
                buttonText={gettext("Sign in with Google")}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
                className={"btn-google"}
                theme={"dark"}
              />
            </div>
            <br />
            <button
              className="btn btn-info btn-block btn-microsoft"
              type="button"
              onClick={handleMicrosoftAction}
            >
              <FaMicrosoft /> <div>{gettext("Sign in with Microsoft")}</div>
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default injectIntl(Registration);
