import React, { Component } from "react";
// import '../../App.css';
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  LayersControl,
  LayerGroup,
  useMap
} from "react-leaflet";
import { injectIntl } from "react-intl";

class OpenStreetMap extends Component {
  #mapCenter;

  constructor(props) {
    super(props);
    this.intl = props.intl;
    this.#mapCenter = [55.24545475, 9.48847176];
  }

  renderMarker = type => {
    let markers = [];
    let mapCenter = this.#mapCenter;
    if (this.props.data && this.props.data.data) {
      markers = this.props.data.data.map(function(item) {
        mapCenter = item.latlon;
        return type === item.layer ? (
          <Marker position={item.latlon} riseOnHover={true} key={item.title}>
            <Tooltip>
              {
                <p>
                  <b>{item.title}</b>
                  <br />
                  {item.text}
                </p>
              }
            </Tooltip>
          </Marker>
        ) : (
          <div style={{ width: 0, height: 0 }} key={"div-" + item.title} />
        );
      });
    }
    this.#mapCenter = mapCenter;
    return markers;
  };

  getBounds = () => {
    let allBounds = [];
    // console.log(this.props);
    if (this.props.data && this.props.data.data) {
      this.props.data.data.forEach(bound => {
        allBounds.push(bound.latlon);
      });
    }

    if (allBounds.length) {
      return [allBounds];
    } else {
      return [[[0, 0]]];
    }
  };

  getLayerCenter = () => {
    let allBounds = [];
    let keyCount = 0;
    if (this.props.data && this.props.data.data) {
      this.props.data.data.forEach(bound => {
        let obj1 = (
          <LayersControl.Overlay checked name={bound.title} key={keyCount++}>
            <LayerGroup>{this.renderMarker(bound.title)}</LayerGroup>
          </LayersControl.Overlay>
        );
        allBounds.push(obj1);
      });
    }

    return [allBounds];
  };

  openstreetMap = () => {
    const outerBounds = this.getBounds();
    const innerLayerCenter = this.getLayerCenter();
    function SetViewOnClick({ coords }) {
      const map = useMap();
      map.setView(coords, map.getZoom());

      return null;
    }

    return (
      <MapContainer
        id="leaflet-container"
        scrollWheelZoom={false}
        bounds={outerBounds}
        // center={this.state.mapCenter}
      >
        <LayersControl position="topright">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // url='https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png'
          />

          {innerLayerCenter}

          {/*<LayersControl.Overlay checked name="All">*/}
          {/*  <LayerGroup>{this.renderMarker("all")}</LayerGroup>*/}
          {/*</LayersControl.Overlay>*/}

          {/*<LayersControl.Overlay checked name="Headquarter">*/}
          {/*  <LayerGroup>{this.renderMarker("hq")}</LayerGroup>*/}
          {/*</LayersControl.Overlay>*/}

          {/*<LayersControl.Overlay checked name="School">*/}
          {/*  <LayerGroup>{this.renderMarker("school")}</LayerGroup>*/}
          {/*</LayersControl.Overlay>*/}
        </LayersControl>
        <SetViewOnClick coords={this.#mapCenter} />
      </MapContainer>
    );
  };

  render() {
    return <div>{this.openstreetMap()}</div>;
  }
}

export default injectIntl(OpenStreetMap);
