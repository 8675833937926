import React, { useCallback, useContext, useEffect } from "react";
import { Grid, Skeleton } from "@mui/material";
import {
  useDashboardFilter,
  useTextdomainContext,
  useUser
} from "app/providers";
import { useBroadCastDashboard } from "hooks/useDashboardData";
import * as Constants from "../../../../common/Constants";
import ConPerSourceGraphs from "../ConPerSourceGraphs";
import { ChartContainer, NoBuildingWarning } from "app/components";

export function PerEnergySource(props) {
  //props//////
  const {
    engConskwhGraph,
    engProkwhGraph,
    cokwhGraph,
    eleckwhGraph,
    heatingkwhGraph,
    waterkwhGraph,
    labels
  } = props;

  // component state ///

  const [
    energyConsumptionGraphData,
    setEnergyConsumptionGraphData
  ] = React.useState([]);
  const [
    energyProductionGraphData,
    setEnergyProductionGraphData
  ] = React.useState([]);
  const [coConsumptionGraphData, setCoConsumptionGraphData] = React.useState(
    []
  );
  const [
    elecConsumptionGraphData,
    setElecConsumptionGraphData
  ] = React.useState([]);
  const [graphColors, setGraphColors] = React.useState({});
  const [
    heatingConsumptionGraphData,
    setHeatingConsumptionGraphData
  ] = React.useState([]);
  const [
    waterConsumptionGraphData,
    setWaterConsumptionGraphData
  ] = React.useState([]);

  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  // custom hook
  const {
    selectedCompaniesString,
    selectedBuildingsString,
    fromDate,
    toDate
  } = useDashboardFilter();

  const { colors } = useUser();

  let getMonthNames = (month, gettext) => {
    switch (month) {
      case 0:
        return gettext("Jan");

      case 1:
        return gettext("Feb");

      case 2:
        return gettext("Mar");

      case 3:
        return gettext("Apr");

      case 4:
        return gettext("May");

      case 5:
        return gettext("Jun");

      case 6:
        return gettext("Jul");
      case 7:
        return gettext("Aug");
      case 8:
        return gettext("Sep");
      case 9:
        return gettext("Oct");
      case 10:
        return gettext("Nov");

      case 11:
        return gettext("Dec");

      default:
        return "";
    }
  };

  const params = type => {
    return {
      sourceType: type,
      customers: selectedCompaniesString,
      addresses: selectedBuildingsString,
      fromDate,
      toDate,
      resolution: "m",
      unit: "all"
    };
  };
  const { data: energyData, loading: energyLoading } = useBroadCastDashboard(
    params("energy")
  );

  const { data: electricityData, loading: eLoading } = useBroadCastDashboard(
    params("electricity")
  );

  const { data: waterData, loading: wLoading } = useBroadCastDashboard(
    params("water")
  );

  const { data: heatingData, loading: hLoading } = useBroadCastDashboard(
    params("heating")
  );

  const getEnergyData = useCallback(
    (energyData, fieldName) => {
      let graphData = [];
      let monthNames = [];
      if (energyData && Object.keys(energyData).length) {
        energyData?.data.forEach((data, index) => {
          graphData.push(
            data[fieldName] ? parseFloat(data[fieldName]).toFixed(7) : 0
          );
          let monthName = getMonthNames(new Date(data.ts).getMonth(), gettext);
          let year = new Date(data.ts).getFullYear();

          monthNames.push(monthName + " " + year);
        });
      }
      return { graphData, monthNames };
    },
    [gettext]
  );

  const picGraphColor = useCallback((apiColor, constantColor) => {
    if (apiColor) {
      return apiColor;
    } else {
      return constantColor;
    }
  }, []);

  const getUserColor = useCallback(
    (type, constantColor) => {
      let userColor = colors?.[type]?.shade1?.background;

      if (userColor) {
        return userColor;
      } else {
        return constantColor;
      }
    },
    [colors]
  );

  const getChartData = useCallback(
    (energyData, fieldName, energyGraphColor, constantColor) => {
      let calculatedGraphData = [];

      if (energyData && energyData.length) {
        energyData.forEach((eData, index) => {
          let eGraphdata = getEnergyData(eData, fieldName);
          calculatedGraphData.push({
            name: eData?.address,
            data: eGraphdata.graphData,
            categories: eGraphdata.monthNames
          });
        });
      }

      let energyColor = picGraphColor(
        energyData?.colorSettings?.[energyGraphColor]?.background,
        getUserColor(energyGraphColor, constantColor)
      );

      setGraphColors(prev => {
        return {
          ...prev,
          [energyGraphColor]: [energyColor]
        };
      });

      return calculatedGraphData;
    },
    [getEnergyData, getUserColor, picGraphColor]
  );

  useEffect(() => {
    let energyConsumptionGraphData = [];
    let energyProductionGraphData = [];
    let coConsumptionGraphData = [];
    let elecConsumptionGraphData = [];
    let heatingConsumptionGraphData = [];
    let waterConsumptionGraphData = [];

    if (energyLoading && eLoading && wLoading && hLoading) {
      return;
    } else if (energyData && electricityData && waterData && heatingData) {
      energyConsumptionGraphData = getChartData(
        energyData,
        engConskwhGraph.fieldName,
        "energy",
        Constants.ENERGY_COLOR
      );

      energyProductionGraphData = getChartData(
        energyData,
        engProkwhGraph.fieldName,
        "energy",
        Constants.ENERGY_COLOR
      );
      coConsumptionGraphData = getChartData(
        energyData,
        cokwhGraph.fieldName,
        "co2",
        Constants.CO2_COLOR
      );
      elecConsumptionGraphData = getChartData(
        electricityData,
        eleckwhGraph.fieldName,
        "electricity",
        Constants.ELECTRICITY_COLOR
      );
      heatingConsumptionGraphData = getChartData(
        heatingData,
        heatingkwhGraph.fieldName,
        "heating",
        Constants.HEAT_COLOR
      );
      waterConsumptionGraphData = getChartData(
        waterData,
        waterkwhGraph.fieldName,
        "water",
        Constants.WATER_COLOR
      );
    }
    setEnergyConsumptionGraphData(energyConsumptionGraphData);
    setEnergyProductionGraphData(energyProductionGraphData);
    setCoConsumptionGraphData(coConsumptionGraphData);
    setElecConsumptionGraphData(elecConsumptionGraphData);
    setHeatingConsumptionGraphData(heatingConsumptionGraphData);
    setWaterConsumptionGraphData(waterConsumptionGraphData);
  }, [
    getChartData,
    engConskwhGraph,
    engProkwhGraph,
    cokwhGraph,
    eleckwhGraph,
    heatingkwhGraph,
    waterkwhGraph,
    electricityData,
    waterData,
    energyData,
    energyLoading,
    heatingData,
    eLoading,
    wLoading,
    hLoading,
    gettext,
    selectedBuildingsString
  ]);

  ////show loader

  const showLoader = () => {
    return (
      <>
        <ChartContainer>
          <Skeleton variant="rectangular" width="35%" height="10%" />
          <br></br>
          <Skeleton variant="rectangular" width="100%" height="85%" />
        </ChartContainer>
      </>
    );
  };

  return (
    <>
      {selectedBuildingsString.length ? (
        <>
          <Grid item container direction="row" columnSpacing={2}>
            <Grid item xs={4}>
              {!energyLoading ? (
                <ConPerSourceGraphs
                  uniqueId={engConskwhGraph.id}
                  data={energyConsumptionGraphData}
                  labels={labels}
                  title={gettext(engConskwhGraph.title)}
                  graphColors={graphColors?.energy ? graphColors?.energy : []}
                  fontSize="14px"
                />
              ) : (
                showLoader()
              )}
            </Grid>
            <Grid item xs={4}>
              {!energyLoading ? (
                <ConPerSourceGraphs
                  uniqueId={engProkwhGraph.id}
                  data={energyProductionGraphData}
                  labels={labels}
                  title={gettext(engProkwhGraph.title)}
                  graphColors={graphColors?.energy ? graphColors?.energy : []}
                  fontSize="14px"
                />
              ) : (
                showLoader()
              )}
            </Grid>
            <Grid item xs={4}>
              {!energyLoading ? (
                <ConPerSourceGraphs
                  uniqueId={cokwhGraph.id}
                  data={coConsumptionGraphData}
                  labels={labels}
                  title={gettext(cokwhGraph.title)}
                  graphColors={graphColors?.co2 ? graphColors?.co2 : []}
                  fontSize="14px"
                />
              ) : (
                showLoader()
              )}
            </Grid>
          </Grid>
          <Grid item container direction="row" columnSpacing={2} marginTop={1}>
            <Grid item xs={4}>
              {!eLoading ? (
                <ConPerSourceGraphs
                  uniqueId={eleckwhGraph.id}
                  data={elecConsumptionGraphData}
                  labels={labels}
                  title={gettext(eleckwhGraph.title)}
                  fontSize="14px"
                  graphColors={
                    graphColors?.electricity ? graphColors?.electricity : []
                  }
                />
              ) : (
                showLoader()
              )}
            </Grid>
            <Grid item xs={4}>
              {!hLoading ? (
                <ConPerSourceGraphs
                  uniqueId={heatingkwhGraph.id}
                  data={heatingConsumptionGraphData}
                  labels={labels}
                  title={gettext(heatingkwhGraph.title)}
                  fontSize="14px"
                  graphColors={graphColors?.heating ? graphColors?.heating : []}
                />
              ) : (
                showLoader()
              )}
            </Grid>
            <Grid item xs={4}>
              {!wLoading ? (
                <ConPerSourceGraphs
                  uniqueId={waterkwhGraph.id}
                  data={waterConsumptionGraphData}
                  labels={labels}
                  title={gettext(waterkwhGraph.title)}
                  fontSize="14px"
                  graphColors={graphColors?.water ? graphColors?.water : []}
                />
              ) : (
                showLoader()
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <NoBuildingWarning />
      )}
    </>
  );
}
