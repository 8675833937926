import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { EDIT_METER } from "./commands";

export function useUpdateMeter(onCompleted = () => {}, onError = () => {}) {
  const [updateMeter, { loading }] = useMutation(EDIT_METER, {
    onCompleted,
    onError,
    refetchQueries: ["getBuildingMeters"]
  });

  const update = useCallback(
    variables => {
      updateMeter({
        variables
      });
    },
    [updateMeter]
  );

  return {
    update,
    loading
  };
}
