import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AcquireLogin, RequirePermission } from "./components";
import {
  Energy,
  Electricity,
  Heat,
  Water,
  NewCo2Report,
  EnergyComparison,
  NewReport,
  EnergyProcesses,
  Purchase,
  Transport,
  WasteRecycling,
  SoldProducts,
  Transactions,
  AddTransactions,
  ClimateReport,
  AccountSettings,
  Gas,
  DataHubRedirectFlow,
  PublicFacingPage,
  PublicFacingEnergyPricesPage,
  RedirectFlow,
  GWater,
  GElectricity,
  GHeating,
  ErrorPage1,
  ErrorPage2,
  ErrorPage3,
  ErrorPage4,
  ErrorPage5,
  ErrorPage6,
  PersonaInformation,
  UserSettings,
  ChangePassword,
  Customers,
  ForgotPassword,
  RecoverPassword,
  Logout,
  ProcurementElectricity
} from "app/modules";

import {
  AccountFileDataList,
  AddEditPlan,
  AddMeterData,
  BuildingMeters,
  CustomerBuildings,
  PartnerMemberspage,
  PermissionsManagement,
  UnAuthorized,
  UserCustomer,
  UserRoleUpdate,
  ViewAccountPlan
} from "app/elements";
import {
  AccessFromPartnerWebsite,
  AcceptSocialLogin,
  AccountChartCreate,
  AccountChartEdit,
  AccountChartList,
  AccountGroupCreate,
  AccountGroupEdit,
  AccountGroupList,
  AccountLink,
  ActiveUserList,
  AddAccountsData,
  AdminEnergySupplier,
  Administration,
  AdminUserList,
  AdminDefaultSettings,
  AdminServiceProviders,
  Benchmark,
  BuilderPage,
  ConfirmEmailRedirectFlow,
  CustomerRoles,
  Dashboard,
  DataLink,
  EmissionFactorsCreate,
  EmissionFactorsEdit,
  EmissionFactorsList,
  EmissionGroups,
  Epc,
  GhgResult,
  Login,
  MapBox,
  MyPage,
  Notification,
  PartnerMembersList,
  PartnersList,
  PartnerAccess,
  Register,
  RegistrationFlow,
  RoleManagement,
  // ScrapperList,
  ScrapperLog,
  Translation,
  UserCustomersPage,
  VerificationEmailSent,
  ScraperList
} from "app/pages";
import { AppLayout, AuthLayout, HeaderLayout } from "app/layouts";
import { AddMeterForm } from "app/features";
import APIKeyPage from "app/modules/UserProfile/APIKeyPage";
import APIDocsPage from "app/modules/UserProfile/APIDocsPage";
import Privacy from "../../app/modules/Guest/pages/Privacy";
import Terms from "../../app/modules/Guest/pages/Terms";
import EnergyPrices from "app/pages/energyPrices/EnergyPrices";
import { useCustomerPermission } from "app/providers";
import Prices from "app/pages/energyPrices/Prices";
import PredictionElectricity from "app/modules/Reports/pages/PredictionElectricity";
import Datahub from "app/pages/support/Datahub";
import { ResetPassword } from "app/modules/Auth/ResetPassword";
import { Token } from "../pages/Login/Token";

export default function AppRoutes() {
  // const [supportAccess, setSupportAccess] = useState(true);
  // const { user } = useUser();
  const { canRead } = useCustomerPermission();
  // useEffect(() => {
  //     if (
  //         user &&
  //         !user?.isSuperAdmin &&
  //         user?.email &&
  //         user?.email?.split("@")[1] !== "fabeke.com"
  //     ) {
  //         setSupportAccess(false);
  //     }
  // }, [user, canRead]);

  return (
    <Routes>
      {/*<Route path="/" element={<Navigate to="/dashboard" />} />*/}
      <Route path="/" element={<Navigate to="/procurement/electricity" />} />

      <Route path="/auth" element={<Navigate to="/auth/login" />} />

      <Route path="/register/flow" element={<Navigate to="/dashboard" />} />

      <Route
        path="/user-profile"
        element={<Navigate to="/user-profile/personal-information" />}
      />

      <Route element={<HeaderLayout />}>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Route>

      <Route path="/r/gg/:customerKey" element={<RedirectFlow />} />

      <Route path="/r/:type/gg/:customerKey" element={<RedirectFlow />} />

      <Route element={<HeaderLayout />}>
        <Route
          path="/public/budget/prices/electricity"
          element={<PublicFacingEnergyPricesPage />}
        />
      </Route>

      <Route
        path="/public/:customerId/dashboard/:dashboardKey"
        element={<PublicFacingPage />}
      />

      <Route path="/partneraccess/:token" element={<PartnerAccess />} />

      <Route path="/social/login" element={<AcceptSocialLogin />} />

      <Route path="/confirm_email/" element={<ConfirmEmailRedirectFlow />} />

      <Route path="/error/error-v1" element={<ErrorPage1 />} />

      <Route path="/error/error-v2" element={<ErrorPage2 />} />

      {/* <Route path="/error/error-v3" element={<ErrorPage3 />} /> */}

      <Route path="/error/error-v4" element={<ErrorPage4 />} />

      <Route path="/error/error-v5" element={<ErrorPage5 />} />

      <Route path="/error/error-v6" element={<ErrorPage6 />} />

      {/* Routes outside of Authentication */}
      <Route element={<AuthLayout />}>
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/token" element={<Token />} />
        <Route path="/reset_password/" element={<ResetPassword />} />

        <Route path="/auth/registration" element={<Register />} />

        <Route path="/auth/forgot-password" element={<ForgotPassword />} />

        <Route path="/reset_password/:code" element={<RecoverPassword />} />
      </Route>

      {/* Routes Inside of Authentication */}
      <Route element={<AcquireLogin />}>
        <Route path="/logout" element={<Logout />} />

        <Route path="/registration-flow" element={<RegistrationFlow />} />
        <Route path="/verification-email" element={<VerificationEmailSent />} />

        {/* Routes inside of App layout */}
        <Route element={<AppLayout />}>
          <Route path="/unauthorized" element={<UnAuthorized />} />

          <Route path="/error/error-v3" element={<ErrorPage3 />} />

          {/* Dashboard Menu Routes */}
          <Route element={<RequirePermission code={"dashboard"} />}>
            <Route path="/dashboard-old" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/budget" element={<Prices />}>
              <Route
                path=":energyType/prices/:country/:date"
                element={<EnergyPrices />}
              />
              <Route
                path=":energyType/prices/:country"
                element={<EnergyPrices />}
              />
            </Route>
          </Route>

          {/* Building Menu Routes */}
          <Route element={<RequirePermission code={"building-energy"} />}>
            <Route path="/buildings/energy" element={<Energy />} />
          </Route>

          <Route element={<RequirePermission code={"building-electricty"} />}>
            <Route path="/buildings/electricity" element={<Electricity />} />
          </Route>

          <Route element={<RequirePermission code={"building-heating"} />}>
            <Route path="/buildings/heating" element={<Heat />} />
          </Route>

          <Route element={<RequirePermission code={"building-water"} />}>
            <Route path="/buildings/water" element={<Water />} />
          </Route>

          <Route element={<RequirePermission code={"building-gas"} />}>
            <Route path="/buildings/gas" element={<Gas />} />
          </Route>

          <Route element={<RequirePermission code={"building-epc"} />}>
            <Route path="/buildings/epc" element={<Epc />} />
          </Route>

          <Route element={<RequirePermission code={"building-co2-report"} />}>
            <Route path="/buildings/co2-report" element={<NewCo2Report />} />
          </Route>

          {/* <Route element={<RequirePermission code={"building-co2-report"} />}>
            <Route
              path="/buildings/new-co2-report"
              element={<NewCo2Report />}
            />
          </Route> */}
          {/* <Route element={<RequirePermission code={"building-co2-report"} />}>
            <Route
              path="/buildings/new-co2-report"
              element={<NewCo2Report />}
            />
          </Route> */}

          <Route element={<RequirePermission code={"building-benchmark"} />}>
            <Route path="/buildings/benchmark" element={<Benchmark />} />
          </Route>

          <Route element={<RequirePermission code={"building-electricty"} />}>
            <Route
              path="/procurement/electricity"
              element={<ProcurementElectricity />}
            />
          </Route>

          {/* <Route path="/notifications" element={<Notification />} /> */}

          {/* {/ my-account=>notifications /} */}
          <Route
            element={<RequirePermission code={"my-account-notifications"} />}
          >
            <Route path="/notifications" element={<Notification />} />
          </Route>

          {canRead("administration") && (
            <>
              <Route
                element={<RequirePermission code={"building-administration"} />}
              >
                <Route
                  path="/buildings/administration"
                  element={<UserCustomersPage />}
                />
              </Route>

              <Route
                element={<RequirePermission code={"building-administration"} />}
              >
                <Route
                  path="/buildings/administration/:cid/buildings"
                  element={
                    <Administration prevUrl={window.location.pathname} />
                  }
                />
              </Route>

              <Route
                element={<RequirePermission code={"building-administration"} />}
              >
                <Route
                  // path="/buildings/administration/:bid/meters/:type"
                  // path="/buildings/administration/customers/:cid/buildings/:bid/meters/:type"
                  path="/buildings/administration/:cid/buildings/:bid/meters/:type"
                  element={
                    <BuildingMeters prevUrl={window.location.pathname} />
                  }
                />
              </Route>

              <Route
                element={<RequirePermission code={"building-administration"} />}
              >
                <Route
                  // path="/buildings/administration/:bid/meters/:type/add"
                  path="/buildings/administration/:cid/buildings/:bid/meters/:type/add"
                  element={<AddMeterForm />}
                />
              </Route>

              <Route
                element={<RequirePermission code={"building-administration"} />}
              >
                <Route
                  // path="/buildings/administration/:bid/meters/:type/:meterNo/add-data"
                  path="/buildings/administration/:cid/buildings/:bid/meters/:type/:meterNo/add-data"
                  element={<AddMeterData />}
                />
              </Route>
            </>
          )}

          {/* Company Menu Routes */}
          <Route element={<RequirePermission code={"company-report"} />}>
            <Route path="/company/ghg-result" element={<GhgResult />} />
          </Route>

          <Route element={<RequirePermission code={"company-data"} />}>
            <Route path="/company/data" element={<AddAccountsData />} />
          </Route>

          <Route element={<RequirePermission code={"company-data"} />}>
            <Route
              path="/company/data/:id/view"
              element={<AccountFileDataList />}
            />
          </Route>

          <Route element={<RequirePermission code={"company-account-link"} />}>
            <Route path="/company/account-link" element={<AccountLink />} />
          </Route>

          <Route element={<RequirePermission code={"company-account-link"} />}>
            <Route
              path="/company/account-link/:planId/account-link-view"
              element={<ViewAccountPlan />}
            />
          </Route>

          <Route element={<RequirePermission code={"company-account-link"} />}>
            <Route
              path="/company/account-link/:planId/account-link-edit"
              element={<AddEditPlan />}
            />
          </Route>

          <Route element={<RequirePermission code={"company-account-link"} />}>
            <Route
              path="/company/account-link/account-link-edit"
              element={<AddEditPlan />}
            />
          </Route>

          <Route element={<RequirePermission code={"company-account-link"} />}>
            <Route path="/company/data-link" element={<DataLink />} />
          </Route>

          {/* Partner Menu Routes */}
          <Route element={<RequirePermission code={"partner-users"} />}>
            <Route
              path="/partner/members-list"
              element={<PartnerMembersList />}
            />
          </Route>

          <Route element={<RequirePermission code={"partner-users"} />}>
            <Route
              path="/partner/members-list/:cid/buildings"
              element={<CustomerBuildings />}
            />
          </Route>

          <Route element={<RequirePermission code={"partner-users"} />}>
            <Route
              path="/partner/members-list/:cid/buildings/:bid/meters/:type"
              element={<BuildingMeters prevUrl={window.location.pathname} />}
            />
          </Route>

          <Route element={<RequirePermission code={"partner-users"} />}>
            <Route
              path="/partner/members-list/:cid/buildings/:bid/meters/:type/add"
              // path="/buildings/administration/:cid/buildings/:bid/meters/:type/add"
              element={<AddMeterForm />}
            />
          </Route>

          <Route element={<RequirePermission code={"partner-users"} />}>
            <Route
              path="/partner/members-list/:cid/buildings/:bid/meters/:type/:meterNo/add-data"
              element={<AddMeterData />}
            />
          </Route>

          {/* Admin Menu Routes */}
          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route path="/admin/users-list" element={<AdminUserList />} />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/default-settings"
              element={<AdminDefaultSettings />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/service-providers"
              element={<AdminServiceProviders />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/users-list/:uid/update-role"
              element={<UserRoleUpdate />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-role-mangt"} />}>
            <Route
              path="/admin/system-role-management"
              element={<RoleManagement />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-role-mangt"} />}>
            <Route
              path="/admin/system-role-management/:roleName/permissions/:rolekey"
              element={<PermissionsManagement />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/users-list/:uid/customers"
              element={<UserCustomer />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/users-list/:uid/customers/:cid/buildings"
              element={<CustomerBuildings />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/users-list/:uid/customers/:cid/buildings/:bid/meters/:type"
              element={<BuildingMeters prevUrl={window.location.pathname} />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/users-list/:uid/customers/:cid/buildings/:bid/meters/:type/add"
              element={<AddMeterForm />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/users-list/:uid/customers/:cid/buildings/:bid/meters/:type/:meterNo/add-data"
              element={<AddMeterData />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route path="/admin/partners-list" element={<PartnersList />} />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/partners-list/:pkey/members"
              element={<PartnerMemberspage />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/partners-list/:pkey/members/:cid/buildings"
              element={<CustomerBuildings />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/partners-list/:pkey/members/:cid/buildings/:bid/meters/:type"
              element={<BuildingMeters prevUrl={window.location.pathname} />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/partners-list/:pkey/members/:cid/buildings/:bid/meters/:type/add"
              element={<AddMeterForm />}
            />
          </Route>

          <Route element={<RequirePermission code={"admin-users"} />}>
            <Route
              path="/admin/partners-list/:pkey/members/:cid/buildings/:bid/meters/:type/:meterNo/add-data"
              element={<AddMeterData />}
            />
          </Route>

          <Route element={<RequirePermission code={"scrapper-log"} />}>
            <Route path="/admin/scrapper-log" element={<ScrapperLog />} />
          </Route>

          <Route element={<RequirePermission code={"admin-energy-supplier"} />}>
            <Route
              path="/admin/admin-energy-supplier"
              element={<AdminEnergySupplier />}
            />
          </Route>

          <Route path="/admin/scrapper-list" element={<ScraperList />} />

          <Route path="/admin/emission-groups" element={<EmissionGroups />} />

          <Route
            path="/admin/emission-categories"
            element={<EmissionGroups />}
          />

          <Route path="/admin/account-groups" element={<AccountGroupList />} />

          <Route
            path="/admin/account-groups/:id/edit"
            element={<AccountGroupEdit />}
          />

          <Route
            path="/admin/account-groups/new"
            element={<AccountGroupCreate />}
          />

          <Route path="/admin/account-charts" element={<AccountChartList />} />

          <Route
            path="/admin/account-charts/:id/edit"
            element={<AccountChartEdit />}
          />

          <Route
            path="/admin/account-charts/new"
            element={<AccountChartCreate />}
          />

          <Route
            path="/admin/emission-factors"
            element={<EmissionFactorsList />}
          />

          <Route
            path="/admin/emission-factors/:id/edit"
            element={<EmissionFactorsEdit />}
          />

          <Route
            path="/admin/emission-factors/new"
            element={<EmissionFactorsCreate />}
          />

          {/* {/ support => datahub /} */}
          <Route element={<RequirePermission code={"support-datahub"} />}>
            <Route path="/support/datahub" element={<Datahub />} />
          </Route>

          {/* {/ support=>translation /} */}
          <Route element={<RequirePermission code={"support-translation"} />}>
            <Route path="/support/translation" element={<Translation />} />
          </Route>

          {/* {/ 	support-=> active users /} */}
          <Route element={<RequirePermission code={"support-active-users"} />}>
            <Route path="/support/active-users" element={<ActiveUserList />} />
          </Route>

          {/* {supportAccess && (
            <>
              <Route path="/support/datahub" element={<Datahub />} />
              <Route path="/support/translation" element={<Translation />} />
              <Route
                path="/support/active-users"
                element={<ActiveUserList />}
              />
            </>
          )} */}
          {/* </Route> */}
          {/* <Route element={<RequirePermission code={"admin-users"} />}>
          <Route path="/support/active-users" element={<ActiveUserList />} />
          </Route> */}

          {/* Prediction Routes */}

          {/* <Route element={<RequirePermission code={"prediction-electricty"} />}> */}
          <Route element={<RequirePermission code={"prediction"} />}>
            <Route
              path="/prediction/electricity"
              element={<PredictionElectricity />}
            />
          </Route>

          {/* Others - Related to our website */}

          <Route path="/report/new" element={<NewReport />} />

          <Route
            path="/corporate-co2/energy-processes"
            element={<EnergyProcesses />}
          />

          <Route path="/corporate-co2/purchase" element={<Purchase />} />

          <Route path="/corporate-co2/transport" element={<Transport />} />

          <Route
            path="/corporate-co2/waste-recycling"
            element={<WasteRecycling />}
          />

          <Route
            path="/corporate-co2/sold-products"
            element={<SoldProducts />}
          />

          <Route
            path="/corporate-co2/transactions"
            element={<Transactions />}
          />

          <Route
            path="/corporate-co2/transactions-edit"
            element={<AddTransactions />}
          />

          <Route
            path="/corporate-co2/climate-report"
            element={<ClimateReport />}
          />

          <Route
            path="/energy-consumption/energy-comparison"
            element={<EnergyComparison />}
          />

          {canRead("map") && (
            <Route element={<RequirePermission code={"map"} />}>
              <Route path="/report/map" element={<MapBox />} code="map" />
            </Route>
          )}

          <Route path="/account/settings" element={<AccountSettings />} />

          {/* my-account=> user profile */}

          <Route element={<RequirePermission code={"my-profile"} />}>
            <Route
              path="/user-profile/personal-information"
              element={<PersonaInformation />}
            />
          </Route>

          {/* {/ my-account=>settings /} */}
          <Route element={<RequirePermission code={"my-account-settings"} />}>
            <Route path="/user-profile/settings" element={<UserSettings />} />
          </Route>

          <Route element={<RequirePermission code={"my-password"} />}>
            <Route
              path="/user-profile/change-password"
              element={<ChangePassword />}
            />
          </Route>

          <Route element={<RequirePermission code={"my-customers"} />}>
            <Route path="/user-profile/customers" element={<Customers />} />
          </Route>

          <Route element={<RequirePermission code={"partner-users"} />}>
            <Route
              path="/user-profile/partner-web"
              element={<AccessFromPartnerWebsite />}
            />
          </Route>

          {/* {canRead("customer-roles") && (
                        <Route
                            path="/user-profile/customer-roles"
                            element={<CustomerRoles />}
                        />
                    )} */}

          {/* {/ 	my-account=>customer-roles /} */}
          <Route
            element={<RequirePermission code={"my-account-customer-roles"} />}
          >
            <Route
              path="/user-profile/customer-roles"
              element={<CustomerRoles />}
            />
          </Route>

          {/* {canRead("api-key") && (
                        <Route
                            path="/user-profile/api-key"
                            element={<APIKeyPage />}
                        />
                    )} */}

          {/* {/ my account => api key /} */}
          <Route element={<RequirePermission code={"my-account-api-key"} />}>
            <Route path="/user-profile/api-key" element={<APIKeyPage />} />
          </Route>

          <Route path="/user-profile/api-docs" element={<APIDocsPage />} />

          {/* </Route> */}

          <Route path="/data-hub/redirect" element={<DataHubRedirectFlow />} />

          {/* Not related to our project */}
          <Route path="/builder" element={<BuilderPage />} />

          <Route path="/my-page" element={<MyPage />} />

          <Route element={<RequirePermission code={"building-water"} />}>
            <Route path="/graphql/buildings/water" element={<GWater />} />
          </Route>

          <Route element={<RequirePermission code={"building-electricty"} />}>
            <Route
              path="/graphql/buildings/electricity"
              element={<GElectricity />}
            />
          </Route>

          <Route element={<RequirePermission code={"building-heating"} />}>
            <Route path="/graphql/buildings/heating" element={<GHeating />} />
          </Route>
        </Route>
      </Route>

      <Route
        path="*"
        element={<Navigate to="error/error-v1" replace={true} />}
      />
    </Routes>
  );
}
