import { error } from "./error";
import { info } from "./info";
import { primary } from "./primary";
import { secondary } from "./secondary";
import { success } from "./success";
import { warning } from "./warning";

export const palette = {
  primary: {
    main: primary[800],
    ...primary
  },
  secondary: {
    main: secondary[800],
    ...secondary
  },
  error: {
    main: error[800],
    ...error
  },
  warning: {
    main: warning[800],
    ...warning
  },
  info: {
    main: info[800],
    ...info
  },
  success: {
    main: success[800],
    ...success
  }
};
