import React from "react";
import GEnergyTypes from "./GEnergyTypes";

const GElectricity = () => {
  return (
    <>
      rrrrrrr
      <GEnergyTypes type="electricity" sourceType="Electricity" />
    </>
  );
};
export default GElectricity;
