import React from "react";
import {
  Box,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { useTextdomainContext } from "app/providers";
import { useDebounce } from "hooks";
import { Checkmark, Search } from "comundo-icons";

export function MenuContent(props) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { value, onchange, options, selectOptionLabel, searchable } = props;

  const [displayOptions, setDisplayOptions] = React.useState(options);

  const [searchValue, setSearchValue] = React.useState("");

  const debounceSearchValue = useDebounce(searchValue, 500);

  React.useEffect(() => {
    setDisplayOptions(
      options.filter(option =>
        selectOptionLabel(option)
          .toLowerCase()
          .includes(debounceSearchValue.toLowerCase())
      )
    );
  }, [options, debounceSearchValue, selectOptionLabel]);

  return (
    <Stack spacing={searchable ? 2 : 0}>
      <OutlinedInput
        sx={{ color: "secondary.main", display: searchable ? "" : "none" }}
        size="small"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        placeholder={gettext("Search")}
        startAdornment={
          <InputAdornment position={"start"} style={{ color: "secondary.300" }}>
            <Search color={"inherit"} />
          </InputAdornment>
        }
      />

      <Box
        sx={{
          maxHeight: 336,
          maxWidth: 282,
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "0.21rem"
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "secondary.200"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "secondary.800"
          }
        }}
      >
        {displayOptions.map((option, index) => (
          <MenuItem
            key={selectOptionLabel(option)}
            onClick={() => onchange(option)}
            sx={{
              my: 1,
              color:
                selectOptionLabel(value) === selectOptionLabel(option)
                  ? "primary.main"
                  : "secondary.main"
            }}
          >
            <ListItemText
              primary={
                <Typography noWrap whiteSpace={"break-spaces"}>
                  {selectOptionLabel(option)}
                </Typography>
              }
            ></ListItemText>
            {selectOptionLabel(value) === selectOptionLabel(option) && (
              <Checkmark color={"inherit"} />
            )}
          </MenuItem>
        ))}
      </Box>
    </Stack>
  );
}
