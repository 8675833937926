import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from "react";
import Select from "react-select";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ACCOUNT_DATA_JSON,
  GET_SUMMARY_ACCOUNT_DATA
} from "../../GraphQL/Queries";
import { Button, ButtonGroup, Spinner } from "react-bootstrap";
import ResultSection1 from "./Results/ResultSection1";
import ResultSection2 from "./Results/ResultSection2";
import {
  MainResult,
  MainResultSection1,
  MainResultHead
} from "./Results/GhgStyle";
import { useUser, useTextdomainContext } from "app/providers";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";
import dateFormat from "dateformat";
import { GENERATE_GHG_PDF_REPORT } from "GraphQL/Mutations";
import {
  co2PdfPagePieData,
  ghgPdfPage1
} from "../modules/Reports/partials/Functions";
import { setCacheWithExpiry } from "app/common/Functions";

import DatePicker from "react-datepicker";
import { Box, Grid, Typography } from "@mui/material";
// import { customInputStyle, DEFAULT_TABLE } from "../modules/Reports/pages/EnergyComparisonData";
let newDateFrom = new Date();
let newDateTo = new Date();
newDateFrom.setFullYear(newDateFrom.getFullYear() - 1);
newDateTo.setDate(newDateTo.getDate() - 1);

const appGreenColor = "rgb(67, 105, 50)";

export const customInputStyle = {
  height: 35,
  width: "100%",
  borderRadius: 4,
  borderColor: "rgb(204,204,204)",
  borderWidth: 1.8,
  paddingLeft: 10,
  outlineColor: "rgb(46, 135,252)"
};

const scopeList = [
  { label: "All", value: "all", id: null },
  { label: "Scope 1", value: "scope1" },
  { label: "Scope 2", value: "scope2" },
  { label: "Scope 3", value: "scope3" }
];

const GhgResult = () => {
  //constants
  const { user } = useUser();
  const { teams } = user || {};
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { currentTeamId, currentTeam } = user || {};
  let titles = useRef(null);
  //component states
  const localFilters = JSON.parse(localStorage.getItem("resultFilters"));
  const [accountData, setAccountData] = useState(undefined);
  const [selectedScope, setSelectedScope] = useState(scopeList[0]);
  const [selectedSegment, setselectedSegment] = useState(
    localFilters?.selectedSegment ? localFilters?.selectedSegment : 0
  );
  const [selectedDateFrom, setSelectedDateFrom] = useState(
    localFilters?.fromDate ? new Date(localFilters.fromDate) : newDateFrom
  );
  const [selectedDateTo, setSelectedDateTo] = useState(
    localFilters?.toDate ? new Date(localFilters.toDate) : newDateTo
  );
  const [downloadClicked, setDownlaodClicked] = useState(false);
  const [customerCompany, setCustomerCompany] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    localFilters && localFilters?.customer
      ? localFilters?.customer
      : scopeList[0]
  );
  const date1 = {
    fromDate: dateFormat(selectedDateFrom, "yyyy-mm-dd"),
    toDate: dateFormat(selectedDateTo, "yyyy-mm-dd")
  };
  const [pdfButtonDisabled, changePdfButtonDisabled] = useState(true);
  const [table1PdfData, setTable1PdfData] = useState({});
  const [accDataTable, setAccDataTable] = useState({});

  //mutations

  const [
    fetchSummaryData,
    { data: summaryData, loading, refetch: refetchSummaryData }
  ] = useLazyQuery(
    GET_SUMMARY_ACCOUNT_DATA,

    {
      variables: {
        fromDate: date1?.fromDate,
        toDate: date1?.toDate,
        customerId: selectedCompany?.id
      }
    }
  );

  const [
    fetchSummaryDataJson,
    { data: accDataJson, loadingJsonData, refetch: refetchSummaryDataJson }
  ] = useLazyQuery(
    GET_ACCOUNT_DATA_JSON,

    {
      variables: {
        fromDate: date1?.fromDate,
        toDate: date1?.toDate,
        customerId: selectedCompany?.id
      }
    }
  );

  const [getPdfReport, { loading: pdfLoading }] = useMutation(
    GENERATE_GHG_PDF_REPORT,
    {
      onCompleted(_data) {
        const {
          generateGhgPdfReport: { filePath }
        } = _data;
        window.open(filePath);
        setDownlaodClicked(false);
      },
      onError(error) {
        // error.graphQLErrors.map(({ message }) => {
        //   // console.log("Error =>", error);
        // });
      }
    }
  );

  //functions

  const handleChangeCompany = val => {
    setSelectedCompany(val);
  };

  const handleChangeMeter = val => {
    setSelectedScope(val);
  };

  const handleDownloadButton = () => {
    setDownlaodClicked(true);

    getPdfJsonData();
  };

  const getPdfJsonData = async () => {
    let customResource = {};

    customResource.customers_names = selectedCompany?.label;
    customResource.from_year = dateFormat(date1?.fromDate, "yyyy") - 1;
    customResource.to_year = dateFormat(date1?.toDate, "yyyy") - 1;
    const page1 = ghgPdfPage1(customResource, gettext);

    const page2 = {
      title: gettext("Main Result"),
      description: gettext(
        "The company's total CO₂e emissions are listed in Table 1 and are based on emission factors from 2019. CO₂e emissions are expressed in tonnes of CO₂ equivalent, and the table shows the emissions by scope 1, 2 and 3 cf. the GHG protocol (see instructions). Emissions outside scope are not accounted for according to the GHG protocol."
      ),
      tableTitle: gettext("Overview of the company's total CO₂e emissionsi"),
      tableData: table1PdfData
    };

    // const page4 = {
    //     title: gettext("Partial Result"),
    //     description: gettext(
    //         "Table 3 shows a more detailed result display. Table 3 shows all the subcategories where data can be entered for, the associated emission in tons of CO₂-e and the subcategory's total share of the total emissions."
    //     ),
    //     tableTitle: gettext(
    //         "Overview of the company's total CO₂e emissions"
    //     ),
    //     tableData: table2PdfData,
    // };

    // page3 = {
    //     title: "Partial results",
    //     description:
    //         "Table 3 shows a more detailed result display. Table 3 shows all the subcategories where data can be entered for, the associated emission in tons of CO2-e and the subcategory's total share of the total emissions.",
    //     chart1title: "Overview of the company's total CO2e emissions",
    //     chart1uri: "ghgpage2Uri",

    //     count: 1,
    // };
    let jsonData2 = {
      page1: page1,
      page2: page2,
      page3: await co2PdfPagePieData(
        "ghgpage2Uri",
        "ghgpage3Uri",
        gettext("Overview of the company's total CO₂e emissionsi"),
        gettext("Overview of the company's total CO₂e emissions"),
        "",
        gettext("Main Results"),
        gettext(
          "The figures below show graphical representations of the company's CO₂e emissions. Figure 1 indicates the company's absolute CO₂e emissions in tonnes CO₂ equivalents divided by scopes. Figure 2 shows it percentage distribution of the CO₂e emission divided by scope 1, 2 and 3"
        )
      ),
      // page4: page4,
      total: 4
    };
    getPdfReport({
      variables: {
        pdfJsonSettngs: JSON.stringify(jsonData2)
      }
    });
  };

  const getUriDetails = useCallback(titleArray => {
    titles.current = titleArray;
  }, []);

  //methods

  useEffect(() => {
    let data = [];
    let tableData = [];
    if (!loadingJsonData && accDataJson) {
      accDataJson?.accountDataJson &&
        JSON.parse(accDataJson?.accountDataJson).forEach((levels, index) => {
          data = [];
          let levelLabel = levels.Name.split(">");
          let labelLength = levelLabel.length;
          data.push(
            labelLength,
            levelLabel[labelLength - 1],
            levels.scope1,
            levels.scope2,
            levels.scope3,
            levels.out_of_scope
          );
          tableData.push(data);
        });
    }

    setAccDataTable({
      tableHeadings: ["", "Scope1", "Scope2", "Scope3", "Out of Scope"],
      content: tableData
    });
  }, [accDataJson, loadingJsonData]);

  useEffect(() => {
    fetchSummaryData();
    refetchSummaryData();
    fetchSummaryDataJson();
    refetchSummaryDataJson();
  }, [
    selectedDateFrom,
    selectedDateTo,
    selectedCompany,
    fetchSummaryDataJson,
    refetchSummaryDataJson,
    fetchSummaryData,
    refetchSummaryData,
    selectedSegment
  ]);

  useEffect(() => {
    if (teams && teams.length > 0) {
      let companies = [{ label: "All", value: "all", id: null }];
      teams.forEach(item => {
        companies.push({
          label: item.name,
          value: item.name,
          id: item.Id
        });
      });
      setCustomerCompany(companies);
    }
  }, [teams]);

  useEffect(() => {
    if (!loading && summaryData) {
      const { summaryAccountData } = summaryData;
      setAccountData(summaryAccountData);
    }
  }, [summaryData, loading]);

  useEffect(() => {
    localStorage.setItem(
      "resultFilters",
      JSON.stringify({
        fromDate: selectedDateFrom,
        toDate: selectedDateTo,
        customer: selectedCompany,
        selectedSegment: selectedSegment
      })
    );
  }, [selectedDateFrom, selectedDateTo, selectedCompany, selectedSegment]);

  useEffect(() => {
    changePdfButtonDisabled(pdfLoading);
  }, [pdfLoading]);

  return (
    <MainResult>
      {loading ? (
        <MainResultSection1 className="card card-custom gutter-b example example-compact">
          <MainResultHead className="row m-0">
            <div className="col-sm-12 list-load">
              <Spinner animation="border" />
            </div>
          </MainResultHead>
        </MainResultSection1>
      ) : (
        <>
          <div className="row top-section-filters">
            <Grid
              container
              direction="row"
              columnSpacing={2}
              rowSpacing={2}
              alignItems="center"
              sx={{ px: 2, py: 1 }}
            >
              <Grid item lg={0.75} md={2}>
                <Typography>{gettext("Company")} :</Typography>
              </Grid>
              <Grid item lg={2} md={5}>
                <Select
                  options={customerCompany}
                  onChange={handleChangeCompany}
                  value={[selectedCompany]}
                />
              </Grid>
              <Grid item lg={2} md={5}>
                <Select
                  options={scopeList}
                  onChange={handleChangeMeter}
                  value={[selectedScope]}
                />
              </Grid>
              <Grid item lg={2} md={3}>
                <ButtonGroup size="lg">
                  <Button
                    onClick={() => setselectedSegment(0)}
                    style={{
                      backgroundColor:
                        selectedSegment === 0 ? appGreenColor : "white",
                      color: selectedSegment === 0 ? "white" : "black"
                    }}
                  >
                    {gettext("Day")}
                  </Button>

                  <Button
                    onClick={() => setselectedSegment(1)}
                    style={{
                      backgroundColor:
                        selectedSegment === 1 ? appGreenColor : "white",
                      color: selectedSegment === 1 ? "white" : "black"
                    }}
                  >
                    {gettext("Month")}
                  </Button>
                  <Button
                    onClick={() => setselectedSegment(2)}
                    style={{
                      backgroundColor:
                        selectedSegment === 2 ? appGreenColor : "white",
                      color: selectedSegment === 2 ? "white" : "black"
                    }}
                  >
                    {gettext("Year")}
                  </Button>
                </ButtonGroup>
              </Grid>

              <Grid item lg={0.5} md={1}>
                <Typography>{gettext("From")} :</Typography>
              </Grid>

              <Grid item lg={2} md={3.5}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  columnSpacing={2}
                >
                  {/* {gettext("From")} */}
                  <DatePicker
                    style={{ marginTop: "20px" }}
                    customInput={<input style={customInputStyle} />}
                    // customInput={this.customInput()}
                    selected={selectedDateFrom}
                    onChange={date => {
                      setSelectedDateFrom(date);
                    }}
                    dateFormat={
                      selectedSegment === 2
                        ? "yyy"
                        : selectedSegment === 1
                        ? "MMM YYY"
                        : "dd/MM/yyy"
                    }
                    showMonthYearPicker={selectedSegment === 1}
                    showYearPicker={selectedSegment === 2}
                  />
                </Box>
              </Grid>

              <Grid item lg={0.5} md={1}>
                <Typography>{gettext("To")} :</Typography>
              </Grid>

              <Grid item lg={2} md={3.5}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  columnSpacing={2}
                >
                  {/* {gettext("To")} */}
                  <DatePicker
                    style={{
                      marginLeft: "20px",
                      paddingLeft: "20px"
                    }}
                    customInput={<input style={customInputStyle} />}
                    selected={selectedDateTo}
                    onChange={date => {
                      setSelectedDateTo(date);
                    }}
                    showMonthYearPicker={selectedSegment === 1}
                    showYearPicker={selectedSegment === 2}
                    dateFormat={
                      selectedSegment === 2
                        ? "yyy"
                        : selectedSegment === 1
                        ? "MMM YYY"
                        : "dd/MM/yyy"
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </div>

          <div className="card card-custom  example example-compact">
            <div className="card-header">
              <h2 className=" align-items-start flex-column">
                <strong>
                  {/* Resultat */}
                  {gettext("Results")}
                </strong>
              </h2>
              <div className="card-toolbar">
                <button
                  className="btn btn-danger font-weight-bolder font-size-sm"
                  onClick={() => {
                    handleDownloadButton();
                  }}
                  disabled={pdfButtonDisabled || pdfLoading}
                >
                  <span className="svg-icon svg-icon-md svg-icon-white">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/files/pdf.svg")}
                      className="h-50 align-self-center"
                    />
                  </span>
                  {/*)}*/}
                  Download PDF
                  {(pdfButtonDisabled || pdfLoading) && (
                    <Spinner className="btn-spinner-co2" animation="border" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <br />

          <ResultSection1
            data={accountData}
            selectedCompany={selectedCompany}
            currentTeamId={currentTeamId}
            setTable1PdfData={setTable1PdfData}
            currentTeam={currentTeam}
            getUriDetails={getUriDetails}
            setPageUri1={uri => {
              setCacheWithExpiry("ghgpage2Uri", uri);
            }}
            setPageUri2={uri => {
              setCacheWithExpiry("ghgpage3Uri", uri);
            }}
          />
          {Object.keys(accDataTable).length ? (
            <ResultSection2
              data={accountData}
              // setTable2PdfData={setTable2PdfData}
              downloadClicked={downloadClicked}
              selectedCompany={selectedCompany}
              currentTeamId={currentTeamId}
              currentTeam={currentTeam}
              gettext={gettext}
              tableData={accDataTable}
              tableTitle="Overview of the company's total CO2e emissions"
            />
          ) : null}
        </>
      )}
    </MainResult>
  );
};

export default GhgResult;
