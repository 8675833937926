import React, { Component } from "react";
import { Spinner, Table } from "react-bootstrap";
import "../../index.scss";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import download from "downloadjs";
import client from "services/apiClient";
import { capitalizeFirstLetter } from "hooks/useCommon";

const { REACT_APP_API_URL } = process.env;
const REACT_APP_EXCEL_END_URL = REACT_APP_API_URL + "toexcel/";

const selectStyles = {
  container: base => ({
    ...base,
    minWidth: 200,
    fontSize: 14,
    fontWeight: "normal",
    flex: 1,
    color: "black"
  })
};

class CustomTable extends Component {
  active = "Activate";
  inActive = "InActivated";

  constructor(props) {
    super(props);
    let exportFilename = "table-data-" + Date.now();
    // let energySource = props.energySource;
    if (props.exportFilename) {
      exportFilename = props.exportFilename;
    }
    let benchmarkDropDown = [
      {
        value: "excel",
        label: this.props.gettext("Excel")
      },
      {
        value: "csv",
        label: this.props.gettext("CSV")
      }
    ];

    let electricityDropDown = [
      {
        value: "consumption_excel",
        label: this.props.gettext("Consumption Excel"),
        title: "consumption"
      },
      {
        value: "consumption_excel_hourly",
        label: this.props.gettext("Consumption Excel Hourly"),
        title: "consumption"
      },
      {
        value: "consumption_csv",
        label: this.props.gettext("Consumption CSV"),
        title: "consumption"
      },
      {
        value: "consumption_csv_hourly",
        label: this.props.gettext("Consumption CSV Hourly"),
        title: "consumption"
      },
      {
        value: "production_excel",
        label: this.props.gettext("Production Excel"),
        title: "production"
      },
      {
        value: "production_excel_hourly",
        label: this.props.gettext("Production Excel Hourly"),
        title: "production"
      },
      {
        value: "production_csv",
        label: this.props.gettext("Production CSV"),
        title: "production"
      },
      {
        value: "production_csv_hourly",
        label: this.props.gettext("Production CSV Hourly"),
        title: "production"
      },
      {
        value: "compensation_excel",
        label: this.props.gettext("Compensation Excel"),
        title: "compensation"
      },
      {
        value: "compensation_excel_hourly",
        label: this.props.gettext("Compensation Excel Hourly"),
        title: "compensation"
      },

      {
        value: "compensation_csv",
        label: this.props.gettext("Compensation CSV"),
        title: "compensation"
      },
      {
        value: "compensation_csv_hourly",
        label: this.props.gettext("Compensation CSV Hourly"),
        title: "compensation"
      }
    ];

    this.state = {
      // download: electricityDropDown, // energySource === 'electricity' ? electricityDropDown : allDropDown,
      download:
        props.energySource === "all"
          ? benchmarkDropDown
          : electricityDropDown.filter(
              option => option.title === props.tableType
            ),
      downloadCode: "download",
      downloadName: props.gettext("Download"),
      exportFilename: exportFilename,
      tableDataHourly: { title: "", heading: [], content: [] }
    };
  }

  componentWillReceiveProps(nextProps) {
    let benchmarkDropDown = [
      {
        value: "excel",
        label: this.props.gettext("Excel")
      },
      {
        value: "csv",
        label: this.props.gettext("CSV")
      }
    ];

    let electricityDropDown = [
      {
        value: "consumption_excel",
        label: this.props.gettext("Consumption Excel"),
        title: "consumption"
      },
      {
        value: "consumption_excel_hourly",
        label: this.props.gettext("Consumption Excel Hourly"),
        title: "consumption"
      },
      {
        value: "consumption_csv",
        label: this.props.gettext("Consumption CSV"),
        title: "consumption"
      },
      {
        value: "consumption_csv_hourly",
        label: this.props.gettext("Consumption CSV Hourly"),
        title: "consumption"
      },
      {
        value: "production_excel",
        label: this.props.gettext("Production Excel"),
        title: "production"
      },
      {
        value: "production_excel_hourly",
        label: this.props.gettext("Production Excel Hourly"),
        title: "production"
      },
      {
        value: "production_csv",
        label: this.props.gettext("Production CSV"),
        title: "production"
      },
      {
        value: "production_csv_hourly",
        label: this.props.gettext("Production CSV Hourly"),
        title: "production"
      },
      {
        value: "compensation_excel",
        label: this.props.gettext("Compensation Excel"),
        title: "compensation"
      },
      {
        value: "compensation_excel_hourly",
        label: this.props.gettext("Compensation Excel Hourly"),
        title: "compensation"
      },

      {
        value: "compensation_csv",
        label: this.props.gettext("Compensation CSV"),
        title: "compensation"
      },
      {
        value: "compensation_csv_hourly",
        label: this.props.gettext("Compensation CSV Hourly"),
        title: "compensation"
      }
    ];
    this.setState({ exportFilename: nextProps.exportFilename });
    if (this.props.tableDataHourly !== nextProps.tableDataHourly) {
      this.setState({
        tableDataHourly: nextProps.tableDataHourly
      });
    }
    this.setState({
      downloadName: nextProps.gettext("Download"),
      download:
        nextProps.energySource === "all"
          ? benchmarkDropDown
          : electricityDropDown.filter(
              option => option.title === nextProps.tableType
            )
    });
  }

  handleChangeDownload = (selectedOption, title) => {
    const { handleDownloadFile } = this.props;
    if (this.props.energySource !== "all") {
      this.setState({
        ...this.state,
        selectedFileOptionTitle: capitalizeFirstLetter(selectedOption.title)
      });
    }

    if (
      (handleDownloadFile && title.includes("Consumption")) ||
      title.includes("Production") ||
      title.includes("Compensation")
    ) {
      handleDownloadFile(selectedOption.value);
      return;
    } else if (
      (handleDownloadFile && !title.includes("Consumption")) ||
      !title.includes("Production") ||
      !title.includes("Compensation")
    ) {
      handleDownloadFile(selectedOption.value);
    } else {
      let ext = "xlsx";
      let pageName = "excel";
      if (
        selectedOption.value === "csv" ||
        selectedOption.value === "csv-hourly"
      ) {
        ext = pageName = "csv";
      }

      let data4post = "";

      if (
        selectedOption.value === "excel-hourly" ||
        selectedOption.value === "csv-hourly"
      ) {
        data4post = this.props.tableDataHourly;
      } else {
        data4post = this.props.tableData;
      }

      data4post["building"] = this.props.buildingCode;

      client
        .post(REACT_APP_EXCEL_END_URL + pageName, data4post, {
          headers: {
            "Content-Type": "application/json"
            // 'Accept': 'application/vnd.ms-excel'
          },
          responseType: "blob"
        })
        .then(response => {
          const filename = this.state.exportFilename + "." + ext;
          const content = response.headers["content-type"];
          download(response.data, filename, content);
        })
        .catch(error => {});
    }
  };

  //Table heading
  renderTableHeading = () => {
    const list = this.props.tableData;
    const { type } = this.props;
    if (type === "account-plan" || type === "building") {
      return (
        <thead>
          <tr>
            {list?.heading?.map((item, index) => (
              <span
                className={
                  index === 1 || index === 2
                    ? "table-heading-center"
                    : "table-heading-left"
                }
                key={index}
              >
                {item ? item : ""}
              </span>
            ))}
          </tr>
        </thead>
      );
    } else if (type === "building-edit") {
      return (
        <thead>
          <tr>
            {list?.heading?.map((item, index) => (
              <span
                className={
                  index === 0 ? "table-heading-left" : "table-heading-center"
                }
                key={index}
              >
                {item ? item : ""}
              </span>
            ))}
          </tr>
        </thead>
      );
    } else {
      return (
        <thead>
          <tr>
            {list?.heading?.map((item, index) => {
              return (
                <th
                  className={
                    index === 0 ? "table-heading-left" : "table-heading-right"
                  }
                  key={index}
                >
                  {item ? item : ""}
                </th>
              );
            })}
          </tr>
        </thead>
      );
    }
  };

  onButtonsTap = (type, data, i) => {
    if (type === "View") {
      this.props.onViewTap(data, i);
    } else if (type === "Edit") {
      this.props.onEditTap(data, i);
    } else if (type === this.active || type === this.inActive) {
      this.props.onActiveTap(data, i);
    } else if (type === "Tick") {
      this.props.onTickTap(data, i);
    } else if (type === "energyEdit" || type === "energyCheck") {
      this.props.onCheckorEditTap(type, data, i);
    }
  };

  isEnergyAvailable = (energy, contentIndex) => {
    let isAvailable = false;

    if (energy === "Electricity") {
      isAvailable = true;
    } else if (energy === "Heat" || energy === "Water") {
      // list.energyList.forEach((e) => {
      //     if (e.content[0] === energy) {
      //         isAvailable = true
      //     }
      // })
    }

    return isAvailable;
  };

  getEnergyList = item => {
    let electricity = item.meter.electricity ? item.meter.electricity : [];
    let water = item.meter.water ? item.meter.water : [];
    let heating = item.meter.heating ? item.meter.heating : [];
    let gas = item.meter.gas ? item.meter.gas : [];
    return [...electricity, ...water, ...heating, ...gas];
  };

  getEnergyName = (name, type) => {
    if (type === "heating") {
      return "Heating";
    }
    return name;
  };

  //Table row
  renderTableRow = () => {
    const list = this.props.tableData;
    const { type, hideButton } = this.props;
    if (type === "account-plan") {
      return (
        <tbody>
          {list?.content?.map((row, i) => (
            <tr key={i}>
              <td className={"table-row-right"} key={0}>
                <div className="table-row-text">{row[0]}</div>
              </td>
              <td className={"table-row-center"} key={1}>
                <div className="table-row-text">{row[1]}</div>
              </td>
              <td className={"table-row-center"} key={2}>
                <div className="table-row-text">{row[2]}</div>
              </td>
              <td className={"table-row-center"} key={3}>
                <div className="row">
                  <button
                    disabled={false}
                    className={"account-table-view"}
                    onClick={() => this.onButtonsTap("View", row, i)}
                  >
                    {row[3] ? this.props.gettext("View") : ""}
                    {/*  */}
                  </button>
                </div>
              </td>
              <td className={"table-row-center"} key={4}>
                <div className="row">
                  <button
                    disabled={false}
                    className={"account-table-edit"}
                    onClick={() => this.onButtonsTap("Edit", row, i)}
                  >
                    {row[4] ? this.props.gettext("Edit") : ""}
                    {/*  */}
                  </button>
                </div>
              </td>
              <td className={"table-row-center"} key={5}>
                <div className="row">
                  {!row[5] && (
                    <button
                      disabled={false}
                      className={"account-table-btn1"}
                      onClick={() => this.onButtonsTap("Activate", row, i)}
                    >
                      {!row[5] ? this.props.gettext("Activate") : ""}
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (type === "building") {
      return (
        <tbody>
          {list?.content?.map((item, index) => (
            <tr key={`building_${index}`}>
              <td className="table-row-right">
                <div className="table-row-text">{item.address}</div>
              </td>
              <td className={"table-row-center"}>
                <button
                  className="plain-btn"
                  onClick={() =>
                    this.onButtonsTap(
                      this.isEnergyAvailable(item, index)
                        ? "energyCheck"
                        : "energyEdit",
                      item,
                      index
                    )
                  }
                >
                  <img
                    className="active-check"
                    src={
                      "/media/svg/icons/Navigation/checkmark-green.svg"
                      // !this.isEnergyAvailable(item, index) ? '/media/svg/icons/Navigation/' + (item === 'Electricity' ? 'checkmark-green'
                      // : (item === 'Heat' ? 'checkmark-heat' : 'checkmark-water')) + '.svg'
                      // : '/media/svg/icons/Navigation/' + (item === 'Heat' ? 'edit-heat'
                      // : 'edit-water') + '.svg'
                    }
                    alt={"."}
                  />
                </button>
              </td>
              <td className={"table-row-center"}>
                <button
                  className="plain-btn"
                  onClick={() =>
                    this.onButtonsTap(
                      this.isEnergyAvailable(item, index)
                        ? "energyCheck"
                        : "energyEdit",
                      item,
                      index
                    )
                  }
                >
                  <img
                    className="active-check"
                    src={
                      "/media/svg/icons/Navigation/edit-heat.svg"
                      // this.isEnergyAvailable(item, index) ? '/media/svg/icons/Navigation/' + (item === 'Electricity' ? 'checkmark-green'
                      // : (item === 'Heat' ? 'checkmark-heat' : 'checkmark-water')) + '.svg'
                      // : '/media/svg/icons/Navigation/' + (item === 'Heat' ? 'edit-heat' : 'edit-water') + '.svg'
                    }
                    alt={"."}
                  />
                </button>
              </td>
              <td className={"table-row-right"}>
                <button
                  className="plain-btn"
                  onClick={() =>
                    this.onButtonsTap(
                      this.isEnergyAvailable(item, index)
                        ? "energyCheck"
                        : "energyEdit",
                      item,
                      index
                    )
                  }
                >
                  <img
                    className="active-check"
                    src={
                      "/media/svg/icons/Navigation/edit-water.svg"
                      // this.isEnergyAvailable(item, index) ? '/media/svg/icons/Navigation/' + (item === 'Electricity' ? 'checkmark-green' : (item === 'Heat' ? 'checkmark-heat' : 'checkmark-water')) + '.svg' : '/media/svg/icons/Navigation/' + (item === 'Heat' ? 'edit-heat' : 'edit-water') + '.svg'
                    }
                    alt={"."}
                  />
                </button>
              </td>
              <td
                className={
                  index === 1 || index === 2
                    ? "table-row-center"
                    : "table-row-right"
                }
              >
                <div className="row">
                  <button
                    // disabled={item === this.inActive}
                    className="account-table-view"
                    // className={(item === 'Edit') ? 'account-table-edit' : (item === 'View') ? 'account-table-view' : 'account-table-btn1'}
                    onClick={() =>
                      this.onButtonsTap(
                        this.isEnergyAvailable(item, index)
                          ? "energyCheck"
                          : "energyEdit",
                        item,
                        index
                      )
                    }
                  >
                    {this.props.gettext("View")}
                  </button>
                  <button
                    // disabled={item === this.inActive}
                    className="account-table-edit"
                    // className={(item === 'Edit') ? 'account-table-edit' : (item === 'View') ? 'account-table-view' : 'account-table-btn1'}
                    onClick={() =>
                      this.onButtonsTap(
                        this.isEnergyAvailable(item, index)
                          ? "energyCheck"
                          : "energyEdit",
                        item,
                        index
                      )
                    }
                  >
                    {this.props.gettext("Edit")}
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (type === "building-edit") {
      return (
        <tbody>
          {list?.content?.map((item, i) => (
            <tr key={`edit-energy_${i}`}>
              <td className="table-row-right">
                {this.getEnergyName(item.name, item.type)}
              </td>
              <td className="table-row-center">{item.unit}</td>
              <td className="table-row-center">{item.meterNo}</td>
              <td className="table-row-center">{item.serialNo}</td>
              <td className="table-row-center">{item.supplier}</td>
              {!hideButton && (
                <td className="table-row-center">
                  <div className="row">
                    <button
                      className="account-table-edit"
                      onClick={() => this.onButtonsTap("Edit", item, i)}
                    >
                      {this.props.gettext("Edit")}
                    </button>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      );
    } else {
      return (
        <tbody>
          {list?.content?.map((row, index) => (
            <tr key={index}>
              {row?.map((item, index) => {
                const newItem = item.toString();
                return (
                  <td
                    className={
                      index === 0
                        ? "table-row-left table-data"
                        : "table-row-right table-data"
                    }
                    key={index}
                  >
                    {item === "Total"
                      ? this.props.gettext("Total")
                      : this.props?.locale === "da"
                      ? newItem
                          .replace(/\./g, "#")
                          .replace(/,/g, ".")
                          .replace(/#/g, ",")
                      : item}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      );
    }
  };

  renderHorizontalTable = () => {
    const list = this.props.tableData;

    return (
      <tbody>
        {/* {list?.heading?.map((rowHeading, headIndex) => (
                    <tr key={headIndex}>
                        <th>{rowHeading}</th>

                        {list?.content?.map((rowData, index) => (
                            <>
                                <td
                                    className={
                                        index === 0
                                            ? "table-row-right"
                                            : "table-row-right"
                                    }
                                    key={index}
                                >
                                    {rowData === "Total"
                                        ? this.props.gettext("Total")
                                        : rowData[headIndex]}
                                </td>
                            </>
                        ))}
                    </tr>
                ))} */}

        {list?.heading?.length &&
          list?.heading?.map((rowHeading, headIndex) => (
            <tr key={headIndex}>
              <th>{rowHeading ? rowHeading : ""}</th>
              {list.content &&
                list?.content[rowHeading]?.map((rowData, index) => {
                  const newItem = rowData.toString();
                  return (
                    <>
                      <td
                        className={
                          index === 0 ? "table-row-right" : "table-row-right"
                        }
                        key={index}
                      >
                        {rowData === "Total"
                          ? this.props.gettext("Total")
                          : this.props?.locale === "da"
                          ? newItem
                              .replace(/\./g, "#")
                              .replace(/,/g, ".")
                              .replace(/#/g, ",")
                          : rowData
                          ? rowData
                          : 0}
                      </td>
                    </>
                  );
                })}
            </tr>
          ))}
      </tbody>
    );
  };

  //Table
  renderSection = () => {
    // const { tableData, tableDataHourly } = this.props;
    const { tableData, exportDisabled } = this.props;
    return (
      <div className="table-div">
        <div className="table-title">
          <h3>{tableData && tableData?.title}</h3>
          <div className="table-toolbar">
            {this.props.showExport && (
              <>
                {(this.props.energySource !== "all"
                  ? exportDisabled &&
                    tableData?.title.includes(
                      this?.state?.selectedFileOptionTitle
                    )
                  : exportDisabled) && (
                  <Spinner
                    className="btn-spinner-download"
                    animation="border"
                  />
                )}
                <Select
                  options={this.state.download}
                  onChange={e => this.handleChangeDownload(e, tableData?.title)}
                  value={[
                    {
                      value: this.state.downloadCode,
                      label: this.state.downloadName
                    }
                  ]}
                  styles={selectStyles}
                  selectProps={tableData?.title}
                  isDisabled={exportDisabled}
                  {...this.props}
                  {...this.props.field}
                />
              </>
            )}
          </div>
        </div>

        <Table responsive>
          {this.props.tableView ? null : this.renderTableHeading()}
          {this.props.tableView ? null : this.renderTableRow()}

          {this.props.tableView ? this.renderHorizontalTable() : null}
        </Table>
      </div>
    );
  };

  render() {
    return <div>{this.renderSection()}</div>;
  }
}

export default CustomTable;
