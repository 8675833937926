import { format } from "date-fns";
import dateFormat from "dateformat";
import { translate } from "app/common/Functions";
import { transformResponse } from "../../../common/TransformResponse";

// const WATER_COLORS = ["#040925", "#5e6278"];
// const HEATING_COLORS = ["#8f5400", "#030C24"];
// const ELECTRICITY_COLORS = ["#8c9141", "#030C24", "#426A2F"];

const GAS_COLORS = ["#dc562b", "#030C24", "#05CD99"];
const WATER_COLORS = ["#004BFF", "#030C24", "#05CD99"];
const HEATING_COLORS = ["#FFC442", "#030C24", "#05CD99"];
// const ELECTRICITY_COLORS = ["#426A2F", "#030C24", "#05CD99"];
const ENERGY_COLORS = ["#ADB7C9", "#030C24", "#05CD99"];

export const getColorList = type => {
  switch (type) {
    case "gas":
      return GAS_COLORS;
    case "water":
      return WATER_COLORS;
    case "heating":
      return HEATING_COLORS;
    case "electricity":
      return ENERGY_COLORS;
    default:
      return ENERGY_COLORS;
  }
};

export const getChartData = (options, type) => {
  const colorsList = getColorList(type);

  return {
    unique_id: options.unique_id,
    type: "area",
    dataLabelsEnabled: false,
    yaxisTitleText: options.yaxisTitleText,
    xaxisTitleText: options.xaxisTitleText,
    colors: colorsList,
    plotOptionsBarDistributed: false,
    chart_title: options.chart_title,
    categories: options.categories,
    data: [
      {
        name: options.name,
        data: options.data,
        type: "column"
      },
      {
        name: options.prod_name,
        data: options.prod_data,
        type: "column"
      },
      type === "electricity" && {
        name: options.green_name,
        data: options.green_data,
        type: "column"
      }
    ]
  };
};

export const getChartOptions = (
  unique_id,
  yaxisTitleText,
  chart_title,
  categories,
  data,
  name,
  prod_data,
  prod_name,
  green_data,
  green_name
) => {
  const options = {
    unique_id: unique_id,
    yaxisTitleText: yaxisTitleText,
    chart_title: chart_title,
    categories: categories,
    data: data,
    name: name,
    prod_data: prod_data,
    prod_name: prod_name,
    green_data,
    green_name
  };
  return options;
};

export const getOneYearOldDate = localFilters => {
  let oneYearOldDate = new Date();
  oneYearOldDate.setFullYear(oneYearOldDate.getFullYear() - 1);
  if (localFilters && localFilters.date) {
    return new Date(localFilters.date);
  }
  return oneYearOldDate;
};

export const getSelectedSegment = localFilters => {
  return localFilters ? parseInt(localFilters.selectedSegment) : 2;
};

export const getTransformEnergyCalculation = (
  res,
  type,
  selectedSegment,
  unit,
  pageType,
  date,
  gettext
) => {
  let response = transformResponse(
    res,
    type,
    selectedSegment,
    unit,
    pageType,
    gettext
  );
  let segment =
    selectedSegment === 0 ? "day" : selectedSegment === 1 ? "month" : "year";
  const resType =
    selectedSegment === 0 ? "h" : selectedSegment === 1 ? "d" : "m";
  let tableData = { title: "", heading: [], content: [] };
  let chartData = {};
  let consumptionData = [];
  let costData = [];
  let co2Data = [];
  let productionData = [];
  let productionCostData = [];
  let productionCo2Data = [];
  let greenElectricity = [];
  let greenCo2 = [];
  let chartData1 = getChartData(
    getChartOptions("chartData1", "", "", [], [], "", [], "")
  );
  let chartData2 = getChartData(
    getChartOptions("chartData2", "", "", [], [], "", [], "")
  );
  let chartData3 = getChartData(
    getChartOptions("chartData3", "", "", [], [], "", [], "")
  );

  const { data } = res.data.data;
  if (data && data.length > 0) {
    const customer = data[0];
    const resolution = customer.resolution;
    if (resType !== resolution) {
      return {
        tableData,
        chartData1,
        chartData2,
        chartData3
      };
    }

    if (response.allTableData && response.allTableData.content.length > 0) {
      tableData = response.allTableData;
      let segmentValue = getSegmentValue(selectedSegment, date);
      if (type === "water") {
        tableData.title = `${gettext("Water")} (${segmentValue})`;
      } else if (type === "heating") {
        tableData.title = `${gettext("District Heating")} (${segmentValue})`;
      } else if (type === "electricity") {
        tableData.title = `${gettext("Electricity")} (${segmentValue})`;
      }
    }

    if (response.allChartData) {
      chartData = response.allChartData;

      if (segment === "day") {
        consumptionData = chartData.day.consumption;
        costData = chartData.day.cost;
        co2Data = chartData.day.co2;
        productionData = chartData.day.production;
        productionCostData = chartData.day.productionCost;
        productionCo2Data = chartData.day.productionCo2;
        type === "electricity" &&
          (greenElectricity = chartData.day.greenElectricity);
        type === "electricity" && (greenCo2 = chartData.day.greenCo2);
      } else if (segment === "month") {
        consumptionData = chartData.month.consumption;
        costData = chartData.month.cost;
        co2Data = chartData.month.co2;
        productionData = chartData.month.production;
        productionCostData = chartData.month.productionCost;
        productionCo2Data = chartData.month.productionCo2;
        type === "electricity" &&
          (greenElectricity = chartData.month.greenElectricity);
        type === "electricity" && (greenCo2 = chartData.month.greenCo2);
      } else if (segment === "year") {
        consumptionData = chartData.year.consumption;
        costData = chartData.year.cost;
        co2Data = chartData.year.co2;
        productionData = chartData.year.production;
        productionCostData = chartData.year.productionCost;
        productionCo2Data = chartData.year.productionCo2;
        type === "electricity" &&
          (greenElectricity = chartData.year.greenElectricity);
        type === "electricity" && (greenCo2 = chartData.year.greenCo2);
      }

      chartData1 = getChartData(
        getChartOptions(
          "chartData1",
          "kWh",
          gettext(
            "Energy consumption, production, and compensation measured in kWh"
          ),
          Object.keys(consumptionData)
            .sort()
            .map(i => {
              let str = i.slice(-8).substring(0, 5);
              if (segment === "day") {
                str = i
                  .slice(-5)
                  .substring(0, 5)
                  .replace(".", ":");
              } else if (segment === "month") {
                let tmpDate = new Date(i + "T00:00:00");
                str = dateFormat(tmpDate, "dd-mm-yyyy");
              } else if (segment === "year") {
                let tmpDate = new Date(i + "T00:00:00");
                str = dateFormat(tmpDate, "mmm-yyyy");
              }
              return segment === "year"
                ? `${translate(str.split("-")[0])} - ${str.split("-")[1]}`
                : str;
            }),
          Object.values(consumptionData),
          gettext("Consumption"),
          Object.values(productionData),
          gettext("Production"),
          Object.values(greenElectricity),
          gettext("Compensation")
        ),
        type
      );

      chartData2 = getChartData(
        getChartOptions(
          "chartData2",
          "DKK",
          "Cost",
          Object.keys(costData)
            .sort()
            .map(i => {
              let str = i.slice(-8).substring(0, 5);
              if (segment === "day") {
                str = i
                  .slice(-5)
                  .substring(0, 5)
                  .replace(".", ":");
              } else if (segment === "month") {
                let tmpDate = new Date(i + "T00:00:00");
                str = dateFormat(tmpDate, "dd-mm-yyyy");
              } else if (segment === "year") {
                let tmpDate = new Date(i + "T00:00:00");
                str = dateFormat(tmpDate, "mmm-yyyy");
              }
              return segment === "year"
                ? `${translate(str.split("-")[0])} - ${str.split("-")[1]}`
                : str;
            }),
          Object.values(costData),
          gettext("Cost"),
          Object.values(productionCostData),
          gettext("Production Cost")
        ),
        type
      );

      chartData3 = getChartData(
        getChartOptions(
          "chartData3",
          gettext("Tons CO₂"),
          gettext("Energy CO₂ measured in Tons"),
          Object.keys(co2Data)
            .sort()
            .map(i => {
              let str = i.slice(-8).substring(0, 5);
              if (segment === "day") {
                str = i
                  .slice(-5)
                  .substring(0, 5)
                  .replace(".", ":");
              } else if (segment === "month") {
                let tmpDate = new Date(i + "T00:00:00");
                str = dateFormat(tmpDate, "dd-mm-yyyy");
              } else if (segment === "year") {
                let tmpDate = new Date(i + "T00:00:00");
                str = dateFormat(tmpDate, "mmm-yyyy");
              }
              return segment === "year"
                ? `${translate(str.split("-")[0])} - ${str.split("-")[1]}`
                : str;
            }),
          Object.values(co2Data),
          `${gettext("Consumption")}`,
          Object.values(productionCo2Data),
          `${gettext("Production")}`,
          Object.values(greenCo2),
          gettext("Compensation")
        ),
        type
      );
    }
  }
  return {
    tableData,
    chartData1,
    chartData2,
    chartData3
  };
};

export const getEnergyPageTransformCalculation = (
  energyList,
  selectedSegment,
  unit,
  pageType,
  date,
  gettext
) => {
  let tableData = { title: "", heading: [], content: [] };
  let chartData = {};
  let consumptionData = {};
  let productionData = [];
  let costData = {};
  let co2Data = {};
  let productionCostData = [];
  let productionCo2Data = [];
  let greenElectricity = [];
  let greenCo2 = [];
  let chartData1 = getChartData(
    getChartOptions("chartData1", "", "", [], [], "", [], "")
  );
  let chartData2 = getChartData(
    getChartOptions("chartData2", "", "", [], [], "", [], "")
  );
  let chartData3 = getChartData(
    getChartOptions("chartData3", "", "", [], [], "", [], "")
  );
  let segment =
    selectedSegment === 0 ? "day" : selectedSegment === 1 ? "month" : "year";
  const resType =
    selectedSegment === 0 ? "h" : selectedSegment === 1 ? "d" : "m";

  energyList.map((res, index) => {
    const { data } = res.data.data;

    const electricityGreen = res.data.data.electricity_green;

    let energyType =
      index === 0 ? "electricity" : index === 1 ? "heating" : "water";

    if (data && data.length > 0) {
      const customer = data[0];
      const resolution = customer.resolution;
      if (resType !== resolution) {
        return {
          tableData,
          chartData1,
          chartData2,
          chartData3
        };
      }
    }

    let response = transformResponse(
      res,
      res.type,
      selectedSegment,
      unit,
      pageType,
      gettext
    );

    if (response?.allTableData && response?.allTableData?.content?.length > 0) {
      const allTableData = response.allTableData;
      let segmentValue = getSegmentValue(selectedSegment, date);
      tableData.title = `${gettext("Energy")} (${segmentValue})`;
      tableData.heading = [
        ...tableData.heading,
        ...allTableData.heading
      ].filter((x, i, a) => a.indexOf(x) === i);
      let allTableDataContent = allTableData.content;

      Object.keys(allTableDataContent).map((key, i) => {
        if (!tableData.content[key]) {
          if (energyType === "electricity")
            if (segment === "year") {
              tableData.content[key] = ["", "0", "0", "0", "0", "0", "0"];
            } else {
              tableData.content[key] = [
                "",
                "00:00",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0"
              ];
            }
          else if (segment === "year") {
            tableData.content[key] = ["", "0", "0", "0", "0"];
          } else {
            tableData.content[key] = ["", "00:00", "0", "0", "0", "0"];
          }
        }
        let tempContentArray = allTableDataContent[key];

        let oldContentArray = tableData.content[key];

        oldContentArray[0] = tempContentArray[0];

        if (energyType !== "electricity") {
          if (segment === "year") {
            oldContentArray[1] = additionOfValue(
              oldContentArray[1],
              tempContentArray[1],
              2
            );

            oldContentArray[3] = additionOfValue(
              oldContentArray[3],
              tempContentArray[2],
              3
            );
          } else {
            oldContentArray[1] = tempContentArray[1];

            oldContentArray[2] = additionOfValue(
              oldContentArray[2],
              tempContentArray[2],
              2
            );

            oldContentArray[6] = additionOfValue(
              oldContentArray[6],
              tempContentArray[5],
              5
            );
          }
          oldContentArray[4] = additionOfValue(
            oldContentArray[4],
            tempContentArray[3],
            segment === "year" ? 2 : 4
          );

          oldContentArray[5] = additionOfValue(
            oldContentArray[5],
            tempContentArray[4],
            segment === "year" ? 5 : 2
          );
        } else {
          if (segment === "year") {
            oldContentArray[1] = additionOfValue(
              oldContentArray[1],
              tempContentArray[1],
              2
            );
          } else {
            oldContentArray[1] = tempContentArray[1];

            oldContentArray[7] = additionOfValue(
              oldContentArray[7],
              tempContentArray[7],
              7
            );
          }

          oldContentArray[2] = additionOfValue(
            oldContentArray[2],
            tempContentArray[2],
            2
          );

          oldContentArray[3] = additionOfValue(
            oldContentArray[3],
            tempContentArray[3],
            2
          );

          oldContentArray[4] = additionOfValue(
            oldContentArray[4],
            tempContentArray[4],
            2
          );

          oldContentArray[5] = additionOfValue(
            oldContentArray[5],
            tempContentArray[5],
            segment === "year" ? 5 : 2
          );

          oldContentArray[6] = additionOfValue(
            oldContentArray[6],
            tempContentArray[6],
            6
          );
        }

        tableData.content[key] = oldContentArray;
        return true;
      });
    }

    if (response.allChartData) {
      chartData = response.allChartData;
      let segmentSelected = chartData[segment];

      segmentSelected &&
        Object.keys(segmentSelected?.consumption).map((key, i) => {
          if (electricityGreen && energyType === "electricity") {
            if (!greenElectricity[key]) {
              greenElectricity[key] = 0;
            }
            greenElectricity[key] =
              greenElectricity[key] + segmentSelected?.consumption[key];
          } else {
            if (!consumptionData[key]) {
              consumptionData[key] = 0;
            }
            consumptionData[key] =
              consumptionData[key] + segmentSelected?.consumption[key];
          }

          return true;
        });

      segmentSelected &&
        Object.keys(segmentSelected.production).map((key, i) => {
          if (!productionData[key]) {
            productionData[key] = 0;
          }
          productionData[key] =
            productionData[key] + segmentSelected.production[key];
          return true;
        });

      segmentSelected &&
        Object.keys(segmentSelected.cost).map((key, i) => {
          if (!costData[key]) {
            costData[key] = 0;
          }
          costData[key] = costData[key] + segmentSelected.cost[key];
          return true;
        });

      segmentSelected &&
        Object.keys(segmentSelected.productionCost).map((key, i) => {
          if (!productionCostData[key]) {
            productionCostData[key] = 0;
          }
          productionCostData[key] =
            productionCostData[key] + segmentSelected.productionCost[key];
          return true;
        });

      segmentSelected &&
        Object.keys(segmentSelected.co2).map((key, i) => {
          if (!co2Data[key]) {
            co2Data[key] = 0;
          }
          co2Data[key] = co2Data[key] + segmentSelected.co2[key];
          return true;
        });

      segmentSelected &&
        Object.keys(segmentSelected.productionCo2).map((key, i) => {
          if (!productionCo2Data[key]) {
            productionCo2Data[key] = 0;
          }
          productionCo2Data[key] =
            productionCo2Data[key] + segmentSelected.productionCo2[key];
          return true;
        });
    }

    return true;
  });

  chartData1 = getChartData(
    getChartOptions(
      "chartData1",
      "kWh",
      gettext(
        "Energy consumption, production, and compensation measured in kWh"
      ),
      Object.keys(consumptionData)
        .sort()
        .map(i => {
          let str = i.slice(-8).substring(0, 5);
          if (segment === "day") {
            str = i
              .slice(-5)
              .substring(0, 5)
              .replace(".", ":");
          } else if (segment === "month") {
            let tmpDate = new Date(i + "T00:00:00");
            str = dateFormat(tmpDate, "dd-mm-yyyy");
          } else if (segment === "year") {
            let tmpDate = new Date(i + "T00:00:00");
            str = dateFormat(tmpDate, "mmm-yyyy");
          }
          return segment === "year"
            ? `${translate(str.split("-")[0])} - ${str.split("-")[1]}`
            : str;
        }),
      Object.values(consumptionData),
      gettext("Consumption"),
      Object.values(productionData),
      gettext("Production"),
      Object.values(greenElectricity),
      gettext("Compensation (kWh)")
    ),
    "electricity"
  );

  chartData3 = getChartData(
    getChartOptions(
      "chartData3",
      gettext("Energy CO₂ measured in Tons"),
      gettext("Energy CO₂ measured in Tons"),
      Object.keys(co2Data)
        .sort()
        .map(i => {
          let str = i.slice(-8).substring(0, 5);
          if (segment === "day") {
            str = i
              .slice(-5)
              .substring(0, 5)
              .replace(".", ":");
          } else if (segment === "month") {
            let tmpDate = new Date(i + "T00:00:00");
            str = dateFormat(tmpDate, "dd-mm-yyyy");
          } else if (segment === "year") {
            let tmpDate = new Date(i + "T00:00:00");
            str = dateFormat(tmpDate, "mmm-yyyy");
          }
          return segment === "year"
            ? `${translate(str.split("-")[0])} - ${str.split("-")[1]}`
            : str;
        }),
      Object.values(co2Data),
      `${gettext("Consumption")} CO₂`,
      Object.values(productionCo2Data),
      `${gettext("Production")} CO₂`,
      Object.values(greenCo2),
      gettext("Compensation CO₂ (Tons)")
    ),
    "electricity"
  );

  return {
    tableData: getTotalValues(tableData, selectedSegment),
    chartData1,
    chartData2,
    chartData3
  };
};

export const getTotalValues = (data, selectedSegment) => {
  const segment = selectedSegment;
  let cons = 0;
  let emission = 0;
  let prodKwh = 0;
  let prodCO2 = 0;
  let green = 0;
  let greenCO2 = 0;
  let contentOption = [];
  const { content } = data;

  content.forEach(item => {
    cons = additionOfValue(cons, item[segment === 2 ? 1 : 2], 2);
    emission = additionOfValue(emission, item[segment === 2 ? 2 : 3], 6);
    prodKwh = additionOfValue(prodKwh, item[segment === 2 ? 3 : 4], 2);
    prodCO2 = additionOfValue(prodCO2, item[segment === 2 ? 4 : 5], 6);
    green = additionOfValue(green, item[segment === 2 ? 5 : 6], 6);
    greenCO2 = additionOfValue(greenCO2, item[segment === 2 ? 6 : 7], 7);

    contentOption.push([item[0], item[1], item[2], item[3], item[4]]);
  });

  contentOption.push(["Total", cons, emission, prodKwh, prodCO2]);

  segment === 2
    ? content.push(["Total", cons, emission, prodKwh, prodCO2, green, greenCO2])
    : content.push([
        "Total",
        "",
        cons,
        emission,
        prodKwh,
        prodCO2,
        green,
        greenCO2
      ]);

  data.content = content;

  return data;
};

export const additionOfValue = (oldTime, newTime, index) => {
  let value1 = parseFloat(oldTime);
  let value2 = parseFloat(newTime);
  return (value1 + value2).toFixed(
    index === 2 ||
      index === 3 ||
      index === 4 ||
      index === 5 ||
      index === 6 ||
      index === 7
      ? 2
      : 2
  );
};

export const getSegmentValue = (selectedSegment, date) => {
  return selectedSegment === 0
    ? format(date, "dd-MM-yyyy")
    : selectedSegment === 1
    ? format(date, "MM-yyyy")
    : format(date, "yyyy");
};

export const pdfReportSettings = {
  page1: {
    heading: "",
    company: "",
    address: ""
  },
  page2: {
    section1heading: "Introduction",
    section1text: "",
    section2heading: "Summary",
    section2text: ""
  },
  page3: {
    title: "page3",
    description: "",
    chart1title: "",
    chart1uri: ""
  },
  page4: {
    title: "page4",
    description: "",
    chart1title: "",
    chart1uri: ""
  },
  page5: {
    title: "page5",
    description: "",
    chart1title: "",
    chart1uri: ""
  },
  page6: {
    title: "page6",
    description: "",
    chart1title: "",
    chart1uri: ""
  },
  page7: {
    title: "page7",
    description: "",
    chart1title: "",
    chart1uri: ""
  },
  page8: {
    title: "page8",
    description: "",
    chart1title: "",
    chart1uri: ""
  },
  page9: {
    title: "page9",
    description: "",
    chart1title: "",
    chart1uri: ""
  },
  page13: {
    title: "page13",
    description: "",
    chart1title: "",
    chart1uri: ""
  }
};
