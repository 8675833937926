import { gql } from "@apollo/client";

export const FETCH_PLAN_LINK_CSV_BY_ID = gql`
  query plainLinkCsv($id: String!) {
    plainLinkCsv(id: $id) {
      fileUrl
      name
    }
  }
`;
