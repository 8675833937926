import { Grid } from "@mui/material";
import { BenchmarkFilters, BenchmarkBody } from "app/features";
import { BenchmarkFilterProvider } from "app/providers";
import React from "react";

export function Benchmark() {
  return (
    <BenchmarkFilterProvider>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BenchmarkFilters />
        </Grid>

        <Grid item xs={12}>
          <BenchmarkBody />
        </Grid>
      </Grid>
    </BenchmarkFilterProvider>
  );
}
