import { gql } from "@apollo/client";

export const GET_ACCOUNTS_DATA_LIST = gql`
  query accountsData($customerId: String) {
    accountsData(customerId: $customerId) {
      Id
      createdAt
      fileName
      customerId
      customerName
    }
  }
`;

export const DELETE_ACCOUNT_DATA_FILE = gql`
  mutation deleteAccountData($id: String!) {
    deleteAccountData(id: $id) {
      message
      success
    }
  }
`;
