import { gql } from "@apollo/client";

export const GET_PROVIDERS = gql`
  query comServiceProviders(
    $name: String
    $category: String
    $countryCode: String
  ) {
    comServiceProviders(
      name: $name
      category: $category
      countryCode: $countryCode
    ) {
      Id
      name
      category
      countryCode
      url
      api
    }
  }
`;

export const ADD_PROVIDER = gql`
  mutation addServiceProvider(
    $api: String
    $category: String!
    $countryCode: String!
    $name: String!
    $url: String
  ) {
    addServiceProvider(
      api: $api
      category: $category
      countryCode: $countryCode
      name: $name
      url: $url
    ) {
      success
      message
    }
  }
`;

export const DELETE_PROVIDER = gql`
  mutation deleteServiceProvider($providerId: String!) {
    deleteServiceProvider(providerId: $providerId) {
      success
      message
    }
  }
`;

export const EDIT_PROVIDER = gql`
  mutation editServiceProvider(
    $api: String
    $category: String!
    $name: String!
    $countryCode: String!
    $providerId: String
    $url: String
  ) {
    editServiceProvider(
      api: $api
      category: $category
      countryCode: $countryCode
      name: $name
      providerId: $providerId
      url: $url
    ) {
      success
      message
    }
  }
`;
