import React, { useState, useContext, useEffect, useMemo } from "react";
import client from "services/apiClient";
import {
  ACCOUNT_SETTINGS_DATA,
  SAVE_ACCOUNT_SETTINGS_DATA
} from "../../../common/Constants";
import Select from "react-select";
import { withUser, useTextdomainContext } from "app/providers";

const AccountSettings = props => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const accountSettingsHeadings = useMemo(() => {
    return ["Setting", "Value"];
  }, []);

  let settingsData = {
    title: "Account Settings",
    heading: accountSettingsHeadings,
    content: []
  };
  const [tableData, setTableData] = useState(settingsData);
  const [dropdownDataArray, setDropdownDataArray] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    client.get(ACCOUNT_SETTINGS_DATA).then(response => {
      let data = response.data.data;

      let tableData = {
        title: "Account Settings",
        heading: accountSettingsHeadings,
        content: data
      };

      setTableData(tableData);
    });
  }, [accountSettingsHeadings]);

  const handleSubmit = event => {
    event.preventDefault();
    let formData = [...dropdownDataArray];

    event.target.forEach(event_val => {
      if (event_val.name && event_val.value) {
        formData.push({ label: event_val.name, val: event_val.value });
      }
    });

    client
      .post(SAVE_ACCOUNT_SETTINGS_DATA, { settings: formData })
      .then(response => {
        setSuccessMessage(gettext("Record updated successfully."));
      });
  };

  return (
    <form
      className="card card-custom card-stretch"
      onSubmit={handleSubmit}
      encType="multipart/form-data"
    >
      <div className="card card-custom gutter-b example example-compact">
        <div className="row"></div>

        <div className="table-shift-up">
          <div>
            <div className="table-div">
              {successMessage ? (
                <div className="alert alert-success">{successMessage}</div>
              ) : null}
              <div className="table-title">
                <h3>{gettext("Account Settings")}</h3>
                <div className="table-toolbar">
                  <button
                    className="btn btn-success mr-2"
                    type="submit"
                    value="Submit"
                  >
                    {gettext("Save")}
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="table-heading-right">
                        {gettext("Settings")}
                      </th>
                      <th className="table-heading-left">{gettext("Value")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.content.map((row, i) => (
                      <tr key={i}>
                        <td className="table-row-right" key={i}>
                          {row.var}
                        </td>
                        {row.type && row.type === "input" ? (
                          <td className="table-row-left">
                            <input
                              className="form-control form-control-lg form-control-solid"
                              type="text"
                              name={row.var}
                              defaultValue={row.value}
                            />
                          </td>
                        ) : null}
                        {row.type && row.type === "textarea" ? (
                          <td className="table-row-left">
                            <textarea
                              className="form-control form-control-lg form-control-solid"
                              type="text"
                              name={row.var}
                            >
                              {row.value}
                            </textarea>
                          </td>
                        ) : null}
                        {row.type && row.type === "dropdown" ? (
                          <td className="table-row-left">
                            <div className="w-50">
                              <Select
                                onChange={value => {
                                  setDropdownDataArray([
                                    ...dropdownDataArray,
                                    { label: row.var, val: value.value }
                                  ]);
                                }}
                                options={row.json_value}
                                defaultValue={[
                                  { value: row.value, label: row.value }
                                ]}
                                {...props}
                                {...props.field}
                              />
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default withUser(AccountSettings);
