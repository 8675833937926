import React from "react";
import SectionTable from "../../../components/SectionTable";
import { CORPORATE_PURCHASE } from "../../../common/Constants";
import client from "services/apiClient";

class Purchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: {
        title: "Purchase",
        heading: ["Name", "Scope1", "Scope2", "Scope3", "Udenfor Scope"],
        content: []
        // content: [
        //   ["Primære Indkøb af råvarer til produktion", "2", "3", "4", "5"],
        //   ["Indkøb af materialer", "10", "20", "21", "30"],
        //   ["Indkøb af produkter og services", "45", "64", "45", "45"],
        //   ["Andet", "6", "55", "40", "00"],
        //   ["Sekundære indkøb af hjælpematerialer og service ydelser", "45", "45", "54", "65"],
        //   ["Indkøb af materialer", "45", "45", "54", "65"],
        //   ["Indkøb af produkter og services", "45", "45", "54", "65"],
        //   ["Andet", "45", "45", "54", "65"]
        // ]
      },
      yearList: [
        { value: "All", label: "All" },
        { value: "2015", label: "2015" },
        { value: "2016", label: "2016" },
        { value: "2017", label: "2017" },
        { value: "2018", label: "2018" },
        { value: "2019", label: "2019" },
        { value: "2020", label: "2020" },
        { value: "2021", label: "2021" }
      ]
      // , day: {
      //   xaxis: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00',
      //     '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00',
      //     '20:00', '21:00', '22:00', '23:00'],
      //   yaxis: [130, 40, 35, 50, 49, 60, 70, 91, 125, 85,
      //     25, 65, 53, 35, 45, 65, 45, 95, 78, 15,
      //     12, 34, 24, 25]
      // },
      // month: {
      //   xaxis: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
      //     '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
      //     '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
      //   yaxis: [130, 40, 35, 50, 49, 60, 70, 91, 125, 85,
      //     25, 65, 53, 35, 45, 65, 45, 95, 78, 15,
      //     12, 34, 24, 25, 65, 75, 85, 92, 14, 10]
      // },
      // year: {
      //   xaxis: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      //   yaxis: [130, 40, 35, 50, 49, 60, 70, 91, 125, 85, 25, 65]
      // }
    };
  }

  updateChartData(month, quarter, year) {
    client
      .get(CORPORATE_PURCHASE, {
        params: { month: month, quarter: quarter, year: year }
      })
      .then(response => {
        let tableData = this.state.tableData;
        tableData["content"] = response.data.data;
        this.setState({ tableData: tableData });
      });
  }

  render() {
    return (
      <div className="card card-custom gutter-b example example-compact">
        <SectionTable
          topSectionHeading="Bygning"
          tableData={this.state.tableData}
          yearList={this.state.yearList}
          getDropDownValues={(month, quarter, year) => {
            this.updateChartData(month, quarter, year);
            // console.log("DropDown values ", month.value, quarter.value, year.value)
          }}
        />
      </div>
    );
  }
}

export default Purchase;
