import React from "react";
import SwitchTeam from "./SwitchTeam";
import CustomersList from "./CustomersList";

export function Customers() {
  return (
    <>
      <SwitchTeam />
      <CustomersList />
    </>
  );
}
export default Customers;
