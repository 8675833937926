import React, { useContext, useState } from "react";
import { useTextdomainContext } from "app/providers";
import { DataGridComponent } from "../../components";

import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import { useActiveUsersList } from "./hooks";
import TrackModel from "./components/TrackModel";

import { IconButton, Tooltip } from "@mui/material";

export function ActiveUserListTable(props) {
  const { searchString } = props;

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { activeUsersList, loading } = useActiveUsersList(searchString);

  const [track_show, settrack] = useState(false);
  const handleClose_track = () => settrack(false);
  const handleShow_track = () => settrack(true);

  function track_functionality(row) {
    handleShow_track();
  }

  function performAction(row, action) {
    switch (action) {
      case "track":
        track_functionality(row);
        break;
      default:
        break;
    }
  }

  function columnFormater(params) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Tooltip title={gettext("Track")} arrow>
          <IconButton onClick={() => performAction(params.row, "track")}>
            <TrackChangesIcon size={16} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  const columns = [
    {
      field: "name",
      headerName: gettext("Name"),
      flex: 1,
      minWidth: 150,
      headerAlign: "left"
    },
    {
      field: "email",
      headerName: gettext("Email"),
      flex: 1,
      minWidth: 150,
      headerAlign: "left"
    },
    {
      field: "company",
      headerName: gettext("Company"),
      headerAlign: "left"
    },
    {
      field: "country",
      headerName: gettext("Country"),
      headerAlign: "left"
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      flex: 1,
      minWidth: 200,
      maxWidth: 250,
      headerAlign: "center",
      renderCell: params => columnFormater(params)
    }
  ];
  return (
    <>
      <DataGridComponent
        rows={activeUsersList}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        initialState={{ pinnedColumns: { right: ["actions"] } }}
        autoHeight
      />
      <TrackModel
        show={track_show}
        onHide={handleClose_track}
        getTeamMembers={props.getTeamMembers}
      />
    </>
  );
}
