import React, { useContext, useState } from "react";
import { ActiveUserListTable } from "app/features";
import { Grid, Typography } from "@mui/material";
import { ActiveUsersTableControls } from "./components";
import { useTextdomainContext } from "app/providers";
export function ActiveUserList() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const [searchString, setSearchString] = useState("");
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography fontSize={22} fontWeight={700} marginLeft={1.5}>
                {gettext("Active Users List")}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ActiveUsersTableControls setSearchString={setSearchString} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ActiveUserListTable searchString={searchString} />
        </Grid>
      </Grid>
    </>
  );
}
