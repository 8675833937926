import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Spinner } from "react-bootstrap";
import { GET_ACCOUNT_CHARTS } from "../../../../GraphQL/Queries";
import { CREATE_ACCOUNT_CHART } from "../../../../GraphQL/Mutations";
import AccountChartForm from "./account-chart-form";
import { GoBackButton, AccountChartHeader } from "./chartStyle";

const AccountChartCreate = () => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const navigate = useNavigate();
  const [nameDa, setNameDa] = useState("");
  const [nameDe, setNameDe] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountGroupId, setAccountGroupId] = useState("");
  const [tab, setTab] = useState("");
  const [category, setCategory] = useState("");

  const [accountGroup, { loading }] = useMutation(CREATE_ACCOUNT_CHART, {
    onCompleted() {
      navigate(`/admin/account-charts`);
    },
    onError(error) {
      // error.graphQLErrors.map(({ message }) => {
      //   // console.log("Error =>", error);
      // });
    }
  });

  const createAccountChart = () => {
    accountGroup({
      variables: {
        nameDa: nameDa,
        nameDe: nameDe,
        nameEn: nameEn,
        tab: tab,
        category: category,
        accountNumber: parseInt(accountNumber),
        accountGroupId: accountGroupId
      },
      refetchQueries: [{ query: GET_ACCOUNT_CHARTS }]
    });
  };

  const hanleGoBack = () => {
    navigate(`/admin/account-charts`);
  };

  const validForm = () =>
    nameDa && nameDe && nameEn && accountNumber && accountGroupId;

  return (
    <>
      <GoBackButton className="row m-0">
        <div className="col-sm-12 p-0">
          <div className="form-group col-md-2 p-0">
            <button
              type="button"
              className="btn btn-outline-info"
              onClick={hanleGoBack}
            >
              <AiOutlineArrowLeft title="back" size={18} className="mr-2" />
              {gettext("Back")}
            </button>
          </div>
        </div>
      </GoBackButton>
      <AccountChartHeader className="card card-custom gutter-b example example-compact">
        <div className="row m-0 table-title">
          <div className="col-sm-12 title">
            <h3>{gettext("New Account Chart")}</h3>
          </div>
        </div>
        <>
          <AccountChartForm
            nameDa={nameDa}
            nameDe={nameDe}
            nameEn={nameEn}
            accountNumber={accountNumber}
            accountGroupId={accountGroupId}
            tab={tab}
            category={category}
            setNameDa={setNameDa}
            setNameDe={setNameDe}
            setNameEn={setNameEn}
            setAccountNumber={setAccountNumber}
            setAccountGroupId={setAccountGroupId}
            setTab={setTab}
            setCategory={setCategory}
          />

          <div className="row m-0 border-top-row">
            <div className="col-sm-12 create-action">
              <div className="form-group col-md-12">
                <button
                  type="submit"
                  onClick={createAccountChart}
                  disabled={!validForm() || loading}
                >
                  {gettext("Create Account Chart")} &nbsp;
                  {loading && (
                    <Spinner
                      className="btn-spinner-delete"
                      animation="border"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      </AccountChartHeader>
    </>
  );
};

export default AccountChartCreate;
