import React from "react";
// import CustomTable from "../../../components/CustomTable";
import SectionTable from "../../../components/SectionTable";
import { CORPORATE_CLIMATE_REPORT } from "../../../common/Constants";
import client from "services/apiClient";

class ClimateReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: {
        title: "Climate Report",
        heading: ["Name", "Scope1", "Scope2", "Scope3", "Udenfor Scope"],
        content: []
        // content: [
        //   [<b><h5>Energi og processer</h5></b>, "", "", "", ""],
        //   ["Elektricitet", "10", "20", "21", "30"],
        //   ["Energi og procesenergi", "45", "64", "45", "45"],
        //   ["Procesudledning", "45", "45", "54", "65"],
        //   ["Andet", "45", "45", "54", "65"],
        //   [<b><h5>Indkøb</h5></b>, "", "", "", ""],
        //   ["Primære Indkøb af råvarer til produktion", "45", "45", "54", "65"],
        //   ["Indkøb af materialer", "45", "45", "54", "65"],
        //   ["Indkøb af produkter og services", "45", "45", "54", "65"],
        //   ["Andet", "45", "45", "54", "65"],
        //   ["Sekundære indkøb af hjælpematerialer og service ydelser", "45", "45", "54", "65"],
        //   ["Indkøb af materialer", "45", "45", "54", "65"],
        //   ["Indkøb af produkter og services", "45", "45", "54", "65"],
        //   ["Andet", "45", "45", "54", "65"],
        //   [<b><h5>Transport</h5></b>, "", "", "", ""],
        //   ["Egne og leasede transportmidler", "45", "45", "54", "65"],
        //   ["Medarbejdertransport", "45", "45", "54", "65"],
        //   ["Varetransport til virksomhed", "45", "45", "54", "65"],
        //   ["Varetransport fra virksomhed til kunde", "45", "45", "54", "65"],
        //   ["Andet", "45", "45", "54", "65"],
        //   [<b><h5>Affald og genbrug</h5></b>, "", "", "", ""],
        //   ["Affald", "45", "45", "54", "65"],
        //   ["Andet", "45", "45", "54", "65"],
        //   [<b><h5>Solgte produkter</h5></b>, "", "", "", ""],
        //   ["Forarbejdning og processering af solgte produkter", "45", "45", "54", "65"],
        //   ["Brug af solgte og udlejede produkter", "45", "45", "54", "65"],
        //   ["End-of-life behandling", "45", "45", "54", "65"],
        //   ["Andet", "45", "45", "54", "65"],
        // ]
      },
      yearList: [
        { value: "All", label: "All" },
        { value: "2015", label: "2015" },
        { value: "2016", label: "2016" },
        { value: "2017", label: "2017" },
        { value: "2018", label: "2018" },
        { value: "2019", label: "2019" },
        { value: "2020", label: "2020" },
        { value: "2021", label: "2021" }
      ]
    };
  }

  updateChartData(month, quarter, year) {
    client
      .get(CORPORATE_CLIMATE_REPORT, {
        params: { month: month, quarter: quarter, year: year }
      })
      .then(response => {
        const data = response.data.data;
        // console.log(data);
        let finalArray = [];
        let tableData = this.state.tableData;

        if (data.energy_process) {
          finalArray.push([
            <b>
              <h5>Energi og processer</h5>
            </b>,
            "",
            "",
            "",
            ""
          ]);
          data.energy_process.map(val => {
            finalArray.push(val);
            return null;
          });
        }

        if (data.purchase) {
          finalArray.push([
            <b>
              <h5>Indkøb</h5>
            </b>,
            "",
            "",
            "",
            ""
          ]);
          data.purchase.map(val => {
            finalArray.push(val);
            return null;
          });
        }

        if (data.transport) {
          finalArray.push([
            <b>
              <h5>Transport</h5>
            </b>,
            "",
            "",
            "",
            ""
          ]);
          data.transport.map(val => {
            finalArray.push(val);
            return null;
          });
        }

        if (data.waste_recycling) {
          finalArray.push([
            <b>
              <h5>Affald og genbrug</h5>
            </b>,
            "",
            "",
            "",
            ""
          ]);
          data.waste_recycling.map(val => {
            finalArray.push(val);
            return null;
          });
        }

        if (data.sold_products) {
          finalArray.push([
            <b>
              <h5>Solgte produkter</h5>
            </b>,
            "",
            "",
            "",
            ""
          ]);
          data.sold_products.map(val => {
            finalArray.push(val);
            return null;
          });
        }

        tableData["content"] = finalArray;
        this.setState({ tableData: tableData });
      });
  }

  render() {
    return (
      <div className="card card-custom gutter-b example example-compact">
        <SectionTable
          topSectionHeading="Bygning"
          tableData={this.state.tableData}
          yearList={this.state.yearList}
          getDropDownValues={(month, quarter, year) => {
            this.updateChartData(month, quarter, year);
            // console.log(month, quarter, year);
            // console.log("DropDown values ", month.value, quarter.value, year.value)
          }}
        />
      </div>
    );
  }
}

export default ClimateReport;
