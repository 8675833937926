import {
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
  styled,
  Typography
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useTextdomainContext } from "app/providers";
import { useBuildingStep } from "app/features/RegistrationFlowAddMeterForm/providers";
import { AddMeterSteps } from "./components";
import { IoCheckmark } from "react-icons/io5";

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.secondary[200],
  borderRadius: "12px",
  backgroundColor: theme.palette.secondary[50],
  "&:before": {
    display: "none"
  }
}));

export function TypeSelector(props) {
  const { type, expanded, onChange } = props;

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const typeLabel = React.useMemo(() => {
    return {
      electricity: {
        label: gettext("Electricity")
      },
      heating: {
        label: gettext("Heating")
      },
      gas: {
        label: gettext("Natural Gas")
      },
      water: {
        label: gettext("Water")
      }
    };
  }, [gettext]);

  const { buildingMeterData } = useBuildingStep();

  const completed = buildingMeterData?.[type]?.step > 2;

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        sx={{
          borderRadius: "12px",
          backgroundColor: completed ? "primary.700" : ""
        }}
        expandIcon={completed ? null : <ExpandMoreIcon />}
        id="meter-type-selector"
      >
        <Typography
          fontSize={18}
          fontWeight={500}
          color={completed ? "white" : "secondary.main"}
        >
          {typeLabel[type].label}
        </Typography>

        <Box sx={{ flex: 1 }} />

        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          {completed && <IoCheckmark size={16} color="white" />}
          <Typography
            fontSize={16}
            fontWeight={400}
            color={completed ? "white" : "secondary.500"}
            sx={{ mr: 1 }}
          >
            {buildingMeterData?.[type]?.step === -1
              ? gettext("Unavailable")
              : buildingMeterData?.[type]?.step < 3
              ? 3 -
                buildingMeterData?.[type]?.step +
                " " +
                gettext("steps left")
              : gettext("Completed")}
          </Typography>
        </Box>
      </AccordionSummary>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderTop: "1px solid",
          borderColor: "secondary.200"
        }}
      >
        <LinearProgress
          variant="determinate"
          value={((buildingMeterData?.[type]?.step + 1) / 4) * 100}
          sx={{ height: "2px", backgroundColor: "secondary.200" }}
        />

        <AccordionDetails
          sx={{ padding: 4, justifyContent: "center", alignItems: "center" }}
        >
          <AddMeterSteps type={type} typeInfo={buildingMeterData?.[type]} />
        </AccordionDetails>
      </Box>
    </Accordion>
  );
}
