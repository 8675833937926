import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: "100%",
      height: "100%",
      marginTop: "50px"
    },
    backNavButton: {
      float: "left",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      marginTop: "8px",
      "&:hover": {
        opacity: 0.7
      }
    },
    backButtonText: {
      fontSize: "20px!important",
      fontWeight: "500!important",
      margin: "5px",
      marginTop: "2.5px"
    },
    mainTitle: {
      color: "black",
      textAlign: "center"
    },
    title: {
      fontWeight: "550!important",
      fontSize: "32px!important"
    },
    searchFilter: {
      padding: "6px",
      backgroundColor: theme.palette.secondary["50"],
      border: "1px solid",
      borderColor: theme.palette.secondary["200"],
      borderRadius: "8px"
    },
    primaryMessage: {
      mt: 1,
      fontSize: "24px!important",
      fontWeight: "500!important"
    },
    secondaryMessage: {
      fontSize: "18px!important",
      fontWeight: "400!important"
    }
  };
});

export default useStyles;
