import React, { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "app/providers";

const StepContext = createContext();

export function useStep() {
  const { currentStep, lastStep, handleBack, refreshStep } = useContext(
    StepContext
  );

  const minStep = 0;

  const maxStep = 2;

  return {
    currentStep,
    lastStep,
    handleBack,
    refreshStep,
    minStep,
    maxStep
  };
}

export default function RegistrationFlowStepProvider({ children }) {
  const [currentStep, setCurrentStep] = useState(0);

  const [lastStep, setLastStep] = useState(0);

  const [stepRefreshed, setRefreshStep] = useState(false);

  const { user } = useUser();

  const { registrationStep } = user || {};

  useEffect(() => {
    if (registrationStep !== undefined && registrationStep !== null) {
      setCurrentStep(registrationStep);
    }
  }, [registrationStep, stepRefreshed]);

  const handleBack = () => {
    setLastStep(currentStep);
    setCurrentStep(prevStep => prevStep - 1);
  };

  const refreshStep = () => {
    setLastStep(currentStep);
    setRefreshStep(prev => !prev);
  };

  const value = {
    currentStep,
    lastStep,
    handleBack,
    refreshStep
  };

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
}
