import React, { useState, useRef, useContext, useCallback } from "react";
import Toast from "app/components/toast/Toast";
import { useTextdomainContext } from "app/providers";
import { Spinner } from "react-bootstrap";
import client from "services/apiClient";
import { Typography, Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
// import download from "downloadjs";

const Translation = () => {
  const { REACT_APP_API_URL_GRAPHQL, REACT_APP_URL } = process.env;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [toastList, updateToastList] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [documentError, setDocumentError] = useState(false);

  // Document Uploading //
  const drop = React.useRef(null);

  const handleRef = useCallback(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);

    return () => {
      drop.current.removeEventListener("dragover", handleDragOver);
      drop.current.removeEventListener("drop", handleDrop);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    handleRef();
  }, [handleRef]);

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async e => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length && files[0].name.includes(".po")) {
      const formData = new FormData();
      try {
        const query = `mutation uploadTranslationZip {
        uploadTranslationZip {
          success
          message
        }
      }`;
        formData.append("query", query);
        formData.append("file", files[0]);
        setDocumentLoading(true);
        const response = await client.post(
          REACT_APP_API_URL_GRAPHQL,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
        if (
          response.status === 200 &&
          response?.data?.data?.uploadTranslationZip?.success === false
        ) {
          const toastArray = [
            {
              title: gettext(
                `${response?.data?.data?.uploadTranslationZip?.message[0]}`
              ),
              type: "error"
            }
          ];
          updateToastList(toastArray);
          setDocumentLoading(false);
          setDocumentError(true);
          setDocumentUploaded(false);
        } else if (
          response.status === 200 &&
          response?.data?.data?.uploadTranslationZip?.success === true
        ) {
          const toastArray = [
            {
              title: gettext("Document Uploaded Successfully"),
              type: "success"
            }
          ];
          updateToastList(toastArray);
          setDocumentLoading(false);
          setDocumentUploaded(true);
          setDocumentError(false);
        } else if (response.status === 400) {
          const toastArray = [
            {
              title: gettext("Document Uploading Failed"),
              description: gettext(`Some Error Occurred`),
              type: "error"
            }
          ];
          updateToastList(toastArray);
          setDocumentLoading(false);
          setDocumentError(true);
          setDocumentUploaded(false);
        }
      } catch (error) {
        const toastArray = [
          {
            title: gettext("Document Uploading Failed"),
            description: gettext(`Some Error Occurred`),
            type: "error"
          }
        ];
        updateToastList(toastArray);
        setDocumentLoading(false);
        setDocumentError(true);
        setDocumentUploaded(false);
      }
    } else {
      const toastArray = [
        {
          title: gettext(`Please Upload PO file`),
          type: "error"
        }
      ];
      updateToastList(toastArray);
      setDocumentError(true);
      setDocumentUploaded(false);
    }
  };
  const documentOneRef = useRef(null);

  const handleUploadDocument = () => {
    documentOneRef.current?.click();
  };
  const handleFileOne = async e => {
    if (!e.target.files) {
      return;
    }

    const formData = new FormData();
    try {
      const query = `mutation uploadTranslationZip {
        uploadTranslationZip {
          success
          message
        }
      }`;
      formData.append("query", query);
      formData.append("file", e.target.files[0]);
      setDocumentLoading(true);
      const response = await client.post(REACT_APP_API_URL_GRAPHQL, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (
        response.status === 200 &&
        response?.data?.data?.uploadTranslationZip?.success === false
      ) {
        const toastArray = [
          {
            title: gettext(
              `${response?.data?.data?.uploadTranslationZip?.message[0]}`
            ),
            type: "error"
          }
        ];
        updateToastList(toastArray);
        setDocumentLoading(false);
        setDocumentError(true);
        setDocumentUploaded(false);
      } else if (
        response.status === 200 &&
        response?.data?.data?.uploadTranslationZip?.success === true
      ) {
        const toastArray = [
          {
            title: gettext("Document Uploaded Successfully"),
            type: "success"
          }
        ];
        updateToastList(toastArray);
        setDocumentLoading(false);
        setDocumentUploaded(true);
        setDocumentError(false);
      } else if (response.status === 400) {
        const toastArray = [
          {
            title: gettext("Document Uploading Failed"),
            description: gettext(`Some Error Occurred`),
            type: "error"
          }
        ];
        updateToastList(toastArray);
        setDocumentLoading(false);
        setDocumentError(true);
        setDocumentUploaded(false);
      }
    } catch (error) {
      const toastArray = [
        {
          title: gettext("Document Uploading Failed"),
          description: gettext(`Some Error Occurred`),
          type: "error"
        }
      ];
      updateToastList(toastArray);
      setDocumentLoading(false);
      setDocumentError(true);
      setDocumentUploaded(false);
    }
  };
  const handleDownload = async () => {
    // const url = "https://app-dev.fabeke.com/data/gettext/translation.zip";
    // axios
    //   .get(url, {
    //     responseType: "blob",
    //     headers: {
    //       "Content-Type": "application/json"
    //       // 'Accept': 'application/vnd.ms-excel'
    //     },
    //   })
    //   .then((res) => {
    //     console.log("response", res);
    //   });
    // client
    //   .post('https://localhost:3000/data/gettext/translation.zip' + 'zip', {
    //     headers: {
    //       "Content-Type": "application/json",
    //       // 'Accept': 'application/vnd.ms-excel'
    //     },
    //     responseType: "blob",
    //   })
    //   .then((response) => {
    //     console.log(response);
    //     // const filename = this.state.exportFilename + "." + ext;
    //     const content = response.headers["content-type"];
    //     // download(response.data, filename, content);
    //     console.log("res", response);
    //     console.log("content", content);
    //   })
    //   .catch((error) => {
    //     /* console.log(error) */
    //   });
    //  const response = await axios.get(REACT_APP_EXCEL_END_URL);
    //  console.log('response',response);
    window.open(REACT_APP_URL + "data/gettext/translation.zip", "_blank");
    // download('https://localhost:3000/data/gettext/translation.zip');
  };

  return (
    <>
      <div className="d-flex flex-row">
        <div className="pt-8 pl-6 pr-6" style={{ flex: 1 }}>
          <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
            {gettext("Translation")}
          </Typography>
          <Box
            sx={{
              boxShadow: 2,
              borderRadius: "12px",
              padding: "10px",
              width: 740,
              height: 350,
              marginTop: "10px"
            }}
          >
            <div
              variant="contained"
              style={{
                borderRadius: 24,
                width: "72px",
                height: "30px",
                color: "#015731",
                background: "#E6EEEA",
                border: "1px solid #B3CDC1",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                paddingTop: "3px",
                fontWeight: "500",
                fontSize: 14
              }}
            >
              {gettext("Upload")}
            </div>
            <div ref={drop}>
              <button
                style={{
                  height: 168,
                  marginTop: "16px",
                  width: "100%",
                  borderRadius: "12px",
                  backgroundColor: "#fafafa",
                  border: documentError
                    ? "1px dashed #FF4D4D"
                    : "1px dashed #015731",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer"
                }}
                onClick={handleUploadDocument}
                disabled={documentLoading}
              >
                {documentLoading ? (
                  <Spinner animation="border" variant="dark" />
                ) : documentUploaded ? (
                  <CheckCircleIcon
                    style={{ color: "#015731" }}
                    fontSize="large"
                  />
                ) : documentError ? (
                  <InfoIcon style={{ color: "#FF4D4D" }} fontSize="large" />
                ) : (
                  <CloudUploadIcon fontSize="large" />
                )}
                <Typography
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 16,
                    fontWeight: "400",
                    alignItems: "center",
                    color: documentError ? "#FF3333" : "#015731",
                    marginTop: "15px"
                  }}
                >
                  {documentUploaded
                    ? gettext("Uploaded Successfully")
                    : documentError
                    ? gettext("File type not supported")
                    : gettext("Drop the file here or")}

                  {!documentUploaded && !documentError && (
                    <span
                      style={{
                        textDecoration: "underline",
                        marginLeft: 4,
                        fontWeight: "500",
                        color: "#015731"
                      }}
                    >
                      {gettext("browse")}
                    </span>
                  )}
                </Typography>
                <input
                  type="file"
                  accept=".po"
                  ref={documentOneRef}
                  onChange={handleFileOne}
                  style={{ display: "none" }}
                />
              </button>
            </div>
            <Box
              sx={{
                marginTop: "20px",
                width: "100%",
                backgroundColor: "#fafafa",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #E4E4E7",
                borderRadius: "12px",
                height: 80,
                paddingX: "160px",
                display: "flex",
                textAlign: "center"
              }}
            >
              <Typography>
                {gettext(
                  "You can upload only the PO file. After uploading that file, it takes almost 10 minutes to reflect the changes on the website."
                )}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              boxShadow: 2,
              borderRadius: "12px",
              padding: "10px",
              width: 740,
              height: 242,
              marginTop: "28px"
            }}
          >
            <div
              variant="contained"
              style={{
                borderRadius: 24,
                width: "91px",
                height: "30px",
                color: "#015731",
                background: "#E6EEEA",
                border: "1px solid #B3CDC1",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                paddingTop: "3px",
                fontWeight: "500",
                fontSize: 14
              }}
            >
              <p>{gettext("Download")}</p>
            </div>
            <Box
              sx={{
                marginTop: "10px",
                width: "100%",
                background: "#fafafa",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #E4E4E7",
                borderRadius: "12px",
                padding: "34px",
                height: 100,
                display: "flex",
                textAlign: "center"
              }}
            >
              <Typography>
                {gettext(
                  "It will download a zip file containing the PO and POT files. PO file will have the existing translations, and the POT file will have the latest text that requires translation. We can use the Poedit software to import the POT text into the PO file and translate it."
                )}
              </Typography>
            </Box>
            <button
              style={{
                display: "flex",
                flexDirection: "row",
                borderRadius: "8px",
                backgroundColor: "#27272A",
                alignItems: "center",
                justifyContent: "center",
                width: "133px",
                textAlign: "center",
                margin: "0 auto",
                marginTop: 20,
                border: 0,
                height: 40
              }}
              onClick={() => handleDownload()}
            >
              <DownloadIcon
                fontSize="small"
                color="white"
                style={{ color: "white" }}
              />
              <Typography
                sx={{
                  marginLeft: "4px",
                  color: "white",
                  fontWeight: "500",
                  fontSize: 16
                }}
              >
                {gettext("Download")}
              </Typography>
            </button>
          </Box>
          {/* <div>
            {!document?.name ? (
              <Button onClick={handleUploadDocument}>
                {gettext("Upload Po file")}
              </Button>
            ) : documentLoading ? (
              <Spinner animation="border" variant="dark" />
            ) : (
              <Button onClick={handleUploadDocument}>
                {gettext("Upload PO file")}
              </Button>
            )}
           
            <input
              type="file"
              accept=".po"
              ref={documentOneRef}
              onChange={handleFileOne}
              style={{ display: "none" }}
            />
          </div>
          <div className="mt-3">
            <Button onClick={() => handleDownload()}>
              {gettext("Download")}
            </Button>
          </div> */}
        </div>
      </div>

      <Toast toastList={toastList} />
    </>
  );
};

export default Translation;
