import { useState, useEffect } from "react";
import { useDashboard } from "hooks/useDashboardData";

export const useEPC = props => {
  const { customer, addresses, type, from, to, resolution } = props;
  const [epcData, setEpcData] = useState([]);
  const getEPCParams = type => {
    return {
      sourceType: type,
      customers: customer,
      addresses: addresses,
      fromDate: from,
      toDate: to,
      resolution: resolution
    };
  };
  const { data: electricity, loading: eLoading } = useDashboard(
    getEPCParams("electricity")
  );
  const { data: heating, loading: hLoading } = useDashboard(
    getEPCParams("heating")
  );
  const { data: water, loading: wLoading } = useDashboard(
    getEPCParams("water")
  );
  const { data: allData, loading: aLoading } = useDashboard(getEPCParams(type));
  useEffect(() => {
    if (type === "energy" && !eLoading && !hLoading && !wLoading) {
      setEpcData([electricity, heating, water]);
    } else if (type !== "energy" && !aLoading) {
      setEpcData([allData]);
    }
  }, [
    electricity,
    heating,
    water,
    allData,
    type,
    eLoading,
    hLoading,
    wLoading,
    aLoading
  ]);

  return { epcData };
};
