import { gql } from "@apollo/client";

export const ADD_ELECTRICITY_PAGE_DATA = gql`
  mutation comAddElectricityPageData(
    $customerId: String!
    $data: JSONString!
    $description: String!
  ) {
    comAddElectricityPageData(
      customerId: $customerId
      data: $data
      description: $description
    ) {
      success
      message
    }
  }
`;

export const DELETE_ELECTRICITY_PAGE_DATA = gql`
  mutation comDeleteElectricityPageData($customerId: String!) {
    comDeleteElectricityPageData(customerId: $customerId) {
      success
      message
    }
  }
`;

export const GET_ELECTRICITY_PAGE_DATA = gql`
  query comGetElectricityPageData($customerId: String!) {
    comGetElectricityPageData(customerId: $customerId) {
      id
      customerId
      description
      data
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_BUILDING_PUBLIC_STATUS = gql`
  mutation updateBuildingPublicStatus(
    $buildingName: String!
    $isPublic: Boolean!
  ) {
    updateBuildingPublicStatus(
      buildingName: $buildingName
      isPublic: $isPublic
    ) {
      success
      message
    }
  }
`;
