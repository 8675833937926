import React from "react";
import { Chip, Collapse, Divider, Grid } from "@mui/material";

export function Level3Tabs({
  Level3SelectedTab,
  Level3TabGroups,
  handleLevel3TabChange,
  name
}) {
  return (
    <Collapse in={Boolean(Level3TabGroups)}>
      <Divider sx={{ marginY: 1 }} variant="middle" />
      <Grid container spacing={1}>
        {Level3TabGroups?.map((tab, index) => (
          <Grid item>
            <Chip
              key={index}
              label={name && tab[name]}
              variant={
                Level3SelectedTab === (name && tab["key"])
                  ? "filled"
                  : "outlined"
              }
              color="success"
              size="small"
              onClick={() => handleLevel3TabChange(tab)}
            />
          </Grid>
        ))}
      </Grid>
    </Collapse>
  );
}
