import React from "react";
// import { Table } from "react-bootstrap";
import "../../index.scss";
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Typography,
  Box
} from "@mui/material";

const EnergyPricesCustomTable = props => {
  const {
    energyType,
    tableData,
    gettext,
    selectedCurrency,
    currencySelectionComponent,
    fileSelectionComponent
  } = props;

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <TableContainer
      sx={{
        borderRadius: "12px",
        padding: "24px",
        border: "1px solid #E4E4E7"
      }}
    >
      <Box sx={{ padding: "15px 0 25px 0" }}>
        <Typography
          variant="h6"
          fontFamily="Aeonik"
          sx={{ fontWeight: "550", fontSize: "20px", color: "black" }}
        >
          {gettext(capitalizeFirstLetter(energyType)) +
            " " +
            gettext("prices for consumption") +
            " " +
            (selectedCurrency === "EUR" ? "€/kWh" : selectedCurrency + "/kWh")}
        </Typography>
        {fileSelectionComponent}
        {currencySelectionComponent}
      </Box>
      <Table sx={{ backgroundColor: "white" }}>
        <TableHead sx={{ backgroundColor: "white", border: "none" }}>
          <TableRow sx={{ borderBottom: "1px solid #E4E4E7" }}>
            {tableData.tableHeading.map((item, index) => (
              <TableCell
                sx={{
                  backgroundColor: "white",
                  color: "#015731",
                  fontWeight: 500,
                  textAlign: "center",
                  border: "none"
                }}
                index={index}
                colSpan={index === 0 ? 1 : tableData.totalCountryCodes}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
          <TableRow sx={{ borderBottom: "1px solid #E4E4E7" }}>
            {tableData.subHeading.map((item, index) => (
              <TableCell
                sx={{
                  backgroundColor: "white",
                  textAlign: index !== 0 && "center",
                  border: "none",
                  fontWeight: 500
                }}
                index={index}
              >
                {item ? item : ""}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.content.map((row, index) => (
            <TableRow
              sx={{
                "&:nth-child(even)": { backgroundColor: "#FAFAFA" },
                "&:hover": { backgroundColor: "#FAFAFA" }
              }}
            >
              {row.map((item, index) => (
                <TableCell
                  sx={{
                    color: index !== 0 && "#71717A",
                    textAlign: index !== 0 && "center",
                    fontWeight: index === 0 && 500,
                    fontSize: index === 0 && "14px",
                    border: "none"
                  }}
                >
                  {item === "Total" ? this.props.gettext("Total") : item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EnergyPricesCustomTable;
