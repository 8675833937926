import { Box, Button, Grid } from "@mui/material";
import { useTextdomainContext } from "app/providers";
import React from "react";
import { IoLink } from "react-icons/io5";
import { SelectBuilding, SelectCompany, SelectDashboard } from "./components";

export function Filter({ handleShareOpen }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: "secondary.50",
        border: "1px solid",
        borderColor: "secondary.200",
        borderRadius: "8px"
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={"auto"}>
          <SelectDashboard />
        </Grid>

        <Grid item xs={12} sm={"auto"}>
          <SelectCompany />
        </Grid>

        <Grid item xs={12} sm={"auto"}>
          <SelectBuilding />
        </Grid>

        <Grid item xs={12} sm textAlign="end">
          <Button
            size="small"
            color="secondary"
            variant="contained"
            startIcon={<IoLink />}
            onClick={handleShareOpen}
          >
            {gettext("Share")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
