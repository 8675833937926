import { TextField } from "@mui/material";
import React from "react";

export function Textbox({ name, type, textboxValues, settextboxValues }) {
  return (
    <TextField
      id="outlined-basic"
      fullWidth
      size={"small"}
      label={type[name]}
      variant="outlined"
      value={textboxValues[type["key"]]}
      onChange={e =>
        // settextboxValues(prev => {
        //   prev[type] = e.target.value || "";
        //   return prev;
        // })

        settextboxValues({
          ...textboxValues,
          [type["key"]]: e.target.value || ""
        })
      }
    />
  );
}
