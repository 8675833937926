import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useCallback, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { DELETE_ACCOUNT_DATA_FILE, GET_ACCOUNTS_DATA_LIST } from "./commands";

export function useAccountsDataList(customerId) {
  const { data, loading, error, refetch } = useQuery(GET_ACCOUNTS_DATA_LIST, {
    variables: {
      customerId: customerId
    }
  });

  return {
    accountsDataList: data?.accountsData,
    loading,
    error,
    refetch
  };
}

export function useDelete() {
  const { enqueueSnackbar } = useSnackbar();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [deleteAccountData, { loading }] = useMutation(
    DELETE_ACCOUNT_DATA_FILE
  );

  const deleteFile = useCallback(
    async (id, reset) => {
      try {
        await deleteAccountData({
          variables: { id }
        });

        enqueueSnackbar(gettext("Deleting Successfull"), {
          variant: "success"
        });

        await reset();
      } catch (error) {
        enqueueSnackbar(gettext("Error Deleting"), {
          variant: "error"
        });
      }
    },
    [deleteAccountData, enqueueSnackbar, gettext]
  );

  return {
    deleteFile,
    loading
  };
}
