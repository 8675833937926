import { useTextdomainContext } from "app/providers";
import { useContext, useMemo } from "react";

export function useEnergySources() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  return useMemo(
    () => [
      { label: gettext("All"), value: "energy" },
      { label: gettext("Electricity"), value: "electricity" },
      { label: gettext("District Heating"), value: "heating" },
      { label: gettext("Natural Gas"), value: "gas" },
      { label: gettext("Water"), value: "water" }
    ],
    [gettext]
  );
}
