import React, { useCallback, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Spinner } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import Select from "react-select";
import { useFetchNewMembers, useSubmit } from "./hooks";
// import { FaUserCircle, FaSearch } from "react-icons/fa";

// function UserDetails({ selectedUser }) {
//   return (
//     <>
//       <div className="d-flex flex-row my-4">
//         <FaUserCircle size={40} className="mr-2" />
//         <div className="d-flex flex-column">
//           <div className="text-uppercase font-weight-bold">
//             {selectedUser?.name}
//           </div>
//           <div className=" font-italic">{selectedUser?.email}</div>
//         </div>
//       </div>
//     </>
//   );
// }

export function AddMemberForm({ partner }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { newMembers, loading: fetching } = useFetchNewMembers(partner);

  const {
    success,
    error,
    loading: submitting,
    addMemberToPartner
  } = useSubmit();

  const [memberId, setMemberId] = useState(null);

  //const [selectedUser, setSelectedUser] = useState(null);

  const addHandler = useCallback(
    async e => {
      e.preventDefault();
      addMemberToPartner({
        variables: { customerName: memberId, customerKey: partner },
        refetchQueries: ["comGetNotMembers", "getPartnerMembers"]
      });
    },
    [memberId, partner, addMemberToPartner]
  );

  return (
    <div className="card card-custom example example-compact">
      <form onSubmit={addHandler}>
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3 className="col-xs-6">{gettext("Add Members")}</h3>
            <button
              className="col-xs-6 btn btn-primary d-flex align-items-center"
              type="submit"
              disabled={submitting || !memberId}
            >
              {submitting ? (
                <Spinner animation="border" variant="light" />
              ) : (
                <>
                  <MdAdd size={20} />
                  {gettext("Add")}
                </>
              )}
            </button>
          </div>

          {/* end::Header */}

          {
            <Collapse in={success}>
              <Alert severity="success">
                <AlertTitle>{gettext("Success")}</AlertTitle>
                {gettext("Member Added Successfully")}
              </Alert>
            </Collapse>
          }
          {
            <Collapse in={error}>
              <Alert severity="error">
                <AlertTitle>{gettext("Error")}</AlertTitle>
                {gettext("There was an Error while Adding The Member")}
              </Alert>
            </Collapse>
          }
          <div className="card-body">
            <div className="form-group">
              <label>{gettext("Company")}</label>

              <Select
                onChange={option => setMemberId(option.name)}
                options={newMembers}
                loading={fetching}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder={gettext("Company")}
                autosize={true}
              />

              {/* {selectedUser && <UserDetails selectedUser={selectedUser} />} */}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
