import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  styled,
  Typography
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AiOutlinePlus } from "react-icons/ai";
import { useTextdomainContext, useUser } from "app/providers";
import React from "react";
import {
  IoClose,
  IoInformationCircle,
  IoPencil,
  IoPerson
} from "react-icons/io5";
import { useBuildingStep } from "app/features/RegistrationFlowAddMeterForm/providers";

const { REACT_APP_URL } = process.env;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400
  }
}));

export function Supplier({ type }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const {
    buildingMeterData,
    setMetersData,
    editMetersData,
    deleteMetersData,
    saveMetersData
  } = useBuildingStep();

  const [onInputMode, setOnInputMode] = React.useState(
    !buildingMeterData?.[type]?.data?.length || false
  );

  const [isEditing, setIsEditing] = React.useState(false);
  const [editingIndex, setEditingIndex] = React.useState();

  const [supplier, setSupplier] = React.useState("");
  const [providerUsername, setProviderUserName] = React.useState("");
  const [providerPassword, setProviderPassword] = React.useState("");

  const addClickHandler = React.useCallback(() => {
    setMetersData(type, {
      supplier,
      providerUsername,
      providerPassword
    });
    setOnInputMode(false);
    setSupplier("");
    setProviderUserName("");
    setProviderPassword("");
  }, [type, supplier, providerUsername, providerPassword, setMetersData]);

  const editClickHandler = React.useCallback(() => {
    editMetersData(type, editingIndex, {
      supplier,
      providerUsername,
      providerPassword
    });
    setOnInputMode(false);
    setIsEditing(false);
    setSupplier("");
    setProviderUserName("");
    setProviderPassword("");
  }, [
    type,
    editingIndex,
    supplier,
    providerUsername,
    providerPassword,
    editMetersData
  ]);

  const goToEditMode = React.useCallback(
    index => {
      setSupplier(buildingMeterData?.[type]?.data?.[index]?.supplier || "");
      setProviderUserName(
        buildingMeterData?.[type]?.data?.[index]?.providerUsername || ""
      );
      setProviderPassword(
        buildingMeterData?.[type]?.data?.[index]?.providerPassword || ""
      );
      setEditingIndex(index);
      setOnInputMode(true);
      setIsEditing(true);
    },
    [type, buildingMeterData]
  );

  const deleteClickHandler = React.useCallback(
    index => {
      deleteMetersData(type, index);
    },
    [type, deleteMetersData]
  );

  const { user } = useUser();

  const { customerKey } = user || {};

  const openDataHub = React.useCallback(() => {
    window.open(
      `${REACT_APP_URL}r/gg/${customerKey}`,
      "",
      "noopener,noreferre"
    );
  }, [customerKey]);

  return type === "electricity" ? (
    <Button
      disableElevation
      size="large"
      fullWidth
      variant="contained"
      color="secondary"
      onClick={() => openDataHub()}
    >
      {gettext("Connect To DataHub")}
    </Button>
  ) : onInputMode ? (
    <Stack spacing={2}>
      <Stack>
        <InputLabel htmlFor="supplier-name">
          <Typography fontSize={14} fontWeight={500} color="#3F3F46">
            {gettext("Supplier name")}
          </Typography>
        </InputLabel>
        <OutlinedInput
          id="supplier-name"
          name="supplier"
          type={"text"}
          fullWidth
          placeholder={gettext("Enter supplier name")}
          size="large"
          value={supplier}
          onChange={e => setSupplier(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <LightTooltip
                title={
                  <Typography color="#4D896F" textAlign={"center"}>
                    {gettext("Input the supplier.")}
                  </Typography>
                }
                arrow
                placement="top"
              >
                <IconButton>
                  <IoInformationCircle />
                </IconButton>
              </LightTooltip>
            </InputAdornment>
          }
        />
      </Stack>

      <Stack>
        <InputLabel htmlFor="provider-username">
          <Typography fontSize={14} fontWeight={500} color="#3F3F46">
            {gettext("Provider username")}
          </Typography>
        </InputLabel>
        <OutlinedInput
          id="provider-username"
          name="providerUsername"
          type={"text"}
          fullWidth
          placeholder={gettext("Enter provider username")}
          size="large"
          value={providerUsername}
          onChange={e => setProviderUserName(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <LightTooltip
                title={
                  <Typography color="#4D896F" textAlign={"center"}>
                    {gettext("Input the provider Username.")}
                  </Typography>
                }
                arrow
                placement="top"
              >
                <IconButton>
                  <IoInformationCircle />
                </IconButton>
              </LightTooltip>
            </InputAdornment>
          }
        />
      </Stack>

      <Stack>
        <InputLabel htmlFor="provider-password">
          <Typography fontSize={14} fontWeight={500} color="#3F3F46">
            {gettext("Provider password")}
          </Typography>
        </InputLabel>
        <OutlinedInput
          id="provider-password"
          name="providerPassword"
          type={"text"}
          fullWidth
          size="large"
          placeholder={gettext("Enter provider password")}
          value={providerPassword}
          onChange={e => setProviderPassword(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <LightTooltip
                title={
                  <Typography color="#4D896F" textAlign={"center"}>
                    {gettext("Input the Provider password.")}
                  </Typography>
                }
                arrow
                placement="top"
              >
                <IconButton>
                  <IoInformationCircle />
                </IconButton>
              </LightTooltip>
            </InputAdornment>
          }
        />
      </Stack>

      <Button
        disableElevation
        disabled={
          supplier === "" || providerUsername === "" || providerPassword === ""
        }
        fullWidth
        size="large"
        variant="contained"
        color="secondary"
        onClick={isEditing ? () => editClickHandler() : () => addClickHandler()}
      >
        {isEditing ? gettext("Edit") : gettext("Add")}
      </Button>
    </Stack>
  ) : (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography fontSize={18} fontWeight={500} color="#3F3F46">
          {gettext("Add more suppliers")}
        </Typography>

        <Button
          disableElevation
          size="medium"
          variant="outlined"
          startIcon={<AiOutlinePlus size={16} />}
          color="secondary"
          onClick={() => setOnInputMode(true)}
        >
          {gettext("Add")}
        </Button>
      </Box>

      {buildingMeterData?.[type]?.data?.map((meter, index) => (
        <Box
          key={meter.supplier}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "white",
            border: "1px solid #E4E4E7",
            borderRadius: "8px",
            gap: 1
          }}
        >
          <IoPerson size={16} color="#34795A" />
          <Typography fontSize={16} fontWeight={500} color="#27272A">
            {meter.supplier}
          </Typography>
          <Box sx={{ flex: 1 }} />
          <IoPencil size={16} onClick={() => goToEditMode(index)} />
          <IoClose
            size={16}
            color="#EF4444"
            onClick={() => deleteClickHandler(index)}
          />
        </Box>
      ))}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end"
        }}
      >
        <Button
          disableElevation
          size="medium"
          fullWidth={false}
          variant="contained"
          color="secondary"
          onClick={() => saveMetersData(type)}
        >
          {gettext("Save")}
        </Button>
      </Box>
    </Stack>
  );
}
