import React, { useState, useEffect, useCallback, useContext } from "react";
// import Select from "react-select";
import { Spinner, Button } from "react-bootstrap";
// import { useAddCustomer } from "../AddCustomerForm/hooks";
// import { useAddCustomer, useGetNotMyCustomers } from "app/hooks";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Collapse,
  // Button,
  Grid,
  // IconButton,
  // Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useDebounce } from "hooks";
// import { NavigationHeader } from "../../components";
// import { useFindCompanies, useSubmit } from "./hooks";
// import { AiOutlinePlusCircle } from "react-icons/ai";
// import { AddressesInput, CountryDropdown } from "./components";
// import { useUser } from "app/providers";
import {
  // AddressesInput,
  CountryDropdown
} from "app/modules/RegistrationFlow/tabs/AddCompanyTab/components";
import {
  useFindCompanies,
  useSubmit
} from "app/modules/RegistrationFlow/tabs/AddCompanyTab/hooks";

import { useTextdomainContext } from "app/providers";

export function NewCustomerForm({ userId, userName }) {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setshowErrorMessage] = useState(false);

  const [companyName, setCompanyName] = useState("");

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const [companyCountryValue, setCompanyCountryValue] = useState(null);

  const [customerLabel, setCustomerLabel] = useState(null);

  const [CVRNumber, setCVRNumber] = useState(null);

  const debouncedValue = useDebounce(companyName, 500, [companyName]);

  const {
    matchedCompanies,
    companyCountry,
    // companyAddresses,
    loading
  } = useFindCompanies(debouncedValue, () => {});

  useEffect(() => {
    setCompanyCountryValue(companyCountry);
  }, [companyCountry]);

  const resetForm = useCallback(() => {
    setCVRNumber("");
    setCustomerLabel("");
    setCompanyCountryValue("");
    setCompanyName("");
  }, []);

  const { submit, submiting } = useSubmit(
    () => {
      setShowSuccessMessage(true);

      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);

      resetForm();
    },
    () => {
      setshowErrorMessage(true);
      setTimeout(() => {
        setshowErrorMessage(false);
      }, 5000);
    }
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      submit({
        customer: {
          customerName: debouncedValue,
          customerCountry: companyCountryValue,
          customerLabel: customerLabel,
          customerCvr: CVRNumber
        },
        notRegisterFlow: true
      });
    },
    [submit, debouncedValue, companyCountryValue, customerLabel, CVRNumber]
  );

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <form onSubmit={handleSubmit}>
          <div className="table-div m-0">
            {/* begin::Header */}

            <div className="table-title">
              <h3>
                {/* <FormattedMessage id="AUTH.MENU.ADD_CUSTOMER" /> */}
                {gettext("Add New Company")}
              </h3>

              <span className="font-size-sm w-100">
                {gettext("Add Company to") + " " + userName}
              </span>
            </div>

            <div className="form">
              <div className="card-body">
                {
                  <Collapse in={showSuccessMessage}>
                    <Alert severity="success">
                      <AlertTitle>{gettext("Success")}</AlertTitle>
                      {gettext("Company Has Been Added Successfully")}
                    </Alert>
                  </Collapse>
                }
                {
                  <Collapse in={showErrorMessage}>
                    <Alert severity="error">
                      <AlertTitle>{gettext("Error")}</AlertTitle>
                      {gettext("There was a problem adding Company")}
                    </Alert>
                  </Collapse>
                }
                {/* <div className="form-group">
                  <label>
                    <FormattedMessage id="USER.LIST.CUSTOMERS.ADD.SELECT_CUSTOMER" />
                  </label>

                  <Select
                    options={newCustomers.map(c => ({ value: c, label: c }))}
                    isLoading={isOptionsLoading}
                    onChange={opt => setInputNewTeam(opt.value)}
                    required
                  />
                </div> */}
                {/* <Paper sx={{ padding: 4, borderRadius: 4 }} elevation={2}> */}
                {/* <form onSubmit={handleSubmit}> */}
                <Stack spacing={4}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography fontSize={16} fontWeight={600} align="left">
                          {gettext("Add Company")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item md={6} xs={12}>
                            <Autocomplete
                              disablePortal
                              disableClearable
                              freeSolo
                              fullWidth
                              loading={loading && companyName.length > 0}
                              options={matchedCompanies}
                              getOptionLabel={option => option}
                              inputValue={companyName}
                              onInputChange={(e, newValue) =>
                                setCompanyName(newValue)
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label={gettext("Company Name")}
                                  size="small"
                                  required
                                  InputProps={{
                                    ...params.InputProps
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <CountryDropdown
                              value={companyCountryValue}
                              required
                              placeholder={gettext("Company Country")}
                              onChange={value => setCompanyCountryValue(value)}
                              disabled={Boolean(companyCountry)}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item md={6}>
                            <TextField
                              type="text"
                              // {...params}
                              label={gettext("Label")}
                              size="small"
                              // required={required}
                              value={customerLabel}
                              onChange={e => setCustomerLabel(e.target.value)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item md={6}>
                            <TextField
                              type="text"
                              // {...params}
                              label={gettext("CVR number")}
                              size="small"
                              // required={required}
                              value={CVRNumber}
                              onChange={e => setCVRNumber(e.target.value)}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                          }}
                        >
                          <Typography
                            fontSize={16}
                            fontWeight={600}
                            align="left"
                          >
                            Add Buildings
                          </Typography>

                          <IconButton onClick={() => addNewBuildingInput()}>
                            <AiOutlinePlusCircle color="green" />
                          </IconButton>
                        </Box>
                      </Grid>

                      {newBuildings.map((building, index) => (
                        <AddressesInput
                          key={index}
                          address={building.buildingAddress}
                          onChangeAddress={onChangeAddress}
                          country={building.buildingCountry}
                          onChangeCountry={onChangeCountry}
                          disabled={false}
                          canReduce={
                            companyAddresses?.length > 0 ||
                            newBuildings.length > 1
                          }
                          required={true}
                          index={index}
                          removeBuildingInput={removeBuildingInput}
                          showReduceIcon={true}
                        />
                      ))}

                      {companyAddresses.map((building, index) => (
                        <AddressesInput
                          key={index}
                          address={building.address}
                          country={building.country}
                          disabled={true}
                          showReduceIcon={true}
                        />
                      ))}
                    </Grid>
                  </Grid> */}
                  <Box className="ml-auto mr-11">
                    <Button
                      className="mr-2 btn btn-primary"
                      type="submit"
                      disabled={submiting}
                    >
                      {submiting ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        <>{gettext("Save")}</>
                      )}
                    </Button>
                    <Button
                      className="btn btn-secondary"
                      type="reset"
                      onClick={resetForm}
                    >
                      {gettext("Reset")}
                    </Button>
                  </Box>
                </Stack>
                {/* </form> */}
                {/* </Paper> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
