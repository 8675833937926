import { gql } from "@apollo/client";

export const FETCH_CHART_OF_ACCOUNTS_LIST = gql`
  query chartOfAccounts {
    chartOfAccounts {
      Id
      accountNumber
      name
      createdAt
      updatedAt
      accountGroupId
    }
  }
`;

export const FETCH_ACCOUNT_GROUPS_LIST = gql`
  query accountGroups {
    accountGroups {
      Id
      name
    }
  }
`;

export const SAVE_PLAN_LINK = gql`
  mutation insertPlainLink(
    $name: String!
    $status: Boolean!
    $customerName: String!
    $fromDate: Date
    $toDate: Date
    $companyAccount: [MappingData]!
  ) {
    insertPlainLink(
      name: $name
      status: $status
      customerName: $customerName
      fromDate: $fromDate
      toDate: $toDate
      companyAccount: $companyAccount
    ) {
      message
      success
      plainLinkId
    }
  }
`;

export const EDIT_PLAN_LINK = gql`
  mutation updatePlainLink(
    $id: String!
    $plainLinkData: UpdatePlainLinkDataInput!
  ) {
    updatePlainLink(id: $id, plainLinkData: $plainLinkData) {
      message
      success
    }
  }
`;

export const FETCH_PLAN_LINK_BY_ID = gql`
  query plainLink($id: String!) {
    plainLink(id: $id) {
      Id
      name
      fromDate
      toDate
      status
      createdAt
      updatedAt
      customerId
      companyAccount {
        accountName
        accountNumber
        price
        chartOfAccountId
      }
    }
  }
`;
