import React from "react";
import { PerEnergySource } from "../PerEnergySource";

export function TotalKwhPerEnergySourceM2() {
  const kwhGraph = {
    id: "consumptionKwh",
    title: "Energy consumption measured in kWh/m2",
    fieldName: "vPerM2"
  };

  const Co2Graph = {
    id: "consumptionCo2",
    title: "CO₂ eq measured in tons per m2",
    fieldName: "co2PerM2"
  };

  return (
    <PerEnergySource
      Co2Graph={Co2Graph}
      kwhGraph={kwhGraph}
      labels={["electricity", "water", "heating"]}
    />
  );
}
