import React from "react";
// This page is Deprecated
class ViewPlan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileName: "",
      percentage: 0,
      planName: props.location.state.data[0]
    };
  }

  onAddTap = () => {
    // const url = 'https://sample-videos.com/csv/Sample-Spreadsheet-10-rows.csv'
    // window.open(url);
  };

  onFileSelect = e => {
    let files = e.target.files;
    // console.log('file-reader ', files[0])
    this.setState({ fileName: files.name });
  };

  getCSVPlan = () => {
    const url = "https://sample-videos.com/csv/Sample-Spreadsheet-10-rows.csv";
    window.open(url);
  };

  render() {
    // const { fileName, percentage, planName } = this.state
    const { planName } = this.state;
    return (
      <div className="card card-custom gutter-b example example-compact">
        <div className="add-plan-container">
          {/* <CustomModal show={this.state.showModal} handleClose={this.hideModal} > */}
          <div className="modal-title">View Plan</div>
          <div className="modal-name-tf-container">
            <input
              disabled
              placeholder="Enter Name"
              className={"modal-name-tf"}
              name="Name"
              value={planName}
            />
          </div>

          <div>
            <div>CSV file</div>
            <button className={"modal-upload"} onClick={this.getCSVPlan}>
              Get CSV file
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewPlan;
