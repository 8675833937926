import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { Menu } from "./Menu";
import { Link } from "react-router-dom";
import { IconButton, SwipeableDrawer } from "@mui/material";
import { ChevronBack } from "comundo-icons";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

export function SideDrawer({
  drawerWidth,
  isDrawerOpen,
  setIsDrawerOpen,
  children
}) {
  return (
    <>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "primary.main"
            }
          }}
          open
        >
          <DrawerHeader>
            <Box sx={{ minWidth: "102px", width: "150px" }}>
              <Link to="">
                <img alt="logo" src={"/media/logos/fabeke_white.png"} />
              </Link>
            </Box>
          </DrawerHeader>

          <Menu />
        </Drawer>

        <SwipeableDrawer
          anchor={"left"}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onOpen={() => setIsDrawerOpen(true)}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "primary.main"
            }
          }}
        >
          <DrawerHeader>
            <Box sx={{ minWidth: "102px", width: "150px" }}>
              <Link to="">
                <img alt="logo" src={"/media/logos/fabeke_white.png"} />
              </Link>
            </Box>
            <IconButton onClick={() => setIsDrawerOpen(false)}>
              <ChevronBack color={"white"} />
            </IconButton>
          </DrawerHeader>

          <Menu />
        </SwipeableDrawer>
      </Box>

      <Box
        component={"main"}
        sx={{ flexGrow: 1, p: 3, width: `calc(100% - ${drawerWidth}px)` }}
      >
        <Toolbar />
        {children}
      </Box>
    </>
  );
}
