import { gql } from "@apollo/client";

export const DELETE_TEAM_MEMBER = gql`
  mutation deleteTeamMember($customerName: String, $email: String) {
    comDeleteCustomerUser(customerName: $customerName, email: $email) {
      success
      message
    }
  }
`;
