import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4";

import App from "app/App";

const { REACT_APP_GOOGLE_ANALYTICS_UA } = process.env;

ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_UA);

ReactDOM.render(<App />, document.getElementById("root"));
