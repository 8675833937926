import { useMutation } from "@apollo/client";
// import { useAuth } from "app/providers";
import { useCallback, useState } from "react";
// import ReactGA from "react-ga4";
import { RESET_PASSWORD } from "./commands";

export function useSubmit(onCompleted = () => null, onError = () => null) {
  // const { setToken } = useAuth();
  const [resetSuccess, setResetSuccess] = useState(null);
  const [comResetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: ({ comResetPassword }) => {
      if (comResetPassword.success === false) {
        setResetSuccess(false);
        throw Error(comResetPassword.message);
      }
      setResetSuccess(true);
      localStorage.clear();
    },
    onError: error => {
      setResetSuccess(false);
      onError(error);
    }
  });

  const submit = useCallback(
    async credentials => {
      try {
        await comResetPassword({
          variables: credentials
        });

        // ReactGA.event({
        //   category: "Authentication",
        //   action: "PasswordReset",
        //   label: credentials?.email.replace("@", "[at]")
        // });
      } catch (error) {}
    },
    [comResetPassword]
  );

  return {
    submit,
    isSubmitting: loading,
    resetSuccess
  };
}
