import dateFormat from "dateformat";
import { getOr } from "lodash/fp";
import { translate, translateMonth } from "../../../common/Functions";
import * as Constants from "../../../common/Constants";

export const totalYearEnergyValue = (metersData, frmDate, tDate) => {
  let consumption = 0;
  let consumptionCo2 = 0;
  let production = 0;
  let productionCo2 = 0;
  const { data } = metersData;
  const toDate =
    new Date(new Date(tDate).setDate("01")).toISOString().split("T")[0] +
    "T00:00:00";
  const fromDate = new Date(frmDate);

  data &&
    data.map(item => {
      const date = new Date(item.ts);
      if (
        new Date(date).getTime() >= new Date(fromDate).getTime() &&
        new Date(date).getTime() <= new Date(toDate).getTime()
      ) {
        consumption += item.consumptionV;
        consumptionCo2 += item.consumptionCo2;
        production += item.productionV;
        productionCo2 += item.productionCo2;
      }
      return false;
    });
  return [consumption, consumptionCo2, production, productionCo2];
};

export const totalEnergyValue = (metersData, year) => {
  let consumption = 0;
  let consumptionCo2 = 0;
  let production = 0;
  let productionCo2 = 0;
  const { data } = metersData;

  data &&
    data.map(item => {
      const date = new Date(item.ts);
      const toDate = dateFormat(date, "yyyy");
      if (toDate.toString() === year.toString()) {
        consumption += item.consumptionV;
        consumptionCo2 += item.consumptionCo2;
        production += item.productionV;
        productionCo2 += item.productionCo2;
      }
      return false;
    });
  return [consumption, consumptionCo2, production, productionCo2];
};

export const perMonthEnergyValue = (
  metersData,
  year,
  chartType,
  startingMonth = 0,
  gettext
) => {
  let values = [];
  let months = [];
  const { data } = metersData;
  data &&
    data.map(item => {
      const date = new Date(item.ts);
      const toDate = dateFormat(date, "yyyy");
      const month = date.getMonth();
      if (
        (toDate.toString() === year.toString() && month >= startingMonth) ||
        (toDate.toString() === (year + 1).toString() && month < startingMonth)
      ) {
        months.push(gettext(dateFormat(date, "mmm")));
        if (chartType === "Consumption") {
          values.push(item.consumptionCo2);
        } else if (chartType === "Production" && item.productionCo2 > 0) {
          values.push(item.productionCo2);
        } else if (
          chartType === "Production" &&
          values.length > 0 &&
          item.productionCo2 === 0
        ) {
          values.push(item.productionCo2);
        } else if (chartType === "Compensation") {
          values.push(item.consumptionCo2 - item.productionCo2);
        }
      }
      return false;
    });
  return { values, months };
};

const energySourceLabel = (type, gettext) => {
  switch (type) {
    case "ENERGY_CONSUMPTION":
      return gettext("Energy Consumption");
    case "ENERGY_PRODUCTION":
      return gettext("Energy Production");
    case "ENERGY_COMPENSATION":
      return gettext("Energy Compensation");
    case "ELECTRICITY_CONSUMPTION":
      return gettext("Electricity Consumption");
    case "ELECTRICITY_PRODUCTION":
      return gettext("Electricity Production");
    case "ELECTRICITY_COMPENSATION":
      return gettext("Electricity Compensation");
    case "HEATING_CONSUMPTION":
      return gettext("Heating Consumption");
    case "HEATING_PRODUCTION":
      return gettext("Heating Production");
    case "HEATING_COMPENSATION":
      return gettext("Heating Compensation");
    case "GAS_CONSUMPTION":
      return gettext("Gas Consumption");
    case "GAS_PRODUCTION":
      return gettext("Gas Production");
    case "GAS_COMPENSATION":
      return gettext("Gas Compensation");
    case "WATER_CONSUMPTION":
      return gettext("Water Consumption");
    case "WATER_PRODUCTION":
      return gettext("Water Production");
    case "WATER_COMPENSATION":
      return gettext("Water Compensation");
    default:
      break;
  }
};

export const getColorType = (type, sourceType, colors, colorType) => {
  if (colorType === undefined) {
    if (type === "consumption" && sourceType === "energy") {
      return getOr(Constants.ENERGY_COLOR, "energy.shade1.background", colors);
    } else if (type === "consumption" && sourceType === "electricity") {
      return getOr(
        Constants.ELECTRICITY_COLOR,
        "electricity.shade1.background",
        colors
      );
    } else if (type === "consumption" && sourceType === "heating") {
      return getOr(Constants.HEAT_COLOR, "heating.shade1.background", colors);
    } else if (type === "consumption" && sourceType === "gas") {
      return getOr(Constants.GAS_COLOR, "gas.shade1.background", colors);
    } else if (type === "consumption" && sourceType === "water") {
      return getOr(Constants.WATER_COLOR, "water.shade1.background", colors);
    } else if (type === "production") {
      return getOr(
        Constants.PRODUCTIION_COLOR,
        "production.shade1.background",
        colors
      );
    } else if (type === "compensation") {
      return getOr(
        Constants.COMPENSATIION_COLOR,
        "compensation.shade1.background",
        colors
      );
    }
  } else if (colorType !== undefined) {
    if (type === "consumption" && sourceType === "energy") {
      return getOr(Constants.CO2_COLOR, "energy.shade1.background", colors);
    } else if (type === "consumption" && sourceType === "electricity") {
      return getOr(
        Constants.CO2_COLOR,
        "electricity.shade1.background",
        colors
      );
    } else if (type === "consumption" && sourceType === "heating") {
      return getOr(Constants.HEAT_COLOR, "heating.shade1.background", colors);
    } else if (type === "consumption" && sourceType === "gas") {
      return getOr(Constants.GAS_COLOR, "gas.shade1.background", colors);
    } else if (type === "consumption" && sourceType === "water") {
      return getOr(Constants.CO2_COLOR, "water.shade1.background", colors);
    } else if (type === "production") {
      return getOr(
        Constants.PRODUCTIION_COLOR,
        "production.shade1.background",
        colors
      );
    } else if (type === "compensation") {
      return getOr(
        Constants.COMPENSATIION_COLOR,
        "compensation.shade1.background",
        colors
      );
    }
  }
};

export const dataEnergyGraph = (
  data,
  years,
  label,
  type,
  sourceType,
  colors,
  gettext,
  colorType
) => {
  const chartId = `co2-chart-${label}`;
  const energyLabel = energySourceLabel(label, gettext);
  const chartTitle = `${gettext("Total CO₂ per year from")} ${energyLabel}`;
  const yaxisTitleText = gettext("Tons CO₂");
  let colorArray = [];
  let chartData = [];

  colorArray.push(getColorType(type, sourceType, colors, colorType));
  chartData.push({
    name: energyLabel,
    data: data
  });

  let graphOptions = {
    unique_id: chartId,
    type: "bar",
    chart_title: chartTitle,
    categories: years,
    stacked: true,
    dataLabelsEnabled: false,
    plotOptionsBarDistributed: false,
    yaxisTitleText: yaxisTitleText,
    colors: colorArray,
    data: chartData
  };

  return graphOptions;
};

// Private functions
// const co2PdfAllPagesData = data => {
//   return new Promise((resolve, reject) => {
//     let page1 = co2PdfPage1(data);
//     let finaleData = {
//       page1: page1,
//       page2: page1,
//       page3: page1,
//       page4: page1,
//       page5: page1,
//       page6: page1,
//       page7: page1,
//       page8: page1,
//       page9: page1,
//       page13: page1
//     };
//     resolve(finaleData);
//   });
// };

// const co2PdfPage1 = data => {
//   let company = data.customers_names ? data.customers_names : "...";
//   let address = data.buildings ? data.buildings : "...";
//   let finalData = {
//     heading: 1translate12("MENU.CARBON_REPORT"),
//     company: company,
//     address
//   };
//   return finalData;
// };

export const divide3SourceGraph = (
  eArray,
  hArray,
  wArray,
  gArray,
  years,
  label,
  type,
  sourceType,
  chartId,
  colors,
  gettext,
  chartTitle,
  stacked
) => {
  let colorArray = [];
  let chartData = [];

  let yaxisTitleText = gettext("Tons CO₂");
  // const chartTitle = gettext("Annual CO₂ reductions measured in tonnes");
  let eLabel = gettext("Electricity");
  let hLabel = gettext("Heating");
  let wLabel = gettext("Water");
  let EColor = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade1.background",
    colors
  );
  let HColor = getOr(Constants.HEAT_COLOR, "heating.shade1.background", colors);
  let WColor = getOr(Constants.WATER_COLOR, "water.shade1.background", colors);

  if (type === "Production") {
    eLabel = gettext("Electricity");
    hLabel = gettext("Heating");
    wLabel = gettext("Water");
    EColor = getOr(
      Constants.ELECTRICITY_COLOR,
      "production.shade1.background",
      colors
    );
    HColor = getOr(
      Constants.HEAT_COLOR,
      "production.shade1.background",
      colors
    );
    WColor = getOr(
      Constants.WATER_COLOR,
      "production.shade1.background",
      colors
    );
  } else if (type === "Compensation") {
    eLabel = gettext("Electricity");
    hLabel = gettext("Heating");
    wLabel = gettext("Water");
  }

  colorArray.push(EColor);
  chartData.push({
    name: eLabel,
    data: eArray
  });

  // let newHeatingData = [];

  // if (gArray?.length) {
  //     if (hArray?.length) {
  //         let gArrayFlag = false;
  //         if (gArray?.length > hArray?.length) {
  //             gArrayFlag = true;
  //             newHeatingData = JSON.parse(JSON.stringify(gArray));
  //         } else if (gArray?.length < hArray?.length) {
  //             gArrayFlag = false;
  //             newHeatingData = JSON.parse(JSON.stringify(hArray));
  //         }

  //         let CombinedData = [];
  //         newHeatingData?.forEach((num, idx) => {
  //             let value = !gArrayFlag ? gArray[idx] : hArray[idx];
  //             CombinedData.push(
  //                 parseFloat(
  //                     parseFloat(num) + parseFloat(value ? value : 0)
  //                 ).toFixed(2)
  //             );
  //         });

  //         newHeatingData = CombinedData;
  //     }
  // } else if (hArray?.length) {
  //     newHeatingData = JSON.parse(JSON.stringify(hArray));
  // }

  if (hArray.some(el => el > 0) || gArray.some(el => el > 0)) {
    colorArray.push(HColor);

    chartData.push({
      name: hLabel,
      // Adding One more Check for heating and GAS data //
      data:
        gArray[0] === 0
          ? hArray
          : hArray[0] === 0
          ? gArray
          : sumHeatingAndGasArrays(gArray, hArray)
    });
  }

  if (wArray.some(el => el > 0)) {
    colorArray.push(WColor);
    chartData.push({
      name: wLabel,
      data: wArray
    });
  }

  let graphOptions = {
    unique_id: chartId,
    type: "bar",
    chart_title: chartTitle,
    categories: years,
    stacked: stacked,
    dataLabelsEnabled: false,
    plotOptionsBarDistributed: false,
    yaxisTitleText: yaxisTitleText,
    colors: colorArray,
    data: chartData
  };
  return graphOptions;
};
const sumHeatingAndGasArrays = (gArray, hArray) => {
  let newHeatingData = [];
  if (gArray?.length) {
    if (hArray?.length) {
      let gArrayFlag = false;
      if (gArray?.length > hArray?.length) {
        gArrayFlag = true;
        newHeatingData = JSON.parse(JSON.stringify(gArray));
      } else if (gArray?.length < hArray?.length) {
        gArrayFlag = false;
        newHeatingData = JSON.parse(JSON.stringify(hArray));
      }

      let CombinedData = [];
      newHeatingData?.forEach((num, idx) => {
        let value = !gArrayFlag ? gArray[idx] : hArray[idx];
        CombinedData.push(
          parseFloat(parseFloat(num) + parseFloat(value ? value : 0)).toFixed(2)
        );
      });

      newHeatingData = CombinedData;
    }
  } else if (hArray?.length) {
    newHeatingData = JSON.parse(JSON.stringify(hArray));
  }
  return newHeatingData;
};

export const co2ReportLeftGraph = (
  eArray,
  hArray,
  gArray,
  wArray,
  months,
  year,
  label,
  type,
  sourceType,
  chartId1,
  chartType,
  colors,
  gettext,
  chartTitle,
  sourceIndex,
  grid,
  background,
  legend
) => {
  let colorArray = [];
  let chartData = [];

  let yaxisTitleText = sourceIndex === 2 ? "" : gettext("Tons CO₂");

  let eLabel = gettext("Electricity");
  let hLabel = gettext("Heating");
  let wLabel = gettext("Water");
  let EColor = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade1.background",
    colors
  );
  let HColor = getOr(Constants.HEAT_COLOR, "heating.shade1.background", colors);
  let WColor = getOr(Constants.WATER_COLOR, "water.shade1.background", colors);

  if (chartType === "Production") {
    eLabel = gettext("Electricity");
    hLabel = gettext("Heating");
    wLabel = gettext("Water");
    EColor = getOr(
      Constants.ELECTRICITY_COLOR,
      "production.shade1.background",
      colors
    );
    HColor = getOr(
      Constants.HEAT_COLOR,
      "production.shade1.background",
      colors
    );
    WColor = getOr(
      Constants.WATER_COLOR,
      "production.shade1.background",
      colors
    );
  } else if (chartType === "Compensation") {
    eLabel = gettext("Electricity");
    hLabel = gettext("Heating");
    wLabel = gettext("Water");
  }

  chartData.push({
    name: eLabel,
    data: eArray
  });

  colorArray.push(EColor);

  // colorArray.push(EColor);

  //

  // if (hArray.some((el) => el > 0) || gArray.some((el) => el > 0)) {

  if (hArray.length || gArray.length) {
    colorArray.push(HColor);
    chartData.push({
      name: hLabel,
      data:
        gArray[0] === 0
          ? hArray
          : hArray[0] === 0
          ? gArray
          : sumHeatingAndGasArrays(gArray, hArray)
    });
  }
  if (wArray.length > 0) {
    colorArray.push(WColor);
    chartData.push({
      name: wLabel,
      data: wArray
    });
  }

  let graphOptions = {
    unique_id: chartId1,
    type: "bar",
    chart_title: chartTitle,
    categories: months,
    stacked: true,
    dataLabelsEnabled: false,
    plotOptionsBarDistributed: false,
    yaxisTitleText: yaxisTitleText,
    colors: colorArray,
    data: chartData,
    grid,
    background,
    legend
  };
  return graphOptions;
};

export const co2ReportRightGraph = (
  eValue,
  hValue,
  gValue,
  wValue,
  year,
  label,
  type,
  sourceType,
  chartId1,
  chartType,
  colors,
  gettext,
  chartTitle
) => {
  let colorArray = [];
  let chartData = [];
  let labels = [];

  let eLabel = gettext("Electricity");
  let hLabel = gettext("Heating");
  let wLabel = gettext("Water");

  if (chartType === "Production") {
    eLabel = gettext("Electricity");
    hLabel = gettext("Heating");
    wLabel = gettext("Water");
  } else if (chartType === "Compensation") {
    eLabel = gettext("Electricity");
    hLabel = gettext("Heating");
    wLabel = gettext("Water");
  }

  const totalEnergyCo2Year = Number(eValue + hValue + wValue);

  const perElectricityConsCo2Year = Number((eValue * 100) / totalEnergyCo2Year);

  const perGasCo2Year = Number((gValue * 100) / totalEnergyCo2Year);

  const perHeatingCo2Year = Number((hValue * 100) / totalEnergyCo2Year);
  const perWaterCo2Year = Number((wValue * 100) / totalEnergyCo2Year);

  const EColor = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade1.background",
    colors
  );
  const HColor = getOr(
    Constants.HEAT_COLOR,
    "heating.shade1.background",
    colors
  );
  const WColor = getOr(
    Constants.WATER_COLOR,
    "water.shade1.background",
    colors
  );

  colorArray.push(EColor);

  //
  chartData.push(perElectricityConsCo2Year);
  labels.push(eLabel);

  if (hValue > 0 || gValue > 0) {
    colorArray.push(HColor);
    chartData.push(perHeatingCo2Year + perGasCo2Year);
    labels.push(hLabel);
  }

  if (wValue > 0) {
    colorArray.push(WColor);
    chartData.push(perWaterCo2Year);
    labels.push(wLabel);
  }

  let graphOptions = {
    unique_id: chartId1,
    type: "pie",
    chart_title: chartTitle,
    labels,
    colors: colorArray,
    data: chartData
  };

  return graphOptions;
};

export const co2ReportGraphMonthly = (
  year1,
  year2,
  year3,
  fromYear,
  secondYear,
  toYear,
  sourceType,
  chartId,
  chartType,
  chartPageTitle,
  colors,
  gettext,
  picColor
) => {
  let chartData = [];
  const yaxisTitleText = gettext("Tons CO₂");
  const chartTitle = translate(chartPageTitle, {
    year1: toYear,
    year2: secondYear,
    year3: fromYear
  });

  const EColor = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade1.background",
    colors
  );
  const HColor = getOr(
    Constants.HEAT_COLOR,
    "heating.shade1.background",
    colors
  );
  const WColor = getOr(
    Constants.WATER_COLOR,
    "water.shade1.background",
    colors
  );
  // colorArray.push(Co2Color);

  const colorArray = {
    electricity: [
      EColor,
      EColor,
      EColor
      // Constants.YEAR1_ELECTRICITY_COLOR,
      // Constants.YEAR2_ELECTRICITY_COLOR,
      // Constants.YEAR3_ELECTRICITY_COLOR
      // Constants.YEAR_PRODUCTION_COLOR
    ],
    heating: [
      HColor,
      HColor,
      HColor
      // Constants.YEAR1_HEAT_COLOR,
      // Constants.YEAR2_HEAT_COLOR,
      // Constants.YEAR3_HEAT_COLOR,
      // Constants.YEAR_PRODUCTION_COLOR,
      // Constants.YEAR_PRODUCTION_COLOR,
      // Constants.YEAR_PRODUCTION_COLOR
    ],
    water: [
      WColor,
      WColor,
      WColor
      // Constants.YEAR1_WATER_COLOR,
      // Constants.YEAR2_WATER_COLOR,
      // Constants.YEAR3_WATER_COLOR,
      // Constants.YEAR_PRODUCTION_COLOR,
      // Constants.YEAR_PRODUCTION_COLOR,
      // Constants.YEAR_PRODUCTION_COLOR
    ]
  };

  chartData.push({
    name: `${chartType} ${fromYear}`,
    data: year1
  });
  chartData.push({
    name: `${chartType} ${secondYear}`,
    data: year2
  });
  chartData.push({
    name: `${chartType} ${toYear}`,
    data: year3
  });

  let categories = [];
  for (let i = 1; i <= 12; i++) {
    categories.push(translateMonth(i, "short"));
  }

  let graphOptions = {
    unique_id: chartId,
    type: "bar",
    chart_title: chartTitle,
    categories: categories,
    stacked: false,
    dataLabelsEnabled: false,
    plotOptionsBarDistributed: false,
    yaxisTitleText: yaxisTitleText,
    colors: colorArray[sourceType],
    data: chartData
  };

  return graphOptions;
};
