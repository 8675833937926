import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Spinner } from "react-bootstrap";
import { GET_ACCOUNT_GROUPS } from "../../../../GraphQL/Queries";
import { CREATE_ACCOUNT_GROUPS } from "../../../../GraphQL/Mutations";
import AccountGroupForm from "./account-group-form";
import { GoBackButton, AccountChartHeader } from "./groupStyle";

const AccountGroupCreate = () => {
  const navigate = useNavigate();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [nameDa, setNameDa] = useState("");
  const [nameDe, setNameDe] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [type, setType] = useState("");

  const [accountGroup, { loading }] = useMutation(CREATE_ACCOUNT_GROUPS, {
    onCompleted() {
      navigate(`/admin/account-groups`);
    },
    onError(error) {
      // error.graphQLErrors.map(({ message }) => {
      //   // console.log("Error =>", error);
      // });
    }
  });

  const createAccountGroup = () => {
    accountGroup({
      variables: {
        nameDa: nameDa,
        nameDe: nameDe,
        nameEn: nameEn,
        type: type
      },
      refetchQueries: [{ query: GET_ACCOUNT_GROUPS }]
    });
  };

  const hanleGoBack = () => {
    navigate(`/admin/account-groups`);
  };

  const validForm = () => nameDa && nameDe && nameEn && type;

  return (
    <>
      <GoBackButton className="row m-0">
        <div className="col-sm-12 p-0">
          <div className="form-group col-md-2 p-0">
            <button
              type="button"
              className="btn btn-outline-info"
              onClick={hanleGoBack}
            >
              <AiOutlineArrowLeft title="back" size={18} className="mr-2" />
              {gettext("Back")}
            </button>
          </div>
        </div>
      </GoBackButton>
      <AccountChartHeader className="card card-custom gutter-b example example-compact">
        <div className="row m-0 table-title">
          <div className="col-sm-12 title">
            <h3>{gettext("New Account Group")}</h3>
          </div>
        </div>
        <>
          <AccountGroupForm
            nameDa={nameDa}
            nameDe={nameDe}
            nameEn={nameEn}
            type={type}
            setNameDa={setNameDa}
            setNameDe={setNameDe}
            setNameEn={setNameEn}
            setType={setType}
          />

          <div className="row m-0 border-top-row">
            <div className="col-sm-12 create-action">
              <div className="form-group col-md-12">
                <button
                  type="submit"
                  onClick={createAccountGroup}
                  disabled={!validForm() || loading}
                >
                  {gettext("Create Account Group")} &nbsp;
                  {loading && (
                    <Spinner
                      className="btn-spinner-delete"
                      animation="border"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      </AccountChartHeader>
    </>
  );
};

export default AccountGroupCreate;
