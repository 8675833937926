import React from "react";
import { Grid } from "@mui/material";
import ScrapperListTable from "./components/ScrapperListTable";
import { useScrapperInfo } from "./hooks/useScrapperLists";

export function ScraperList(props) {
  const { gettext, search, queryFilter } = props;

  const [selectedFilters, setSeletedFilters] = React.useState(queryFilter);

  const { infoList, loading } = useScrapperInfo(search, selectedFilters);

  return (
    <Grid item>
      <ScrapperListTable
        rows={infoList}
        gettext={gettext}
        loading={loading}
        setSeletedFilters={setSeletedFilters}
      />
    </Grid>
  );
}
