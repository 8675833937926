import React, { useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import { useLoginAs } from "../hooks";

const LoginModel = props => {
  const { show, onHide, editableRow } = props;

  const { loginAs } = useLoginAs();

  const handleLoginAsClick = useCallback(
    email => {
      loginAs(email);
    },
    [loginAs]
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Login as</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to login as <b>{editableRow?.name}</b>?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => handleLoginAsClick(editableRow?.email)}
        >
          Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModel;
