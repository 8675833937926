export const GET_TEAM_USERS_USERS = "v1/team_users/users/";
export const CUSTOMER_ADDRESSES = "address/customer/";
export const GET_CUSTOMERS_URL = "user/teams/";

export const GET_ALLROLES_URL = "v1/roles";
export const GET_USER_URL = "v1/users/get_my_users";

export const REPORT_CONSUMER_URL = "data/v2/api/by_address?";

export const GET_LOGIN_AS = "user/login_as";

export const GET_CUSTOMER_AGGREGATED_DATA = "v1/buildings/meters/data";

export const GET_SCRAPPER_LOG = "v1/admin/scrapper_logs";
