import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { isLocationActive } from "app/common/Methods";
import { alpha } from "@mui/material";

export default function Level2MenuHead({
  link,
  label,
  icon,
  children,
  display
}) {
  const location = useLocation();

  const [activeColor, setactiveColor] = useState("");

  const [open, setOpen] = useState(false);

  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    const active = isLocationActive(location, link);

    setisActive(active);

    setOpen(active);
  }, [location, link]);

  useEffect(() => {
    setactiveColor(isActive ? "white" : "primary.200");
  }, [isActive]);

  const handleClick = () => {
    setOpen(prevopen => !prevopen);
  };

  return (
    <>
      {display && (
        <>
          <ListItem disablePadding sx={{ color: activeColor }}>
            <ListItemButton
              onClick={handleClick}
              disableGutters
              sx={{
                borderRadius: "8px",
                backgroundColor: isActive
                  ? alpha("#FFFFFF", 0.28)
                  : "transparent"
              }}
            >
              {icon}
              <Typography variant="h6" sx={{ color: activeColor }}>
                {label}
              </Typography>
            </ListItemButton>
          </ListItem>

          <Collapse in={open} timeout="auto">
            <List component="div">{children}</List>
          </Collapse>
        </>
      )}
    </>
  );
}
