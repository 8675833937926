import React from "react";
import { PartnerMembersListTable } from "app/features";

export function PartnerMembersList() {
  return (
    <>
      <PartnerMembersListTable />
    </>
  );
}
