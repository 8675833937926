import { gql } from "@apollo/client";

export const EDIT_METER = gql`
  mutation updateMeter($buildingId: String!, $data: UpdateMeterDataInput!) {
    updateMeter(buildingId: $buildingId, data: $data) {
      success
      message
    }
  }
`;
export const MOVE_METER = gql`
  mutation customerMoveBuildingMeter(
    $addressName: String!
    $data: MoveBuildingMeterInput!
  ) {
    customerMoveBuildingMeter(addressName: $addressName, data: $data) {
      success
      message
    }
  }
`;
