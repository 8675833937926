import { gql } from "@apollo/client";

export const SCRAPPER_LIST = gql`
  query scrapperInfo($search: String, $queryFilter: ScraperFilterInput) {
    scrapperInfo(search: $search, queryFilter: $queryFilter) {
      Id
      autoId0
      country
      createdAt
      description
      scrapperName
      status
      type
      url
    }
  }
`;
