import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { UPDATE_USER_BY_USER } from "../commands";

export function useUpdateUser(onCompleted = () => null, onError = () => null) {
  const [comUpdateUserAdmin, { loading }] = useMutation(UPDATE_USER_BY_USER, {
    onCompleted: () => {
      onCompleted();
    },
    onError: () => {
      onError();
    },
    refetchQueries: ["comMyUsers"]
  });

  const updateUser = useCallback(
    async variables => {
      comUpdateUserAdmin({
        variables
      });
    },
    [comUpdateUserAdmin]
  );

  return {
    updateUser,
    updating: loading
  };
}
