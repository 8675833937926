import { useState, useEffect } from "react";
import { Button, Menu, Stack, MenuItem, CircularProgress } from "@mui/material";
import { IoChevronDown } from "react-icons/io5";
import useStyles from "../styles";
import { capitalizeFirstLetter } from "hooks/useCommon";
import {
  useBuildingEnergyPricesFile,
  useEnergyPricesFile
} from "hooks/EnergyPrices/useEnergyPrices";
import { getOr } from "lodash/fp";

const options = ["excel", "csv"];

const fileDownloadHook = function(type) {
  if (type === "building") {
    return useBuildingEnergyPricesFile;
  } else if (type === "country") {
    return useEnergyPricesFile;
  }
};

const FileSelection = props => {
  const {
    selectedDate,
    selectedBuilding,
    type,
    selectedCountry,
    selectedCurrency,
    gettext
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const [selectedFileType, selectFileType] = useState("");
  const { data: fileData, loading: loadingFile } = fileDownloadHook(type)(
    selectedDate,
    selectedBuilding?.name || selectedBuilding || selectedCountry || "",
    selectedFileType,
    selectedCurrency
  );

  useEffect(() => {
    if (
      fileData?.meterDataWithTaxes?.fileUrl ||
      fileData?.dataResourcePrice?.fileUrl
    ) {
      const fileUrl = getOr(
        null,
        type === "building"
          ? "meterDataWithTaxes.fileUrl"
          : type === "country"
          ? "dataResourcePrice.fileUrl"
          : "",
        fileData
      );
      window.open(fileUrl);
      selectFileType("");
    }
  }, [fileData, type]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={
          loadingFile ? (
            <CircularProgress size={12} />
          ) : (
            <IoChevronDown size={12} />
          )
        }
        color="secondary"
        onClick={handleClick}
        className={classes.currencyDropdown}
        disabled={loadingFile}
        sx={{ fontSize: "14px" }}
      >
        {gettext("Download")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { p: 0 }
        }}
        PaperProps={{
          sx: {
            p: 1,
            borderRadius: "8px",
            marginTop: "8px",
            overflow: "hidden"
          }
        }}
      >
        <Stack
          spacing={2}
          sx={{
            "&:focus": {
              outline: "none"
            },
            minWidth: "165px"
          }}
        >
          {options.map(type => (
            <MenuItem
              onClick={() => {
                handleClose();
                selectFileType(type);
              }}
            >
              {gettext(capitalizeFirstLetter(type))}
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </>
  );
};

export default FileSelection;
