import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { UPDATE_ROLE_MODULES } from "../commands";

export function useUpdateRoleModule() {
  const [comUpdateRoleModule, { loading }] = useMutation(UPDATE_ROLE_MODULES, {
    refetchQueries: ["getRoleModules"]
  });

  const update = useCallback(
    variables => {
      comUpdateRoleModule({
        variables
      });
    },
    [comUpdateRoleModule]
  );

  return {
    update,
    updating: loading
  };
}
