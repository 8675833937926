// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Alert(theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center"
        },
        outlined: {
          border: "none",
          borderLeft: "2.5px solid",
          "&.MuiAlert-outlinedSuccess": {
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main
          },
          "&.MuiAlert-outlinedInfo": {
            color: theme.palette.info.main,
            borderColor: theme.palette.info.main
          },
          "&.MuiAlert-outlinedWarning": {
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main
          },
          "&.MuiAlert-outlinedError": {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main
          }
        }
      }
    }
  };
}
