import { GoBack } from "app/components";
import { useUser } from "app/providers";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BuildingListTable } from "../../../features";

export function Administration() {
  const location = useLocation();

  const [writeAccess, setWriteAccess] = useState(false);

  useEffect(() => {
    setWriteAccess(location.pathname.split("/")[1] !== "partner");
  }, [location]);

  const { user } = useUser();

  const { currentTeam } = user || {};

  return (
    <>
      <GoBack
        prevUrl={
          location.pathname.split("/administration")[0] + "/administration"
        }
      />

      <BuildingListTable customer={currentTeam} writeAccess={writeAccess} />
    </>
  );
}
