import { createGlobalStyle } from "styled-components";

export const DatePickerStyles = createGlobalStyle`
      .react-datepicker__triangle::before {
        display: none;
      }
      .react-datepicker__triangle::after {
        display: none;
      }
      .react-datepicker__header {
        border: none;
        background: white;
      }
      .react-datepicker.date-picker-calendar {
        border-radius: 8px;
        border: 1px solid #FAFAFA;
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      }
      .react-datepicker__day{
        border-radius: 0;
      }
      .react-datepicker__day.react-datepicker__day--selected {
        background-color: #015731;
      }
`;
