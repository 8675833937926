import { Grid } from "@mui/material";
import { EpcBody, EpcFilters } from "app/features";
import { EpcFilterProvider } from "app/providers";
import React from "react";

export function Epc() {
  return (
    <EpcFilterProvider>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EpcFilters />
        </Grid>

        <Grid item xs={12}>
          <EpcBody />
        </Grid>
      </Grid>
    </EpcFilterProvider>
  );
}
