import React from "react";
// import GEnergyTypes from "./GEnergyTypes";
import EnergyCollectionTypes from "./EnergyCollectionTypes";

const Electricity = () => {
  return (
    <>
      {/* <GEnergyTypes type="electricity" sourceType="Electricity" /> */}
      <EnergyCollectionTypes type="electricity" sourceType="Electricity" />
    </>
  );
};
export default Electricity;
