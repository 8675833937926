import { useMutation } from "@apollo/client";
import { useAuth } from "app/providers";
import { useCallback } from "react";
import ReactGA from "react-ga4";
import { LOGIN } from "./commands";

export function useSubmit(onCompleted = () => {}, onError = () => {}) {
  const { setToken } = useAuth();

  const [comLogin, { loading }] = useMutation(LOGIN, {
    onCompleted: ({ comLogin }) => {
      localStorage.clear();

      if (comLogin?.success) {
        if (comLogin?.token) {
          onCompleted();
          setToken(comLogin?.token);
        }
      } else {
        onError(comLogin?.message[0]);
      }
    },
    onError: error => {
      onError(error?.message);
    }
  });

  const submit = useCallback(
    async credentials => {
      try {
        await comLogin({
          variables: credentials
        });

        ReactGA.event({
          category: "Authentication",
          action: "Login",
          label: credentials?.email.replace("@", "[at]")
        });
      } catch (error) {}
    },
    [comLogin]
  );

  return {
    submit,
    isSubmitting: loading
  };
}
