import { useQuery } from "@apollo/client";
import { GET_ALL_ROLES } from "../commands";

export function useAllRoles() {
  const { data, loading } = useQuery(GET_ALL_ROLES);

  return {
    allRoles: data?.comRoles || [],
    loading
  };
}
