import { Button } from "@mui/material";
import { useTextdomainContext } from "app/providers";
import { useSnackbar } from "notistack";
import React from "react";

export function CopyButton(props) {
  const { text, children } = props;

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(gettext("Copied!"), {
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" }
    });
  };
  return (
    <Button {...props} onClick={handleCopy}>
      {children}
    </Button>
  );
}
