import { NoDashboardWarning } from "app/components";
import { TotalKwhDashboard, TotalKwhM2Dashboard } from "app/elements";
import { useDashboardFilter } from "app/providers";
import React from "react";
import { TotalKwhPerEnergySourceM2, KwhM2 } from "../../Dashboards";
import TotalKwhPerEnergySource from "../../Dashboards/TotalKwhPerEnergySource";
import { SnapshotWrapper } from "./components";

export function SelectedDashboard() {
  const { selectedDashboard } = useDashboardFilter();

  switch (selectedDashboard.key) {
    case "1001":
      return (
        <SnapshotWrapper>
          <TotalKwhDashboard />
        </SnapshotWrapper>
      );

    case "1002":
      return (
        <SnapshotWrapper>
          <TotalKwhPerEnergySource />
        </SnapshotWrapper>
      );

    case "1003":
      return (
        <SnapshotWrapper>
          <TotalKwhM2Dashboard />
        </SnapshotWrapper>
      );

    case "1004":
      return (
        <SnapshotWrapper>
          <TotalKwhPerEnergySourceM2 />
        </SnapshotWrapper>
      );

    case "1005":
      return (
        <SnapshotWrapper>
          <KwhM2 />
        </SnapshotWrapper>
      );

    default:
      return <NoDashboardWarning />;
  }
}
