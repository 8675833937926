import { useQuery } from "@apollo/client";
import { GET_CUSTOMERS_BUILDING_METERS } from "../commands";

export function useCustomersBuildings(
  customerName,
  onCompleted = () => {},
  onError = () => {}
) {
  const { data, loading } = useQuery(GET_CUSTOMERS_BUILDING_METERS, {
    variables: {
      customerName
    },
    onCompleted: ({ comCustomerAddresses }) => {
      onCompleted(comCustomerAddresses);
    },
    onError,
    fetchPolicy: "network-only"
  });

  return {
    buildings: data?.comCustomerAddresses || [],
    loading
  };
}
