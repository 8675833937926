import { Box, Grid, Typography } from "@mui/material";
import { useTextdomainContext } from "app/providers";
import React from "react";

export function NoDashboardWarning() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      display="flex"
      sx={{ height: "70vh" }}
    >
      <Grid item>
        <Box component="div" whiteSpace="normal" textAlign="center">
          <img
            src="/media/NoGraphData.png"
            alt="No Data Found"
            width="150"
            height="40"
            className="pt-5"
          />

          <Typography
            variant="body1"
            color="primary"
            sx={{
              mt: 1,
              fontSize: 24,
              fontWeight: 500,
              lineheight: 24
            }}
            textOverflow="hidden"
          >
            {gettext("No Dashboard Selected!")}
          </Typography>
          <Typography
            variant="caption"
            color="gray"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              lineheight: 16
            }}
          >
            {gettext("Please select atleast one dashboard")}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
