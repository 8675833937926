import { Grid } from "@mui/material";
import { LineChart, NoBuildingWarning, NoCompanyWarning } from "app/components";
import { useDashboardFilter, useTextdomainContext } from "app/providers";
import React from "react";
import { useTotalKwhDasboardData } from "./hooks";

export function TotalKwhDashboard() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const {
    loading,
    catagories,
    EcInKwhSeries,
    Co2InKwhSeries,
    EcInKwhColors,
    Co2InKwhColors
  } = useTotalKwhDasboardData();

  const { selectedCompanies, selectedBuildings } = useDashboardFilter();

  if (!selectedCompanies.length) return <NoCompanyWarning />;

  if (!loading && !selectedBuildings.length) return <NoBuildingWarning />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LineChart
          title={gettext("Energy consumption measured in kWh")}
          catagories={catagories}
          data={EcInKwhSeries}
          colors={EcInKwhColors}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12}>
        <LineChart
          title={gettext("CO₂ eq measured in tons")}
          catagories={catagories}
          data={Co2InKwhSeries}
          colors={Co2InKwhColors}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}
