import { Box } from "@mui/material";
import { RegistrationFlowStepProvider, useUser } from "app/providers";
import React, { useRef } from "react";
import { Navigate } from "react-router-dom";
import { AppBar } from "./components";
import { Core } from "./core";

export function RegistrationFlow() {
  const appbarRef = useRef(null);
  const offsetHeight = appbarRef?.current?.offsetHeight;

  const { user } = useUser();

  const { isRegistered, isVerified } = user || {};

  return isRegistered && isVerified ? (
    <Navigate to="/" />
  ) : isRegistered ? (
    <Navigate to="/verification-email" />
  ) : (
    <RegistrationFlowStepProvider>
      <Box minHeight={"100%"}>
        <AppBar ref={appbarRef} />

        <Core offsetHeight={offsetHeight} />
      </Box>
    </RegistrationFlowStepProvider>
  );
}
