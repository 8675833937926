import { gql } from "@apollo/client";

export const REGISTER = gql`
  mutation comRegister(
    $countryCode: String!
    $customerType: String!
    $email: String!
    $lang: String!
    $name: String!
    $password: String!
    $timezone: String!
    $token: String
  ) {
    comRegister(
      countryCode: $countryCode
      customerType: $customerType
      email: $email
      lang: $lang
      name: $name
      password: $password
      timezone: $timezone
      token: $token
    ) {
      success
      message
      token
    }
  }
`;
