import { useState, useEffect } from "react";
import MeterDetailsDaily from "./MeterDetailsDaily";
import MeterDetailsMonthly from "./MeterDetailsMonthly";
import MeterDetailsYearly from "./MetersDataYearly";

const DatahubMeterDetails = props => {
  const { view, setView, meteringPointId, gettext } = props;
  const [date, setDate] = useState(new Date());
  const [selectedSegment, setSelectedSegment] = useState(0);

  const [newDate, setNewDate] = useState(new Date());

  function formatDate(dateString, segment) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    if (segment === 0) {
      return `${year}-${month}-${day}`;
    } else if (segment === 1) {
      return `${year}-${month}`;
    } else if (segment === 2) {
      return `${year}`;
    } else {
      return "Invalid segment value";
    }
  }

  useEffect(() => {
    setNewDate(formatDate(date, selectedSegment));
  }, [selectedSegment, date]);

  return (
    <>
      {selectedSegment === 0 ? (
        <MeterDetailsDaily
          view={view}
          setView={setView}
          meteringPointId={meteringPointId}
          date={newDate}
          setDate={setDate}
          selectedSegment={selectedSegment}
          setSelectedSegment={setSelectedSegment}
          gettext={gettext}
        />
      ) : selectedSegment === 1 ? (
        <MeterDetailsMonthly
          view={view}
          setView={setView}
          meteringPointId={meteringPointId}
          date={newDate}
          setDate={setDate}
          selectedSegment={selectedSegment}
          setSelectedSegment={setSelectedSegment}
          gettext={gettext}
        />
      ) : (
        <MeterDetailsYearly
          view={view}
          setView={setView}
          meteringPointId={meteringPointId}
          date={newDate}
          setDate={setDate}
          selectedSegment={selectedSegment}
          setSelectedSegment={setSelectedSegment}
          gettext={gettext}
        />
      )}
    </>
  );
};
export default DatahubMeterDetails;
