import React, { useState, useContext } from "react";
import { DataGridComponent, DeleteConfirmation } from "app/components";
import { IconButton, Tooltip } from "@mui/material";
import { FaBuilding } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { usePartnerMembers, useRemove } from "./hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser, useTextdomainContext } from "app/providers";

export function PartnerMembersListTable(props) {
  const navigate = useNavigate();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { partner } = props;

  const location = useLocation();

  const { user } = useUser();

  const { customerKey } = user || {};

  const [confirmationOpen, setconfirmationOpen] = useState(false);

  const [label, setlabel] = useState("");

  const { members, loading: fetching } = usePartnerMembers(
    partner ? partner : customerKey
  );

  const { loading: deleting, remove } = useRemove(() => {
    handleConfirmationClose();
  });

  const handleConfirmationOpen = () => setconfirmationOpen(true);
  const handleConfirmationClose = () => setconfirmationOpen(false);

  const handleRemoveCustomer = customer => {
    setlabel(customer?.name);
    handleConfirmationOpen();
  };
  const handleCustomerBuildings = customer => {
    navigate(`${location.pathname}/${customer.id}/buildings`);
  };

  function columnFormater(params) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Tooltip title={gettext("Delete")} arrow>
          <IconButton onClick={() => handleRemoveCustomer(params.row)}>
            <MdDelete size={18} />
          </IconButton>
        </Tooltip>

        <Tooltip title={gettext("Buildings")} arrow>
          <IconButton onClick={() => handleCustomerBuildings(params.row)}>
            <FaBuilding size={18} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  const columns = [
    {
      field: "name",
      headerName: gettext("Name"),
      flex: 2,
      headerAlign: "left"
    },
    {
      field: "address",
      headerName: gettext("Address"),
      flex: 1.5,
      headerAlign: "left"
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      flex: 0.8,
      headerAlign: "center",
      renderCell: params => columnFormater(params)
    }
  ];

  return (
    <>
      <div className="card card-custom example example-compact">
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3 className="custom-header-list">
              <span>{gettext("Members")}</span>
            </h3>
          </div>

          {/* end::Header */}

          <div className="card-body customer-list">
            <DataGridComponent
              rows={members}
              columns={columns}
              loading={fetching}
              disableSelectionOnClick={true}
              autoHeight
            />
          </div>
        </div>
      </div>
      <DeleteConfirmation
        show={confirmationOpen}
        onHide={handleConfirmationClose}
        label={label}
        loading={deleting}
        onConfirmed={() => remove(label)}
      />
    </>
  );
}
