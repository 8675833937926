import { useMutation } from "@apollo/client";
import { useUser } from "app/providers";
import { useCallback } from "react";
import { UPDATE_CURRENT_USER_PROFILE } from "./commands";

export function useUpdateProfile(
  onCompleted = data => {},
  onError = err => {}
) {
  const { setUser } = useUser();

  const [comUpdateAccount, { loading }] = useMutation(
    UPDATE_CURRENT_USER_PROFILE,
    {
      onCompleted: ({ comUpdateAccount }) => {
        setUser(prev => {
          return { ...prev, ...comUpdateAccount?.user };
        });
        onCompleted(comUpdateAccount);
      },
      onError
    }
  );
  const updateProfile = useCallback(
    variables => {
      comUpdateAccount({
        variables
      });
    },
    [comUpdateAccount]
  );
  return {
    updateProfile,
    loading
  };
}
