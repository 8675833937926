import React, { useState, useCallback, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Spinner } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import {
  useAddCustomer,
  useCustomerRoles,
  useGetNotMyCustomers
} from "./hooks";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Grid, InputLabel } from "@mui/material";

export function AddCustomerForm({ userId, userName }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [customerName, setCustomerName] = useState("");

  const [inputRole, setinputRole] = useState("");

  const { customerRoles } = useCustomerRoles();

  const { newCustomers, loading: isOptionsLoading } = useGetNotMyCustomers(
    userId
  );

  const { addCustomer, loading: isAdding } = useAddCustomer(() => {
    setCustomerName("");
    setinputRole("");
  });
  const handleChange = event => {
    setCustomerName(event.target.value);
  };

  const submitHandler = useCallback(
    e => {
      e.preventDefault();
      addCustomer({
        userId,
        customerName: customerName,
        role: inputRole
      });
    },
    [addCustomer, userId, customerName, inputRole]
  );

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <form onSubmit={submitHandler}>
          <div className="table-div m-0">
            {/* begin::Header */}

            <div className="table-title">
              <h3>{gettext("Add Company")}</h3>

              <button
                className="col-xs-6 btn btn-primary d-flex align-items-center"
                type="submit"
                disabled={isAdding}
              >
                {isAdding ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  <>
                    <MdAdd size={20} />
                    {gettext("Add")}
                  </>
                )}
              </button>

              <span className="font-size-sm w-100">
                {`${gettext("Add Company to")} ${" " + userName}`}
              </span>
            </div>

            <div className="form">
              <div className="card-body">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel>{gettext("Select Company")}</InputLabel>
                      <Select
                        labelId="select-customer"
                        id="select-customer"
                        fullWidth
                        loading={isOptionsLoading}
                        value={customerName}
                        label={gettext("Select Company")}
                        onChange={handleChange}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 280 } } }}
                        required
                      >
                        {newCustomers.map(e => {
                          return <MenuItem value={e}>{e}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel>{gettext("Role")}</InputLabel>
                      <Select
                        labelId="select-role"
                        id="select-role"
                        fullWidth
                        value={inputRole}
                        label={gettext("Role")}
                        onChange={e => setinputRole(e.target.value)}
                      >
                        {customerRoles?.map(customerRole => (
                          <MenuItem value={customerRole.name}>
                            {customerRole.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
