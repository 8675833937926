import { useMutation } from "@apollo/client";
import { useTextdomainContext } from "app/providers";
import { useSnackbar } from "notistack";
import { useCallback, useContext } from "react";
import { ADD_PUBLIC_DESCRIPTION } from "./command";

export function useAddDescription() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { enqueueSnackbar } = useSnackbar();

  const [addAllTeamSettings, { loading }] = useMutation(
    ADD_PUBLIC_DESCRIPTION,
    {
      onCompleted: () => {
        enqueueSnackbar(gettext("Description Added Successfully"), {
          variant: "success"
        });
      }
    }
  );

  const addDescription = useCallback(
    variables => {
      try {
        addAllTeamSettings({
          variables
        });
      } catch (error) {}
    },
    [addAllTeamSettings]
  );

  return {
    addDescription,
    isAdding: loading
  };
}
