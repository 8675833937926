import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { getOr } from "lodash/fp";
import {
  GET_TEAM_ID,
  GET_ALL_TEAM_SETTINGS,
  GET_GRAPH_IMAGES
} from "./commands";

export const useGetTeamId = customer => {
  const [
    fetchTeamData,
    { data, loading, refetch: reFetchTeamData }
  ] = useLazyQuery(GET_TEAM_ID, {
    variables: {
      sef: customer
    }
  });
  const [teamId, setTeamId] = useState(null);

  useEffect(() => {
    if (customer) {
      fetchTeamData();
      reFetchTeamData();
    }
  }, [customer, fetchTeamData, reFetchTeamData]);

  useEffect(() => {
    if (!loading && data) {
      const getTeamId = getOr(null, "getTeamId.teamId", data);
      setTeamId(getTeamId);
    }
  }, [data, loading]);

  return { teamId };
};

export const usePublicGraphData = (customerId, dashboardKey) => {
  const { data, loading } = useQuery(GET_GRAPH_IMAGES, {
    variables: {
      customerId: customerId,
      dashboard: dashboardKey
    }
  });

  const [
    fetchTeamSettingsData,
    {
      data: settings,
      loading: settingLoading,
      refetch: reFetchTeamSettingsData
    }
  ] = useLazyQuery(GET_ALL_TEAM_SETTINGS, {
    variables: {
      teamId: customerId
    }
  });

  const [settingData, setSettingData] = useState(null);

  // useEffect(() => {
  //   if (customerId && dashboardKey) {
  //     fetchPublicGraphData();
  //     reFetchPublicGraphData();
  //   }
  // }, [customerId, dashboardKey, fetchPublicGraphData, reFetchPublicGraphData]);

  useEffect(() => {
    if (customerId) {
      fetchTeamSettingsData();
      reFetchTeamSettingsData();
    }
  }, [customerId, fetchTeamSettingsData, reFetchTeamSettingsData]);

  // useEffect(() => {
  //   if (!loading && data) {
  //     const getTeamId = getOr([], "comGetGraphImages", data);
  //     console.log(getTeamId)
  //     setPublicGraphImage(getTeamId);
  //   }
  // }, [data, loading]);

  useEffect(() => {
    if (!settingLoading && settings) {
      const sData = getOr(null, "getAllTeamSettings.values", settings);
      setSettingData(sData);
    }
  }, [settings, settingLoading]);

  return {
    publicGraph: data?.comGetGraphImages || [],
    settings: settingData,
    loading: loading || settingLoading
  };
};
