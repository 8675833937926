import React, { useEffect, useMemo, useState, useContext } from "react";
import { injectIntl } from "react-intl";
import { Spinner } from "react-bootstrap";
import { MixedWidget1 } from "../../../widgets";
import { useTextdomainContext } from "app/providers";

function OneOfThreeWidget(props) {
  const { loading } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const initialData = useMemo(() => {
    return {
      unique_id: props.uniqueId,
      type: "area",
      chart_title: "...",
      yaxisTitleText: "...",
      categories: ["..."],
      data: [
        {
          name: gettext("Electricity"),
          data: [0]
        },
        {
          name: gettext("District Heating"),
          data: [0]
        },
        {
          name: gettext("Water"),
          data: [0]
        }
      ]
    };
  }, [props.uniqueId, gettext]);
  const [data, updateData] = useState(initialData);

  useEffect(() => {
    if (props.data.type) {
      updateData(props.data);
    } else {
      updateData(initialData);
    }
  }, [props.data, initialData]);

  return (
    <div
      className={"col-sm-12 col-md-6 col-lg-6 col-xl-4 pl-0 pr-0"}
      id="broadCast-title"
    >
      <div className="chart-layout m-0">
        <MixedWidget1
          className="card-stretch gutter-b"
          data={data}
          addressColors={data?.colors}
        />
      </div>
      {loading ? (
        <div className="position-absolute broad-casting bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : null}
    </div>
  );
}

export default injectIntl(OneOfThreeWidget);
