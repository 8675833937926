import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useUser } from "app/providers";
import { useLocation } from "react-router";
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { IoCheckmark, IoChevronDown } from "react-icons/io5";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    "&::-webkit-scrollbar": {
      width: "3px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: `#E4E4E7`
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#27272A",
      outline: `none`
    }
  }
}));

function CustomerDropdown(props) {
  const { onChange, gettext } = props;
  const { user } = useUser();
  const { teams, currentTeam, currentTeamId } = user || {};
  const classes = useStyles();
  const [filteredTeams, setFilteredTeams] = useState(teams);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const key = location.pathname + "selectedCustomerDropdown";

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue("");
    setFilteredTeams(teams);
  };

  const handleSearchValues = value => {
    setSearchValue(value);
    if (!value) {
      setFilteredTeams(teams);
    } else {
      const filteredData = teams.filter(item => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilteredTeams(filteredData);
    }
  };

  useEffect(() => {
    if (localStorage.getItem(key)) {
      const customer = [
        {
          value: currentTeam,
          label: currentTeam,
          id: currentTeamId
        }
      ];
      setSelectedCustomer(customer || JSON.parse(localStorage.getItem(key)));
    } else if (currentTeam !== null) {
      const customer = [
        {
          value: currentTeam,
          label: currentTeam,
          id: currentTeamId
        }
      ];
      setSelectedCustomer(customer);
    }
  }, [currentTeam, currentTeamId, setSelectedCustomer, key]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(selectedCustomer));
    onChange && onChange(selectedCustomer);
  }, [onChange, selectedCustomer, key]);

  let handleChangeCustomer = selectedOption => {
    const customer = [
      {
        value: selectedOption.name,
        label: selectedOption.name,
        id: selectedOption.Id
      }
    ];
    setSelectedCustomer(customer);
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<IoChevronDown size={12} />}
        color="secondary"
        onClick={handleClick}
        sx={{
          "&:hover": {
            background: "#E6EEEA",
            border: "1px solid #B3CDC1"
          },
          border: "1px solid transparent",
          borderRadius: "8px",
          padding: "0 8px 0 8px"
        }}
      >
        <Typography
          color="secondary.800"
          fontWeight="fontWeightMedium"
          fontSize="14px"
        >
          {gettext("Customer")}
        </Typography>
        {selectedCustomer && selectedCustomer.length && (
          <Chip
            sx={{
              ml: 0.5,
              backgroundColor: "primary.50",
              color: "primary.main",
              fontSize: "14px"
            }}
            size="small"
            label={<Typography>{selectedCustomer[0].label}</Typography>}
          />
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { p: 0 }
        }}
        PaperProps={{
          sx: {
            p: 1,
            borderRadius: "8px",
            marginTop: "8px",
            overflow: "hidden"
          }
        }}
        sx={{
          "& .MuiMenuItem-root": {
            fontWeight: 400,
            fontSize: "14px",
            height: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "4px",
            mx: 1
          },
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: "#FFFFFF",
            color: "#19384d"
          }
        }}
      >
        <Stack
          spacing={2}
          sx={{
            "&:focus": {
              outline: "none"
            }
          }}
        >
          <OutlinedInput
            sx={{
              color: "secondary.main",
              mx: 1,
              "& .css-hq24ym-MuiOutlinedInput-notchedOutline": {
                borderRadius: "4px"
              }
            }}
            size="small"
            value={searchValue}
            onChange={e => handleSearchValues(e.target.value)}
            placeholder={gettext("Search")}
            startAdornment={
              <InputAdornment
                position={"start"}
                style={{
                  color: "secondary.300"
                }}
              >
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Box
            className={classes.root}
            sx={{ maxHeight: "300px", overflowY: "auto" }}
          >
            {filteredTeams && filteredTeams.length > 0 ? (
              filteredTeams?.map(({ name, Id }, index) => (
                <MenuItem
                  component="li"
                  key={Id}
                  selected={selectedCustomer[0]?.id === Id}
                  onClick={() => {
                    handleChangeCustomer(filteredTeams[index]);
                  }}
                >
                  <Typography>{gettext(name)}</Typography>
                  {selectedCustomer[0]?.id === Id && (
                    <IoCheckmark size={16} color="#19384d" />
                  )}
                </MenuItem>
              ))
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  margin: "12px"
                }}
              >
                {gettext("Not Found")}
              </Typography>
            )}
          </Box>
        </Stack>
      </Menu>
    </>
  );
}

export default injectIntl(CustomerDropdown);
