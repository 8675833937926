import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { DO_VERSION, UPDATE_COLORS } from "./commands";

export function useUpdateColors(onCompleted = () => {}, onError = () => {}) {
  const [updateColors, { loading }] = useMutation(UPDATE_COLORS, {
    onCompleted,
    onError
  });

  const update = useCallback(
    variables => {
      updateColors({
        variables,
        refetchQueries: [variables.updateUserSettings ? "comMe" : "allSettings"]
      });
    },
    [updateColors]
  );

  return {
    update,
    updating: loading
  };
}

export function useUpdateVersion(onCompleted = () => {}, onError = () => {}) {
  const [updateVersion, { loading }] = useMutation(DO_VERSION, {
    onCompleted,
    onError
  });

  const update = useCallback(
    variables => {
      updateVersion({
        variables,
        refetchQueries: ["allSettings"]
      });
    },
    [updateVersion]
  );

  return {
    updateVersion: update,
    versionUpdating: loading
  };
}
