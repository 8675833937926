import { gql } from "@apollo/client";

export const RESET_PASSWORD = gql`
  mutation comResetPassword(
    $password1: String!
    $password2: String!
    $token: String!
  ) {
    comResetPassword(
      password1: $password1
      password2: $password2
      token: $token
    ) {
      success
      message
    }
  }
`;
