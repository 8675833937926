import { gql } from "@apollo/client";
import { ME_TYPE_FRAGMENT } from "GraphQL/Fragments";

export const SUBMIT_METERS_REGISTRATIONFLOW = gql`
  ${ME_TYPE_FRAGMENT}
  mutation submitMeters(
    $electricity: [AddMultipleMeterInput]
    $gas: [AddMultipleMeterInput]
    $heating: [AddMultipleMeterInput]
    $skip: Boolean
    $water: [AddMultipleMeterInput]
  ) {
    addCustomerMeterRegistrationFlow(
      electricity: $electricity
      gas: $gas
      heating: $heating
      skip: $skip
      water: $water
    ) {
      success
      message
      user {
        ...MeType
      }
    }
  }
`;
