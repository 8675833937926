import { useUser } from "app/providers";
import React, { useState, createContext, useContext, useCallback } from "react";
import { useCustomersBuildings, useSubmitMeters } from "./hooks";

const BuildingStepContext = createContext();

export function useBuildingStep() {
  const {
    currentStep,
    buildingMeterData,
    setHas,
    setMode,
    setMetersData,
    editMetersData,
    deleteMetersData,
    saveMetersData,
    skip,
    next
  } = useContext(BuildingStepContext);

  return {
    currentStep: currentStep + 1,
    totalSteps: buildingMeterData.length,
    buildingMeterData: buildingMeterData[currentStep] || {},
    setHas,
    setMode,
    setMetersData,
    editMetersData,
    deleteMetersData,
    saveMetersData,
    skip,
    next
  };
}

export default function BuildingStepProvider({ children }) {
  const [currentStep, setCurrentStep] = useState(0);

  const [buildingMeterData, setBuildingMeterData] = useState([]);

  const { user } = useUser();

  const {
    teams: [team]
  } = user || { teams: [] };

  const { buildings } = useCustomersBuildings(team.name, buildings => {
    setBuildingMeterData(
      buildings?.map(building => {
        return {
          address: building.address,
          electricity: {
            step: 0,
            has: null,
            mode: "",
            data: building.electricity || []
          },
          heating: {
            step: 0,
            has: null,
            mode: "",
            data: building.heating || []
          },
          water: {
            step: 0,
            has: null,
            mode: "",
            data: building.water || []
          },
          gas: {
            step: 0,
            has: null,
            mode: "",
            data: building.gas || []
          }
        };
      })
    );
  });

  const setHas = useCallback(
    (type, value) => {
      setBuildingMeterData(prev => {
        prev[currentStep][type].has = value;
        if (value) prev[currentStep][type].step = 1;
        else prev[currentStep][type].step = -1;
        return [...prev];
      });
    },
    [currentStep]
  );

  const setMode = useCallback(
    (type, value) => {
      setBuildingMeterData(prev => {
        prev[currentStep][type].mode = value;
        prev[currentStep][type].step = 2;
        return [...prev];
      });
    },
    [currentStep]
  );

  const setMetersData = useCallback(
    (type, data) => {
      setBuildingMeterData(prev => {
        prev[currentStep][type].data.push(data);
        return [...prev];
      });
    },
    [currentStep]
  );

  const editMetersData = useCallback(
    (type, index, data) => {
      setBuildingMeterData(prev => {
        prev[currentStep][type].data[index] = data;
        return [...prev];
      });
    },
    [currentStep]
  );

  const deleteMetersData = useCallback(
    (type, index) => {
      setBuildingMeterData(prev => {
        prev[currentStep][type].data.splice(index, 1);
        return [...prev];
      });
    },
    [currentStep]
  );

  const saveMetersData = useCallback(
    type => {
      setBuildingMeterData(prev => {
        if (prev[currentStep][type].data.length)
          prev[currentStep][type].step = 3;
        else prev[currentStep][type].step = 2;
        return [...prev];
      });
    },
    [currentStep]
  );

  const { submit } = useSubmitMeters();

  const formatData = useCallback(buildingData => {
    return buildingData.map(building => {
      return {
        address: building.address,
        electricity: building.electricity.data,
        heating: building.heating.data,
        water: building.water.data,
        gas: building.gas.data
      };
    });
  }, []);

  const skip = useCallback(() => {
    setBuildingMeterData(prev => {
      const building = buildings.find(building => {
        return building.address === prev[currentStep].address;
      });
      prev[currentStep] = {
        address: building.address,
        electricity: {
          step: 0,
          has: null,
          mode: "",
          data: building.electricity || []
        },
        heating: {
          step: 0,
          has: null,
          mode: "",
          data: building.heating || []
        },
        water: {
          step: 0,
          has: null,
          mode: "",
          data: building.water || []
        },
        gas: {
          step: 0,
          has: null,
          mode: "",
          data: building.gas || []
        }
      };
      return [...prev];
    });

    setCurrentStep(prevCurrentStep => {
      if (prevCurrentStep < buildingMeterData.length - 1) {
        prevCurrentStep++;
      } else {
        submit(formatData(buildingMeterData));
      }
      return prevCurrentStep;
    });
  }, [buildings, currentStep, buildingMeterData, submit, formatData]);

  const next = useCallback(() => {
    setCurrentStep(prevCurrentStep => {
      if (prevCurrentStep < buildingMeterData.length - 1) {
        prevCurrentStep++;
      } else {
        submit(formatData(buildingMeterData));
      }
      return prevCurrentStep;
    });
  }, [buildingMeterData, submit, formatData]);

  const value = {
    currentStep,
    buildingMeterData,
    setHas,
    setMode,
    setMetersData,
    editMetersData,
    deleteMetersData,
    saveMetersData,
    skip,
    next
  };

  return (
    <BuildingStepContext.Provider value={value}>
      {children}
    </BuildingStepContext.Provider>
  );
}
