import React, { useContext } from "react";
// import { getOr } from "lodash/fp";
import {
  MainResultSection1,
  // MainResultTable,
  MainResultHead
} from "./GhgStyle";
// import DataRow from "./DataRow";
// import DataHeading from "./DataHeading";
import { useTextdomainContext } from "app/providers";
// import * as XLSX from "xlsx/xlsx.mjs";
// import Select from "react-select";
import GhgCustomTable from "app/components/GhgCustomTable";

const ResultSection2 = props => {
  // const { data, setTable2PdfData, tableData, tableTitle } = props;
  const { tableData, tableTitle } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  // const [totalScope1, setTotalScope1] = useState(0.0);
  // const [totalScope2, setTotalScope2] = useState(0.0);
  // const [totalScope3, setTotalScope3] = useState(0.0);
  // const [totalOutOfScope, setTotalOutOfScope] = useState(0.0);

  // const [csvData, setCSVData] = useState(null);

  // let downloadOptions = [
  //   {
  //     value: "download",
  //     label: gettext("Download")
  //   },
  //   {
  //     value: "excel",
  //     label: gettext("Excel")
  //   },
  //   {
  //     value: "csv",
  //     label: gettext("CSV")
  //   }
  // ];
  // const selectStyles = {
  //   container: base => ({
  //     ...base,
  //     minWidth: 200,
  //     fontSize: 14,
  //     fontWeight: "normal",
  //     flex: 1,
  //     color: "black"
  //   })
  // };
  // const CSVHeadings = [
  //   "",
  //   gettext("Scope 1"),
  //   gettext("Scope 2"),
  //   gettext("Scope 3"),
  //   gettext("Out of Scope")
  // ];

  // const handleDownloadFile = selectedOption => {
  //   //Had to create a new workbook and then add the header
  //   const wb = XLSX.utils.book_new();
  //   const ws = XLSX.utils.json_to_sheet([]);
  //   XLSX.utils.sheet_add_aoa(ws, [CSVHeadings]);
  //   //Starting in the second row to avoid overriding and skipping headers
  //   XLSX.utils.sheet_add_json(ws, csvData.data, {
  //     origin: "A2",
  //     skipHeader: true
  //   });
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  //   if (selectedOption.value === "excel") {
  //     XLSX.writeFile(wb, "downlaod-" + new Date() + ".xlsx");
  //   } else if (selectedOption.value === "csv") {
  //     XLSX.utils.sheet_to_csv(wb);
  //     XLSX.writeFile(wb, "downlaod-" + new Date() + ".csv");
  //   }
  // };

  // const getValue = value => {
  //   if (value) {
  //     return value;
  //   } else {
  //     return 0;
  //   }
  // };

  // useEffect(() => {
  //   if (data) {
  //     setTotalScope1(getValue(data?.scope1?.value));

  //     setTotalScope2(
  //       // getOr(0, "scope2", data.scope2.value)
  //       getValue(data?.scope2?.value)
  //     );

  //     setTotalScope3(
  //       // getOr(0, "scope3", data.scope3.value)
  //       getValue(data?.scope3?.value)
  //     );

  //     setTotalOutOfScope(
  //       // getOr(0, "outOfScope", data.scope1.value)
  //       getValue(data?.outOfScope?.value)
  //     );
  //   }
  // }, [data]);

  // useEffect(() => {
  //   let jsonData = {
  //     headings: {
  //       h1: "",
  //       h2: gettext("Scope 1"),
  //       h3: gettext("Scope 2"),
  //       h4: gettext("Scope 3"),
  //       h5: gettext("Out of scope")
  //     },
  //     data: [
  //       {
  //         value1: gettext("Total"),
  //         value2: totalScope1,
  //         value3: totalScope2,
  //         value4: totalScope3,
  //         value5: totalOutOfScope
  //       }
  //     ]
  //   };
  //   setCSVData(jsonData);
  //   setTable2PdfData(jsonData);
  // }, [
  //   totalScope1,
  //   totalScope2,
  //   totalScope3,
  //   totalOutOfScope,
  //   setTable2PdfData,
  //   gettext
  // ]);

  return (
    <MainResultSection1 className="card card-custom gutter-b example example-compact">
      <MainResultHead className="row m-0">
        <div className="col-sm-12 p-0">
          <h3 className="pl-2">{gettext("Partial results")}</h3>
          <hr />
          <p className="pl-2 pt-2">
            {gettext(
              "Table 3 shows a more detailed result display. Table 3 shows all the subcategories where data can be entered for, the associated emission in tons of CO₂-e and the subcategory's total share of the total emissions."
            )}
          </p>
        </div>
      </MainResultHead>
      <br />

      {/* <MainResultTable className="row m-0 bg-white">
        <div className="col-sm-12 p-0">
          <div className="row m-0">
            <div className="col-sm-12 p-0 result-title">
              <p className="pl-2">{gettext("Table 3")}</p>
            </div>
            <div className="table-title col-sm-12 pl-0">
              <p className="col-xs-6 col-sm-6 col-md-6 col-lg-6 table-description">
                {gettext("Overview of the company's total CO₂e emissions")}
              </p>
              <div className="table-toolbar pr-1">
                <Select
                  options={downloadOptions}
                  onChange={handleDownloadFile}
                  value={[
                    {
                      value: "download",
                      label: "Download"
                    }
                  ]}
                  styles={selectStyles}
                  isDisabled={false}
                />
              </div>
            </div>
          </div>
          <div className="row m-0 custom-row">
            <div className="col-sm-4"></div>
            <div className="col-sm-2"> {gettext("Scope 1")} </div>
            <div className="col-sm-2"> {gettext("Scope 2")}</div>
            <div className="col-sm-2"> {gettext("Scope 3")}</div>
            <div className="col-sm-2">{gettext("Out of scope")}</div>
          </div>
          <div className="row m-0 border-total-row border-bottom-row custom-row">
            <div className="col-sm-4">
              <strong>Total</strong>
            </div>
            <div className="col-sm-2">
              <strong>{decimalPoint(totalScope1)}</strong>
            </div>
            <div className="col-sm-2">
              <strong>{decimalPoint(totalScope2)}</strong>
            </div>
            <div className="col-sm-2">
              <strong>{decimalPoint(totalScope3)}</strong>
            </div>
            <div className="col-sm-2">
              <strong>{decimalPoint(totalOutOfScope)}</strong>
            </div>
          </div>
        </div>
      </MainResultTable> */}

      <div className="col-sm-12 pl-3">
        <GhgCustomTable
          gettext={gettext}
          tableData={tableData}
          tableTitle={tableTitle}
        />
      </div>
    </MainResultSection1>
  );
};

export default ResultSection2;
