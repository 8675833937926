import React from "react";
import { GoBack } from "../../components";
import { AccountDataListTable } from "../../features";

export function AccountFileDataList() {
  return (
    <>
      <GoBack />

      <AccountDataListTable />
    </>
  );
}
