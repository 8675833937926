import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { getOr } from "lodash/fp";
import EpcFilters from "../components/EpcFilters";
import EpcTable from "../components/EpcTable";
import EpcChart from "../components/EpcChart";
import EpcNotice from "../components/EpcNotice";
import { Spinner } from "react-bootstrap";
import { useEPC } from "../../../../hooks/useEPC";
import { useUser } from "app/providers";
import {
  useBuildingFilter
  // useMetersFilter
} from "hooks";
import * as Constants from "../../../common/Constants";

const TYPES = ["energy", "electricity", "heating", "water", "gas"];

function EPC() {
  const { user, colors } = useUser();
  const { currentTeam } = user || {};
  const { buildings: addresses, loading } = useBuildingFilter(currentTeam);
  const [buildingOptions, setbuildingOptions] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [buildingsLoading, setbuildingsLoading] = useState(true);
  const [Loading, setLoading] = useState(true);
  const [yearsTableState, setyearsTableState] = useState({
    current: { name: "", value: "" },
    prev1: { name: "", value: "" },
    prev2: { name: "", value: "" }
  });
  const [pass1, setpass1] = useState(false);
  const [pass2, setpass2] = useState(false);
  const [pass3, setpass3] = useState(false);

  const [selectedBuildings, setSelectedBuildings] = useState("");
  const [selectedTypes, setSelectedTypes] = useState("");
  const [selectedYears, setSelectedYears] = useState("");
  const [selectedColor, setSelectedColor] = useState("#ADB7C9");

  const { epcData: epcData1 } = useEPC({
    customer: user.currentTeam,
    addresses: selectedBuildings,
    type: selectedTypes,
    from: selectedYears + "-01-01T00:00:00",
    to: Number(selectedYears) + 1 + "-01-01T00:00:00",
    resolution: "m"
  });

  const { epcData: epcData2 } = useEPC({
    customer: user.currentTeam,
    addresses: selectedBuildings,
    type: selectedTypes,
    from: Number(selectedYears) - 1 + "-01-01T00:00:00",
    to: Number(selectedYears) - 1 + 1 + "-01-01T00:00:00",
    resolution: "m"
  });

  const { epcData: epcData3 } = useEPC({
    customer: user.currentTeam,
    addresses: selectedBuildings,
    type: selectedTypes,
    from: Number(selectedYears) - 2 + "-01-01T00:00:00",
    to: Number(selectedYears) - 2 + 1 + "-01-01T00:00:00",
    resolution: "m"
  });

  useEffect(() => {
    if (colors) {
      const ENColor = getOr(
        Constants.HEAT_COLOR,
        "energy.shade1.background",
        colors
      );
      const EColor = getOr(
        Constants.ELECTRICITY_COLOR,
        "electricity.shade1.background",
        colors
      );
      const HColor = getOr(
        Constants.HEAT_COLOR,
        "heating.shade1.background",
        colors
      );
      const WColor = getOr(
        Constants.WATER_COLOR,
        "water.shade1.background",
        colors
      );
      const GColor = getOr(
        Constants.WATER_COLOR,
        "gas.shade1.background",
        colors
      );
      setColorList([ENColor, EColor, HColor, WColor, GColor]);
      setSelectedColor(ENColor);
    }
  }, [colors, selectedTypes]);

  useEffect(() => {
    if (!loading && addresses) {
      setbuildingOptions(addresses);
      setbuildingsLoading(false);
    }
  }, [addresses, loading]);

  useEffect(() => {
    if (epcData1 && epcData1.length > 0) {
      let current = 0;
      epcData1.forEach(resources => {
        if (resources && resources.data.length > 0) {
          resources.data.forEach(item => {
            current += item.vPerM2;
          });
        }
      });
      setyearsTableState(prev => ({
        ...prev,
        current: { name: selectedYears, value: current.toFixed(2) }
      }));
      setpass1(true);
      setLoading(false);
    }
  }, [epcData1, selectedYears]);

  useEffect(() => {
    if (epcData2.length > 0) {
      let prev1 = 0;
      epcData2.forEach(resources => {
        if (resources && resources.data.length > 0) {
          resources.data.forEach(item => {
            prev1 += item.vPerM2;
          });
        }
      });
      setyearsTableState(prev => ({
        ...prev,
        prev1: { name: Number(selectedYears) - 1, value: prev1.toFixed(2) }
      }));
      setpass2(true);
      setLoading(false);
    }
  }, [epcData2, selectedYears]);

  useEffect(() => {
    if (epcData3.length > 0) {
      let prev2 = 0;
      epcData3.forEach(resources => {
        if (resources && resources.data.length > 0) {
          resources.data.forEach(item => {
            prev2 += item.vPerM2;
          });
        }
      });
      setyearsTableState(prev => ({
        ...prev,
        prev2: { name: Number(selectedYears) - 2, value: prev2.toFixed(2) }
      }));
      setpass3(true);
      setLoading(false);
    }
  }, [epcData3, selectedYears]);

  const handleApplyFilers = (building, type, year) => {
    setSelectedBuildings(building);
    setSelectedTypes(type);
    setSelectedYears(year);
    const colorIndex = TYPES.indexOf(type);
    setSelectedColor(colorList[colorIndex]);
    // setLoading(true);
    // applyFilers(building, type, year);
  };

  return (
    <>
      <EpcFilters
        buildingOptions={buildingOptions}
        buildingsLoading={buildingsLoading}
        applyFilers={handleApplyFilers}
      />

      {pass1 && pass2 && pass3 ? (
        <>
          <EpcTable yearsTableState={yearsTableState} Loading={Loading} />
          <EpcChart
            yearsTableState={yearsTableState}
            selectedColor={selectedColor}
          />
          <EpcNotice />
        </>
      ) : (
        <>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Spinner animation="border" variant="dark" />
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <svg
              width="64px"
              height="64px"
              viewBox="0 0 64 64"
              data-name="Layer 1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title />
              <path
                className="cls-1"
                style={{ fill: "#0074ff" }}
                d="M56.5,59.06H7.5a2,2,0,0,1-2-2V46.78a2,2,0,0,1,2-2H25.61a2,2,0,0,1,0,4H9.5v6.28h45V48.78H36.86a2,2,0,0,1,0-4H56.5a2,2,0,0,1,2,2V57.06A2,2,0,0,1,56.5,59.06Z"
              />
              <path
                className="cls-2"
                style={{ fill: "#ffb300" }}
                d="M43,35.48a2,2,0,0,1-1.41-.59l-22-22a2,2,0,0,1,2.83-2.83l22,22a2,2,0,0,1,0,2.82A2,2,0,0,1,43,35.48Z"
              />
              <path
                className="cls-2"
                style={{ fill: "#ffb300" }}
                d="M21,35.48a2,2,0,0,1-1.42-.59,2,2,0,0,1,0-2.82l22-22a2,2,0,0,1,2.83,2.83l-22,22A2,2,0,0,1,21,35.48Z"
              />
            </svg>
          </div>
        </>
      )}
    </>
  );
}

export default injectIntl(EPC);
