import { Box } from "@mui/material";
import { useBuildingStep } from "app/features/RegistrationFlowAddMeterForm/providers";
import { Meter, Supplier } from "./Modes";
import React from "react";

export function Data({ type }) {
  const { buildingMeterData } = useBuildingStep();

  return (
    <Box marginX={5}>
      {buildingMeterData?.[type].mode === "manual" ? (
        <Meter type={type} />
      ) : (
        <Supplier type={type} />
      )}
    </Box>
  );
}
