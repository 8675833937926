import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { format } from "date-fns";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Spinner } from "react-bootstrap";
import {
  GET_EMISSION_FACTOR,
  GET_EMISSION_FACTOR_LIST
} from "../../../../GraphQL/Queries";
import { UPDATE_EMISSION_FACTOR_MUTATION } from "../../../../GraphQL/Mutations";
import EmissionFactorsForm from "./emission-factors-form";
import { GoBackButton, AccountChartHeader } from "./emission-factors-style";

const EmissionFactorsEdit = () => {
  const { id } = useParams();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const navigate = useNavigate();
  const localFilters = JSON.parse(localStorage.getItem("emissionFilters"));
  const value =
    localFilters && localFilters.year
      ? new Date(localFilters.year)
      : new Date();
  const [date, setDate] = useState(value);

  const [selectedKey, setSelectedKey] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedParam1, setSelectedParam1] = useState("");
  const [selectedParam2, setSelectedParam2] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedYear, setSelectedYear] = useState(
    parseInt(format(date, "yyyy"))
  );

  const [scope1, setScope1] = useState("");
  const [scope2, setScope2] = useState("");
  const [scope3, setScope3] = useState("");
  const [source, setSource] = useState("");
  const [
    fetchEditEmissionFactor,
    { data: emissionData, loading, refetch: refetchEditEmissionFactor }
  ] = useLazyQuery(GET_EMISSION_FACTOR, {
    variables: {
      id: id,
      year: format(date, "yyyy")
    }
  });

  useEffect(() => {
    if (date) {
      fetchEditEmissionFactor();
      refetchEditEmissionFactor();
      setDate(date);
    }
  }, [fetchEditEmissionFactor, refetchEditEmissionFactor, date]);

  useEffect(() => {
    if (!loading && emissionData) {
      const { emissionFactor } = emissionData;
      const findData = emissionFactor.data.find(
        item => item.year === parseInt(format(date, "yyyy"))
      );
      setSelectedKey(emissionFactor.key);
      setSelectedGroup(emissionFactor.group);
      setSelectedParam1(emissionFactor.parameter1);
      setSelectedParam2(emissionFactor.parameter2);
      setSelectedUnit(emissionFactor.unit);
      setScope1(findData.scope1);
      setScope2(findData.scope2);
      setScope3(findData.scope3);
      setSource(findData.source);
      setSelectedYear(findData.year);
    }
  }, [emissionData, loading, date]);

  const [updateEmissionFactor, { loading: updateLoading }] = useMutation(
    UPDATE_EMISSION_FACTOR_MUTATION,
    {
      onCompleted() {
        navigate(`/admin/emission-factors`);
      },
      onError(error) {
        // error.graphQLErrors.map(({ message }) => {
        //   // console.log("Error =>", error);
        // });
      }
    }
  );

  const handleUpdateAccountChart = () => {
    updateEmissionFactor({
      variables: {
        id: id,
        key: selectedKey,
        group: selectedGroup,
        parameter1: selectedParam1,
        parameter2: selectedParam2,
        unit: selectedUnit,
        year: selectedYear,
        data: {
          source: source,
          scope1: scope1,
          scope2: scope2,
          scope3: scope3,
          outOfScope: 0.3
        }
      },
      refetchQueries: [
        {
          query: GET_EMISSION_FACTOR_LIST,
          variables: {
            year: selectedYear
          }
        },
        {
          query: GET_EMISSION_FACTOR,
          variables: {
            id: id,
            year: selectedYear
          }
        }
      ]
    });
  };

  const hanleGoBack = () => {
    navigate(`/admin/emission-factors`);
  };

  const validForm = () =>
    scope1 &&
    scope2 &&
    scope3 &&
    selectedKey &&
    selectedGroup &&
    selectedParam1 &&
    selectedParam2 &&
    selectedUnit &&
    selectedYear;

  return (
    <>
      <GoBackButton className="row m-0">
        <div className="col-sm-12 p-0">
          <div className="form-group col-md-2 p-0">
            <button
              type="button"
              className="btn btn-outline-info"
              onClick={hanleGoBack}
            >
              <AiOutlineArrowLeft title="back" size={18} className="mr-2" />
              {gettext("Back")}
            </button>
          </div>
        </div>
      </GoBackButton>
      <AccountChartHeader className="card card-custom gutter-b example example-compact">
        <div className="row m-0 table-title">
          <div className="col-sm-12 title">
            <h3>{gettext("Edit Emission Factor")}</h3>
          </div>
        </div>
        <>
          <EmissionFactorsForm
            selectedKey={selectedKey}
            selectedGroup={selectedGroup}
            selectedParam1={selectedParam1}
            selectedParam2={selectedParam2}
            selectedUnit={selectedUnit}
            selectedYear={selectedYear}
            scope1={scope1}
            scope2={scope2}
            scope3={scope3}
            source={source}
            setSelectedKey={setSelectedKey}
            setSelectedGroup={setSelectedGroup}
            setSelectedParam1={setSelectedParam1}
            setSelectedParam2={setSelectedParam2}
            setSelectedUnit={setSelectedUnit}
            setSelectedYear={setSelectedYear}
            setScope1={setScope1}
            setScope2={setScope2}
            setScope3={setScope3}
            setSource={setSource}
            sourceType="Edit"
          />

          <div className="row m-0 border-top-row">
            <div className="col-sm-12 create-action">
              <div className="form-group col-md-12">
                <button
                  type="button"
                  onClick={handleUpdateAccountChart}
                  disabled={!validForm() || updateLoading}
                >
                  {gettext("Update Emission Factor")} &nbsp;
                  {updateLoading && (
                    <Spinner
                      className="btn-spinner-delete"
                      animation="border"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      </AccountChartHeader>
    </>
  );
};

export default EmissionFactorsEdit;
