import { Container } from "@mui/material";
import {
  RegistrationFlowAddBuildingForm,
  RegistrationFlowAddCompanyForm,
  RegistrationFlowAddMeterForm
} from "app/features";
import { useStep } from "app/providers";
import React from "react";

export function StepForm() {
  const { currentStep } = useStep();

  const pages = [
    <RegistrationFlowAddCompanyForm />,
    <RegistrationFlowAddBuildingForm />,
    <RegistrationFlowAddMeterForm />
  ];
  return (
    <Container maxWidth={currentStep === 2 ? "sm" : "xs"}>
      {pages[currentStep]}
    </Container>
  );
}
