import { gql } from "@apollo/client";

export const ADD_METER_DATA = gql`
  mutation loadMeterData($buildingId: String!, $data: LoadMeterDataInput!) {
    loadMeterData(buildingId: $buildingId, data: $data) {
      success
      message
    }
  }
`;
