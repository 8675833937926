import React from "react";
import FilterComponent from "../components/FilterComponent";
// import * as auth from "../../Auth";
// import injectIntl from "react-intl/dist/components/injectIntl";

// export function NewReport(props) {
//     return (
//         <FilterComponent />
//     );
// }
class NewReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formId: [0]
    };
  }

  addFilterForm() {
    let newId = this.state.formId.length;
    this.setState(prevState => ({ formId: prevState.formId.concat([newId]) }));
  }

  render() {
    return (
      <form action="" className="col-form">
        <div>
          <div id="filterFormContainer">
            {this.state.formId.map(formId => (
              <FilterComponent key={formId} formId={formId} />
            ))}
          </div>
          <div id="addFilterContainer" className="row py-5">
            <div className="col">
              <button
                onClick={() => this.addFilterForm()}
                className="btn btn-info btn-lg btn-block"
                type="button"
              >
                Add more
              </button>
            </div>
          </div>
          <div className="row py-5">
            <div className="col text-right">
              <button
                onClick={() => this.addFilterForm()}
                className="btn btn-primary generate-btn"
                type="button"
              >
                Generate Report
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default NewReport;
// export default injectIntl(connect(null, auth.actions)(NewReport));
