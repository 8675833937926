import { gql } from "@apollo/client";

export const SEND_WELCOME_EMAIL = gql`
  mutation comSendWelcomeEmail($email: String!) {
    comSendWelcomeEmail(email: $email) {
      success
      message
    }
  }
`;
