import axios from "axios";
import { useEffect, useState } from "react";

export default function useFindAddresses(searchString, onCompleted = () => {}) {
  const [addresses, setAddresses] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchString) {
      setLoading(true);

      axios
        .get(
          `https://dawa.aws.dk/adresser/autocomplete?q=${searchString}&side=1&per_side=1000`
        )
        .then(
          res => {
            setAddresses(res.data);
          },
          () => {
            setAddresses([]);
          }
        )
        .finally(() => {
          setLoading(false);
        });
    } else setAddresses([]);
  }, [searchString]);

  return {
    addresses: addresses || [],
    loading
  };
}
