import React, { useEffect, useRef, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { ProgressBar } from "react-bootstrap";
import Select from "react-select";
import { readString } from "react-papaparse";
import * as XLSX from "xlsx";
import {
  useFetchAccountGroups,
  useFetchCharOfAccounts,
  useGetPlanLinkById,
  useSave,
  useUpdate
} from "./hooks";
import { Accordion, AccordionDetails, Typography, styled } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useParams } from "react-router-dom";
import moment from "moment";

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  margin: 4,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  }
}));

export function AddEditPlan(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const fileInput = useRef(null);

  const { planId } = useParams();

  const { planLinkData, refetchPlainLinkById } = useGetPlanLinkById(planId);

  const { save } = useSave();

  const { update } = useUpdate();

  const [planName, setplanName] = useState("");
  const [fileName, setfileName] = useState("");
  const [fileSize, setfileSize] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [percentage, setpercentage] = useState(0);
  const [CsvFile, setCsvFile] = useState(null);
  const [isEditPage] = useState(planId);
  const [, setisNameEmpty] = useState(false);
  const [
    charOfAccountsFormattedList,
    setcharOfAccountsFormattedList
  ] = useState([]);
  const [accountListCSV, setaccountListCSV] = useState([]);
  const [selectedAccountList, setselectedAccountList] = useState([]);

  const { charOfAccounts } = useFetchCharOfAccounts();
  const { accountGroups } = useFetchAccountGroups();

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (planId && planLinkData) {
      setplanName(planLinkData?.name);
      setfromDate(planLinkData?.fromDate);
      settoDate(planLinkData?.toDate);

      setaccountListCSV([...planLinkData.companyAccount]);
      setselectedAccountList([...planLinkData.companyAccount]);
    }
  }, [planId, planLinkData]);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const reset = () => {
    setExpanded(false);
    setaccountListCSV([]);
    setselectedAccountList([]);
    setplanName("");
  };

  useEffect(() => {
    let charOfAccountsFormattedList = [];
    if (charOfAccounts && accountGroups) {
      accountGroups.map((group, index) => {
        charOfAccountsFormattedList.splice(index, 0, {
          ...group,
          accounts: []
        });

        charOfAccounts.map(charOfAccount => {
          if (group?.Id === charOfAccount?.accountGroupId) {
            charOfAccountsFormattedList[index].accounts.push(charOfAccount);
          }
          return 0;
        });
        return 0;
      });
      setcharOfAccountsFormattedList(charOfAccountsFormattedList.reverse());
    }
  }, [charOfAccounts, accountGroups]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: "40px",
      height: "40px",
      background: "rgb(236,246,239)",
      borderColor: "lightgray",
      borderWidth: 1,
      color: "white",
      boxShadow: null,
      "&:hover": {
        borderColor: "lightgray"
      }
    }),
    multiValue: base => ({
      ...base,
      color: "red",
      position: "absolute",
      top: 0
    }),
    multiValueLabel: base => ({
      ...base,
      backgroundColor: "green",
      // width: 0,
      color: "white",
      display: "none"
    }),
    multiValueRemove: base => ({
      ...base,
      display: "none"
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 6px"
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px"
    }),
    indicatorSeparator: state => ({
      display: "none"
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px"
    })
  };

  const chosenPlanStyle = {
    control: (base, state) => ({
      ...base,
      background: "rgb(236,246,239)",
      borderColor: "lightgray",
      borderWidth: 1,
      color: "white",
      boxShadow: null,
      "&:hover": {
        borderColor: "lightgray"
      }
    })
  };

  const getPlanListFromCSV = async file => {
    let reader = new FileReader();

    reader.onload = function(e) {
      let data = e.target.result;

      let readedData = XLSX.read(data, { type: "binary" });

      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      const fileData = XLSX.utils.sheet_to_json(ws, { header: 1 });

      let accountListCSV = [];
      let initialSelectedAccountList = [];

      // const letters = fileData[2][0]?.split(" ");

      fileData.forEach((r, index) => {
        if (index === 2) {
          let letters = r[0].split(" ");
          setfromDate(moment(letters[3], "DD.MM.YY").format("YYYY-MM-DD"));
          settoDate(moment(letters[5], "DD.MM.YY").format("YYYY-MM-DD"));
        }

        index > 3 &&
          !isNaN(parseInt(r[0])) &&
          accountListCSV.push({
            accountNumber: r[0],
            price: r[2],
            accountName: r[1]
          }) &&
          initialSelectedAccountList.push({
            chartOfAccountId: null,
            accountName: r[1],
            accountNumber: r[0],
            price: r[2]
          });
      });

      setselectedAccountList(initialSelectedAccountList);
      setaccountListCSV(accountListCSV);
    };

    if (file.type === "text/csv") {
      let data;
      let reader1 = new FileReader();
      reader1.onload = function(e) {
        data = e.target.result;
        readString(data, {
          complete: res => {
            let accountListCSV = [];
            let initialSelectedAccountList = [];
            res.data.forEach((r, index) => {
              if (index === 2) {
                let letters = r[0].split(" ");
                setfromDate(
                  moment(letters[3], "DD.MM.YY").format("YYYY-MM-DD")
                );
                settoDate(moment(letters[5], "DD.MM.YY").format("YYYY-MM-DD"));
              }

              index > 3 &&
                !isNaN(parseInt(r[0])) &&
                accountListCSV.push({
                  accountNumber: r[0],
                  price: parseFloat(
                    r[2].replace(/\./g, "") +
                      "." +
                      (r?.length < 6 ? "00" : r[3])
                  ),
                  accountName: r[1]
                }) &&
                initialSelectedAccountList.push({
                  chartOfAccountId: null,
                  accountName: r[1],
                  accountNumber: r[0],
                  price: parseFloat(
                    r[2].replace(/\./g, "") +
                      "." +
                      (r?.length < 6 ? "00" : r[3])
                  )
                });
            });
            setselectedAccountList(initialSelectedAccountList);
            setaccountListCSV(accountListCSV);
          }
        });
      };
      reader1.readAsText(file, "ascii");
    } else if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      reader.readAsBinaryString(file);
    }
  };

  const onSaveTap = e => {
    e.preventDefault();

    if (planName === "") {
      setisNameEmpty(true);
    } else {
      if (isEditPage) {
        //Edit Plan page
        update(
          planId,
          planName,
          false,
          fromDate,
          toDate,
          selectedAccountList,
          refetchPlainLinkById
        );
      } else {
        //Add Plan page
        CsvFile &&
          save(
            planName,
            false,
            fromDate,
            toDate,
            selectedAccountList,
            reset,
            CsvFile
          );
      }
    }
  };

  const onFileSelect = async e => {
    let files = e.target.files;

    if (files.length > 0) {
      setCsvFile(files[0]);

      setfileName(files[0].name);
      setfileSize(files[0].size);
      // setcsvStr(str);
    }
  };

  const onUpload = () => {
    setpercentage(100);
    if (CsvFile) {
      getPlanListFromCSV(CsvFile);
    }
    setTimeout(() => {
      onCancelUpload();
    }, 800);
  };

  const onCancelUpload = () => {
    fileInput.current.value = "";
    setfileName("");
    setpercentage("");
    setfileSize("");
  };

  const handleSelection = (selected, account) => {
    let updateselectedAccountList = selectedAccountList;

    const AccountIndex = selectedAccountList.findIndex(selectedAccount => {
      return selectedAccount.accountNumber === selected?.accountNumber;
    });

    updateselectedAccountList[AccountIndex] = {
      ...updateselectedAccountList[AccountIndex],
      chartOfAccountId: account.Id
    };

    setselectedAccountList([...updateselectedAccountList]);
  };

  const handleRemoval = (selected, removedValue) => {
    let updateselectedAccountList = selectedAccountList;

    const AccountIndex = selectedAccountList.findIndex(selectedAccount => {
      return selectedAccount.accountNumber === removedValue.accountNumber;
    });

    updateselectedAccountList[AccountIndex] = {
      ...updateselectedAccountList[AccountIndex],
      chartOfAccountId: null
    };

    setselectedAccountList([...updateselectedAccountList]);
  };

  const isOptionDisabled = option => {
    let updateselectedAccountList = selectedAccountList;

    const AccountIndex = selectedAccountList.findIndex(selectedAccount => {
      return selectedAccount.accountNumber === option.accountNumber;
    });

    return !(
      updateselectedAccountList[AccountIndex].chartOfAccountId === 0 ||
      updateselectedAccountList[AccountIndex].chartOfAccountId === null
    );
  };

  const getSelectedAccountsValues = accountID => {
    return selectedAccountList?.filter(selectedAccount => {
      return selectedAccount.chartOfAccountId === accountID;
    });
  };

  const niceBytes = x => {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    //include a decimal point and a tenths-place digit if presenting
    //less than ten of KB or greater units
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  };

  const renderTopSection = () => {
    return (
      <div>
        <div className=" card-custom gutter-b example example-compact">
          <div className="">
            <h3 className="">
              <span className="add-plan-header font-weight-bolder padding-container">
                {isEditPage
                  ? gettext("Edit Account Plan")
                  : gettext("Add Account Plan")}
              </span>
            </h3>
            <hr className="mt20" />
          </div>
        </div>
        {!isEditPage && (
          <div className="modal-name-tf-container">
            <div className="custom-row">
              <div className="bold padding-container">
                1. {gettext("Upload your account plan:")}
              </div>
              <div className="ml20">
                <button
                  className="modal-upload mr20"
                  onClick={() => {
                    fileInput.current.click();
                  }}
                >
                  {gettext("Upload")}
                </button>
                <input
                  className="modal-picker spacing"
                  type="file"
                  accept=".csv, .xlsx"
                  onChange={e => onFileSelect(e)}
                  ref={fileInput}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderAccountLink = () => {
    return (
      <div>
        <div className="bold padding-container">
          {isEditPage ? 1 : 2}.{" "}
          {gettext(
            "Link your account plan to the standard account plan for Climatereport:"
          )}
        </div>
        <div className="custom-row">
          <div className="account-plan-values-container ml20">
            <div className="color-primary-green bold padding-container">
              {gettext("Standard Plan")}
            </div>
          </div>
          <div className="account-plan-dropdown-container mr150">
            <div className="color-primary-green bold padding-container">
              {gettext("Account")}
            </div>
          </div>
        </div>

        <div className="border-line mt8 mb8"></div>

        {charOfAccountsFormattedList?.map((plan, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={"account-plan-heading"}
            >
              <Typography ml={4} fontStyle={"italic"} fontWeight="bold">
                {plan?.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {plan?.accounts &&
                plan?.accounts
                  .sort(function(a, b) {
                    return a.accountNumber - b.accountNumber;
                  })
                  .map((account, index1) => (
                    <div key={index1} className="account-plan-values-box">
                      <div
                        key={index1}
                        className={
                          index1 === plan.accounts.length - 1
                            ? "account-plan-values-container pb12 pt8 mt12"
                            : "account-plan-values-container mt12 pt8"
                        }
                      >
                        <div key={index1} className="account-link-val1">
                          {account.accountNumber + " " + account.name}
                        </div>

                        {/* dropdown plan list */}
                        <img
                          className="mr20 ml20"
                          src={"/media/svg/icons/Navigation/right-arrow.svg"}
                          alt={"..."}
                        ></img>
                        <Select
                          className="account-plan-dropdown"
                          placeholder={gettext("Account")}
                          value={null}
                          getOptionLabel={option =>
                            option.accountNumber + " " + option.accountName
                          }
                          getOptionValue={option => option.accountName}
                          isSearchable={false}
                          isClearable={false}
                          onChange={(selected, index) =>
                            handleSelection(selected, account)
                          }
                          options={accountListCSV}
                          styles={customStyles}
                          isOptionDisabled={option => isOptionDisabled(option)}
                        />

                        {/* 3rd box - chosen plan */}
                        <img
                          className="mr20 ml20"
                          src={"/media/svg/icons/Navigation/right-arrow.svg"}
                          alt={"..."}
                        ></img>
                        <Select
                          className="account-plan-dropdown mr20"
                          placeholder={gettext("No chosen account")}
                          isMulti
                          value={getSelectedAccountsValues(account.Id)}
                          getOptionLabel={option =>
                            option.accountNumber + " " + option.accountName
                          }
                          getOptionValue={option => option.accountNumber}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                          }}
                          isSearchable={false}
                          isClearable={false}
                          noOptionsMessage={() => null}
                          onChange={(selected, action) =>
                            handleRemoval(selected, action.removedValue)
                          }
                          styles={chosenPlanStyle}
                        />
                      </div>
                      <div className="border-line mt20"></div>
                    </div>
                  ))}
            </AccordionDetails>
          </Accordion>
        ))}
        <form onSubmit={onSaveTap}>
          <div className="custom-row padding-container mt-4">
            <div className="bold">
              {isEditPage ? 2 : 3}. {gettext("Save your account plan:")}
            </div>
            {/* <div className='ml20'>Name</div> */}
            <input
              placeholder="Enter Name"
              className={"modal-name-tf"}
              name="Name"
              required
              value={planName}
              onChange={e => {
                setplanName(e.target.value);
                setisNameEmpty(false);
              }}
            />
            <button className="standard-account-btn spacing mr20" type="submit">
              {gettext("Save")}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const renderUploadBox = () => {
    return (
      <div className="fade-bg">
        <div className="border-line"></div>
        <div className="custom-row pt20 pb20 pl20 pr20">
          {/* File Name */}
          <div>{fileName}</div>

          {/* Size and progress bar */}
          <div className="spacing width200">
            <div className="text-center mb8">{niceBytes(fileSize)}</div>
            <ProgressBar animated now={percentage} label={`${percentage}%`} />
          </div>

          {/* Buttons */}
          <div className="custom-row spacing">
            <button className="standard-account-btn" onClick={onUpload}>
              <i className="fa fa-upload"></i>
              <span>{gettext("Start")}</span>
            </button>

            <button className="upload-cancel ml10" onClick={onCancelUpload}>
              <i className="fa fa-ban "></i>
              <span>{gettext("Cancel")}</span>
            </button>
          </div>
        </div>
        <div className="border-line mb20"></div>
      </div>
    );
  };

  return (
    <div className="card card-custom gutter-b example example-compact">
      <div className="add-plan-container">
        {renderTopSection()}

        {fileName !== "" && renderUploadBox()}

        {renderAccountLink()}
      </div>
    </div>
  );
}
