import { gql } from "@apollo/client";

export const ADD_GRAPH_IMAGE = gql`
  mutation addGraphImage(
    $customerId: String!
    $dashboard: String!
    $imageBytes: String!
    $title: String!
    $buildings: [String]!
    $companies: [String]!
    $dashboardName: String!
  ) {
    addGraphImage(
      customerId: $customerId
      dashboard: $dashboard
      imageBytes: $imageBytes
      title: $title
      buildings: $buildings
      companies: $companies
      dashboardName: $dashboardName
    ) {
      success
      message
    }
  }
`;

export const DELETE_GRAPH_IMAGE = gql`
  mutation deleteGraphImage($customerId: String!, $dashboard: String!) {
    deleteGraphImage(customerId: $customerId, dashboard: $dashboard) {
      success
      message
    }
  }
`;
