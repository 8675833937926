import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { DELETE_METER } from "./commands";

export function useDeleteMeter(onCompleted = () => {}, onError = () => {}) {
  const [deleteMeter, { loading }] = useMutation(DELETE_METER, {
    onCompleted,
    onError,
    refetchQueries: ["getBuildingMeters"]
  });

  const delMeter = useCallback(
    variables => {
      deleteMeter({
        variables
      });
    },
    [deleteMeter]
  );

  return {
    delete: delMeter,
    loading
  };
}
