import { useQuery } from "@apollo/client";
import { GET_TAB_GROUPS } from "./commands";

export function useTabGroups() {
  const { data, loading, error, refetch } = useQuery(GET_TAB_GROUPS);

  return {
    tabGroups: data?.tabGroups,
    loading,
    error,
    refetch
  };
}
