import { gql } from "@apollo/client";

export const GET_CUSTOMERS_ROLES_BY_ID = gql`
  query getCustomerRoles($customerId: String) {
    comCustomerRoles(customerId: $customerId) {
      name
    }
  }
`;
// query getCustomerRoles {
//     comCustomerRoles {
//         name
//     }
// }
