import React from "react";
// import GEnergyTypes from "./GEnergyTypes";
import EnergyCollectionTypes from "./EnergyCollectionTypes";

const Heat = () => {
  return (
    <>
      {/* <GEnergyTypes type="heating" sourceType="Heating" /> */}
      <EnergyCollectionTypes type="heating" sourceType="Heating" />
    </>
  );
};
export default Heat;
