import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { DataGridComponent } from "../../components";
import { useFetchEmissionGroups } from "./hooks";

export function EmissionGroupsListTable() {
  const { data, loading } = useFetchEmissionGroups();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const columns = [
    {
      field: "id",
      headerName: "id",
      flex: 0.2,
      headerAlign: "left"
    },
    {
      field: "nameEn",
      headerName: "nameEn",
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "nameDknameDa",
      headerName: "nameDknameDa",
      flex: 1,
      headerAlign: "left"
    }
  ];

  return (
    <div>
      <div className="card card-custom example example-compact">
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3 className="custom-header-list">
              <span>{gettext("Emission Groups")}</span>
            </h3>
          </div>

          {/* end::Header */}

          <div className="card-body customer-list">
            <DataGridComponent
              rows={data}
              columns={columns}
              loading={loading}
              disableSelectionOnClick={true}
              autoHeight
            />
          </div>
        </div>
      </div>
    </div>
  );
}
