import { gql } from "@apollo/client";

export const GET_PARTNER_MEMBERS = gql`
  query getPartnerMembers($customerKey: String!) {
    comGetMembers(customerKey: $customerKey) {
      address
      city
      country
      customerKey
      cvr
      id
      name
      personalTeam
      phone
      phone2
      pnumber
      sef
      type
      userId
      zip
    }
  }
`;

export const DELETE_PARTNER = gql`
  mutation comSetCustomerNull($customerName: String) {
    comSetCustomerNull(customerName: $customerName) {
      success
      message
    }
  }
`;
