import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useAuth, useUser } from "app/providers";
import { deleteAll } from "app/common/IndexedDb";
import { LayoutSplashScreen } from "_metronic/layout";
import { Navigate, useLocation } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

const Logout = props => {
  const location = useLocation();

  const { state } = location;

  const { user, setUser } = useUser();

  const { unsetToken } = useAuth();

  const client = useApolloClient();

  const storageLocale = localStorage.getItem("languageSelect");
  localStorage.clear();
  storageLocale && localStorage.setItem("languageSelect", storageLocale);

  deleteAll("cache");

  ReactGA.event({
    category: "Authentication",
    action: "Logout",
    label: user.email?.replace("@", "[at]")
  });

  setUser(null);

  client.clearStore();

  useEffect(() => {
    if (!state || !state?.loginAs) {
      unsetToken();
    }
  }, [state, unsetToken]);

  if (state?.loginAs) {
    return <Navigate to={"/dashboard"} />;
  } else {
    return <LayoutSplashScreen />;
  }

  // return <LayoutSplashScreen />;
};

export default Logout;
