import { useUser } from "app/providers";
import { useState, useEffect } from "react";

export const usePagePrivileges = moduleName => {
  const [_read, setReadPrivilege] = useState(false);
  const [_write, setWritePrivilege] = useState(false);
  const [_delete, setDeletePrivilege] = useState(false);

  const { user } = useUser();

  const { modulePermissions } = user || {};

  useEffect(() => {
    if (moduleName && modulePermissions && modulePermissions.length > 0) {
      const module = modulePermissions.find(item => item.code === moduleName);
      if (module) {
        setReadPrivilege(module?.read);
        setWritePrivilege(module?.write);
        setDeletePrivilege(module?.delete);
      }
    }
  }, [modulePermissions, moduleName]);

  return [_read, _write, _delete];
};
