import React, { useEffect, useRef } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import { Box, Typography, useTheme } from "@mui/material";
import { IoCopy } from "react-icons/io5";
import { CopyButton } from "../CopyButton";
import { useTextdomainContext } from "app/providers";
// import { LivePreviewButton } from "../LivePreviewButton";

export function CodeBlock({ language, children }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const codeRef = useRef(null);

  useEffect(() => {
    if (codeRef.current) {
      hljs.highlightElement(codeRef.current);
    }
  }, [children]);

  const theme = useTheme();

  return (
    <pre
      style={{
        height: "100%",
        overflow: "auto",
        overflowWrap: "anywhere",
        borderRadius: "8px",
        backgroundColor: theme.palette.secondary.main
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "8px"
        }}
      >
        <Typography color={"secondary.50"}>{language}</Typography>

        <CopyButton
          variant="text"
          sx={{ color: "secondary.50" }}
          startIcon={<IoCopy size={12} />}
          text={children}
        >
          {gettext("copy code")}
        </CopyButton>
      </Box>
      <code ref={codeRef} className={`language-${language}`}>
        {children}
      </code>
    </pre>
  );
}
