import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { Box, Button, Chip, Menu, MenuItem, Typography } from "@mui/material";
import { IoCheckmark, IoChevronDown } from "react-icons/io5";

function TableDropdown(props) {
  const { onChange, options, selectedOption, gettext } = props;
  const [selected, setSelected] = useState(selectedOption);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleYearChange = item => {
    setSelected(item);
    onChange(item);
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<IoChevronDown size={12} />}
        color="secondary"
        onClick={handleClick}
      >
        <Typography
          color="secondary.800"
          fontWeight="fontWeightMedium"
          fontSize="14px"
        >
          {gettext("Year")}
        </Typography>
        {selected !== "" && (
          <Chip
            sx={{
              ml: 0.5,
              backgroundColor: "primary.50",
              color: "primary.main",
              fontSize: "14px"
            }}
            size="small"
            label={<Typography>{selected.label}</Typography>}
          />
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-paper": {
            boxSizing: "borderBox",
            position: "relative",
            width: "150px",
            background: "#FFFFFF",
            border: "1px solid #E4E4E7",
            boxShadow:
              "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px"
          },
          "& .MuiMenuItem-root": {
            fontWeight: 400,
            fontSize: "14px",
            height: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "4px",
            mx: 1
          },
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: "#FFFFFF",
            color: "#19384d"
          }
        }}
      >
        <Box>
          {options &&
            options.length > 0 &&
            options?.map((item, index) => (
              <MenuItem
                key={item.value}
                selected={selected.value === item.value}
                onClick={() => {
                  handleYearChange(item);
                }}
              >
                {gettext(item.label)}
                {selected.value === item.value && (
                  <IoCheckmark size={16} color="#19384d" />
                )}
              </MenuItem>
            ))}
        </Box>
      </Menu>
    </>
  );
}

export default injectIntl(TableDropdown);
