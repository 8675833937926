import React, { useContext } from "react";
import List from "@mui/material/List";
import Level1MenuItem from "./Level1MenuItem";
import Level2MenuItem from "./Level2MenuItem";
import Level2MenuHead from "./Level2MenuHead";
import {
  RiHomeLine,
  RiBuilding2Line,
  RiMapPin2Line,
  RiAdminLine,
  RiTranslate
  // RiPriceTag3Line
} from "react-icons/ri";
import { GiMoneyStack } from "react-icons/gi";
// import {
//     AiOutlineThunderbolt
// } from "react-icons/ai";
import { MdOutlineViewStream, MdOutlineAccountCircle } from "react-icons/md";
// import { MdOutlineAccountCircle } from "react-icons/md";
import TimelineIcon from "@mui/icons-material/Timeline";
import Logout from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  useCustomerPermission,
  useTextdomainContext
  // useUser,
} from "app/providers";
import { CanDisplay } from "app/common/Methods";

export function Menu() {
  // const { user } = useUser();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  // useEffect(() => {
  //     const { email, isSuperAdmin } = user;
  //     if (email && !isSuperAdmin && email.split("@")[1] !== "fabeke.com") {
  //         setSupportAccess(false);
  //     }
  // }, [user]);

  const { canRead, canAccessPage } = useCustomerPermission();
  // const { canRead } = useCustomerPermission();

  return (
    <List sx={{ paddingX: 2 }}>
      <Level1MenuItem
        link={"/dashboard"}
        label={gettext("Dashboard")}
        icon={<RiHomeLine size={18} className="mx-3" />}
        code="dashboard"
      />

      <Level2MenuHead
        label={gettext("Buildings")}
        // code="building-energy"
        display={CanDisplay("Buildings")}
        icon={<MdOutlineViewStream size={18} className="mx-3" />}
        link={"/buildings"}
      >
        <Level2MenuItem
          link={"/buildings/energy"}
          label={gettext("Energy")}
          code="building-energy"
        />
        <Level2MenuItem
          link={"/buildings/electricity"}
          label={gettext("Electricity")}
          code="building-electricty"
        />
        <Level2MenuItem
          link={"/buildings/heating"}
          label={gettext("District Heating")}
          // label={gettext("Heating")}
          code="building-heating"
        />
        <Level2MenuItem
          link={"/buildings/gas"}
          label={gettext("Natural Gas")}
          code="building-gas"
        />
        <Level2MenuItem
          link={"/buildings/water"}
          label={gettext("Water")}
          code="building-water"
        />
        <Level2MenuItem
          link={"/buildings/epc"}
          label={gettext("EPC")}
          code="building-epc"
        />
        <Level2MenuItem
          link={"/buildings/co2-report"}
          label={gettext("CO₂ Report")}
          code="building-co2-report"
        />
        {/* <Level2MenuItem
         link={"/buildings/new-co2-report"}
         label={gettext('CO₂ Report')}
         code="building-co2-report"
       /> */}
        <Level2MenuItem
          link={"/buildings/benchmark"}
          label={gettext("Benchmark")}
          code="building-benchmark"
        />
        {canRead("administration") && (
          <Level2MenuItem
            link={"/buildings/administration"}
            label={gettext("Administration")}
            code="building-administration"
          />
        )}
      </Level2MenuHead>

      <Level2MenuHead
        link={"/company"}
        label={gettext("Company")}
        // code="company-report"
        display={CanDisplay("Company")}
        icon={<RiBuilding2Line size={18} className="mx-3" />}
      >
        {/* <Level2MenuItem
         link={"/company/scope-1"}
         label={
           <>
             gettext('Scope') 1
           </>
         }
         code="company-scope1"
       />
       <Level2MenuItem
         link={"/company/scope-2"}
         label={
           <>
             gettext('Scope') 2
           </>
         }
         code="company-scope2"
       />
       <Level2MenuItem
         link={"/company/scope-3"}
         label={
           <>
             gettext('Scope') 3
           </>
         }
         code="company-scope1"
       /> */}
        <Level2MenuItem
          link={"/company/account-link"}
          label={gettext("Account link")}
          code="company-account-link"
        />
        <Level2MenuItem
          link={"/company/data"}
          label={gettext("Data")}
          code="company-data"
        />
        <Level2MenuItem
          link={"/company/data-link"}
          label={gettext("Data Link")}
          code="company-data"
        />
        <Level2MenuItem
          link={"/company/ghg-result"}
          label={gettext("GHG Result")}
          code="company-report"
        />
        {/* <Level2MenuItem
         link={"/company/comparison"}
         label={gettext('Comparison')}
         code="company-comparision"
       /> */}

        {/* <Level2MenuItem
         link={"/company/settings"}
         label={gettext('Settings')}
         // code='my-settings'
       /> */}
        {/* <Level2MenuItem
         link={"/company/customers"}
         label={gettext('Companies')}
         // code='my-customers'
       /> */}
        {/* <Level2MenuItem
         link={"/company/all"}
         label={gettext('All')}
         // code='building-water'
       /> */}
      </Level2MenuHead>

      <Level2MenuHead
        link={"/procurement"}
        label={gettext("Procurement")}
        display={CanDisplay("Procurement")}
        icon={<GiMoneyStack size={18} className="mx-3" />}
      >
        <Level2MenuItem
          link={"/procurement/electricity"}
          label={gettext("Electricity")}
          code="procurement-electricty"
        />
      </Level2MenuHead>

      {canRead("map") && (
        <Level1MenuItem
          link={"/report/map"}
          label={gettext("Map")}
          code="map"
          //svg={toAbsoluteUrl("/media/gg/808080/svg-256/map.svg")}
          icon={<RiMapPin2Line size={18} className="mx-3" />}
        />
      )}

      {/* <Level2MenuHead
        link={"/partner"}
        label={gettext("Network")}
        // code="admin-buildings"
        display={CanDisplay("Partner")}
        icon={<FaRegHandshake size={18} className="mx-3" />}
      >
        <Level2MenuItem
          link={"/partner/members-list"}
          label={gettext("Members List")}
          code="partner-users"
        />
      </Level2MenuHead> */}

      <Level2MenuHead
        link={"/admin"}
        label={gettext("Admin")}
        // code="admin-buildings"
        display={CanDisplay("Admin")}
        icon={<RiAdminLine size={18} className="mx-3" />}
      >
        <Level2MenuItem
          link={"/admin/users-list"}
          label={gettext("Users List")}
          code="admin-users"
        />
        {/* <Level2MenuItem
          link={"/admin/partners-list"}
          label={gettext("Network List")}
          code="admin-users"
        /> */}
        <Level2MenuItem
          link={"/admin/system-role-management"}
          label={gettext("System Roles")}
          code="admin-role-mangt"
        />
        <Level2MenuItem
          link={"/admin/scrapper-log"}
          label={gettext("Scraper Log")}
          code="scrapper-log"
        />
        <Level2MenuItem
          link={"/admin/scrapper-list"}
          label={gettext("Scraper List")}
          // code="scrapper-log"
        />
        <Level2MenuItem
          link={"/admin/admin-energy-supplier"}
          label={gettext("Admin Energy Supplier")}
          code="admin-energy-supplier"
        />
        <Level2MenuItem
          link={"/admin/account-groups"}
          label={gettext("Account Group")}
          // code="admin-energy-supplier"
        />
        <Level2MenuItem
          link={"/admin/account-charts"}
          label={gettext("Account Charts")}
          // code="admin-energy-supplier"
        />
        <Level2MenuItem
          link={"/admin/emission-factors"}
          label={gettext("Emission Factors")}
          // code="admin-energy-supplier"
        />
        <Level2MenuItem
          link={"/admin/default-settings"}
          label={gettext("Default Settings")}
        />
        <Level2MenuItem
          link={"/admin/service-providers"}
          label={gettext("Service Providers")}
        />
      </Level2MenuHead>

      {canAccessPage("prediction") && (
        <Level2MenuHead
          label={gettext("Prediction")}
          // code="building-energy"
          display={CanDisplay("prediction")}
          icon={<TimelineIcon size={18} className="mx-3" />}
          link={"/prediction"}
        >
          <Level2MenuItem
            link={"/prediction/electricity"}
            label={gettext("Electricity")}
            code="prediction"
          />
        </Level2MenuHead>
      )}

      {/* {supportAccess && ( */}
      <Level2MenuHead
        link={"/support"}
        label={gettext("Support")}
        // code="admin-buildings"
        display={CanDisplay("support")}
        icon={<RiTranslate size={18} className="mx-3" />}
      >
        <Level2MenuItem
          link={"/support/datahub"}
          label={gettext("DataHub")}
          code="support-datahub"
        />
        <Level2MenuItem
          link={"/support/translation"}
          label={gettext("Translation")}
          code="support-translation"
        />
        <Level2MenuItem
          link={"/support/active-users"}
          label={gettext("Active Users")}
          code="support-active-users"
        />
      </Level2MenuHead>
      {/* )} */}

      <Level2MenuHead
        link={"/user-profile"}
        label={gettext("My Account")}
        // code="admin-buildings"
        display={CanDisplay("Account")}
        icon={<MdOutlineAccountCircle size={18} className="mx-3" />}
      >
        <Level2MenuItem
          link={"/user-profile/personal-information"}
          label={gettext("My Profile")}
          code="my-profile"
        />
        <Level2MenuItem
          link={"/user-profile/change-password"}
          label={gettext("Change Password")}
          code="my-password"
        />
        <Level2MenuItem
          link={"/user-profile/customers"}
          label={gettext("My Companies")}
          code="my-customers"
        />
        <Level2MenuItem
          link={"/user-profile/partner-web"}
          label={gettext("Access from partner website")}
          code={"partner-users"}
        />
        {canRead("customer-roles") && (
          <Level2MenuItem
            link={"/user-profile/customer-roles"}
            label={gettext("Company Roles")}
            code={"my-account-customer-roles"}
          />
        )}
        {canRead("api-key") && (
          <Level2MenuItem
            link={"/user-profile/api-key"}
            label={gettext("API KEY")}
            code={"my-account-api-key"}
          />
        )}
        <Level2MenuItem
          link={"/user-profile/api-docs"}
          label={gettext("API Docs")}
          code={undefined}
        />
        <Level2MenuItem
          link={"/Notifications"}
          label={gettext("Notifications")}
          code={"my-account-notifications"}
          icon={
            <NotificationsIcon
              className="mx-3"
              sx={{ fontSize: 18, cursor: "pointer" }}
            />
          }
        />
        <Level2MenuItem
          link={"/user-profile/settings"}
          label={gettext("Settings")}
          code={"my-account-settings"}
        />
      </Level2MenuHead>

      <Level1MenuItem
        link={"/logout"}
        label={gettext("Sign Out")}
        icon={<Logout className="mx-3" />}
      />
    </List>
  );
}
