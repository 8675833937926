import React from "react";
import { ProgressBar } from "react-bootstrap";
import Select from "react-select";
// import { readRemoteFile, readString } from "react-papaparse";
import { readString } from "react-papaparse";
// import { size } from "lodash";

//PAGE DEPRECATED
class AddPlan extends React.Component {
  fileInput;

  constructor(props) {
    super(props);

    this.state = {
      planName: props.location.state.data[0],
      fileName: "",
      fileSize: "",
      percentage: 0,
      csvStr: "",
      isEditPage: props.location.state.type === "edit",
      isNameEmpty: false,
      checked: false,
      individualPlanList: [],
      allDropdownSelectedValues: [],
      planList: [
        {
          heading: "Omkostninger til råvarer og hjælpematerialer",
          values: [
            {
              standardPlan: { name: "1610 Varekøb m/ moms" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },

            {
              standardPlan: { name: "1630 Varekøb udland" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1650 Varekøb udland, ikke-EU" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1670 Varekøb u/ moms" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            }
          ],
          isOpen: false
        },
        {
          heading: "Andre eksterne omkostninger",
          values: [],
          isOpen: false
        },
        {
          heading: "Salgsomkostninger",
          values: [
            {
              standardPlan: { name: "1710 Fragtomkostninger" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1730 Annoncering og reklame" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1750 Udstillinger og dekoration" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1770 Restaurationsbesøg" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1790 Repræsentationsomkostninger" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1810 Repræsentationsomkostninger" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1830 Andre salgsomkostninger" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1850 Gaver og blomster" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            }
          ],
          isOpen: false
        },
        {
          heading: "Lokaleomkostninger",
          values: [
            {
              standardPlan: {
                name: "1910 Husleje, ekskl. el, vand og varme /m moms"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: {
                name: "1930 Husleje, ekskl. el, vand og varme /u moms"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1950 El" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "1990 Vand" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "2010 Varme" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "2090 Rengøring og renovation" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing---"
              }
            },
            {
              standardPlan: { name: "2110 Reparation og vedligeholdelse" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2130 Forsikringer" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2170 Andre lokaleomkostninger" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            }
          ],
          isOpen: false
        },
        {
          heading: "Administrationsomkostninger",
          values: [
            {
              standardPlan: {
                name:
                  "2210 Småanskaffelser under skattemæssig grænse for småaktiver"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: {
                name:
                  "2230 Småanskaffelser over skattemæssig grænse for småaktiver"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2250 Underleverandører" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: {
                name: "2270 Forsknings- og udviklingsomkostninger"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2290 Øvrige produktionsomkostninger" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2370 It-udstyr mv." },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: {
                name: "2390 Skattefri rejse- og befordringsgodtgørelse"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2410 Kontingenter" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2430 Faglitteratur" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2450 Porto og gebyrer, u/ moms" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2470 Porto og gebyrer, m/ moms" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: {
                name:
                  "2490 Telefon, internet mv. på virksomhedens adresse (kun virksomhed)"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: {
                name:
                  "2510 Mobiltelefoni, internet mv., anskaffelse og abonnement (delvis privat)"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2530 Kontorartikler" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: {
                name:
                  "2550 Leje og operationelle leasingydelser (ekskl. husleje)"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2570 Rejseudgifter" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2590 Vikarassistance" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2610 Konsulentydelser" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2630 Kursusudgifter" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: {
                name:
                  "2650 Driftsomkostninger, personbiler (fradragsret for moms)"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: {
                name:
                  "2670 Driftsomkostninger, personbiler (ikke fradragsret for moms)"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2690 Driftsomkostninger, varebiler" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2710 Arbejdsskadeforsikring" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: {
                name: "2750 Revision og regnskabsmæssig assistance"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2770 Advokatmæssig assistance" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: { name: "2790 Øvrige rådgivningshonorarer" },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            },
            {
              standardPlan: {
                name: "2810 Administrationsvederlag/management fee"
              },
              individualPlan: {
                url:
                  "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing"
              }
            }
          ],
          isOpen: false
        }
      ]
    };
  }

  customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: "40px",
      height: "40px",
      background: "rgb(236,246,239)",
      borderColor: "lightgray",
      borderWidth: 1,
      color: "white",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: "lightgray"
      }
    }),
    multiValue: base => ({
      ...base,
      color: "red",
      position: "absolute",
      top: 0
    }),
    multiValueLabel: base => ({
      ...base,
      backgroundColor: "green",
      // width: 0,
      color: "white",
      display: "none"
    }),
    multiValueRemove: base => ({
      ...base,
      display: "none"
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 6px"
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px"
    }),
    indicatorSeparator: state => ({
      display: "none"
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px"
    })
  };

  chosenPlanStyle = {
    control: (base, state) => ({
      ...base,
      // minHeight: '40px',
      // height: '40px',
      background: "rgb(236,246,239)",
      // borderColor: state.isFocused ? "#eee" : "green",
      borderColor: "lightgray",
      borderWidth: 1,
      color: "white",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: "lightgray"
      }
    })
  };

  str2ab = str => {
    let buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    let bufView = new Uint16Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  };

  getPlanListFromCSV = str => {
    readString(str, {
      complete: res => {
        let planList = this.state.individualPlanList;
        res.data.forEach((r, index) => {
          // r.length > 3 && (r[4] === 'FALSE' || r[4] === 'TRUE') && planList.push({ value: (r[0] + ' ' + r[1]), label: (r[0] + ' ' + r[1]), isOptionDisabled: r[4] })
          let decoder = new TextDecoder("iso-8859-1");
          const txt = decoder.decode(this.str2ab(r[0] + " " + r[1]));
          index > 3 &&
            parseInt(r[0]) &&
            planList.push({
              value: txt,
              label: txt,
              isOptionDisabled: false
            });
        });
        this.setState({ individualPlanList: planList });
        // console.log('readString1222VISHAL', planList)
      }
    });

    // readRemoteFile('/media/csv/saldobalance.csv', {
    //     complete: (res) => {
    //         var planList = this.state.individualPlanList
    //         // console.log('readString1222VISHAL111--', res)
    //         res.data.forEach((r, index) => {
    //             (r[4] === 'FALSE' || r[4] === 'TRUE') && planList.push({ value: (r[0] + ' ' + r[1]), label: (r[0] + ' ' + r[1]), isOptionDisabled: r[4] })
    //         })
    //         this.setState({ individualPlanList: planList })
    //         console.log('readString1222VISHAL', planList)
    //     }
    // })
  };

  getFormattedDate = () => {
    const date = new Date();
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date
      .getDate()
      .toString()
      .padStart(2, "0");

    return day + "/" + month + "/" + year;
  };

  onSaveTap = () => {
    const { isEditPage, planName } = this.state;

    if (this.state.planName === "") {
      this.setState({ isNameEmpty: true });
    } else {
      const sd = sessionStorage.getItem("accountPlan");
      const plan = JSON.parse(sd);
      if (isEditPage) {
        //Edit Plan page
        const index = this.props.location.state.index;
        if (plan) {
          plan.content[index][0] = planName;
          plan.content[index][1] = this.getFormattedDate();
          plan.content[index][2] = this.getFormattedDate();
          sessionStorage.setItem("accountPlan", JSON.stringify(plan));
          this.props.history.push("/settings/account-link");
        }
      } else {
        //Add Plan page
        // plan.content.push([this.state.planName, this.getFormattedDate(), this.getFormattedDate(), "View", "Edit", "Activate"])
        plan &&
          plan.content.push([
            this.state.planName,
            this.getFormattedDate(),
            this.getFormattedDate(),
            "View",
            "Edit",
            "Activate"
          ]);
        sessionStorage.setItem("accountPlan", JSON.stringify(plan));
        this.props.history.push("/settings/account-link");
      }
    }
  };

  onFileSelect = async e => {
    let files = e.target.files;
    // console.log('file-reader ', files)
    if (files.length > 0) {
      const str = await files[0].text();
      // console.log('file-reader ', files)
      this.setState({
        fileName: files[0].name,
        fileSize: files[0].size,
        csvStr: str
      });
    }
  };

  onUpload = () => {
    const random = Math.floor(Math.random() * (70 - 30 + 1)) + 30;
    this.setState({ percentage: random }, () => {
      setTimeout(() => {
        this.setState({ percentage: 100 }, () => {
          if (this.state.csvStr !== "") {
            this.getPlanListFromCSV(this.state.csvStr);
          }
          setTimeout(() => {
            this.onCancelUpload();
          }, 800);
        });
      }, 800);
    });
  };

  onCancelUpload = () => {
    this.fileInput.value = "";
    this.setState({ fileName: "", percentage: "", fileSize: "" });
  };

  getCSVPlan = () => {
    // const url = 'https://sample-videos.com/csv/Sample-Spreadsheet-10-rows.csv'
    const url =
      "https://drive.google.com/file/d/1s8laMO1zBY2RqDm6RMha8ipz_2_1x73Q/view?usp=sharing";
    window.open(url);
  };

  handleToggle = checked => {
    this.setState({ checked });
  };

  onHeadingClick = (plan, index) => {
    const list = this.state.planList;
    list[index].isOpen = !list[index].isOpen;
    this.setState({ planList: list });
  };

  shouldDisableOption = (item, allDropdownSelectedValues) => {
    let disable = false;
    allDropdownSelectedValues.forEach(val => {
      val.selected.forEach(sel => {
        if (sel.value === item.value) {
          disable = true;
        }
      });
    });
    // console.log('drop-option-disable ', disable)
    return disable;
  };

  updateIndividualPlanList = allDropdownSelectedValues => {
    //this will update values for disable option in dropdown
    let list = this.state.individualPlanList;
    // console.log("dropdown- 1", allDropdownSelectedValues)
    list.forEach((l, index) => {
      // const index1 = allDropdownSelectedValues.findIndex(x => (x.selected === l));
      // if (index1 >= 0) {
      if (this.shouldDisableOption(l, allDropdownSelectedValues)) {
        list[index].isOptionDisabled = true;
      } else {
        list[index].isOptionDisabled = false;
      }
    });
    // console.log('drop-updated-list ', list)
    this.setState({ individualPlanList: list });
  };

  handleDropdown = (selected, planName, index1) => {
    // console.log("dropdown- ", selected)

    let allDropdownSelectedValues = this.state.allDropdownSelectedValues;
    const selectedIndex = allDropdownSelectedValues.findIndex(
      x => x.standardPlanName === planName
    );

    if (selectedIndex >= 0) {
      allDropdownSelectedValues[selectedIndex].selected = selected
        ? selected
        : [];
    } else {
      allDropdownSelectedValues.push({
        selected: selected,
        standardPlanName: planName
      });
    }

    this.setState({ allDropdownSelectedValues: allDropdownSelectedValues });
    this.updateIndividualPlanList(allDropdownSelectedValues);
  };

  getChosenPlanValues = standardPlanName => {
    let arr = [];
    this.state.allDropdownSelectedValues.forEach(v => {
      if (v.standardPlanName === standardPlanName) {
        arr = v.selected;
      }
    });
    return arr;
  };

  niceBytes = x => {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    //include a decimal point and a tenths-place digit if presenting
    //less than ten of KB or greater units
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  };

  renderTopSection = () => {
    // const { fileName, isNameEmpty, planName, isEditPage } = this.state
    // const { isNameEmpty, planName } = this.state
    return (
      <div>
        {/* <div className='custom-row'> */}
        {/* <button className='standard-account-btn'>Add Account Plan</button> */}
        {/* <button className='standard-account-btn spacing' onClick={this.onSaveTap}>Save</button> */}
        {/* </div> */}
        <div className=" card-custom gutter-b example example-compact">
          <div className="">
            <h3 className="">
              <span className="add-plan-header font-weight-bolder padding-container">
                Add Account Plan
              </span>
            </h3>
            <hr className="mt20" />
            {/* <div className="card-toolbar"/> */}
          </div>
        </div>
        {/* <div className='modal-title'>{isEditPage ? 'Edit Account Plan' : 'Add Account Plan'}</div> */}
        <div className="modal-name-tf-container">
          <div className="custom-row">
            <div className="bold padding-container">
              1. Upload your account plan:
            </div>
            <div className="ml20">
              <button
                className="modal-upload mr20"
                onClick={() => {
                  this.fileInput.click();
                }}
              >
                Upload
              </button>
              <input
                className="modal-picker spacing"
                type="file"
                accept=".csv"
                onChange={e => this.onFileSelect(e)}
                ref={ref => (this.fileInput = ref)}
              />
            </div>
          </div>
          {/* <div className='custom-row'> */}
          {/* <div>Name</div>
                        <input
                            placeholder="Enter Name"
                            className={'modal-name-tf'}
                            name="Name"
                            value={planName}
                            onChange={(e) => this.setState({ planName: e.target.value, isNameEmpty: false })}
                        /> */}

          {/* <div className='spacing'>
                            <button className='modal-upload mr20' onClick={() => { console.log('this.fileInput ', this.fileInput.click()) }}>Upload your account plan</button>
                            <input className='modal-picker spacing' type='file' accept='.csv' onChange={(e) => this.onFileSelect(e)} ref={ref => (this.fileInput = ref)} />
                        </div> */}
          {/* <button disabled={fileName === ''} className={fileName === '' ? 'modal-upload-disable' : 'modal-upload'} onClick={this.onUpload} >Upload your account plan</button> */}
          {/* </div> */}
          {/* {isNameEmpty && <div className="fv-plugins-message-container"> */}
          {/* <div className="fv-help-block">Enter Name</div> */}
          {/* </div>} */}
        </div>
      </div>
    );
  };

  renderAccountLink = () => {
    const { planList, individualPlanList, planName } = this.state;

    return (
      <div>
        <div className="bold padding-container">
          2. Link your account plan to the standard account plan for Climate
          report:
        </div>
        <div className="custom-row">
          <div className="account-plan-values-container ml20">
            <div className="color-primary-green bold padding-container">
              Standard Plan
            </div>
          </div>
          <div className="account-plan-dropdown-container mr150">
            <div className="color-primary-green bold padding-container">
              Konto
            </div>
          </div>
        </div>

        <div className="border-line mt8 mb8"></div>

        {planList.map((plan, index) => (
          <div key={index}>
            {/* Heading */}
            <button
              className={"account-plan-heading"}
              onClick={() => this.onHeadingClick(plan, index)}
            >
              <div
                className={
                  index === 0
                    ? "custom-row pt12 mb12"
                    : index === planList.length - 1
                    ? "custom-row pb12 pt8 mb12"
                    : "custom-row pt8 mb12"
                }
              >
                <div className="account-link-arrow">
                  <img
                    className={
                      plan.isOpen
                        ? "plan-heading-arrow-180"
                        : "plan-heading-arrow-0"
                    }
                    src={"/media/svg/icons/Navigation/Angle-down.svg"}
                    alt={"..."}
                  ></img>
                </div>
                <div className="plan-heading-italic">{plan.heading}</div>
              </div>
              {index !== planList.length - 1 && (
                <div className="border-line mb-m5"></div>
              )}
            </button>

            {/* sub category view */}
            {plan.isOpen &&
              plan.values &&
              plan.values.map((val, index1) => (
                <div key={index1} className="account-plan-values-box">
                  <div
                    key={index1}
                    className={
                      index1 === plan.values.length - 1
                        ? "account-plan-values-container pb12 pt8 mt12"
                        : "account-plan-values-container mt12 pt8"
                    }
                  >
                    <div className="account-link-val1">
                      {val.standardPlan.name}
                    </div>

                    {/* dropdown plan list */}
                    <img
                      className="mr20 ml20"
                      src={"/media/svg/icons/Navigation/right-arrow.svg"}
                      alt={"..."}
                    ></img>
                    <Select
                      className="account-plan-dropdown"
                      placeholder="Account"
                      value={this.getChosenPlanValues(val.standardPlan.name)}
                      isMulti
                      isSearchable={false}
                      isClearable={false}
                      onChange={(selected, index) =>
                        this.handleDropdown(
                          selected,
                          val.standardPlan.name,
                          index1
                        )
                      }
                      options={individualPlanList}
                      styles={this.customStyles}
                      isOptionDisabled={option => option.isOptionDisabled}
                    />

                    {/* 3rd box - chosen plan */}
                    <img
                      className="mr20 ml20"
                      src={"/media/svg/icons/Navigation/right-arrow.svg"}
                      alt={"..."}
                    ></img>
                    <Select
                      className="account-plan-dropdown mr20"
                      placeholder="No chosen account"
                      isMulti
                      value={this.getChosenPlanValues(val.standardPlan.name)}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                      isSearchable={false}
                      isClearable={false}
                      noOptionsMessage={() => null}
                      onChange={(selected, index) =>
                        this.handleDropdown(
                          selected,
                          val.standardPlan.name,
                          index1
                        )
                      }
                      styles={this.chosenPlanStyle}
                    />
                  </div>
                  <div className="border-line mt20"></div>
                </div>
              ))}
          </div>
        ))}
        <div className="custom-row padding-container">
          <div className="bold">3. Save your account plan:</div>
          {/* <div className='ml20'>Name</div> */}
          <input
            placeholder="Enter Name"
            className={"modal-name-tf"}
            name="Name"
            value={planName}
            onChange={e =>
              this.setState({ planName: e.target.value, isNameEmpty: false })
            }
          />
          <button
            className="standard-account-btn spacing mr20"
            onClick={this.onSaveTap}
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  renderUploadBox = () => {
    const { fileName, fileSize, percentage } = this.state;
    return (
      <div className="fade-bg">
        <div className="border-line"></div>
        <div className="custom-row pt20 pb20 pl20 pr20">
          {/* File Name */}
          <div>{fileName}</div>

          {/* Size and progress bar */}
          <div className="spacing width200">
            <div className="text-center mb8">{this.niceBytes(fileSize)}</div>
            <ProgressBar animated now={percentage} label={`${percentage}%`} />
          </div>

          {/* Buttons */}
          <div className="custom-row spacing">
            <button class="standard-account-btn" onClick={this.onUpload}>
              <i class="fa fa-upload"></i>
              <span>Start</span>
            </button>

            <button class="upload-cancel ml10" onClick={this.onCancelUpload}>
              <i class="fa fa-ban "></i>
              <span>Cancel</span>
            </button>
          </div>
        </div>
        <div className="border-line mb20"></div>
      </div>
    );
  };

  render() {
    // const {fileName, percentage, isNameEmpty, planName, isEditPage} = this.state
    const { fileName } = this.state;
    // console.log('fileName-111 ', fileName)
    return (
      <div className="card card-custom gutter-b example example-compact">
        <div className="add-plan-container">
          {this.renderTopSection()}
          {fileName !== "" && this.renderUploadBox()}

          {/* {isEditPage && <div className='csv-container'>
                    <div>CSV file</div>
                    <button className={'modal-upload'} onClick={this.getCSVPlan}>Get CSV file</button>
                </div>} */}
          {this.renderAccountLink()}
          {/* <div className='row'>
                        <button className='account-table-btn2' onClick={this.onAddTap}>{isEditPage ? 'Edit' : 'Add'}</button>
                    </div> */}
        </div>
      </div>
    );
  }
}

export default AddPlan;
