import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";

const AccountGroupForm = props => {
  const {
    nameDa,
    nameDe,
    nameEn,
    type,
    setNameDa,
    setNameDe,
    setNameEn,
    setType
  } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  return (
    <div className="row m-0 border-top-row">
      <div className="col-sm-12  mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Type")}</label>
          <input
            type="text"
            className="form-control input"
            id="type"
            name="type"
            required
            placeholder={gettext("Enter Type")}
            value={type}
            onChange={e => setType(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12  mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Danish Name")}</label>
          <input
            type="text"
            className="form-control input"
            id="nameDa"
            name="nameDa"
            required
            placeholder={gettext("Enter Danish Name")}
            value={nameDa}
            onChange={e => setNameDa(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("German Name")}</label>
          <input
            type="text"
            className="form-control input"
            id="nameDe"
            name="nameDe"
            required
            placeholder={gettext("Enter German Name")}
            value={nameDe}
            onChange={e => setNameDe(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("English Name")}</label>
          <input
            type="text"
            className="form-control input"
            id="nameEn"
            name="nameEn"
            required
            placeholder={gettext("Enter English Name")}
            value={nameEn}
            onChange={e => setNameEn(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountGroupForm;
