import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
// import client from "../../../services/apiClient";
// import { apiRoutes } from "../../../utils";
import { DELETE_PARTNER, GET_PARTNER_MEMBERS } from "./commands";

export function usePartnerMembers(customerKey) {
  const { data, loading } = useQuery(GET_PARTNER_MEMBERS, {
    variables: {
      customerKey
    }
  });

  return {
    members: data?.comGetMembers || [],
    loading
  };
}

export function useRemove(Callback) {
  // const [loading, setLoading] = useState(false);

  // const [success, setSuccess] = useState(false);

  // const [error, setError] = useState(false);

  const [deletePartner, { loading }] = useMutation(DELETE_PARTNER, {
    onCompleted: () => {
      Callback();
    },
    onError: () => {
      // onError();
    }
  });

  const remove = useCallback(
    id => {
      deletePartner({
        variables: { customerName: id },
        refetchQueries: ["getPartnerMembers"]
      });
      // client
      //   .delete(apiRoutes.removeMembers.path + `/?id=${id}`)
      //   .then(response => {
      //     Callback();

      //     setSuccess(true);
      //     setError(false);
      //     setLoading(false);
      //     setTimeout(() => {
      //       setSuccess(false);
      //     }, 5000);
      //   })
      //   .catch(err => {
      //     setSuccess(false);
      //     setError(true);
      //     setLoading(false);

      //     setTimeout(() => {
      //       setError(false);
      //     }, 5000);
      //   });
    },
    [deletePartner]
  );

  // return { success, error, loading, remove };
  return { loading, remove };
}
