import React, { useContext, useState, useEffect } from "react";
import { useCountries, useDebounce } from "hooks";
import { useTextdomainContext } from "app/providers";
import {
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  InputAdornment,
  OutlinedInput,
  MenuItem
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGridComponent } from "app/components";
import AddProvider from "./components/add-provider";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDeleteProvider, useProvidersList } from "./hooks";
import EditProvider from "./components/edit-provider";
import Toast from "app/components/toast/Toast";

export function AdminServiceProviders() {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { del, deleting } = useDeleteProvider();
  const [deleteModal, setDeleteModal] = useState(false);
  const [delRowId, setDelRowId] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [currentProvider, setCurrentProvider] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    name: "",
    category: "",
    countryCode: ""
  });
  const [search, setSearch] = useState({
    name: "",
    category: "all",
    country: "all"
  });
  const debouncedSearch = useDebounce(search);
  const { providersList, loading } = useProvidersList(searchQuery);
  const { countries, loading: loadingCountries } = useCountries();

  useEffect(() => {
    if (!loadingCountries) {
      let srch = { ...debouncedSearch };
      if (srch.category === "all") srch.category = "";
      if (srch.country !== "all") {
        srch.countryCode = countries.filter(
          con => con.name === srch.country
        )[0]?.code;
      } else {
        srch.countryCode = "";
      }
      delete srch.country;
      setSearchQuery({ ...srch });
    }
  }, [countries, debouncedSearch, loadingCountries, setSearchQuery]);

  const columns = [
    {
      field: "name",
      headerName: gettext("Name"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => <Typography>{params.row.name}</Typography>
    },
    {
      field: "category",
      headerName: gettext("Category"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => (
        <Typography style={{ textTransform: "capitalize" }}>
          {params.row.category}
        </Typography>
      )
    },
    {
      field: "country",
      headerName: gettext("Country"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => (
        <Typography style={{ textTransform: "capitalize" }}>
          {countries.filter(con => params.row.countryCode === con.code)[0]
            ?.name || gettext("NA")}
        </Typography>
      )
    },
    {
      field: "url",
      headerName: gettext("Url"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => (
        <Typography>{params.row.url || gettext("NA")}</Typography>
      )
    },
    {
      field: "api",
      headerName: gettext("API"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => (
        <Typography>{params.row.api || gettext("NA")}</Typography>
      )
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => columnFormater(params)
    }
  ];

  function columnFormater(params) {
    return (
      <>
        <Tooltip title={gettext("Edit")} arrow>
          <IconButton
            onClick={() => {
              setEditRowId(params.row.Id);
              setCurrentProvider({
                name: params.row.name,
                category: params.row.category,
                countryCode: params.row.countryCode,
                url: params.row.url,
                api: params.row.api
              });
              setEditModal(true);
            }}
          >
            <FaUserEdit size={18} />
          </IconButton>
        </Tooltip>

        <Tooltip title={gettext("Delete")} arrow>
          <IconButton
            onClick={() => {
              setDeleteModal(true);
              setDelRowId(params.row.Id);
            }}
          >
            <MdDelete size={18} />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <AddProvider countries={countries} loadingCountries={loadingCountries} />
      <div className="table-div">
        <div className="table-title">
          <div className="col-7 p-0">
            <h3>{gettext("Service Providers")}</h3>
            <span className="font-size-sm w-100">
              {gettext("List of all service providers")}
            </span>
          </div>
        </div>
        <div className="card-body">
          <Stack
            direction={"row"}
            spacing={2}
            margin={2}
            marginLeft={0}
            marginTop={0}
          >
            <OutlinedInput
              type={"text"}
              size="small"
              name="name"
              placeholder="Search"
              value={search.name || ""}
              onChange={e =>
                setSearch({ ...search, [e.target.name]: e.target.value })
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            <TextField
              style={{ width: 225 }}
              select
              fullWidth
              variant="outlined"
              label="Category"
              name="category"
              value={search.category || "all"}
              onChange={e =>
                setSearch({ ...search, [e.target.name]: e.target.value })
              }
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"energy"}>Energy</MenuItem>
              <MenuItem value={"electricity"}>Electricity</MenuItem>
              <MenuItem value={"heating"}>Heatiing</MenuItem>
              <MenuItem value={"water"}>Water</MenuItem>
              <MenuItem value={"gas"}>Gas</MenuItem>
            </TextField>
            <TextField
              style={{ width: 225 }}
              select
              fullWidth
              variant="outlined"
              label="Country"
              name="country"
              value={search.country || "all"}
              onChange={e =>
                setSearch({ ...search, [e.target.name]: e.target.value })
              }
            >
              <MenuItem value={"all"}>All</MenuItem>
              {countries.map(country => (
                <MenuItem key={country.code} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <DataGridComponent
            rows={providersList}
            columns={columns}
            loading={loading}
            getRowId={row => row.Id}
            disableSelectionOnClick={true}
          />
        </div>
      </div>
      <Modal
        show={deleteModal}
        animation
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{gettext("Delete Provider")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {gettext("Are you sure do you want to delete this provider?")}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ maxWidth: "50px" }}
            variant="secondary"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {gettext("No")}
          </Button>
          {deleting ? (
            <Spinner animation="border" variant="dark" />
          ) : (
            <Button
              style={{ maxWidth: "50px" }}
              variant="danger"
              onClick={async () => {
                await del({ providerId: delRowId });
                setDeleteModal(false);
                const toastArray = [
                  {
                    title: gettext("Provider Deleted Successfully!"),
                    type: "success"
                  }
                ];
                setToastList(toastArray);
              }}
            >
              {gettext("Yes")}
            </Button>
          )}
        </Modal.Footer>
        {deleting ? (
          <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="light" />
          </div>
        ) : null}
      </Modal>
      <EditProvider
        countries={countries}
        loadingCountries={loadingCountries}
        currentProvider={currentProvider}
        providerId={editRowId}
        editModal={editModal}
        setEditModal={setEditModal}
      />
      <Toast toastList={toastList} />
    </>
  );
}
