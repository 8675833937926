import { format } from "date-fns";
import { getOr } from "lodash/fp";
import dateFormat from "dateformat";
import { transformResponse } from "../../../common/GTransformResponse";
// import { capitalizeFirstLetter } from "hooks/useCommon";
// import { gettext } from "modules/src/gettext";

// import { getTeamId } from "app/common/Functions";
function extractColorSettings(colorSettings, type, colorType, page) {
  let consumption = null;

  consumption =
    type === "energy"
      ? getOr("#485561", "energy.shade1.background", colorSettings)
      : type === "co2"
      ? getOr("#c0c7b5", "co2.shade1.background", colorSettings)
      : type === "electricity"
      ? getOr("#adb7c9", "electricity.shade1.background", colorSettings)
      : type === "heating"
      ? getOr("#dc562b", "heating.shade1.background", colorSettings)
      : type === "water"
      ? getOr("#004bff", "water.shade1.background", colorSettings)
      : type === "gas"
      ? getOr("#ffc442", "gas.shade1.background", colorSettings)
      : null;

  // consumption =
  //     type === "energy"
  //         ? getOr("#485561", "energy.shade1.background", colorSettings)
  //         : type === "co2"
  //         ? getOr("#c0c7b5", "co2.shade1.background", colorSettings)
  //         : type === "electricity"
  //         ? getOr("#adb7c9", "electricity.shade1.background", colorSettings)
  //         : type === "heating"
  //         ? getOr("#dc562b", "heating.shade1.background", colorSettings)
  //         : type === "water"
  //         ? getOr("#004bff", "water.shade1.background", colorSettings)
  //         : type === "gas"
  //         ? getOr("#ffc442", "gas.shade1.background", colorSettings)
  //         : null;

  return type !== "heating" && type !== "gas" && type !== "water"
    ? [
        consumption || "#426A2F",
        getOr("#030C24", "production.shade1.background", colorSettings),
        getOr("#05CD99", "compensation.shade1.background", colorSettings)
      ]
    : [
        consumption || "#426A2F",
        // getOr("#030C24", "production.shade1.background", colorSettings),
        getOr("#05CD99", "compensation.shade1.background", colorSettings)
      ];
}

export const getChartData = (
  options,
  colorSettings,
  type,
  colorType,
  page,
  charts
) => {
  return {
    unique_id: options.unique_id,
    type: "area",
    dataLabelsEnabled: false,
    yaxisTitleText: options.yaxisTitleText,
    xaxisTitleText: options.xaxisTitleText,
    colors: extractColorSettings(colorSettings, type, colorType, page),
    plotOptionsBarDistributed: false,
    chart_title: options.chart_title,
    categories: options.categories,
    data:
      page && charts === "comparisonKWHData"
        ? [
            {
              name: options.name,
              data: options.data
              // type: "column",
            },
            {
              name: options.prod_name,
              data: options.prod_data
              // type: "column",
            },
            {
              name: options.green_name,
              data: options.green_data
              // type: "column",
            }
          ]
        : type !== "heating" && type !== "gas" && type !== "water"
        ? [
            {
              name: options.name,
              data: options.data,
              type: "column"
            },
            {
              name: options.prod_name,
              data: options.prod_data,
              type: "column"
            },
            {
              name: options.green_name,
              data: options.green_data,
              type: "column"
            }
          ]
        : [
            {
              name: options.name,
              data: options.data,
              type: "column"
            },
            // {
            //   name: options.prod_name,
            //   data: options.prod_data,
            //   type: 'column',
            // },
            {
              name: options.green_name,
              data: options.green_data,
              type: "column"
            }
          ]
  };
};

export const getChartOptions = (
  unique_id,
  yaxisTitleText,
  chart_title,
  categories,
  data,
  name,
  prod_data,
  prod_name,
  green_data,
  green_name
) => {
  let options;

  options = {
    unique_id: unique_id,
    yaxisTitleText: yaxisTitleText,
    chart_title: chart_title,
    categories: categories,
    data: data,
    name: name,
    prod_data: prod_data,
    prod_name: prod_name,
    green_data,
    green_name
  };

  return options;
};

export const getOneYearOldDate = localFilters => {
  let oneYearOldDate = new Date();
  oneYearOldDate.setFullYear(oneYearOldDate.getFullYear() - 1);
  if (localFilters && localFilters.date) {
    return new Date(localFilters.date);
  }
  return oneYearOldDate;
};

export const getSelectedSegment = localFilters => {
  return localFilters ? parseInt(localFilters.selectedSegment) : 2;
};

export const getTransformEnergyCalculation = (
  res,
  predRes,
  type,
  selectedSegment,
  // unit,
  pageType,
  // date,
  startDate,
  endDate,
  fromDate,
  toDate,
  colorSettings,
  gettext,
  page
) => {
  let response;

  if (res !== undefined && predRes !== undefined) {
    response = transformResponse(
      res,
      predRes,
      type,
      selectedSegment,
      // unit,
      pageType,
      fromDate,
      toDate,
      gettext,
      page
    );
  }

  let segment =
    selectedSegment === 0 ? "day" : selectedSegment === 1 ? "month" : "year";
  // const resType =
  //   selectedSegment === 0 ? "h" : selectedSegment === 1 ? "d" : "m";
  let tableData = {};
  let allChartsData = {};

  let graphs = {};

  let graphUniqueId = {
    comparisonKWHData: "comparisonKWHData",
    chartData1: "chartData1",
    conervsionRate: "conervsionRate",
    chartData3: "chartData3",
    price: "price",
    Cost: "Cost"
  };

  let graphYaxisTitle = {
    comparisonKWHData: "KWH",
    chartData1: "KWH",
    conervsionRate: "CO₂/KWH",
    chartData3: "Tons CO₂",
    price: "DKK/KWH",
    Cost: "DKK"
  };
  let graphType = {
    comparisonKWHData: "comparison",
    chartData1: "cpc",
    conervsionRate: "conervsionRate",
    chartData3: "others",
    price: "price",
    Cost: "cost"
  };

  const { data } = res;
  if (data && data.length > 0) {
    if (response?.allTableData && Object.keys(response?.allTableData).length) {
      tableData = response?.allTableData;
      let segmentValue = getSegmentValue(selectedSegment, startDate);

      Object.keys(tableData).forEach((tableType, index) => {
        let tableTitle = getTableType(tableType, gettext, type, page);
        let title = "";
        if (type === "water") {
          // title = page?gettext("Predicted")
          title = `${gettext(`Water`) + " " + tableTitle} (${segmentValue})`;
          tableData[tableType].title = page
            ? gettext("Predicted") + " " + title
            : title;
        } else if (type === "heating") {
          tableData[tableType].title = tableTitle + " " + segmentValue;
        } else if (type === "electricity") {
          tableData[tableType].title = tableTitle;
        } else if (type === "energy") {
          tableData[tableType].title = tableTitle + " " + segmentValue;
        } else if (type === "gas") {
          tableData[tableType].title = tableTitle + " " + segmentValue;
        }
      });
    }

    if (response?.allChartData) {
      allChartsData = response?.allChartData;
      Object.keys(allChartsData).length &&
        Object.keys(allChartsData[segment]).length &&
        Object.keys(allChartsData[segment]).forEach((charts, index) => {
          // if (charts !== "comparisonKWHData")
          if (charts !== "comparisonKWHData") {
            graphs[charts] = getChartData(
              getChartOptions(
                graphUniqueId[charts],
                graphYaxisTitle[charts],
                getTranslation(graphType[charts], type, gettext, page),

                allChartsData[segment][charts]["compensation"] &&
                  Object.keys(allChartsData[segment][charts]["compensation"])
                    .sort()
                    .map(i => {
                      let str = i.slice(-8).substring(0, 5);
                      if (segment === "day") {
                        str = i
                          .slice(-5)
                          .substring(0, 5)
                          .replace(".", ":");
                      } else if (segment === "month") {
                        let tmpDate = new Date(i + "T00:00:00");
                        str = dateFormat(tmpDate, "dd-mm-yyyy");
                      } else if (segment === "year") {
                        let tmpDate = new Date(i + "T00:00:00");
                        str = dateFormat(tmpDate, "mmm-yyyy");
                      }
                      return segment === "year"
                        ? `${getMonthNames(str.split("-")[0], gettext)} - ${
                            str.split("-")[1]
                          }`
                        : str;
                    }),
                allChartsData[segment][charts]["consumption"] &&
                  Object.values(allChartsData[segment][charts]["consumption"]),
                gettext("Consumption"),
                allChartsData[segment][charts]["production"] &&
                  Object.values(allChartsData[segment][charts]["production"]),
                allChartsData[segment][charts]["production"] &&
                  gettext("Production"),
                Object.values(allChartsData[segment][charts]["compensation"]),
                gettext("Compensation")
              ),
              colorSettings,
              type,
              "",
              page,
              charts
            );
          } else {
            graphs[charts] = getChartData(
              getChartOptions(
                graphUniqueId[charts],
                graphYaxisTitle[charts],
                getTranslation(graphType[charts], type, gettext, page),
                allChartsData[segment][charts]["predictedKWHData"] &&
                  Object.keys(
                    allChartsData[segment][charts]["predictedKWHData"]
                  )
                    .sort()
                    .map(i => {
                      let str = i.slice(-8).substring(0, 5);
                      if (segment === "day") {
                        str = i
                          .slice(-5)
                          .substring(0, 5)
                          .replace(".", ":");
                      } else if (segment === "month") {
                        let tmpDate = new Date(i + "T00:00:00");
                        str = dateFormat(tmpDate, "dd-mm-yyyy");
                      } else if (segment === "year") {
                        let tmpDate = new Date(i + "T00:00:00");
                        str = dateFormat(tmpDate, "mmm-yyyy");
                      }
                      return segment === "year"
                        ? `${getMonthNames(str.split("-")[0], gettext)} - ${
                            str.split("-")[1]
                          }`
                        : str;
                    }),
                allChartsData[segment][charts]["orignalKWHData"] &&
                  Object.values(
                    allChartsData[segment][charts]["orignalKWHData"]
                  ),
                allChartsData[segment][charts]["orignalKWHData"] &&
                  gettext("Orignal"),

                allChartsData[segment][charts]["predictedKWHData"] &&
                  Object.values(
                    allChartsData[segment][charts]["predictedKWHData"]
                  ),
                allChartsData[segment][charts]["predictedKWHData"] &&
                  gettext("Predicted")
              ),
              colorSettings,
              type,
              "",
              page,
              charts
            );
          }
        });
    }
  }

  return {
    tableData,
    graphs
  };
};

let getMonthNames = (name, gettext) => {
  if (name === "Jan") {
    return gettext("Jan");
  } else if (name === "Feb") {
    return gettext("Feb");
  } else if (name === "Mar") {
    return gettext("Mar");
  } else if (name === "Apr") {
    return gettext("Apr");
  } else if (name === "May") {
    return gettext("May");
  } else if (name === "Jun") {
    return gettext("Jun");
  } else if (name === "Jul") {
    return gettext("Jul");
  } else if (name === "Aug") {
    return gettext("Aug");
  } else if (name === "Sep") {
    return gettext("Sep");
  } else if (name === "Oct") {
    return gettext("Oct");
  } else if (name === "Nov") {
    return gettext("Nov");
  } else if (name === "Dec") {
    return gettext("Dec");
  }
  return "";
};

export const getEnergyPageTransformCalculation = (
  energyList,
  selectedSegment,
  unit,
  pageType,
  date,
  gettext
) => {
  let tableData = { title: "", heading: [], content: [] };
  let chartData = {};
  let consumptionData = {};
  let productionData = [];
  let costData = {};
  let co2Data = {};
  let productionCostData = [];
  let productionCo2Data = [];
  let greenElectricity = [];
  let greenCo2 = [];
  let chartData1 = getChartData(
    getChartOptions("chartData1", "", "", [], [], "", [], "")
  );
  let chartData2 = getChartData(
    getChartOptions("chartData2", "", "", [], [], "", [], "")
  );
  let chartData3 = getChartData(
    getChartOptions("chartData3", "", "", [], [], "", [], "")
  );
  let segment =
    selectedSegment === 0 ? "day" : selectedSegment === 1 ? "month" : "year";
  const resType =
    selectedSegment === 0 ? "h" : selectedSegment === 1 ? "d" : "m";

  energyList.map((res, index) => {
    const { data } = res.data.data;

    const electricityGreen = res.data.data.electricity_green;

    let energyType =
      index === 0 ? "electricity" : index === 1 ? "heating" : "water";

    if (data && data.length > 0) {
      const customer = data[0];
      const resolution = customer.resolution;
      if (resType !== resolution) {
        return {
          tableData,
          chartData1,
          chartData2,
          chartData3
        };
      }
    }

    let response = transformResponse(
      res,
      res.type,
      selectedSegment,
      unit,
      pageType,
      gettext
    );

    if (response?.allTableData && response?.allTableData?.content?.length > 0) {
      const allTableData = response?.allTableData;
      let segmentValue = getSegmentValue(selectedSegment, date);
      tableData.title = `${gettext("Energy")} (${segmentValue})`;
      tableData.heading = [
        ...tableData.heading,
        ...allTableData.heading
      ].filter((x, i, a) => a.indexOf(x) === i);
      let allTableDataContent = allTableData.content;

      Object.keys(allTableDataContent).map((key, i) => {
        if (!tableData.content[key]) {
          if (energyType === "electricity")
            if (segment === "year") {
              tableData.content[key] = ["", "0", "0", "0", "0", "0", "0"];
            } else {
              tableData.content[key] = [
                "",
                "00:00",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0"
              ];
            }
          else if (segment === "year") {
            tableData.content[key] = ["", "0", "0", "0", "0"];
          } else {
            tableData.content[key] = ["", "00:00", "0", "0", "0", "0"];
          }
        }
        let tempContentArray = allTableDataContent[key];

        let oldContentArray = tableData.content[key];

        oldContentArray[0] = tempContentArray[0];

        if (energyType !== "electricity") {
          if (segment === "year") {
            oldContentArray[1] = additionOfValue(
              oldContentArray[1],
              tempContentArray[1],
              2
            );

            oldContentArray[3] = additionOfValue(
              oldContentArray[3],
              tempContentArray[2],
              3
            );
          } else {
            oldContentArray[1] = tempContentArray[1];

            oldContentArray[2] = additionOfValue(
              oldContentArray[2],
              tempContentArray[2],
              2
            );

            oldContentArray[6] = additionOfValue(
              oldContentArray[6],
              tempContentArray[5],
              5
            );
          }
          oldContentArray[4] = additionOfValue(
            oldContentArray[4],
            tempContentArray[3],
            segment === "year" ? 2 : 4
          );

          oldContentArray[5] = additionOfValue(
            oldContentArray[5],
            tempContentArray[4],
            segment === "year" ? 5 : 2
          );
        } else {
          if (segment === "year") {
            oldContentArray[1] = additionOfValue(
              oldContentArray[1],
              tempContentArray[1],
              2
            );
          } else {
            oldContentArray[1] = tempContentArray[1];

            oldContentArray[7] = additionOfValue(
              oldContentArray[7],
              tempContentArray[7],
              7
            );
          }

          oldContentArray[2] = additionOfValue(
            oldContentArray[2],
            tempContentArray[2],
            2
          );

          oldContentArray[3] = additionOfValue(
            oldContentArray[3],
            tempContentArray[3],
            2
          );

          oldContentArray[4] = additionOfValue(
            oldContentArray[4],
            tempContentArray[4],
            2
          );

          oldContentArray[5] = additionOfValue(
            oldContentArray[5],
            tempContentArray[5],
            segment === "year" ? 5 : 2
          );

          oldContentArray[6] = additionOfValue(
            oldContentArray[6],
            tempContentArray[6],
            6
          );
        }

        tableData.content[key] = oldContentArray;
        return true;
      });
    }

    if (response?.allChartData) {
      chartData = response?.allChartData;
      let segmentSelected = chartData[segment];

      segmentSelected &&
        Object.keys(segmentSelected?.consumption).map((key, i) => {
          if (electricityGreen && energyType === "electricity") {
            if (!greenElectricity[key]) {
              greenElectricity[key] = 0;
            }
            greenElectricity[key] =
              greenElectricity[key] + segmentSelected?.consumption[key];
          } else {
            if (!consumptionData[key]) {
              consumptionData[key] = 0;
            }
            consumptionData[key] =
              consumptionData[key] + segmentSelected?.consumption[key];
          }

          return true;
        });

      segmentSelected &&
        Object.keys(segmentSelected.production).map((key, i) => {
          if (!productionData[key]) {
            productionData[key] = 0;
          }
          productionData[key] =
            productionData[key] + segmentSelected.production[key];
          return true;
        });

      segmentSelected &&
        Object.keys(segmentSelected.cost).map((key, i) => {
          if (!costData[key]) {
            costData[key] = 0;
          }
          costData[key] = costData[key] + segmentSelected.cost[key];
          return true;
        });

      segmentSelected &&
        Object.keys(segmentSelected.productionCost).map((key, i) => {
          if (!productionCostData[key]) {
            productionCostData[key] = 0;
          }
          productionCostData[key] =
            productionCostData[key] + segmentSelected.productionCost[key];
          return true;
        });

      segmentSelected &&
        Object.keys(segmentSelected.co2).map((key, i) => {
          if (!co2Data[key]) {
            co2Data[key] = 0;
          }
          co2Data[key] = co2Data[key] + segmentSelected.co2[key];
          return true;
        });

      segmentSelected &&
        Object.keys(segmentSelected.productionCo2).map((key, i) => {
          if (!productionCo2Data[key]) {
            productionCo2Data[key] = 0;
          }
          productionCo2Data[key] =
            productionCo2Data[key] + segmentSelected.productionCo2[key];
          return true;
        });
    }

    return true;
  });

  chartData1 = getChartData(
    getChartOptions(
      "chartData1",
      "kWh",
      gettext(
        "Energy consumption, production, and compensation measured in kWh"
      ),
      Object.keys(consumptionData)
        .sort()
        .map(i => {
          let str = i.slice(-8).substring(0, 5);
          if (segment === "day") {
            str = i
              .slice(-5)
              .substring(0, 5)
              .replace(".", ":");
          } else if (segment === "month") {
            let tmpDate = new Date(i + "T00:00:00");
            str = dateFormat(tmpDate, "dd-mm-yyyy");
          } else if (segment === "year") {
            let tmpDate = new Date(i + "T00:00:00");
            str = dateFormat(tmpDate, "mmm-yyyy");
          }
          return segment === "year"
            ? `${getMonthNames(str.split("-")[0], gettext)} - ${
                str.split("-")[1]
              }`
            : str;
        }),
      Object.values(consumptionData),
      gettext("Consumption"),
      Object.values(productionData),
      gettext("Production"),
      Object.values(greenElectricity),
      gettext("Compensation (kWh)")
    ),
    "electricity"
  );

  chartData3 = getChartData(
    getChartOptions(
      "chartData3",
      gettext("Energy CO₂ measured in Tons"),
      gettext("Energy CO₂ measured in Tons"),
      Object.keys(co2Data)
        .sort()
        .map(i => {
          let str = i.slice(-8).substring(0, 5);
          if (segment === "day") {
            str = i
              .slice(-5)
              .substring(0, 5)
              .replace(".", ":");
          } else if (segment === "month") {
            let tmpDate = new Date(i + "T00:00:00");
            str = dateFormat(tmpDate, "dd-mm-yyyy");
          } else if (segment === "year") {
            let tmpDate = new Date(i + "T00:00:00");
            str = dateFormat(tmpDate, "mmm-yyyy");
          }
          return segment === "year"
            ? `${getMonthNames(str.split("-")[0], gettext)} - ${
                str.split("-")[1]
              }`
            : str;
        }),
      Object.values(co2Data),
      `${gettext("Consumption")} CO₂`,
      Object.values(productionCo2Data),
      `${gettext("Production")} CO₂`,
      Object.values(greenCo2),
      gettext("Compensation CO₂ (Tons)")
    ),
    "electricity"
  );

  return {
    tableData: getTotalValues(tableData, selectedSegment),
    chartData1,
    chartData2,
    chartData3
  };
};

export const getTotalValues = (data, selectedSegment) => {
  const segment = selectedSegment;
  let cons = 0;
  let emission = 0;
  let prodKwh = 0;
  let prodCO2 = 0;
  let green = 0;
  let greenCO2 = 0;
  let contentOption = [];
  const { content } = data;

  content.forEach(item => {
    cons = additionOfValue(cons, item[segment === 2 ? 1 : 2], 2);
    emission = additionOfValue(emission, item[segment === 2 ? 2 : 3], 6);
    prodKwh = additionOfValue(prodKwh, item[segment === 2 ? 3 : 4], 2);
    prodCO2 = additionOfValue(prodCO2, item[segment === 2 ? 4 : 5], 6);
    green = additionOfValue(green, item[segment === 2 ? 5 : 6], 6);
    greenCO2 = additionOfValue(greenCO2, item[segment === 2 ? 6 : 7], 7);

    contentOption.push([item[0], item[1], item[2], item[3], item[4]]);
  });

  contentOption.push(["Total", cons, emission, prodKwh, prodCO2]);

  segment === 2
    ? content.push(["Total", cons, emission, prodKwh, prodCO2, green, greenCO2])
    : content.push([
        "Total",
        "",
        cons,
        emission,
        prodKwh,
        prodCO2,
        green,
        greenCO2
      ]);

  data.content = content;

  return data;
};

export const additionOfValue = (oldTime, newTime, index) => {
  let value1 = parseFloat(oldTime);
  let value2 = parseFloat(newTime);
  return (value1 + value2).toFixed(index === 2 ? 2 : 2);
};

export const getSegmentValue = (selectedSegment, date) => {
  return selectedSegment === 0
    ? format(date, "dd-MM-yyyy")
    : selectedSegment === 1
    ? format(date, "MM-yyyy")
    : format(date, "yyyy");
};

export const getTableType = (tableType, gettext, type, page) => {
  if (tableType === "consumption" && type === "water") {
    return gettext("Water Consumption");
  } else if (tableType === "production" && type === "water") {
    return gettext("Water Production");
  } else if (tableType === "compensation" && type === "water") {
    return gettext("Water Compensation");
  } else if (tableType === "consumption" && type === "heating") {
    return gettext("District Heating Consumption");
  } else if (tableType === "production" && type === "heating") {
    return gettext("District Heating Production");
  } else if (tableType === "compensation" && type === "heating") {
    return gettext("District Heating Compensation");
  } else if (tableType === "consumption" && type === "electricity") {
    if (page) {
      return gettext("Predicted Electricity Consumption");
    } else {
      return gettext("Electricity Consumption");
    }
  } else if (tableType === "production" && type === "electricity") {
    if (page) {
      return gettext("Predicted Electricity Production");
    } else {
      return gettext("Electricity Production");
    }
  } else if (tableType === "compensation" && type === "electricity") {
    if (page) {
      return gettext("Predicted Electricity Compensation");
    } else {
      return gettext("Electricity Compensation");
    }
  } else if (tableType === "consumption" && type === "energy") {
    return gettext("Energy Consumption");
  } else if (tableType === "production" && type === "energy") {
    return gettext("Energy Production");
  } else if (tableType === "compensation" && type === "energy") {
    return gettext("Energy Compensation");
  } else if (tableType === "consumption" && type === "gas") {
    return gettext("Natural Gas Consumption");
  } else if (tableType === "production" && type === "gas") {
    return gettext("Natural Gas Production");
  } else if (tableType === "compensation" && type === "gas") {
    return gettext("Natural Gas Compensation");
  }
};

const getTranslation = (graphType, type, gettext, page) => {
  if (type === "energy") {
    return graphType === "cpc"
      ? gettext(
          "Energy consumption, production, and compensation measured in kWh"
        )
      : graphType === "conervsionRate"
      ? gettext(
          "Energy conversion rate for consumption, production, and compensation measured in g CO₂/kWh"
        )
      : graphType === "cost"
      ? gettext(
          "Energy cost  for consumption, production, and compensation measured in DKK"
        )
      : graphType === "price"
      ? gettext(
          "Energy price  for consumption, production, and compensation measured in DKK/kWh"
        )
      : gettext("Energy CO₂ measured in Tons");
  }
  // /else {
  // lse if (graphType === "cpc") {
  else if (type === "electricity") {
    let title = "";

    if (graphType === "comparison") {
      title = gettext(
        "Comparison of predicted and orignal electricity consumption   in kWh"
      );
      return title;
    } else if (graphType === "conervsionRate") {
      title = gettext(
        "Electricity conversion rate for consumption, production, and compensation measured in g CO₂/kWh"
      );
      return page ? gettext("Predicted") + " " + title : title;
    } else if (graphType === "cpc") {
      title = gettext(
        "Electricity consumption, production, and compensation measured in kWh"
      );
      return page ? gettext("Predicted") + " " + title : title;
    } else if (graphType === "cost") {
      title = gettext(
        "Electricity cost  for consumption, production, and compensation measured in DKK"
      );
      return page ? gettext("Predicted") + " " + title : title;
    } else if (graphType === "price") {
      title = gettext(
        "Electricity price  for consumption, production, and compensation measured in DKK/kWh"
      );
      return page ? gettext("Predicted") + " " + title : title;
    } else {
      title = gettext("Electricity CO₂ measured in Tons");
      return page ? gettext("Predicted") + " " + title : title;
    }
  } else if (type === "heating") {
    return graphType === "conervsionRate"
      ? gettext(
          "Conversion rate for consumption, and compensation measured in CO₂/kWh"
        )
      : graphType === "cpc"
      ? gettext("District heating consumption and compensation measured in kWh")
      : graphType === "cost"
      ? gettext(
          "District heating cost  for consumption, and compensation measured in DKK"
        )
      : graphType === "price"
      ? gettext(
          "District heating price  for consumption, and compensation measured in DKK/kWh"
        )
      : gettext("District heating CO₂ measured in Tons");
  } else if (type === "water") {
    return graphType === "conervsionRate"
      ? gettext(
          "Conversion rate for consumption, and compensation measured in CO₂/kWH"
        )
      : graphType === "cpc"
      ? gettext("Water consumption and compensation measured in kWh")
      : graphType === "cost"
      ? gettext(
          "Water cost  for consumption , and compensation measured in DKK"
        )
      : graphType === "price"
      ? gettext(
          "Water price  for consumption, and compensation measured in DKK/kWh"
        )
      : gettext("Water CO₂ measured in Tons");
  } else if (type === "gas") {
    return graphType === "conervsionRate"
      ? gettext(
          "Conversion rate for consumption, and compensation measured in CO₂/kWh"
        )
      : graphType === "cpc"
      ? gettext("Natural Gas consumption and compensation measured in kWh")
      : graphType === "cost"
      ? gettext(
          "Natural Gas cost  for consumption and compensation measured in DKK"
        )
      : graphType === "price"
      ? gettext(
          "Natural Gas price  for consumption, and compensation measured in DKK/kWh"
        )
      : gettext("Natural Gas CO₂ measured in Tons");
  } else if (type === "co2-in") {
    return gettext("CO₂ in Tons");
  }
  return "";
  // }

  // else {
  //     if (type === "electricity") {
  //         return gettext("Electricity CO₂ measured in Tons");
  //     } else if (type === "heating") {
  //         return gettext("District heating CO₂ measured in Tons");
  //     } else if (type === "water") {
  //         return gettext("Water CO₂ measured in Tons");
  //     } else if (type === "gas") {
  //         return gettext("Gas CO₂ measured in Tons");
  //     } else if (type === "co2-in") {
  //         return gettext("CO₂ in Tons");
  //     }
  //     return "";
  // }
};
