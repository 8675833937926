import React from "react";
import { useParams } from "react-router-dom";
import { PartnerMembersListTable, AddMemberForm } from "app/features";

export function PartnerMemberspage() {
  const { pkey } = useParams();

  return (
    <>
      <AddMemberForm partner={pkey} />

      <PartnerMembersListTable partner={pkey} />
    </>
  );
}
