import { gql } from "@apollo/client";

export const ADD_PUBLIC_DESCRIPTION = gql`
  mutation addAllTeamSettings($teamId: String!, $values: [TeamSettingsInput]) {
    addAllTeamSettings(teamId: $teamId, values: $values) {
      success
      message
    }
  }
`;
