import { Button, Typography } from "@mui/material";
import { useTextdomainContext } from "app/providers";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useContext } from "react";
import * as XLSX from "xlsx";

export function FileInputButton({
  refresh,
  setFile,
  selectedCustomer,
  selectedCustomersIds,
  setFileName,
  setFileData
}) {
  const inputRef = useRef(null);

  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [rowData, setRowData] = React.useState([]);
  const [letters, setLetters] = React.useState("");

  const resetFileInput = useCallback(() => {
    // inputRef.current.value = null;
  }, []);

  useEffect(() => {
    resetFileInput();
  }, [refresh, resetFileInput]);

  const handleFileSubmit = useCallback(
    e => {
      const file = e?.target?.files[0];

      let reader = new FileReader();

      reader.onload = function(e) {
        let data = e.target.result;

        let readedData = XLSX.read(data, { type: "binary" });

        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        const fileData = XLSX.utils.sheet_to_json(ws, { header: 1 });

        let rows = [];

        const letters = fileData[2][0]?.split(" ");

        fileData.forEach((r, index) => {
          if (index > 3 && !isNaN(parseInt(r[0])))
            rows = [
              ...rows,
              {
                accountNumber: r[0],
                accountName: r[1],
                period: r[2],
                yearBefore: r[3]
              }
            ];
        });

        setFile(file);
        setRowData(rows);
        setLetters(letters);

        setFileName(file?.name);

        // setFileData({
        //     fromDate: moment(letters[3], "DD.MM.YY").format(
        //         "YYYY-MM-DD"
        //     ),
        //     toDate: moment(letters[5], "DD.MM.YY").format("YYYY-MM-DD"),
        //     accountData: rows,
        //     customerId: selectedCustomersIds,
        //     // buildingName: selectedBuilding,
        //     selectedCustomers: selectedCustomer,
        // });
      };

      reader.readAsBinaryString(file);
    },
    [
      setFile,
      setFileName
      // setFileData,
      // selectedCustomersIds,
      // selectedBuilding,
      // selectedCustomer,
    ]
  );

  React.useEffect(() => {
    if (rowData.length && letters !== "")
      setFileData({
        fromDate: moment(letters[3], "DD.MM.YY").format("YYYY-MM-DD"),
        toDate: moment(letters[5], "DD.MM.YY").format("YYYY-MM-DD"),
        accountData: rowData,
        customerId: selectedCustomersIds,
        // buildingName: selectedBuilding,
        selectedCustomers: selectedCustomer
      });
  }, [selectedCustomersIds, selectedCustomer, setFileData, letters, rowData]);

  return (
    <>
      <Button
        variant="contained"
        size="large"
        component="label"
        disabled={selectedCustomer !== null ? false : true}
        sx={{
          borderRadius: "5px",
          textTransform: "none",
          display: "flex",
          flexDirection: "column",
          m: "auto",
          width: "fit-content"
        }}
      >
        <Typography fontSize={14}>{gettext("Upload File")}</Typography>
        <input
          hidden
          accept=".xlsx"
          ref={inputRef}
          type="file"
          onChange={handleFileSubmit}
        />
      </Button>
    </>
  );
}
