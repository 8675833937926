import React from "react";
import { MixedWidget2 } from "../../../widgets";
import { emptyBarChart, emptyPieChart } from "../../../common/Constants";
import { NewMixedWidget3 } from "app/widgets/mixed/NewMixedWidget3";

function Co2SectionCol1Row1(props) {
  return (
    <div className={"row"}>
      <div className={props.data2 ? "col-md-6" : "col-md-12"}>
        {props.data1 && (
          <NewMixedWidget3
            className="card-stretch "
            data={props.data1 ? props.data1 : emptyBarChart}
            imageUrl={uri => {
              props.imageUrl1(uri);
            }}
          />
        )}
      </div>
      {props.data2 && (
        <div className={"col-md-6"}>
          {props.data2 && (
            <MixedWidget2
              className="card-stretch "
              data={props.data2 ? props.data2 : emptyPieChart}
              imageUrl={uri => {
                props.imageUrl2(uri);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Co2SectionCol1Row1;
