const defaultOptions = {
  chart: {
    type: "bar",
    height: "100%",
    stacked: false,
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false
    }
  },
  colors: ["#4D81FF"],
  title: {
    text: "",
    align: "left",
    floating: false,
    style: {
      fontSize: "20px",
      fontWeight: 700,
      fontFamily: "Aeonik",
      color: "#27272A"
    }
  },
  legend: {
    show: false
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    hover: {
      size: 8,
      sizeOffset: 3
    }
  },
  xaxis: {
    type: "catagory",
    categories: [
      "0:00",
      "2:00",
      "4:00",
      "6:00",
      "8:00",
      "10:00",
      "12:00",
      "14:00",
      "16:00",
      "18:00",
      "20:00",
      "23:00"
    ],
    position: "bottom",
    floating: false,
    labels: {
      show: true,
      rotate: 0,
      hideOverlappingLabels: true,
      style: {
        colors: "#A1A1AA",
        fontSize: "12px",
        fontWeight: 400
      }
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  },
  yaxis: {
    floating: false,
    decimalsInFloat: 2,
    forceNiceScale: true,
    labels: {
      show: true,
      align: "left",
      offsetX: 0,
      style: {
        colors: "#A1A1AA",
        fontSize: "12px",
        fontWeight: 400
      }
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      show: false
    }
  },
  tooltip: {
    followCursor: true,
    intersect: false
    // y: {
    //   formatter: function (value) {
    //     return value.toFixed(2).toLocaleString();
    //   }
    // }
  },
  grid: {
    show: true,
    borderColor: "#D4D4D8",
    strokeDashArray: 6,
    position: "back",
    xaxis: {
      lines: {
        show: false
      }
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "50%",
      endingShape: "rounded",
      borderRadius: 3,
      borderRadiusApplication: "end"
    }
  }
};

export const hourlyConsumptionChart = (data, title, gettext) => {
  let chartOpts = { ...defaultOptions };
  chartOpts.title.text = title;
  let chartSeries = [];
  chartSeries.push({ name: gettext("Consumption Price"), data: [] });
  data?.forEach(dt => {
    if (parseInt(dt.ts.split(":")[0]) % 2 === 0 || dt.ts === "23:00") {
      chartSeries[0].data.push(`${dt?.price?.toFixed(2)}`);
    }
  });
  return {
    options: { series: chartSeries || [], ...chartOpts }
  };
};
