import { gql } from "@apollo/client";

export const GET_COMPANY_ADDRESSES = gql`
  query getCompanyAddresses($customerName: String!) {
    comGetCustomerDetails(customerName: $customerName) {
      country
      addresses {
        address
        country
      }
    }
  }
`;
