import { useSendLoginSlackMessage } from "app/modules/Auth/methods/sendLoginSlackMessage";
import React, { createContext, useCallback, useContext } from "react";
import { useCookies } from "react-cookie";

const AuthContext = createContext();

const AUTH_TOKEN_KEY = "userAuthtoken";

export function useAuth() {
  const { AuthToken, setToken, unsetToken } = useContext(AuthContext);

  return {
    isAuthenticated: Boolean(AuthToken),
    token: AuthToken,
    setToken,
    unsetToken
  };
}

export default function AuthProvider({ children }) {
  const [cookies, setCookie] = useCookies();

  const AuthToken = cookies[AUTH_TOKEN_KEY];

  const sendLoginMessage = useSendLoginSlackMessage();

  const setToken = useCallback(
    async token => {
      try {
        setCookie(AUTH_TOKEN_KEY, token, {
          path: "/"
        });

        sendLoginMessage(token);
      } catch (error) {}
    },
    [setCookie, sendLoginMessage]
  );

  const unsetToken = useCallback(async () => {
    try {
      setCookie(AUTH_TOKEN_KEY, "", {
        maxAge: -1,
        path: "/"
      });
      // graphqlClient.resetStore();
    } catch (error) {}
  }, [setCookie]);

  const value = {
    AuthToken,
    setToken,
    unsetToken
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
