import { useMutation } from "@apollo/client";
import { INSERT_ACCOUNTS_DATA } from "./commands";
import { useSnackbar } from "notistack";
import { useCallback, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import client from "services/apiClient";

export function useUpload(reset) {
  const { enqueueSnackbar } = useSnackbar();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [insertAccountData, { loading }] = useMutation(INSERT_ACCOUNTS_DATA);

  const upload = useCallback(
    async (file, fileData) => {
      const { REACT_APP_API_URL_GRAPHQL } = process.env;

      try {
        if (file && fileData?.accountData.length > 0) {
          const result = await insertAccountData({
            variables: fileData
          });

          const {
            data: {
              insertAccountData: { accountDataId }
            }
          } = result;

          const formData = new FormData();
          const query = `mutation uploadAccountDataCsv {
            uploadAccountDataCsv(
                id : "${accountDataId}"
              ) {
              message
              success
            }
          }`;
          formData.append("query", query);
          formData.append("file", file);

          await client.post(REACT_APP_API_URL_GRAPHQL, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          await reset();

          enqueueSnackbar(gettext("Account Data Successfully Uploaded"), {
            variant: "success"
          });
        } else
          enqueueSnackbar(gettext("No Data to Upload"), {
            variant: "warning"
          });
      } catch (error) {
        enqueueSnackbar(gettext("Error Uploading Account Data"), {
          variant: "error"
        });
      }
    },
    [insertAccountData, reset, enqueueSnackbar, gettext]
  );

  return {
    upload,
    uploading: loading
  };
}
