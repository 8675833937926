import { gql } from "@apollo/client";

export const GET_ACCOUNTS_DATA_LIST = gql`
  query accountsData {
    accountsData {
      Id
      createdAt
      fileName
    }
  }
`;
