import React, { useEffect, useState, useContext } from "react";
import dateFormat from "dateformat";
import { useMutation } from "@apollo/client";
import LoadingBar from "../../../components/LoadingBar";
import FilterComponent from "../components/Co2ReportFilterComponent";
import Co2Introduction from "../components/Co2Introduction";
import Co2EnergySection from "../NewCO2Report/Co2EnergySection";
// import Co2SourceSection from "../NewCO2Report/Co2SourceSection";
import // setCacheWithExpiry
// getCacheWithoutExpiry
"../../../common/Functions";
import {
  // useCO2Report,
  // useCustomerURL,
  useCO2EnergyReport
} from "../../../../hooks/useCO2Report";
import { ShowGreenElectricity } from "../../../components";
import Co2Section5 from "../components/Co2Section5";
import {
  co2PdfPage1,
  co2PdfPage2,
  co2PdfPage6,
  co2PdfPageData,
  co2PdfPagePieData
} from "../partials/Functions";
import { useUser, useTextdomainContext } from "app/providers";
import { GENERATE_PDF_REPORT } from "../../../../GraphQL/Mutations";
import { Box, Grid, Typography } from "@mui/material";
import PageIndicator from "app/pages/support/Datahub/components/PageIndicator";

const crumbs = [
  { label: "Buildings", value: 0 },
  { label: "CO₂ Report", value: "CO₂ Report" }
];

const _ = require("lodash");

const NewCo2Report = () => {
  const { user, colors } = useUser();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { teams } = user || {};
  const tempDate = new Date();
  tempDate.setFullYear(tempDate.getFullYear() - 1);

  const localFilters = JSON.parse(localStorage.getItem("newCo2Report"));
  const value =
    localFilters && localFilters.fromDate
      ? new Date(localFilters.fromDate)
      : tempDate;
  const value2 =
    localFilters && localFilters.toDate
      ? new Date(localFilters.toDate)
      : new Date();
  const [fromDate, setFromDate] = useState(value);
  const [toDate, setToDate] = useState(value2);

  const [selectedCustomer, changeSelectedCustomer] = useState("");
  const [selectedBuilding, changeSelectedBuilding] = useState("");
  const [showProgress, changeShowProgress] = useState(false);
  const [data, changeData] = useState([]);

  // const [page3MainTitle, setPage3MainTitle] = useState("");
  // const [page3MainDescription, setPage3MainDescription] = useState("");

  // const [page3Title, setPage3Title] = useState("");

  const [page4MainTitle, setPage4MainTitle] = useState("");
  const [page4MainDescription, setPage4MainTitleDescription] = useState("");
  const [page4Title, setPage4Title] = useState("");

  const [page5Title, setPage5Title] = useState("");
  const [page5MainTitle, setPage5MainTitle] = useState("");
  const [page5MainDescription, setPage5MainTitleDescription] = useState("");
  const [page5Title2, setPage5Title2] = useState("");

  const [page6MainTitle, setPage6MainTitle] = useState("");
  const [page6MainDescription, setPage6MainTitleDescription] = useState("");
  const [page6Title, setPage6Title] = useState("");
  const [page6Title2, setPage6Title2] = useState("");

  const [page7MainTitle, setPage7MainTitle] = useState("");
  const [page7MainDescription, setPage7MainTitleDescription] = useState("");
  const [page7Title, setPage7Title] = useState("");
  const [page7Title2, setPage7Title2] = useState("");

  // const [page8MainTitle, setPage8MainTitle] = useState("");
  // const [page8MainDescription, setPage8MainTitleDescription] = useState("");
  // const [page8Title, setPage8Title] = useState("");

  const [page9MainTitle, setPage9MainTitle] = useState("");
  const [page9MainDescription, setPage9MainTitleDescription] = useState("");
  const [page9Title, setPage9Title] = useState("");

  const [page10MainTitle, setPage10MainTitle] = useState("");
  const [page10MainDescription, setPage10MainTitleDescription] = useState("");
  const [page10Title, setPage10Title] = useState("");
  // const [page10Title2, setPage10Title2] = useState("");

  const [page11MainTitle, setPage11MainTitle] = useState("");
  const [page11MainDescription, setPage11MainTitleDescription] = useState("");
  const [page11Title, setPage11Title] = useState("");
  const [page11Title2, setPage11Title2] = useState("");

  // const [page12MainTitle, setPage12MainTitle] = useState("");
  // const [page12MainDescription, setPage12MainTitleDescription] = useState("");
  // const [page12Title, setPage12Title] = useState("");
  // const [page12Title2, setPage12Title2] = useState("");

  const [page13MainTitle, setPage13MainTitle] = useState("");
  const [page13MainDescription, setPage13MainTitleDescription] = useState("");
  const [page13Title, setPage13Title] = useState("");

  const [page14MainTitle, setPage14MainTitle] = useState("");
  const [page14MainDescription, setPage14MainTitleDescription] = useState("");
  const [page14Title, setPage14Title] = useState("");

  // const [page15MainTitle, setPage15MainTitle] = useState("");
  // const [page15MainDescription, setPage15MainTitleDescription] = useState("");
  // const [page15Title, setPage15Title] = useState("");
  // // const [page15Title2, setPage15Title2] = useState("");

  // const [page16MainTitle, setPage16MainTitle] = useState("");
  // const [page16MainDescription, setPage16MainTitleDescription] = useState("");
  // const [page16Title, setPage16Title] = useState("");
  // // const [page16Title2, setPage16Title2] = useState("");

  // const [page17MainTitle, setPage17MainTitle] = useState("");
  // const [page17MainDescription, setPage17MainTitleDescription] = useState("");
  // const [page17Title, setPage17Title] = useState("");
  // // const [page17Title2, setPage17Title2] = useState("");

  const [page18MainTitle, setPage18MainTitle] = useState("");
  const [page18MainDescription, setPage18MainTitleDescription] = useState("");
  const [page18Title, setPage18Title] = useState("");

  // const [page19MainTitle, setPage19MainTitle] = useState("");
  // const [page19MainDescription, setPage19MainTitleDescription] = useState("");
  // const [page19Title, setPage19Title] = useState("");

  // const [page20MainTitle, setPage20MainTitle] = useState("");
  // const [page20MainDescription, setPage20MainTitleDescription] = useState("");
  // const [page20Title, setPage20Title] = useState("");

  // const [page21MainTitle, setPage21MainTitle] = useState("");
  // const [page21MainDescription, setPage21MainTitleDescription] = useState("");
  // const [page21Title, setPage21Title] = useState("");

  // const [page22MainTitle, setPage22MainTitle] = useState("");
  // const [page22MainDescription, setPage22MainTitleDescription] = useState("");
  // const [page22Title, setPage22Title] = useState("");

  // const [page23MainTitle, setPage23MainTitle] = useState("");
  // const [page23MainDescription, setPage23MainTitleDescription] = useState("");
  // const [page23Title, setPage23Title] = useState("");

  // const [page24MainTitle, setPage24MainTitle] = useState("");
  // const [page24MainDescription, setPage24MainTitleDescription] = useState("");
  // const [page24Title, setPage24Title] = useState("");

  // const [page25MainTitle, setPage25MainTitle] = useState("");
  // const [page25MainDescription, setPage25MainTitleDescription] = useState("");
  // const [page25Title, setPage25Title] = useState("");

  // const [page26MainTitle, setPage26MainTitle] = useState("");
  // const [page26MainDescription, setPage26MainTitleDescription] = useState("");
  // const [page26Title, setPage26Title] = useState("");

  // const [page27MainTitle, setPage27MainTitle] = useState("");
  // const [page27MainDescription, setPage27MainTitleDescription] = useState("");
  // const [page27Title, setPage27Title] = useState("");

  // const [page28MainTitle, setPage28MainTitle] = useState("");
  // const [page28MainDescription, setPage28MainTitleDescription] = useState("");
  // const [page28Title, setPage28Title] = useState("");

  // const [page29MainTitle, setPage29MainTitle] = useState("");
  // const [page29MainDescription, setPage29MainTitleDescription] = useState("");
  // const [page29Title, setPage29Title] = useState("");

  // const [page30MainTitle, setPage30MainTitle] = useState("");
  // const [page30MainDescription, setPage30MainTitleDescription] = useState("");
  // const [page30Title, setPage30Title] = useState("");

  // const [page31MainTitle, setPage31MainTitle] = useState("");
  // const [page31MainDescription, setPage31MainTitleDescription] = useState("");
  // const [page31Title, setPage31Title] = useState("");

  // const [page32MainTitle, setPage32MainTitle] = useState("");
  // const [page32MainDescription, setPage32MainTitleDescription] = useState("");
  // const [page32Title, setPage32Title] = useState("");

  // const [page33MainTitle, setPage33MainTitle] = useState("");
  // const [page33MainDescription, setPage33MainTitleDescription] = useState("");
  // const [page33Title, setPage33Title] = useState("");

  // const [page34MainTitle, setPage34MainTitle] = useState("");
  // const [page34MainDescription, setPage34MainTitleDescription] = useState("");
  // const [page34Title, setPage34Title] = useState("");

  // const [page35MainTitle, setPage35MainTitle] = useState("");
  // const [page35MainDescription, setPage35MainTitleDescription] = useState("");
  // const [page35Title, setPage35Title] = useState("");
  // const [resources, setResources] = useState({});

  // const { co2Report: resources, isLoading } = useCO2Report({
  //   customer: selectedCustomer,
  //   addresses: selectedBuilding,
  //   from: dateFormat(toDate, "yyyy") - 3 + "-01-01",
  //   to: dateFormat(toDate, "yyyy") + "-01-01",
  //   resolution: "m"
  // });

  const getMonth = date => {
    const month = date.getMonth() + 1;
    const monthInStringForm = month < 10 ? `0${month}` : month;
    return monthInStringForm;
  };

  const lastDayOfMonth = function(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return new Date(year, month, 0).getDate();
  };

  const params = payload => {
    return {
      customers: selectedCustomer,
      addresses: selectedBuilding,
      user: user,
      fromDate:
        dateFormat(toDate, "yyyy") - 3 + `-${getMonth(fromDate)}-01T00:00:00`,
      toDate:
        dateFormat(toDate, "yyyy") +
        `-${getMonth(toDate)}-${lastDayOfMonth(toDate)}T00:00:00`,
      fromYear: dateFormat(toDate, "yyyy") - 3,
      secondYear: dateFormat(toDate, "yyyy") - 2,
      thirdYear: dateFormat(toDate, "yyyy") - 1,
      toYear: dateFormat(toDate, "yyyy"),
      resolution: "m",
      sourceType: payload,
      dateFrom: fromDate,
      dateTo: toDate
    };
  };

  const { co2Report: energyData, loading: energyLoading } = useCO2EnergyReport(
    params("energy")
  );
  const {
    co2Report: electricityData,
    loading: electricityLoading
  } = useCO2EnergyReport(params("electricity"));
  const {
    co2Report: heatingData,
    loading: heatingLoading
  } = useCO2EnergyReport(params("heating"));
  const { co2Report: waterData, loading: waterLoading } = useCO2EnergyReport(
    params("water")
  );
  const { co2Report: gasData, loading: gasLoading } = useCO2EnergyReport(
    params("gas")
  );
  useEffect(() => {
    if (electricityLoading || heatingLoading || waterLoading || gasLoading) {
      return;
    }
    if (
      electricityData !== null &&
      heatingData !== null &&
      waterData !== null &&
      gasData !== null
    ) {
      let customResource = {};
      let customerNames = "";
      let customerNamesArray = [];
      let customersArray = selectedCustomer.split(","); //.map(Number);

      // Need to discuss this part
      teams.map(customer => {
        if (
          customersArray.find(id => {
            return id === customer.name;
          })
        ) {
          customerNamesArray.push(customer.name);
          return false;
        }
        return false;
      });

      // This statement is logically useless
      // The return condition will always return `true` no matter whatever
      // values are in the `customerNamesArray`
      // customerNamesArray = customerNamesArray.filter(
      //   (v, i, a) => a.indexOf(v) === i
      // );

      customerNames = customerNamesArray.join(", ");
      customResource.customers = selectedCustomer;
      customResource.customers_names = customerNames;
      customResource.buildings = selectedBuilding;
      customResource.user_id = user.id;
      customResource.team_id = user.currentTeamId;
      customResource.from_year = dateFormat(toDate, "yyyy") - 3;
      customResource.second_year = dateFormat(toDate, "yyyy") - 2;
      customResource.to_year = dateFormat(toDate, "yyyy");
      customResource.heating = heatingData;
      customResource.gas = gasData;
      customResource.electricity = electricityData;
      customResource.water = waterData;
      customResource.electricityGreen = electricityData.electricityGreen;
      customResource.month = toDate.getMonth();
      changeShowProgress(false);
      changeData(customResource);
    }
  }, [
    electricityData,
    heatingData,
    waterData,
    gasData,
    gasLoading,
    electricityLoading,
    heatingLoading,
    waterLoading,
    teams,
    selectedCustomer,
    selectedBuilding,
    user,
    fromDate,
    toDate
  ]);

  let handleChangeCustomer = selectedValue => {
    changeSelectedCustomer(selectedValue);
  };

  let handleChangeBuilding = selectedValue => {
    changeSelectedBuilding(selectedValue);
  };

  const handleFromDate = date => {
    let cloneDate = _.cloneDeep(date);
    let nextYear = new Date(cloneDate);
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    setFromDate(date);
    setToDate(nextYear);
  };

  const handleToDate = date => {
    let cloneDate = _.cloneDeep(date);
    let previousYear = new Date(cloneDate);
    previousYear.setFullYear(previousYear.getFullYear() - 1);
    setFromDate(previousYear);
    setToDate(date);
  };

  const [getPdfReport, { loading: pdfLoading }] = useMutation(
    GENERATE_PDF_REPORT,
    {
      onCompleted(_data) {
        const {
          generatePdfReport: { filePath }
        } = _data;
        window.open(filePath);
        // window.location.replace(filePath);
      },
      onError(error) {
        // error.graphQLErrors.map(({ message }) => {
        //   // console.log("Error =>", error);
        // });
      }
    }
  );

  useEffect(() => {
    localStorage.setItem(
      "newCo2Report",
      JSON.stringify({
        fromDate: fromDate,
        toDate: toDate
      })
    );
  }, [fromDate, toDate]);

  const getPdfJsonData = async () => {
    // getCacheWithoutExpiry('page30Uri').then(result => {
    //   console.log('page30Uri =>', result);
    // });

    const page1 = co2PdfPage1(data, gettext);
    const page2 = await co2PdfPage2(data, energyData, gettext);
    const lastPage = co2PdfPage6(data, gettext);
    let jsonData2 = {
      page1: page1,
      page2: page2,
      page3: await co2PdfPageData(
        "page3Uri",
        page4Title,
        "",
        page4MainTitle,
        page4MainDescription
      ),

      // page4: await co2PdfPageData(
      //     "page4Uri",
      //     page4Title,
      //     "",
      //     page4MainTitle,
      //     page4MainDescription
      // ),
      page5: await co2PdfPagePieData(
        "page11Uri",
        "page11Uri2",
        page11Title,
        page11Title2,
        "",
        page11MainTitle,
        page11MainDescription
      ),

      // page4: await co2PdfPagePieData(
      //   "page5Uri",
      //   "page5Uri2",
      //   page5Title,
      //   page5Title2,
      //   "",
      //   page5MainTitle,
      //   page5MainDescription
      // ),
      page6: await co2PdfPagePieData(
        "page6Uri",
        "page6Uri2",
        page6Title,
        page6Title2,
        "",
        page6MainTitle,
        page6MainDescription
      ),
      page7: await co2PdfPagePieData(
        "page7Uri",
        "page7Uri2",
        page7Title,
        page7Title2,
        "",
        page7MainTitle,
        page7MainDescription
      ),
      // page8: await co2PdfPageData(
      //   "page8Uri",
      //   page8Title,
      //   "",
      //   page8MainTitle,
      //   page8MainDescription
      // ),

      page9: await co2PdfPageData(
        "page9Uri",
        page9Title,
        "",
        page9MainTitle,
        page9MainDescription
      ),
      // page10: await co2PdfPageData(
      //     "page10Uri",
      //     // "page10Uri2",
      //     page10Title,
      //     // page10Title2,
      //     "",
      //     page10MainTitle,
      //     page10MainDescription
      // ),
      // page11: await co2PdfPageData(
      //   "page11Uri",
      //   // "page11Uri2",
      //   page11Title,
      //   // page11Title2,
      //   "",
      //   page11MainTitle,
      //   page11MainDescription
      // ),
      // page12: await co2PdfPageData(
      //   "page12Uri",
      //   // "page12Uri2",
      //   page12Title,
      //   // page12Title2,
      //   "",
      //   page12MainTitle,
      //   page12MainDescription
      // ),
      page13: await co2PdfPageData(
        "page13Uri",
        page13Title,
        "",
        page13MainTitle,
        page13MainDescription
      ),
      page14: await co2PdfPageData(
        "page14Uri",
        page14Title,
        "",
        page14MainTitle,
        page14MainDescription
      ),
      // page15: await co2PdfPageData(
      //   "page15Uri",
      //   // "page15Uri2",
      //   page15Title,
      //   // page15Title2,
      //   "",
      //   page15MainTitle,
      //   page15MainDescription
      // ),
      // page16: await co2PdfPageData(
      //   "page16Uri",
      //   // "page16Uri2",
      //   page16Title,
      //   // page16Title2,
      //   "",
      //   page16MainTitle,
      //   page16MainDescription
      // ),
      // page17: await co2PdfPageData(
      //   "page17Uri",
      //   // "page17Uri2",
      //   page17Title,
      //   // page17Title2,
      //   "",
      //   page17MainTitle,
      //   page17MainDescription
      // ),
      page18: await co2PdfPageData(
        "page18Uri",
        page18Title,
        "",
        page18MainTitle,
        page18MainDescription
      ),
      // page19: await co2PdfPageData(
      //   "page19Uri",
      //   page19Title,
      //   "",
      //   page19MainTitle,
      //   page19MainDescription
      // ),
      // page20: await co2PdfPageData(
      //   "page20Uri",
      //   page20Title,
      //   "",
      //   page20MainTitle,
      //   page20MainDescription
      // ),
      // page21: await co2PdfPageData(
      //   "page21Uri",
      //   page21Title,
      //   "",
      //   page21MainTitle,
      //   page21MainDescription
      // ),
      // page22: await co2PdfPageData(
      //   "page22Uri",
      //   page22Title,
      //   "",
      //   page22MainTitle,
      //   page22MainDescription
      // ),
      // page23: await co2PdfPageData(
      //   "page23Uri",
      //   page23Title,
      //   "",
      //   page23MainTitle,
      //   page23MainDescription
      // ),
      // page24: await co2PdfPageData(
      //   "page24Uri",
      //   page24Title,
      //   "",
      //   page24MainTitle,
      //   page24MainDescription
      // ),
      // page25: await co2PdfPageData(
      //   "page25Uri",
      //   page25Title,
      //   "",
      //   page25MainTitle,
      //   page25MainDescription
      // ),
      // page26: await co2PdfPageData(
      //   "page26Uri",
      //   page26Title,
      //   "",
      //   page26MainTitle,
      //   page26MainDescription
      // ),
      // page27: await co2PdfPageData(
      //   "page27Uri",
      //   page27Title,
      //   "",
      //   page27MainTitle,
      //   page27MainDescription
      // ),
      // page28: await co2PdfPageData(
      //   "page28Uri",
      //   page28Title,
      //   "",
      //   page28MainTitle,
      //   page28MainDescription
      // ),
      // page29: await co2PdfPageData(
      //   "page29Uri",
      //   page29Title,
      //   "",
      //   page29MainTitle,
      //   page29MainDescription
      // ),
      // page30: await co2PdfPageData(
      //   "page30Uri",
      //   page30Title,
      //   "",
      //   page30MainTitle,
      //   page30MainDescription
      // ),
      // page31: await co2PdfPageData(
      //   "page31Uri",
      //   page31Title,
      //   "",
      //   page31MainTitle,
      //   page31MainDescription
      // ),
      // page32: await co2PdfPageData(
      //   "page32Uri",
      //   page32Title,
      //   "",
      //   page32MainTitle,
      //   page32MainDescription
      // ),
      // page33: await co2PdfPageData(
      //   "page33Uri",
      //   page33Title,
      //   "",
      //   page33MainTitle,
      //   page33MainDescription
      // ),
      // page34: await co2PdfPageData(
      //   "page34Uri",
      //   page34Title,
      //   "",
      //   page34MainTitle,
      //   page34MainDescription
      // ),
      // page35: await co2PdfPageData(
      //   "page35Uri",
      //   page35Title,
      //   "",
      //   page35MainTitle,
      //   page35MainDescription
      // ),
      page36: lastPage,
      total: 36
    };

    getPdfReport({
      variables: {
        pdfJsonSettngs: JSON.stringify(jsonData2)
      }
    });
  };

  return (
    <Grid container display="flex" flexDirection="column" rowGap={2}>
      <Grid item>
        <PageIndicator view={"CO₂ Report"} gettext={gettext} crumbs={crumbs} />
      </Grid>
      <Grid item spacing={2}>
        <LoadingBar show={showProgress} />
        <FilterComponent
          onChangeCustomer={handleChangeCustomer}
          onChangeBuilding={handleChangeBuilding}
          setFromDate={handleFromDate}
          setToDate={handleToDate}
          fromDate={fromDate}
          toDate={toDate}
        />
        {data?.electricityGreen && (
          <ShowGreenElectricity greenStatus={data?.electricityGreen} />
        )}
      </Grid>

      {energyData ? (
        <Grid
          item
          sx={{
            p: 3,
            backgroundColor: "secondary.50",
            border: "1px solid",
            // minHeight: "52px",
            borderColor: "secondary.200",
            borderRadius: "8px"
          }}
          spacing={2}
        >
          <Co2Introduction
            data={data}
            energyData={energyData}
            energyLoading={energyLoading}
            params={params("energy")}
            showProgress={showProgress}
            isLoading={energyLoading}
            dateFrom={fromDate}
            dateTo={toDate}
            pdfLoading={pdfLoading}
            getPdfJsonData={() => getPdfJsonData()}
          />
          <Co2EnergySection
            params={params("energy")}
            // getMainTitle={getMainTitle}
            // getMainDescription={getMainDescription}
            data={data}
            energyData={energyData}
            electricityData={electricityData}
            gasData={gasData}
            heatingData={heatingData}
            waterData={waterData}
            energyLoading={energyLoading}
            electricityLoading={electricityLoading}
            gasLoading={gasLoading}
            heatingLoading={heatingLoading}
            waterLoading={waterLoading}
            sourceType="energy"
            consumptionType="ENERGY_CONSUMPTION"
            productionType="ENERGY_PRODUCTION"
            compensationType="ENERGY_COMPENSATION"
            dateFrom={fromDate}
            dateTo={toDate}
            colors={colors}
            // setPage3Title={setPage3Title}
            setPage4Title={setPage4Title}
            setPage4MainTitle={setPage4MainTitle}
            setPage4MainTitleDescription={setPage4MainTitleDescription}
            setPage5MainTitle={setPage5MainTitle}
            setPage5MainTitleDescription={setPage5MainTitleDescription}
            page5Title={page5Title}
            page5MainTitle={page5MainTitle}
            page5MainDescription={page5MainDescription}
            page10Title={page10Title}
            page5Title2={page5Title2}
            page10MainTitle={page10MainTitle}
            page10MainDescription={page10MainDescription}
            setPage5Title={setPage5Title}
            setPage5Title2={setPage5Title2}
            setPage6MainTitle={setPage6MainTitle}
            setPage6MainTitleDescription={setPage6MainTitleDescription}
            setPage6Title={setPage6Title}
            setPage6Title2={setPage6Title2}
            setPage7MainTitle={setPage7MainTitle}
            setPage7MainTitleDescription={setPage7MainTitleDescription}
            setPage7Title={setPage7Title}
            setPage7Title2={setPage7Title2}
            // setPage8MainTitle={setPage8MainTitle}
            // setPage8MainTitleDescription={
            //     setPage8MainTitleDescription
            // }
            // setPage8Title={setPage8Title}
            setPage9MainTitle={setPage9MainTitle}
            setPage9MainTitleDescription={setPage9MainTitleDescription}
            setPage9Title={setPage9Title}
            setPage10MainTitle={setPage10MainTitle}
            setPage10MainTitleDescription={setPage10MainTitleDescription}
            setPage10Title={setPage10Title}
            // setPage10Title2={setPage10Title2}
            setPage11MainTitle={setPage11MainTitle}
            setPage11MainTitleDescription={setPage11MainTitleDescription}
            setPage11Title={setPage11Title}
            setPage11Title2={setPage11Title2}
            // setPage12MainTitle={setPage12MainTitle}
            // setPage12MainTitleDescription={
            //     setPage12MainTitleDescription
            // }
            // setPage12Title={setPage12Title}
            // setPage12Title2={setPage12Title2}
            setPage13MainTitle={setPage13MainTitle}
            setPage13MainTitleDescription={setPage13MainTitleDescription}
            setPage13Title={setPage13Title}
            setPage14MainTitle={setPage14MainTitle}
            setPage14MainTitleDescription={setPage14MainTitleDescription}
            setPage14Title={setPage14Title}
            setPage18MainTitle={setPage18MainTitle}
            setPage18MainTitleDescription={setPage18MainTitleDescription}
            setPage18Title={setPage18Title}
            // setPage15MainTitle={setPage15MainTitle}
            // setPage15MainTitleDescription={setPage15MainTitleDescription}
            // setPage15Title={setPage15Title}
            // // setPage15Title2={setPage15Title2}
            // setPage16MainTitle={setPage16MainTitle}
            // setPage16MainTitleDescription={setPage16MainTitleDescription}
            // setPage16Title={setPage16Title}
            // // setPage16Title2={setPage16Title2}
            // setPage17MainTitle={setPage17MainTitle}
            // setPage17MainTitleDescription={setPage17MainTitleDescription}
            // setPage17Title={setPage17Title}
            // setPage17Title2={setPage17Title2}
          />
          {/* <Co2SourceSection
        params={params("electricity")}
        data={data}
        energyData={energyData}
        electricityData={electricityData}
        sourceData={electricityData}
        heatingData={heatingData}
        waterData={waterData}
        energyLoading={energyLoading}
        electricityLoading={electricityLoading}
        heatingLoading={heatingLoading}
        waterLoading={waterLoading}
        picColor="co2"
        sourceType="electricity"
        consumptionType="ELECTRICITY_CONSUMPTION"
        productionType="ELECTRICITY_PRODUCTION"
        compensationType="ELECTRICITY_COMPENSATION"
        dateFrom={fromDate}
        dateTo={toDate}
        colors={colors}
        setPageUri1={uri => {
          setCacheWithExpiry("page18Uri", uri);
        }}
        setPageUri2={uri => {
          setCacheWithExpiry("page19Uri", uri);
        }}
        setPageUri3={uri => {
          setCacheWithExpiry("page20Uri", uri);
        }}
        setPageUri4={uri => {
          setCacheWithExpiry("page21Uri", uri);
        }}
        setPageUri5={uri => {
          setCacheWithExpiry("page22Uri", uri);
        }}
        setPageUri6={uri => {
          setCacheWithExpiry("page23Uri", uri);
        }}
        setPageMainTitle1={setPage18MainTitle}
        setPageMainTitleDescription1={setPage18MainTitleDescription}
        setPageTitle1={setPage18Title}
        setPageMainTitle2={setPage19MainTitle}
        setPageMainTitleDescription2={setPage19MainTitleDescription}
        setPageTitle2={setPage19Title}
        setPageMainTitle3={setPage20MainTitle}
        setPageMainTitleDescription3={setPage20MainTitleDescription}
        setPageTitle3={setPage20Title}
        setPageMainTitle4={setPage21MainTitle}
        setPageMainTitleDescription4={setPage21MainTitleDescription}
        setPageTitle4={setPage21Title}
        setPageMainTitle5={setPage22MainTitle}
        setPageMainTitleDescription5={setPage22MainTitleDescription}
        setPageTitle5={setPage22Title}
        setPageMainTitle6={setPage23MainTitle}
        setPageMainTitleDescription6={setPage23MainTitleDescription}
        setPageTitle6={setPage23Title}
      />

      <Co2SourceSection
        params={params("heating")}
        data={data}
        energyData={energyData}
        electricityData={electricityData}
        heatingData={heatingData}
        sourceData={heatingData}
        waterData={waterData}
        energyLoading={energyLoading}
        electricityLoading={electricityLoading}
        heatingLoading={heatingLoading}
        waterLoading={waterLoading}
        sourceType="heating"
        picColor="co2"
        consumptionType="HEATING_CONSUMPTION"
        productionType="HEATING_PRODUCTION"
        compensationType="HEATING_COMPENSATION"
        dateFrom={fromDate}
        dateTo={toDate}
        colors={colors}
        setPageUri1={uri => {
          setCacheWithExpiry("page24Uri", uri);
        }}
        setPageUri2={uri => {
          setCacheWithExpiry("page25Uri", uri);
        }}
        setPageUri3={uri => {
          setCacheWithExpiry("page26Uri", uri);
        }}
        setPageUri4={uri => {
          setCacheWithExpiry("page27Uri", uri);
        }}
        setPageUri5={uri => {
          setCacheWithExpiry("page28Uri", uri);
        }}
        setPageUri6={uri => {
          setCacheWithExpiry("page29Uri", uri);
        }}
        setPageMainTitle1={setPage24MainTitle}
        setPageMainTitleDescription1={setPage24MainTitleDescription}
        setPageTitle1={setPage24Title}
        setPageMainTitle2={setPage25MainTitle}
        setPageMainTitleDescription2={setPage25MainTitleDescription}
        setPageTitle2={setPage25Title}
        setPageMainTitle3={setPage26MainTitle}
        setPageMainTitleDescription3={setPage26MainTitleDescription}
        setPageTitle3={setPage26Title}
        setPageMainTitle4={setPage27MainTitle}
        setPageMainTitleDescription4={setPage27MainTitleDescription}
        setPageTitle4={setPage27Title}
        setPageMainTitle5={setPage28MainTitle}
        setPageMainTitleDescription5={setPage28MainTitleDescription}
        setPageTitle5={setPage28Title}
        setPageMainTitle6={setPage29MainTitle}
        setPageMainTitleDescription6={setPage29MainTitleDescription}
        setPageTitle6={setPage29Title}
      />
      <Co2SourceSection
        params={params("water")}
        data={data}
        energyData={energyData}
        waterData={waterData}
        sourceData={waterData}
        loading={waterLoading}
        sourceType="water"
        picColor="co2"
        consumptionType="WATER_CONSUMPTION"
        productionType="WATER_PRODUCTION"
        compensationType="WATER_COMPENSATION"
        dateFrom={fromDate}
        dateTo={toDate}
        colors={colors}
        setPageUri1={uri => {
          setCacheWithExpiry("page30Uri", uri);
        }}
        setPageUri2={uri => {
          setCacheWithExpiry("page31Uri", uri);
        }}
        setPageUri3={uri => {
          setCacheWithExpiry("page32Uri", uri);
        }}
        setPageUri4={uri => {
          setCacheWithExpiry("page33Uri", uri);
        }}
        setPageUri5={uri => {
          setCacheWithExpiry("page34Uri", uri);
        }}
        setPageUri6={uri => {
          setCacheWithExpiry("page35Uri", uri);
        }}
        setPageMainTitle1={setPage30MainTitle}
        setPageMainTitleDescription1={setPage30MainTitleDescription}
        setPageTitle1={setPage30Title}
        setPageMainTitle2={setPage31MainTitle}
        setPageMainTitleDescription2={setPage31MainTitleDescription}
        setPageTitle2={setPage31Title}
        setPageMainTitle3={setPage32MainTitle}
        setPageMainTitleDescription3={setPage32MainTitleDescription}
        setPageTitle3={setPage32Title}
        setPageMainTitle4={setPage33MainTitle}
        setPageMainTitleDescription4={setPage33MainTitleDescription}
        setPageTitle4={setPage33Title}
        setPageMainTitle5={setPage34MainTitle}
        setPageMainTitleDescription5={setPage34MainTitleDescription}
        setPageTitle5={setPage34Title}
        setPageMainTitle6={setPage35MainTitle}
        setPageMainTitleDescription6={setPage35MainTitleDescription}
        setPageTitle6={setPage35Title}
      /> */}
          {/* <Co2SourceSection
        params={params("gas")}
        data={data}
        energyData={energyData}
        electricityData={electricityData}
        heatingData={heatingData}
        waterData={waterData}
        energyLoading={energyLoading}
        electricityLoading={electricityLoading}
        heatingLoading={heatingLoading}
        waterLoading={waterLoading}
        sourceType="gas"
        consumptionType="GAS_CONSUMPTION"
        productionType="GAS_PRODUCTION"
        compensationType="GAS_COMPENSATION"
        dateFrom={fromDate}
        dateTo={toDate}
        setPageUri1={setPage18Uri}
        setPageTitle1={setPage18Title}
        setPageUri2={setPage19Uri}
        setPageTitle2={setPage19Title}
        setPageUri3={setPage20Uri}
        setPageTitle3={setPage20Title}
        setPageUri4={setPage21Uri}
        setPageTitle4={setPage21Title}
        setPageUri5={setPage22Uri}
        setPageTitle5={setPage22Title}
        setPageUri6={setPage23Uri}
        setPageTitle6={setPage23Title}
      /> */}
          <Co2Section5 isLoading={energyLoading} />
        </Grid>
      ) : (
        <>
          <Box component="div" whiteSpace="normal" textAlign="center">
            <img
              src="/media/NoGraphData.png"
              alt="No Data Found"
              width="150"
              height="40"
              className="pt-5"
            />

            <Typography
              variant="body1"
              color="primary"
              sx={{
                mt: 1,
                fontSize: 24,
                fontWeight: 500,
                lineheight: 24
              }}
              textOverflow="hidden"
            >
              {gettext("No data found for selected date")}
            </Typography>
            <Typography
              variant="caption"
              color="gray"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                lineheight: 18
              }}
            >
              {gettext("Please select another date.")}
            </Typography>
          </Box>
        </>
      )}
    </Grid>
  );
};

export default NewCo2Report;
