import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { usePagePrivileges } from "hooks/privileges";
import { isLocationActive } from "app/common/Methods";
import { alpha } from "@mui/material";
import { useUser } from "app/providers";

export default function Level2MenuItem({ link, label, code }) {
  const location = useLocation();

  const { user } = useUser();
  const isSuperAdmin = Boolean(user?.isSuperAdmin);

  const [_read, _write] = usePagePrivileges(code);

  const [displayOption, setDisplayOption] = useState(false);

  const [activeColor, setactiveColor] = useState("");
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    setisActive(isLocationActive(location, link));
  }, [location, link]);

  useEffect(() => {
    setactiveColor(isActive ? "white" : "primary.200");
  }, [isActive]);

  useEffect(() => {
    if (isSuperAdmin) {
      setDisplayOption(true);
    } else {
      if (_read || _write) {
        setDisplayOption(true);
      } else if (code === undefined) {
        setDisplayOption(true);
      } else {
        setDisplayOption(false);
      }
    }
  }, [_read, _write, code, isSuperAdmin]);

  return (
    <>
      {displayOption && (
        <NavLink to={link}>
          <ListItem sx={{ pl: 1.5, pr: 2, py: 0, color: activeColor }}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                marginX: 1,
                borderWidth: "1px",
                borderColor: isActive ? "white" : "primary.900"
              }}
            />
            <ListItemButton
              sx={{
                borderRadius: "8px",
                backgroundColor: isActive
                  ? alpha("#FFFFFF", 0.28)
                  : "transparent"
              }}
            >
              <Typography variant="h6" sx={{ color: activeColor }}>
                {label}
              </Typography>
            </ListItemButton>
          </ListItem>
        </NavLink>
      )}
    </>
  );
}
