import { gql } from "@apollo/client";

export const DELETE_METER = gql`
  mutation deleteMeter(
    $buildingId: String!
    $meterNo: String!
    $meterId: String!
    $type: String!
  ) {
    deleteMeter(
      buildingId: $buildingId
      meterNo: $meterNo
      meterId: $meterId
      type: $type
    ) {
      success
      message
    }
  }
`;
