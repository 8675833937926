import { gql } from "@apollo/client";

export const GET_DATAHUB_CUSTOMERS = gql`
  query {
    dataHubCustomers {
      customerName
      customerKey
      customerCvr
      validFrom
      validTo
      lastSeen
      buildingCount
      accessGiven
      status
      id
    }
  }
`;
