import React from "react";
import GEnergyTypes from "./GEnergyTypes";

const GHeating = () => {
  return (
    <>
      <GEnergyTypes type="heating" sourceType="Heating" />
    </>
  );
};
export default GHeating;
