import React, { useContext, useState, useCallback } from "react";
import { useTextdomainContext } from "app/providers";
import {
  Grid,
  TextField,
  Typography,
  Stack,
  Box,
  Button,
  Collapse,
  Alert,
  AlertTitle,
  MenuItem,
  Autocomplete
} from "@mui/material";
import { Spinner } from "react-bootstrap";
import { useAddProvider } from "../hooks";
import { changeHandler } from "../utils";

const formInitialStage = {
  name: "",
  category: "",
  country: "",
  api: "",
  url: ""
};

function AddProvider(props) {
  const { countries } = props;
  const [form, setForm] = useState(formInitialStage);
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = e => {
    changeHandler(e, form, setForm, error, setError);
  };

  const { create, creating } = useAddProvider();

  const submitHandler = useCallback(
    async e => {
      e.preventDefault();
      let formData = { ...form };
      delete formData.country;
      formData["countryCode"] = form?.country?.code || "";
      const result = await create(formData);
      if (result.data.addServiceProvider.success) {
        setSuccess(true);
        setError(false);
        setForm(formInitialStage);
        setTimeout(() => {
          setSuccess(false);
        }, 4000);
      } else {
        setError(result.data.addServiceProvider.message[0]);
      }
    },
    [create, form, setSuccess, setError]
  );
  return (
    <form onSubmit={submitHandler}>
      <div className="table-div">
        <div className="table-title">
          <h3>{gettext("Add Service Provider")}</h3>
        </div>
        <div className="form">
          <div className="card-body">
            <Collapse in={success}>
              <Alert severity="success">
                <AlertTitle>{gettext("Success")}</AlertTitle>
                {gettext("Provider Added Successfully!")}
              </Alert>
            </Collapse>
            <Collapse in={error && !success ? true : false}>
              <Alert severity="error">
                <AlertTitle>{gettext("Error")}</AlertTitle>
                {gettext(error)}
              </Alert>
            </Collapse>
            <Stack spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography fontSize={16} fontWeight={600} align="left">
                      {gettext("Add a new Service Provider")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="text"
                          label={gettext("Name")}
                          size="small"
                          name="name"
                          inputProps={{ maxLength: 50 }}
                          value={form.name}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          label={gettext("Category")}
                          name="category"
                          onChange={handleChange}
                          value={form.category}
                          select
                          fullWidth
                          size="small"
                        >
                          <MenuItem value={"energy"}>Energy</MenuItem>
                          <MenuItem value={"electricity"}>Electricity</MenuItem>
                          <MenuItem value={"heating"}>Heatiing</MenuItem>
                          <MenuItem value={"water"}>Water</MenuItem>
                          <MenuItem value={"gas"}>Gas</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          disablePortal
                          options={countries}
                          getOptionLabel={option => option.name || ""}
                          value={form.country}
                          onChange={(e, newValue) => {
                            if (
                              error &&
                              error.toLowerCase().includes("country") &&
                              newValue
                            ) {
                              setError(null);
                            }
                            setForm({ ...form, country: newValue });
                          }}
                          size="small"
                          fullWidth
                          isOptionEqualToValue={(option, value) =>
                            option.value === value
                          }
                          renderInput={params => (
                            <TextField {...params} label={gettext("Country")} />
                          )}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="text"
                          label={gettext("Url")}
                          size="small"
                          name="url"
                          value={form.url}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          type="text"
                          label={gettext("API")}
                          name="api"
                          value={form.api}
                          onChange={handleChange}
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box className="ml-auto mr-11">
                <Button
                  className="mr-2 btn btn-primary"
                  type="submit"
                  disabled={creating}
                >
                  {creating ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    <>{gettext("Save")}</>
                  )}
                </Button>
                <Button
                  className="btn btn-secondary"
                  type="reset"
                  onClick={() =>
                    setForm({ name: "", category: "", url: "", api: "" })
                  }
                >
                  {gettext("Reset")}
                </Button>
              </Box>
            </Stack>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddProvider;
