import React from "react";
// import GEnergyTypes from "./GEnergyTypes";
import EnergyCollectionTypes from "./EnergyCollectionTypes";

const Water = () => {
  return (
    <>
      {/* <GEnergyTypes type="water" sourceType="Water" /> */}
      <EnergyCollectionTypes type="water" sourceType="Water" />
    </>
  );
};
export default Water;
