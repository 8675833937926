import { gql } from "@apollo/client";

export const UPDATE_USER_ROLES = gql`
  mutation updateUserRoles(
    $countryCode: String
    $customerKey: String
    $email: String
    $lang: String
    $name: String
    $password: String
    $roles: [String]
    $status: String
    $timezone: String
    $userId: String
  ) {
    comUpdateUserAdmin(
      countryCode: $countryCode
      customerKey: $customerKey
      email: $email
      lang: $lang
      name: $name
      password: $password
      roles: $roles
      status: $status
      timezone: $timezone
      userId: $userId
    ) {
      success
      message
    }
  }
`;
