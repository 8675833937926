import { gql } from "@apollo/client";

export const GET_ACCOUNT_DATA_BY_ID = gql`
  query accountData($id: String!) {
    accountData(id: $id) {
      Id
      createdAt
      fileName
      data {
        accountNumber
        accountName
        period
        yearBefore
        level1
        level2
        level3
        level4
        unit
        fuelType
        emissionFactorElectricity
        greenhouseGas
        materialGroup
        productGroup
        productItem
        vehicles
        fuel
        wasteType
        wasteManagement
        energyType
        emissionFactor
        purchase
        transportType
        material
        consumption
        production
        amount
        biofuelShare
        shareOfBiogas
        scope1
        scope2
        scope3
        outOfScope
      }
    }
  }
`;

export const UPDATE_ACCOUNT_DATA_ELEMENT = gql`
  mutation updateAccountData(
    $id: String!
    $data: GenericScalar!
    $accountNumber: Int!
  ) {
    updateAccountData(id: $id, data: $data, accountNumber: $accountNumber) {
      message
      success
    }
  }
`;
