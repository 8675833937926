import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { getOr } from "lodash/fp";
import {
  // getUnitDateOption,
  // getHourlyExportDates,
  getFileDataType
} from "../../../../hooks/useGCommon";
import { BENCHMARK_DATA } from "../../../../GraphQL/Queries";

const DownloadBenchMark = props => {
  const {
    selectedFileType,
    energyParams,
    setExportDisabled,
    timeStamp,
    exportDisabled,
    selectedSourceType,
    setSelectedSourceType
  } = props;
  const {
    customers,
    buildingCode,
    segment,
    fromDate,
    toDate
    // addressesList
  } = energyParams;
  // const { unit } = getUnitDateOption(segment, date);
  // const { fromDate, toDate } = getHourlyExportDates(segment, unit);
  const { fileType, segmentType } = getFileDataType(selectedFileType, segment);
  // const [oldFileType, setOldFileType] = useState("");

  const [
    fetchListVoxes,
    { data: fileMeterData, refetch: refetchNewListVoxes }
  ] = useLazyQuery(BENCHMARK_DATA, {
    variables: {
      meterType: selectedSourceType,
      address: buildingCode,
      customer: customers,
      fromDate: fromDate,
      toDate: toDate,
      resolution: segmentType,
      fileType: fileType
    }
  });
  useEffect(() => {
    if (fileMeterData) {
      const fileUrl = getOr(null, "benchmarkData", fileMeterData);
      if (fileUrl) {
        window.open(fileUrl);
      }
      setExportDisabled(false);
      setSelectedSourceType("");
      // setOldFileType(selectedFileType);
    }
  }, [fileMeterData, setExportDisabled, timeStamp, setSelectedSourceType]);

  useEffect(() => {
    if (
      selectedSourceType &&
      selectedFileType &&
      selectedFileType !== "download" &&
      exportDisabled
    ) {
      fetchListVoxes();
      refetchNewListVoxes();
    }
  }, [
    selectedFileType,
    fetchListVoxes,
    refetchNewListVoxes,
    exportDisabled,
    selectedSourceType
    // timeStamp
    // fileMeterData
  ]);

  return true;
};

export default DownloadBenchMark;
