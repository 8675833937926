import React from "react";
import { Button, Chip, Menu } from "@mui/material";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { IoChevronDown } from "react-icons/io5";
import { MenuContent } from "./components";
import { useDashboardFilter, useTextdomainContext } from "app/providers";

export function SelectBuilding() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { selectedBuildings } = useDashboardFilter();

  return (
    <>
      <Button
        id="basic-button"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        startIcon={<HiBuildingOffice2 />}
        endIcon={<IoChevronDown size={12} />}
        color="secondary"
        onClick={handleClick}
      >
        {gettext("Buildings")}
        <Chip
          sx={{ ml: 0.5, backgroundColor: "primary.50", color: "primary.main" }}
          size="small"
          label={selectedBuildings.length}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { p: 0 }
        }}
        PaperProps={{
          sx: { p: 1, borderRadius: "8px" }
        }}
      >
        <MenuContent onClose={handleClose} />
      </Menu>
    </>
  );
}
