import { gql } from "@apollo/client";

export const GET_BUILDING_METERS = gql`
  query getBuildingMeters($sourceType: String!, $buildingId: String!) {
    customersMeters(sourceType: $sourceType, buildingId: $buildingId) {
      meterNo
      meterId
      label
      supplier
      providerUsername
      providerPassword
      meterType
    }
  }
`;
