import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { useBenchmarkFilter, useTextdomainContext } from "app/providers";
import moment from "moment";
import React from "react";
import {
  BodyHeadTableCell,
  HeadTableCell,
  StyledTableRow
} from "../../components";

export function LabelTable({ labels }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { resolution } = useBenchmarkFilter();

  return (
    <Table aria-label="simple table" sx={{ width: "auto" }}>
      <TableHead>
        <TableRow>
          <HeadTableCell align="center">{gettext("Hours")}</HeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {labels.map(label => (
          <StyledTableRow key={label} sx={{ border: 0 }}>
            <BodyHeadTableCell component="th" align="center" fontWeight={500}>
              {resolution === "h"
                ? moment(label).format("HH:mm")
                : resolution === "d"
                ? gettext(moment(label).format("DD")) +
                  " " +
                  gettext(moment(label).format("MMM"))
                : resolution === "m"
                ? gettext(moment(label).format("MMM")) +
                  " " +
                  moment(label).format("YYYY")
                : "--"}
            </BodyHeadTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
}
