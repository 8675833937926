import { gql } from "@apollo/client";

export const GET_BUILDINGS = gql`
  query getCustomerBuildings($customerName: String!) {
    customersBuildings(customerName: $customerName) {
      Id
      name
      ident
      address
      status
      electricity
      water
      heating
      gas
      country
      label
      electricityGreen
      waterGreen
      heatingGreen
      gasGreen
      colorSettings {
        electricity {
          background
          text
        }
        heating {
          background
          text
        }
        water {
          background
          text
        }
        gas {
          background
          text
        }
        energy {
          background
          text
        }
        co2 {
          background
          text
        }
      }
    }
  }
`;

export const UPDATE_BUILDING = gql`
  mutation updateCustomerBuilding(
    $buildingId: String!
    $data: UpdateBuildingInput
  ) {
    updateCustomerBuilding(buildingId: $buildingId, data: $data) {
      success
      message
    }
  }
`;
