import { gql } from "@apollo/client";

export const GET_COUNTRIES = gql`
  query mongoCountries {
    mongoCountries {
      Id
      alpha3
      autoId0
      capital
      code
      continent
      continentCode
      currency
      id
      name
      phone
      status
      symbol
    }
  }
`;
