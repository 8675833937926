/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../_metronic/layout";
import { localeNumberFormat } from "../../common/Functions";
import { DECIMAL } from "../../common/Constants";
import { Box, Grid, Typography } from "@mui/material";

export function MixedWidget2(props) {
  const randomString = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 10);
  // let className = props.className;
  let chartTitle = props?.data?.chart_title
    ? props?.data?.chart_title.replace("CO2", "CO₂")
    : "";
  let uniqueId = props?.data?.unique_id ? props?.data?.unique_id : randomString;
  let media_type = props?.data?.media_type ? props?.data?.media_type : "web";
  let objectId = "kt_mixed_widget_1_chart" + uniqueId + "_" + media_type;
  const title = props?.data?.chart_title;
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById(objectId);
    if (!element) {
      return;
    }

    const options = getChartOptions(layoutProps, props.data);

    const chart = new ApexCharts(element, options);

    // chart.render().then(tmp => {
    //   if (typeof props.imageUrl === "function") {
    //     chart.dataURI().then(uri => {
    //       props.imageUrl(uri.imgURI);
    //     });
    //   }
    // });

    chart.render().then(tmp => {
      // window.setTimeout(function() {
      chart.dataURI().then(uri => {
        if (typeof props.imageUrl === "function") {
          props.imageUrl(uri.imgURI);
          const chartSrc = uri.imgURI.replace(
            /^data:image\/(png|jpg);base64,/,
            ""
          );
          if (chartSrc) {
            // setGraphImages(chartSrc, uuid(), team_id, title);
          }
        }
      });
      // }, 1000);
    });

    return function cleanUp() {
      chart.destroy();
    };
  }, [props, title, layoutProps, objectId]);

  return (
    <Grid container xs={12} rowGap={4} pt={1}>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
            color: "#27272A"
          }}
        >
          {chartTitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          p={1.5}
          pt={5}
          pb={2.5}
          id={objectId}
          sx={{
            backgroundColor: "#f2f5f7",
            borderRadius: "14px"
          }}
          style={{
            height: "100%"
          }}
        ></Box>
      </Grid>
    </Grid>
  );
}

function getChartOptions(layoutProps, data) {
  // const strokeColor = "#D13647";
  // const strokeColor = "#426a2f";

  const options = {
    // series: data.data.filter(
    //   graphData => Array.isArray(graphData.data) && graphData.name
    // ),
    series: data.data,
    chart: {
      // width: 380,
      height: 270,
      type: "donut",
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: "63%"
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function(val) {
        return `${localeNumberFormat(val, DECIMAL)}%`;
      }
    },
    stroke: {
      show: false,
      width: 0,
      colors: ["transparent"]
    },
    labels: data.labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ],
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "bottom",
      horizontalAlign: "bottom",
      markers: {
        width: 18,
        height: 8
      }
    },
    // colors: ["#426a2f", "#dc8d07", "#1c2d58"],
    colors: data.colors,
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          return `${localeNumberFormat(val, DECIMAL)}%`;
        }
      }
    }
  };

  return options;
}
