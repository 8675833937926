import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  styled,
  TextField,
  Typography
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useCallback, useContext, useState } from "react";
import { useDebounce } from "hooks";
import { useFindAddresses } from "../../hooks";
import { useCompanyBuildings } from "../../hooks/useCompanyBuildings";
import { useTextdomainContext, useUser } from "app/providers";
import { IoCloseOutline, IoInformationCircle } from "react-icons/io5";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400
  }
}));

export default function AddressesInput(props) {
  const {
    newBuildings,
    setNewBuildings,
    buildingError,
    setBuildingError
  } = props;

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const [addressValue, setAddressValue] = useState("");

  const debounceAddressValue = useDebounce(addressValue, 500);

  const { addresses: addressesOptions, loading } = useFindAddresses(
    debounceAddressValue
  );

  const { user } = useUser();

  const {
    teams: [team]
  } = user || {};

  const { country } = useCompanyBuildings(
    team?.name,
    (companyAddresses, country) => {
      if (companyAddresses.length > 0) setNewBuildings([...companyAddresses]);
      // else setNewBuildings([{ address: "", country: country }]);
    }
  );

  const addNewBuildingInput = useCallback(
    buildingAddress => {
      const address =
        typeof buildingAddress === "object"
          ? buildingAddress.tekst
          : buildingAddress;
      setNewBuildings(prevArray => {
        if (!prevArray.find(prevAdd => prevAdd.address === address)) {
          prevArray.splice(0, 0, {
            address,
            country
          });
        }
        return [...prevArray];
      });
    },
    [country, setNewBuildings]
  );

  const removeBuildingInput = useCallback(
    index => {
      setNewBuildings(prevArray => {
        prevArray.splice(index, 1);
        return [...prevArray];
      });
    },
    [setNewBuildings]
  );

  return (
    <Stack>
      <InputLabel htmlFor="building-input">
        <Typography fontWeight={500} color="#3F3F46">
          {gettext("Building Address")}
        </Typography>
      </InputLabel>
      <Autocomplete
        id={"building-input"}
        disablePortal
        disableClearable
        freeSolo
        fullWidth
        loading={loading}
        options={addressesOptions}
        getOptionLabel={option => option.tekst || ""}
        inputValue={addressValue}
        onInputChange={(e, newValue) => {
          setAddressValue(newValue);
          setBuildingError(false);
        }}
        value={null}
        onChange={(e, newValue) => {
          addNewBuildingInput(newValue);
          setAddressValue("");
        }}
        renderInput={params => (
          <TextField
            {...params}
            error={Boolean(buildingError)}
            helperText={buildingError}
            placeholder={gettext("Add building address")}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <LightTooltip
                    title={
                      <Typography color="#4D896F" textAlign={"center"}>
                        {gettext(
                          "Type the building address and select it from list or press “enter” to add more."
                        )}
                      </Typography>
                    }
                    arrow
                    placement="top"
                  >
                    <IconButton>
                      <IoInformationCircle />
                    </IconButton>
                  </LightTooltip>
                </InputAdornment>
              )
            }}
          />
        )}
      />
      <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}>
        {newBuildings.map((building, index) => (
          <Chip
            key={building.address}
            sx={{
              borderRadius: "8px",
              border: "1px solid",
              borderColor: "primary.200",
              backgroundColor: "primary.50"
            }}
            size="small"
            label={
              <Typography fontWeight={500} color="primary.main">
                {building.address}
              </Typography>
            }
            onDelete={() => removeBuildingInput(index)}
            deleteIcon={<IoCloseOutline />}
          />
        ))}
      </Box>
    </Stack>
  );
}
