import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGridComponent } from "../../components";
import { useFetchPartners } from "./hooks";
import { IoPeople } from "react-icons/io5";

export function PartnerListTable() {
  const navigate = useNavigate();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { partners, loading } = useFetchPartners();

  function gotoMembers(row) {
    navigate(`/admin/partners-list/${row.customerKey}/members`);
  }

  function columnFormater(params) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Tooltip title="Members" arrow>
          <IconButton onClick={() => gotoMembers(params.row)}>
            <IoPeople size={18} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  const columns = [
    {
      field: "name",
      headerName: gettext("Name"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "email",
      headerName: gettext("Email"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "countryCode",
      headerName: gettext("Country"),
      flex: 0.5,
      headerAlign: "left"
    },
    {
      field: "timezone",
      headerName: gettext("Timezone"),
      flex: 0.6,
      headerAlign: "left"
    },
    {
      field: "status",
      headerName: gettext("Status"),
      flex: 0.4,
      headerAlign: "left"
    },
    {
      field: "customerKey",
      headerName: gettext("Actions"),
      flex: 0.8,
      headerAlign: "center",
      renderCell: params => columnFormater(params)
    }
  ];

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3>{gettext("Network List")}</h3>
            <span className="font-size-sm w-100">
              {gettext("List of all Networks")}
            </span>
          </div>
          {/* end::Header */}
          <div className="card-body">
            <DataGridComponent
              rows={partners}
              columns={columns}
              loading={loading}
              disableSelectionOnClick={true}
              autoHeight
            />
          </div>
        </div>
      </div>
    </>
  );
}
