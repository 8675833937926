import { Box, Skeleton } from "@mui/material";
import React from "react";

const tableLoadingState = () => {
  return (
    <Box
      width="100%"
      height="100vh"
      sx={{ display: "flex", flexDirection: "column", gap: 1, pt: 5.5 }}
    >
      <Skeleton variant="rounded" animation="wave" width="100%" height="10%" />
      <Skeleton variant="rounded" animation="wave" width="100%" height="10%" />
      <Skeleton variant="rounded" animation="wave" width="100%" height="10%" />
      <Skeleton variant="rounded" animation="wave" width="100%" height="10%" />
      <Skeleton variant="rounded" animation="wave" width="100%" height="10%" />
      <Skeleton variant="rounded" animation="wave" width="100%" height="10%" />
      <Skeleton variant="rounded" animation="wave" width="100%" height="10%" />
      <Skeleton variant="rounded" animation="wave" width="100%" height="10%" />
      <Skeleton variant="rounded" animation="wave" width="100%" height="10%" />
      <Skeleton variant="rounded" animation="wave" width="100%" height="10%" />
    </Box>
  );
};
export default tableLoadingState;
