import React, { useMemo, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { EnergySupplierList } from "app/features/EnergySupplierList";
import { AddEnergySupplierForm } from "app/features/AddEnergySupplierForm";
import { useCountries } from "hooks";

export function AdminEnergySupplier() {
  const { countries } = useCountries();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const energyTypes = useMemo(() => {
    return [
      {
        id: 1,
        name: "electricity",
        label: gettext("Electricity")
      },
      {
        id: 2,
        name: "heating",
        label: gettext("Heating")
      },
      {
        id: 3,
        name: "water",
        label: gettext("Water")
      },
      {
        id: 4,
        name: "gas",
        label: gettext("Gas")
      }
    ];
  }, [gettext]);

  return (
    <>
      <div>
        <EnergySupplierList countries={countries} types={energyTypes} />
        <AddEnergySupplierForm
          countries={countries}
          callback={() => {}}
          types={energyTypes}
        />
      </div>
    </>
  );
}
