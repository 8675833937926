import { Autocomplete, Box, Button, TextField } from "@mui/material";
import React, { useContext } from "react";
import { useTextdomainContext, useUser } from "app/providers";

export function CustomerRolesControls({
  teams,
  selectedCustomer,
  setSelectedCustomer,
  setAddModalOpen
}) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);
  const { user } = useUser();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "right",
        gap: 2
      }}
    >
      <Autocomplete
        disableClearable
        disablePortal
        disabled={user?.demo}
        id="combo-box-demo"
        placeholder={gettext("Select Company")}
        size="small"
        value={selectedCustomer}
        onChange={(e, newValue) => setSelectedCustomer(newValue)}
        options={teams}
        getOptionLabel={option => option.name}
        sx={{ flex: 1, maxWidth: 300 }}
        renderInput={params => (
          <TextField {...params} label={gettext("Company")} />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.Id}>
            {option.name}
          </li>
        )}
      />

      <Button
        size="small"
        disabled={user?.demo}
        variant="contained"
        sx={{ textDecoration: "none" }}
        onClick={() => setAddModalOpen()}
      >
        {gettext("Invite User")}
      </Button>
    </Box>
  );
}
