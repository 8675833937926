import React from "react";
import { ErrorPage5 } from "../modules/ErrorsExamples/ErrorPage5";
import { sendSlackMessage } from "../common/Functions";
import { withUser } from "app/providers";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  componentDidCatch(error, info) {
    const { user } = this.props;
    let userId = user && user.id ? user.id : "N/A";
    let userEmail = user && user.email ? user.email : "N/A";
    let userName = user && user.name ? user.name : "N/A";
    let teamName = user && user.currentTeam ? user.currentTeam : "NA";

    this.setState({ hasError: true });

    this.setState({
      error: error,
      info: info
    });

    if (process.env.REACT_APP_NODE_ENV !== "development") {
      let slackErrorData = {
        channel: "react-errors",
        type: "error",
        heading: window.location.href,
        detail: error.toString(),
        fields: [
          {
            title: "User ID",
            value: userId
          },
          {
            title: "User Name",
            value: userName
          },
          {
            title: "User Email",
            value: userEmail
          },
          {
            title: "User Team",
            value: teamName
          }
        ]
      };
      sendSlackMessage(slackErrorData);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return <h1>Something went wrong. {process.env.NODE_ENV}</h1>;
      if (process.env.REACT_APP_NODE_ENV === "development") {
        return (
          <>
            <h1>Something went wrong. {process.env.REACT_APP_NODE_ENV}</h1>
            <p>
              <pre>{this.state.error.stack}</pre>
              <hr />
              <h2>Full Stack</h2>
              <pre>{this.state.info.componentStack}</pre>
            </p>
          </>
        );
      } else {
        return <ErrorPage5 />;
      }
    }
    return this.props.children;
  }
}

export default withUser(ErrorBoundary);
