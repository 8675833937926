import { Box, Button, Collapse, Grid, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { AiFillFileText } from "react-icons/ai";
import { BsCloudUploadFill } from "react-icons/bs";

const DetailCard = ({ label, data }) => {
  return (
    <Box>
      <Typography fontSize={16} fontWeight="bold" sx={{ my: 1 }}>
        {label}
      </Typography>
      {typeof data === "object" ? (
        data &&
        data.map((item, index) => {
          return <Typography fontSize={13}>{item}</Typography>;
        })
      ) : (
        <Typography fontSize={13}>{data}</Typography>
      )
      // <Typography fontSize={13}>{data}</Typography>
      }
    </Box>
  );
};
const FileCard = ({ data }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <AiFillFileText size={30} style={{ marginRight: 4 }} />
      <Typography fontSize={13}>{data}</Typography>
    </Box>
  );
};

export function FileUpload({ fileName, fileData, onUpload }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  return (
    <Collapse in={Boolean(fileData) && fileData?.selectedCustomers !== null}>
      <Paper>
        <Grid container sx={{ padding: 2 }}>
          <Grid item md={4} xs={12} display="flex">
            <FileCard label={gettext("Name")} data={fileName} />
          </Grid>
          <Grid item md={2} xs={12}>
            <DetailCard label={gettext("From")} data={fileData?.fromDate} />
          </Grid>
          <Grid item md={2} xs={12}>
            <DetailCard label={gettext("To")} data={fileData?.toDate} />
          </Grid>
          <Grid item md={2} xs={12}>
            <DetailCard
              label={gettext("Company")}
              data={fileData?.selectedCustomers}
            />
          </Grid>
          {/* <Grid item md={2} xs={12}>
            <DetailCard
              label={gettext("Buildings")}
              data={fileData?.buildingName}
            />
          </Grid> */}
          <Grid item md={2} xs={12} display="flex" justifyContent="center">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="outlined"
                startIcon={<BsCloudUploadFill />}
                onClick={onUpload}
              >
                {gettext("Start")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Collapse>
  );
}
