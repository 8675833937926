import React from "react";

function LoadingBar(props) {
  return (
    <>
      {props.show && (
        <div className="loading-bar">
          <div className="loading-color"></div>
        </div>
      )}
    </>
  );
}

export default LoadingBar;
