import React, { useEffect } from "react";
import { LayoutSplashScreen } from "_metronic/layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth, useTextdomainContext } from "app/providers";
import { useSnackbar } from "notistack";

export default function AcceptSocialLogin() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const error = searchParams.get("error");

  const { setToken } = useAuth();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  useEffect(() => {
    if (token) {
      setToken(token);
      navigate("/");
    } else {
      if (error && error === "405")
        enqueueSnackbar(gettext("Your account doesn’t exist, please Sign Up"), {
          variant: "warning"
        });
      else
        enqueueSnackbar(gettext("Login failed! Please try again"), {
          variant: "warning"
        });

      navigate("/auth/login", {
        replace: true
      });
    }
  }, [enqueueSnackbar, gettext, navigate, setToken, token, error]);

  return <LayoutSplashScreen />;
}
