import React, { useMemo, useEffect, useContext } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import { getOr } from "lodash/fp";
import * as Constants from "../../../../common/Constants";
import { useLang, useTextdomainContext } from "app/providers";
import { ChartContainer } from "app/components";
import { Box } from "@mui/material";
import moment from "moment";

export function NewEnergyGraphs(props) {
  const { data, selectedSegment, imageUrl, label } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const locale = useLang();

  let E1Color = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade1.background",
    props.propsColors
  );
  let E2Color = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade2.background",
    props.propsColors
  );
  let E3Color = getOr(
    Constants.ELECTRICITY_COLOR,
    "electricity.shade3.background",
    props.propsColors
  );
  const randomString = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 10);
  // let className = props.className;
  let chartTitle = props?.data?.chart_title
    ? props?.data?.chart_title.replace("CO2", "CO₂")
    : "";
  let uniqueId = props?.data?.unique_id ? props?.data?.unique_id : randomString;
  let media_type = props?.data?.media_type ? props?.data?.media_type : "web";
  let objectId = "kt_mixed_widget_1_chart" + uniqueId + "_" + media_type;

  const title = props?.data?.chart_title;

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  useEffect(() => {
    if (data) {
      const element = document.getElementById(objectId);
      if (!element) {
        return;
      }
      const options = getChartOptions(
        data,
        E1Color,
        E2Color,
        E3Color,
        label,
        locale,
        chartTitle,
        selectedSegment,
        gettext
      );

      const chart = new ApexCharts(element, options);

      chart
        .render()

        .then(() => {
          if (typeof imageUrl === "function") {
            chart.dataURI().then(uri => {
              imageUrl(uri.imgURI);
            });
          }
        })
        .catch(err => {
          return true;
        });
      return function cleanUp() {
        chart.destroy();
      };
    }
  }, [
    data,
    imageUrl,
    title,
    layoutProps,
    objectId,
    E1Color,
    E2Color,
    E3Color,
    label,
    chartTitle,
    locale,
    selectedSegment,
    gettext
  ]);

  return (
    <ChartContainer>
      {/*             
                            {chartTitle === "Gas CO₂ measured in Tons"
                                ? "Natural Gas CO₂ measured in Tons"
                                : chartTitle ===
                                  "Gas consumption and compensation measured in kWh"
                                ? "Natural Gas consumption and compensation measured in kWh"
                                : chartTitle} */}

      {/* Chart */}
      <Box
        pt={1}
        id={objectId}
        style={{
          height: "100%"
        }}
      ></Box>
    </ChartContainer>
  );
}

export function getChartOptions(
  data,
  E1Color,
  E2Color,
  E3Color,
  label,
  locale,
  chartTitle,
  selectedSegment,
  gettext
) {
  const calcUnits = unit => {
    if (unit === "KWH") {
      return "kWh";
    } else if (unit === "CO₂/KWH") {
      return "CO₂/kWh";
    } else if (unit === "DKK/KWH") {
      return "DKK/kWh";
    } else {
      return unit;
    }
  };

  const calcDate = (selectedSegment, value) => {
    // return value
    let newDate = new Date(value);
    if (selectedSegment === 2) {
      newDate = newDate.toLocaleDateString(locale, {
        month: "short"
      });
      // return newDate;
      return value;
    } else if (selectedSegment === 1) {
      newDate = moment(value, "DD-MM-YYYY");
      return newDate.format("DD") + " " + gettext(newDate.format("MMM"));
    } else {
      return value;
    }
  };

  const typeChecked = data.data.findIndex(item => item.type === "column");
  const options = {
    series: data.data.filter(
      graphData => Array.isArray(graphData.data) && graphData.name
    ),

    chart: {
      type: typeChecked !== -1 ? "line" : "bar",
      height: "100%",
      stacked: data.stacked,
      zoom: {
        enabled: false
      },
      animations: {
        enabled: false
      },
      // fontFamily: 'Aeonik',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 3,
        borderRadiusApplication: "end"
      }
    },
    dataLabels: {
      enabled: false
    },
    // colors: colors,
    title: {
      text: chartTitle ? chartTitle : " ",
      align: "left",
      floating: false,
      style: {
        fontSize: "20px",
        fontWeight: 700,
        fontFamily: "Aeonik",
        color: "#27272A",
        lineHeight: "20px"
      }
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "round",
      colors: undefined,
      width: 1,
      dashArray: 0
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      formatter: function(seriesName, opts) {
        const color = opts.w.globals.colors[opts.seriesIndex];
        return `
          <span style="display:flex;flex-direction:row;justify-content:center;align-items:center;align-self:center;gap:5px;;
          padding: 4px 8px;
          border-radius: 25px;
          border: 1px solid ${color};
          background-color: ${color}14;">
          <span style="background-color:${color};width:8px;height:8px;border-radius:50%;"></span>
          <span style="font-size:14px;font-weight:400;" >${seriesName}</span>
          
          
          </span>
        `;
      },
      position: "top",
      horizontalAlign: "left",
      offsetY: -23,
      labels: {
        useSeriesColors: true
      },
      markers: {
        width: 0,
        height: 0,
        radius: 0
      },
      itemMargin: {
        horizontal: 4,
        vertical: 0
      }
    },
    markers: {
      hover: {
        size: 8,
        sizeOffset: 3
      }
    },

    xaxis: {
      tooltip: {
        enabled: false
      },
      type: "catagory",
      title: {
        text: data.xaxisTitleText,
        style: {
          colors: "#A1A1AA",
          fontSize: "14px",
          fontWeight: 400
        }
      },
      categories: data.categories,
      position: "bottom",
      floating: false,
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        style: {
          colors: "#A1A1AA",
          fontSize: "13px",
          fontWeight: 400
        },

        formatter: function(value) {
          return calcDate(selectedSegment, value);
        }
      },
      offsetY: 3,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        show: false
      }
    },
    yaxis: {
      floating: false,
      decimalsInFloat: 2,
      forceNiceScale: true,
      title: {
        text: data.yaxisTitleText
          ? data.yaxisTitleText.replace("CO2", "CO₂")
          : ""
        // rotate: -90,
      },

      axisTicks: { show: false },
      labels: {
        show: true,
        align: "left",
        formatter: function(value) {
          // const f = new Intl.NumberFormat(locale, {
          //     notation: "compact",
          //     maximumFractionDigits: 2,
          // });
          if (locale === "da") {
            const numValue = Number(value);
            // const newStringValue = numValue.toFixed(2).toLocaleString();
            const newStringValue = numValue
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            const updatedValue = newStringValue
              .replace(/\./g, "#")
              .replace(/,/g, ".")
              .replace(/#/g, ",");
            return updatedValue;
          }
          return value.toFixed(2);
        },
        style: {
          colors: "#A1A1AA",
          fontSize: "13px",
          fontWeight: 400
        }
      },
      axisBorder: {
        show: false
      },
      crosshairs: {
        show: false
      }
    },
    tooltip: {
      followCursor: true,
      y: {
        // formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
        //   const color = w.globals.colors[seriesIndex];
        //   return `

        //                 <span style="font-size:12px;font-weight:400;color:${color}" >${value
        //     .toFixed(2)
        //     .toLocaleString() +
        //     " (" +
        //     calcUnits(data.yaxisTitleText) +
        //     ")"}</span>`;
        // }
        formatter: function(value, { seriesIndex, w }) {
          const color = w.globals.colors[seriesIndex];
          if (locale === "en") {
            const numValue = Number(value);
            // const newStringValue = numValue.toFixed(2).toLocaleString();
            const newStringValue = numValue
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return `
                        <span style="font-size:12px;font-weight:400;color:${color}" >${newStringValue +
              " (" +
              calcUnits(data.yaxisTitleText) +
              ")"}</span>`;
          } else if (locale === "da") {
            const numValue = Number(value);
            // const newStringValue = numValue
            //     .toFixed(2)
            //     .toLocaleString();
            const newStringValue = numValue
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            const updatedValue = newStringValue
              .replace(/\./g, "#")
              .replace(/,/g, ".")
              .replace(/#/g, ",");
            return `
                   
              <span style="font-size:12px;font-weight:400;color:${color}" >${updatedValue +
              " (" +
              calcUnits(data.yaxisTitleText) +
              ")"}</span>`;
          }
        }
      }
      // x: {
      //   formatter: function(value) {
      //     const date = new Date(value);
      //     return selectedSegment === 2
      //       ? date.toLocaleDateString(locale, {
      //           month: "short",
      //           year: "numeric"
      //         })
      //       : value;
      //   }
      // }
    },
    grid: {
      show: true,
      borderColor: "#D4D4D8",
      strokeDashArray: 6,
      position: "back",
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    colors: data.colors
  };

  return options;
}
