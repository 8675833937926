import { useCompanyBuildings, useEnergySources } from "hooks";
import React, { createContext } from "react";
import { useUser } from "../UserProvider";

const EpcFilterContext = createContext();

export function useEpcFilter() {
  return React.useContext(EpcFilterContext);
}

export default function EpcFilterProvider({ children }) {
  const allEnergySources = useEnergySources();

  const [selectedBuilding, setSelectedBuilding] = React.useState({});

  const [selectedEnergySource, setSelectedEnergySource] = React.useState(
    allEnergySources[0]
  );
  const currentYear = new Date().getFullYear() - 1;

  const yearsList = Array.from(new Array(currentYear - 2016), (val, index) => {
    const thisYear = currentYear - index;
    return { value: thisYear, label: `${thisYear} - ${thisYear - 2}` };
  });

  const [selectedYear, setSelectedYear] = React.useState(yearsList[0]);

  const { user } = useUser();

  const { currentTeam } = user || {};

  const { buildings, loading } = useCompanyBuildings(currentTeam, buildings => {
    if (buildings.length) {
      setSelectedBuilding(buildings[0] || {});
    }
  });

  const value = {
    buildings,
    allEnergySources,
    yearsList,
    selectedBuilding,
    setSelectedBuilding,
    selectedEnergySource,
    setSelectedEnergySource,
    selectedYear,
    setSelectedYear,
    filtersLoading: loading
  };

  return (
    <EpcFilterContext.Provider value={value}>
      {children}
    </EpcFilterContext.Provider>
  );
}
