import { useMutation } from "@apollo/client";
import { SEND_WELCOME_EMAIL } from "../commands";
import { useCallback } from "react";

// export function useSendWelcomeEmail(
//   onCompleted = () => {},
//   onError = () => {}
// ) {
//   const [comSendWelcomeEmail, { loading }] = useMutation(SEND_WELCOME_EMAIL, {
//     onCompleted: () => {
//       onCompleted();
//     },
//     onError: () => {
//       onError();
//     }
//   });
//   const sendEmail = useCallback(
//     ({ email }) => {
//       comSendWelcomeEmail({
//         variables: {
//           email
//         }
//       });
//     },
//     [comSendWelcomeEmail]
//   );

export function useSendWelcomeEmail(
  onCompleted = () => {},
  onError = () => {}
) {
  const [comSendWelcomeEmail, { loading }] = useMutation(SEND_WELCOME_EMAIL, {
    onCompleted: () => {
      onCompleted();
    },
    onError: () => {
      onError();
    }
  });

  const sendEmail = useCallback(
    email => {
      comSendWelcomeEmail({
        variables: {
          email
        }
      });
    },
    [comSendWelcomeEmail]
  );

  return {
    sendEmail,
    loading
  };
}
