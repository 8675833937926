import { NetworkStatus, useMutation, useQuery } from "@apollo/client";
import { useCallback, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useSnackbar } from "notistack";
import { FETCH_ÁCCOUNT_PLANS_LIST, UPDATE_PLAN_LINK_STATUS } from "./commands";

export function useFetchPlainLinks() {
  const { data, loading, error, refetch, networkStatus } = useQuery(
    FETCH_ÁCCOUNT_PLANS_LIST
  );

  return {
    planLinks: data?.plainLinks,
    loading,
    updating: networkStatus === NetworkStatus.refetch,
    error,
    refetch
  };
}

export function useUpdatePlanLinkStatus() {
  const { enqueueSnackbar } = useSnackbar();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [updatePlainLinkStatus] = useMutation(UPDATE_PLAN_LINK_STATUS);

  const updateStatus = useCallback(
    async (id, status, refetch) => {
      try {
        await updatePlainLinkStatus({
          variables: {
            id,
            status
          }
        });

        await refetch();

        enqueueSnackbar(gettext("Plan Status Updated Successfully"), {
          variant: "success"
        });
      } catch (error) {
        enqueueSnackbar(gettext("Error Updating Plan Status"), {
          variant: "error"
        });
      }
    },
    [updatePlainLinkStatus, enqueueSnackbar, gettext]
  );

  return {
    updateStatus
  };
}
