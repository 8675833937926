import React, { useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MdDelete } from "react-icons/md";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
// import { UPDATE_TEAM_MEMBERS_ROLE_URL } from "../_redux/userProfileCrud";
import { injectIntl } from "react-intl";
import DeleteModel from "./DeleteModel";
import { useUser, useTextdomainContext } from "app/providers";
import { gql, useQuery, useMutation } from "@apollo/client";

export function TeamMembers(props) {
  const { intl, team } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [editableRow, seteditableRow] = useState({});
  const [updating, setUpdating] = useState(false);
  const [delete_show, setdelete] = useState(false);

  const { user } = useUser();

  const handleClose_delete = () => setdelete(false);
  const handleShow_delete = () => setdelete(true);

  //getting formated text for user desc
  const translatedMessage = intl.formatMessage(
    { id: "AUTH.MENU.CLIENT_USERS_DESC" },
    { name: team ? team.name : user.currentTeam }
  );

  const { data, loading } = useQuery(
    gql`
      query comGetTeamMembers($customerName: String) {
        comGetTeamMembers(customerName: $customerName) {
          name
          email
          read
          write
          owner
        }
      }
    `,
    {
      variables: {
        customerName: team ? team.name : user.currentTeam
      }
    }
  );

  const { comGetTeamMembers } = data || {};
  const [UpdateUserPermission] = useMutation("", {
    onCompleted() {},
    onError() {},
    refetchQueries: ["comGetTeamMembers"]
  });

  // Table columns
  // const labelId = intl.formatMessage({ id: "USERS.TABLE.HEADING.ID" });
  const labelName = intl.formatMessage({ id: "USERS.TABLE.HEADING.NAME" });
  const labelEmail = intl.formatMessage({ id: "USERS.TABLE.HEADING.EMAIL" });
  const labelActions = intl.formatMessage({
    id: "USERS.TABLE.HEADING.ACTIONS"
  });
  const labelPermission = intl.formatMessage({
    id: "USERS.TABLE.HEADING.PERMISSION"
  });

  const edituserPermissionClkHandler = (value, type, row) => {
    let readval, writeval;

    switch (type) {
      case "write":
        if (value) {
          writeval = true;
        } else {
          readval = row.read;
          writeval = false;
        }

        break;
      case "read":
        if (value) {
          readval = true;
          writeval = row.write;
        } else {
          readval = false;
        }
        break;

      default:
        break;
    }
    setUpdating(true);
    UpdateUserPermission({
      variables: {
        userEmail: row.email,
        permissionData: {
          read: readval,
          write: writeval
        }
      }
    }).then(res => {
      if (res.data && res.data.comUpdateUserPermissions.success === "true") {
        setUpdating(false);
      }
      setUpdating(false);
    });
  };

  function PermissionsRead(row) {
    let isCheck = false;
    if (row.row.read === true) isCheck = true;
    else isCheck = false;

    return (
      <input
        type="checkbox"
        onChange={e =>
          edituserPermissionClkHandler(e.target.checked, "read", row.row)
        }
        checked={isCheck}
        id={`readChkbxID${row.row.user_id}`}
        name="role_chkbx_read"
        value="Read"
      />
    );
  }

  function PermissionsWrite(row) {
    let isCheck = false;
    if (row.row.write === true) isCheck = true;
    else isCheck = false;
    return (
      <input
        type="checkbox"
        onChange={e =>
          edituserPermissionClkHandler(e.target.checked, "write", row.row)
        }
        checked={isCheck}
        id={`writeChkbxID${row.row.user_id}`}
        name="role_chkbx_write"
        value="Write"
      />
    );
  }

  function DeleteFunctionality(row) {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip>
            <strong>Delete</strong>
          </Tooltip>
        }
      >
        <MdDelete
          // title="Delete"
          size={20}
          onClick={() => {
            seteditableRow(row.row);
            handleShow_delete();
          }}
        />
      </OverlayTrigger>
    );
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        {loading || updating ? (
          <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="light" />
          </div>
        ) : null}

        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3>{gettext("Users")}</h3>
            <span className="font-size-sm w-100">{translatedMessage}</span>
          </div>
          {/* end::Header */}

          <div className="card-body overflow-auto">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2} align="center">
                    {labelName}
                  </TableCell>
                  <TableCell rowSpan={2} align="center">
                    {labelEmail}
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    {labelPermission}
                  </TableCell>
                  <TableCell rowSpan={2} align="center">
                    {labelActions}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{gettext("Read")}</TableCell>
                  <TableCell align="center">{gettext("Write")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {comGetTeamMembers?.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">
                      <PermissionsRead row={row} />
                    </TableCell>
                    <TableCell align="center">
                      <PermissionsWrite row={row} />
                    </TableCell>
                    {/* <TableCell align="center">
                      <PermissionsDelete row={row} />
                    </TableCell> */}
                    <TableCell align="center">
                      <DeleteFunctionality row={row} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      <DeleteModel
        show={delete_show}
        onHide={handleClose_delete}
        editableRow={editableRow}
        customerName={team?.name}
      />
    </>
  );
}
export default injectIntl(TeamMembers);
