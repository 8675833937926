import * as React from "react";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useAccountsDataList } from "./hooks";
import { Skeleton, styled } from "@mui/material";
import { FileDetails } from "./components";
import { capitalizeFirstLetter } from "hooks/useCommon";

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  margin: 4,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  }
}));

export function FileListAccordtion() {
  const { accountsDataList, loading } = useAccountsDataList();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {loading ? (
        <>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={50}
            sx={{ marginY: 1 }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={50}
            sx={{ marginY: 1 }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={50}
            sx={{ marginY: 1 }}
          />
        </>
      ) : (
        accountsDataList?.map((file, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={"account-plan-heading"}
            >
              <Typography ml={4} fontStyle={"italic"} fontWeight="bold">
                {file?.fileName && capitalizeFirstLetter(file?.fileName)}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <FileDetails fileId={file?.Id} />
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </>
  );
}
