import React, { useEffect } from "react";
import { MenuItem, TextField } from "@mui/material";

import { useDropdownOptions } from "./hooks";
// import { useTextdomainContext } from "app/providers";

export function Dropdown({
  name,
  type,
  dropdownValues,
  setdropdownValues,
  Level1SelectedTab,
  Level2SelectedTab,
  Level3SelectedTab
}) {
  const [productGroupType, SetProductGroupType] = React.useState(null);
  // const { TextdomainContext } = useTextdomainContext();
  // const { gettext } = useContext(TextdomainContext);
  const { options } = useDropdownOptions(
    type["key"],
    Level1SelectedTab,
    productGroupType,
    Level2SelectedTab,
    Level3SelectedTab
  );

  const { productGroup, vehicles, wasteType } = dropdownValues;

  useEffect(() => {
    if (type["key"] === "productItem" && productGroup !== undefined) {
      SetProductGroupType(productGroup);
    }
  }, [productGroup, type]);

  useEffect(() => {
    if (type["key"] === "fuel" && vehicles !== undefined) {
      SetProductGroupType(vehicles);
    }
  }, [vehicles, type]);

  useEffect(() => {
    if (type["key"] === "wasteManagement" && wasteType !== undefined) {
      SetProductGroupType(wasteType);
    }
  }, [wasteType, type]);

  return (
    <TextField
      select
      id="outlined-basic"
      fullWidth
      size={"small"}
      label={type[name]}
      value={dropdownValues[type["key"]]}
      onChange={e =>
        setdropdownValues({
          ...dropdownValues,
          [type["key"]]: e.target.value
        })
      }
    >
      {options.map((option, index) => (
        <MenuItem key={option.key} value={option.key}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
