import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ProgressBar } from "react-bootstrap";
import { readString } from "react-papaparse";
import { withRouter } from "../../../../utils";
// import Moment from 'moment';

class AddTransactions extends React.Component {
  customInputStyle = {
    height: 35,
    borderRadius: 4,
    borderColor: "rgb(204,204,204)",
    borderWidth: 1.8,
    paddingLeft: 10,
    outlineColor: "rgb(46, 135,252)"
  };

  constructor(props) {
    super(props);

    // let oneYearOldDate = new Date();
    // oneYearOldDate.setFullYear(oneYearOldDate.getFullYear() - 1);
    // let oneYearOldDate1 = new Date();
    // oneYearOldDate1.setFullYear(oneYearOldDate1.getFullYear() - 1);
    // console.log("constructor")

    this.state = {
      selectedDate: new Date(),
      selectedDate1: new Date(),
      selectedSegment: 0,
      planName: props.location.state.data[0],
      isEditPage: props.location.state.type === "edit",
      isViewPage: props.location.state.type === "view",
      isNameEmpty: false,
      fileName: "",
      fileSize: "",
      percentage: 0,
      csvStr: ""
    };
    // const sd = sessionStorage.getItem('transaction')
    // const plan = JSON.parse(sd)
    // const index = this.props.location.state.index

    // if (this.state.isEditPage) {

    //     const dates = new Date(plan.content[index][1])
    //     const dates1 = new Date(plan.content[index][2])
    //     const formatDate=new Intl.DateTimeFormat('en-US', { year: 'numeric', day: '2-digit', month: '2-digit' }).format(dates)
    //     const formatDate1=new Intl.DateTimeFormat('en-US', { year: 'numeric', day: '2-digit', month: '2-digit' }).format(dates1)
    //     this.state.selectedDate = formatDate
    //     this.state.selectedDate1 = formatDate1

    // }
  }
  getFormattedDates = input => {
    let pattern = /(.*?)\/(.*?)\/(.*?)$/;
    let result = input.replace(pattern, function(match, p1, p2, p3) {
      // var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12"
      ];
      return months[p2 - 1] + "/" + p1 + "/" + p3;
      // return (p1) + "/" + months[(p2 - 1)] + "/" + p3;
    });
    return result;
  };

  componentDidMount() {
    // const { isEditPage, isViewPage, selectedDate, selectedDate1 } = this.state;
    const { isEditPage, isViewPage } = this.state;

    if (isEditPage || isViewPage) {
      const sd = sessionStorage.getItem("transaction");
      const plan = JSON.parse(sd);
      const index = this.props.location.state.index;
      const dates = new Date(this.getFormattedDates(plan.content[index][1]));
      const dates1 = new Date(this.getFormattedDates(plan.content[index][2]));
      this.setState({ selectedDate: dates, selectedDate1: dates1 });
    }
  }
  getFormattedDate = () => {
    const date = new Date();
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date
      .getDate()
      .toString()
      .padStart(2, "0");

    return day + "/" + month + "/" + year;
  };

  getSelectedDate = date => {
    // const date = new Date();
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date
      .getDate()
      .toString()
      .padStart(2, "0");

    return day + "/" + month + "/" + year;
  };

  onSaveTap = () => {
    const { isEditPage, planName, selectedDate, selectedDate1 } = this.state;

    if (this.state.planName === "") {
      this.setState({ isNameEmpty: true });
    } else {
      const sd = sessionStorage.getItem("transaction");
      const plan = JSON.parse(sd);
      if (isEditPage) {
        //Edit Transaction page
        const index = this.props.location.state.index;
        if (plan) {
          plan.content[index][0] = planName;
          plan.content[index][1] = this.getSelectedDate(selectedDate);
          plan.content[index][2] = this.getSelectedDate(selectedDate1);
          plan.content[index][3] = this.getFormattedDate();
          plan.content[index][4] = this.getFormattedDate();
          sessionStorage.setItem("transaction", JSON.stringify(plan));
          this.props.navigate("/corporate-co2/transactions");
        }
      } else {
        //Add Transaction page
        plan &&
          plan.content.push([
            this.state.planName,
            this.getSelectedDate(selectedDate),
            this.getSelectedDate(selectedDate1),
            this.getFormattedDate(),
            this.getFormattedDate(),
            "View",
            "Edit"
          ]);
        sessionStorage.setItem("transaction", JSON.stringify(plan));
        this.props.navigate("/corporate-co2/transactions");
      }
    }
  };

  customInput = () => {
    return <input style={this.customInputStyle} />;
  };

  fromTo = (selectedDate, selectedDate1) => {
    // console.log('fromTo: ' + selectedDate)
    const { isViewPage } = this.state;
    return (
      <>
        <div className="bold ml20">1. Choose transaction period:</div>
        <div className="custom-row">
          <p className="bold ml20 mr20 mt20 mb20">From:</p>
          <div className="date-picker">
            <DatePicker
              customInput={this.customInput()}
              dateFormat="dd/MM/yyy"
              selected={selectedDate}
              onChange={date => {
                this.setState({ selectedDate: date });
                // getSelectedDate(date, selectedSegment)
              }}
              disabled={isViewPage ? true : false}
            />
          </div>
          <p className="bold ml20 mr20 mt20 mb20">To:</p>
          <div className="date-picker">
            <DatePicker
              dateFormat="dd/MM/yyy"
              customInput={this.customInput()}
              selected={selectedDate1}
              onChange={date => {
                this.setState({ selectedDate1: date });
                // getSelectedDate(date, selectedSegment)
              }}
              disabled={isViewPage ? true : false}
            />
          </div>
        </div>
      </>
    );
  };

  uploadTransaction = isViewPage => {
    return (
      <div className="custom-row">
        <div className="bold ml20 mr20 mt20 mb20">2. Upload Transactions:</div>
        <div className="ml20">
          <button
            disabled={isViewPage ? true : false}
            className="modal-upload mr20"
            onClick={() => {
              /* console.log('this.fileInput ', this.fileInput.click()) */
            }}
          >
            Upload
          </button>
          <input
            className="modal-picker spacing"
            type="file"
            accept=".csv"
            onChange={e => this.onFileSelect(e)}
            ref={ref => (this.fileInput = ref)}
          />
        </div>
      </div>
    );
  };

  onFileSelect = async e => {
    let files = e.target.files;
    // console.log('file-reader ', files)
    if (files.length > 0) {
      const str = await files[0].text();
      // console.log('file-reader ', files)
      this.setState({
        fileName: files[0].name,
        fileSize: files[0].size,
        csvStr: str
      });
    }
  };

  onUpload = () => {
    const random = Math.floor(Math.random() * (70 - 30 + 1)) + 30;
    this.setState({ percentage: random }, () => {
      setTimeout(() => {
        this.setState({ percentage: 100 }, () => {
          if (this.state.csvStr !== "") {
            this.getPlanListFromCSV(this.state.csvStr);
          }
          setTimeout(() => {
            this.onCancelUpload();
          }, 800);
        });
      }, 800);
    });
  };

  onCancelUpload = () => {
    this.fileInput.value = "";
    this.setState({ fileName: "", percentage: "", fileSize: "" });
  };

  renderUploadBox = () => {
    const { fileName, fileSize, percentage } = this.state;
    return (
      <div className="fade-bg">
        <div className="border-line"></div>
        <div className="custom-row pt20 pb20 pl20 pr20">
          {/* File Name */}
          <div>{fileName}</div>

          {/* Size and progress bar */}
          <div className="spacing width200">
            <div className="text-center mb8">{this.niceBytes(fileSize)}</div>
            <ProgressBar animated now={percentage} label={`${percentage}%`} />
          </div>

          {/* Buttons */}
          <div className="custom-row spacing">
            <button class="standard-account-btn" onClick={this.onUpload}>
              <i class="fa fa-upload"></i>
              <span>Start</span>
            </button>

            <button class="upload-cancel ml10" onClick={this.onCancelUpload}>
              <i class="fa fa-ban "></i>
              <span>Cancel</span>
            </button>
          </div>
        </div>
        <div className="border-line mb20"></div>
      </div>
    );
  };

  niceBytes = x => {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    //include a decimal point and a tenths-place digit if presenting
    //less than ten of KB or greater units
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  };

  str2ab = str => {
    let buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    let bufView = new Uint16Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  };

  getPlanListFromCSV = str => {
    readString(str, {
      complete: res => {
        // var planList = this.state.individualPlanList
        // res.data.forEach((r, index) => {
        //     // r.length > 3 && (r[4] === 'FALSE' || r[4] === 'TRUE') && planList.push({ value: (r[0] + ' ' + r[1]), label: (r[0] + ' ' + r[1]), isOptionDisabled: r[4] })
        //     let decoder = new TextDecoder('iso-8859-1');
        //     const txt = decoder.decode(this.str2ab((r[0] + ' ' + r[1])));
        //     index > 3 && (parseInt(r[0]) && planList.push({ value: txt, label: txt, isOptionDisabled: false }))
        // })
        // this.setState({ individualPlanList: planList })
        // console.log('readString1222VISHAL', planList)
      }
    });
  };

  nameSection = (planName, IsEditPage, isViewPage) => {
    return (
      <div className="custom-row">
        <div className="bold ml20 mr20 mt20 mb20">
          3. Save your transactions:{" "}
        </div>
        <input
          placeholder="Enter Name"
          className={"modal-name-tf"}
          name="Name"
          value={planName}
          disabled={isViewPage ? true : false}
          onChange={e =>
            this.setState({ planName: e.target.value, isNameEmpty: false })
          }
        />
        {isViewPage ? (
          ""
        ) : (
          <button
            className="standard-account-btn spacing mr20"
            onClick={this.onSaveTap}
          >
            {IsEditPage ? "Update" : "Save"}
          </button>
        )}
      </div>
    );
  };

  render() {
    const {
      selectedDate,
      selectedDate1,
      planName,
      isEditPage,
      isViewPage,
      fileName
    } = this.state;
    // console.log("render")
    return (
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <h3 className="card-title">
            <span className="card-label font-weight-bolder text-dark">
              Add Transactions
            </span>
          </h3>
          <div className="card-toolbar"></div>
        </div>
        {this.fromTo(selectedDate, selectedDate1)}
        {this.uploadTransaction(isViewPage)}
        {fileName !== "" && this.renderUploadBox()}
        {this.nameSection(planName, isEditPage, isViewPage)}
        {/* <div className="col-sm-12 mt20 mb20">Fabeke is verified by Energistyrelsen to automatically get electricity data of buildings from Datahub. You can automatically add your buildings if you click GO TO DATAHUB and grant Global Goals access to your buildings in Datahub.</div>

                <button className="datahub-btn ml14 mr14 mb20 pl20 pr20" onClick={this.goToDatahub} >GO TO DATAHUB</button> */}
      </div>
    );
  }
}

export default withRouter(AddTransactions);
