import { Box } from "@mui/material";
import { useTextdomainContext, useUser } from "app/providers";
import React from "react";
import { Link } from "react-router-dom";

export function CustomerSelector() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { user } = useUser();

  const { currentTeam } = user || {};

  return (
    <Box sx={{ ml: 2 }}>
      <Link to={"/user-profile/customers"}>
        {!currentTeam || currentTeam === "null"
          ? gettext("Select Company")
          : currentTeam}
      </Link>
    </Box>
  );
}
