import React, { useEffect, useContext } from "react";
import { Typography, Button, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useAuth, useTextdomainContext } from "app/providers";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LayoutSplashScreen } from "_metronic/layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useVerifyUserByToken, useSendWelcomeEmail } from "./hooks";

export function ConfirmEmailRedirectFlow() {
  const { isAuthenticated } = useAuth();

  const { TextdomainContext } = useTextdomainContext();

  const { enqueueSnackbar } = useSnackbar();

  const { gettext } = useContext(TextdomainContext);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const token = searchParams.get("token");

  const { sendEmail } = useSendWelcomeEmail(
    () => {},
    () => {}
  );

  const { verify, isConfirmed, email } = useVerifyUserByToken(
    () => {},
    () => {
      enqueueSnackbar("Failed to verify", {
        variant: "error"
      });
      navigate("/auth/login");
    }
  );

  useEffect(() => {
    if (token) {
      verify(token);
    }
  }, [verify, token]);

  useEffect(() => {
    if (email) {
      sendEmail(email);
    }
  }, [sendEmail, email]);

  const handleClick = () => {
    if (isAuthenticated) {
      navigate("/");
    } else {
      window.open("https://www.fabeke.com/", "_self");
    }
  };

  return isConfirmed ? (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        marginTop: "100px"
      }}
    >
      <Grid item>
        <CheckCircleIcon
          color="primary"
          sx={{
            width: "64px",
            height: "64px"
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          sx={{
            fontSize: 36,
            fontWeight: 700,
            lineheight: 24
          }}
        >
          {gettext("Verification Successful")}
        </Typography>
      </Grid>
      <Grid item direction="column" alignItems="center" justify="center">
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 500,
            color: "#52525B",
            lineheight: 14
          }}
        >
          {gettext(
            "Congratulation, you’ve chosen the best CO2 accounting platform."
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          sx={{
            mt: -2,
            fontSize: 18,
            fontWeight: 500,
            color: "#52525B",
            lineheight: 14
          }}
        >
          {gettext("You can continue to use fabeke services now. ")}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{
            backgroundColor: "#27272A",
            "&:hover": {
              backgroundColor: "#27272A"
            }
          }}
          variant="contained"
          size="large"
          onClick={handleClick}
          // onClick={() =>
          //   isAuthenticated ?
          //     navigate("/")
          //     :  window.open("https://www.fabeke.com/", "_self")}
        >
          {gettext("Go to Fabeke")}
        </Button>
      </Grid>
    </Grid>
  ) : (
    <LayoutSplashScreen />
  );
}
