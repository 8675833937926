export const getLineChartOptions = (
  data,
  colors,
  id,
  locale,
  title,
  fontSize
) => {
  return {
    series: data,
    chart: {
      type: "line",
      stacked: false,
      zoom: {
        enabled: false
      },
      // fontFamily: 'Aeonik',
      toolbar: {
        show: false
      }
    },
    colors: colors,
    title: {
      text: title,
      align: "left",
      floating: false,
      style: {
        fontSize: fontSize ? fontSize : "20px",
        fontWeight: 700,
        fontFamily: "Aeonik",
        color: "#27272A"
      }
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "round",
      colors: undefined,
      width: 4,
      dashArray: 0
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      formatter: function(seriesName, opts) {
        const color = opts.w.globals.colors[opts.seriesIndex];
        return `
        <span style="display:flex;flex-direction:row;justify-content:center;align-items:center;align-self:center;gap:5px;;
        padding: 5px 10px;
        border-radius: 25px;
        border: 1px solid ${color};
        background-color: ${color}14;">
        <span style="background-color:${color};width:8px;height:8px;border-radius:50%;"></span>
        <span style="font-size:13px;font-weight:400;" >${seriesName}</span>
        </span>
      `;
      },
      position: "top",
      horizontalAlign: "left",
      offsetY: -15,
      labels: {
        useSeriesColors: true
      },
      markers: {
        width: 0,
        height: 0,
        radius: 0
      },
      itemMargin: {
        horizontal: 4,
        vertical: 0
      }
    },
    markers: {
      hover: {
        size: 8,
        sizeOffset: 3
      }
    },
    xaxis: {
      type: "catagory",
      // categories: data && data.length ? data[0].catagories : [],
      categories:
        data !== undefined && data.length ? data[0]["categories"] : [],
      position: "bottom",
      floating: false,
      labels: {
        show: true,
        rotate: 0,
        hideOverlappingLabels: true,
        // formatter: function(value) {
        //     const date = new Date(value);
        //     return date.toLocaleDateString(locale, {
        //         month: "short",
        //         year: "numeric",
        //     });
        // },
        style: {
          colors: "#A1A1AA",
          fontSize: "12px",
          fontWeight: 400
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        show: false
      },
      tooltip: {
        enabled: true,
        offsetY: -10,
        formatter: function(val, opts) {
          return null;
        }
      }
    },
    yaxis: {
      floating: false,
      decimalsInFloat: 2,
      forceNiceScale: true,
      labels: {
        show: true,
        align: "left",
        offsetX: -5,
        formatter: function(value) {
          const numValue = Number(value);
          // const newStringValue = numValue.toFixed(2).toLocaleString();
          const newStringValue = numValue
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          const updatedValue = newStringValue
            .replace(/\./g, "#")
            .replace(/,/g, ".")
            .replace(/#/g, ",");
          // if (value >= 1000000000000) {
          //   return Math.round(value / 1000000000000) + "T";
          // } else if (value >= 1000000000) {
          //   return Math.round(value / 1000000000) + "B";
          // } else if (value >= 1000000) {
          //   return Math.round(value / 1000000) + "M";
          // } else if (value >= 1000) {
          //   return Math.round(value / 1000) + "K";
          // } else if (locale === "da") {
          //   return updatedValue;
          // }

          if (locale === "da") {
            return updatedValue;
          }
          return value.toFixed(2);
        },
        style: {
          colors: "#A1A1AA",
          fontSize: "12px",
          fontWeight: 400
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        show: false
      }
    },
    tooltip: {
      followCursor: true,
      shared: true,
      y: {
        formatter: function(value) {
          // console.log('local',locale)
          // return value.toLocaleString();
          if (locale === "en") {
            const numValue = Number(value);
            const newStringValue = numValue.toLocaleString();
            return newStringValue;
          } else if (locale === "da") {
            const numValue = Number(value);
            const newStringValue = numValue.toLocaleString();
            const updatedValue = newStringValue
              .replace(/\./g, "#")
              .replace(/,/g, ".")
              .replace(/#/g, ",");
            return updatedValue;
          } else {
            const numValue = Number(value);
            const newStringValue = numValue.toLocaleString();
            return newStringValue;
          }
        }
      }
    },
    grid: {
      show: true,
      borderColor: "#D4D4D8",
      strokeDashArray: 6,
      position: "back",
      xaxis: {
        lines: {
          show: false
        }
      }
    }
  };
  // return {
  //   series: data,
  //   colors: colorSettings,
  //   chart: {
  //     id: id,
  //     type: "line",
  //     toolbar: {
  //       show: false,
  //       tools: {
  //         download: false
  //       }
  //     }
  //   },
  //   // plotOptions: {
  //   //     bar: {
  //   //         horizontal: false,
  //   //         columnWidth: "55%",
  //   //         endingShape: "rounded",
  //   //     },
  //   // },
  //   dataLabels: {
  //     enabled: false
  //   },
  //   stroke: {
  //     curve: "smooth"
  //   },

  //   xaxis: {
  //     type: "category",
  //     axisTicks: {
  //       show: false
  //     },
  //     axisBorder: {
  //       show: false
  //     },
  //     categories: data !== undefined && data.length ? data[0]["categories"] : []
  //   },
  //   // responsive: [
  //   //     {
  //   //         breakpoint: 1380,
  //   //         options: {
  //   //             legend: {
  //   //                 formatter: function(seriesName, opts) {
  //   //                     return (
  //   //                         "<div>" +
  //   //                         seriesName +
  //   //                         "</div>" +
  //   //                         '<div style="color:black";>' +
  //   //                         opts.w.globals.seriesTotals[opts.seriesIndex] +
  //   //                         "</div>"
  //   //                     );
  //   //                 },
  //   //                 offsetY: 5,
  //   //                 fontSize: "14px",
  //   //                 height: 50,
  //   //                 itemMargin: {
  //   //                     horizontal: 4,
  //   //                     vertical: 0,
  //   //                 },
  //   //             },
  //   //             title: {
  //   //                 style: {
  //   //                     fontSize: "16px",
  //   //                 },
  //   //             },
  //   //         },
  //   //     },
  //   // ],
  //   grid: {
  //     show: true,
  //     borderColor: "#90A4AE",
  //     strokeDashArray: 10,
  //     position: "back",
  //     xaxis: {
  //       lines: {
  //         show: false
  //       }
  //     },
  //     yaxis: {
  //       lines: {
  //         show: true
  //       }
  //     },
  //     row: {
  //       colors: undefined,
  //       opacity: 0.5
  //     },
  //     column: {
  //       colors: undefined,
  //       opacity: 0.5
  //     },
  //     padding: {
  //       top: 0,
  //       right: 15,
  //       bottom: 0,
  //       left: 15
  //     }
  //   },
  //   yaxis: {
  //     // title: {
  //     //     text: "$ (thousands)",
  //     // },
  //     strokeDashArray: 2
  //   },
  //   fill: {
  //     opacity: 1
  //   },
  //   legend: {
  //     show: false
  //   },
  //   // legend: {
  //   //     position: "top",
  //   //     horizontalAlign: "left",
  //   //     floating: true,
  //   //     margin: 10,
  //   //     offsetY: -2,
  //   //     // offsetX: -5,
  //   //     // formatter: function(seriesName, opts) {
  //   //     //     return `${(<Chip label={seriesName} />)}`;
  //   //     // },
  //   // },
  //   tooltip: {
  //     shared: false
  //     // y: {
  //     //   formatter: function(val) {
  //     //     return val;
  //     //   }
  //     // }
  //   }
  // };
};
