import { Button, Drawer, Grid, Paper, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { FieldColorInput } from "./components";

export function ColorPicker({
  open,
  gettext,
  onClose,
  buildingId = null,
  colorSettings,
  update,
  updating,
  targetBuildingColors = true,
  settingsKey = null,
  shade = "shade1",
  updateUserSettings = false
}) {
  const [color, setColor] = useState("#b32aa9");

  const [colors, setColors] = useState({});

  useEffect(() => {
    Boolean(colorSettings) && setColors(cloneDeep(colorSettings));
  }, [colorSettings]);

  const handleSaveBuilding = useCallback(() => {
    update({
      buildingId,
      data: {
        colorSetting: colors
      }
    });
  }, [update, buildingId, colors]);

  const handleSaveSettings = useCallback(() => {
    if (settingsKey === "graph-colors") {
      let shadeColors = {};
      Object.keys(colors).forEach(col => {
        shadeColors[col] = { [shade]: colors[col] };
      });
      update({
        key: settingsKey,
        settings: JSON.stringify(shadeColors),
        updateUserSettings: updateUserSettings
      });
    } else {
      update({
        key: settingsKey,
        settings: JSON.stringify(colors),
        updateUserSettings: updateUserSettings
      });
    }
  }, [update, settingsKey, colors, shade, updateUserSettings]);

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { minWidth: 300, width: "30%", padding: 4 }
      }}
      sx={{
        zIndex: theme => theme.zIndex.appBar + 1
      }}
    >
      <Stack spacing={4} sx={{ height: "100%" }}>
        <Paper
          elevation={2}
          sx={{
            borderRadius: 4,
            padding: 2,
            [`& .react-colorful__hue`]: {
              height: "8px",
              borderRadius: "5px",
              marginBottom: 2
            },
            [`& .react-colorful__hue-pointer`]: {
              width: "20px",
              height: "20px"
            },
            [`& .react-colorful__saturation`]: {
              borderRadius: 2,
              marginBottom: 2
            },
            [`& .react-colorful`]: { width: "auto" }
          }}
        >
          <HexColorPicker color={color} onChange={setColor} />
        </Paper>

        <Paper
          elevation={2}
          sx={{
            height: "100%",
            overflow: "auto",
            padding: 3,
            borderRadius: 4,
            "&::-webkit-scrollbar": {
              width: "0.4em"
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.3)",
              borderRadius: 8
            }
          }}
        >
          {Object.keys(colors).map((fieldName, index) => (
            <FieldColorInput
              key={index}
              colorpickerValue={color}
              setColors={setColors}
              fieldName={fieldName}
              field={colors[fieldName]}
              shades={settingsKey === "graph-colors"}
              gettext={gettext}
            />
          ))}
        </Paper>

        <Paper elevation={2} sx={{ borderRadius: 4, padding: 2 }}>
          <Grid container spacing={4}>
            <Grid item xs={6} textAlign="center">
              <Button
                variant="outlined"
                fullWidth
                sx={{ borderRadius: 2 }}
                onClick={() => onClose()}
              >
                {gettext("Cancel")}
              </Button>
            </Grid>

            <Grid item xs={6} textAlign="center">
              <LoadingButton
                variant="contained"
                loading={updating}
                fullWidth
                sx={{ borderRadius: 2 }}
                onClick={() => {
                  if (targetBuildingColors) handleSaveBuilding();
                  else handleSaveSettings();
                }}
              >
                {gettext("Save")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      </Stack>
    </Drawer>
  );
}
