import { gql } from "@apollo/client";

export const UPDATE_USER_PERMISSION = gql`
  mutation comUpdateUserPermissions(
    $customerId: String!
    $permissionData: PermissionDataType
    $userEmail: String!
  ) {
    comUpdateUserPermissions(
      customerId: $customerId
      permissionData: $permissionData
      userEmail: $userEmail
    ) {
      success
      message
    }
  }
`;
