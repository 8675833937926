import { gql } from "@apollo/client";
import { ME_TYPE_FRAGMENT } from "GraphQL/Fragments";

export const SUBMIT_CUSTOMER = gql`
  ${ME_TYPE_FRAGMENT}
  mutation comCustomerUpdateRegisterFlow(
    $customer: RegisterFlowCountryInput
    $notRegisterFlow: Boolean
  ) {
    comCustomerUpdateRegisterFlow(
      customer: $customer
      notRegisterFlow: $notRegisterFlow
    ) {
      success
      message
      user {
        ...MeType
      }
    }
  }
`;
