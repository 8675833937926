import { Chip, Stack, Table, TableRow, Typography } from "@mui/material";
import { useTextdomainContext, useLang } from "app/providers";
import React from "react";
import {
  BodyTableCell,
  BorderedTableBody,
  BorderedTableHead,
  HeadTableCell,
  StyledTableRow
} from "../../components";

export function BuildingTable({ label, buildingData, currentYear, prevYear }) {
  const { TextdomainContext } = useTextdomainContext();

  const locale = useLang();

  const { gettext } = React.useContext(TextdomainContext);

  const getRound = React.useCallback(
    num => {
      // return num.toFixed(2);

      if (locale === "da") {
        // const newStringValue = num
        //   .toFixed(2)
        //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

        const newStringValue = Number(num)
          ? parseFloat(num)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          : num.toFixed(2);

        return newStringValue
          .replace(/\./g, "#")
          .replace(/,/g, ".")
          .replace(/#/g, ",");
      } else {
        return Number(num)
          ? parseFloat(num)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          : num.toFixed(2);
      }
    },
    [locale]
  );

  return (
    <Stack spacing={4} width={"100%"} alignItems={"center"}>
      <Stack spacing={2} alignItems={"center"} color={"secondary"}>
        <Typography fontSize={12} fontWeight={500}>
          {label}
        </Typography>

        <Chip
          color={"primary"}
          size="small"
          label={
            <Typography fontSize={12} fontWeight={400}>
              {buildingData?.address}
            </Typography>
          }
        />
      </Stack>

      <Table aria-label="building one table">
        <BorderedTableHead>
          <TableRow>
            <HeadTableCell align="center">
              {gettext("kWh")}
              <br />
              {currentYear}
            </HeadTableCell>
            <HeadTableCell align="center">
              {gettext("kWh")}
              <br />
              {prevYear}
            </HeadTableCell>
            <HeadTableCell align="center">
              {gettext("CO₂")}
              <br />
              {currentYear}
            </HeadTableCell>
            <HeadTableCell align="center">
              {gettext("CO₂")}
              <br />
              {prevYear}
            </HeadTableCell>
          </TableRow>
        </BorderedTableHead>
        <BorderedTableBody>
          {buildingData?.data?.map(calanderData => (
            <StyledTableRow key={calanderData?.currentData?.ts}>
              <BodyTableCell align="center">
                {getRound(calanderData?.currentData?.consumptionV || 0)}
              </BodyTableCell>
              <BodyTableCell align="center">
                {getRound(calanderData?.previousData?.consumptionV || 0)}
              </BodyTableCell>
              <BodyTableCell align="center">
                {getRound(calanderData?.currentData?.consumptionCo2 || 0)}
              </BodyTableCell>
              <BodyTableCell align="center">
                {getRound(calanderData?.previousData?.consumptionCo2 || 0)}
              </BodyTableCell>
            </StyledTableRow>
          ))}
        </BorderedTableBody>
      </Table>
    </Stack>
  );
}
