import React, { useState } from "react";
import { CustomerListTable } from "app/features";
// import { AddCompanyTab } from "app/modules/RegistrationFlow/tabs";
import { useUser } from "app/providers";
import { NewCustomerForm } from "app/features/NewCustomerForm";
// import { Button } from "react-bootstrap";
// import { FaExchangeAlt } from "react-icons/fa";
// import { GoBack } from "app/components";

export function UserCustomersPage() {
  const { user } = useUser();

  const [userName, setUserName] = useState(null);
  // const [formVal, setFormVal] = useState("N");

  // const toggleCustomerForm = () => {
  //   setFormVal(formVal === "N" ? "E" : "N");
  // };

  return (
    <>
      {/* <GoBack /> */}

      {/* <div className="btn-group mt-3 mb-3">
        <button
          className="btn btn-primary"
          onClick={toggleCustomerForm}
          style={{ fontSize: "1.4rem" }}
        >
          <FaExchangeAlt size={18} style={{ margin: "0 7px 5px 0" }} />
          {formVal !== "N"
            ? "Switch to new customer"
            : "Switch to add customer"}
        </button>
      </div>
      {formVal !== "N" ? (
        <AddCustomerForm userId={user.id} userName={userName} />
      ) : ( */}
      <NewCustomerForm userId={user.id} userName={userName} />
      {/* )} */}
      <br />
      <CustomerListTable userId={user.id} setUserName={setUserName} />
    </>
  );
}
