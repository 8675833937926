import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef
} from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Toast from "app/components/toast/Toast";

import {
  Alert,
  AlertTitle,
  Collapse,
  IconButton,
  Tooltip
} from "@mui/material";
import { useLang, useUser, useTextdomainContext } from "app/providers";
import { useCountries, useTimezones, useUpdateProfile } from "hooks";
import { Select } from "app/components";
import client from "services/apiClient";
import { Spinner, Modal, Button } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteUserDocument,
  GetUserDocument
} from "app/components/AppBar/commands";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

function PersonaInformation(props) {
  const inputRef = useRef(null);
  const documentOneRef = useRef(null);
  const documentTwoRef = useRef(null);
  const documentThreeRef = useRef(null);
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);
  // Fields
  const languages = [
    {
      value: "en",
      label: "English",
      flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg")
    },
    {
      value: "de",
      label: "German",
      flag: toAbsoluteUrl("/media/svg/flags/162-germany.svg")
    },
    {
      value: "da",
      label: "Danish",
      flag: toAbsoluteUrl("/media/svg/flags/174-denmark.svg")
    },
    {
      value: "es",
      label: "Spanish",
      flag: toAbsoluteUrl("/media/svg/flags/016-spain.svg")
    },
    {
      value: "sv",
      label: "Swedish",
      flag: toAbsoluteUrl("/media/svg/flags/184-sweden.svg")
    },
    {
      value: "no",
      label: "Norwegian",
      flag: toAbsoluteUrl("/media/svg/flags/143-norway.svg")
    }
  ];
  const lang = useLang();

  const currentLanguage = languages.find(x => x.value === lang);

  const [loading, setloading] = useState(false);

  const [dataUri, setDataUri] = useState(undefined);

  const [toastList, updateToastList] = useState([]);

  const [photoChanged, setPhotoChanged] = useState(false);

  const [documentID, setDocumentID] = useState("");
  const [documentOneID, setDocumentOneID] = useState("");
  const [documentTwoID, setDocumentTwoID] = useState(0);
  const [documentThreeID, setDocumentThreeID] = useState(0);
  const [allDocuments, setAllDocuments] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentTwoLoading, setDocumentTwoLoading] = useState(false);
  const [documentThreeLoading, setDocumentThreeLoading] = useState(false);

  const { user } = useUser();

  const [facebookLink, setFacebookLink] = useState(user.facebookLink);

  const [twitterLink, setTwitterLink] = useState(user.twitterLink);

  const [errorMessage, setErrorMessage] = useState(false);

  const [successMessage, setSuccessMessage] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteUserDocument, documentDeleteLoading] = useMutation(
    DeleteUserDocument,
    {
      onCompleted() {
        if (documentOneID === documentID) {
          const toastArray = [
            {
              title: gettext(` Document Deleted Successfully`),
              type: "success"
            }
          ];
          updateToastList(toastArray);
          setDeleteModal(false);
          setDocumentID(0);
          setDocumentOne({});
        } else if (documentTwoID === documentOneID) {
          const toastArray = [
            {
              title: gettext(` Document Deleted Successfully`),
              type: "success"
            }
          ];
          updateToastList(toastArray);
          setDeleteModal(false);
          setDocumentTwoID(0);
          setDocumentTwo({});
        } else if (documentThreeID === documentOneID) {
          const toastArray = [
            {
              title: gettext(` Document Deleted Successfully`),
              type: "success"
            }
          ];
          updateToastList(toastArray);
          setDeleteModal(false);
          setDocumentThreeID(0);
          setDocumentThree({});
        } else {
          // const document =
          //   allDocuments &&
          //   allDocuments?.userDocuments.find(
          //     e => JSON.stringify(e?.id) === JSON.stringify(documentOneID)
          //   );
          // const name = document?.fileName.split("_", 4);
          const toastArray = [
            {
              title: gettext(` Document Deleted Successfully`),
              type: "success"
            }
          ];
          updateToastList(toastArray);
          setDeleteModal(false);
        }
      },
      onError() {},
      refetchQueries: ["userDocuments"]
    }
  );

  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [documentOne, setDocumentOne] = useState({});
  const [documentTwo, setDocumentTwo] = useState({});
  const [documentThree, setDocumentThree] = useState({});
  const [countryValue, setCountryValue] = useState();
  const { REACT_APP_API_URL_GRAPHQL } = process.env;

  const [timezoneValue, setTimezoneValue] = useState();

  const { timezones, loading: timezoneLoading } = useTimezones();

  const { countries, loading: countriesLoading } = useCountries();

  const handleCountryChange = useCallback(
    option => {
      setCountryValue(option);

      setTimezoneValue(null);

      setTimezoneOptions(
        timezones.filter(timezone => {
          return option.code === timezone.countryCode;
        })
      );
    },
    [timezones]
  );

  useEffect(() => {
    if (Boolean(user)) {
      setCountryValue(
        countries?.find(country => {
          return user.countryCode === country.code;
        })
      );

      setTimezoneValue(
        timezones?.find(timezone => {
          return user.timezone === timezone.zoneName;
        })
      );
    }
  }, [user, countries, timezones]);

  //   Get All User Documents //
  const { data, refetch } = useQuery(GetUserDocument);

  useEffect(() => {
    setAllDocuments(data && data);
    refetch();
  }, [data, refetch]);

  const initialValues = {
    photo: user.profilePhotoUrl || "",
    name: user.name,
    email: user.email,
    lang: user.lang
  };

  const Schema = Yup.object().shape({
    photo: Yup.string(),
    name: Yup.string()
      .trim()
      .required("Name is required"),
    email: Yup.string()
      .email("Wrong email format")
      .required("Email is required")
  });

  const langChange = (field, action) => {
    formik.setFieldValue("lang", field.value);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const { updateProfile } = useUpdateProfile(
    () => {
      formik.setSubmitting(false);
      setSuccessMessage(true);
      setTimeout(() => {
        setSuccessMessage(false);
      }, 5000);
    },
    () => {
      setloading(false);
      setErrorMessage(true);
      formik.setSubmitting(false);
      setTimeout(() => {
        setErrorMessage(false);
      }, 5000);
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      updateProfile({
        file:
          dataUri !== ""
            ? dataUri?.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
            : "",
        name: values.name,
        lang: values.lang,
        countryCode: countryValue?.code,
        timezone: timezoneValue?.zoneName,
        facebookLink,
        twitterLink
      });

      localStorage.setItem(
        I18N_CONFIG_KEY,
        JSON.stringify({ selectedLang: values.lang })
      );
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    }
  });

  useEffect(() => {
    if (user) {
      formik.initialValues.photo = user.profilePhotoPath || "";
      formik.initialValues.name = user.name;
      formik.initialValues.email = user.email;
      formik.initialValues.lang = user.lang;
      setFacebookLink(user.facebookLink);
      setTwitterLink(user.twitterLink);
    }
  }, [user, formik.initialValues]);

  const removePic = () => {
    inputRef.current.value = null;
    setDataUri("");
    setPhotoChanged(true);
  };

  const changePic = event => {
    const fileToDataUri = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });

    fileToDataUri(event.currentTarget.files[0]).then(dataUri => {
      setPhotoChanged(true);
      setDataUri(dataUri);
    });
  };

  // Documents Handling   //

  // const [getUserDocument] = useQuery(GetUserDocument,{
  //   onError() {},
  //   onCompleted(){},
  // })

  const handleUploadDocumentOne = () => {
    documentOneRef.current?.click();
  };
  const handleUploadDocumentTwo = () => {
    documentTwoRef.current?.click();
  };
  const handleUploadDocumentThree = () => {
    documentThreeRef.current?.click();
  };

  const handleFileOne = async e => {
    if (!e.target.files) {
      return;
    } else if (e.target.files[0].size > 5e6) {
      const toastArray = [
        {
          title: gettext("Document Uploading Failed"),
          description: gettext(`Please Select file upto 5MB`),
          type: "error"
        }
      ];
      updateToastList(toastArray);
      return;
    }
    setDocumentOne(e.target.files[0]);
    const formData = new FormData();
    try {
      const query = `mutation uploadUserDocument {
            uploadUserDocument {
              success
              message
              documentId
            }
          }`;
      formData.append("query", query);
      formData.append("file", e.target.files[0]);
      setDocumentLoading(true);
      const response = await client.post(REACT_APP_API_URL_GRAPHQL, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (response.status === 200) {
        const toastArray = [
          {
            title: gettext("Document Uploaded Successfully"),
            type: "success"
          }
        ];
        updateToastList(toastArray);
        setDocumentID(response?.data?.data?.uploadUserDocument?.documentId);
        await refetch();
        setDocumentLoading(false);
      } else if (response.status === 400) {
        const toastArray = [
          {
            title: gettext("Document Uploading Failed"),
            description: gettext(`Some Error Occurred`),
            type: "error"
          }
        ];
        updateToastList(toastArray);
        setDocumentLoading(false);
      }
    } catch (error) {
      const toastArray = [
        {
          title: gettext("Document Uploading Failed"),
          description: gettext(`Some Error Occurred`),
          type: "error"
        }
      ];
      updateToastList(toastArray);
      setDocumentLoading(false);
    }
  };
  const handleFileTwo = async e => {
    if (!e.target.files) {
      return;
    } else if (e.target.files[0].size > 5e6) {
      const toastArray = [
        {
          title: gettext("Document Uploading Failed"),
          description: gettext(`Please Select file upto 5MB`),
          type: "error"
        }
      ];
      updateToastList(toastArray);
      return;
    }

    setDocumentTwo(e.target.files[0]);
    const formData = new FormData();
    try {
      const query = `mutation uploadUserDocument {
            uploadUserDocument {
              success
              message
              documentId
            }
          }`;
      formData.append("query", query);
      formData.append("file", e.target.files[0]);
      setDocumentTwoLoading(true);
      const response = await client.post(REACT_APP_API_URL_GRAPHQL, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (response.status === 200) {
        const toastArray = [
          {
            title: gettext("Document Uploaded Successfully"),
            type: "success"
          }
        ];
        updateToastList(toastArray);
        setDocumentTwoID(response?.data?.data?.uploadUserDocument?.documentId);
        await refetch();
        setDocumentTwoLoading(false);
      } else if (response.status === 400) {
        const toastArray = [
          {
            title: gettext("Document Uploading Failed"),
            description: gettext(`Some Error Occurred`),
            type: "error"
          }
        ];
        updateToastList(toastArray);
        setDocumentTwoLoading(false);
      }
    } catch (error) {
      const toastArray = [
        {
          title: gettext("Document Uploading Failed"),
          description: gettext(`Some Error Occurred`),
          type: "error"
        }
      ];
      updateToastList(toastArray);
      setDocumentTwoLoading(false);
    }
  };
  const handleFileThree = async e => {
    if (!e.target.files) {
      return;
    } else if (e.target.files[0].size > 5e6) {
      const toastArray = [
        {
          title: gettext("Document Uploading Failed"),
          description: gettext(`Please Select file upto 5MB`),
          type: "error"
        }
      ];
      updateToastList(toastArray);
      return;
    }

    setDocumentThree(e.target.files[0]);
    const formData = new FormData();
    try {
      const query = `mutation uploadUserDocument {
            uploadUserDocument {
              success
              message
              documentId
            }
          }`;
      formData.append("query", query);
      formData.append("file", e.target.files[0]);
      setDocumentThreeLoading(true);
      const response = await client.post(REACT_APP_API_URL_GRAPHQL, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (response.status === 200) {
        const toastArray = [
          {
            title: gettext("Document Uploaded Successfully"),
            type: "success"
          }
        ];
        updateToastList(toastArray);
        setDocumentThreeID(
          response?.data?.data?.uploadUserDocument?.documentId
        );
        await refetch();
        setDocumentThreeLoading(false);
      } else if (response.status === 400) {
        const toastArray = [
          {
            title: gettext("Document Uploading Failed"),
            description: gettext(`Some Error Occurred`),
            type: "error"
          }
        ];
        updateToastList(toastArray);
        setDocumentThree(false);
        setDocumentThreeLoading(false);
      }
    } catch (error) {
      const toastArray = [
        {
          title: gettext("Document Uploading Failed"),
          description: gettext(`Some Error Occurred`),
          type: "error"
        }
      ];
      updateToastList(toastArray);
      setDocumentThree(false);
      setDocumentThreeLoading(false);
    }
  };
  const openDeleteModal = id => {
    setDeleteModal(true);
    setDocumentOneID(id);
  };
  const deleteDocument = id => {
    deleteUserDocument({
      variables: {
        documentId: id
      }
    });
  };
  const showDocument = ID => {
    const document =
      allDocuments &&
      allDocuments?.userDocuments.find(
        e => JSON.stringify(e?.id) === JSON.stringify(ID)
      );
    if (document?.fileUrl) {
      window.open(document?.fileUrl, "_blank");
    }
  };

  return (
    <form
      className="card card-custom card-stretch"
      onSubmit={formik.handleSubmit}
      encType="multipart/form-data"
    >
      {loading && <ModalProgressBar />}
      {/* begin::Header */}
      <div className="card card-custom gutter-b example example-compact">
        <div className="table-div">
          <div className="table-title">
            <h3>{gettext("Profile")}</h3>
            {/* <div className="card-toolbar">
              <button
                type="submit"
                className="btn btn-success mr-2"
                disabled={
                  formik.isSubmitting || (formik.touched && !formik.isValid)
                }
              >
                {gettext("Save Changes")}
                {formik.isSubmitting}
              </button>
              <Link
                to="/user-profile/personal-information"
                className="btn btn-secondary"
              >
                {gettext("Cancel")}
              </Link>
            </div> */}
            <span className="font-size-sm w-100">
              {gettext("My information")}
            </span>
          </div>
          {/* end::Header */}
          {/* begin::Form */}
          <div className="form">
            {/* begin::Body */}
            <div className="card-body">
              {
                <Collapse in={errorMessage}>
                  <Alert severity="error">
                    <AlertTitle>{gettext("Error")}</AlertTitle>
                    {gettext("There was a problem updating personal Info")}
                  </Alert>
                </Collapse>
              }
              {
                <Collapse in={successMessage}>
                  <Alert severity="success">
                    <AlertTitle>{gettext("Success")}</AlertTitle>
                    {gettext("Personal information update successfully")}
                  </Alert>
                </Collapse>
              }
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {gettext("Picture")}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div
                    className="image-input image-input-outline"
                    id="kt_profile_avatar"
                  >
                    <div className="image-input-wrapper overflow-hidden">
                      <img
                        style={{ objectFit: "fill" }}
                        src={photoChanged ? dataUri : user.profilePhotoUrl}
                        alt=""
                      />
                    </div>

                    <label
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar"
                    >
                      <i className="fa fa-pen icon-sm text-muted"></i>
                      <input
                        type="file"
                        name="photo1"
                        id="photo1"
                        accept=".png, .jpg, .jpeg"
                        onChange={changePic}
                        ref={inputRef}
                        disabled={user.demo}
                      />
                      <input
                        type="hidden"
                        name="profile_avatar_remove"
                        disabled={user.demo}
                      />
                    </label>
                    <span
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="cancel"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Cancel avatar"
                    >
                      <i className="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                    <span
                      onClick={removePic}
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="remove"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Remove avatar"
                    >
                      <i className="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                  </div>
                  <span className="form-text text-muted">
                    {gettext("Allowed file types")}: png, jpg, jpeg.
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {gettext("Name")}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    placeholder="Name"
                    readOnly={user.demo}
                    className={`form-control form-control-lg ${getInputClasses(
                      "name"
                    )}`}
                    name="name"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="invalid-feedback">{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {gettext("Language")}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <Select
                    onChange={langChange}
                    readOnly={user.demo}
                    isDisabled={user.demo}
                    options={languages}
                    defaultValue={[
                      {
                        value: currentLanguage.value,
                        label: currentLanguage.label
                      }
                    ]}
                    {...props}
                    {...props.field}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {gettext("Country")}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <Select
                    options={countries}
                    loading={countriesLoading}
                    placeholder="Country"
                    isDisabled={user.demo}
                    value={countryValue}
                    onChange={handleCountryChange}
                    required={true}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.code}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {gettext("Timezone")}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <Select
                    options={timezoneOptions}
                    loading={timezoneLoading}
                    placeholder="Timezone"
                    isDisabled={user.demo}
                    required={true}
                    value={timezoneValue}
                    onChange={option => setTimezoneValue(option)}
                    getOptionLabel={option =>
                      option.zoneName + ` GMT(${option.gmtOffset})`
                    }
                    getOptionValue={option => option.zoneName}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  <span>{gettext("Facebook Link")}</span>
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly={user.demo}
                    onChange={e => setFacebookLink(e.target.value)}
                    value={facebookLink}
                    placeholder={gettext("Facebook Link")}
                    className="social-field"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  <span>{gettext("Twitter Link")}</span>
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    readOnly={user.demo}
                    onChange={e => setTwitterLink(e.target.value)}
                    value={twitterLink}
                    placeholder={gettext("Twitter Link")}
                    className="social-field"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {gettext("Email")}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div className="input-group input-group-lg input-group-solid">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-at"></i>
                      </span>
                    </div>
                    <input
                      type="email"
                      placeholder="Email"
                      className={`form-control form-control-lg form-control-solid ${getInputClasses(
                        "email"
                      )}`}
                      name="email"
                      {...formik.getFieldProps("email")}
                      disabled
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {gettext("Documents")}
                </label>
                <>
                  {allDocuments?.userDocuments?.map(document => {
                    return (
                      <div className="col-lg-2 col-xl-2">
                        <div>
                          <IconButton disabled={user.demo}>
                            <InsertDriveFileIcon fontSize="large" />
                          </IconButton>

                          <div className="row">
                            <Tooltip title={gettext("View Document")} arrow>
                              <IconButton
                                onClick={() => showDocument(document?.id)}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={gettext("Delete Document")} arrow>
                              <IconButton
                                onClick={() => {
                                  openDeleteModal(document?.id);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>

                <>
                  {allDocuments?.userDocuments?.length === 0 && (
                    <div className="col-lg-2 col-xl-2">
                      <div>
                        {!documentOne?.name ? (
                          <Tooltip
                            title={gettext("Upload Document")}
                            arrow
                            sx={{
                              padding: 0,
                              margin: 0
                            }}
                          >
                            <IconButton
                              onClick={handleUploadDocumentOne}
                              disabled={user.demo}
                            >
                              <AddCircleOutlineIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>
                        ) : documentLoading ? (
                          <Spinner animation="border" variant="dark" />
                        ) : (
                          <InsertDriveFileIcon fontSize="large" />
                        )}
                        {documentOne?.name && (
                          <div className="row">
                            <Tooltip title={gettext("View Document")} arrow>
                              <IconButton
                                onClick={() => showDocument(documentID)}
                                disabled={!documentOne?.name || documentLoading}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={gettext("Delete Document")} arrow>
                              <IconButton
                                onClick={() => openDeleteModal(documentID)}
                                disabled={!documentOne?.name || documentLoading}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                        <input
                          type="file"
                          ref={documentOneRef}
                          onChange={handleFileOne}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  )}
                  {allDocuments?.userDocuments?.length === 1 &&
                    !documentLoading && (
                      <div className="col-lg-2 col-xl-2">
                        <div>
                          {!documentTwo?.name ? (
                            <Tooltip
                              title={gettext("Upload Document")}
                              arrow
                              sx={{
                                padding: 0,
                                margin: 0
                              }}
                            >
                              <IconButton onClick={handleUploadDocumentTwo}>
                                <AddCircleOutlineIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          ) : documentTwoLoading ? (
                            <Spinner animation="border" variant="dark" />
                          ) : (
                            <InsertDriveFileIcon fontSize="large" />
                          )}
                          {documentTwo?.name && (
                            <div className="row">
                              <Tooltip title={gettext("View Document")} arrow>
                                <IconButton
                                  onClick={() => showDocument(documentTwoID)}
                                  disabled={
                                    !documentTwo?.name || documentTwoLoading
                                  }
                                >
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={gettext("Delete Document")} arrow>
                                <IconButton
                                  onClick={() => openDeleteModal(documentTwoID)}
                                  disabled={
                                    !documentTwo?.name || documentTwoLoading
                                  }
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                          <input
                            type="file"
                            ref={documentTwoRef}
                            onChange={handleFileTwo}
                            style={{
                              display: "none"
                            }}
                          />
                        </div>
                      </div>
                    )}
                  {allDocuments?.userDocuments?.length === 2 &&
                    !documentTwoLoading && (
                      <div className="col-lg-2 col-xl-2">
                        <div>
                          {!documentThree?.name ? (
                            <Tooltip
                              title={gettext("Upload Document")}
                              arrow
                              sx={{
                                padding: 0,
                                margin: 0
                              }}
                            >
                              <IconButton onClick={handleUploadDocumentThree}>
                                <AddCircleOutlineIcon fontSize="large" />
                              </IconButton>
                            </Tooltip>
                          ) : documentThreeLoading ? (
                            <Spinner animation="border" variant="dark" />
                          ) : (
                            <InsertDriveFileIcon fontSize="large" />
                          )}
                          {documentThree?.name && (
                            <div className="row">
                              <Tooltip title={gettext("View Document")} arrow>
                                <IconButton
                                  onClick={() => showDocument(documentThreeID)}
                                  disabled={
                                    !documentThree?.name || documentThreeLoading
                                  }
                                >
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={gettext("Delete Document")} arrow>
                                <IconButton
                                  onClick={() =>
                                    openDeleteModal(documentThreeID)
                                  }
                                  disabled={
                                    !documentThree?.name || documentThreeLoading
                                  }
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}

                          <input
                            type="file"
                            ref={documentThreeRef}
                            onChange={handleFileThree}
                            style={{
                              display: "none"
                            }}
                          />
                        </div>
                      </div>
                    )}
                </>
              </div>
            </div>
            {/* end::Body */}
          </div>
          {/* end::Form */}
        </div>

        {/* start:footer */}
        <div className="table-footer table-actions-alignment">
          <div className="col-12 p-0">
            <div className="card-toolbar">
              <button
                type="submit"
                className="btn btn-success mr-2"
                disabled={
                  formik.isSubmitting ||
                  (formik.touched && !formik.isValid) ||
                  user.demo
                }
              >
                {gettext("Save Changes")}
                {formik.isSubmitting}
              </button>
              <Link
                to="/user-profile/personal-information"
                className="btn btn-secondary"
              >
                {gettext("Cancel")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        animation
        size="lg"
        aria-labelledby=""
        centered
        style={{ backgroundColor: "transparent" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this document?</Modal.Body>
        <Modal.Footer>
          {documentDeleteLoading?.loading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <Button
              variant="primary"
              onClick={() => deleteDocument(documentOneID)}
            >
              {gettext("Yes")}
            </Button>
          )}
          <Button variant="secondary" onClick={() => setDeleteModal(false)}>
            {gettext("No")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Toast toastList={toastList} />
    </form>
  );
}

export default PersonaInformation;
