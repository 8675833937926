import React, { useContext, useEffect, useState } from "react";
import { useTextdomainContext } from "app/providers";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useDeleteRole } from "../hooks";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import Toast from "app/components/toast/Toast";

export default function DeleteModel({ show, onHide, editableRow }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [toastList, setToastList] = useState([]);
  const [showErrorMessage, setshowErrorMessage] = useState(null);

  const { delete: del, deleting } = useDeleteRole(
    payload => {
      if (
        payload.comDeleteRole.success === "true" &&
        payload.comDeleteRole.success !== "false"
      ) {
        const toastArray = [
          {
            title: gettext("Role Deleted Successfully!"),
            type: "success"
          }
        ];
        setToastList(toastArray);
        setshowErrorMessage(null);
        onHide();
      } else if (payload.comDeleteRole.success === "false") {
        const toastArray = [
          {
            title: gettext("Cannot delete role. Role assigned to user!"),
            type: "error"
          }
        ];
        setToastList(toastArray);
        setshowErrorMessage(null);
        onHide(false);
      } else {
        setshowErrorMessage(payload.comDeleteRole.message);
        onHide(false);
      }
    },
    payload => {
      setshowErrorMessage(payload.comDeleteRole.message);
    }
  );

  const handleDelete = async () => {
    setshowErrorMessage(null);

    del({
      key: editableRow?.key
    });
  };

  useEffect(() => {
    setshowErrorMessage(null);
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        animation
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{gettext("Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Collapse in={showErrorMessage}>
            {showErrorMessage &&
              showErrorMessage.map(msg => (
                <Alert severity="error">
                  <AlertTitle>{gettext("Prohibited")}</AlertTitle>
                  {gettext(msg)}
                </Alert>
              ))}
          </Collapse>
          {gettext("Are you sure you want to Delete")}
          {" " + editableRow.role}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {gettext("Close")}
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            {gettext("Delete")}
          </Button>
        </Modal.Footer>
        {deleting ? (
          <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" variant="light" />
          </div>
        ) : null}
      </Modal>
      <Toast toastList={toastList} />
    </>
  );
}
