export function getDashboardDates() {
  const now = new Date();
  const todayUTC = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
  );
  const threeYearsAgoUTC = new Date(
    Date.UTC(now.getUTCFullYear() - 3, now.getUTCMonth(), now.getUTCDate())
  );

  todayUTC.setUTCHours(0);
  todayUTC.setUTCMinutes(0);
  todayUTC.setUTCSeconds(0);
  todayUTC.setUTCMilliseconds(0);

  threeYearsAgoUTC.setUTCHours(0);
  threeYearsAgoUTC.setUTCMinutes(0);
  threeYearsAgoUTC.setUTCSeconds(0);
  threeYearsAgoUTC.setUTCMilliseconds(0);

  return {
    fromDate: threeYearsAgoUTC.toISOString().slice(0, 19),
    toDate: todayUTC.toISOString().slice(0, 19)
  };
}
