import { gql } from "@apollo/client";

export const GET_CUSTOMERS_BUILDING_METERS = gql`
  query getCustomersBuildingMeters($customerName: String) {
    comCustomerAddresses(customerName: $customerName) {
      address
      electricity {
        meterNo
        label
      }
      heating {
        meterNo
        label
      }
      water {
        meterNo
        label
      }
      gas {
        meterNo
        label
      }
    }
  }
`;
