import { useQuery } from "@apollo/client";
import { SCRAPPER_LIST } from "../commands";

export function useScrapperInfo(search, queryFilter) {
  const { data, loading } = useQuery(SCRAPPER_LIST, {
    variables: {
      search,
      queryFilter
    },
    fetchPolicy: "no-cache"
  });

  return {
    infoList: data?.scrapperInfo || [],
    loading
  };
}
