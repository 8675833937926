import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { IoKeySharp } from "react-icons/io5";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { DataGridComponent } from "app/components";
import EditModel from "./components/EditModel";
import DeleteModel from "./components/DeleteModel";
import AddNewModel from "./components/AddNewModel";
import { useAllRoles } from "./hooks";
import React, { useContext, useState } from "react";
import { useTextdomainContext } from "app/providers";
import Toast from "app/components/toast/Toast";

export function RoleManagementTable(props) {
  const { role } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  //component state
  const [editableRow, seteditableRow] = useState({});

  const [Add_show, setAdd] = useState(false);
  const [edit_show, setedit] = useState(false);

  const [delete_show, setdelete] = useState(false);
  const { allRoles, loading } = useAllRoles(role);
  const [toastList, updateToastList] = useState([]);

  const handleClose_Add = () => setAdd(false);

  const handleShow_Add = () => setAdd(true);

  const handleClose_edit = message => {
    if (message === "success") {
      updateToastList([
        {
          title: "Success",
          description: "Role updated successfully",
          type: "success"
        }
      ]);
    }
    setedit(false);
  };

  const handleShow_edit = () => setedit(true);

  const handleClose_delete = () => setdelete(false);

  const handleShow_delete = () => setdelete(true);

  const navigate = useNavigate();

  function Add_functionality() {
    handleShow_Add();
  }
  function edit_functionality(row) {
    seteditableRow(row);
    handleShow_edit();
  }
  function delete_functionality(row) {
    seteditableRow(row);
    handleShow_delete();
  }

  const gotoPermissinos = row => {
    navigate(
      `/admin/system-role-management/${row.role}/permissions/${row.key}`
    );
  };
  function performAction(row, action) {
    switch (action) {
      case "edit":
        edit_functionality(row);
        break;
      case "delete":
        delete_functionality(row);
        break;
      case "permissions":
        gotoPermissinos(row);
        break;
      default:
        break;
    }
  }
  function columnFormater(params) {
    return (
      <>
        <Tooltip title={gettext("Permissions")} arrow>
          <IconButton onClick={() => gotoPermissinos(params.row)}>
            <IoKeySharp size={20} />
          </IconButton>
        </Tooltip>
        <Tooltip title={gettext("Edit")} arrow>
          <IconButton onClick={() => performAction(params.row, "edit")}>
            <FaUserEdit size={20} />
          </IconButton>
        </Tooltip>
        <Tooltip title={gettext("Delete")} arrow>
          <IconButton onClick={() => performAction(params.row, "delete")}>
            <MdDelete size={20} />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  const columns = [
    {
      field: "role",
      headerName: gettext("Name"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      flex: 1,
      minWidth: 200,
      maxWidth: 250,
      headerAlign: "left",
      renderCell: params => columnFormater(params)
    }
  ];

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="table-div">
          <div className="table-title">
            <div className="w-100 min-w-100">
              <h3>{gettext("Roles")}</h3>
              <span className="font-size-sm w-100">
                {gettext("List of all Roles")}
              </span>
              <span className="float-right">
                <button
                  onClick={() => Add_functionality(true)}
                  className="btn btn-primary font-weight-bold mr-2 float-right"
                >
                  Add New Role
                </button>
              </span>
            </div>
          </div>

          <div className="card-body">
            <div style={{ width: "100%" }}>
              <DataGridComponent
                rows={allRoles}
                disableSelectionOnClick={true}
                initialState={{
                  pinnedColumns: { right: ["actions"] }
                }}
                columns={columns}
                getRowId={row => row.role}
                loading={loading}
                autoHeight
              />
              <AddNewModel show={Add_show} onHide={handleClose_Add} />
              <EditModel
                show={edit_show}
                onHide={handleClose_edit}
                editableRow={editableRow}
              />
              <DeleteModel
                show={delete_show}
                onHide={handleClose_delete}
                editableRow={editableRow}
              />
              <Toast toastList={toastList} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
