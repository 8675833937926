import React, { useState, useEffect, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Spinner, Modal, Button } from "react-bootstrap";
import { useEditEnergySupplier } from "../../../../hooks/useAdminES";

export default function EditModel({
  show,
  onHide,
  editableRow,
  countries,
  countriesLoading,
  types,
  callback
}) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [inputType, setinputType] = useState("");
  const [inputName, setinputName] = useState("");
  const [inputAddress, setinputAddress] = useState("");
  const [inputZipCode, setinputZipCode] = useState("");
  const [inputCity, setinputCity] = useState("");
  const [inputCountry, setinputCountry] = useState("");
  const [inputCVR, setinputCVR] = useState("");
  const [inputPhone, setinputPhone] = useState("");
  const [inputEmail, setinputEmail] = useState("");

  useEffect(() => {
    setinputType(editableRow.type);
    setinputName(editableRow.name);
    setinputAddress(editableRow.address);
    setinputZipCode(editableRow.zipCode);
    setinputCity(editableRow.city);
    countries?.length > 0 &&
      setinputCountry(
        editableRow.country &&
          countries.find(obj => obj.name === editableRow.country)?.code
      );
    setinputCVR(editableRow.cvr);
    setinputPhone(editableRow.phone);
    setinputEmail(editableRow.email);
  }, [editableRow, countries]);

  const reset = e => {
    setinputType("");
    setinputName("");
    setinputAddress("");
    setinputZipCode("");
    setinputCity("");
    setinputCountry("");
    setinputCVR("");
    setinputPhone("");
    setinputEmail("");
  };

  const { updating, submitAdminEnergySupplier } = useEditEnergySupplier({
    resetForm: reset,
    callback: callback,
    onHide: onHide
  });

  const submitHandler = e => {
    e.preventDefault();
    const params = {
      id: editableRow.id,
      type: inputType,
      name: inputName,
      address: inputAddress,
      zipcode: inputZipCode,
      city: inputCity,
      country: inputCountry,
      cvr: inputCVR,
      phone: inputPhone,
      email: inputEmail
    };
    submitAdminEnergySupplier(inputType, params);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{gettext("Edit Energy Supplier")}</Modal.Title>
      </Modal.Header>
      <form onSubmit={submitHandler}>
        <Modal.Body>
          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Type")}</label>
                <select
                  className="form-control"
                  id="type"
                  name="type"
                  required
                  value={inputType}
                  onChange={e => setinputType(e.target.value)}
                >
                  <option value="">Select...</option>
                  {types &&
                    types.map(type => (
                      <option value={type.name} key={`type_${type.id}`}>
                        {type.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="Name"
                  name="Name"
                  required
                  placeholder="Enter name"
                  value={inputName}
                  onChange={e => setinputName(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{gettext("Address")}</label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                placeholder="Enter address"
                value={inputAddress}
                onChange={e => setinputAddress(e.target.value)}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Zip Code")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="zipCode"
                  name="zipCode"
                  placeholder="Enter zipCode"
                  value={inputZipCode}
                  onChange={e => setinputZipCode(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("City")}</label>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    placeholder="Enter city"
                    value={inputCity}
                    onChange={e => setinputCity(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Country")}</label>
                <select
                  className="form-control"
                  id="Country"
                  name="Country"
                  required
                  value={inputCountry && inputCountry}
                  onChange={e => setinputCountry(e.target.value)}
                >
                  <option value="">Select...</option>
                  {countries &&
                    countries.map(country => (
                      <option
                        key={`countries_${country.id}`}
                        value={country.code}
                        id={country.code}
                      >
                        {country.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("CVR")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="CVR"
                  name="CVR"
                  placeholder="Enter CVR"
                  value={inputCVR}
                  onChange={e => setinputCVR(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Phone")}</label>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type={"text"}
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Enter phone"
                    value={inputPhone}
                    onChange={e => setinputPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("Email")}</label>
                <input
                  type="email"
                  className="form-control"
                  id="Email"
                  name="Email"
                  placeholder="Enter email"
                  value={inputEmail}
                  onChange={e => setinputEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {gettext("Close")}
          </Button>
          <Button type="submit" variant="primary">
            {gettext("Save Changes")}
          </Button>
        </Modal.Footer>
      </form>
      {updating || countriesLoading ? (
        <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : null}
    </Modal>
  );
}
