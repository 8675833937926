import React from "react";
import { getLineChartOptions } from "./GetlineChartOptions";
import { ChartContainer } from "app/components";
import { useLang } from "app/providers";
import ReactApexChart from "react-apexcharts";

function ApexLineGraph(props) {
  const { data, title, colorSettings, uniqueId, fontSize } = props;

  const locale = useLang();

  const options = getLineChartOptions(
    data,
    colorSettings,
    uniqueId,
    locale,
    title,
    fontSize
  );

  return (
    <ChartContainer>
      <ReactApexChart
        options={options}
        series={data}
        type="line"
        width={"100%"}
        height="100%"
      />
    </ChartContainer>
  );
}

export default ApexLineGraph;
