import { useQuery } from "@apollo/client";
import {
  GET_ENERGY_PRICES,
  GET_ENERGY_PRICES_FILE,
  GET_BUILDING_ENERGY_PRICES,
  GET_BUILDING_ENERGY_PRICES_FILE
} from "./commands";

export function useEnergyPrices(dataDate, country) {
  const { data, loading, error, refetch } = useQuery(GET_ENERGY_PRICES, {
    variables: {
      dataDate,
      country
    }
  });

  return {
    data: data,
    loading,
    error,
    refetch
  };
}

export function useEnergyPricesFile(dataDate, country, fileType, currency) {
  const { data, loading, error, refetch } = useQuery(GET_ENERGY_PRICES_FILE, {
    variables: {
      dataDate,
      country,
      fileType,
      currency
    }
  });

  return {
    data: data,
    loading,
    error,
    refetch
  };
}

export function useBuildingEnergyPrices(date, buildingName) {
  const { data, loading, error, refetch } = useQuery(
    GET_BUILDING_ENERGY_PRICES,
    {
      variables: {
        date,
        buildingName
      }
    }
  );

  return {
    data: data,
    loading,
    error,
    refetch
  };
}

export function useBuildingEnergyPricesFile(date, buildingName, fileType) {
  const { data, loading, error, refetch } = useQuery(
    GET_BUILDING_ENERGY_PRICES_FILE,
    {
      variables: {
        date,
        buildingName,
        fileType
      }
    }
  );

  return {
    data: data,
    loading,
    error,
    refetch
  };
}
