import React from "react";
import { Grid } from "@mui/material";
import ScrapperLogTable from "./components/ScrapperLogTable";
import { useScrapperLogs } from "./hooks/useScrapperLogs";

export function ScrapperLog(props) {
  const { gettext, search, queryFilter } = props;
  const [selectedFilters, setSeletedFilters] = React.useState(queryFilter);
  const { logsList, loading } = useScrapperLogs(search, selectedFilters);

  return (
    <Grid item>
      <ScrapperLogTable
        rows={logsList}
        gettext={gettext}
        loading={loading}
        setSeletedFilters={setSeletedFilters}
      />
    </Grid>
  );
}
