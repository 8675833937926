import React, { useContext, useState } from "react";
import { UserListTable, AddUserForm } from "app/features";
import { Grid, Typography } from "@mui/material";
import { UsersTableControls } from "./components";
import { useTextdomainContext } from "app/providers";
import UserTableFilter from "./components/UserTableFilter";

export function AdminUserList() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const [searchString, setSearchString] = useState("");
  const [statusFilter, setStatusFilter] = useState("Active");

  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const handleOpen = () => setAddModalOpen(true);
  const handleClose = () => setAddModalOpen(false);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography fontSize={22} fontWeight={700}>
                {gettext("Users List")}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <UsersTableControls
                setSearchString={setSearchString}
                setAddModalOpen={handleOpen}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <UserTableFilter
            setStatusFilter={setStatusFilter}
            gettext={gettext}
          />
        </Grid>

        <Grid item xs={12}>
          <UserListTable
            searchString={searchString}
            statusFilter={statusFilter}
          />
        </Grid>
      </Grid>

      <AddUserForm open={isAddModalOpen} handleClose={handleClose} />
    </>
  );
}
