import React from "react";
import { injectIntl } from "react-intl";

function CardHeader(props) {
  return (
    <div className="card-header">
      <h3 className="card-title">
        <span className="card-label font-weight-bolder text-dark">
          {props.title}
        </span>
      </h3>
    </div>
  );
}

export default injectIntl(CardHeader);
