import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTextdomainContext } from "app/providers";
import { useCountries, useTimezones } from "hooks";
import { useAllRoles } from "./hooks";
import { useAddUserByAdmin } from "hooks/useUsers";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";

export function AddUserForm({ open, handleClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const [timezonesList, settimezonesList] = useState([]);

  const [passwordShown, setPasswordShown] = useState(false);

  const [inputName, setinputName] = useState("");

  const [inputEmail, setinputEmail] = useState("");

  const [inputLang, setinputLang] = useState("");

  const [inputCountry, setinputCountry] = useState("");

  const [inputTimezone, setinputTimezone] = useState("");

  const [inputStatus, setinputStatus] = useState("");

  const [inputCustomerKey, setinputCustomerKey] = useState("");

  const [inputRole, setinputRole] = useState("");

  const [inputPassword, setinputPassword] = useState("");

  // const [readOnly, setReadOnly] = useState(true);

  const { countries, loading: countriesLoading } = useCountries();

  const { timezones, loading: timezonesLoading } = useTimezones();

  const { allRoles } = useAllRoles();

  const resetForm = useCallback(() => {
    setinputName("");
    setinputEmail("");
    setinputLang("");
    setinputCountry("");
    setinputTimezone("");
    setinputStatus("");
    setinputRole("");
    setinputPassword("");
    setinputCustomerKey("");
  }, []);

  const [emailError, setEmailError] = useState(false);

  const { addUserByAdmin, loading: updating } = useAddUserByAdmin(
    () => {
      resetForm();
      handleClose();
      enqueueSnackbar(gettext("New User Added Successfully"), {
        variant: "success"
      });
    },
    error => {
      if (error === "409") setEmailError(true);
      else if (error === "410")
        enqueueSnackbar(gettext("Not authorized to perform this action"), {
          variant: "error"
        });
      else
        enqueueSnackbar(gettext("Error: Somthing went wrong!"), {
          variant: "error"
        });
    }
  );
  useEffect(() => {
    let timezonesTemp = [];
    timezones &&
      timezones.forEach(timezone => {
        inputCountry.code === timezone.countryCode &&
          timezonesTemp.push(timezone);
      });
    settimezonesList(timezonesTemp);
  }, [inputCountry, timezones]);

  const submitHandler = e => {
    e.preventDefault();
    addUserByAdmin({
      variables: {
        name: inputName,
        email: inputEmail,
        password: inputPassword,
        role: [inputRole?.key],
        countryCode: inputCountry?.code,
        lang: inputLang?.value,
        timezone: inputTimezone.zoneName,
        status: inputStatus.value,
        customerKey: inputCustomerKey
      },
      refetchQueries: ["comMyUsers"]
    });
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleNameChange = value => {
    setinputName(value);
    setinputCustomerKey(
      value.replace(/\s+/g, "").toLowerCase() +
        (value.length > 0 ? Math.floor(Math.random() * 1000000000) : "")
    );
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: theme => theme.zIndex.appBar + 1
      }}
      PaperProps={{
        sx: { padding: 4, maxWidth: 720 }
      }}
    >
      <Box sx={{ width: "auto" }}>
        <form onSubmit={submitHandler}>
          <Stack spacing={4} sx={{ height: "100%" }}>
            <Typography fontSize={22} fontWeight={700}>
              {gettext("Add New User")}
            </Typography>

            <Box sx={{ height: "100%" }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label={gettext("Name")}
                    placeholder={gettext("Enter name")}
                    size="small"
                    required
                    fullWidth
                    value={inputName}
                    onChange={e => handleNameChange(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    type="email"
                    label={gettext("Email")}
                    placeholder={gettext("Enter email")}
                    size="small"
                    required
                    fullWidth
                    autoComplete="off"
                    value={inputEmail}
                    onChange={e => {
                      setinputEmail(e.target.value);
                      setEmailError(false);
                    }}
                    error={emailError}
                    helperText={emailError && gettext("Email already exists!")}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    type={passwordShown ? "text" : "password"}
                    label={gettext("Password")}
                    placeholder={gettext("Enter password")}
                    size="small"
                    required
                    fullWidth
                    autoComplete="off"
                    value={inputPassword}
                    onChange={e => setinputPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePassword}
                            edge="end"
                          >
                            {passwordShown ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={allRoles}
                    getOptionLabel={option => option.role || ""}
                    value={inputRole}
                    onChange={(e, newValue) => setinputRole(newValue)}
                    size="small"
                    fullWidth
                    renderInput={params => (
                      <TextField {...params} required label={gettext("Role")} />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={[
                      {
                        label: gettext("English"),
                        value: "en"
                      },
                      {
                        label: gettext("Danish"),
                        value: "da"
                      },
                      {
                        label: gettext("German"),
                        value: "de"
                      }
                    ]}
                    value={inputLang}
                    onChange={(e, newValue) => setinputLang(newValue)}
                    size="small"
                    fullWidth
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={gettext("Language")}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={countries}
                    getOptionLabel={option => option.name || ""}
                    value={inputCountry}
                    onChange={(e, newValue) => setinputCountry(newValue)}
                    size="small"
                    fullWidth
                    isOptionEqualToValue={(option, value) =>
                      option.value === value
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={gettext("Country")}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={timezonesList}
                    getOptionLabel={option => option.zoneName || ""}
                    value={inputTimezone}
                    onChange={(e, newValue) => setinputTimezone(newValue)}
                    size="small"
                    fullWidth
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={gettext("Timezone")}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    options={[
                      {
                        label: gettext("Active"),
                        value: "Active"
                      },
                      {
                        label: gettext("InActive"),
                        value: "InActive"
                      },
                      {
                        label: gettext("Deleted"),
                        value: "Deleted"
                      },
                      {
                        label: gettext("Blocked"),
                        value: "Blocked"
                      }
                    ]}
                    value={inputStatus}
                    onChange={(e, newValue) => setinputStatus(newValue)}
                    size="small"
                    fullWidth
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={gettext("Status")}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    label={gettext("Company Key")}
                    placeholder={gettext("Enter Company Key")}
                    size="small"
                    required
                    fullWidth
                    value={inputCustomerKey}
                    onChange={e => setinputCustomerKey(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>

            <Paper elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={6} textAlign="center">
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    {gettext("Cancel")}
                  </Button>
                </Grid>

                <Grid item xs={6} textAlign="center">
                  <LoadingButton
                    variant="contained"
                    loading={updating || countriesLoading || timezonesLoading}
                    fullWidth
                    type="submit"
                  >
                    {gettext("Add")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </form>
      </Box>
    </Drawer>
    // <div className="card card-custom gutter-b example example-compact">
    //   <div className="table-div">
    //     {/* begin::Header */}
    //     <div className="table-title">
    //       <h3>{gettext("Add User")}</h3>
    //     </div>
    //     {/* end::Header */}
    //     <div className="card-body">

    //     </div>
    //   </div>
    //   {updating || countriesLoading || timezonesLoading ? (
    //     <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
    //       <Spinner animation="border" variant="light" />
    //     </div>
    //   ) : null}
    // </div>
  );
}
