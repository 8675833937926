import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Spinner } from "react-bootstrap";
import { useAddEnergySupplier } from "../../../hooks/useAdminES";

export function AddEnergySupplierForm(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [inputType, setinputType] = useState("");
  const [inputName, setinputName] = useState("");
  const [inputAddress, setinputAddress] = useState("");
  const [inputZipCode, setinputZipCode] = useState("");
  const [inputCity, setinputCity] = useState("");
  const [inputCountry, setinputCountry] = useState("");
  const [inputCVR, setinputCVR] = useState("");
  const [inputPhone, setinputPhone] = useState("");
  const [inputEmail, setinputEmail] = useState("");

  const reset = e => {
    setinputType("");
    setinputName("");
    setinputAddress("");
    setinputZipCode("");
    setinputCity("");
    setinputCountry("");
    setinputCVR("");
    setinputPhone("");
    setinputEmail("");
  };

  const {
    updating,
    message,
    success,
    submitAdminEnergySupplier
  } = useAddEnergySupplier({ resetForm: reset, callback: props.callback });

  const submitHandler = e => {
    e.preventDefault();
    const params = {
      type: inputType,
      name: inputName,
      address: inputAddress,
      zipcode: inputZipCode,
      city: inputCity,
      country: inputCountry,
      cvr: inputCVR,
      phone: inputPhone,
      email: inputEmail
    };
    submitAdminEnergySupplier(inputType, params);
  };

  return (
    <div className="card card-custom gutter-b example example-compact">
      <div className="table-div">
        {/* begin::Header */}
        <div className="table-title">
          <h3>{gettext("Add Energy Supplier")}</h3>
        </div>
        {/* end::Header */}
        <div className="card-body">
          <form onSubmit={submitHandler}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Type")}</label>
                <select
                  className="form-control"
                  id="type"
                  name="type"
                  required
                  value={inputType}
                  onChange={e => setinputType(e.target.value)}
                >
                  <option value="">Select...</option>
                  {props.types &&
                    props.types.map(type => (
                      <option value={type.name} key={"type_" + type.id}>
                        {type.label}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="Name"
                  name="Name"
                  required
                  placeholder={gettext("Enter name")}
                  value={inputName}
                  onChange={e => setinputName(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label>{gettext("Address")}</label>
              <input
                type="text"
                className="form-control"
                id="address"
                name="address"
                placeholder={gettext("Enter address")}
                value={inputAddress}
                onChange={e => setinputAddress(e.target.value)}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Zip Code")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="zipCode"
                  name="zipCode"
                  placeholder={gettext("Enter zipCode")}
                  value={inputZipCode}
                  onChange={e => setinputZipCode(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("City")}</label>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    placeholder={gettext("Enter city")}
                    value={inputCity}
                    onChange={e => setinputCity(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Country")}</label>
                <select
                  className="form-control"
                  id="Country"
                  name="Country"
                  required
                  value={inputCountry && inputCountry}
                  onChange={e => setinputCountry(e.target.value)}
                >
                  <option value="">Select...</option>
                  {props.countries &&
                    props.countries.map(country => (
                      <option
                        key={"countries_" + country.id}
                        value={country.code}
                        id={country.code}
                      >
                        {country.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("CVR")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="CVR"
                  name="CVR"
                  placeholder={gettext("Enter CVR")}
                  value={inputCVR}
                  onChange={e => setinputCVR(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Phone")}</label>
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type={"text"}
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder={gettext("Enter phone")}
                    value={inputPhone}
                    onChange={e => setinputPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>{gettext("Email")}</label>
                <input
                  type="email"
                  className="form-control"
                  id="Email"
                  name="Email"
                  placeholder={gettext("Enter email")}
                  value={inputEmail}
                  onChange={e => setinputEmail(e.target.value)}
                />
              </div>
            </div>
            {message ? (
              success ? (
                <p style={{ color: "green" }}>{message}</p>
              ) : (
                <p style={{ color: "red" }}>{message}</p>
              )
            ) : null}
            <button className="form-control btn btn-primary" type="submit">
              {gettext("Add")}
            </button>
          </form>
        </div>
      </div>
      {updating || props.countriesLoading ? (
        <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </div>
      ) : null}
    </div>
  );
}
