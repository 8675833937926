import { useQuery } from "@apollo/client";
import { GET_ENERGY_SUPPLIERS } from "../commands";

export function useEnergySuppliers(Type) {
  const { data, loading } = useQuery(GET_ENERGY_SUPPLIERS, {
    variables: {
      Type
    }
  });

  return {
    energySuppliers: data?.comEnergySuppliers || [],
    loading
  };
}
