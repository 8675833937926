import React, { useCallback, useContext, useEffect } from "react";
import { Grid } from "@mui/material";
import {
  useDashboardFilter,
  useTextdomainContext,
  useUser
} from "app/providers";
import { useDashboard } from "hooks/useDashboardData";
import * as Constants from "../../../common/Constants";
import ConPerSourceGraphs from "./ConPerSourceGraphs";
import { ChartContainer, NoBuildingWarning } from "app/components";
import { LineChartLoadingState } from "app/components/LineChart/components";

export function PerEnergySource(props) {
  const { kwhGraph, Co2Graph } = props;
  const [graphData, setGraphData] = React.useState([]);
  const [co2GraphData, setCo2GraphData] = React.useState([]);
  const [graphColors, setGraphColors] = React.useState([]);

  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const {
    selectedCompaniesString,
    selectedBuildingsString,
    fromDate,
    toDate
  } = useDashboardFilter();

  const { colors } = useUser();

  let getMonthNames = useCallback(
    month => {
      switch (month) {
        case 0:
          return gettext("Jan");

        case 1:
          return gettext("Feb");

        case 2:
          return gettext("Mar");

        case 3:
          return gettext("Apr");

        case 4:
          return gettext("May");

        case 5:
          return gettext("Jun");

        case 6:
          return gettext("Jul");
        case 7:
          return gettext("Aug");
        case 8:
          return gettext("Sep");
        case 9:
          return gettext("Oct");
        case 10:
          return gettext("Nov");

        case 11:
          return gettext("Dec");

        default:
          return "";
      }
    },
    [gettext]
  );

  const params = type => {
    return {
      sourceType: type,
      customers: selectedCompaniesString,
      addresses: selectedBuildingsString,
      fromDate,
      toDate,
      resolution: "m",
      unit: "all"
    };
  };

  const { data: electricityData, loading: eLoading } = useDashboard(
    params("electricity")
  );

  const { data: waterData, loading: wLoading } = useDashboard(params("water"));

  const { data: heatingData, loading: hLoading } = useDashboard(
    params("heating")
  );

  const getEnergyData = useCallback(
    (energyData, fieldName) => {
      let graphData = [];
      let monthNames = [];
      if (energyData?.data?.length) {
        energyData.data.forEach((data, index) => {
          graphData.push(
            data[fieldName] ? parseFloat(data[fieldName]).toFixed(2) : 0
          );
          let monthName = getMonthNames(new Date(data.ts).getMonth());
          let year = new Date(data.ts).getFullYear();

          monthNames.push(monthName + " " + year);
        });
      }
      return { graphData, monthNames };
    },
    [getMonthNames]
  );

  const picGraphColor = useCallback((apiColor, constantColor) => {
    if (apiColor) {
      return apiColor;
    } else {
      return constantColor;
    }
  }, []);

  const getUserColor = useCallback(
    (type, constantColor) => {
      let userColor = colors?.[type]?.shade1?.background;

      if (userColor) {
        return userColor;
      } else {
        return constantColor;
      }
    },
    [colors]
  );

  const getChartData = useCallback(
    fieldName => {
      let egraphData = [];
      let wgraphData = [];
      let hgraphData = [];
      let calculatedGraphData = [];

      egraphData = getEnergyData(electricityData, fieldName);
      wgraphData = getEnergyData(waterData, fieldName);
      hgraphData = getEnergyData(heatingData, fieldName);

      let eColor = picGraphColor(
        electricityData?.colorSettings?.electricity?.background,
        getUserColor("electricity", Constants.ELECTRICITY_COLOR)
      );
      let wColor = picGraphColor(
        electricityData?.colorSettings?.water?.background,
        getUserColor("water", Constants.WATER_COLOR)
      );
      let hColor = picGraphColor(
        electricityData?.colorSettings?.heating?.background,
        getUserColor("heating", Constants.HEAT_COLOR)
      );

      setGraphColors([eColor, wColor, hColor]);
      calculatedGraphData.push({
        name: gettext("Electricity"),
        data: egraphData.graphData,
        categories: egraphData.monthNames
      });

      calculatedGraphData.push({
        name: gettext("Water"),
        data: wgraphData.graphData,
        categories: wgraphData.monthNames
      });

      calculatedGraphData.push({
        name: gettext("Heating"),
        data: hgraphData.graphData,
        categories: hgraphData.monthNames
      });

      return calculatedGraphData;
    },
    [
      gettext,
      getEnergyData,
      getUserColor,
      electricityData,
      waterData,
      heatingData,
      picGraphColor
    ]
  );

  ////show loader

  const showLoader = () => {
    return (
      <>
        <ChartContainer>
          <LineChartLoadingState />
        </ChartContainer>
      </>
    );
  };

  ////methods

  useEffect(() => {
    let consumptionGraphData = [];
    let co2GraphData = [];

    if (eLoading && wLoading && hLoading) {
      return;
    } else if (electricityData && waterData && heatingData) {
      consumptionGraphData = getChartData(kwhGraph.fieldName);

      co2GraphData = getChartData(Co2Graph.fieldName);
    }
    setCo2GraphData(co2GraphData);
    setGraphData(consumptionGraphData);
  }, [
    getChartData,
    Co2Graph,
    kwhGraph,
    electricityData,
    waterData,
    heatingData,
    eLoading,
    wLoading,
    hLoading,
    gettext,
    selectedBuildingsString
  ]);

  return (
    <>
      {selectedBuildingsString.length ? (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            {!eLoading && !wLoading && !hLoading ? (
              <ConPerSourceGraphs
                uniqueId={kwhGraph.id}
                categories={electricityData?.colorSettings}
                data={graphData}
                title={gettext(kwhGraph.title)}
                graphColors={graphColors}
                colors={
                  colors
                    ? colors
                    : {
                        energy: {
                          backgroundColor: "#4D81FF",
                          color: "#F2F6FF"
                        },
                        gas: {
                          backgroundColor: "#37D7AD",
                          color: "#EDFAF7"
                        },
                        water: {
                          backgroundColor: "#FFD068",
                          color: "#FFFBF2"
                        }
                      }
                }
              />
            ) : (
              showLoader()
            )}
          </Grid>
          <Grid item xs={12}>
            {!eLoading && !wLoading && !hLoading ? (
              <ConPerSourceGraphs
                uniqueId={Co2Graph.id}
                data={co2GraphData}
                title={gettext(Co2Graph.title)}
                graphColors={graphColors}
                colors={
                  electricityData?.colorSettings
                    ? electricityData?.colorSettings
                    : {
                        energy: {
                          backgroundColor: "#4D81FF",
                          color: "#F2F6FF"
                        },
                        gas: {
                          backgroundColor: "#37D7AD",
                          color: "#EDFAF7"
                        },
                        water: {
                          backgroundColor: "#FFD068",
                          color: "#FFFBF2"
                        }
                      }
                }
              />
            ) : (
              showLoader()
            )}
          </Grid>
        </Grid>
      ) : (
        <NoBuildingWarning />
      )}
    </>
  );
}
