import { Stack } from "@mui/material";
import React from "react";
import { TypeSelector } from "./components";

export function MainBody() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Stack spacing={2}>
      <TypeSelector
        type={"electricity"}
        expanded={expanded === "electricity"}
        onChange={handleChange("electricity")}
      />

      <TypeSelector
        type={"heating"}
        expanded={expanded === "heating"}
        onChange={handleChange("heating")}
      />

      <TypeSelector
        type={"gas"}
        expanded={expanded === "gas"}
        onChange={handleChange("gas")}
      />

      <TypeSelector
        type={"water"}
        expanded={expanded === "water"}
        onChange={handleChange("water")}
      />
    </Stack>
  );
}
