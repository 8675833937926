import { IconButton, Tooltip } from "@mui/material";
import React, { useCallback, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { DataGridComponent } from "../../components";
import { useFetchAccountFileData, useDelete } from "./hooks";
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useConfirmation } from "../../providers";
import { EditModal } from "./components";
import { useTextdomainContext } from "app/providers";

export function AccountDataListTable() {
  const { id } = useParams();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const {
    fileName,
    accountFileDataList,
    loading,
    refetch
  } = useFetchAccountFileData(id);

  const { deleteDataElement } = useDelete();

  const { confirm, closeConfirmation } = useConfirmation({ loading: false });

  const [showEditModal, setShowEditModal] = useState(false);

  const [editableRow, setEditableRow] = useState(undefined);

  const handleEditModalShow = useCallback(row => {
    setShowEditModal(true);
    setEditableRow(row);
  }, []);

  const handleEditModalHide = useCallback(() => {
    setShowEditModal(false);
  }, []);

  const onConfirmHandler = useCallback(() => {
    closeConfirmation();
    refetch();
  }, [closeConfirmation, refetch]);

  const onUpdateHandler = useCallback(() => {
    handleEditModalHide();
    refetch();
  }, [handleEditModalHide, refetch]);

  const actionColumnFormater = params => {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Tooltip title="Edit" arrow>
          <IconButton onClick={() => handleEditModalShow(params?.row)}>
            <AiFillEdit size={18} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete" arrow>
          <IconButton
            onClick={() =>
              confirm({
                confirmationText:
                  gettext("Are you sure you want to Delete") +
                  " " +
                  params?.row?.accountName +
                  "?",
                onConfirm: () =>
                  deleteDataElement(
                    id,
                    params?.row?.accountNumber,
                    onConfirmHandler
                  )
              })
            }
          >
            <MdDelete size={18} />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      field: "accountNumber",
      headerName: gettext("No."),
      headerAlign: "left"
    },
    {
      field: "accountName",
      headerName: gettext("Name"),
      flex: 2,
      headerAlign: "left"
    },
    {
      field: "period",
      headerName: gettext("The period"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "yeaBefore",
      headerName: gettext("The year before"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      headerAlign: "center",
      renderCell: params => actionColumnFormater(params)
    }
  ];

  return (
    <>
      <div className="card card-custom example example-compact">
        <div className="table-div m-0">
          {/* begin::Header */}
          <div className="table-title">
            <h3 className="custom-header-list">
              <span className="font-size-sm w-100">{fileName}</span>
            </h3>
            <div className="card-toolbar"></div>
          </div>
          {/* end::Header */}

          <div className="card-body customer-list">
            <DataGridComponent
              rows={accountFileDataList}
              columns={columns}
              loading={loading}
              getRowId={row => row.accountNumber}
              disableSelectionOnClick={false}
            />
          </div>
        </div>
      </div>
      <EditModal
        show={showEditModal}
        onHide={handleEditModalHide}
        editableRow={editableRow}
        id={id}
        onUpdateHandler={onUpdateHandler}
      />
    </>
  );
}
