export const info = {
  50: "#E6EDFF",
  100: "#CCDBFF",
  200: "#B3C9FF",
  300: "#99B7FF",
  400: "#80A5FF",
  500: "#6693FF",
  600: "#4D81FF",
  700: "#336FFF",
  800: "#1A5DFF",
  900: "#004BFF"
};
