import { NewMixedWidget3 } from "app/widgets/mixed/NewMixedWidget3";
import React from "react";

import { emptyBarChart } from "../../../common/Constants";

function Co2SectionCol1Row1(props) {
  return (
    <div className={"row"}>
      <div className={"col-sm-12"}>
        <div className="MuiPaper-root MuiCard-root jss1 MuiPaper-elevation1 MuiPaper-rounded">
          <div className="MuiCardContent-root">
            {props.data1 && (
              <NewMixedWidget3
                className="card-stretch"
                data={props.data1 ? props.data1 : emptyBarChart}
                propsColors={props?.propsColors}
                label="CO2Report"
                imageUrl={uri => {
                  props.imageUrl(uri);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Co2SectionCol1Row1;
