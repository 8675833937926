import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { ADD_GRAPH_IMAGE, DELETE_GRAPH_IMAGE } from "./commands";

export function useAddGraphImage() {
  const [addGraphImage, { loading }] = useMutation(ADD_GRAPH_IMAGE);

  const addDashboard = useCallback(
    async variables => {
      try {
        await addGraphImage({
          variables,
          refetchQueries: ["comGetGraphImages"]
        });
      } catch (error) {}
    },
    [addGraphImage]
  );

  return {
    addDashboard,
    isAdding: loading
  };
}

export function useDeleteGraphImage() {
  const [deleteGraphImage, { loading }] = useMutation(DELETE_GRAPH_IMAGE);

  const deleteDashboard = useCallback(
    async variables => {
      try {
        await deleteGraphImage({
          variables,
          refetchQueries: ["comGetGraphImages"]
        });
      } catch (error) {}
    },
    [deleteGraphImage]
  );

  return {
    deleteDashboard,
    isDeleting: loading
  };
}
