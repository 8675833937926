import React from "react";
import ApexLineGraph from "./ApexLineGraph";

function ConPerSourceGraphs(props) {
  const { uniqueId, data, title, graphColors, fontSize } = props;
  const [activeGraph, setActiveGraph] = React.useState({});

  return (
    <>
      {data.length ? (
        <ApexLineGraph
          data={data}
          uniqueId={uniqueId}
          title={title}
          colorSettings={graphColors}
          setActiveGraph={setActiveGraph}
          activeGraph={activeGraph}
          fontSize={fontSize}
        />
      ) : null}
    </>
  );
}

export default ConPerSourceGraphs;
