import { useQuery } from "@apollo/client";
import { GET_ACTIVE_USERS } from "../commands";

export function useActiveUsersList(searchString) {
  const { data, loading } = useQuery(GET_ACTIVE_USERS, {
    variables: {
      searchString
    }
  });

  return {
    activeUsersList: data?.comActiveUsers || [],
    loading
  };
}
