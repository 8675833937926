import React, { useEffect, useState, useContext, useCallback } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
// import {MixedWidget3} from "../../../widgets";
import Co2SectionCol1Row1 from "../components/Co2SectionCol1Row1";
import { localeNumberFormat } from "../../../common/Functions";
import { DECIMAL } from "../../../common/Constants";
import {
  totalEnergyValue,
  divide3SourceGraph,
  totalYearEnergyValue
} from "../partials/Co2Function";
import { Grid, Typography } from "@mui/material";

function NewCo2Section2(props) {
  const {
    // getMainTitle,
    // getMainDescription,
    params,
    setMainPageTitle,
    setMainPageDescription,

    energyData,
    electricityData,
    gasData,
    heatingData,
    waterData,
    energyLoading,
    electricityLoading,
    gasLoading,
    heatingLoading,
    sourceType,
    consumptionType,
    waterLoading,
    chartId,
    chartType,
    colors,
    sourceIndex,
    stacked
  } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { fromYear, secondYear, thirdYear, toYear, dateFrom, dateTo } = params;
  const [data1, changeData1] = useState(null);
  const [totalEnergyCo2Year1, setTotalEnergyCo2Year1] = useState(0);
  const [totalEnergyCo2Year3, setTotalEnergyCo2Year3] = useState(0);

  const getFromDate = useCallback(
    fromYear => {
      return (
        new Date(
          new Date(new Date(dateFrom).setFullYear(fromYear)).setDate("01")
        )
          .toISOString()
          .split("T")[0] + "T00:00:00"
      );
    },
    [dateFrom]
  );

  const getToDate = useCallback(
    ToYear => {
      return new Date(
        new Date(new Date(dateFrom).setFullYear(ToYear)).setMonth(
          new Date(dateTo).getMonth() + 11
        )
      );
    },
    [dateFrom, dateTo]
  );

  const getGraphLabels = useCallback(
    (fYear, TYear) => {
      let fromDate = new Date(fYear).toLocaleString("default", {
        month: "short",
        year: "numeric"
      });

      let toDate = new Date(TYear).toLocaleString("default", {
        month: "short",
        year: "numeric"
      });
      return (
        gettext(fromDate.split(" ")[0]) +
        " " +
        fromDate.split(" ")[1] +
        "-" +
        gettext(toDate.split(" ")[0]) +
        " " +
        toDate.split(" ")[1]
      );
      // return `${fromDate}-${toDate}`;
    },
    [gettext]
  );

  useEffect(() => {
    if (energyData && !energyLoading) {
      const result1 = totalEnergyValue(energyData, fromYear);
      const result3 = totalEnergyValue(energyData, toYear);
      if (chartType === "Consumption") {
        setTotalEnergyCo2Year1(result3[1]);
        setTotalEnergyCo2Year3(result1[1]);
      } else if (chartType === "Production") {
        setTotalEnergyCo2Year1(result3[3]);
        setTotalEnergyCo2Year3(result1[3]);
      } else if (chartType === "Compensation") {
        setTotalEnergyCo2Year1(result3[1] - result3[3]);
        setTotalEnergyCo2Year3(result1[1] - result1[3]);
      }
    }
  }, [energyData, fromYear, toYear, chartType, energyLoading]);

  useEffect(() => {
    if (
      electricityData &&
      heatingData &&
      gasData &&
      waterData &&
      energyData &&
      !gasLoading &&
      !electricityLoading &&
      !heatingLoading &&
      !waterLoading
    ) {
      let type = "";
      if (sourceIndex === 1) {
        type = "emissions";
      } else if (sourceIndex === 3) {
        type = "reductions";
      } else {
        type = "emissions";
      }

      const eFromYear = totalYearEnergyValue(
        electricityData,
        getFromDate(fromYear),
        getToDate(fromYear)
      );

      const eSecondYear = totalYearEnergyValue(
        electricityData,
        getFromDate(secondYear),
        getToDate(secondYear)
      );
      const eThirdYear = totalYearEnergyValue(
        electricityData,
        getFromDate(thirdYear),
        getToDate(thirdYear)
      );

      const hFromYear = totalYearEnergyValue(
        heatingData,
        getFromDate(fromYear),
        getToDate(fromYear)
      );
      const hSecondYear = totalYearEnergyValue(
        heatingData,
        getFromDate(secondYear),
        getToDate(secondYear)
      );
      const hThirdYear = totalYearEnergyValue(
        heatingData,
        getFromDate(thirdYear),
        getToDate(thirdYear)
      );

      const gFromYear = totalYearEnergyValue(
        gasData,
        getFromDate(fromYear),
        getToDate(fromYear)
      );
      const gSecondYear = totalYearEnergyValue(
        gasData,
        getFromDate(secondYear),
        getToDate(secondYear)
      );
      const gThirdYear = totalYearEnergyValue(
        gasData,
        getFromDate(thirdYear),
        getToDate(thirdYear)
      );

      const wFromYear = totalYearEnergyValue(
        waterData,
        getFromDate(fromYear),
        getToDate(fromYear)
      );
      const wSecondYear = totalYearEnergyValue(
        waterData,
        getFromDate(secondYear),
        getToDate(secondYear)
      );
      const wThirdYear = totalYearEnergyValue(
        waterData,
        getFromDate(thirdYear),
        getToDate(thirdYear)
      );

      let CData = null;
      if (chartType === "Consumption") {
        CData = divide3SourceGraph(
          [eFromYear[1], eSecondYear[1], eThirdYear[1]],
          [hFromYear[1], hSecondYear[1], hThirdYear[1]],
          [wFromYear[1], wSecondYear[1], wThirdYear[1]],
          [gFromYear[1], gSecondYear[1], gThirdYear[1]],
          [
            getGraphLabels(getFromDate(fromYear), getToDate(fromYear)),
            getGraphLabels(getFromDate(secondYear), getToDate(secondYear)),
            getGraphLabels(getFromDate(thirdYear), getToDate(thirdYear))
          ],
          consumptionType,
          "Consumption",
          sourceType,
          chartId,
          colors,
          gettext,
          gettext("Annual CO₂ emissions measured in tonnes"),
          stacked
        );
      } else if (chartType === "Production") {
        CData = divide3SourceGraph(
          [eFromYear[3], eSecondYear[3], eThirdYear[3]],
          [hFromYear[3], hSecondYear[3], hThirdYear[3]],
          [wFromYear[3], wSecondYear[3], wThirdYear[3]],
          [gFromYear[1], gSecondYear[1], gThirdYear[1]],
          [
            getGraphLabels(getFromDate(fromYear), getToDate(fromYear)),
            getGraphLabels(getFromDate(secondYear), getToDate(secondYear)),
            getGraphLabels(getFromDate(thirdYear), getToDate(thirdYear))
          ],
          consumptionType,
          "Production",
          sourceType,
          chartId,
          colors,
          gettext,
          gettext(`Annual CO₂ ${type} measured in tonnes`),
          stacked
        );
      } else if (chartType === "Compensation") {
        CData = divide3SourceGraph(
          [
            eFromYear[1] - eFromYear[3],
            eSecondYear[1] - eSecondYear[3],
            eThirdYear[1] - eThirdYear[3]
          ],
          [
            hFromYear[1] - hFromYear[3],
            hSecondYear[1] - hSecondYear[3],
            hThirdYear[1] - hThirdYear[3]
          ],
          [
            wFromYear[1] - wFromYear[3],
            wSecondYear[1] - wSecondYear[3],
            wThirdYear[1] - wThirdYear[3]
          ],
          [
            gFromYear[1] - gFromYear[3],
            gSecondYear[1] - gSecondYear[3],
            gThirdYear[1] - gThirdYear[3]
          ],
          [
            getGraphLabels(getFromDate(fromYear), getToDate(fromYear)),
            getGraphLabels(getFromDate(secondYear), getToDate(secondYear)),
            getGraphLabels(getFromDate(thirdYear), getToDate(thirdYear))
          ],
          consumptionType,
          "Compensation",
          sourceType,
          chartId,
          colors,
          gettext,
          gettext(`Annual CO₂ ${type} measured in tonnes`),
          stacked
        );
      }
      changeData1(CData);
    }
  }, [
    getGraphLabels,
    dateFrom,
    getToDate,
    getFromDate,
    dateTo,
    sourceIndex,
    electricityData,
    gasData,
    gasLoading,
    energyData,
    heatingData,
    waterData,
    electricityLoading,
    heatingLoading,
    waterLoading,
    fromYear,
    secondYear,
    thirdYear,
    chartId,
    chartType,
    consumptionType,
    sourceType,
    colors,
    gettext,
    toYear,
    stacked
  ]);

  let totalEnergyCo2Year1Formatted = localeNumberFormat(
    totalEnergyCo2Year1,
    DECIMAL
  );
  let totalEnergyCo2Year3Formatted = localeNumberFormat(
    totalEnergyCo2Year3,
    DECIMAL
  );

  const getSourceTitle = () => {
    let title = "";
    if (sourceIndex === 1) {
      title = gettext(
        `Energy consumptions annual CO₂ emissions measured in tonnes for each energy source`
      );
    } else if (sourceIndex === 3) {
      title = gettext(
        `Yearly CO₂ reductions measured in tonnes from electricity production`
      );
    } else {
      // title = `${gettext(
      //   "CO₂ accounts for"
      // )} ${fromYear}, ${secondYear} ${gettext("and")} ${toYear}`;
      title = `${gettext(
        "Yearly CO₂ reductions measured in tonnes from electricity production"
      )}`;
    }
    // getMainTitle && getMainTitle(title);
    return title;
  };

  const getSourceType = () => {
    if (sourceIndex === 1) {
      return "emissions";
    }
    //  else if (sourceIndex === 1) {
    //     return "reduction";
    // }
    else if (sourceIndex === 3) {
      return "reductions";
    } else {
      return "emissions";
    }
  };

  useEffect(() => {
    if (setMainPageDescription) {
      const sourceType = () => {
        if (sourceIndex === 1) {
          return "emissions";
        }
        //  else if (sourceIndex === 1) {
        //     return "reduction";
        // }
        else if (sourceIndex === 3) {
          return "reductions";
        } else {
          return "emissions";
        }
      };
      if (totalEnergyCo2Year1 < totalEnergyCo2Year3) {
        setMainPageDescription(
          `${gettext("The total CO₂")} ${sourceType()} ${gettext("fell from")}
            ${totalEnergyCo2Year3Formatted} ${gettext(
            "tonnes of CO₂ in"
          )} ${fromYear} ${gettext(
            "to"
          )} ${totalEnergyCo2Year1Formatted} ${gettext(
            "tonnes of CO₂ in"
          )} ${toYear}.`
        );
      } else if (totalEnergyCo2Year1 > totalEnergyCo2Year3) {
        setMainPageDescription(
          `${gettext("The total CO₂")} ${sourceType()} ${gettext(
            "increased from"
          )}
            ${totalEnergyCo2Year3Formatted} ${gettext(
            "tonnes of CO₂ in"
          )} ${fromYear} ${gettext(
            "to"
          )} ${totalEnergyCo2Year1Formatted} ${gettext(
            "tonnes of CO₂ in"
          )} ${toYear}`
        );
      } else if (totalEnergyCo2Year1 === totalEnergyCo2Year3) {
        setMainPageDescription(
          `${gettext("The total CO₂")} ${sourceType()} ${gettext(
            "have been constant at"
          )}
            ${totalEnergyCo2Year3Formatted} ${gettext(
            "tonnes of CO₂ in the period"
          )} ${fromYear} ${gettext("to")} ${toYear}`
        );
      }
    }
    if (setMainPageTitle) {
      let title = "";
      if (sourceIndex === 1) {
        title = gettext(
          `Energy consumptions annual CO₂ emissions measured in tonnes for each energy source`
        );
      } else if (sourceIndex === 3) {
        title = gettext(
          `Yearly CO₂ reductions measured in tonnes from electricity production`
        );
      } else {
        // title = `${gettext(
        //   "CO₂ accounts for"
        // )} ${fromYear}, ${secondYear} ${gettext("and")} ${toYear}`;
        title = `${gettext(
          "Yearly CO₂ reductions measured in tonnes from electricity production"
        )}`;
      }

      setMainPageTitle(title);
    }
  }, [
    sourceIndex,
    totalEnergyCo2Year1,
    totalEnergyCo2Year3,
    sourceType,
    setMainPageDescription,
    gettext,
    totalEnergyCo2Year3Formatted,
    fromYear,
    thirdYear,
    totalEnergyCo2Year1Formatted,
    setMainPageTitle,
    toYear
  ]);

  return (
    <>
      <Grid
        container
        display="flex"
        flexDirection="column"
        rowGap={2}
        sx={{
          backgroundColor: "#ffffff",
          p: 3,
          borderRadius: "8px",
          my: 3
        }}
      >
        <Grid item>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#27272A"
            }}
          >
            {getSourceTitle()}
          </Typography>
        </Grid>

        <Grid
          item
          sx={{
            borderBottom: "1px solid #A1A1AA5E",
            pb: 2.5,
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#27272A"
          }}
        >
          {totalEnergyCo2Year1 < totalEnergyCo2Year3 &&
            `${gettext("The total CO₂")} ${getSourceType()} ${gettext(
              "fell from"
            )}
                 ${totalEnergyCo2Year3Formatted} ${gettext(
              "tonnes of CO₂ in"
            )} ${fromYear} ${gettext(
              "to"
            )} ${totalEnergyCo2Year1Formatted} ${gettext(
              "tonnes of CO₂ in"
            )} ${toYear}`}
          {totalEnergyCo2Year1 > totalEnergyCo2Year3 &&
            `${gettext("The total CO₂")} ${getSourceType()} ${gettext(
              "increased from"
            )}
                ${totalEnergyCo2Year3Formatted} ${gettext(
              "tonnes of CO₂ in"
            )} ${fromYear} ${gettext(
              "to"
            )} ${totalEnergyCo2Year1Formatted} ${gettext(
              "tonnes of CO₂ in"
            )} ${toYear}`}
          {totalEnergyCo2Year1 === totalEnergyCo2Year3 &&
            `${gettext("The total CO₂")} ${getSourceType()} ${gettext(
              "have been constant at"
            )}
                 ${totalEnergyCo2Year3Formatted} ${gettext(
              "tonnes of CO₂ in the period"
            )} ${fromYear} ${gettext("to")} ${toYear}`}
        </Grid>
        <Grid item>
          {data1 && (
            <Co2SectionCol1Row1
              data1={data1}
              propsColors={props.colors}
              imageUrl={uri => {
                if (typeof props.setPageUri === "function") {
                  props.setPageUri(uri);
                  props.setPageTitle(data1.chart_title);
                }
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default injectIntl(NewCo2Section2);
