import React, { useCallback, useContext, useState } from "react";
import { useTextdomainContext } from "app/providers";
import { DataGridComponent } from "../../components";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useGetMyBuildings, useUpdateBuilding } from "./hooks";
import { Spinner } from "react-bootstrap";
import { FaUserEdit } from "react-icons/fa";
import Toast from "app/components/toast/Toast";
import TextField from "@mui/material/TextField";
import {
  Button,
  IconButton,
  Tooltip,
  Typography,
  Box,
  // Button,
  Grid,
  // Paper,
  Stack
} from "@mui/material";
import { AddressesInput } from "app/modules/RegistrationFlow/tabs/AddBuildingTab/components";
import { styled } from "@mui/material/styles";
import { IoMdColorFill } from "react-icons/io";
import { ColorPicker } from "./components";
import { useMutation } from "@apollo/client";
import { ADD_NEW_BUILDING, EDIT_CUSTOMER_BUILDING } from "GraphQL/Mutations";

const ColorButton = styled(Button)(({ customColor }) => ({
  textTransform: "none",
  color: customColor,
  backgroundColor: customColor,
  "&:hover": {
    backgroundColor: customColor
  }
}));

export function BuildingListTable(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { cid } = useParams();

  const { writeAccess } = props;

  const [edit, setEdit] = useState(false);

  const [editBuildingId, setEditBuildingId] = useState("");

  const [buildingLabel, setBuildingLabel] = useState("");

  const [colorMenuOpen, setColorMenuOpen] = useState(false);
  const [toastList, updateToastList] = useState([]);

  const [selectedColorSetting, setSelectedColorSetting] = useState({});

  const [buildingId, setBuildingId] = useState(null);

  const [newBuildings, setNewBuildings] = useState([
    { buildingAddress: "", buildingCountry: "" }
  ]);
  const navigate = useNavigate();

  const location = useLocation();
  const [AddBuilding, apiLoading] = useMutation(ADD_NEW_BUILDING, {
    onCompleted() {
      const toastArray = [
        {
          title: gettext("New Building Added"),
          description: gettext("New Building added successfully"),
          type: "success"
        }
      ];
      updateToastList(toastArray);
      setNewBuildings([{ buildingAddress: "", buildingCountry: "" }]);
    },
    onError() {},
    refetchQueries: ["getCustomerBuildings"]
  });
  const [EditCustomerBuilding, editMutation] = useMutation(
    EDIT_CUSTOMER_BUILDING,
    {
      onCompleted() {
        const toastArray = [
          {
            title: gettext("Building Updated"),
            description: gettext(" Building updated successfully"),
            type: "success"
          }
        ];
        updateToastList(toastArray);
        setNewBuildings([{ buildingAddress: "", buildingCountry: "" }]);
        setBuildingLabel("");
        setEdit(false);
      },
      onError() {},
      refetchQueries: ["getCustomerBuildings"]
    }
  );
  const { buildings, loading } = useGetMyBuildings(cid);
  const { update, updating } = useUpdateBuilding();

  //-----------Update Functions---------------//

  const updateCustomerBuilding = useCallback(
    body => {
      update(body);
    },
    [update]
  );

  //---  Country and Address Functions ---- //

  const reset = () => {
    setNewBuildings([{ buildingAddress: "", buildingCountry: "" }]);
    setBuildingLabel("");
  };

  const onChangeAddress = useCallback((index, value) => {
    setNewBuildings(prevArray => {
      let arr = [...prevArray];
      arr[index].buildingAddress = value;
      return arr;
    });
  }, []);

  const onChangeCountry = useCallback((index, value) => {
    setNewBuildings(prevArray => {
      let arr = [...prevArray];
      arr[index].buildingCountry = value;
      return arr;
    });
  }, []);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      e.target.reset();
      const data = {
        address: newBuildings && newBuildings[0].buildingAddress,
        country: newBuildings && newBuildings[0].buildingCountry,
        label: buildingLabel
      };
      edit
        ? EditCustomerBuilding({
            variables: {
              buildingId: editBuildingId,
              customerName: cid,
              data: JSON.stringify(data)
            }
          })
        : AddBuilding({
            variables: {
              customer: cid,
              buildings: [
                {
                  address: newBuildings && newBuildings[0].buildingAddress,
                  country: newBuildings && newBuildings[0].buildingCountry,
                  label: buildingLabel
                }
              ]
            }
          });
    },
    [
      AddBuilding,
      cid,
      newBuildings,
      edit,
      editBuildingId,
      EditCustomerBuilding,
      buildingLabel
    ]
  );

  //-----------------------------------//

  //------------Change Handlers-----------//

  const handleCheckBoxChange = (checked, row, type) => {
    let data = {
      electricityGreen: row?.electricityGreen,
      heatingGreen: row?.heatingGreen === "true",
      waterGreen: row?.waterGreen === "true",
      status: row?.status === "active"
    };

    switch (type) {
      case "electricity":
        data.electricityGreen = checked ? true : false;
        break;
      case "heating":
        data.heatingGreen = checked ? true : false;
        break;
      case "water":
        data.waterGreen = checked ? true : false;
        break;
      case "status":
        data.status = checked ? "active" : "inactive";
        break;
      default:
        break;
    }

    const body = {
      buildingId: row?.Id,
      data
    };

    updateCustomerBuilding(body);
  };

  //----------------------------------------//

  const goToMeter = (type, buildingID, row) => {
    navigate(`${location.pathname}/${buildingID}/meters/${type}`, {
      state: { buildingAddress: row.address }
    });
  };

  const getTotalMeters = (totalMeters, gettext) => {
    if (totalMeters) {
      return totalMeters + " " + gettext("Meters");
    } else {
      return `0 ${gettext("Meters")}`;
    }
  };

  //--------------Formatters----------------//

  function electricityColumnFormater(row) {
    return (
      <ColorButton
        variant="contained"
        size="small"
        onClick={() => goToMeter("electricity", row.Id, row)}
        customColor={row?.colorSettings?.electricity.background}
      >
        <Typography color={row?.colorSettings?.electricity.text}>
          {getTotalMeters(row?.electricity?.length, gettext)}
          {/* {row?.electricity?.length
                        ? row?.electricity?.length + ` ${gettext("Meters")}`
                        : `0 ${gettext("Meters")}`} */}
        </Typography>
      </ColorButton>
    );
  }

  function heatingColumnFormater(row) {
    return (
      <ColorButton
        variant="contained"
        size="small"
        onClick={() => goToMeter("heating", row.Id, row)}
        customColor={row?.colorSettings?.heating.background}
      >
        <Typography color={row?.colorSettings?.heating.text}>
          {getTotalMeters(row?.heating?.length, gettext)}
          {/* {row?.heating?.length
                        ? row?.heating?.length + ` ${gettext("Meters")}`
                        : `0 ${gettext("Meters")}`} */}
        </Typography>
      </ColorButton>
    );
  }

  function waterColumnFormater(row) {
    return (
      <ColorButton
        variant="contained"
        size="small"
        onClick={() => goToMeter("water", row.Id, row)}
        customColor={row?.colorSettings?.water.background}
      >
        <Typography color={row?.colorSettings?.water.text}>
          {getTotalMeters(row?.water?.length, gettext)}
          {/* {row?.water?.length
                        ? row?.water?.length + ` ${gettext("Meters")}`
                        : `0 ${gettext("Meters")}`} */}
        </Typography>
      </ColorButton>
    );
  }

  function gasColumnFormater(row) {
    return (
      <ColorButton
        variant="contained"
        size="small"
        customColor={row?.colorSettings?.gas.background}
        onClick={() => goToMeter("gas", row.Id, row)}
      >
        <Typography color={row?.colorSettings?.gas.text}>
          {getTotalMeters(row?.gas?.length, gettext)}
          {/* {row?.gas?.length
                        ? row?.gas?.length + ` ${gettext("Meters")}`
                        : `0 ${gettext("Meters")}`} */}
        </Typography>
      </ColorButton>
    );
  }

  function checkBoxColumnFormater(row, type) {
    let checked = false;

    switch (type) {
      case "electricity":
        checked = row?.electricityGreen;
        break;
      case "heating":
        checked = row?.heatingGreen === "true";
        break;
      case "water":
        checked = row?.waterGreen === "true";
        break;
      case "status":
        checked = row?.status === "active";
        break;
      default:
        break;
    }

    return (
      <div className="w-100 d-flex justify-content-center">
        <input
          style={{ cursor: "pointer" }}
          type="checkbox"
          disabled={updating}
          onChange={e => handleCheckBoxChange(e.target.checked, row, type)}
          checked={checked}
          name={type}
          value={type}
        />
      </div>
    );
  }

  const colorSettingsFormater = useCallback(
    row => {
      return (
        <div className="w-100 d-flex justify-content-center">
          <Tooltip title={gettext("Colors")} arrow>
            <IconButton
              onClick={() => {
                setBuildingId(row.Id);
                setSelectedColorSetting(row?.colorSettings);
                setColorMenuOpen(true);
              }}
            >
              <IoMdColorFill />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
    [gettext]
  );
  function columnFormater(params) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Tooltip title={gettext("Edit Building")} arrow>
          <IconButton
            onClick={() => {
              setNewBuildings([
                {
                  buildingAddress: params?.address,
                  buildingCountry: params?.country
                }
              ]);
              setEditBuildingId(params?.Id);
              setBuildingLabel(params?.label);
              setEdit(true);
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }}
          >
            <FaUserEdit />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  //---------------------------------------------//

  //-----------------DataGrid Columns-------------//
  const columns = [
    {
      field: "address",
      headerName: gettext("Address"),
      flex: 3,
      headerAlign: "left"
    },
    {
      field: "electricity",
      headerName: gettext("Electricity"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => electricityColumnFormater(params.row)
    },
    {
      field: "heating",
      headerName: gettext("Heating"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => heatingColumnFormater(params.row)
    },
    {
      field: "water",
      headerName: gettext("Water"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => waterColumnFormater(params.row)
    },
    {
      field: "gas",
      headerName: gettext("Gas"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => gasColumnFormater(params.row)
    },
    {
      field: "label",
      headerName: gettext("Label"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "electricity_green",
      headerName: gettext("Green Electricity"),
      flex: 1,
      hide: !writeAccess,
      headerAlign: "center",
      renderCell: params => checkBoxColumnFormater(params.row, "electricity")
    },
    {
      field: "heating_green",
      headerName: gettext("Green Heating"),
      flex: 1,
      hide: true,
      headerAlign: "center",
      renderCell: params => checkBoxColumnFormater(params.row, "heating")
    },
    {
      field: "water_green",
      headerName: gettext("Green Water"),
      flex: 1,
      hide: true,
      headerAlign: "center",
      renderCell: params => checkBoxColumnFormater(params.row, "water")
    },
    {
      field: "status",
      headerName: gettext("Active/Inactive"),
      flex: 1,
      hide: true,
      headerAlign: "center",
      renderCell: params => checkBoxColumnFormater(params.row, "status")
    },
    {
      field: "colorSettings",
      headerName: gettext("Colors"),
      flex: 1,
      headerAlign: "center",
      renderCell: params => colorSettingsFormater(params.row)
    },
    {
      field: "editBuilding",
      headerName: gettext("Actions"),
      flex: 1,
      headerAlign: "center",
      renderCell: params => columnFormater(params.row)
    }
  ];
  //---------------------------------------------//
  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <form onSubmit={handleSubmit}>
          <div className="table-div m-0">
            {/* begin::Header */}

            <div className="table-title">
              <h3>
                {/* <FormattedMessage id="AUTH.MENU.ADD_CUSTOMER" /> */}
                {gettext("Add New Building")}
              </h3>

              {/* <button
                className="col-xs-6 btn btn-primary d-flex align-items-center"
                type="submit"
                disabled={apiLoading?.loading}
              >
                {apiLoading?.loading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  <>
                    <MdAdd size={20} />
                    <FormattedMessage id="USER.LIST.CUSTOMERS.BUILDING.METERS.ADD_METER" />
                  </>
                )}
              </button> */}
              <span className="font-size-sm w-100">
                {cid + "'s"}
                {` ${gettext("Buildings")}`}
              </span>
            </div>

            <div className="form">
              <div className="card-body">
                {/* <div className="form-group">
                  <label>
                    <FormattedMessage id="USER.LIST.CUSTOMERS.ADD.SELECT_CUSTOMER" />
                  </label>

                  <Select
                    options={newCustomers.map(c => ({ value: c, label: c }))}
                    isLoading={isOptionsLoading}
                    onChange={opt => setInputNewTeam(opt.value)}
                    required
                  />
                </div> */}
                {/* <Paper sx={{ padding: 4, borderRadius: 4 }} elevation={2}> */}
                {/* <form onSubmit={handleSubmit}> */}
                <Stack spacing={4}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                          }}
                        >
                          <Typography
                            fontSize={16}
                            fontWeight={600}
                            align="left"
                          >
                            {gettext("Add Building")}
                          </Typography>
                        </Box>
                      </Grid>

                      {newBuildings.map((building, index) => (
                        <AddressesInput
                          gettext={gettext}
                          key={index}
                          address={building.buildingAddress}
                          onChangeAddress={onChangeAddress}
                          country={building.buildingCountry}
                          onChangeCountry={onChangeCountry}
                          disabled={false}
                          required={true}
                          index={index}
                        />
                      ))}
                    </Grid>
                  </Grid>
                  <Box>
                    <TextField
                      id="outlined-basic"
                      label={gettext("Label")}
                      variant="outlined"
                      className="h-5"
                      sx={{ width: "48.5%" }}
                      size="small"
                      value={buildingLabel}
                      onChange={e => setBuildingLabel(e.target.value)}
                      required
                    />
                  </Box>
                  <Box className="ml-auto mr-6">
                    <Button
                      variant="primary"
                      className="mr-2 btn btn-primary"
                      type="submit"
                      disabled={apiLoading?.loading}
                    >
                      {apiLoading?.loading || editMutation?.loading ? (
                        <Spinner animation="border" variant="dark" />
                      ) : (
                        <>{gettext("Save")}</>
                      )}
                    </Button>

                    <Button
                      variant="secondary"
                      type="reset"
                      value="reset"
                      className="btn btn-secondary"
                      onClick={reset}
                    >
                      {gettext("Reset")}
                    </Button>
                  </Box>
                </Stack>
                {/* </form> */}
                {/* </Paper> */}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="card card-custom example example-compact">
        <div className="table-div m-0">
          {/* begin::Header */}
          <div className="table-title">
            <h3 className="custom-header-list">
              <span>
                {cid + "'s"}
                {` ${gettext("Buildings")}`}
              </span>
            </h3>
            <div className="card-toolbar"></div>
            <span className="font-size-sm w-100">{gettext("Buildings")}</span>
          </div>
          {/* end::Header */}

          <div className="card-body customer-list">
            <DataGridComponent
              rows={buildings}
              columns={columns}
              loading={loading || updating}
              getRowId={row => row.Id}
              disableSelectionOnClick={true}
            />
          </div>
        </div>
      </div>

      <ColorPicker
        open={colorMenuOpen}
        gettext={gettext}
        onClose={() => setColorMenuOpen(false)}
        buildingId={buildingId}
        colorSettings={selectedColorSetting}
        update={update}
        updating={updating}
      />
      <Toast toastList={toastList} />
    </>
  );
}
