import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Box, Button, Chip, Grid, ListItemText } from "@mui/material";
import { IoCheckmark, IoChevronDown } from "react-icons/io5";

let options = ["All", "Active", "Inactive", "Deleted", "Blocked"];

export default function UserTableFilter({ setStatusFilter, gettext }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = index => {
    setSelectedIndex(index);
    // setStatusFilter(options[index]);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let status = options[selectedIndex];
    if (status === "All") {
      setStatusFilter("");
    } else {
      setStatusFilter(status);
    }
  }, [selectedIndex, setStatusFilter]);

  return (
    <>
      <Box
        alignitems="center"
        columnGap={1}
        sx={{
          p: 1,
          backgroundColor: "secondary.50",
          border: "1px solid",
          borderColor: "secondary.200",
          borderRadius: "8px"
        }}
      >
        <Grid item xs={12} sm={"auto"}>
          <Button
            id="basic-button"
            size="small"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            endIcon={<IoChevronDown size={12} />}
            color="secondary"
            onClick={handleClickListItem}
          >
            {gettext("Status")}

            <Chip
              sx={{
                ml: 0.5,
                backgroundColor: "primary.50",
                color: "primary.main"
              }}
              size="small"
              label={options[selectedIndex]}
            />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
              sx: { p: 0 }
            }}
            PaperProps={{
              sx: {
                p: 1,
                borderRadius: "8px",
                minWidth: "150px"
              }
            }}
          >
            {options.map((value, index) => (
              <MenuItem
                key={index}
                // selected={index === selectedIndex}
                sx={{
                  color:
                    index === selectedIndex ? "primary.main" : "secondary.main"
                }}
                onClick={() => handleMenuItemClick(index)}
              >
                {/* {value} */}
                <ListItemText>
                  {value === "All"
                    ? gettext("All")
                    : value === "Active"
                    ? gettext("Active")
                    : value === "Inactive"
                    ? gettext("Inactive")
                    : value === "Deleted"
                    ? gettext("Deleted")
                    : gettext("Blocked")}
                </ListItemText>
                {index === selectedIndex && (
                  <IoCheckmark color="#015731" size={16} />
                )}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Box>
    </>
  );
}
