import React from "react";
import client from "services/apiClient";
import { Table } from "react-bootstrap";
import { titleCase } from "title-case";
const { REACT_APP_API_URL } = process.env;
const CO2_REPORT_URL = REACT_APP_API_URL + "api/energy/electricity/";

class Co2 extends React.Component {
  constructor(props) {
    super(props);
    this.generateHeaders = this.generateHeaders.bind(this);
    this.generateRows = this.generateRows.bind(this);

    this.state = {
      apiTableCols: [],
      apiRowData: [],
      reportTitle: "Loading...",
      targetName: "Loading...",
      dateFrom: "0000-00-00",
      dateTo: "0000-00-00",
      notes: "",
      id: "test"
    };
  }
  componentDidMount() {
    let id = this.state.id;
    client.get(CO2_REPORT_URL + id).then(response => {
      let rows = [];
      let total = response.data.data.usage.months.total;
      let unit = response.data.data.usage.months.unit;
      let dateFrom = response.data.data.target.period.start;
      let dateTo = response.data.data.target.period.end;
      let cols = Object.keys(response.data.data.usage.months.details).map(
        function(key, value) {
          return {
            key: key,
            label: titleCase(key)
          };
        }
      );

      cols = [
        ...cols,
        {
          key: "total",
          label: "Total"
        }
      ];

      this.setState({ ...this.state, apiTableCols: cols });
      this.setState({ ...this.state, reportTitle: response.data.data.report });
      this.setState({
        ...this.state,
        targetName: response.data.data.target.name
      });
      this.setState({ ...this.state, dateFrom: dateFrom });
      this.setState({ ...this.state, dateTo: dateTo });
      this.setState({ ...this.state, notes: response.data.additional.test });

      let usageDataLength = response.data.data.usage.months.total.length;

      for (let i = 0; i < usageDataLength; i++) {
        let rowCols = [];
        cols.forEach(function(key, value) {
          // eslint-disable-next-line
          let selectedData = eval(
            "response.data.data.usage.months.details." + key.key
          );
          if (key.key === "total") {
            rowCols[key.key] = total[i] + " " + unit;
          } else {
            rowCols[key.key] = selectedData[i] + " " + unit;
          }
        });

        rows.push(rowCols);
      }
      this.setState({ ...this.state, apiRowData: rows });
    });
  }

  generateHeaders() {
    let cols = this.state.apiTableCols; // [{key, label}]
    return cols.map(function(colData) {
      return <th key={colData.key}>{colData.label}</th>;
    });
  }

  generateRows() {
    let cols = this.state.apiTableCols, // [{key, label}]
      data = this.state.apiRowData,
      rowCount = 0;

    if (data.length > 0) {
      return data.map(function(item) {
        let cells = cols.map(function(colData) {
          return <td key={colData.key}>{item[colData.key]}</td>;
        });
        return <tr key={"co2-row-" + rowCount++}>{cells}</tr>;
      });
    }
  }

  render() {
    let headers = this.generateHeaders();
    let rows = this.generateRows();

    return (
      <>
        <div className={"card card-custom gutter-b example example-compact"}>
          <div className={"card-header"}>
            <div className={"card-title"}>
              <h3 className={"card-label"}>{this.state.reportTitle}</h3>
            </div>
          </div>
          <div className={"card-body"}>
            <div className={"row"}>
              <div className={"col-sm-12"}>
                <h4>{this.state.targetName}</h4>
              </div>
              <div className={"col-sm-12"}>
                Period: {this.state.dateFrom} - {this.state.dateTo}
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-sm-12"}>
                <h4>Usage</h4>
              </div>
              <div className={"col-sm-12"}>
                <Table striped bordered hover>
                  <thead>
                    <tr>{headers}</tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              </div>
            </div>
          </div>
          {this.state.notes && (
            <div className={"card-footer"}>
              <div role="alert" className="fade alert alert-primary show">
                {this.state.notes}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Co2;
