import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Grid, Tab, Typography } from "@mui/material";
import { CodePreviewTab } from "app/features";
import { useTextdomainContext } from "app/providers";
import React from "react";

export default function AccessFromPartnerWebsite() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const [value, setValue] = React.useState("signup");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography fontSize={22} fontWeight={700}>
          {gettext("Access from partner website")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TabContext value={value}>
          <TabList onChange={handleChange}>
            <Tab label={gettext("Account creation code")} value="signup" />
            <Tab label={gettext("Login code")} value="signin" />
          </TabList>

          <TabPanel value="signup">
            <CodePreviewTab
              htmlFilePath={"/thirdParty/signup/fabeke-signup.html"}
            />
          </TabPanel>

          <TabPanel value="signin">
            <CodePreviewTab
              htmlFilePath={"/thirdParty/login/fabeke-login.html"}
            />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
}
