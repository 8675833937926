import { Box } from "@mui/material";
import React from "react";

export function ChartContainer({ children }) {
  return (
    <Box
      sx={{
        p: 1,
        height: "30vh",
        minHeight: "350px",
        border: "1px solid",
        borderColor: "secondary.200",
        borderRadius: "8px"
      }}
    >
      {children}
    </Box>
  );
}
