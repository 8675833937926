import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { DELETE_TEAM_MEMBER } from "../commands";

export function useDeleteTeamMember(
  onCompleted = () => {},
  onError = () => {}
) {
  const [comDeleteCustomerUser, { loading }] = useMutation(DELETE_TEAM_MEMBER, {
    onCompleted,
    onError,
    refetchQueries: ["comGetTeamMembers"]
  });

  const deleteMember = useCallback(
    variables => {
      comDeleteCustomerUser({
        variables
      });
    },
    [comDeleteCustomerUser]
  );

  return {
    deleteMember,
    deleting: loading
  };
}
