import React from "react";
import { NavLink } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Box, Typography } from "@mui/material";

export default function ProfileMenuItem({ link, icon, label, desc }) {
  return (
    <NavLink to={link}>
      <MenuItem sx={{ color: "primary.main" }}>
        <ListItemIcon sx={{ color: "inherit" }}>{icon}</ListItemIcon>
        <Box>
          <Typography
            variant="body1"
            color="gray"
            sx={{ fontSize: 16, fontWeight: "light" }}
          >
            {label}
          </Typography>
          <Typography variant="caption" color="gray">
            {desc}
          </Typography>
        </Box>
      </MenuItem>
    </NavLink>
  );
}
