import {
  useDashboardFilter,
  useTextdomainContext,
  useUser
} from "app/providers";
import { useMetersData } from "hooks";
import { useContext } from "react";

export function useTotalKwhM2DasboardData() {
  const {
    loading: filterLoading,
    selectedCompaniesString,
    selectedBuildingsString,
    fromDate,
    toDate
  } = useDashboardFilter();

  const { data, loading: chartDataLoading } = useMetersData({
    meterType: "energy",
    meteringPointId: "all",
    address: selectedBuildingsString,
    customer: selectedCompaniesString,
    fromDate,
    toDate,
    resolution: "m"
  });

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { colors } = useUser();

  const { energy, co2 } = colors || {};

  return {
    loading: filterLoading || chartDataLoading,
    catagories: data?.map(data => data.ts) || [],
    EcInKwhM2Series:
      [
        {
          name: gettext("Aggregated"),
          data: data?.map(data => data.vPerM2)
        }
      ] || [],
    Co2InKwhM2Series:
      [
        {
          name: gettext("Aggregated"),
          data: data?.map(data => data.co2PerM2)
        }
      ] || [],
    EcInKwhM2Colors: [energy?.shade1?.background] || ["#485561"],
    Co2InKwhM2Colors: [co2?.shade1?.background] || ["#c0c7b5"]
  };
}
