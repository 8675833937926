import React from "react";
import {
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import dateFormat from "dateformat";
import EmailIcon from "@mui/icons-material/Email";
import LaunchIcon from "@mui/icons-material/Launch";
import { useTextdomainContext } from "app/providers";
import { useNavigate } from "react-router-dom";
import { useNotificationsPreview } from "./hooks";
import { IoNotifications } from "react-icons/io5";

export function Notification() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const navigate = useNavigate();

  const [showNotificationCounter, setShowNotificationCounter] = React.useState(
    true
  );

  const { count, previewNotifications } = useNotificationsPreview(
    showNotificationCounter
  );

  const [notification, setNotification] = React.useState(null);

  const openNotification = Boolean(notification);

  const handleOpenNotification = event => {
    setNotification(event.currentTarget);
    setShowNotificationCounter(false);
  };
  const handleCloseNotification = () => {
    setNotification(null);
  };

  return (
    <>
      <Tooltip title={gettext("Notifications")} arrow>
        <IconButton sx={{ ml: 2 }} onClick={handleOpenNotification}>
          <Badge badgeContent={count} max={9} color="error">
            <IoNotifications size={24} color="#27272A" />
          </Badge>
        </IconButton>
      </Tooltip>

      <Box>
        <Menu
          anchorEl={notification}
          id="account-menu"
          open={openNotification}
          onClose={handleCloseNotification}
          onClick={handleCloseNotification}
          PaperProps={{
            style: {
              width: 400,
              maxHeight: 400,
              overflowY: "scroll"
            },
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0,
              "& .MuiAvatar-root": {
                width: 82,
                height: 82,
                ml: 0,
                mr: 0
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 5,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0
              }
            }
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top"
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom"
          }}
        >
          <Box
            height={60}
            borderBottom={0.5}
            className="px-4 d-flex align-items-center"
          >
            <Typography fontSize={22} fontWeight={700}>
              {gettext("Notifications")}
            </Typography>
            <Tooltip
              title={gettext("See All Notifications")}
              arrow
              style={{ marginLeft: "auto" }}
            >
              <IconButton onClick={() => navigate("/notifications")}>
                <LaunchIcon size={16} />
              </IconButton>
            </Tooltip>
          </Box>
          {previewNotifications?.map(notify => {
            return (
              <Box key={notify?.id}>
                <MenuItem
                  sx={{ color: "primary.main" }}
                  onClick={() => navigate("/notifications")}
                >
                  <ListItemIcon
                    sx={{ color: "inherit" }}
                    style={{ marginBottom: 62 }}
                  >
                    <EmailIcon fontSize="medium" />
                  </ListItemIcon>
                  <Box component="div" whiteSpace="normal">
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{
                        fontSize: 16,
                        fontWeight: "light"
                      }}
                      textOverflow="hidden"
                    >
                      {notify?.Message}
                    </Typography>
                    <Typography variant="caption" color="gray">
                      {dateFormat(
                        notify?.date,
                        "dddd, mmm dS, yyyy, h:MM:ss TT"
                      )}
                    </Typography>
                  </Box>
                </MenuItem>
                <Divider />
              </Box>
            );
          })}
          <Divider />
        </Menu>
      </Box>
    </>
  );
}
