import { Button, Grid } from "@mui/material";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Modal } from "react-bootstrap";
import { useUpdate } from "../hooks";

export function EditModal({ show, onHide, editableRow, id, onUpdateHandler }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [accountNumber, setAccountNumber] = useState(0);

  const [accountName, setAccountName] = useState("Account Name");

  const [period, setPeriod] = useState(0);

  const [yearBefore, setYearBefore] = useState(0);

  useEffect(() => {
    if (editableRow) {
      setAccountNumber(editableRow?.accountNumber);
      setAccountName(editableRow?.accountName);
      setPeriod(editableRow?.period);
      setYearBefore(editableRow?.yearBefore);
    }
  }, [editableRow]);

  const { update } = useUpdate();

  const submitHandler = useCallback(
    e => {
      e.preventDefault();
      update(
        id,
        {
          account_number: accountNumber,
          account_name: accountName,
          period,
          year_before: yearBefore
        },
        editableRow?.accountNumber,
        onUpdateHandler
      );
    },
    [
      id,
      accountNumber,
      accountName,
      period,
      yearBefore,
      editableRow,
      update,
      onUpdateHandler
    ]
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{gettext("Update Account Data")}</Modal.Title>
      </Modal.Header>
      <form onSubmit={submitHandler}>
        <Modal.Body>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <label>{gettext("No.")}</label>
              <input
                type="number"
                className="form-control"
                required
                placeholder="Account Number"
                value={accountNumber}
                onChange={e => setAccountNumber(e.target.value)}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <label>{gettext("Name")}</label>
              <input
                type="text"
                className="form-control"
                required
                placeholder="Account Name"
                value={accountName}
                onChange={e => setAccountName(e.target.value)}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <label>{gettext("The period")}</label>
              <input
                type="number"
                className="form-control"
                required
                placeholder="Period"
                value={period}
                onChange={e => setPeriod(e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <label>{gettext("The year before")}</label>
              <input
                type="number"
                className="form-control"
                required
                placeholder="Year Before"
                value={yearBefore}
                onChange={e => setYearBefore(e.target.value)}
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={onHide}>
            {gettext("Cancel")}
          </Button>
          <Button type="submit" variant="contained">
            {gettext("Save")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
