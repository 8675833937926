import React, { useContext } from "react";
import ProfileMenuItem from "../MenuItem";
import Logout from "@mui/icons-material/Logout";
import { useTextdomainContext } from "app/providers";

export default function ProfileMenu() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  return (
    <ProfileMenuItem
      link={"/logout"}
      icon={<Logout />}
      label={gettext("Sign Out")}
      desc={null}
    />
  );
}
