import gql from "graphql-tag";

export const CREATE_ACCOUNT_GROUPS = gql`
  mutation insertAccountGroup(
    $nameDa: String
    $nameDe: String
    $nameEn: String
    $type: String
  ) {
    insertAccountGroup(
      nameDa: $nameDa
      nameDe: $nameDe
      nameEn: $nameEn
      type: $type
    ) {
      success
      message
    }
  }
`;

export const UPDATE_ACCOUNT_GROUP = gql`
  mutation updateAccountGroup(
    $id: String!
    $nameDa: String
    $nameDe: String
    $nameEn: String
    $type: String
  ) {
    updateAccountGroup(
      id: $id
      updateData: {
        nameDa: $nameDa
        nameDe: $nameDe
        nameEn: $nameEn
        type: $type
      }
    ) {
      success
      message
    }
  }
`;

export const DELETE_ACCOUNT_GROUP = gql`
  mutation deleteAccountGroup($id: String!) {
    deleteAccountGroup(id: $id) {
      success
      message
    }
  }
`;

export const CREATE_ACCOUNT_CHART = gql`
  mutation insertChartAccount(
    $accountNumber: Int!
    $accountGroupId: String!
    $nameDa: String
    $nameDe: String
    $nameEn: String
    $category: String
    $tab: String
  ) {
    insertChartAccount(
      accountNumber: $accountNumber
      accountGroupId: $accountGroupId
      nameDa: $nameDa
      nameDe: $nameDe
      nameEn: $nameEn
      category: $category
      tab: $tab
    ) {
      success
      message
    }
  }
`;

export const UPDATE_ACCOUNT_CHART = gql`
  mutation updateChartAccount(
    $id: String!
    $accountNumber: Int
    $accountGroupId: String
    $nameDa: String
    $nameDe: String
    $nameEn: String
    $category: String
    $tab: String
  ) {
    updateChartAccount(
      id: $id
      updateData: {
        accountNumber: $accountNumber
        accountGroupId: $accountGroupId
        nameDa: $nameDa
        nameDe: $nameDe
        nameEn: $nameEn
        category: $category
        tab: $tab
      }
    ) {
      success
      message
    }
  }
`;

export const DELETE_ACCOUNT_CHART = gql`
  mutation deleteChartAccount($id: String!) {
    deleteChartAccount(id: $id) {
      success
      message
    }
  }
`;

export const CHANGE_PASSWORD_MUTATIION = gql`
  mutation comUpdatePassword($currentPassword: String, $newPassword: String) {
    comUpdatePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      success
      message
    }
  }
`;

export const CREATE_EMISSION_FACTOR_MUTATION = gql`
  mutation insertEmissionFactor(
    $data: EmissionFactorData!
    $group: String!
    $key: String!
    $parameter1: String!
    $parameter2: String!
    $unit: String!
    $year: Int!
  ) {
    insertEmissionFactor(
      data: $data
      group: $group
      key: $key
      parameter1: $parameter1
      parameter2: $parameter2
      unit: $unit
      year: $year
    ) {
      success
      message
    }
  }
`;

export const UPDATE_EMISSION_FACTOR_MUTATION = gql`
  mutation updateEmissionFactor(
    $id: String!
    $data: EmissionFactorData!
    $group: String!
    $key: String!
    $parameter1: String!
    $parameter2: String!
    $unit: String!
    $year: Int!
  ) {
    updateEmissionFactor(
      id: $id
      emissionFactorData: {
        data: $data
        group: $group
        key: $key
        parameter1: $parameter1
        parameter2: $parameter2
        unit: $unit
        year: $year
      }
    ) {
      success
      message
    }
  }
`;

export const DELETE_EMISSION_FACTOR = gql`
  mutation deleteEmissionFactor($id: String!, $year: Int!) {
    deleteEmissionFactor(id: $id, year: $year) {
      success
      message
    }
  }
`;

export const GENERATE_PDF_REPORT = gql`
  mutation generatePdfReport($pdfJsonSettngs: JSONString!) {
    generatePdfReport(data: $pdfJsonSettngs) {
      success
      message
      filePath
    }
  }
`;

export const GENERATE_GHG_PDF_REPORT = gql`
  mutation generateGhgPdfReport($pdfJsonSettngs: JSONString!) {
    generateGhgPdfReport(data: $pdfJsonSettngs) {
      success
      message
      filePath
    }
  }
`;

export const SWITCH_TEAM = gql`
  mutation switchTeam($teamId: Int!) {
    switchTeam(teamId: $teamId) {
      success
      message
    }
  }
`;

export const ADD_PARTNER_MEMBER = gql`
  mutation comAddPartnerMember($customerName: String!, $customerKey: String!) {
    comAddPartnerMember(
      customerName: $customerName
      customerKey: $customerKey
    ) {
      success
      message
    }
  }
`;

export const ADD_USER_BY_ADMIN = gql`
  mutation comCreateUserAdmin(
    $countryCode: String
    $customerKey: String
    $email: String
    $lang: String
    $name: String
    $password: String
    $role: [String]
    $status: String
    $timezone: String
  ) {
    comCreateUserAdmin(
      countryCode: $countryCode
      customerKey: $customerKey
      email: $email
      lang: $lang
      name: $name
      password: $password
      role: $role
      status: $status
      timezone: $timezone
    ) {
      success
      message
    }
  }
`;

export const GENERATE_API_KEY = gql`
  mutation comApiKeyRequest($label: String) {
    comApiKeyRequest(label: $label) {
      success
      message
      token
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation comForgetPassword($email: String!) {
    comForgetPassword(email: $email) {
      success
      message
    }
  }
`;

export const DELETE_API_KEY = gql`
  mutation comDeleteApiKey($email: String, $label: String) {
    comDeleteApiKey(email: $email, label: $label) {
      success
      message
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation comUpdateCustomer(
    $customerData: CustomerUpdateInput
    $customerId: String!
  ) {
    comUpdateCustomer(customerData: $customerData, customerId: $customerId) {
      success
      message
    }
  }
`;
export const ADD_NEW_BUILDING = gql`
  mutation comCustomerAddBuildings(
    $buildings: [RegisterFlowBuildingInput]
    $customer: String
  ) {
    comCustomerAddBuildings(buildings: $buildings, customer: $customer) {
      success
      message
    }
  }
`;
export const EDIT_CUSTOMER_BUILDING = gql`
  mutation editCustomerBuilding(
    $buildingId: String
    $customerName: String
    $data: JSONString
  ) {
    editCustomerBuilding(
      buildingId: $buildingId
      customerName: $customerName
      data: $data
    ) {
      success
      message
    }
  }
`;
