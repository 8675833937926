import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { SUBMIT_CUSTOMER } from "../commands";

export default function useSubmit(onCompleted = () => {}, onError = () => {}) {
  const [comCustomerUpdateRegisterFlow, { loading }] = useMutation(
    SUBMIT_CUSTOMER,
    {
      onCompleted,
      onError
    }
  );

  const submit = useCallback(
    variables => {
      comCustomerUpdateRegisterFlow({
        variables: variables,
        refetchQueries: ["getUserCustomers"]
      });
    },
    [comCustomerUpdateRegisterFlow]
  );

  return {
    submit,
    submiting: loading
  };
}
