import React, { useState, useContext, useCallback } from "react";
import { useTextdomainContext } from "app/providers";
import { useInviteCustomerUser } from "./hooks";
import {
  Alert,
  Box,
  Button,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useCustomerRolesById } from "../CustomerRolesTable/hooks/useCustomerRolesById";

export function InviteCustomerUserForm({
  open,
  handleClose,
  selectedCustomer
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const [inputEmail, setinputEmail] = useState("");

  const [inputRole, setinputRole] = useState("");

  const resetForm = useCallback(() => {
    setinputEmail("");
    setinputRole("");
  }, []);

  // const { customerRoles } = useCustomerRoles();

  const { customerRoles, error } = useCustomerRolesById(selectedCustomer.Id);

  const { invite, loading: inviting } = useInviteCustomerUser(
    () => {
      resetForm();
      handleClose();
      enqueueSnackbar(gettext("User has been Invited Successfully"), {
        variant: "success"
      });
    },
    error => {
      enqueueSnackbar(
        gettext(
          "Error: You cannot invite. Only Company owner can invite a user!"
        ),
        {
          variant: "error"
        }
      );
    }
  );

  const submitHandler = e => {
    e.preventDefault();

    invite({
      customerId: selectedCustomer.Id,
      email: inputEmail,
      userRole: inputRole
    });
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: theme => theme.zIndex.appBar + 1
      }}
      PaperProps={{
        sx: { padding: 4, maxWidth: 720 }
      }}
    >
      <Box sx={{ width: "auto" }}>
        <form onSubmit={submitHandler}>
          <Stack spacing={4} sx={{ height: "100%" }}>
            <Typography fontSize={22} fontWeight={700}>
              {gettext("Invite User")}
            </Typography>

            <Box sx={{ height: "100%" }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    type="email"
                    label={gettext("Email")}
                    placeholder={gettext("Enter user email")}
                    size="small"
                    required
                    fullWidth
                    autoComplete="off"
                    value={inputEmail}
                    onChange={e => {
                      setinputEmail(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      {gettext("Role")}
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      fullWidth
                      value={inputRole}
                      label={gettext("Role")}
                      onChange={e => setinputRole(e.target.value)}
                    >
                      {customerRoles?.map(customerRole => (
                        <MenuItem value={customerRole.name}>
                          {customerRole.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {error ? (
                    <Stack spacing={2}>
                      <Alert severity="error">{`${error}`}</Alert>
                    </Stack>
                  ) : null}
                </Grid>
              </Grid>
            </Box>

            <Paper elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={6} textAlign="center">
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    {gettext("Cancel")}
                  </Button>
                </Grid>

                <Grid item xs={6} textAlign="center">
                  <LoadingButton
                    variant="contained"
                    loading={inviting}
                    fullWidth
                    type="submit"
                    disable={error ? true : false}
                  >
                    {gettext("Invite")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </form>
      </Box>
    </Drawer>
    // <div className="card card-custom gutter-b example example-compact">
    //   <div className="table-div">
    //     {/* begin::Header */}
    //     <div className="table-title">
    //       <h3>{gettext("Add User")}</h3>
    //     </div>
    //     {/* end::Header */}
    //     <div className="card-body">

    //     </div>
    //   </div>
    //   {updating || countriesLoading || timezonesLoading ? (
    //     <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
    //       <Spinner animation="border" variant="light" />
    //     </div>
    //   ) : null}
    // </div>
  );
}
