import { apiRoutes } from "../utils";
import client from "./apiClient";

export function getAdminEnergySupplierList(type) {
  return client.get(apiRoutes.AdminEnergySupplir.path + "?type=" + type).then(
    response => {
      return response.data;
    },
    err => {
      return err;
      // console.log(err);
    }
  );
}

export function addAdminEnergySupplier(type, params) {
  return client
    .post(apiRoutes.AdminEnergySupplir.path + "?type=" + type, params)
    .then(
      response => {
        return response;
      },
      error => {
        return error;
      }
    );
}

export function editAdminEnergySupplier(type, params) {
  return client
    .put(apiRoutes.AdminEnergySupplir.path + "?type=" + type, params)
    .then(
      response => {
        return response;
      },
      error => {
        return error;
      }
    );
}

export function deleteAdminEnergySupplierSvc(params) {
  const url =
    apiRoutes.AdminEnergySupplir.path +
    `?id=${params.id}&type=${params.type}&name=${params.name}`;
  return client.delete(url).then(
    response => {
      return response;
    },
    error => {
      return error;
    }
  );
}
