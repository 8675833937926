import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_USERS } from "../commands";

export function useCustomerUsers(customerName) {
  const { data, loading } = useQuery(GET_CUSTOMER_USERS, {
    variables: {
      customerName
    }
  });

  return {
    customerUsers: data?.comGetTeamMembers || [],
    loading
  };
}
