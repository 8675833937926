import {
  Box,
  Button,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import {
  useDashboardFilter,
  useTextdomainContext,
  useUser
} from "app/providers";
import html2canvas from "html2canvas";
import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { IoCheckmark } from "react-icons/io5";
import { useAddGraphImage, useDeleteGraphImage } from "./hooks";

export function AccessControl({ setLoading }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { user } = useUser();

  const { currentTeamId } = user || {};

  const {
    wrapperRef,
    selectedDashboard,
    isDashboardPublic,
    selectedBuildings,
    selectedCompanies,
    publicURL
  } = useDashboardFilter();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const options = React.useMemo(
    () => [
      { value: "private", label: gettext("Private access") },
      { value: "public", label: gettext("Public access") }
    ],
    [gettext]
  );

  const selectedOption = options[+isDashboardPublic];

  const { addDashboard, isAdding } = useAddGraphImage();

  const { deleteDashboard, isDeleting } = useDeleteGraphImage();

  React.useEffect(() => {
    setLoading(isAdding || isDeleting);
  }, [isAdding, isDeleting, setLoading]);

  const addDashboardToPublic = React.useCallback(() => {
    const node = wrapperRef.current;
    const buildingNames = selectedBuildings.map(building => building?.address);
    const companyNames = selectedCompanies.map(company => company?.name);
    setLoading(true);
    html2canvas(node)
      .then(canvas => {
        const imgData = canvas.toDataURL("image/png");

        addDashboard({
          customerId: currentTeamId,
          dashboard: selectedDashboard.key,
          imageBytes: imgData.replace(
            /^data:image\/(png|jpeg|jpg);base64,/,
            ""
          ),
          title: selectedDashboard.label,
          dashboardName: selectedDashboard.label,
          companies: companyNames,
          buildings: buildingNames
        });
      })
      .finally(() => setLoading(false));
  }, [
    addDashboard,
    wrapperRef,
    currentTeamId,
    selectedDashboard,
    selectedBuildings,
    selectedCompanies,
    setLoading
  ]);

  const removeDashboardFromPublic = React.useCallback(() => {
    setLoading(true);
    deleteDashboard({
      customerId: currentTeamId,
      dashboard: selectedDashboard.key
    });
    setLoading(false);
  }, [deleteDashboard, selectedDashboard, currentTeamId, setLoading]);

  // const [copied, setCopied] = React.useState(false);

  // const handleCopyClick = () => {
  //   if (isDashboardPublic) {
  //     navigator.clipboard.writeText(publicURL);
  //     // setCopied(true);
  //   }
  // };

  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid",
        borderRadius: "8px",
        borderColor: "secondary.200"
      }}
    >
      {/* <Stack
        direction={"row"}
        
        alignItems="center"
        onClick={handleCopyClick}
      > */}

      {isDashboardPublic ? (
        <Link
          href={publicURL}
          target="_blank"
          underline="none"
          sx={{ width: "60%", cursor: "pointer" }}
          alignItems="center"
        >
          <Typography
            fontSize={14}
            fontWeight={400}
            noWrap
            color={"secondary.600"}
          >
            {publicURL}
          </Typography>
        </Link>
      ) : (
        <Link
          disabled
          underline="none"
          sx={{ width: "60%", cursor: "default" }}
          alignItems="center"
        >
          <Typography
            fontSize={14}
            fontWeight={400}
            noWrap
            color={"secondary.200"}
          >
            {publicURL}
          </Typography>
        </Link>
      )}

      {/* {isDashboardPublic && (
          <Tooltip open={true} arrow title="Add">
            <IoCopy size={26} />
          </Tooltip>
        )} */}
      {/* </Stack> */}

      <Button
        variant="text"
        size="small"
        endIcon={<BiChevronDown />}
        sx={{ whiteSpace: "nowrap" }}
        color={isDashboardPublic ? "primary" : "secondary"}
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        {selectedOption.label}
      </Button>

      <Menu
        id="public- menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            boxShadow:
              "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)"
          }
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              index === 0
                ? removeDashboardFromPublic()
                : addDashboardToPublic();
              setAnchorEl(null);
            }}
            sx={{
              color:
                selectedOption.value === option.value
                  ? "primary.main"
                  : "secondary.main"
            }}
          >
            <ListItemText>{option.label}</ListItemText>
            {selectedOption.value === option.value && <IoCheckmark size={16} />}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
