import { useQuery } from "@apollo/client";
import { GET_USER_NAME } from "../commands";

export function useGetUserName(userId) {
  const { data, loading } = useQuery(GET_USER_NAME, {
    variables: {
      userId
    }
  });

  return {
    userName: data?.comGetUser?.name || "",
    loading
  };
}
