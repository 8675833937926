import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
import { Spinner } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ReactTooltip from "react-tooltip";
// import { DECIMAL } from "../../../common/Constants";
import { Snackbar } from "@mui/material";
import Alert from "../components/Alert";
import { totalEnergyCo2, co2GeneratePdf } from "../partials/Functions";
// import { localeNumberFormat } from "../../../common/Functions";

function Co2FirstSection(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { isLoading } = props;
  let company = props.data.customers_names ? props.data.customers_names : "...";
  let address = props.data.buildings ? props.data.buildings : "...";
  // let userId = props.data.user_id?props.data.user_id:0;
  // let teamId = props.data.team_id?props.data.team_id:0;
  let year1 = props.data.to_year ? props.data.to_year : "";
  // let year2 = props.data.second_year?props.data.second_year:'';
  let year3 = props.data.from_year ? props.data.from_year : "";
  let totalEnergyCo2Year1 = 0;
  let totalEnergyCo2Year3 = 0;

  // const {REACT_APP_PDF_END_URL} = process.env;

  // const [introduction, changeIntroduction] = useState('...')
  const [snackbarOpen, changeSnackbarOpen] = useState(false);
  // const [pdfLoading, changePdfLoading] = useState(false)
  const pdfLoading = false;
  const [pdfButtonDisabled, changePdfButtonDisabled] = useState(true);

  if (props.data.electricity || props.data.heating || props.data.water) {
    totalEnergyCo2Year1 = totalEnergyCo2(props.data, year1);
    totalEnergyCo2Year3 = totalEnergyCo2(props.data, year3);
  }

  // let totalEnergyCo2Year1Formatted = localeNumberFormat(
  //   totalEnergyCo2Year1,
  //   DECIMAL
  // );
  // let totalEnergyCo2Year3Formatted = localeNumberFormat(
  //   totalEnergyCo2Year3,
  //   DECIMAL
  // );

  address = "";

  // useEffect(() => {
  //     getSetting('co2_introduction_detail')
  //         .then((result) => {
  //             console.log(result)
  //             changeIntroduction(result);
  //         });
  // }, [])

  useEffect(() => {
    changePdfButtonDisabled(props.showProgress);
  }, [props.showProgress]);

  // const saveIntroduction = (e) => {
  //     let newValue = e.value;
  //     setSetting('co2_introduction_detail', newValue)
  //         .then((res) => {
  //             handleClickSnackbar();
  //         });
  // }

  // const handleClickSnackbar = () => {
  //     changeSnackbarOpen(true);
  // }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    changeSnackbarOpen(false);
  };

  const generatePdf = () => {
    changePdfButtonDisabled(true);
    co2GeneratePdf(props.data, gettext)
      .then(() => {
        changePdfButtonDisabled(false);
      })
      .catch(err => {
        changePdfButtonDisabled(false);
      })
      .finally(() => {
        changePdfButtonDisabled(false);
      });
  };

  return (
    <>
      <div className={"card card-custom gutter-b example example-compact"}>
        <div className="card-header py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {company}
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              {address}
            </span>
          </h3>
          {/*{this.props.showToolbar && (*/}
          {/*{false && (*/}
          <div className="card-toolbar">
            <button
              className="btn btn-danger font-weight-bolder font-size-sm"
              onClick={() => generatePdf()}
              disabled={pdfButtonDisabled || isLoading}
            >
              {pdfLoading && (
                <span className="svg-icon svg-icon-md svg-icon-white spinner">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/files/pdf.svg")}
                    className="h-50 align-self-center"
                  />
                </span>
              )}
              {/*{!this.props.pdfLoading && (*/}
              <span className="svg-icon svg-icon-md svg-icon-white">
                <SVG
                  src={toAbsoluteUrl("/media/svg/files/pdf.svg")}
                  className="h-50 align-self-center"
                />
              </span>
              {/*)}*/}
              {gettext("Download PDF")}
              {(pdfButtonDisabled || isLoading) && (
                <Spinner className="btn-spinner-co2" animation="border" />
              )}
            </button>
          </div>
          {/*)}*/}
        </div>
        <div className={"card-body"}>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <div className={"row"}>
                <ReactTooltip />

                <div className={"col-sm-12"}>
                  <h3>{gettext("Introduction")}</h3>
                  <p>
                    {company && company + "'s "}
                    {gettext(
                      "CO₂ report 2020 describes the CO₂ emissions for all the company's sources of supply: water, wastewater, heat and electricity. The CO₂ report makes it possible to follow the CO₂ emissions, and contributes to making decisions on an informed and database basis."
                    )}
                  </p>
                  <p>
                    {gettext(
                      "The basis for this report is The Greenhouse Gas Protocol, which is an internationally recognized tool for calculating and reporting greenhouse gas emissions. By using The Greenhouse Gas Protocol it is possible to compare with other similar companies, properties and public institutions."
                    )}
                  </p>
                  <p>
                    {gettext(
                      "The last page lists the assumptions and sources on which the calculation of CO₂ consumption is based in this report."
                    )}
                  </p>
                  <p>
                    {gettext(
                      "The report uses the 125% method for emissions, which is prescribed by the Danish Energy Agency."
                    )}
                  </p>

                  {/* <div >
                                        <EditTextarea
                                            rows={8}
                                            onEditMode='No'
                                            // value={introduction}
                                            defaultValue={introduction}
                                            // onSave={saveIntroduction}
                                            key={introduction}
                                            className={"edit-text-area"}
                                            readonly={true}
                                        />
                                    </div> */}
                </div>

                <div className={"col-sm-12"}>
                  <h3>{gettext("Summary")}</h3>
                  <p>{gettext("Below are the 3 previous years.")}</p>
                  <p>
                    {gettext("The total CO₂ emissions")}
                    {/* {year1: year1,
                    totalEnergyCo2Year1: totalEnergyCo2Year1Formatted} */}
                  </p>
                  {totalEnergyCo2Year1 < totalEnergyCo2Year3 && (
                    <p>
                      {gettext("Total CO₂ emissions fell from")}
                      {" " + totalEnergyCo2Year3 + " "}
                      {gettext("tonnes of CO₂ in")}
                      {" " + year3 + " "}
                      {gettext("to")}
                      {" " + totalEnergyCo2Year1 + " "}
                      {gettext("tonnes of CO₂ in")}
                      {" " + year1}
                    </p>
                  )}

                  {totalEnergyCo2Year1 > totalEnergyCo2Year3 && (
                    <p>
                      {gettext("Total CO₂ emissions increased from")}
                      {" " + totalEnergyCo2Year3 + " "}
                      {gettext("tonnes of CO₂ in")}
                      {" " + year3 + " "}
                      {gettext("to")}
                      {" " + totalEnergyCo2Year1 + " "}
                      {gettext("tonnes of CO₂ in")}
                      {" " + year1}
                    </p>
                  )}
                  {totalEnergyCo2Year1 === totalEnergyCo2Year3 && (
                    <p>
                      {`${gettext(
                        "The total CO₂ emissions have been constant at"
                      )} ${" " + totalEnergyCo2Year3 + " "} ${gettext(
                        "tonnes of CO₂ in the period"
                      )} ${" " + year3 + " "} ${gettext("to")} ${" " + year1}`}
                    </p>
                  )}
                  <p>
                    {gettext(
                      "The CO emissions emitted by the individual supply sources are stated under the individual sections for water, electricity, and heat."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Record has saved
        </Alert>
      </Snackbar>
    </>
  );
}

export default injectIntl(Co2FirstSection);
