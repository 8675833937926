import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Button, Spinner } from "react-bootstrap";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import { useParams } from "react-router-dom";
import { GoBack } from "app/components";
import { useAddMeter } from "./hooks";
import { Autocomplete, TextField } from "@mui/material";
import { useProvidersList } from "app/pages/admin/service-providers/hooks";
import AddServiceProviderModal from "../AddServiceProviderModal";

// let meterOption = [
//     { value: "heating", lable: "Heating" },
//     { value: "gas", lable: "Natural Gas" },
// ];

export function AddMeterForm() {
  const { bid, type } = useParams();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [inputMeterNumber, setinputMeterNumber] = useState("");
  // const [selectMeterType, setSelectMeterType] = useState("heating");
  const [provider, setProvider] = useState(null);
  const [providerUsername, setProviderUsername] = useState("");
  const [providerPassword, setProviderPassword] = useState("");
  const [inputMeterLabel, setinputMeterLabel] = useState("");
  const { providersList } = useProvidersList({ category: type });
  const [providerModal, setProviderModal] = useState(false);
  const [showSuccessMessage, setshowSuccessMessage] = useState(false);

  const [showErrorMessage, setshowErrorMessage] = useState(false);

  const reset = () => {
    setinputMeterNumber("");
    setinputMeterLabel("");
    setProvider(null);
    setProviderUsername("");
    setProviderPassword("");
  };

  const { addMeter, loading } = useAddMeter(
    () => {
      setshowSuccessMessage(true);

      setTimeout(() => {
        setshowSuccessMessage(false);
      }, 5000);

      reset();
    },
    () => {
      setshowErrorMessage(true);
      setTimeout(() => {
        setshowErrorMessage(false);
      }, 5000);
    }
  );

  // const handleOptionSelect = (event) => {
  //     setSelectMeterType(event.target.value);
  // };

  const submitHandler = e => {
    e.preventDefault();

    setshowSuccessMessage(false);
    setshowErrorMessage(false);

    addMeter({
      buildingId: bid,
      meterNo: inputMeterNumber,
      label: inputMeterLabel,
      supplier: provider?.name || null,
      providerUsername,
      providerPassword,
      sourceType: type
    });
  };

  return (
    <>
      <GoBack />
      <div className="card card-custom example example-compact">
        <form onSubmit={submitHandler}>
          <div className="table-div">
            {/* begin::Header */}
            <div className="table-title">
              <h3 className="col-xs-6">{gettext("Add Meter")}</h3>
            </div>
            {/* end::Header */}

            {
              <Collapse in={showSuccessMessage}>
                <Alert severity="success">
                  <AlertTitle>{gettext("Success")}</AlertTitle>
                  {gettext("Meter Has Been Added Successfully")}
                </Alert>
              </Collapse>
            }
            {
              <Collapse in={showErrorMessage}>
                <Alert severity="error">
                  <AlertTitle>{gettext("Error")}</AlertTitle>
                  {gettext("There was a problem adding Meter")}
                </Alert>
              </Collapse>
            }

            <div className="card-body">
              <div className="form-group">
                <label>{gettext("Meter No")}</label>
                <input
                  type="number"
                  className="form-control"
                  id="meterNumber"
                  name="meterNumber"
                  required
                  placeholder={gettext("Enter Meter Number")}
                  value={inputMeterNumber}
                  onChange={e => setinputMeterNumber(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <label>{gettext("Meter Label")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="meterLabel"
                  name="meterLabel"
                  required
                  placeholder={gettext("Enter Meter Label")}
                  value={inputMeterLabel}
                  onChange={e => setinputMeterLabel(e.target.value)}
                  autoComplete="off"
                />
              </div>
              {/*type === "heating" && (
                <div className="form-group">
                  <label>{gettext("Select Meter")}</label>
                  <Select
                    fullWidth
                    className="meter-dropdown"
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    options={meterOption}
                    // open={open}
                    // onClose={handleClose}
                    // onOpen={handleOpen}
                    value={selectMeterType}
                    // label="Age"
                    onChange={handleOptionSelect}
                    sx={{
                      backgroundColor: "#ffffff !important"
                    }}
                  >
                    <MenuItem value={"heating"}>
                      {" "}
                      {gettext("District Heating")}
                    </MenuItem>
                    <MenuItem value={"gas"}>{gettext("Natural Gas")}</MenuItem>
                  </Select>
                </div>
                  )*/}
              <div className="form-group">
                <label>{gettext("Service Provider")}</label>
                <Autocomplete
                  disablePortal
                  name="providers-selection"
                  id="providers-selection"
                  getOptionLabel={option => option.name || ""}
                  value={provider}
                  inputProps={{
                    form: {
                      autoComplete: "off"
                    }
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return option.Id === value.Id;
                  }}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "clear") {
                      setProvider(null);
                      setProviderUsername("");
                      setProviderPassword("");
                      return;
                    } else {
                      const supplier = providersList.filter(
                        p => p.name === newInputValue
                      )[0];
                      if (supplier) {
                        setProvider(supplier);
                      }
                    }
                  }}
                  options={providersList}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      style={{ backgroundColor: "white" }}
                    />
                  )}
                />
                <span id="providerSelection" class="form-text">
                  <Button
                    className="btn btn-primary"
                    onClick={() => setProviderModal(true)}
                  >
                    Add a New Provider
                  </Button>
                </span>
              </div>
              <div className="form-group">
                <label>{gettext("Provider Username")}</label>
                <input
                  type="text"
                  disabled={
                    (providersList && !providersList.length) || !provider
                  }
                  className="form-control"
                  id="providerUsername"
                  name="providerUsername"
                  placeholder={gettext("Enter Provider Username")}
                  value={providerUsername}
                  onChange={e => setProviderUsername(e.target.value)}
                  required={provider ? true : false}
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <label>{gettext("Provider Password")}</label>
                <input
                  type="password"
                  disabled={
                    (providersList && !providersList.length) || !provider
                  }
                  className="form-control"
                  id="providerPassword"
                  name="providerPassword"
                  placeholder={gettext("Enter Provider Password")}
                  value={providerPassword}
                  onChange={e => setProviderPassword(e.target.value)}
                  required={provider ? true : false}
                  autoComplete="new-password"
                />
              </div>
              <div className="card-actions" style={{ textAlign: "right" }}>
                <button
                  className="btn btn-primary m-2 mr-0"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    <>{gettext("Save")}</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <AddServiceProviderModal
        category={type}
        providerModal={providerModal}
        setProviderModal={setProviderModal}
      />
    </>
  );
}
