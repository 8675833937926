import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import EnergyHourlyPrice from "./EnergyHourlyPrice";
import { useParams } from "react-router-dom";

const EnergyPrices = () => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { energyType, country, date } = useParams();

  return (
    <>
      <EnergyHourlyPrice
        type="energy"
        sourceType="Energy"
        gettext={gettext}
        country={country}
        date={date}
        energyType={energyType}
      />
    </>
  );
};

export default EnergyPrices;
