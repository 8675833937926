import { Box } from "@mui/material";
import React from "react";

export function HtmlWebPreview({ htmlCode }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "scroll",
        "&::-webkit-scrollbar": {
          width: 0,
          height: 0
        }
      }}
      dangerouslySetInnerHTML={{ __html: htmlCode }}
    />
  );
}
