import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import EditModel from "./components/EditModel";
import { DataGridComponent } from "app/components";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import { useEnergySuppliers } from "./hooks";
import { IconButton, Tooltip } from "@mui/material";

export function EnergySupplierList(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { types, countries, countriesLoading } = props;

  const [inputType, setinputType] = useState("electricity");

  const [editableRow, seteditableRow] = useState({});

  const [edit_show, setedit] = useState(false);

  const { energySuppliers, loading } = useEnergySuppliers(inputType);

  const handleClose_edit = () => setedit(false);

  const handleShow_edit = () => setedit(true);

  const handleDeleteRow = row => {
    // deleteAdminEnergySupplier(row);
  };

  const handleConfirmDialog = row => {
    confirmAlert({
      title: gettext("Confirmation"),
      message: gettext("Are you sure you want to Delete") + " " + row.name,
      buttons: [
        {
          label: gettext("Yes"),
          onClick: () => handleDeleteRow(row)
        },
        {
          label: gettext("No")
          // onClick: () => alert('Click No')
        }
      ]
    });
  };

  const edit_functionality = row => {
    seteditableRow(row);

    handleShow_edit();
  };

  const onTypeChange = e => {
    setinputType(e.target.value);
  };

  const performAction = (row, action) => {
    switch (action) {
      case "edit":
        edit_functionality(row);
        break;
      case "delete":
        handleConfirmDialog(row);
        break;
      default:
        break;
    }
  };

  const columnFormater = params => {
    return (
      <>
        <Tooltip title={gettext("Edit")} arrow>
          <IconButton onClick={() => performAction(params.row, "edit")}>
            <FaUserEdit size={20} />
          </IconButton>
        </Tooltip>

        <Tooltip title={gettext("Delete")} arrow>
          <IconButton onClick={() => performAction(params.row, "delete")}>
            <MdDelete size={20} />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const columns = [
    {
      field: "type",
      headerName: gettext("Type"),
      flex: 0.4,
      headerAlign: "left"
    },
    {
      field: "name",
      headerName: gettext("Name"),
      flex: 0.8,
      headerAlign: "left"
    },
    {
      field: "email",
      headerName: gettext("Email"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "address",
      headerName: gettext("Address"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "country",
      headerName: gettext("Country"),
      flex: 0.5,
      headerAlign: "left"
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      flex: 0.4,
      headerAlign: "left",
      renderCell: params => columnFormater(params)
    }
  ];

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-body">
          <div className="row top-section-filters">
            <div className="form-group col-md-6">
              <label>{gettext("Type")}</label>
              <select
                className="form-control"
                id="type"
                name="type"
                required
                value={inputType}
                onChange={onTypeChange}
              >
                {types &&
                  types.map(type => (
                    <option value={type.name} key={"type_" + type.id}>
                      {type.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3>{gettext("Energy Suppliers List")}</h3>
            <span className="font-size-sm w-100">
              {gettext("List of all energy suppliers")}
            </span>
          </div>
          {/* end::Header */}
          <div className="card-body">
            <div style={{ width: "100%" }}>
              <DataGridComponent
                rows={energySuppliers}
                columns={columns}
                loading={loading}
                disableSelectionOnClick={true}
                autoHeight
              />
            </div>
          </div>
        </div>
      </div>

      <EditModel
        show={edit_show}
        onHide={handleClose_edit}
        editableRow={editableRow}
        countries={countries}
        countriesLoading={countriesLoading}
        types={types}
      />
    </>
  );
}
