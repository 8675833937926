import { useEffect, useState } from "react";
// import EnergyCollectionTypes from "./EnergyCollectionTypes";
// import GEnergyTypes from "./GEnergyTypes";
import { useUser } from "app/providers";
// import {
//   // useBuildingFilter
//   // useMetersFilter
// } from "hooks";
import { useDashboard } from "hooks/useDashboardData";
import {
  // getComparisonTable,
  requestForAllEnergySourch
} from "./EnergyComparisonData";

export const useEnergyComparisonRequest = props => {
  const { addresses, fromDate, toDate } = props;
  const { user } = useUser();
  const { currentTeam } = user || {};

  const [cEyData, setCEgyData] = useState({});
  const [cEData, setCEData] = useState({});
  const [cHData, setCHData] = useState({});
  const [cWData, setCWData] = useState({});

  const previouseParams = type => {
    let fromYear = fromDate ? fromDate.split("-") : null;
    fromYear = `${fromYear[0] - 1}-${fromYear[1]}-${fromYear[2]}`;
    let toYear = toDate ? toDate.split("-") : null;
    toYear = `${toYear[0] - 1}-${toYear[1]}-${toYear[2]}`;
    return {
      sourceType: type,
      customers: currentTeam,
      addresses: addresses,
      fromDate: fromYear,
      toDate: toYear,
      resolution: "d"
    };
  };
  const currentParams = type => {
    return {
      sourceType: type,
      customers: currentTeam,
      addresses: addresses,
      fromDate: fromDate,
      toDate: toDate,
      resolution: "d"
    };
  };
  const { data: cEnergyData, loading: cEnergyLoading } = useDashboard(
    currentParams("energy")
  );
  const { data: cElectricityData, loading: cElectricityLoading } = useDashboard(
    currentParams("electricity")
  );
  const { data: cHeatingData, loading: cHeatingLoading } = useDashboard(
    currentParams("benchmark_heating")
  );
  const { data: cWaterData, loading: cWaterLoading } = useDashboard(
    currentParams("water")
  );

  const { data: pEnergyData, loading: pEnergyLoading } = useDashboard(
    previouseParams("energy")
  );
  const { data: pElectricityData, loading: pElectricityLoading } = useDashboard(
    previouseParams("electricity")
  );
  const { data: pHeatingData, loading: pHeatingLoading } = useDashboard(
    previouseParams("benchmark_heating")
  );
  const { data: pWaterData, loading: pWaterLoading } = useDashboard(
    previouseParams("water")
  );
  // console.log('new Address => ',cEnergyData, pEnergyData, cEnergyLoading, pEnergyLoading)
  useEffect(() => {
    if (!cEnergyLoading && !pEnergyLoading && cEnergyData && pEnergyData) {
      const result = requestForAllEnergySourch(cEnergyData, pEnergyData, "all");
      setCEgyData(result);
    } else {
      setCEgyData({});
    }
  }, [cEnergyData, pEnergyData, cEnergyLoading, pEnergyLoading]);

  useEffect(() => {
    if (
      !cElectricityLoading &&
      !pElectricityLoading &&
      cElectricityData &&
      pElectricityData
    ) {
      const result = requestForAllEnergySourch(
        cElectricityData,
        pElectricityData,
        "electricity"
      );
      setCEData(result);
    } else {
      setCEData({});
    }
  }, [
    cElectricityData,
    pElectricityData,
    cElectricityLoading,
    pElectricityLoading
  ]);

  useEffect(() => {
    if (!cHeatingLoading && !pHeatingLoading && cHeatingData && pHeatingData) {
      const result = requestForAllEnergySourch(
        cHeatingData,
        pHeatingData,
        "heating"
      );
      setCHData(result);
    } else {
      setCHData({});
    }
  }, [cHeatingData, pHeatingData, cHeatingLoading, pHeatingLoading]);

  useEffect(() => {
    if (!cWaterLoading && !pWaterLoading && cWaterData && pWaterData) {
      const result = requestForAllEnergySourch(cWaterData, pWaterData, "water");
      setCWData(result);
    } else {
      setCWData({});
    }
  }, [cWaterData, pWaterData, cWaterLoading, pWaterLoading]);

  return {
    energy: cEyData,
    electricity: cEData,
    heating: cHData,
    water: cWData,
    loadingData: cElectricityLoading || cHeatingLoading || cWaterLoading
  };
};
