import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { DataGridComponent } from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete, MdPassword, MdLogin } from "react-icons/md";
import { IoKeySharp, IoPersonSharp } from "react-icons/io5";
import EditModel from "./components/EditModel";
import ChpassModel from "./components/ChpassModel";
import DeleteModel from "./components/DeleteModel";
import LoginModel from "./components/LoginModel";
import { useUsersList } from "./hooks";
import Toast from "app/components/toast/Toast";
import { IconButton, Tooltip } from "@mui/material";

export function UserListTable(props) {
  const { restricted, searchString, statusFilter } = props;

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const navigate = useNavigate();

  const location = useLocation();

  const { usersList, loading } = useUsersList(searchString, statusFilter);

  const [editableRow, seteditableRow] = useState({});

  const [edit_show, setedit] = useState(false);

  const [delete_show, setdelete] = useState(false);

  const [chpass_show, setchpass] = useState(false);

  const [login_show, setlogin] = useState(false);

  const [toastList, updateToastList] = useState([]);

  const handleClose_edit = () => setedit(false);

  const handleShow_edit = () => setedit(true);

  const handleClose_delete = () => setdelete(false);

  const handleShow_delete = () => setdelete(true);

  const handleClose_chpass = message => {
    if (message === "success") {
      updateToastList([
        {
          title: "Success",
          description: "Password updated successfully",
          type: "success"
        }
      ]);
    }
    setchpass(false);
  };

  const roleComparator = (v1, v2) => {
    let x = v1;
    let y = v2;
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };

  const handleShow_chpass = () => setchpass(true);

  const handleClose_login = () => setlogin(false);

  const handleShow_login = () => setlogin(true);

  function edit_functionality(row) {
    seteditableRow(row);

    handleShow_edit();
  }
  function chpass_functionality(row) {
    seteditableRow(row);

    handleShow_chpass();
  }
  function login_functionality(row) {
    seteditableRow(row);
    handleShow_login();
  }
  function delete_functionality(row) {
    seteditableRow(row);
    handleShow_delete();
  }
  function roles_functionality(row) {
    navigate(`/admin/users-list/${row.id}/update-role`);
  }
  function customers_functionality(row) {
    navigate(`${location.pathname}/${row.id}/customers`, {
      state: { user: row }
    });
  }

  function performAction(row, action) {
    switch (action) {
      case "edit":
        edit_functionality(row);
        break;
      case "delete":
        delete_functionality(row);
        break;
      case "roles":
        roles_functionality(row);
        break;
      case "customers":
        customers_functionality(row);
        break;
      case "chpass":
        chpass_functionality(row);
        break;
      case "login":
        login_functionality(row);
        break;
      default:
        break;
    }
  }

  function columnFormater(params) {
    return (
      <div className="w-100 d-flex justify-content-center">
        {!restricted && (
          <Tooltip title={gettext("Edit")} arrow>
            <IconButton onClick={() => performAction(params.row, "edit")}>
              <FaUserEdit size={16} />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={gettext("Delete")} arrow>
          <IconButton onClick={() => performAction(params.row, "delete")}>
            <MdDelete size={16} />
          </IconButton>
        </Tooltip>

        {!restricted && (
          <Tooltip title={gettext("Change Password")} arrow>
            <IconButton onClick={() => performAction(params.row, "chpass")}>
              <MdPassword size={16} />
            </IconButton>
          </Tooltip>
        )}

        {!restricted && (
          <Tooltip title={gettext("Update Roles")} arrow>
            <IconButton onClick={() => performAction(params.row, "roles")}>
              <IoKeySharp size={16} />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={gettext("Companies")} arrow>
          <IconButton onClick={() => performAction(params.row, "customers")}>
            <IoPersonSharp size={16} />
          </IconButton>
        </Tooltip>

        {!restricted && (
          <Tooltip title={gettext("Login as")} arrow>
            <IconButton onClick={() => performAction(params.row, "login")}>
              <MdLogin size={16} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  }

  const columns = [
    {
      field: "name",
      headerName: gettext("Name"),
      flex: 1,
      minWidth: 150,
      headerAlign: "left"
    },
    {
      field: "email",
      headerName: gettext("Email"),
      flex: 1,
      minWidth: 150,
      headerAlign: "left"
    },
    {
      field: "roles",
      headerName: gettext("Role"),
      flex: 1,
      headerAlign: "left",
      minWidth: 80,
      sortComparator: roleComparator
    },
    {
      field: "countryCode",
      headerName: gettext("Country"),
      headerAlign: "left"
    },
    {
      field: "timezone",
      headerName: gettext("Timezone"),
      headerAlign: "left"
    },
    {
      field: "status",
      headerName: gettext("Status"),
      headerAlign: "left"
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      flex: 1,
      minWidth: 200,
      maxWidth: 250,
      headerAlign: "center",
      renderCell: params => columnFormater(params)
    }
  ];

  return (
    <>
      <DataGridComponent
        rows={usersList}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        initialState={{ pinnedColumns: { right: ["actions"] } }}
        autoHeight
      />

      <EditModel
        show={edit_show}
        onHide={handleClose_edit}
        editableRow={editableRow}
      />
      <ChpassModel
        show={chpass_show}
        onHide={handleClose_chpass}
        editableRow={editableRow}
      />
      <DeleteModel
        show={delete_show}
        onHide={handleClose_delete}
        editableRow={editableRow}
        getTeamMembers={props.getTeamMembers}
      />
      <LoginModel
        show={login_show}
        onHide={handleClose_login}
        editableRow={editableRow}
      />
      <Toast toastList={toastList} />
    </>
  );
}
