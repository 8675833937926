import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import {
  Alert,
  Box,
  Button,
  // Checkbox,
  Collapse,
  // FormControlLabel,
  // FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack
} from "@mui/material";
import { useSubmit } from "./hooks";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import GoogleLogin from "react-google-login";
// import {
//     loginGoogle,
//     loginWithSocialMedia,
// } from "app/modules/Auth/_redux/authCrud";
// import { useGoogleLogin } from "app/modules/Auth/Login/hooks";

export function ResetPasswordForm(props) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { token, setResetPassword } = props;

  const [disableButton, setdisableButton] = useState(false);

  // const {
  //     REACT_APP_URL,
  //     REACT_APP_CLIENT_ID_GOOGLE,
  //     REACT_APP_BASE_BACKEND_URL,
  // } = process.env;
  // const { setToken } = useAuth();

  // const validationSchema = useMemo(
  //   () =>
  //     Yup.object().shape({
  //       newPassword: Yup.string()
  //         // .newPassword(gettext("Must be a valid email"))
  //         .min(3, "Minimum 3 symbols")
  //         .max(50, "Maximum 50 symbols")
  //         .required(gettext("New Password is required")),
  //       confirmPassword: Yup.string()
  //       .min(3, "Minimum 3 symbols")
  //       .max(50, "Maximum 50 symbols")
  //       .required(gettext("Confirm Password is required"))
  //         // .test({
  //         //   message: `password doesn't match`,
  //         //   test: (value) => value == newPassword,
  //         // })
  //     // .when("newPassword", {
  //     //   is: val => (val && val.length > 0 ? true : false),
  //     //   then: Yup.string().oneOf(
  //     //     [Yup.ref("newPassword")],
  //     //     "Password and Confirm Password didn't match"
  //     //   )
  //     // })
  //     }),
  //   [gettext]
  // );

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(3, gettext("Minimum 3 symbols"))
      .max(50, gettext("Maximum 50 symbols"))
      .required(gettext("New Password is required")),
    confirmPassword: Yup.string()
      .required(gettext("Confirm Password is required"))
      .when("newPassword", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          gettext("Passwords didn't match")
        )
      })
  });

  const { submit, isSubmitting, resetSuccess } = useSubmit(
    () => {
      // setResetSuccess(true);
    },
    error => {
      if (Boolean(error)) {
        formik.setErrors({
          submit: error.toString()
        });
      } else
        formik.setErrors({
          submit: gettext("Unknown Error Occurred !")
        });
    }
  );

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location.pathname]);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: ""
    },
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // submit the form here
      submit({
        password1: values.newPassword,
        password2: values.confirmPassword,
        token: token?.replaceAll(" ", "+")
      });
    },
    validationSchema
  });

  useEffect(() => {
    if (resetSuccess === true) {
      // navigate("/auth/login");
      // setResetPassword(resetSuccess);
      formik.setErrors({
        submit: "Password Reset Successful!"
      });
    }
    if (setResetPassword) {
      setResetPassword(resetSuccess);
    }
  }, [resetSuccess, setResetPassword, navigate, formik]);

  useEffect(() => {
    if (token === undefined || token === null) {
      setdisableButton(true);
      formik.setErrors({
        submit: "Valid token link is required"
      });
    }
  }, [token, formik]);
  // const scope = [
  //     "https://www.googLeapis.com/auth/userinfo.email",
  //     "https://www.googleapis.com/auth/userinfo.profile",
  // ].join(" ");

  // const responseGoogle = () => {
  //     try {
  //         const params = {
  //             response_type: "code",
  //             prompt: "select_account",
  //             access_type: "offline",
  //             scope,
  //         };
  //         const res = loginGoogle(params);
  //         if (res) {
  //             console.log("response from google recieved in form", res);
  //         }
  //     } catch (error) {
  //         // console.log(error);
  //     }
  // };

  return (
    <Box sx={{ marginY: 1 }}>
      {!resetSuccess ? (
        <>
          <Collapse in={Boolean(formik.errors.submit)}>
            <Alert
              variant="outlined"
              severity={resetSuccess ? "success" : "error"}
            >
              {formik.errors.submit}
            </Alert>
          </Collapse>

          <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <Stack>
                  <InputLabel htmlFor="password-login">
                    {/* <Typography fontSize={14} fontWeight={500} color="#3F3F46"> */}
                    {gettext("New Password")}
                    {/* </Typography> */}
                  </InputLabel>
                  <OutlinedInput
                    id="password-login"
                    name="newPassword"
                    type={showNewPassword ? "text" : "password"}
                    fullWidth
                    size="large"
                    // placeholder={gettext("Enter New Password")}
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // error={Boolean(
                    //     formik.touched.password &&
                    //         formik.errors.password
                    // )}
                    endAdornment={
                      <InputAdornment position="end" sx={{ color: "#D4D4D8" }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="inherit"
                          size="small"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.newPassword}
                      </div>
                    </div>
                  ) : null}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack>
                  <InputLabel htmlFor="password-login">
                    {/* <Typography fontSize={14} fontWeight={500} color="#3F3F46"> */}
                    {gettext("Confirm Password")}
                    {/* </Typography> */}
                  </InputLabel>
                  <OutlinedInput
                    id="password-login"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    fullWidth
                    size="large"
                    // placeholder={gettext("Enter Confirm Password")}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // error={Boolean(
                    //     formik.touched.password &&
                    //         formik.errors.password
                    // )}
                    endAdornment={
                      <InputAdornment position="end" sx={{ color: "#D4D4D8" }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="inherit"
                          size="small"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.confirmPassword}
                      </div>
                    </div>
                  ) : null}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Button
                  disableElevation
                  disabled={disableButton ? disableButton : isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  {gettext("Reset Password")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <a href="/auth/login">
                <Button
                  disableElevation
                  fullWidth
                  size="large"
                  variant="contained"
                  color="secondary"
                >
                  {gettext("Sign In")}
                </Button>
              </a>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
