import styled from "styled-components";

export const ChartList = styled.div`
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .new-account-chart {
    text-align: center;
    button {
      background-color: #1bc5bd;
      border-color: #1bc5bd;
      color: #fff;
      padding: 8px 10px;
      font-size: 14px;
      border: none;
      border-radius: 4px;
    }
  }
  hr {
    margin: 0px !important;
    border-top: 1px solid gray;
  }
`;

export const MainResultHead = styled.div``;

export const TableList = styled.div`
  .row {
    padding: 10px 0px;
  }
  .table-heading {
    font-size: 16px !important;
  }
  .top-border {
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .action-button {
    text-align: center;
    .edit {
      background-color: #1bc5bd;
      border-color: #1bc5bd;
      color: #fff;
      padding: 8px 24px;
      width: 71px;
      font-size: 14px;
      border: none;
      border-radius: 4px;
    }
    .delete {
      background-color: rgb(228, 230, 239);
      border-color: rgb(228, 230, 239);
      color: rgb(63, 66, 84);
      padding: 8px 16px;
      width: 73px;
      font-size: 14px;
      border: none;
      border-radius: 4px;
    }
    .btn-spinner-delete {
      margin: 0px 0px !important;
      width: 1.3rem !important;
      height: 1.3rem !important;
    }
  }
`;

export const GoBackButton = styled.div``;

export const AccountChartHeader = styled.div`
  .title {
  }
  .table-title {
    margin-bottom: 15px !important;
  }
  .form-group {
    height: 60px !important;
    .input {
      height: 45px !important;
    }
  }
  .create-action {
    text-align: center;
    margin-top: 15px;
    button {
      background-color: #1bc5bd;
      border-color: #1bc5bd;
      color: #fff;
      padding: 15px 45px;
      font-size: 16px;
      border: none;
      border-radius: 4px;
    }
    button:disabled {
      opacity: 0.8;
      cursor: no-drop;
    }
  }
  .btn-spinner-delete {
    margin: 0px 0px !important;
    width: 1.3rem !important;
    height: 1.3rem !important;
  }
`;
