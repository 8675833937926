import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";

function EpcNotice() {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  return (
    <>
      <div className="card card-custom gutter-b example example-compact d-flex flex-column mw-0 p-5">
        <p>
          {gettext(
            "Note: This is only for showing and estimates and can't be used as an energy performance certificate for now."
          )}
        </p>
      </div>
    </>
  );
}

export default injectIntl(EpcNotice);
