import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useCallback, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import {
  DELETE_ACCOUNT_DATA_ELEMENT,
  GET_ACCOUNT_DATA_BY_ID,
  UPDATE_ACCOUNT_DATA_ELEMENT
} from "./commands";

export function useFetchAccountFileData(id) {
  const { data, loading, error, refetch } = useQuery(GET_ACCOUNT_DATA_BY_ID, {
    variables: {
      id
    }
  });

  return {
    fileName: data?.accountData?.fileName,
    accountFileDataList: data?.accountData?.data,
    loading,
    error,
    refetch
  };
}

export function useUpdate() {
  const { enqueueSnackbar } = useSnackbar();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [updateAccountData, { loading }] = useMutation(
    UPDATE_ACCOUNT_DATA_ELEMENT
  );

  const update = useCallback(
    async (id, data, accountNumber, reset) => {
      try {
        await updateAccountData({
          variables: { id, data, accountNumber }
        });

        enqueueSnackbar(gettext("Deleting Successfull"), {
          variant: "success"
        });

        await reset();
      } catch (error) {
        enqueueSnackbar(gettext("Error Deleting"), {
          variant: "error"
        });
      }
    },
    [updateAccountData, enqueueSnackbar, gettext]
  );

  return {
    update,
    loading
  };
}

export function useDelete() {
  const { enqueueSnackbar } = useSnackbar();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [deleteAccountDataElement, { loading }] = useMutation(
    DELETE_ACCOUNT_DATA_ELEMENT
  );

  const deleteDataElement = useCallback(
    async (id, accountNumber, reset) => {
      try {
        await deleteAccountDataElement({
          variables: { id, accountNumber }
        });

        enqueueSnackbar(gettext("Deleting Successfull"), {
          variant: "success"
        });

        await reset();
      } catch (error) {
        enqueueSnackbar(gettext("Error Deleting"), {
          variant: "error"
        });
      }
    },
    [deleteAccountDataElement, enqueueSnackbar, gettext]
  );

  return {
    deleteDataElement,
    loading
  };
}
