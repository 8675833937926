import { Grid, Typography } from "@mui/material";
import { CustomerRolesTable, InviteCustomerUserForm } from "app/features";
import { useTextdomainContext, useUser } from "app/providers";
import React, { useContext, useState } from "react";
import { CustomerRolesControls } from "./components";

export default function CustomerRoles() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { user } = useUser();

  const { teams, currentTeamId } = user || {};

  const [selectedCustomer, setSelectedCustomer] = useState(
    teams?.find(team => {
      return team.Id === currentTeamId;
    }) ||
      teams[0] ||
      null
  );
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const handleOpen = () => setAddModalOpen(true);
  const handleClose = () => setAddModalOpen(false);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography fontSize={22} fontWeight={700}>
                {gettext("Company Roles")}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <CustomerRolesControls
                teams={teams || []}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
                setAddModalOpen={handleOpen}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CustomerRolesTable selectedCustomer={selectedCustomer} />
        </Grid>
      </Grid>

      <InviteCustomerUserForm
        open={isAddModalOpen}
        handleClose={handleClose}
        selectedCustomer={selectedCustomer}
      />
    </>
  );
}
