import React, { useEffect } from "react";
import { LayoutSplashScreen } from "_metronic/layout";
import { useParams } from "react-router-dom";
import { useUser } from "app/providers";
import { useRedirectStats } from "hooks";

function RedirectFlow() {
  const params = useParams();

  const { user } = useUser();

  const customerKey = params.customerKey;

  const userType = params.type || "company";

  const email = user?.email || "";

  // const [redirect, setRedirect] = useState(false);

  // const [redirectUrl, setRedirectUrl] = useState("/dashboard")

  const { redirectStats } = useRedirectStats(data => {
    window.location.href =
      "https://eloverblik.dk/Authorization/login?thirdPartyId=e899fcd8-2129-4594-a077-c27a51d9dfd3&fromDate=" +
      data.fromDate +
      "&toDate=" +
      data.toDate +
      "&customerKey=" +
      customerKey +
      "&userEmail=" +
      email +
      "&userType=" +
      userType +
      "&returnUrl=https://api.fabeke.com/v2/admin";
  });

  useEffect(() => {
    redirectStats({
      customerKey,
      email,
      userType
    });
  }, [redirectStats, customerKey, email, userType]);

  return (
    // redirect && (
    //   <Suspense fallback={<LayoutSplashScreen />}>
    //     <Routes>
    //       <Navigate to={redirectUrl} />
    //     </Routes>
    //   </Suspense>
    // )
    <LayoutSplashScreen />
  );
}

export default RedirectFlow;
