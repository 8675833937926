import { gql } from "@apollo/client";

export const FIND_COMPANIES = gql`
  query findCompanies(
    $name: String
    $customerName: String!
    $isCompany: Boolean!
  ) {
    dkCompanies(name: $name) @include(if: $isCompany)
    comGetCustomerDetails(customerName: $customerName) {
      country
      addresses {
        address
        country
      }
    }
  }
`;
