import { useQuery } from "@apollo/client";
import { SCRAPPER_LOGS } from "../commands";

export function useScrapperLogs(search, queryFilter) {
  const { data, loading } = useQuery(SCRAPPER_LOGS, {
    variables: {
      search,
      queryFilter
    },
    fetchPolicy: "no-cache"
  });

  return {
    logsList: data?.scrapperLogs || [],
    loading
  };
}
