import React, { useCallback, useContext } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useTextdomainContext } from "app/providers";

export function DeleteConfirmation({
  show,
  onHide,
  loading,
  confirmationText,
  onConfirmed
}) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const handleDelete = useCallback(async () => {
    onConfirmed();
  }, [onConfirmed]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{gettext("Confirmation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmationText}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {gettext("Close")}
        </Button>
        {loading ? (
          <Spinner animation="border" variant="dark" />
        ) : (
          <Button variant="primary" onClick={handleDelete}>
            {gettext("Delete")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
