import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { getOr } from "lodash/fp";
import {
  useGetByAddresses,
  useGetCustomerUrl
} from "../services/dashboardService";
import { GET_WATER_QUERY } from "GraphQL/Queries";

export const useCustomerURL = props => {
  const { user } = props;
  const [teams, setTeams] = useState([]);
  const { data: res } = useGetCustomerUrl(user ? user.id : "");
  useEffect(() => {
    if (!res.isLoading) {
      setTeams(res.teams);
    }
  }, [res]);
  return { teams };
};

export const useCO2Report = props => {
  const { customer, addresses, from, to, resolution } = props;
  const [co2Report, setCO2Report] = useState(null);
  const getEPCParams = dataType => {
    return {
      type: dataType,
      customer,
      addresses,
      from,
      to,
      resolution
    };
  };

  const { data: electricity } = useGetByAddresses(getEPCParams("electricity"));
  const { data: heating } = useGetByAddresses(getEPCParams("heating"));
  const { data: water } = useGetByAddresses(getEPCParams("water"));

  useEffect(() => {
    if (!electricity.isLoading && !heating.isLoading && !water.isLoading) {
      const {
        data: eData,
        electricity_green: elctricityGreen
      } = electricity.data;
      const { data: hData } = heating.data;
      const { data: wData } = water.data;
      setCO2Report({
        electricity: eData,
        heating: hData,
        water: wData,
        elctricityGreen
      });
    }
  }, [electricity, heating, water]);

  return {
    co2Report,
    isLoading: electricity.isLoading || heating.isLoading || water.isLoading
  };
};

export const useCO2EnergyReport = props => {
  const {
    customers,
    addresses,
    fromDate,
    toDate,
    resolution,
    sourceType,
    dateFrom,
    dateTo
    // fromYear,
    // secondYear,
    // toYear
  } = props;

  const [co2Report, setCO2Report] = useState(null);

  const [
    fetchEnergyData,
    { data: energiesData, loading, refetch: reFetchEnergyData }
  ] = useLazyQuery(GET_WATER_QUERY, {
    variables: {
      meterType: sourceType,
      meteringPointId: "all",
      address: addresses,
      customer: customers,
      fromDate: fromDate,
      toDate: toDate,
      resolution: resolution,
      fileType: "excel"
    }
  });

  useEffect(() => {
    if (customers && addresses) {
      fetchEnergyData();
      reFetchEnergyData();
    }
  }, [
    fetchEnergyData,
    reFetchEnergyData,
    customers,
    addresses,
    dateFrom,
    dateTo,
    sourceType
  ]);
  useEffect(() => {
    if (!loading) {
      const metersData = getOr(null, "metersData", energiesData);
      setCO2Report(metersData);
    }
  }, [energiesData, loading]);

  return { co2Report: co2Report, loading };
};
