import React, { useState, useContext } from "react";
import BuildingDropdown from "../../../components/BuildingDropdown";
import DashboardDropdown from "./DashboardDropdown";
import CustomerDropdown from "../../../components/CustomerDropdown";
import { injectIntl } from "react-intl";
import { Spinner } from "react-bootstrap";
import { MdPlaylistAdd } from "react-icons/md";
import { Switch } from "../../../components";
import {
  useTextdomainContext,
  useCustomerPermission,
  useUser
} from "app/providers";

function FilterComponent(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { canEdit } = useCustomerPermission();
  const { user } = useUser();

  const { demo } = user;

  let { isPublic, onChangePublic, onAddDescClick, URL, loadingPublic } = props;
  const [selectedTeams, updateSelectedTeams] = useState("");
  // const [selectedBuildings, updateSelectedBuildings] = useState('');

  const handleChangeCustomers = selectedValue => {
    let selectedCustomers = [];
    if (selectedValue) {
      selectedCustomers = selectedValue.map(selectedRow => {
        return selectedRow.value;
      });
    }

    props.onChangeCustomer &&
      props.onChangeCustomer(selectedCustomers.toString());
    updateSelectedTeams(selectedCustomers.toString());
  };

  const handleChangeBuildings = selectedValue => {
    let selectedBuildings = [];
    if (selectedValue) {
      selectedBuildings = selectedValue.map(selectedRow => {
        return selectedRow.value;
      });
    }
    props.onChangeBuilding &&
      props.onChangeBuilding(selectedBuildings.join("|"));
  };

  return (
    <>
      <div className={"card card-custom gutter-b example example-compact"}>
        <div className="card-body">
          <div className={"row d-flex align-items-center"}>
            <div className={"col-md-2 py-md-2 text-left"}>
              {gettext("Dashboard")}
            </div>
            <div className={"col-md-10 py-2 py-md-2"}>
              <DashboardDropdown onChange={props.onChangeDashboard} />
            </div>
          </div>
          <div className={"row d-flex align-items-center"}>
            <div className={"col-md-2 py-md-2 text-left"}>
              {gettext("Company")}
            </div>
            <div className={"col-md-10 py-2 py-md-2"}>
              <CustomerDropdown onChange={handleChangeCustomers} />
            </div>
          </div>
          <div className={"row d-flex align-items-center"}>
            <div className={"col-md-2 py-md-2 text-left"}>
              {gettext("Building")}
            </div>
            <div className={"col-md-10 py-2 py-md-2"}>
              <BuildingDropdown
                teams={selectedTeams}
                onChange={handleChangeBuildings}
              />
            </div>
          </div>
          <div className={"row d-flex align-items-center"}>
            {canEdit("public-page") ? (
              <div className={"col-md-2 py-md-2 text-left"}>
                {gettext("Public")}
              </div>
            ) : null}
            <div
              className={
                "col-md-10 py-2 py-md-2 d-inline-flex align-items-center text-right"
              }
            >
              <span className="flex-fill ">
                {isPublic && (
                  <span className="d-flex justify-content-between">
                    <a href={URL} target="_blank" rel="noreferrer">
                      {URL}
                    </a>
                    {!demo ? (
                      <MdPlaylistAdd
                        title="Add Description"
                        size={25}
                        onClick={onAddDescClick}
                      />
                    ) : null}
                  </span>
                )}
              </span>
              {loadingPublic && (
                <Spinner
                  animation="border"
                  variant="dark"
                  className="btn-spinner-add"
                />
              )}
              {canEdit("public-page") ? (
                <Switch
                  checked={isPublic}
                  onChange={onChangePublic}
                  disabled={!canEdit("public-page")}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(FilterComponent);
