import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext, useUser } from "app/providers";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { DELETE_API_KEY, GENERATE_API_KEY } from "GraphQL/Mutations";
import { GET_API_KEY } from "GraphQL/Queries";
import { DataGridComponent } from "app/components";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

function APIKeyPage(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const { user } = useUser();

  // Fields
  // const [label, setLabel] = useState("");
  const [APIKeys, setAPIKeys] = useState([]);
  const [isError, setisError] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);

  const labelError = gettext("label is required");

  // UI Helpers
  const initialValues = { label: "" };

  const Schema = Yup.object().shape({
    label: Yup.string().required(labelError)
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const { data, loading } = useQuery(GET_API_KEY, {
    variables: { email: user.email }
  });

  const [generateAPIkey] = useMutation(GENERATE_API_KEY, {
    onCompleted() {},
    onError() {}
  });

  const [deleteAPIkey] = useMutation(DELETE_API_KEY, {
    onCompleted() {},
    onError() {}
  });

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // setloading(true);
      generateAPIkey({
        variables: {
          label: values.label
        },
        refetchQueries: ["comGetUserApiKeys"]
      })
        .then(res => {
          if (res.data && res.data.comApiKeyRequest.success) {
            setStatus(gettext("API key has been generated successfully."));
            setisError(false);
            setisSuccess(true);
            setSubmitting(false);
            // setloading(false);
          } else {
            setStatus(gettext("Something went wrong!"));
            setisError(true);
            setisSuccess(false);
            setSubmitting(false);
            // setloading(false);
          }
          setTimeout(() => {
            setisSuccess(false);
            setisError(false);
          }, 5000);
        })
        .catch(err => {
          setisError(true);
          setisSuccess(false);
          setSubmitting(false);
          // setloading(false);
          //setStatus(err.data.message);
          setTimeout(() => {
            setisSuccess(false);
            setisError(false);
          }, 5000);
        });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    }
  });

  useEffect(() => {
    setAPIKeys(data?.comGetUserApiKeys);
  }, [data]);

  const handleDeleteAPIKey = row => {
    deleteAPIkey({
      variables: {
        email: user.email,
        label: row.label
      },
      refetchQueries: ["comGetUserApiKeys"]
    });
    // client
    //   .delete(
    //     apiRoutes.DeleteCustomerFromUser.path +
    //     `?team_id=${customer.id}&&user_id=${user.id}`
    //   )
    //   .then(data => {
    //     if (data?.toString().includes(400)) {
    //       setErrorMessage(true);
    //       setTimeout(() => {
    //         setErrorMessage(false);
    //       }, 5000);
    //     } else {
    //       // fetchCustomerListCallback(user.id);
    //     }
    //   })
    //   .catch(err => { });
  };

  function columnFormater(params) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <OverlayTrigger
          overlay={
            <Tooltip>
              <strong>{gettext("Delete")}</strong>
            </Tooltip>
          }
        >
          <MdDelete
            // title="Delete"
            size={20}
            onClick={() => handleDeleteAPIKey(params.row)}
          />
        </OverlayTrigger>
      </div>
    );
  }

  const columns = [
    {
      field: "label",
      headerName: gettext("Label"),
      flex: 0.2,
      headerAlign: "left"
    },
    {
      field: "token",
      headerName: gettext("API-KEY"),
      flex: 1,
      headerAlign: "left",
      renderCell: params => {
        return (
          <span
            style={{
              width: "500px",
              display: "inline-block",
              whiteSpace: "normal"
            }}
          >
            {params.value}
          </span>
        );
      }
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      flex: 0.2,
      headerAlign: "center",
      renderCell: params => columnFormater(params)
    }
  ];

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && (
        <Spinner className="btn-spinner-download" animation="border" />
      )}

      {APIKeys && APIKeys.length === 0 && (
        <div className="card card-custom gutter-b example example-compact">
          <div className="table-div">
            {/* begin::Header */}
            <div className="table-title">
              <h3>{gettext("API Key Generation")}</h3>

              <div className="card-toolbar">
                <button
                  type="submit"
                  className="btn btn-success mr-2"
                  disabled={
                    formik.isSubmitting || (formik.touched && !formik.isValid)
                  }
                >
                  {gettext("Save Changes")}
                  {formik.isSubmitting}
                </button>
                <Link
                  to="/user-profile/personal-information"
                  className="btn btn-secondary"
                >
                  {gettext("Cancel")}
                </Link>
              </div>
              <span className="font-size-sm w-100">
                {gettext("You can view your API Key here")}
              </span>
            </div>
            {/* end::Header */}

            {/* begin::Form */}
            <div className="form">
              <div className="card-body">
                {/* begin::Alert */}
                {isError && (
                  <div
                    className="alert alert-custom alert-light-danger fade show mb-10"
                    role="alert"
                  >
                    <div className="alert-icon">
                      <span className="svg-icon svg-icon-3x svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Info-circle.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </div>
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                    <div
                      className="alert-close"
                      onClick={() => setisError(false)}
                    >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">
                          <i className="ki ki-close"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                )}
                {isSuccess && (
                  <div
                    className="alert alert-custom alert-light-success fade show mb-10"
                    role="alert"
                  >
                    <div className="alert-icon">
                      <span className="svg-icon svg-icon-3x svg-icon-success">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Info-circle.svg"
                          )}
                        ></SVG>{" "}
                      </span>
                    </div>
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                    <div
                      className="alert-close"
                      onClick={() => setisSuccess(false)}
                    >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">
                          <i className="ki ki-close"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                )}
                {/* end::Alert */}
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                    {gettext("Label")}
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <input
                      type="text"
                      placeholder={gettext("Enter a label")}
                      className={
                        "form-control form-control-lg form-control-solid mb-2 bg-gray-200 text-gray-600 " +
                        getInputClasses("label")
                      }
                      name="label"
                      {...formik.getFieldProps("label")}
                    />
                    {formik.touched.label && formik.errors.label ? (
                      <div className="invalid-feedback">
                        {formik.errors.label}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* end::Form */}
          </div>
        </div>
      )}

      <div className="card card-custom gutter-b example example-compact">
        <div className="table-div">
          {/* begin::Header */}

          <div className="table-title">
            <span
              className="font-size-sm w-100"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <h3>{gettext("API Key Generation")}</h3>

              <button
                type="submit"
                className="btn btn-success mr-2"
                onClick={() =>
                  window.open("https://docs.fabeke.com/", "_blank")
                }
              >
                {gettext("API Documnet")}
              </button>
            </span>

            <div className="card-toolbar"></div>
            <span className="font-size-sm w-100">
              {gettext("You can view your API Key here")}
            </span>
          </div>
          {/* end::Header */}

          {/* begin::Form */}
          {/* <div className="form">
            <div className="card-body">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  <FormattedMessage id="API_KEY.PAGE.FIELD.API_KAY.LABEL" />
                </label>
                <div className="col-lg-9 col-xl-6"> */}
          {/* <input
                      type="text"
                      // placeholder={gettext("Enter a label")}
                      className={`form-control form-control-lg form-control-solid mb-2 bg-gray-200 text-gray-600}`}
                      name="label"
                      value={APIKey}
                      disabled
                    /> */}
          {/* <p>{'gfd dsfgsdfgs dfgsdfgsdf'}</p>
                </div>
              </div>
            </div>
          </div> */}
          <DataGridComponent
            rows={APIKeys}
            columns={columns}
            getRowId={(row, index) => row.label + index}
            disableSelectionOnClick={true}
            autoHeight
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }]
              }
            }}
          />
          {/* end::Form */}
        </div>
      </div>
    </form>
  );
}

export default APIKeyPage;
