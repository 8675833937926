import React, { useState } from "react";
import Helmet from "react-helmet";
import { CircularProgress } from "@mui/material";
import DocsHtml from "./components/DocsHtml";
import styled from "styled-components";

const MainDocsPage = styled.div`
  .operation-heading code {
    color: #000000 !important;
    font-weight: bold !important;
  }
  .property-name code {
    color: #000000 !important;
  }
  .definition-heading,
  #mutation,
  #http-request-1,
  #request-1,
  #response-1,
  .doc-heading,
  .introduction-item-title,
  #authentication,
  #shell,
  #python,
  #javascript,
  #query,
  #query-1,
  #http-request,
  #query-parameters,
  #request,
  #response {
    color: #000000 !important;
  }
  .loadingScript {
    text-align: center;
    padding: 50px;
  }
`;

function APIDocsPage(props) {
  const [loadedScript, setLoadedScript] = useState(false);
  const { REACT_APP_URL } = process.env;

  const handleScriptInject = scriptTags => {
    if (scriptTags) {
      setTimeout(() => {
        setLoadedScript(true);
      }, 1500);
    }
  };

  return (
    <MainDocsPage>
      <Helmet
        onChangeClientState={(newState, addedTags) =>
          handleScriptInject(addedTags)
        }
      >
        <link
          rel="stylesheet"
          href={`${REACT_APP_URL}api-docs/stylesheets/spectaql.min.css`}
        />
        <script
          type="text/javascript"
          src={`${REACT_APP_URL}api-docs/stylesheets/spectaql.min.js`}
          async="async"
        />
      </Helmet>
      {!loadedScript ? (
        <div className="loadingScript">
          <CircularProgress />
        </div>
      ) : (
        <DocsHtml />
      )}
    </MainDocsPage>
  );
}

export default APIDocsPage;
