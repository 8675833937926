import { Box, Stack, Typography } from "@mui/material";
import { useTextdomainContext } from "app/providers";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import React, { useContext } from "react";
import { useBuildingStep } from "../../providers";

export function Header() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { buildingMeterData, currentStep, totalSteps } = useBuildingStep();
  return (
    <Stack>
      <Typography fontSize={32} fontWeight={700}>
        {gettext("Lets get started")}
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap" }} alignItems="center">
        <Typography fontSize={18} fontWeight={500}>
          {gettext("Add meter details for the building")}
        </Typography>

        <Typography fontSize={18} fontWeight={500}>
          &nbsp;
        </Typography>

        <Typography fontSize={18} fontWeight={500} color="#015731">
          {buildingMeterData?.address}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            backgroundColor: "#FAFAFA",
            borderRadius: "16px",
            p: 0.5
          }}
        >
          <IoChevronBack size={16} color={"#34795A"} />
          <Typography fontSize={16} fontWeight={500} color="#52525B">
            {`${currentStep} / ${totalSteps}`}
          </Typography>
          <IoChevronForward size={16} color={"#34795A"} />
        </Box>
      </Box>
    </Stack>
  );
}
