import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import {
  ADD_USER_CUSTOMER,
  GET_CUSTOMERS_ROLES,
  GET_NEW_CUSTOMERS
} from "./commands";

export function useGetNotMyCustomers(userId) {
  const { data, loading } = useQuery(GET_NEW_CUSTOMERS, {
    variables: {
      userId
    }
  });

  return {
    newCustomers: data?.comNotMyCustomers || [],
    loading
  };
}

export function useCustomerRoles() {
  const { data, loading } = useQuery(GET_CUSTOMERS_ROLES);

  return {
    customerRoles: data?.comCustomerRoles || [],
    loading
  };
}

export function useAddCustomer(onCompleted = () => {}, onError = () => {}) {
  const [comAddCustomerUser, { loading }] = useMutation(ADD_USER_CUSTOMER, {
    onCompleted: onCompleted,
    onError: onError,
    refetchQueries: ["getUserCustomers", "comNotMyCustomers"]
  });

  const addCustomer = useCallback(
    variables => {
      comAddCustomerUser({
        variables
      });
    },
    [comAddCustomerUser]
  );

  return {
    addCustomer,
    loading
  };
}
