import { gql } from "@apollo/client";

export const FETCH_EMMISION_GROUPS_LIST = gql`
  query emissionGroups {
    emissionGroups {
      id
      nameEn
      nameDknameDa
      createdAt
      updatedAt
      parent {
        id
        nameEn
        nameDk
        nameDa
        createdAt
        updatedAt
      }
    }
  }
`;
