import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Box, Typography } from "@mui/material";
import { usePagePrivileges } from "hooks/privileges";

export function ProfileMenuItem({ link, icon, code, label, desc }) {
  const [displayOption, setDisplayOption] = useState(false);
  const [_read, _write] = usePagePrivileges(code);

  useEffect(() => {
    if (_read || _write) {
      setDisplayOption(true);
    } else if (code === undefined) {
      setDisplayOption(true);
    } else {
      setDisplayOption(false);
    }
  }, [_read, _write, code]);

  return (
    <>
      {displayOption && (
        <NavLink to={link}>
          <MenuItem sx={{ color: "primary.main" }}>
            <ListItemIcon sx={{ color: "inherit" }}>{icon}</ListItemIcon>
            <Box>
              <Typography
                variant="body1"
                color="gray"
                sx={{ fontSize: 16, fontWeight: "light" }}
              >
                {label}
              </Typography>
              <Typography variant="caption" color="gray">
                {desc}
              </Typography>
            </Box>
          </MenuItem>
        </NavLink>
      )}
    </>
  );
}
