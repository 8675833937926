import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ACCOUNT_DATA_BY_ID,
  UPDATE_ACCOUNT_DATA_ELEMENT
} from "./commands";
import { useSnackbar } from "notistack";
import { useCallback, useContext } from "react";
import { useTextdomainContext } from "app/providers";

export function useFetchAccountFileData(id) {
  const { data, loading, error, refetch } = useQuery(GET_ACCOUNT_DATA_BY_ID, {
    variables: {
      id
    }
  });

  return {
    accountFileDataList: data?.accountData?.data,
    loading,
    error,
    refetch
  };
}

export function useSave(onSave) {
  const { enqueueSnackbar } = useSnackbar();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [updateAccountData, { loading }] = useMutation(
    UPDATE_ACCOUNT_DATA_ELEMENT,
    {
      onCompleted: () => {
        enqueueSnackbar(gettext("Account Data Successfully Uploaded"), {
          variant: "success"
        });
        onSave();
      },
      onError: () => {
        enqueueSnackbar(gettext("Error Uploading Account Data"), {
          variant: "error"
        });
      },
      refetchQueries: ["accountData"]
    }
  );

  const save = useCallback(
    async (id, data, accountNumber) => {
      await updateAccountData({
        variables: { id, data, accountNumber }
      });
    },
    [updateAccountData]
  );

  return {
    save,
    loading
  };
}
