import React, { useEffect, useState, useMemo, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
import Select from "react-select";

function DashboardDropdown(props) {
  const { onChange } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [selectedDashboard, setSelectedDashboard] = useState({
    value: "Dashboard",
    label: "Aggregated (Total kWh pr. energy source)"
  });
  let key = "selectedDashboardDropdown";

  const dashboards = useMemo(() => {
    return [
      {
        value: "Dashboard3",
        label: gettext("Aggregated (Total kWh)")
      },
      {
        value: "Dashboard",
        label: gettext("Aggregated (Total kWh pr. energy source)")
      },
      {
        value: "Dashboard5",
        label: gettext("Broadcasting (kWh/m2)")
      },
      {
        value: "Dashboard1",
        label: gettext("Aggregated (Total kWh/m2)")
      },
      {
        value: "Dashboard2",
        label: gettext("Aggregated (Total kWh per energy source/m2)")
      }
    ];
  }, [gettext]);

  useEffect(() => {
    if (dashboards.length > 0 && localStorage.getItem(key) === null) {
      let findDashboard = dashboards[0];
      localStorage.setItem(key, JSON.stringify(findDashboard));
      setSelectedDashboard(findDashboard);
      onChange(findDashboard);
    } else if (dashboards.length > 0) {
      const oldDashboard = JSON.parse(localStorage.getItem(key));
      let findDashboard = dashboards.find(
        item => item.value === oldDashboard.value
      );
      localStorage.setItem(key, JSON.stringify(findDashboard));
      setSelectedDashboard(findDashboard);
      onChange(findDashboard);
    }
    return () => {};
  }, [onChange, key, dashboards]);

  let handleChangeDashboards = selectedOption => {
    localStorage.setItem(key, JSON.stringify(selectedOption));
    setSelectedDashboard(selectedOption);
    onChange && onChange(selectedOption);
  };

  return (
    <Select
      options={dashboards.map(item => {
        // item.label = gettext("DASHBOARD.DROPDOWN." + item.value?.toUpperCase());
        return item;
      })}
      onChange={handleChangeDashboards}
      value={[selectedDashboard]}
    />
  );
}

export default injectIntl(DashboardDropdown);
