import React, { useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useMutation } from "@apollo/client";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Spinner } from "react-bootstrap";
import { GET_EMISSION_FACTOR_LIST } from "../../../../GraphQL/Queries";
import { CREATE_EMISSION_FACTOR_MUTATION } from "../../../../GraphQL/Mutations";
import EmissionFactorsForm from "./emission-factors-form";
import { GoBackButton, AccountChartHeader } from "./emission-factors-style";

const EmissionFactorsCreate = () => {
  const navigate = useNavigate();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const localFilters = JSON.parse(localStorage.getItem("emissionFilters"));
  const date =
    localFilters && localFilters.year
      ? new Date(localFilters.year)
      : new Date();
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedParam1, setSelectedParam1] = useState("");
  const [selectedParam2, setSelectedParam2] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedYear, setSelectedYear] = useState(format(date, "yyyy"));

  const [scope1, setScope1] = useState("");
  const [scope2, setScope2] = useState("");
  const [scope3, setScope3] = useState("");
  const [source, setSource] = useState("");

  const [emissionFactor, { loading }] = useMutation(
    CREATE_EMISSION_FACTOR_MUTATION,
    {
      onCompleted(_data) {
        navigate(`/admin/emission-factors`);
      },
      onError(error) {
        // error.graphQLErrors.map(({ message }) => {
        //   // console.log("Error =>", error);
        // });
      }
    }
  );

  const createEmissionFactor = () => {
    emissionFactor({
      variables: {
        key: selectedKey,
        group: selectedGroup,
        parameter1: selectedParam1,
        parameter2: selectedParam2,
        unit: selectedUnit,
        year: selectedYear,
        data: {
          source: source,
          scope1: scope1,
          scope2: scope2,
          scope3: scope3,
          outOfScope: 0.3
        }
      },
      refetchQueries: [
        {
          query: GET_EMISSION_FACTOR_LIST,
          variables: {
            year: format(date, "yyyy")
          }
        }
      ]
    });
  };

  const hanleGoBack = () => {
    navigate(`/admin/emission-factors`);
  };

  const validForm = () =>
    scope1 &&
    scope2 &&
    scope3 &&
    selectedKey &&
    selectedGroup &&
    selectedParam1 &&
    selectedParam2 &&
    selectedUnit &&
    selectedYear;

  return (
    <>
      <GoBackButton className="row m-0">
        <div className="col-sm-12 p-0">
          <div className="form-group col-md-2 p-0">
            <button
              type="button"
              className="btn btn-outline-info"
              onClick={hanleGoBack}
            >
              <AiOutlineArrowLeft title="back" size={18} className="mr-2" />
              {gettext("Back")}
            </button>
          </div>
        </div>
      </GoBackButton>
      <AccountChartHeader className="card card-custom gutter-b example example-compact">
        <div className="row m-0 table-title">
          <div className="col-sm-12 title">
            <h3>{gettext("New Emission Factor")}</h3>
          </div>
        </div>
        <>
          <EmissionFactorsForm
            selectedKey={selectedKey}
            selectedGroup={selectedGroup}
            selectedParam1={selectedParam1}
            selectedParam2={selectedParam2}
            selectedUnit={selectedUnit}
            selectedYear={selectedYear}
            scope1={scope1}
            scope2={scope2}
            scope3={scope3}
            source={source}
            setSelectedKey={setSelectedKey}
            setSelectedGroup={setSelectedGroup}
            setSelectedParam1={setSelectedParam1}
            setSelectedParam2={setSelectedParam2}
            setSelectedUnit={setSelectedUnit}
            setSelectedYear={setSelectedYear}
            setScope1={setScope1}
            setScope2={setScope2}
            setScope3={setScope3}
            setSource={setSource}
            sourceType="New"
          />

          <div className="row m-0 border-top-row">
            <div className="col-sm-12 create-action">
              <div className="form-group col-md-12">
                <button
                  type="submit"
                  onClick={createEmissionFactor}
                  disabled={!validForm() || loading}
                >
                  {gettext("Create Emission Factor")} &nbsp;
                  {loading && (
                    <Spinner
                      className="btn-spinner-delete"
                      animation="border"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      </AccountChartHeader>
    </>
  );
};

export default EmissionFactorsCreate;
