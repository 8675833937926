import { Grid } from "@mui/material";
import DataHubTable from "./components/DataHubTable";
import { useDataHubCustomers } from "./hooks";

const DatahubCustomers = props => {
  const { view, setView, setSelectedItem, selectedItem, gettext } = props;
  const { customersList, loading } = useDataHubCustomers();

  return (
    <Grid item>
      <DataHubTable
        title="Companies"
        setView={setView}
        view={view}
        rows={customersList}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        gettext={gettext}
        loading={loading}
      />
    </Grid>
  );
};
export default DatahubCustomers;
