import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { SUBMIT_METERS_REGISTRATIONFLOW } from "../commands";

export function useSubmitMeters() {
  const [addCustomerMeterRegistrationFlow, { loading }] = useMutation(
    SUBMIT_METERS_REGISTRATIONFLOW
  );

  const submit = useCallback(
    data => {
      addCustomerMeterRegistrationFlow({
        variables: {
          data,
          skip: false
        }
      });
    },
    [addCustomerMeterRegistrationFlow]
  );

  return {
    submit,
    loading
  };
}
