import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
// import Select from "react-select";
import Creatable from "react-select/creatable";
import { useQuery } from "@apollo/client";
import { GET_EMISSION_FACTOR_LOOKUP } from "../../../../GraphQL/Queries";

const EmissionFactorsForm = props => {
  const {
    selectedKey,
    selectedGroup,
    selectedParam1,
    selectedParam2,
    selectedUnit,
    selectedYear,
    setSelectedKey,
    setSelectedGroup,
    setSelectedParam1,
    setSelectedParam2,
    setSelectedUnit,
    setSelectedYear,
    scope1,
    scope2,
    scope3,
    source,
    setScope1,
    setScope2,
    setScope3,
    setSource,
    sourceType
  } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [keyList, setKeyList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [parameter1List, setParameter1List] = useState([]);
  const [parameter2List, setParameter2List] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const yearList = [
    { value: 2019, label: 2019 },
    { value: 2020, label: 2020 },
    { value: 2021, label: 2021 },
    { value: 2022, label: 2022 }
  ];

  const { data } = useQuery(GET_EMISSION_FACTOR_LOOKUP, {
    variables: {}
  });

  useEffect(() => {
    if (data) {
      let { emissionFactorLookup } = data;
      let tempKeyList = [];
      let tempGroupList = [];
      let tempParam1List = [];
      let tempParam2List = [];
      let tempUnitList = [];
      emissionFactorLookup.map(item => {
        tempKeyList.push({
          value: item.key,
          label: item.key
        });
        tempGroupList.push({
          value: item.group,
          label: item.group
        });
        tempParam1List.push({
          value: item.parameter1,
          label: item.parameter1
        });
        tempParam2List.push({
          value: item.parameter2,
          label: item.parameter2
        });
        tempUnitList.push({
          value: item.unit,
          label: item.unit
        });
        return true;
      });
      setKeyList(tempKeyList);
      setGroupList(tempGroupList);
      setParameter1List(tempParam1List);
      setParameter2List(tempParam2List);
      setUnitList(tempUnitList);
    }
  }, [data]);

  return (
    <div className="row m-0 border-top-row">
      <div className="col-sm-12 mb-2 group-row">
        <div className="form-group col-md-6">
          <label>{gettext("Key")}</label>
          <Creatable
            options={keyList}
            isDisabled={sourceType === "Edit"}
            onChange={option => setSelectedKey(option.value)}
            value={[
              {
                value: selectedKey,
                label: selectedKey
              }
            ]}
          />
        </div>
        <div className="form-group col-md-6">
          <label>{gettext("Group")}</label>
          <Creatable
            options={groupList}
            isDisabled={sourceType === "Edit"}
            onChange={option => setSelectedGroup(option.value)}
            value={[
              {
                value: selectedGroup,
                label: selectedGroup
              }
            ]}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2 group-row">
        <div className="form-group col-md-6">
          <label>{gettext("Parameter 1")}</label>
          <Creatable
            options={parameter1List}
            isDisabled={sourceType === "Edit"}
            onChange={option => setSelectedParam1(option.value)}
            value={[
              {
                value: selectedParam1,
                label: selectedParam1
              }
            ]}
          />
        </div>
        <div className="form-group col-md-6">
          <label>{gettext("Parameter 2")}</label>
          <Creatable
            options={parameter2List}
            isDisabled={sourceType === "Edit"}
            onChange={option => setSelectedParam2(option.value)}
            value={[
              {
                value: selectedParam2,
                label: selectedParam2
              }
            ]}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2 group-row">
        <div className="form-group col-md-6">
          <label>{gettext("Unit")}</label>
          <Creatable
            options={unitList}
            isDisabled={sourceType === "Edit"}
            onChange={option => setSelectedUnit(option.value)}
            value={[
              {
                value: selectedUnit,
                label: selectedUnit
              }
            ]}
          />
        </div>
        <div className="form-group col-md-6">
          <label>{gettext("Year")}</label>
          <Creatable
            options={yearList}
            isDisabled={sourceType === "Edit"}
            onChange={option => setSelectedYear(option.value)}
            value={[
              {
                value: selectedYear,
                label: selectedYear
              }
            ]}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Source")}</label>
          <input
            type="text"
            className="form-control input"
            id="Source"
            name="Source"
            required
            placeholder={gettext("Enter Source")}
            value={source}
            onChange={e => setSource(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Parameter 1")}</label>
          <input
            type="number"
            step={0.1}
            className="form-control input"
            id="Scope1"
            name="Scope1"
            required
            placeholder={gettext("Enter Scope 1")}
            value={scope1}
            onChange={e => setScope1(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Parameter 2")}</label>
          <input
            type="number"
            step={0.1}
            className="form-control input"
            id="Scope2"
            name="Scope2"
            required
            placeholder={gettext("Enter Scope 2")}
            value={scope2}
            onChange={e => setScope2(e.target.value)}
          />
        </div>
      </div>
      <div className="col-sm-12 mb-2">
        <div className="form-group col-md-12">
          <label>{gettext("Parameter 3")}</label>
          <input
            type="number"
            step={0.1}
            className="form-control input"
            id="Scope3"
            name="Scope3"
            required
            placeholder={gettext("Enter Scope 3")}
            value={scope3}
            onChange={e => setScope3(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default EmissionFactorsForm;
