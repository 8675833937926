/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { useAuth } from "app/providers";
import { Navigate, Outlet } from "react-router-dom";
// import { Navigate, Outlet, useNavigate } from "react-router-dom";
import "_metronic/_assets/sass/pages/login/classic/login-1.scss";
// import { AppBar, Grid, Link, Toolbar, Box, Button } from "@mui/material";
import { AppBar, Grid, Link, Toolbar, Box } from "@mui/material";
// import LanguageSelector from "_metronic/layout/components/AppBar/LanguageSelector";
import LanguageSelector from "app/components/AppBar/LanguageSelector";

export function AuthLayout() {
  const { isAuthenticated } = useAuth();
  const appbarRef = useRef(null);
  // const navigate = useNavigate();

  // const navigateToPricesPage = function() {
  //   navigate("/public/budget/prices/electricity");
  // };

  return !isAuthenticated ? (
    <Box sx={{ minHeight: "100%", overflow: "hidden" }}>
      <AppBar
        ref={appbarRef}
        position="static"
        color="transparent"
        style={{ background: "#19384d" }}
      >
        <Toolbar>
          <Box sx={{ width: 220 }}>
            <Box
              sx={{
                minWidth: "100px",
                width: "150px",
                paddingY: 2
              }}
            >
              <Link href={process.env.REACT_APP_FRONT_END_URL}>
                <img alt="logo" src={"/media/logos/fabeke_white.png"} />
              </Link>
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          {/*<Box>*/}
          {/*  <Button*/}
          {/*    color="primary"*/}
          {/*    variant="contained"*/}
          {/*    onClick={navigateToPricesPage}*/}
          {/*  >*/}
          {/*    Electricity Prices*/}
          {/*  </Button>*/}
          {/*</Box>*/}
          <Box>
            <LanguageSelector />
          </Box>
          {/*<Box>*/}
          {/*    <Box*/}
          {/*        sx={{*/}
          {/*            minWidth: "100px",*/}
          {/*            width: "150px",*/}
          {/*            paddingY: 2*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <Link href={process.env.REACT_APP_FRONT_END_URL}>*/}
          {/*            <img alt="logo" src={"/media/logos/fabeke_green.png"} />*/}
          {/*        </Link>*/}
          {/*    </Box>*/}
          {/*</Box>*/}
        </Toolbar>
      </AppBar>

      <Grid
        container
        sx={{
          minHeight: `calc(100% - ${appbarRef?.current?.offsetHeight || 76}px )`
        }}
        justifyContent="center"
      >
        <Grid item xs={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Outlet />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            component="img"
            // fullWidth
            sx={{
              maxWidth: "100%",
              objectFit: "cover",
              height: "90vh"
            }}
            alt="The house from the offer."
            src={"/media/SignUpImage.png"}
          />
        </Grid>
      </Grid>
      <footer></footer>
    </Box>
  ) : (
    <Navigate to="/" />
  );
}
