import { styled, TableCell } from "@mui/material";

export const HeadTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "1px solid rgba(224, 224, 224, 1)",
  padding: 0,
  height: "65px",
  fontSize: 12,
  fontWeight: 500,
  color: theme.palette.secondary.main
}));
