import { useMutation, useQuery } from "@apollo/client";
import { useUser, useTextdomainContext } from "app/providers";
import { useSnackbar } from "notistack";
import { useCallback, useContext } from "react";
import client from "services/apiClient";
import {
  EDIT_PLAN_LINK,
  FETCH_ACCOUNT_GROUPS_LIST,
  FETCH_CHART_OF_ACCOUNTS_LIST,
  FETCH_PLAN_LINK_BY_ID,
  SAVE_PLAN_LINK
} from "./commands";

export function useFetchCharOfAccounts() {
  const { data, loading } = useQuery(FETCH_CHART_OF_ACCOUNTS_LIST);
  return {
    charOfAccounts: data?.chartOfAccounts,
    loading
  };
}

export function useFetchAccountGroups() {
  const { data, loading } = useQuery(FETCH_ACCOUNT_GROUPS_LIST);
  return {
    accountGroups: data?.accountGroups,
    loading
  };
}

export function useSave() {
  const { REACT_APP_API_URL_GRAPHQL } = process.env;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { user } = useUser();

  const { currentTeam } = user || {};

  const { enqueueSnackbar } = useSnackbar();

  const [insertPlainLink] = useMutation(SAVE_PLAN_LINK);

  const save = useCallback(
    async (name, status, fromDate, toDate, companyAccount, reset, CsvFile) => {
      try {
        if (companyAccount.length > 0) {
          const result = await insertPlainLink({
            variables: {
              name,
              status,
              fromDate,
              toDate,
              customerName: currentTeam,
              companyAccount
            }
          });

          const {
            data: {
              insertPlainLink: { plainLinkId }
            }
          } = result;

          const formData = new FormData();
          const query = `mutation uploadPlainLinkCsv {
            uploadPlainLinkCsv(
                id : "${plainLinkId}"
              ) {
              message
              success
            }
          }`;
          formData.append("query", query);
          formData.append("file", CsvFile);

          await client.post(REACT_APP_API_URL_GRAPHQL, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          enqueueSnackbar(gettext("New Plan Added Successfully"), {
            variant: "success"
          });

          reset();
        } else
          enqueueSnackbar(gettext("No File Added!"), {
            variant: "warning"
          });
      } catch (error) {
        enqueueSnackbar(gettext("Error adding New Plan"), {
          variant: "error"
        });
      }
    },
    [
      currentTeam,
      insertPlainLink,
      enqueueSnackbar,
      REACT_APP_API_URL_GRAPHQL,
      gettext
    ]
  );

  return {
    save
  };
}

export function useUpdate() {
  const { enqueueSnackbar } = useSnackbar();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { user } = useUser();

  const { currentTeamId } = user || {};

  const [updatePlainLink] = useMutation(EDIT_PLAN_LINK);

  const update = useCallback(
    async (
      id,
      name,
      status,
      fromDate,
      toDate,
      companyAccount,
      refetchPlainLinkById
    ) => {
      try {
        if (companyAccount.length > 0) {
          await updatePlainLink({
            variables: {
              id,
              plainLinkData: {
                name,
                fromDate,
                toDate,
                status,
                customerId: currentTeamId,
                companyAccount
              }
            }
          });

          await refetchPlainLinkById();

          enqueueSnackbar(gettext("New Plan Added Successfully"), {
            variant: "success"
          });
        } else
          enqueueSnackbar(gettext("No File Added!"), {
            variant: "warning"
          });
      } catch (error) {
        enqueueSnackbar(gettext("Error adding New Plan"), {
          variant: "error"
        });
      }
    },
    [currentTeamId, updatePlainLink, enqueueSnackbar, gettext]
  );

  return {
    update
  };
}

export function useGetPlanLinkById(id) {
  const { data, loading, refetch } = useQuery(FETCH_PLAN_LINK_BY_ID, {
    variables: { id }
  });

  return {
    planLinkData: data?.plainLink,
    loading,
    refetchPlainLinkById: refetch
  };
}
