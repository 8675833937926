import { sendSlackMessage } from "app/common/Functions";
import { useUser } from "app/providers";
import { useCallback } from "react";

export async function useSendLoginSlackMessage() {
  const { user } = useUser();

  const userId = user?.id || "N/A";
  const userName = user?.name || "N/A";
  const userEmail = user?.email || "N/A";
  const teamName = user?.currentTeam || "NA";

  const sendLoginMessage = useCallback(
    authToken => {
      if (process.env.REACT_APP_NODE_ENV === "production") {
        let fields = [
          { title: "User Email", value: userEmail },
          { title: "User Name", value: userName },
          { title: "User ID", value: userId },
          { title: "User Team", value: teamName },
          { title: "Token", value: `Bearer: ${authToken}` }
        ];

        let slackErrorData = {
          channel: "fabeke-login",
          type: "success",
          heading: `Login activity: After login, user landed on ${window.location.href}`,
          detail: `${userName} (${userEmail}) has logged in on the ${process.env.REACT_APP_NODE_ENV} environment`,
          fields: fields
        };
        sendSlackMessage(slackErrorData);
      }
    },
    [userId, userName, userEmail, teamName]
  );

  return sendLoginMessage;
}
