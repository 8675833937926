import { Box } from "@mui/material";
import React from "react";
import { useOutlet } from "react-router-dom";
import { AppBar, SideDrawer } from "./components";

const drawerWidth = 260;

export function AppLayout() {
  const children = useOutlet();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        drawerWidth={drawerWidth}
        toggleDrawer={() => setIsDrawerOpen(open => !open)}
      />

      <SideDrawer
        drawerWidth={drawerWidth}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      >
        {children}
      </SideDrawer>
    </Box>
  );
}
