import { useQuery } from "@apollo/client";
// import { useUser } from "app/providers";
import { FIND_COMPANIES } from "../commands";

export default function useFindCompanies(searchString, onCompleted = () => {}) {
  // const { user } = useUser();

  // const { customerType } = user || {};

  const { data, loading } = useQuery(FIND_COMPANIES, {
    variables: {
      name: searchString,
      customerName: searchString,
      // isCompany: customerType === "company",
      isCompany: true
    },
    onCompleted: data => {
      onCompleted(data);
    }
  });

  return {
    matchedCompanies: data?.dkCompanies || [],
    companyCountry: data?.comGetCustomerDetails?.country || null,
    companyAddresses: data?.comGetCustomerDetails?.addresses || [],
    loading
  };
}
