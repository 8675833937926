import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { DataGridComponent } from "../../../../components";
import { useFetchAccountFileData } from "./hooks";
import { LinkComponent } from "./components/LinkComponent";
import { Typography } from "@mui/material";

export function FileDetails({ fileId }) {
  const { accountFileDataList, loading } = useFetchAccountFileData(fileId);
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const linkColumnFormater = row => {
    return (
      <div className="w-100 d-flex justify-content-center">
        <LinkComponent row={row} fileId={fileId} gettext={gettext} />
      </div>
    );
  };

  const accountColumnFormater = row => {
    return (
      <Typography className="pl-4 account-link-val1">
        {row?.accountNumber + " " + row?.accountName}
      </Typography>
    );
  };

  const columns = [
    {
      field: "accountNumber",
      headerName: gettext("Account"),
      flex: 1,
      headerAlign: "center",
      renderCell: params => accountColumnFormater(params?.row)
    },

    {
      field: "actions",
      headerName: gettext("Link"),
      flex: 3,
      headerAlign: "center",
      renderCell: params => linkColumnFormater(params?.row)
    }
  ];
  return (
    <>
      <DataGridComponent
        rows={accountFileDataList}
        columns={columns}
        loading={loading}
        getRowHeight={() => 100}
        getRowId={row => row.accountNumber}
        disableSelectionOnClick={true}
      />
    </>
  );
}
