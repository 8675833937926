import { useContext } from "react";
import { useQuery } from "@apollo/client";
import { useAuth, useUser, useTextdomainContext } from "app/providers";
import { useSnackbar } from "notistack";
import { ME_QUERY, USE_GRAPH_COLOR } from "./commands";

export function useMe(onCompleted = () => null, onError = () => null) {
  const { enqueueSnackbar } = useSnackbar();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { setUser, setColors } = useUser();

  const { unsetToken } = useAuth();

  const { data, loading, refetch } = useQuery(ME_QUERY, {
    onCompleted: ({ comMe }) => {
      try {
        setUser({
          ...comMe
        });
        onCompleted(comMe);
      } catch (error) {}
    },
    onError: () => {
      onError();

      enqueueSnackbar(
        gettext(
          "Unable To Validate The Current User. Logging Out!. Please Login again."
        ),
        {
          variant: "error"
        }
      );

      unsetToken();
    },
    fetchPolicy: "cache-and-network"
  });

  const { data: data2, refetch: refetchColors } = useQuery(USE_GRAPH_COLOR, {
    onCompleted: ({ userGraphColor }) => {
      try {
        setColors({
          ...userGraphColor
        });
      } catch (error) {}
    },
    onError: () => {
      onError();
    },
    fetchPolicy: "cache-and-network"
  });

  return {
    refetchMe: refetch,
    user: data?.comMe,
    loading,
    refetchColors: refetchColors,
    colors: data2?.userGraphColor
  };
}
