import React from "react";
import { Stack } from "@mui/material";
import { BuildingTable, LabelTable } from "./elements";
import { useTextdomainContext } from "app/providers";
import { BenchmarkTablesLoading } from "./components";
import { useBenchmarkData } from "../../hooks";

export function BenchmarkTable() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const {
    loading,
    labels,
    buildingOneData,
    buildingTwoData,
    currentYear,
    prevYear
  } = useBenchmarkData();

  return loading ? (
    <BenchmarkTablesLoading />
  ) : (
    <Stack direction="row" alignItems={"flex-end"} overflow={"auto"}>
      <LabelTable labels={labels} />

      {Boolean(Object.keys(buildingOneData).length) && (
        <BuildingTable
          label={gettext("Building 1")}
          buildingData={buildingOneData}
          currentYear={currentYear}
          prevYear={prevYear}
        />
      )}

      {Boolean(Object.keys(buildingTwoData).length) && (
        <BuildingTable
          label={gettext("Building 2")}
          buildingData={buildingTwoData}
          currentYear={currentYear}
          prevYear={prevYear}
        />
      )}
    </Stack>
  );
}
