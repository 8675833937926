import { gql } from "@apollo/client";
import { ME_TYPE_FRAGMENT } from "GraphQL/Fragments";
import { SHADE_TYPE } from "GraphQL/Types";

export const ME_QUERY = gql`
  ${ME_TYPE_FRAGMENT}
  query comMe {
    comMe {
      ...MeType
    }
  }
`;

export const USE_GRAPH_COLOR = gql`
  query userGraphColor{
    userGraphColor{
      electricity{
        ${SHADE_TYPE}
      }
      heating{
        ${SHADE_TYPE}
      }
      water{
        ${SHADE_TYPE}
      }
      gas{
        ${SHADE_TYPE}
      }
      energy{
        ${SHADE_TYPE}
      }
      compensation{
        ${SHADE_TYPE}
      }
      production{
        ${SHADE_TYPE}
      }
      greenElectricity{
        ${SHADE_TYPE}
      }
      consumption{
        ${SHADE_TYPE}
      }
      co2 {
        ${SHADE_TYPE}
    }
    }
  }
`;
