import React, { useState, useEffect, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  GET_CONTRIEST_LIST,
  GET_TIMEZONES_LIST
} from "../../../common/Constants";
import client from "services/apiClient";

const { REACT_APP_URL } = process.env;

const selectStyles = {
  container: base => ({
    ...base,
    minWidth: 200,
    fontSize: 14,
    fontWeight: "normal",
    flex: 1,
    color: "black",
    width: "100%"
  })
};

const NewRegistrationFlow = props => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryId, setCountryId] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [countries, setCountries] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [inValidField, setInValidField] = useState(false);
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const customerTypes = [
    {
      value: "company",
      label: "Company"
    },
    {
      value: "private",
      label: "Private"
    }
  ];

  // useEffect(() => {
  //   setTeamName(user.teamName);
  // }, [user]);

  useEffect(() => {
    if (
      countryId !== "" &&
      selectedTimeZone !== "" &&
      selectedCustomer !== ""
    ) {
      setInValidField(false);
    } else {
      setInValidField(true);
    }
  }, [countryId, selectedTimeZone, selectedCustomer]);

  useEffect(() => {
    const fetchCountries = async () => {
      client.get(GET_CONTRIEST_LIST).then(response => {
        const countryList = response.data.countries;
        let countryOptions = [];
        countryList.forEach(item => {
          countryOptions.push({
            value: item.code,
            label: item.name,
            id: item.id
          });
        });
        setCountries(countryOptions);
      });
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchTimeZones = async () => {
      client
        .get(`${GET_TIMEZONES_LIST}?country_code=${selectedCountry}`)
        .then(response => {
          const timeZoneList = response.data.timezones;
          let timeZoneOptions = [];
          timeZoneList.forEach(item => {
            timeZoneOptions.push({
              value: item.country_code,
              zoneName: `${item.zone_name}`,
              label: `${item.zone_name} ${item.gmt_offset}`
            });
          });
          if (timeZoneOptions.length > 0) {
            setSelectedTimeZone(timeZoneOptions[0].zoneName);
            setTimeZones(timeZoneOptions);
          }
        });
    };
    fetchTimeZones();
  }, [selectedCountry]);

  const handleCountryOption = item => {
    setSelectedCountry(item.value);
    setCountryId(item.id);
  };

  const handleTimeZoneOption = item => {
    setSelectedTimeZone(item.zoneName);
  };

  const handleRolesOption = item => {
    setSelectedCustomer(item.value);
  };

  const goToDatahub = () => {
    const customerKey = selectedCustomer === "company" ? "Erhverv" : "Privat";
    // setUser(updatedUser);
    window.location = `${REACT_APP_URL}r/${selectedCustomer}/gg/${customerKey}`;
  };

  return (
    <>
      <div className={"card card-custom gutter-b example example-compact"}>
        <div className={"card-body"}>
          <h3>Registration Flow</h3>
          <p>
            {gettext(
              "This Privacy Policy appliesonly to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Comnundo. This policy is not applicable to any information collected offline or via channels other than this website."
            )}
          </p>
          <div className="form-group row users-options">
            <label className="col-xl-2 col-lg-2 col-form-label">
              Country Name
            </label>
            <div className="col-lg-6 col-xl-6">
              <Select
                options={countries}
                onChange={handleCountryOption}
                styles={selectStyles}
              />
            </div>
            <div className="col-lg-3 col-xl-3"></div>
          </div>
          {timeZones && timeZones.length > 1 && (
            <div className="form-group row users-options">
              <label className="col-xl-2 col-lg-2 col-form-label">
                Time Zone
              </label>
              <div className="col-lg-6 col-xl-6">
                <Select
                  options={timeZones}
                  onChange={handleTimeZoneOption}
                  styles={selectStyles}
                />
              </div>
              <div className="col-lg-3 col-xl-3"></div>
            </div>
          )}
          <div className="form-group row users-options">
            <label className="col-xl-2 col-lg-2 col-form-label">
              Company Type
            </label>
            <div className="col-lg-6 col-xl-6">
              <Select
                options={customerTypes}
                onChange={handleRolesOption}
                styles={selectStyles}
              />
            </div>
            <div className="col-lg-3 col-xl-3"></div>
          </div>
          <div className="form-group row users-options">
            <label className="col-xl-2 col-lg-2 col-form-label"></label>
            <div className="col-lg-8 col-xl-8">
              <label className="col-xl-12 col-lg-12 col-form-label p-0 mb4">
                {/* {`${REACT_APP_URL}r/${selectedCustomer}/gg/${teamName.split(" ")[0].toLowerCase()}`} */}
              </label>
              <button
                className="datahub-btn mb20 pl20 pr20"
                onClick={() => goToDatahub()}
                disabled={inValidField}
              >
                GO TO DATAHUB
              </button>
              <Tooltip
                placement="top"
                title={gettext(
                  "Energinet's Datahub stores all our electricity data, and Fabeke is approved by Energinet to retrieve your electricity data so that it can be used in our platform if you give us permission to download them. You can always withdraw your permit on Energinet's Datahub, so you always have control over your data. You grant permission by clicking on Datahub."
                )}
                arrow
                className="ml-2"
              >
                <HelpOutlineIcon />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(NewRegistrationFlow);
