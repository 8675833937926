import { Box, Grid } from "@mui/material";
import React from "react";
import { SelectBuilding, SelectEnergyType, SelectYear } from "./components";

export function EpcFilters() {
  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: "secondary.50",
        border: "1px solid",
        borderColor: "secondary.200",
        borderRadius: "8px"
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={"auto"}>
          <SelectBuilding />
        </Grid>

        <Grid item xs={12} sm={"auto"}>
          <SelectEnergyType />
        </Grid>

        <Grid item xs={12} sm={"auto"}>
          <SelectYear />
        </Grid>
      </Grid>
    </Box>
  );
}
