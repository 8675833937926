import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useTextdomainContext } from "app/providers";
import { useDebounce } from "hooks";

export function UsersTableControls({ setSearchString, setAddModalOpen }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const [value, setValue] = useState("");

  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    setSearchString(debouncedValue);
  }, [setSearchString, debouncedValue]);

  return (
    <Box
      sx={{
        display: "flex"
      }}
    >
      <Box sx={{ flex: 1 }} />

      <Stack direction={"row"} spacing={2}>
        <OutlinedInput
          type={"text"}
          size="small"
          placeholder={gettext("Search")}
          value={value}
          onChange={e => setValue(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />

        <Button
          size="small"
          variant="contained"
          sx={{ textDecoration: "none" }}
          onClick={() => setAddModalOpen()}
        >
          {gettext("Add User")}
        </Button>
      </Stack>
    </Box>
  );
}
