import { gql } from "@apollo/client";

export const ADD_MODULE_PERMISSION = gql`
  mutation addModulePermissions($name: String!, $code: String!) {
    addModulePermissions(name: $name, code: $code) {
      success
      message
    }
  }
`;
