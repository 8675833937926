import { useQuery } from "@apollo/client";
import { GET_COLOR_SETTINGS } from "./commands";

export function useColorSettings() {
  const { data, loading } = useQuery(GET_COLOR_SETTINGS, {
    fetchPolicy: "no-cache"
  });

  return {
    colorSettings: data && data?.userGraphColor,
    loading
  };
}
