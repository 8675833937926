import { Grid } from "@mui/material";
import DataHubTable from "./components/DataHubTable";
import { useDataHubBuildingMeters } from "./hooks";

const DatahubMeters = props => {
  const {
    view,
    setView,
    meterIdList,
    setSelectedItem,
    selectedItem,
    gettext
  } = props;
  const { metersList, loading } = useDataHubBuildingMeters(meterIdList);

  return (
    <Grid item>
      <DataHubTable
        title={view}
        setView={setView}
        view={view}
        rows={metersList}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        gettext={gettext}
        loading={loading}
      />
    </Grid>
  );
};
export default DatahubMeters;
