import { Button, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { ChevronDown } from "comundo-icons";
import { useTextdomainContext } from "app/providers";
import { useBenchmarkData } from "../../hooks";

export function DownloadButton() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { downloadFile } = useBenchmarkData();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadClick = fileType => {
    downloadFile(fileType);
    handleClose();
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="text"
        color="secondary"
        endIcon={<ChevronDown size={16} />}
      >
        <Typography fontSize={14} fontWeight={500}>
          {gettext("Download")}
        </Typography>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        PaperProps={{
          sx: {
            width: "150px",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: "secondary.200",
            boxShadow:
              "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)"
          }
        }}
      >
        <MenuItem onClick={() => handleDownloadClick("excel")}>
          {gettext("Excel")}
        </MenuItem>
        <MenuItem onClick={() => handleDownloadClick("csv")}>
          {gettext("CSV")}
        </MenuItem>
      </Menu>
    </>
  );
}
