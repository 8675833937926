import Moment from "moment";
import dateFormat from "dateformat";

export const getUnitDateOption = (selectedSegment, startDate, endDate) => {
  let now = new Date(startDate);
  let end = new Date(endDate);
  let fromDate = "";
  let year = startDate.getFullYear();
  let month = startDate.getMonth() + 1;
  month = month > 9 ? month : `0${month}`;
  let day = startDate.getDate();
  let selectedDate = year + "-" + month + "-" + day;
  let selectedMonth = year + "-" + month;
  let selectedYear = year;
  let toDate = "";
  let unit = "";
  // let segment = "";
  let toDateObject = "";
  let fromDateObject = "";

  if (selectedSegment === "h") {
    // segment = "day";
    unit = selectedDate;
    fromDate = Moment(startDate).format("YYYY-MM-DD");
    toDate = Moment(endDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
  } else if (selectedSegment === "d") {
    // segment = "month";
    unit = selectedMonth;
    fromDateObject = new Date(now.setMonth(now.getMonth() + 0));
    toDateObject = new Date(end.setMonth(end.getMonth() + 1));
    fromDate = dateFormat(fromDateObject, "yyyy-mm-01");
    toDate = dateFormat(toDateObject, "yyyy-mm-01");
  } else if (selectedSegment === "m") {
    // segment = "year";
    unit = selectedYear;
    let now1 = new Date(now.setMonth(0));
    let now2 = new Date(end.setMonth(0));
    fromDateObject = new Date(now1.setFullYear(now1.getFullYear() + 0));
    toDateObject = new Date(now2.setFullYear(now2.getFullYear() + 1));
    fromDate = dateFormat(fromDateObject, "yyyy-mm-01");
    toDate = dateFormat(toDateObject, "yyyy-mm-01");
  }
  return { fromDate, toDate, unit };
};

export const getHourlyExportDates = (segment, unit) => {
  let dateFromObject = new Date();
  let dateToObject = new Date();
  let fromDate = dateFormat(dateFromObject, "yyyy-mm-dd");
  let toDate = dateFormat(dateToObject, "yyyy-mm-dd");
  switch (segment) {
    case "h":
      dateFromObject = new Date(unit);
      fromDate = dateFormat(dateFromObject, "yyyy-mm-dd");
      dateToObject = new Date(
        dateFromObject.setDate(dateFromObject.getDate() + 1)
      );
      toDate = dateFormat(dateFromObject, "yyyy-mm-dd");
      break;
    case "d":
      dateFromObject = new Date(unit + "-01");
      fromDate = dateFormat(dateFromObject, "yyyy-mm-dd");
      dateToObject = new Date(
        dateFromObject.setMonth(dateFromObject.getMonth() + 1)
      );
      toDate = dateFormat(dateFromObject, "yyyy-mm-dd");
      break;
    case "m":
      dateFromObject = new Date(unit + "-01-01");
      fromDate = dateFormat(dateFromObject, "yyyy-mm-dd");
      dateToObject = new Date(
        dateFromObject.setYear(dateFromObject.getFullYear() + 1)
      );
      toDate = dateFormat(dateFromObject, "yyyy-mm-dd");
      break;
    default:
      break;
  }
  return { fromDate, toDate };
};

export const calculateExportData = (res, meter) => {
  let finalObject = {
    title: "Hourly Data",
    heading: [
      "Time",
      "Consumption (kWh)",
      "Tons carbon emission",
      "Production (kWh)",
      "Production CO2"
    ],
    content: []
  };

  let tmpObject = {};
  let tmpObjectProd = {};
  res.map((obj, index) => {
    obj.data.map((obj2, index2) => {
      if (meter === obj.meter || meter === "all" || meter === "All") {
        if (!tmpObject[obj2.ts]) {
          tmpObject[obj2.ts] = {
            v: 0,
            co2: 0
          };
        }
        if (!tmpObjectProd[obj2.ts]) {
          tmpObjectProd[obj2.ts] = {
            v: 0,
            co2: 0
          };
        }
        if (obj.type_of_mp === "E18") {
          tmpObjectProd[obj2.ts]["v"] += obj2.v;
          tmpObjectProd[obj2.ts]["co2"] += obj2.co2;
        } else {
          tmpObject[obj2.ts]["v"] += obj2.v;
          tmpObject[obj2.ts]["co2"] += obj2.co2;
        }
      } else {
        // console.log("Invalid meter number");
      }
      return false;
    });
    return false;
  });

  for (let key in tmpObject) {
    finalObject.content.push([
      key,
      tmpObject[key].v,
      tmpObject[key].co2,
      tmpObjectProd[key].v,
      tmpObjectProd[key].co2
    ]);
  }
  return finalObject;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
