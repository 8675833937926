import React, { useEffect, useState, useContext, useCallback } from "react";
import Select from "react-select";
import { injectIntl } from "react-intl";
import TopSectionDatePicker from "../../../components/TopSectionDatePicker";
import CustomTable from "../../../components/CustomTable";
import { MixedWidget3 } from "../../../widgets/mixed/MixedWidget3";
import StatusMessage from "../../../components/StatusMessage";
import slug from "slug";
import { Spinner } from "react-bootstrap";
import { getOneYearOldDate, getSelectedSegment } from "./Constant";
import {
  ExportDataHourly,
  useReportConsumerEngergyData
} from "./GExportDataHourly";
import { ShowGreenElectricity } from "../../../components";
import DownloadFile from "./downloadFile";
import { useUser, useTextdomainContext, useLang } from "app/providers";
import {
  useBuildingFilter,
  useColorSettings
  // useMetersFilter
} from "hooks";
import { getOr } from "lodash/fp";
import { Box, Typography } from "@mui/material";

const GEnergyTypes = props => {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { type, sourceType } = props;

  const { user } = useUser();
  const { currentTeam } = user || {};

  const localFilters = JSON.parse(localStorage.getItem("energyFilters"));

  const [selectedSegment, setSelectedSegment] = useState(
    getSelectedSegment(localFilters)
  );

  const [date, setDate] = useState(getOneYearOldDate(localFilters));

  const [isError, setIsError] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const [statusMessage, setStatusMessage] = useState("");

  const [exportFilename, setExportFilename] = useState(
    `${type}-consumption-day`
  );
  const [allBuildingMeters, setAllBuildingMeters] = useState([]);
  const [addressesList, setAddressesList] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState(
    localFilters ? localFilters.buildingName : "All"
  );

  const [selectedMeter, setSelectedMeter] = useState("all");
  const [selectedMeterLabel, setSelectedMeterLabel] = useState(
    localFilters ? localFilters.buildingMeterLabel : "All"
  );
  const [customerMeters, setCustomerMeters] = useState([]);
  const [chartDataFlag, setChartDataFlag] = useState({});
  // const [chartData3Flag, setChartData3Flag] = useState([]);
  const [customerBuilding, setCustomerBuilding] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [exportDisabled, setExportDisabled] = useState(false);
  // const [heatingGraphData, setHeatingGraphData] = useState({});
  // const [heatingCo2GraphData, setHeatingCo2GraphData] = useState({});
  const [timeStamp, setTimeStamp] = useState("");

  const segment =
    selectedSegment === 0 ? "h" : selectedSegment === 1 ? "d" : "m";

  const { buildings: buildingData } = useBuildingFilter(currentTeam);
  const locale = useLang();
  // const { data: buildingData } = useBuildings4Dropdown({ teamId });

  const { colorSettings } = useColorSettings();
  const energyParams = {
    customers: currentTeam,
    buildingCode:
      selectedBuilding === "All"
        ? selectedBuilding.toLowerCase()
        : selectedBuilding,
    meter: selectedMeter,
    type: type,
    segment: segment,
    date: date,
    addressesList: addressesList,
    selectedSegment,
    sourceType
  };

  // const gasParams = {
  //     customers: currentTeam,
  //     buildingCode:
  //         selectedBuilding === "All"
  //             ? selectedBuilding.toLowerCase()
  //             : selectedBuilding,
  //     meter: selectedMeter,
  //     type: "gas",
  //     segment: segment,
  //     date: date,
  //     addressesList: addressesList,
  //     selectedSegment,
  //     sourceType,
  // };

  const {
    // exportDisabled,
    unitStart,
    unitEnd
    // electricityGreen
  } = ExportDataHourly(energyParams);

  const {
    data: { tableData, graphs },
    loading,
    greenElectricity: eGreen
  } = useReportConsumerEngergyData(energyParams, colorSettings);

  if (graphs && Object.keys(graphs).length) {
    graphs.chartData3.colors[0] = getOr(
      "#c0c7b5",
      "co2.shade1.background",
      colorSettings
    );
  }

  // const onBuildingCompleted = useCallback(
  //   buildings => {
  //     if (buildings.length > 0) {
  //       let buildingsArray = [{ label: gettext("All"), value: "all" }];

  //       buildings.map(building => {
  //         buildingsArray.push({ label: building, value: building });
  //         return true;
  //       });

  //       setCustomerBuilding(buildingsArray);

  //       if (!selectedBuilding && buildings[0]) {
  //         setSelectedBuilding(buildings[0]);
  //       }
  //     }
  //   },
  //   [selectedBuilding]
  // );

  // useBuildingFilter(currentTeam, onBuildingCompleted);

  // const onMeterCompleted = useCallback(
  //   meters => {
  //     if (meters.length > 0) {
  //       let metersArray = [{ label: gettext("All"), value: "all" }];

  //       meters.map(meter => {
  //         metersArray.push({ label: meter, value: meter });
  //         return true;
  //       });

  //       setCustomerMeters(metersArray);

  //       if (!selectedMeter && meters[0]) {
  //         setSelectedMeter(meters[0]);
  //       }
  //     }
  //   },
  //   [selectedMeter]
  // );

  // useMetersFilter(type, selectedBuilding, onMeterCompleted);

  const checkChartData = useCallback(
    graphData => {
      let data = {};
      if (!loading && graphData) {
        Object.keys(graphData).forEach((graph, index) => {
          data[graph] = graphData[graph]?.data?.map((key, index) => {
            if (key?.data) {
              if (key?.data?.length) {
                if (key.data.length === 1 && key.data[0] === 0) {
                  return false;
                } else {
                  return true;
                }
              } else {
                return false;
              }
            }
            return false;
          });
        });
      } else {
        data = {};
      }

      return data;
    },
    [loading]
  );
  const displayChart = chart => {
    return (
      chartDataFlag[chart] !== undefined &&
      typeof chartDataFlag[chart] &&
      !chartDataFlag[chart].find(data => data === true)
    );
  };

  const handleChangeBuildings = selectedOption => {
    setSelectedBuilding(selectedOption.value);
    setSelectedMeter("all");
    setSelectedMeterLabel(gettext("All"));

    setIsError(false);
    setIsSuccess(false);
    setStatusMessage("");
  };

  const handleChangeMeter = selectedOption => {
    setSelectedMeter(selectedOption.value);
    setSelectedMeterLabel(selectedOption.label);
  };

  const handleDownloadFile = type => {
    const newDate = new Date();
    setTimeStamp(newDate);
    setExportDisabled(true);
    setSelectedFileType(type);
  };

  //// methods

  useEffect(() => {
    if (!loading) {
      setChartDataFlag(checkChartData(graphs));
      // setChartData3Flag(checkChartData(chartData3));
    }

    // setNo/Data(data);
  }, [graphs, loading, checkChartData]);

  useEffect(() => {
    const buildingAddress = buildingData
      ? buildingData.filter(
          (v, i, a) => a.findIndex(v2 => v2.value === v.value) === i
        )
      : [];

    let meters = [];
    // let gasMeters = [];
    buildingAddress.forEach(item => {
      const types = type ? item[type] : item.electricity;
      types &&
        types.forEach(item2 => {
          meters.push({
            label: item2?.label ? item2.label : item2?.meterNo,
            value: item2?.meterNo
          });
          return true;
        });
      return true;
    });

    meters = meters.filter(
      (v, i, a) => a.findIndex(v2 => v2.value === v.value) === i
    );

    let addressArray = buildingAddress.map((item, index) => {
      return item.value;
    });
    setAddressesList(addressArray.join("|"));
    setAllBuildingMeters(meters);

    if (meters.length) {
      setCustomerMeters([
        ...[{ label: gettext("All"), value: "all" }],
        ...meters
      ]);
    } else {
      setCustomerMeters([]);
    }
    setCustomerBuilding([
      ...[{ label: gettext("All"), value: "All" }],
      ...buildingAddress
    ]);
  }, [buildingData, type, selectedBuilding, gettext]);

  useEffect(() => {
    const fileSegment =
      selectedSegment === 0 ? "day" : selectedSegment === 1 ? "month" : "year";
    setExportFilename(
      `${type}-consumption-${fileSegment}-${slug(
        selectedBuilding
      )}-${unitStart}-${unitEnd}`
    );
  }, [unitStart, unitEnd, selectedSegment, selectedBuilding, type]);

  useEffect(() => {
    if (selectedBuilding === "All" || selectedBuilding === "all") {
      if (allBuildingMeters.length) {
        setCustomerMeters([
          ...[{ label: gettext("All"), value: "all" }],
          ...allBuildingMeters
        ]);
      } else {
        setCustomerMeters([]);
      }
    } else {
      let tempMeters = [];
      // let gasMetersList = [];
      let data =
        buildingData &&
        buildingData.find(item => item.value === selectedBuilding);

      data = data && data[type];

      data &&
        data.forEach(item2 => {
          tempMeters.push({
            label: item2?.label ? item2.label : item2?.meterNo,
            value: item2?.meterNo
          });
          return true;
        });

      if (tempMeters.length) {
        setCustomerMeters([
          ...[{ label: gettext("All"), value: "all" }],
          ...tempMeters
        ]);
      } else {
        setCustomerMeters([]);
      }
    }
  }, [buildingData, type, selectedBuilding, allBuildingMeters, gettext]);

  useEffect(() => {
    localStorage.setItem(
      "energyFilters",
      JSON.stringify({
        date: date,
        selectedSegment: selectedSegment,
        buildingCode:
          selectedBuilding === "All"
            ? selectedBuilding.toLowerCase()
            : selectedBuilding,
        buildingName: selectedBuilding,
        // buildingNameLabel: gettext(selectedBuilding),
        buildingNameLabel:
          selectedBuilding === "all"
            ? gettext("All")
            : gettext(selectedBuilding),
        buildingMeterLabel:
          selectedMeter === "all" ? gettext("All") : gettext(selectedMeterLabel)
      })
    );
  }, [
    date,
    selectedSegment,
    selectedMeter,
    selectedBuilding,
    selectedMeterLabel,
    gettext
  ]);

  return (
    <>
      <div className={"card card-custom gutter-b example example-compact"}>
        <div className="card-body">
          <StatusMessage
            isError={isError}
            isSussess={isSuccess}
            statusMessage={statusMessage}
          />

          <div className={"row top-section-filters"}>
            <div className={"col-lg-1 col-md-2 py-md-3 building-name"}>
              {gettext("Building")}
            </div>
            <div className={"col-lg-3 col-md-5 py-2 py-md-0"}>
              <Select
                options={customerBuilding}
                onChange={handleChangeBuildings}
                value={[
                  {
                    value: selectedBuilding,
                    label: localFilters?.buildingNameLabel
                      ? localFilters?.buildingNameLabel
                      : gettext("All")
                  }
                ]}
              />
            </div>
            <div className={"col-lg-3 col-md-5 py-2 py-md-0"}>
              <Select
                options={customerMeters}
                noOptionsMessage={() => {
                  if (customerMeters.length) return "No Option Match";
                  else return "No meter or label is created";
                }}
                onChange={handleChangeMeter}
                value={[
                  {
                    value: selectedMeter,
                    // label: selectedMeterLabel,

                    label: localFilters?.buildingMeterLabel
                      ? localFilters?.buildingMeterLabel
                      : gettext("All")
                  }
                ]}
              />
            </div>
            <div className={"col-lg-5 col-md-12"}>
              <TopSectionDatePicker
                getSelectedDate={(date, selectedSegment) => {
                  setDate(date);
                }}
                onSegmentChange={selectedSegment => {
                  setDate(date);
                  setSelectedSegment(selectedSegment);
                }}
                gettext={gettext}
              />
            </div>
          </div>

          {loading ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <>
              {type === "electricity" && (
                <ShowGreenElectricity greenStatus={eGreen} />
              )}
              {!Object.keys(chartDataFlag).length ||
              displayChart("chartData1") ||
              displayChart("chartData3") ? (
                <>
                  <Box component="div" whiteSpace="normal" textAlign="center">
                    <img
                      src="/media/NoGraphData.png"
                      alt="No Data Found"
                      width="150"
                      height="40"
                      className="pt-5"
                    />

                    <Typography
                      variant="body1"
                      color="primary"
                      sx={{
                        mt: 1,
                        fontSize: 24,
                        fontWeight: 500,
                        lineheight: 24
                      }}
                      textOverflow="hidden"
                    >
                      {gettext("No data found for selected date")}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="gray"
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineheight: 18
                      }}
                    >
                      {gettext("Please select another date.")}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  {graphs !== undefined && graphs && Object.keys(graphs).length
                    ? Object.keys(graphs).map((graph, index) => {
                        return (
                          <div className="chart-layout">
                            <MixedWidget3 data={graphs[graph]} />
                          </div>
                        );
                      })
                    : null}

                  {tableData !== undefined &&
                  tableData &&
                  Object.keys(tableData).length
                    ? Object.keys(tableData).map((tableType, index) => {
                        return (
                          <CustomTable
                            key={index}
                            locale={locale}
                            energySource={type}
                            tableData={tableData[tableType]}
                            tableType={tableType}
                            // tableDataHourly={tableDataHourly}
                            showExport={true}
                            exportFilename={exportFilename}
                            exportDisabled={exportDisabled}
                            buildingCode={selectedBuilding}
                            gettext={gettext}
                            handleDownloadFile={handleDownloadFile}
                          />
                        );
                      })
                    : null}
                  <DownloadFile
                    energyParams={energyParams}
                    selectedFileType={selectedFileType}
                    exportDisabled={exportDisabled}
                    setExportDisabled={setExportDisabled}
                    timeStamp={timeStamp}
                  />
                </>
              )}

              {/* <div className="chart-layout">
                <MixedWidget3 data={rateCo2ChartData} />
              </div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default injectIntl(GEnergyTypes);
