import { useUser } from "app/providers";

export function CanDisplay(module) {
  const { user } = useUser();
  const { demo } = user;
  const isSuperAdmin = Boolean(user?.isSuperAdmin);

  if (isSuperAdmin) {
    return true;
  }

  switch (module) {
    case "Buildings":
      return true;

    case "Budget":
      return isSuperAdmin;

    case "Company":
      return isSuperAdmin;

    case "Partner":
      return user?.roles?.find(obj => obj.name === "Partner");

    case "prediction":
      // return user?.roles?.find(role => role === "Customer");
      return isSuperAdmin;

    case "support":
      if (!demo) {
        return true;
      } else {
        return false;
      }

    case "Admin":
      return isSuperAdmin;

    case "Account":
      return true;

    case "TEAM_SETTING":
      return user?.roles?.find(obj => obj.name === "Customer");

    default:
      return false;
  }
}
