import { Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { FileListAccordtion } from "../../../features";

export function DataLink() {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight={"bold"}>
              {gettext("Data Link")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <FileListAccordtion />
      </Grid>
    </Grid>
  );
}
