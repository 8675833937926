import { useMutation } from "@apollo/client";
// import { deleteAll } from "app/common/IndexedDb";
import { useAuth } from "app/providers";
// import { useMe } from "hooks";
import { useCallback } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { LOGIN_AS } from "../commands";

export const useLoginAs = onComplete => {
  // const { refetchMe, refetchColors } = useMe();

  const { setToken } = useAuth();

  const navigate = useNavigate();

  const [comLoginAs] = useMutation(LOGIN_AS, {
    onCompleted: ({ comLoginAs }) => {
      // localStorage.clear();

      // deleteAll("cache").then(() => { });

      setToken(comLoginAs.token);
      navigate("/dashboard", { state: { loginAs: true } });

      // refetchMe();
      // refetchColors();
    }
  });

  const loginAs = useCallback(
    async email => {
      try {
        await comLoginAs({
          variables: {
            email
          }
        });

        ReactGA.event({
          category: "Authentication",
          action: "Logout",
          label: email.replace("@", "[at]")
        });
      } catch (error) {}
    },
    [comLoginAs]
  );

  return { loginAs };
};
