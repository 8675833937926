import { useQuery } from "@apollo/client";
import { FETCH_PLAN_LINK_CSV_BY_ID } from "./commands";

export function useGetPlanLinkCsvById(id) {
  const { data, loading } = useQuery(FETCH_PLAN_LINK_CSV_BY_ID, {
    variables: { id }
  });

  return {
    plainLinkCsvURL: data?.plainLinkCsv.fileUrl,
    plainLinkName: data?.plainLinkCsv.name || "",
    loading
  };
}
