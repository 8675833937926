import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  styled,
  Typography
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { AiOutlinePlus } from "react-icons/ai";
import { useTextdomainContext } from "app/providers";
import React from "react";
import {
  IoClose,
  IoInformationCircle,
  IoPencil,
  IoSpeedometer
} from "react-icons/io5";
import { useBuildingStep } from "app/features/RegistrationFlowAddMeterForm/providers";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400
  }
}));

export function Meter({ type }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const {
    buildingMeterData,
    setMetersData,
    editMetersData,
    deleteMetersData,
    saveMetersData
  } = useBuildingStep();

  const [onInputMode, setOnInputMode] = React.useState(
    !buildingMeterData?.[type]?.data?.length || false
  );

  const [isEditing, setIsEditing] = React.useState(false);
  const [editingIndex, setEditingIndex] = React.useState();

  const [meterLabel, setMeterLabel] = React.useState("");
  const [meterNumber, setMeterNumber] = React.useState("");

  // const [meterLabel, setmeterLabel] = React.useState("");
  // const [meterLabel, setmeterLabel] = React.useState("");

  const addMeterClickHandler = React.useCallback(() => {
    setMetersData(type, {
      label: meterLabel,
      meterNo: meterNumber
    });
    setOnInputMode(false);
    setMeterLabel("");
    setMeterNumber("");
  }, [type, meterLabel, meterNumber, setMetersData]);

  const editMeterClickHandler = React.useCallback(() => {
    editMetersData(type, editingIndex, {
      label: meterLabel,
      meterNo: meterNumber
    });
    setOnInputMode(false);
    setIsEditing(false);
    setMeterLabel("");
    setMeterNumber("");
  }, [type, editingIndex, meterLabel, meterNumber, editMetersData]);

  const goToEditMode = React.useCallback(
    index => {
      setMeterLabel(buildingMeterData?.[type]?.data?.[index]?.label);
      setMeterNumber(buildingMeterData?.[type]?.data?.[index]?.meterNo);
      setEditingIndex(index);
      setOnInputMode(true);
      setIsEditing(true);
    },
    [type, buildingMeterData]
  );

  const deleteMeterClickHandler = React.useCallback(
    index => {
      deleteMetersData(type, index);
    },
    [type, deleteMetersData]
  );

  return onInputMode ? (
    <Stack spacing={2}>
      <Stack>
        <InputLabel htmlFor="meter-label">
          <Typography fontSize={14} fontWeight={500} color="secondary.700">
            {gettext("Meter Label")}
          </Typography>
        </InputLabel>
        <OutlinedInput
          id="meter-label"
          name="meterLabel"
          type={"text"}
          placeholder={gettext("Enter meter label")}
          fullWidth
          size="large"
          value={meterLabel}
          onChange={e => setMeterLabel(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <LightTooltip
                title={
                  <Typography color="primary.600" textAlign={"center"}>
                    {gettext("Input the meter label.")}
                  </Typography>
                }
                arrow
                placement="top"
              >
                <IconButton>
                  <IoInformationCircle />
                </IconButton>
              </LightTooltip>
            </InputAdornment>
          }
        />
      </Stack>

      <Stack>
        <InputLabel htmlFor="meter-number">
          <Typography fontSize={14} fontWeight={500} color="secondary.700">
            {gettext("Meter Number")}
          </Typography>
        </InputLabel>
        <OutlinedInput
          id="meter-number"
          name="meterNumber"
          type={"text"}
          fullWidth
          placeholder={gettext("Enter meter number")}
          size="large"
          value={meterNumber}
          onChange={e => setMeterNumber(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <LightTooltip
                title={
                  <Typography color="primary.600" textAlign={"center"}>
                    {gettext("Input the meter number.")}
                  </Typography>
                }
                arrow
                placement="top"
              >
                <IconButton>
                  <IoInformationCircle />
                </IconButton>
              </LightTooltip>
            </InputAdornment>
          }
        />
      </Stack>

      <Button
        disableElevation
        disabled={meterLabel === "" || meterNumber === ""}
        fullWidth
        size="large"
        variant="contained"
        color="secondary"
        onClick={
          isEditing
            ? () => editMeterClickHandler()
            : () => addMeterClickHandler()
        }
      >
        {isEditing ? gettext("Edit") : gettext("Add")}
      </Button>
    </Stack>
  ) : (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography fontSize={18} fontWeight={500} color="secondary.700">
          {gettext("Add more meters")}
        </Typography>

        <Button
          disableElevation
          size="medium"
          variant="outlined"
          startIcon={<AiOutlinePlus size={16} />}
          color="secondary"
          onClick={() => setOnInputMode(true)}
        >
          {gettext("Add")}
        </Button>
      </Box>

      {buildingMeterData?.[type]?.data?.map((meter, index) => (
        <Box
          key={meter?.label}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "white",
            border: "1px solid",
            borderColor: "secondary.200",
            borderRadius: "8px",
            gap: 1
          }}
        >
          <IoSpeedometer size={16} color="#34795A" />
          <Typography fontSize={16} fontWeight={500} color="#27272A">
            {meter?.label}
          </Typography>
          <Box sx={{ flex: 1 }} />
          <IoPencil size={16} onClick={() => goToEditMode(index)} />
          <IoClose
            size={16}
            color="#EF4444"
            onClick={() => deleteMeterClickHandler(index)}
          />
        </Box>
      ))}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end"
        }}
      >
        <Button
          disableElevation
          size="medium"
          fullWidth={false}
          variant="contained"
          color="secondary"
          onClick={() => saveMetersData(type)}
        >
          {gettext("Save")}
        </Button>
      </Box>
    </Stack>
  );
}
