import { Chip, Collapse, Grid } from "@mui/material";
import React from "react";

export function Level1Tabs({
  Level1SelectedTab,
  Level1TabGroups,
  handleLevel1TabChange,
  name
}) {
  return (
    <Collapse in={Boolean(Level1TabGroups)}>
      <Grid container spacing={1}>
        {Level1TabGroups?.map((tab, index) => (
          <Grid item>
            <Chip
              key={index}
              label={name && tab[name]}
              variant={
                Level1SelectedTab === (name && tab["key"])
                  ? "filled"
                  : "outlined"
              }
              color="success"
              size="small"
              onClick={() => handleLevel1TabChange(tab, index)}
            />
          </Grid>
        ))}
      </Grid>
    </Collapse>
  );
}
