import { useQuery } from "@apollo/client";
import { GET_CUSTOMERS_ROLES_BY_ID } from "../commands/getCustomerRolesById";

export function useCustomerRolesById(customerId) {
  const { data, loading, error } = useQuery(GET_CUSTOMERS_ROLES_BY_ID, {
    variables: {
      customerId
    }
  });

  return {
    customerRoles: data?.comCustomerRoles || [],
    loading,
    error
  };
}
