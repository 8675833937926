import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { DELETE_ROLE } from "../commands";

export function useDeleteRole(onCompleted = () => {}, onError = () => {}) {
  const [comDeleteRole, { loading }] = useMutation(DELETE_ROLE, {
    onCompleted,
    onError,
    refetchQueries: ["comRoles"]
  });

  const deleteRole = useCallback(
    variables => {
      comDeleteRole({
        variables
      });
    },
    [comDeleteRole]
  );

  return {
    delete: deleteRole,
    deleting: loading
  };
}
