import client from "services/apiClient";
import { DECIMAL } from "../../../common/Constants";
import {
  getCacheWithoutExpiry,
  localeNumberFormat,
  translateMonth
} from "../../../common/Functions";
import * as Constants from "../../../common/Constants";
import download from "downloadjs";
import { totalEnergyValue } from "./Co2Function";

const { REACT_APP_API_URL } = process.env;

export const totalEnergyCo2 = (data, year) => {
  const totalElectricityCo2 = totalYearCo2(data, "electricity", year, "cons");
  const totalHeatingCo2 = totalYearCo2(data, "heating", year, "cons");
  const totalWaterCo2 = totalYearCo2(data, "water", year, "cons");
  return totalElectricityCo2 + totalHeatingCo2 + totalWaterCo2;
};

export const totalYearCo2 = (data, type, year, use_type) => {
  let totalCo2 = 0;
  // let totalCo2Production = 0;
  if (data) {
    data[type].map(obj1 => {
      if (use_type === "prod" && obj1.type_of_mp === "E18") {
        obj1.data.map(obj2 => {
          let dateParse = obj2.ts.split("-").map(Number);
          if (dateParse[0] === year) {
            totalCo2 += obj2.co2;
          }
          return false;
        });
      } else if (use_type === "cons" && obj1.type_of_mp !== "E18") {
        if (
          (type === "electricity" && !data.elctricityGreen) ||
          type !== "electricity"
        ) {
          obj1.data.map(obj2 => {
            let dateParse = obj2.ts.split("-").map(Number);
            if (dateParse[0] === year) {
              totalCo2 += obj2.co2;
            }
            return false;
          });
        }
      } else if (
        use_type === "comp" &&
        obj1.type_of_mp !== "E18" &&
        type === "electricity" &&
        data.elctricityGreen
      ) {
        obj1.data.map(obj2 => {
          let dateParse = obj2.ts.split("-").map(Number);
          if (dateParse[0] === year) {
            totalCo2 += obj2.co2;
          }
          return false;
        });
      }
      return false;
    });
  }
  return totalCo2;
};

export const co2ValueArray = (data, type, year, use_type) => {
  let finalArray = [];
  for (let m = 0; m < 12; m++) {
    let monthNumber = m + 1;
    let monthYear = year + "-" + monthNumber.toString().padStart(2, "0");
    finalArray[m] = 0;
    data[type].map(obj1 => {
      if (use_type === "prod" && obj1.type_of_mp === "E18") {
        obj1.data.map(obj2 => {
          if (obj2.ts === monthYear) {
            finalArray[m] = obj2.co2;
          }
          return false;
        });
      } else if (use_type === "cons" && obj1.type_of_mp !== "E18") {
        if (
          (type === "electricity" && !data.elctricityGreen) ||
          type !== "electricity"
        ) {
          obj1.data.map(obj2 => {
            if (obj2.ts === monthYear) {
              finalArray[m] = obj2.co2;
            }
            return false;
          });
        }
      } else if (
        use_type === "comp" &&
        obj1.type_of_mp !== "E18" &&
        type === "electricity" &&
        data.elctricityGreen
      ) {
        obj1.data.map(obj2 => {
          if (obj2.ts === monthYear) {
            finalArray[m] = obj2.co2;
          }
          return false;
        });
      }
      return false;
    });
  }
  return finalArray;
};

export const dataEnergyGraph1 = (chartId, data, gettext) => {
  let colors = [];
  let chartData = [];

  let yaxisTitleText = gettext("Tons CO₂");
  const chartTitle = gettext("Annual CO₂ reductions measured in tonnes");
  const year1 = data.to_year ? data.to_year : "...";
  const year2 = data.second_year ? data.second_year : "...";
  const year3 = data.from_year ? data.from_year : "...";
  const electricityConsLabel = gettext("Electricity Consumption");
  const electricityProdLabel = gettext("Electricity Production");
  const electricityCompLabel = gettext("Electricity Compensation");
  const heatingLabel = gettext("Heating Consumption");
  const waterLabel = gettext("Water Consumption");

  const totalElectricityCo2Year1 = totalYearCo2(
    data,
    "electricity",
    year1,
    "cons"
  );
  const totalElectricityCo2Year2 = totalYearCo2(
    data,
    "electricity",
    year2,
    "cons"
  );
  const totalElectricityCo2Year3 = totalYearCo2(
    data,
    "electricity",
    year3,
    "cons"
  );

  const totalElectricityProdCo2Year1 = totalYearCo2(
    data,
    "electricity",
    year1,
    "prod"
  );
  const totalElectricityProdCo2Year2 = totalYearCo2(
    data,
    "electricity",
    year2,
    "prod"
  );
  const totalElectricityProdCo2Year3 = totalYearCo2(
    data,
    "electricity",
    year3,
    "prod"
  );

  const totalElectricityCompCo2Year1 = totalYearCo2(
    data,
    "electricity",
    year1,
    "comp"
  );
  const totalElectricityCompCo2Year2 = totalYearCo2(
    data,
    "electricity",
    year2,
    "comp"
  );
  const totalElectricityCompCo2Year3 = totalYearCo2(
    data,
    "electricity",
    year3,
    "comp"
  );

  const totalHeatingCo2Year1 = totalYearCo2(data, "heating", year1, "cons");
  const totalHeatingCo2Year2 = totalYearCo2(data, "heating", year2, "cons");
  const totalHeatingCo2Year3 = totalYearCo2(data, "heating", year3, "cons");

  const totalHeatingCo2ProdYear1 = totalYearCo2(data, "heating", year1, "prod");
  const totalHeatingCo2ProdYear2 = totalYearCo2(data, "heating", year2, "prod");
  const totalHeatingCo2ProdYear3 = totalYearCo2(data, "heating", year3, "prod");

  const totalWaterCo2Year1 = totalYearCo2(data, "water", year1, "cons");
  const totalWaterCo2Year2 = totalYearCo2(data, "water", year2, "cons");
  const totalWaterCo2Year3 = totalYearCo2(data, "water", year3, "cons");

  const totalWaterCo2ProdYear1 = totalYearCo2(data, "water", year1, "prod");
  const totalWaterCo2ProdYear2 = totalYearCo2(data, "water", year2, "prod");
  const totalWaterCo2ProdYear3 = totalYearCo2(data, "water", year3, "prod");

  colors.push(Constants.ELECTRICITY_COLOR);
  chartData.push({
    name: electricityConsLabel,
    data: [
      totalElectricityCo2Year3,
      totalElectricityCo2Year2,
      totalElectricityCo2Year1
    ]
  });

  colors.push(Constants.PRODUCTION_COLOR);
  chartData.push({
    name: electricityProdLabel,
    data: [
      totalElectricityProdCo2Year3,
      totalElectricityProdCo2Year2,
      totalElectricityProdCo2Year1
    ]
  });

  colors.push(Constants.COMPENSATION_COLOR);
  chartData.push({
    name: electricityCompLabel,
    data: [
      totalElectricityCompCo2Year3,
      totalElectricityCompCo2Year2,
      totalElectricityCompCo2Year1
    ]
  });

  if (totalHeatingCo2Year1 + totalHeatingCo2Year2 + totalHeatingCo2Year3 > 0) {
    colors.push(Constants.HEAT_COLOR);
    chartData.push({
      name: heatingLabel,
      data: [totalHeatingCo2Year3, totalHeatingCo2Year2, totalHeatingCo2Year1]
    });
  }

  if (totalWaterCo2Year1 + totalWaterCo2Year2 + totalWaterCo2Year3 > 0) {
    colors.push(Constants.WATER_COLOR);
    chartData.push({
      name: waterLabel,
      data: [totalWaterCo2Year3, totalWaterCo2Year2, totalWaterCo2Year1]
    });
  }

  if (
    totalElectricityProdCo2Year1 +
      totalElectricityProdCo2Year2 +
      totalElectricityProdCo2Year3 +
      (totalHeatingCo2ProdYear1 +
        totalHeatingCo2ProdYear2 +
        totalHeatingCo2ProdYear3) +
      (totalWaterCo2ProdYear1 +
        totalWaterCo2ProdYear2 +
        totalWaterCo2ProdYear3) >
    0
  ) {
    colors.push(Constants.PRODUCTION_COLOR);
    chartData.push({
      name: "Production",
      data: [
        totalElectricityProdCo2Year3 +
          totalHeatingCo2ProdYear3 +
          totalWaterCo2ProdYear3,
        totalElectricityProdCo2Year2 +
          totalHeatingCo2ProdYear2 +
          totalWaterCo2ProdYear2,
        totalElectricityProdCo2Year1 +
          totalHeatingCo2ProdYear1 +
          totalWaterCo2ProdYear1
      ]
    });
  }

  let graphOptions = {
    unique_id: chartId,
    type: "bar",
    chart_title: chartTitle,
    categories: [year3, year2, year1],
    stacked: true,
    dataLabelsEnabled: false,
    plotOptionsBarDistributed: false,
    yaxisTitleText: yaxisTitleText,
    colors,
    data: chartData
  };
  return graphOptions;
};

export const dataEnergyGraph2 = (chartId, year, data, gettext) => {
  let colors = [];
  let chartData = [];

  let yaxisTitleText = gettext("Tons CO₂");
  const chartTitle = `${gettext(
    "Monthly CO₂ reduction measured in tonnes for"
  )} ${year}`;
  const electricityConsLabel = gettext("Electricity Consumption");
  const electricityProdLabel = gettext("Electricity Production");
  const electricityCompLabel = gettext("Electricity Compensation");
  const heatingLabel = gettext("Heating Consumption");
  const waterLabel = gettext("Water Consumption");

  const electricityValues = co2ValueArray(data, "electricity", year, "cons");
  const heatingValues = co2ValueArray(data, "heating", year, "cons");
  const waterValues = co2ValueArray(data, "water", year, "cons");

  const electricityProdValues = co2ValueArray(
    data,
    "electricity",
    year,
    "prod"
  );
  const heatingProdValues = co2ValueArray(data, "heating", year, "prod");
  const waterProdValues = co2ValueArray(data, "water", year, "prod");

  const electricityCompValues = co2ValueArray(
    data,
    "electricity",
    year,
    "comp"
  );

  //const totalElectricityCo2Year = totalYearCo2(data, "electricity", year, "cons");
  const totalHeatingCo2Year = totalYearCo2(data, "heating", year, "cons");
  const totalWaterCo2Year = totalYearCo2(data, "water", year, "cons");

  // const totalElectricityProdCo2Year = totalYearCo2( data, "electricity", year, "prod" );
  const totalHeatingProdCo2Year = totalYearCo2(data, "heating", year, "prod");
  const totalWaterProdCo2Year = totalYearCo2(data, "water", year, "prod");

  colors.push(Constants.ELECTRICITY_COLOR);
  chartData.push({
    name: electricityConsLabel,
    data: electricityValues
  });

  colors.push(Constants.PRODUCTION_COLOR);
  chartData.push({
    name: electricityProdLabel,
    data: electricityProdValues
  });

  colors.push(Constants.COMPENSATION_COLOR);
  chartData.push({
    name: electricityCompLabel,
    data: electricityCompValues
  });

  if (totalHeatingCo2Year > 0) {
    colors.push(Constants.HEAT_COLOR);
    chartData.push({
      name: heatingLabel,
      data: heatingValues
    });
  }

  if (totalWaterCo2Year > 0) {
    colors.push(Constants.WATER_COLOR);
    chartData.push({
      name: waterLabel,
      data: waterValues
    });
  }

  if (totalHeatingProdCo2Year > 0) {
    colors.push(Constants.PRODUCTION_COLOR);
    chartData.push({
      name: gettext("Heating Production"),
      data: heatingProdValues
    });
  }

  if (totalWaterProdCo2Year > 0) {
    colors.push(Constants.PRODUCTION_COLOR);
    chartData.push({
      name: gettext("Water Production"),
      data: waterProdValues
    });
  }

  let graphOptions = {
    unique_id: chartId,
    type: "bar",
    chart_title: chartTitle,
    categories: [
      translateMonth(1, "short"),
      translateMonth(2, "short"),
      translateMonth(3, "short"),
      translateMonth(4, "short"),
      translateMonth(5, "short"),
      translateMonth(6, "short"),
      translateMonth(7, "short"),
      translateMonth(8, "short"),
      translateMonth(9, "short"),
      translateMonth(10, "short"),
      translateMonth(11, "short"),
      translateMonth(12, "short")
    ],
    stacked: true,
    dataLabelsEnabled: false,
    plotOptionsBarDistributed: false,
    yaxisTitleText: yaxisTitleText,
    colors,
    data: chartData
  };

  return graphOptions;
};

export const dataEnergyGraph3 = (chartId, year, data, gettext) => {
  let colors = [];
  let chartData = [];
  let labels = [];

  const chartTitle = `${gettext("CO₂ emission distribution for")} ${year}`;
  const electricityConsLabel = gettext("Electricity Consumption");
  const electricityProdLabel = gettext("Electricity Production");
  const electricityCompLabel = gettext("Electricity Compensation");
  const heatingLabel = gettext("Heating Consumption");
  const waterLabel = gettext("Water Consumption");

  const totalElectricityConsCo2Year = totalYearCo2(
    data,
    "electricity",
    year,
    "cons"
  );
  const totalElectricityProdCo2Year = totalYearCo2(
    data,
    "electricity",
    year,
    "prod"
  );
  const totalElectricityCompCo2Year = totalYearCo2(
    data,
    "electricity",
    year,
    "comp"
  );

  const totalHeatingCo2Year = totalYearCo2(data, "heating", year, "cons");

  const totalWaterCo2Year = totalYearCo2(data, "water", year, "cons");

  const totalEnergyCo2Year = Number(
    totalElectricityConsCo2Year +
      totalElectricityProdCo2Year +
      totalElectricityCompCo2Year +
      totalHeatingCo2Year +
      totalWaterCo2Year
  );
  const perElectricityConsCo2Year = Number(
    (totalElectricityConsCo2Year * 100) / totalEnergyCo2Year
  );
  const perElectricityProdCo2Year = Number(
    (totalElectricityProdCo2Year * 100) / totalEnergyCo2Year
  );
  const perElectricityCompCo2Year = Number(
    (totalElectricityCompCo2Year * 100) / totalEnergyCo2Year
  );

  const perHeatingCo2Year = Number(
    (totalHeatingCo2Year * 100) / totalEnergyCo2Year
  );
  const perWaterCo2Year = Number(
    (totalWaterCo2Year * 100) / totalEnergyCo2Year
  );

  colors.push(Constants.ELECTRICITY_COLOR);
  chartData.push(perElectricityConsCo2Year);
  labels.push(electricityConsLabel);

  colors.push(Constants.PRODUCTION_COLOR);
  chartData.push(perElectricityProdCo2Year);
  labels.push(electricityProdLabel);

  colors.push(Constants.COMPENSATION_COLOR);
  chartData.push(perElectricityCompCo2Year);
  labels.push(electricityCompLabel);

  if (totalHeatingCo2Year > 0) {
    colors.push(Constants.HEAT_COLOR);
    chartData.push(perHeatingCo2Year);
    labels.push(heatingLabel);
  }

  if (totalWaterCo2Year > 0) {
    colors.push(Constants.WATER_COLOR);
    chartData.push(perWaterCo2Year);
    labels.push(waterLabel);
  }

  let graphOptions = {
    unique_id: chartId,
    type: "pie",
    chart_title: chartTitle,
    labels,
    colors,
    data: chartData
  };

  return graphOptions;
};

export const dataTypeGraphYearly = (
  chartId,
  chartTitleId,
  type,
  data,
  gettext
) => {
  const yaxisTitleText = gettext("Tons CO₂");
  const chartTitle = gettext(chartTitleId);
  const year1 = data.to_year ? data.to_year : "...";
  const year2 = data.second_year ? data.second_year : "...";
  const year3 = data.from_year ? data.from_year : "...";

  const totalTypeConsCo2Year1 = totalYearCo2(data, type, year1, "cons");
  const totalTypeConsCo2Year2 = totalYearCo2(data, type, year2, "cons");
  const totalTypeConsCo2Year3 = totalYearCo2(data, type, year3, "cons");

  const totalTypeProdCo2Year1 = totalYearCo2(data, type, year1, "prod");
  const totalTypeProdCo2Year2 = totalYearCo2(data, type, year2, "prod");
  const totalTypeProdCo2Year3 = totalYearCo2(data, type, year3, "prod");

  const totalTypeCompCo2Year1 = totalYearCo2(data, type, year1, "comp");
  const totalTypeCompCo2Year2 = totalYearCo2(data, type, year2, "comp");
  const totalTypeCompCo2Year3 = totalYearCo2(data, type, year3, "comp");

  const colors = {
    electricity: [
      Constants.YEAR1_ELECTRICITY_COLOR,
      Constants.YEAR_PRODUCTION_COLOR,
      Constants.YEAR_COMPENSATION_COLOR
    ],
    heating: [Constants.YEAR1_HEAT_COLOR, Constants.YEAR_PRODUCTION_COLOR],
    water: [Constants.YEAR1_WATER_COLOR, Constants.YEAR_PRODUCTION_COLOR]
  };
  const typeLabels = {
    electricity: gettext("Electricity"),
    heating: gettext("Heating Consumption"),
    water: gettext("Water Consumption")
  };

  let chartData = [];
  if (type === "electricity") {
    chartData = [
      {
        name: gettext("Electricity Consumption"),
        data: [
          totalTypeConsCo2Year3,
          totalTypeConsCo2Year2,
          totalTypeConsCo2Year1
        ]
      },
      {
        name: gettext("Electricity Production"),
        data: [
          totalTypeProdCo2Year3,
          totalTypeProdCo2Year2,
          totalTypeProdCo2Year1
        ]
      },
      {
        name: gettext("Electricity Compensation"),
        data: [
          totalTypeCompCo2Year3,
          totalTypeCompCo2Year2,
          totalTypeCompCo2Year1
        ]
      }
    ];
  } else {
    chartData = [
      {
        name: typeLabels[type],
        data: [
          totalTypeConsCo2Year3,
          totalTypeConsCo2Year2,
          totalTypeConsCo2Year1
        ]
      }
    ];
  }

  let graphOptions = {
    unique_id: chartId,
    type: "bar",
    chart_title: chartTitle,
    categories: [year3, year2, year1],
    stacked: true,
    dataLabelsEnabled: false,
    plotOptionsBarDistributed: false,
    yaxisTitleText: yaxisTitleText,
    colors: colors[type],
    data: chartData
  };

  return graphOptions;
};

export const dataTypeGraphMonthly = (
  chartId,
  chartTitleId,
  type,
  data,
  gettext
) => {
  let chartData = [];
  const yaxisTitleText = gettext("Tons CO₂");

  const year1 = data.to_year ? data.to_year : "...";
  const year2 = data.second_year ? data.second_year : "...";
  const year3 = data.from_year ? data.from_year : "...";

  const typeYear1ConsValues = co2ValueArray(data, type, year1, "cons");
  const typeYear2ConsValues = co2ValueArray(data, type, year2, "cons");
  const typeYear3ConsValues = co2ValueArray(data, type, year3, "cons");

  const typeYear1ProdValues = co2ValueArray(data, type, year1, "prod");
  const typeYear2ProdValues = co2ValueArray(data, type, year2, "prod");
  const typeYear3ProdValues = co2ValueArray(data, type, year3, "prod");

  const typeYear1CompValues = co2ValueArray(data, type, year1, "comp");
  const typeYear2CompValues = co2ValueArray(data, type, year2, "comp");
  const typeYear3CompValues = co2ValueArray(data, type, year3, "comp");

  let chartTitle = "";
  if (type === "electricity") {
    chartTitle = `${gettext(
      "Electricity consumption divided into months for"
    )} ${year3}, ${year2} ${gettext("and")} ${year1} ${gettext(
      "measured in tonnes of CO₂"
    )}`;
  } else if (type === "heating") {
    chartTitle = `${gettext(
      "Heat consumption divided into months for"
    )} ${year3}, ${year2} ${gettext("and")} ${year1} ${gettext(
      "measured in tonnes of CO₂"
    )}`;
  } else if (type === "water") {
    chartTitle = `${gettext(
      "Water consumption tonnes of CO₂ per month for"
    )} ${year3}, ${year2}, ${year1}`;
  }

  const colors = {
    electricity: [
      Constants.YEAR1_ELECTRICITY_COLOR,
      Constants.YEAR2_ELECTRICITY_COLOR,
      Constants.YEAR3_ELECTRICITY_COLOR,
      Constants.YEAR1_ELECTRICITY_COLOR,
      Constants.YEAR2_ELECTRICITY_COLOR,
      Constants.YEAR3_ELECTRICITY_COLOR,
      Constants.YEAR1_ELECTRICITY_COLOR,
      Constants.YEAR2_ELECTRICITY_COLOR,
      Constants.YEAR3_ELECTRICITY_COLOR,
      Constants.YEAR_PRODUCTION_COLOR,
      Constants.YEAR_PRODUCTION_COLOR,
      Constants.YEAR_PRODUCTION_COLOR
      // Constants.YEAR_PRODUCTION_COLOR
    ],
    heating: [
      Constants.YEAR1_HEAT_COLOR,
      Constants.YEAR2_HEAT_COLOR,
      Constants.YEAR3_HEAT_COLOR,
      Constants.YEAR_PRODUCTION_COLOR,
      Constants.YEAR_PRODUCTION_COLOR,
      Constants.YEAR_PRODUCTION_COLOR
    ],
    water: [
      Constants.YEAR1_WATER_COLOR,
      Constants.YEAR2_WATER_COLOR,
      Constants.YEAR3_WATER_COLOR,
      Constants.YEAR_PRODUCTION_COLOR,
      Constants.YEAR_PRODUCTION_COLOR,
      Constants.YEAR_PRODUCTION_COLOR
    ]
  };

  chartData.push({
    name: `${gettext("Consumption")} ${year3}`,
    data: typeYear3ConsValues
  });
  chartData.push({
    name: `${gettext("Consumption")} ${year2}`,
    data: typeYear2ConsValues
  });
  chartData.push({
    name: `${gettext("Consumption")} ${year1}`,
    data: typeYear1ConsValues
  });

  if (type === "electricity") {
    chartData.push({
      name: `${gettext("Production")} ${year3}`,
      data: typeYear3ProdValues
    });
    chartData.push({
      name: `${gettext("Compensation")} ${year3}`,
      data: typeYear3CompValues
    });

    chartData.push({
      name: `${gettext("Production")} ${year2}`,
      data: typeYear2ProdValues
    });
    chartData.push({
      name: `${gettext("Compensation")} ${year2}`,
      data: typeYear2CompValues
    });

    chartData.push({
      name: `${gettext("Production")} ${year1}`,
      data: typeYear1ProdValues
    });
    chartData.push({
      name: `${gettext("Compensation")} ${year1}`,
      data: typeYear1CompValues
    });
  }

  let graphOptions = {
    unique_id: chartId,
    type: "bar",
    chart_title: chartTitle,
    categories: [
      translateMonth(1, "short"),
      translateMonth(2, "short"),
      translateMonth(3, "short"),
      translateMonth(4, "short"),
      translateMonth(5, "short"),
      translateMonth(6, "short"),
      translateMonth(7, "short"),
      translateMonth(8, "short"),
      translateMonth(9, "short"),
      translateMonth(10, "short"),
      translateMonth(11, "short"),
      translateMonth(12, "short")
    ],
    stacked: false,
    dataLabelsEnabled: false,
    plotOptionsBarDistributed: false,
    yaxisTitleText: yaxisTitleText,
    colors: colors[type],
    data: chartData
  };

  return graphOptions;
};

export const co2GeneratePdf = (data, gettext) => {
  return new Promise((resolve, reject) => {
    co2PdfAllPagesData(data, gettext).then(finalData => {
      // let bodyFormData = new FormData();
      // bodyFormData.append('bodyData', JSON.stringify(finalData));
      client
        .post(REACT_APP_API_URL + "co2pdf/", JSON.stringify(finalData), {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf"
          },
          responseType: "blob"
        })
        .then(response => {
          // console.log(response);
          const filename = "co2-report-" + Date.now() + ".pdf";
          const content = response.headers["content-type"];
          download(response.data, filename, content);
          setTimeout(() => {
            resolve(finalData);
            // this.setState({pdfButtonDisabled: false});
          }, 5000);
        })
        .catch(error => {
          /* console.log(error) */
        });
    });
  });
};

// Private functions
const co2PdfAllPagesData = (data, gettext) => {
  return new Promise((resolve, reject) => {
    let page1 = co2PdfPage1(data);
    let page13 = co2PdfPage6(data);

    co2PdfPage2(data).then(page2 => {
      co2PdfPage3(data, gettext).then(page3 => {
        co2PdfPage4(data, data.to_year, gettext).then(page4 => {
          co2PdfPage4(data, data.second_year, gettext).then(page5 => {
            co2PdfPage4(data, data.from_year, gettext).then(page6 => {
              co2PdfPage5(data, "electricity", gettext).then(page7 => {
                co2PdfPage5(data, "heating", gettext).then(page8 => {
                  co2PdfPage5(data, "water", gettext).then(page9 => {
                    let finaleData = {
                      page1: page1,
                      page2: page2,
                      page3: page3,
                      page4: page4,
                      page5: page5,
                      page6: page6,
                      page7: page7,
                      page8: page8,
                      page9: page9,
                      page13: page13
                    };
                    resolve(finaleData);
                  });
                });
              });
            });
          });
        });
      });
    });
  });
};

export const ghgPdfPage1 = (data, gettext) => {
  let company = data.customers_names ? data.customers_names : "...";
  let address = data.buildings ? data.buildings : "...";
  let finalData = {
    heading: gettext("GHG Report"),
    company: company,
    address
  };
  return finalData;
};

export const co2PdfPage1 = (data, gettext) => {
  let company = data.customers_names ? data.customers_names : "...";
  // let address = data.buildings ? data.buildings : "...";
  let finalData = {
    heading: gettext("Carbon Report"),
    company: company
    // address,
  };
  return finalData;
};

export const co2PdfPage2 = (data, energyData, gettext) => {
  let company = data.customers_names ? data.customers_names : "...";
  return new Promise((resolve, reject) => {
    const section1text =
      `${company ? company + "'s " : "Company's "} ` +
      gettext(
        "CO₂ report 2020 describes the CO₂ emissions for all the company's sources of supply: water, wastewater, heat and electricity. The CO₂ report makes it possible to follow the CO₂ emissions, and contributes to making decisions on an informed and database basis."
      ) +
      "<br /><br />" +
      gettext(
        "The basis for this report is The Greenhouse Gas Protocol, which is an internationally recognized tool for calculating and reporting greenhouse gas emissions. By using The Greenhouse Gas Protocol it is possible to compare with other similar companies, properties and public institutions."
      ) +
      "<br /><br />" +
      gettext(
        "The last page lists the assumptions and sources on which the calculation of CO₂ consumption is based in this report."
      ) +
      "<br /><br />" +
      gettext(
        "The report uses the 125% method for emissions, which is prescribed by the Danish Energy Agency."
      );
    const year1 = data.to_year ? data.to_year : "";
    const year3 = data.from_year ? data.from_year : "";

    const result3 = totalEnergyValue(energyData, year1);
    const result1 = totalEnergyValue(energyData, year3);

    const totalEnergyCo2Year1 = result3[1]; //totalEnergyCo2(data, year1);
    const totalEnergyCo2Year3 = result1[1]; // totalEnergyCo2(data, year3);

    let totalEnergyCo2Year1Formatted = localeNumberFormat(
      totalEnergyCo2Year1,
      DECIMAL
    );
    let totalEnergyCo2Year3Formatted = localeNumberFormat(
      totalEnergyCo2Year3,
      DECIMAL
    );

    let reportSummaryText = "";

    reportSummaryText += gettext("Below are the 3 previous years.");
    reportSummaryText += "<br /><br />";
    reportSummaryText += gettext("The total CO₂ emissions");
    reportSummaryText += "<br /><br />";
    if (totalEnergyCo2Year1 < totalEnergyCo2Year3) {
      reportSummaryText += `${gettext(
        "Total CO₂ emissions fell from"
      )} ${totalEnergyCo2Year3Formatted} ${gettext(
        "tonnes of CO₂ in"
      )} ${year3} ${gettext("to")} ${totalEnergyCo2Year1Formatted} ${gettext(
        "tonnes of CO₂ in"
      )} ${year1}.`;
    }
    if (totalEnergyCo2Year1 > totalEnergyCo2Year3) {
      reportSummaryText += `${gettext(
        "Total CO₂ emissions increased from"
      )} ${totalEnergyCo2Year3Formatted} ${gettext(
        "tonnes of CO₂ in"
      )} ${year3} ${gettext("to")} ${totalEnergyCo2Year1Formatted}`;
    }
    if (totalEnergyCo2Year1 === totalEnergyCo2Year3) {
      reportSummaryText += `${gettext(
        "The total CO₂ emissions have been constant at"
      )} ${totalEnergyCo2Year3Formatted} ${gettext(
        "tonnes of CO₂ in the period"
      )} ${year3} ${gettext("to")} ${year1}`;
    }
    reportSummaryText += "<br /><br />";
    reportSummaryText += gettext(
      "The CO₂ emissions emitted by the individual supply sources are stated under the individual sections for water, electricity, and heat."
    );

    let finalData = {
      section1heading: gettext("Introduction"),
      section1text: section1text,
      section2heading: gettext("Summary"),
      section2text: reportSummaryText
    };
    resolve(finalData);
  });
};

export const co2PdfPage3 = (data, gettext) => {
  return new Promise((resolve, reject) => {
    const year1 = data.to_year ? data.to_year : "";
    const year2 = data.second_year ? data.second_year : "";
    const year3 = data.from_year ? data.from_year : "";
    const title = `${gettext("CO₂ accounts for")} ${year3}, ${year2} ${gettext(
      "and"
    )} ${year1}`;
    const chartTitle = gettext("Annual CO₂ reductions measured in tonnes");
    const totalEnergyCo2Year1 = totalEnergyCo2(data, year1);
    const totalEnergyCo2Year3 = totalEnergyCo2(data, year3);

    let totalEnergyCo2Year1Formatted = localeNumberFormat(
      totalEnergyCo2Year1,
      DECIMAL
    );
    let totalEnergyCo2Year3Formatted = localeNumberFormat(
      totalEnergyCo2Year3,
      DECIMAL
    );

    let description = `${gettext(
      "The total CO₂ emissions have been constant at"
    )} ${totalEnergyCo2Year3Formatted} ${gettext(
      "tonnes of CO₂ in the period"
    )} ${year3} ${gettext("to")} ${year1}`;

    if (totalEnergyCo2Year1 < totalEnergyCo2Year3) {
      description = `${gettext(
        "Total CO₂ emissions fell from"
      )} ${totalEnergyCo2Year3Formatted} ${gettext(
        "tonnes of CO₂ in"
      )} ${year3} ${gettext("to")} ${totalEnergyCo2Year1Formatted} ${gettext(
        "tonnes of CO₂ in"
      )} ${year1}.`;
    } else if (totalEnergyCo2Year1 > totalEnergyCo2Year3) {
      description = `${gettext(
        "Total CO₂ emissions increased from"
      )} ${totalEnergyCo2Year3Formatted} ${gettext(
        "tonnes of CO₂ in"
      )} ${year3} ${gettext("to")} ${totalEnergyCo2Year1Formatted} ${gettext(
        "tonnes of CO₂ in"
      )} ${year1}`;
    }

    getCacheWithoutExpiry("chart1uri").then(chart1uri => {
      let finalData = {
        title: title,
        description: description,
        chart1title: chartTitle,
        chart1uri: chart1uri
      };
      resolve(finalData);
    });
  });
};

export const co2PdfPage4 = (data, selectedYear, gettext) => {
  return new Promise((resolve, reject) => {
    let totalEnergyCo2Year = 0;
    let totalElectricityCo2Year = 0;
    let totalHeatingCo2Year = 0;
    let totalWaterCo2Year = 0;
    let perElectricityCo2Year = 0;
    let perHeatingCo2Year = 0;
    let perWaterCo2Year = 0;

    let description = "";
    // let descKey = 'REPORT.DESCRIPTION.6';
    // const year1 = data.to_year ? data.to_year : '';
    // const year2 = data.second_year ? data.second_year : '';
    // const year3 = data.from_year ? data.from_year : '';
    const title = `${gettext("CO₂ accounts for")} ${selectedYear}`;

    const chartTitle1 = `${gettext(
      "Monthly CO₂ reduction measured in tonnes for"
    )} ${selectedYear}`;

    const chartTitle2 = `${gettext(
      "CO₂ emission distribution for"
    )} ${selectedYear}`;

    if (data.electricity || data.heating || data.water) {
      totalEnergyCo2Year = totalEnergyCo2(data, selectedYear);
      totalElectricityCo2Year = totalYearCo2(
        data,
        "electricity",
        selectedYear,
        "cons"
      );
      totalHeatingCo2Year = totalYearCo2(data, "heating", selectedYear, "cons");
      totalWaterCo2Year = totalYearCo2(data, "water", selectedYear, "cons");
      perElectricityCo2Year = Number(
        (totalElectricityCo2Year * 100) / totalEnergyCo2Year
      );
      perHeatingCo2Year = Number(
        (totalHeatingCo2Year * 100) / totalEnergyCo2Year
      );
      perWaterCo2Year = Number((totalWaterCo2Year * 100) / totalEnergyCo2Year);
    }

    let totalEnergyCo2YearFormatted = localeNumberFormat(
      totalEnergyCo2Year,
      DECIMAL
    );
    let totalElectricityCo2YearFormatted = localeNumberFormat(
      totalElectricityCo2Year,
      DECIMAL
    );
    let totalHeatingCo2YearFormatted = localeNumberFormat(
      totalHeatingCo2Year,
      DECIMAL
    );
    let totalWaterCo2YearFormatted = localeNumberFormat(
      totalWaterCo2Year,
      DECIMAL
    );
    let perElectricityCo2YearFormatted = localeNumberFormat(
      perElectricityCo2Year,
      DECIMAL
    );
    let perHeatingCo2YearFormatted = localeNumberFormat(
      perHeatingCo2Year,
      DECIMAL
    );
    let perWaterCo2YearFormatted = localeNumberFormat(perWaterCo2Year, DECIMAL);

    description += `${gettext("In")} ${selectedYear}, ${gettext(
      "the total CO₂ emissions were"
    )} ${totalEnergyCo2YearFormatted} ${gettext("tonnes of CO₂")}`;
    description += "<br />";
    description += "<br />";
    description += `${gettext(
      "Electricity accounted for a CO₂ emission of"
    )} ${totalElectricityCo2YearFormatted} ${gettext(
      "tonnes of CO₂ or"
    )} ${perElectricityCo2YearFormatted}% ${gettext(
      "of the total CO₂ emission."
    )}`;
    description += "<br />";
    description += "<br />";
    description += `${gettext(
      "The heat consumption accounted for a CO₂ emission of"
    )} ${totalHeatingCo2YearFormatted} ${gettext(
      "tonnes of CO₂ or"
    )} ${perHeatingCo2YearFormatted}% ${gettext("of the total CO₂ emission.")}`;
    description += "<br />";
    description += "<br />";
    description += `${gettext(
      "Water and wastewater accounted for a CO₂ emission of"
    )} ${totalWaterCo2YearFormatted} ${gettext(
      "tonnes of CO₂ or"
    )} ${perWaterCo2YearFormatted}% ${gettext("of the total CO₂ emission.")}`;

    let chart1uri = "chart6uri";
    let chart2uri = "chart7uri";
    switch (selectedYear) {
      case data.to_year:
        chart1uri = "chart2uri";
        chart2uri = "chart3uri";
        break;
      case data.second_year:
        chart1uri = "chart4uri";
        chart2uri = "chart5uri";
        break;
      case data.from_year:
      default:
        chart1uri = "chart6uri";
        chart2uri = "chart7uri";
        break;
    }

    getCacheWithoutExpiry(chart1uri).then(chart1uri => {
      getCacheWithoutExpiry(chart2uri).then(chart2uri => {
        let finalData = {
          title: title,
          description: description,
          chart1title: chartTitle1,
          chart2title: chartTitle2,
          chart1uri: chart1uri,
          chart2uri: chart2uri
        };
        resolve(finalData);
      });
    });
  });
};

const co2PdfPage5 = (data, selectedType, gettext) => {
  return new Promise((resolve, reject) => {
    let titleKey = "";
    let desc1 = "";
    let desc2 = "";
    let desc3 = "";
    let chartTitle1 = "";
    let chartTitle2 = "";

    const year1 = data.to_year ? data.to_year : "";
    const year2 = data.second_year ? data.second_year : "";
    const year3 = data.from_year ? data.from_year : "";

    const totalTypeCo2Year1 = totalYearCo2(data, selectedType, year1, "cons");
    // const totalTypeCo2Year2 = totalYearCo2(data, selectedType, year2, 'cons');
    const totalTypeCo2Year3 = totalYearCo2(data, selectedType, year3, "cons");

    let totalTypeCo2Year1Formatted = localeNumberFormat(
      totalTypeCo2Year1,
      DECIMAL
    );
    let totalTypeCo2Year3Formatted = localeNumberFormat(
      totalTypeCo2Year3,
      DECIMAL
    );

    switch (selectedType) {
      case "electricity":
        titleKey = gettext("CO₂ emissions from electricity");
        desc1 = `${gettext(
          "CO₂ emissions from electricity decreased from"
        )} ${totalTypeCo2Year3Formatted} ${gettext(
          "tonnes CO₂ in"
        )} ${year3} ${gettext("to")} ${totalTypeCo2Year1Formatted} ${gettext(
          "tonnes CO₂ in"
        )} ${year1}`;
        desc2 = `${gettext(
          "CO₂ emissions from electricity increased from"
        )} ${totalTypeCo2Year3Formatted} ${gettext(
          "tonnes CO₂ in"
        )} ${year3} ${gettext("to")} ${totalTypeCo2Year1Formatted} ${gettext(
          "tonnes CO₂ in"
        )} ${year1}`;
        desc3 = `${gettext(
          "The total CO₂ emissions have been constant at"
        )} ${totalTypeCo2Year3Formatted} ${gettext(
          "tonnes of CO₂ in the period"
        )} ${year3} ${gettext("to")} ${year1}`;
        chartTitle1 = gettext(
          "Total electricity consumption per year measured in tonnes of CO₂"
        );
        chartTitle2 = `${gettext(
          "Electricity consumption divided into months for"
        )} ${year3}, ${year2} ${gettext("and")} ${year1} ${gettext(
          "measured in tonnes of CO₂"
        )}`;
        break;
      case "heating":
        titleKey = gettext("CO₂ emissions from heating");
        desc1 = `${gettext(
          "CO₂ emissions for heat fell from"
        )} ${totalTypeCo2Year3Formatted} ${gettext(
          "tonnes of CO₂ in"
        )} ${year3} ${gettext("to")} ${totalTypeCo2Year1Formatted} ${gettext(
          "tonnes CO₂ in"
        )} ${year1}`;
        desc2 = `${gettext(
          "CO₂ emissions for heat rose from"
        )} ${totalTypeCo2Year3Formatted} ${gettext(
          "tonnes of CO₂ in"
        )} ${year3} ${gettext("to")} ${totalTypeCo2Year1Formatted} ${gettext(
          "tonnes CO₂ in"
        )} ${year1}`;
        desc3 = `${gettext(
          "The total CO₂ emissions have been constant at"
        )} ${totalTypeCo2Year3Formatted} ${gettext(
          "tonnes of CO₂ in the period"
        )} ${year3} ${gettext("to")} ${year1}`;
        chartTitle1 = gettext(
          "Total heat consumption per year measured in tonnes of CO₂"
        );
        chartTitle2 = `${gettext(
          "Heat consumption divided into months for"
        )} ${year3}, ${year2} ${gettext("and")} ${year1} ${gettext(
          "measured in tonnes of CO₂"
        )}`;
        break;
      case "water":
        titleKey = gettext("CO₂ emissions from water");
        desc1 = `${gettext(
          "CO₂ emissions for water have decreased from"
        )} ${totalTypeCo2Year3Formatted} ${gettext(
          "tons in"
        )} ${year3} ${gettext("to")} ${totalTypeCo2Year1Formatted} ${gettext(
          "tons of CO₂ in"
        )} ${year1}.`;
        desc2 = `${gettext(
          "CO₂ emissions for water increased from"
        )} ${totalTypeCo2Year3Formatted} ${gettext(
          "tons in"
        )} ${year3} ${gettext("to")} ${totalTypeCo2Year1Formatted} ${gettext(
          "tons of CO₂ in"
        )} ${year1}.`;
        desc3 = `${gettext(
          "CO₂ emissions for water are seen to be unchanged from"
        )} ${totalTypeCo2Year3Formatted} ${gettext(
          "tons in"
        )} ${year3} ${gettext("to")} ${totalTypeCo2Year1Formatted} ${gettext(
          "tons of CO₂ in"
        )} ${year1}.`;
        chartTitle1 = gettext(
          "Total water consumption per year measured in tonnes of CO₂"
        );
        chartTitle2 = `${gettext(
          "Water consumption tonnes of CO₂ per month for"
        )} ${year3}, ${year2}, ${year1}`;
        break;
      default:
        break;
    }
    let descKey = desc3;
    if (totalTypeCo2Year1 < totalTypeCo2Year3) {
      descKey = desc1;
    } else if (totalTypeCo2Year1 > totalTypeCo2Year3) {
      descKey = desc2;
    }
    const title = titleKey;
    const description = descKey;

    let chart1uri = "chart12uri";
    let chart2uri = "chart13uri";
    switch (selectedType) {
      case "electricity":
        chart1uri = "chart8uri";
        chart2uri = "chart9uri";
        break;
      case "heating":
        chart1uri = "chart10uri";
        chart2uri = "chart11uri";
        break;
      case "water":
      default:
        chart1uri = "chart12uri";
        chart2uri = "chart13uri";
        break;
    }

    // if(totalTypeCo2Year1 + totalTypeCo2Year2 + totalTypeCo2Year3 > 0) {
    getCacheWithoutExpiry(chart1uri).then(chart1uri => {
      getCacheWithoutExpiry(chart2uri).then(chart2uri => {
        let finalData = {
          title: title,
          description: description,
          chart1title: chartTitle1,
          chart2title: chartTitle2,
          chart1uri: chart1uri,
          chart2uri: chart2uri
        };
        resolve(finalData);
      });
    });
    // } else {
    //     resolve({});
    // }
  });
};

export const co2PdfPage6 = (data, gettext) => {
  let description = "";
  const title = gettext("Sources and database");
  description += gettext(
    "As a basis for calculating CO₂ emissions, the 125% method as recommended by the Danish Energy Agency has been used."
  );
  description += "<br />";
  description += "<br />";
  description += gettext(
    "CO₂ conversion factor for EL, data is used from Energinet's data hub."
  );
  description += "<br />";
  description += "<br />";
  description += gettext(
    "For the CO₂ discharge for water and wastewater, data from Vandital from 2020 from Danva have been used. Retrieved from their website at <a target='_blank' rel='noopener noreferrer' href='https://www.danva.dk'>www.danva.dk</a>."
  );
  description += "<br />";
  description += "<br />";
  description += gettext(
    "District heating is converted according to the Danish Energy Agency's 125% method for each individual district heating company, and they can be obtained by following: <a target='_blank' rel='noopener noreferrer' href='https://ens.dk/service/statistik-data-noegletal-og-kort/data-oversigt-over-energisektoren'>https://ens.dk/service/statistik-data-noegletal-og-kort/data-oversigt-over-energisektoren</a>"
  );
  description += "<br />";
  description += "<br />";
  description += gettext(
    "If a year has not been updated by the Danish Energy Agency, then the previous year's conversion is used until the year is updated on the Danish Energy Agency's website."
  );
  description += "<br />";
  description += "<br />";
  description += gettext(
    "Data: Overview of the energy sector | The Danish Energy Agency <a target='_blank' rel='noopener noreferrer' href='https://ens.dk'>ens.dk</a>"
  );

  let finalData = {
    title: title,
    description: description
  };

  return finalData;
};

// TODO: Following functions are not used. Verify and delete it.

// function initialApiDate() {
//   let data = {
//     total: {
//       year1: {
//         year: "2018",
//         electricity: {
//           months: {
//             jan: 0,
//             feb: 0,
//             mar: 0,
//             apr: 0,
//             may: 0,
//             jun: 0,
//             jul: 0,
//             aug: 0,
//             sep: 0,
//             oct: 0,
//             nov: 0,
//             dec: 0
//           },
//           year: 0
//         },
//         heat: {
//           months: {
//             jan: 0,
//             feb: 0,
//             mar: 0,
//             apr: 0,
//             may: 0,
//             jun: 0,
//             jul: 0,
//             aug: 0,
//             sep: 0,
//             oct: 0,
//             nov: 0,
//             dec: 0
//           },
//           year: 0
//         },
//         water: {
//           months: {
//             jan: 0,
//             feb: 0,
//             mar: 0,
//             apr: 0,
//             may: 0,
//             jun: 0,
//             jul: 0,
//             aug: 0,
//             sep: 0,
//             oct: 0,
//             nov: 0,
//             dec: 0
//           },
//           year: 0
//         }
//       },
//       year2: {
//         year: "2019",
//         electricity: {
//           months: {
//             jan: 0,
//             feb: 0,
//             mar: 0,
//             apr: 0,
//             may: 0,
//             jun: 0,
//             jul: 0,
//             aug: 0,
//             sep: 0,
//             oct: 0,
//             nov: 0,
//             dec: 0
//           },
//           year: 0
//         },
//         heat: {
//           months: {
//             jan: 0,
//             feb: 0,
//             mar: 0,
//             apr: 0,
//             may: 0,
//             jun: 0,
//             jul: 0,
//             aug: 0,
//             sep: 0,
//             oct: 0,
//             nov: 0,
//             dec: 0
//           },
//           year: 0
//         },
//         water: {
//           months: {
//             jan: 0,
//             feb: 0,
//             mar: 0,
//             apr: 0,
//             may: 0,
//             jun: 0,
//             jul: 0,
//             aug: 0,
//             sep: 0,
//             oct: 0,
//             nov: 0,
//             dec: 0
//           },
//           year: 0
//         }
//       },
//       year3: {
//         year: "2020",
//         electricity: {
//           months: {
//             jan: 0,
//             feb: 0,
//             mar: 0,
//             apr: 0,
//             may: 0,
//             jun: 0,
//             jul: 0,
//             aug: 0,
//             sep: 0,
//             oct: 0,
//             nov: 0,
//             dec: 0
//           },
//           year: 0
//         },
//         heat: {
//           months: {
//             jan: 0,
//             feb: 0,
//             mar: 0,
//             apr: 0,
//             may: 0,
//             jun: 0,
//             jul: 0,
//             aug: 0,
//             sep: 0,
//             oct: 0,
//             nov: 0,
//             dec: 0
//           },
//           year: 0
//         },
//         water: {
//           months: {
//             jan: 0,
//             feb: 0,
//             mar: 0,
//             apr: 0,
//             may: 0,
//             jun: 0,
//             jul: 0,
//             aug: 0,
//             sep: 0,
//             oct: 0,
//             nov: 0,
//             dec: 0
//           },
//           year: 0
//         }
//       }
//     }
//   };
//
//   return data;
// }

// function mergeApiData(initalData, apiData) {
//   let data = apiData;
//   let yearKey1 = "year1";
//   let yearKey2 = "year2";
//   let yearKey3 = "year3";
//
//   if (
//     initalData["total"]["year1"]["year"] < initalData["total"]["year3"]["year"]
//   ) {
//     yearKey1 = "year3";
//     yearKey3 = "year1";
//   }
//
//   if (data[2].elements[0].contents[0]) {
//     let y1mObj = data[2].elements[0].contents[0];
//     initalData = prepareYearData(initalData, y1mObj, yearKey1);
//   }
//
//   if (data[2].elements[0].contents[1]) {
//     let y2mObj = data[2].elements[0].contents[1];
//     initalData = prepareYearData(initalData, y2mObj, yearKey2);
//   }
//
//   if (data[2].elements[0].contents[2]) {
//     let y3mObj = data[2].elements[0].contents[2];
//     initalData = prepareYearData(initalData, y3mObj, yearKey3);
//   }
//   return initalData;
// }

// function prepareMonthData(initalData, data, yearKey, resource) {
//   initalData["total"][yearKey][resource]["months"]["jan"] = data[0];
//   initalData["total"][yearKey][resource]["months"]["feb"] = data[1];
//   initalData["total"][yearKey][resource]["months"]["mar"] = data[2];
//   initalData["total"][yearKey][resource]["months"]["apr"] = data[3];
//   initalData["total"][yearKey][resource]["months"]["may"] = data[4];
//   initalData["total"][yearKey][resource]["months"]["jun"] = data[5];
//   initalData["total"][yearKey][resource]["months"]["jul"] = data[6];
//   initalData["total"][yearKey][resource]["months"]["aug"] = data[7];
//   initalData["total"][yearKey][resource]["months"]["sep"] = data[8];
//   initalData["total"][yearKey][resource]["months"]["oct"] = data[9];
//   initalData["total"][yearKey][resource]["months"]["nov"] = data[10];
//   initalData["total"][yearKey][resource]["months"]["dec"] = data[11];
//   for (const mData in data) {
//     initalData["total"][yearKey][resource]["year"] += data[mData];
//   }
//
//   return initalData;
// }

// function prepareYearData(initalData, ymObj, yearKey) {
//   // let thisYear = initalData['total'][yearKey]['year']
//   if (ymObj.data.series[0]) {
//     // Electricity
//     let eData = ymObj.data.series[0];
//     initalData = prepareMonthData(initalData, eData, yearKey, "electricity");
//   }
//
//   if (ymObj.data.series[1]) {
//     // Heat
//     let hData = ymObj.data.series[1];
//     initalData = prepareMonthData(initalData, hData, yearKey, "heat");
//   }
//
//   if (ymObj.data.series[2]) {
//     // Water
//     let wData = ymObj.data.series[2];
//     initalData = prepareMonthData(initalData, wData, yearKey, "water");
//   }
//
//   return initalData;
// }

// function preparePageInitData(pageData, apiData) {
//   pageData.company = apiData[0].company;
//   pageData.address = apiData[0].address;
//   return pageData;
// }

// function yearTotal(finalApiData, yearKey) {
//   let obj = finalApiData["total"][yearKey];
//   return (
//     obj["electricity"]["year"] + obj["heat"]["year"] + obj["water"]["year"]
//   );
// }

// function prepareSection1Data(pageData, finalApiData, intl) {
//   let year1 = finalApiData["total"]["year1"]["year"];
//   let year2 = finalApiData["total"]["year2"]["year"];
//   let year3 = finalApiData["total"]["year3"]["year"];
//
//   let energyYear1 = yearTotal(finalApiData, "year1");
//   let energyYear2 = yearTotal(finalApiData, "year2");
//   let energyYear3 = yearTotal(finalApiData, "year3");
//
//   // console.log(energyYear1, energyYear2, energyYear3);
//
//   pageData.section1 = {};
//   pageData.section1.title = intl.formatMessage(
//     {
//       id: "REPORT.CO2.SECTION1.TITLE"
//     },
//     {
//       year1: year1,
//       year2: year2,
//       year3: year3
//     }
//   );
//
//   if (year1 > year3) {
//     let descData = {
//       year1: year1,
//       year3: year3,
//       energyYear1: energyYear1,
//       energyYear3: energyYear3
//     };
//     if (energyYear1 > energyYear3) {
//       pageData.section1.description = intl.formatMessage(
//         { id: "REPORT.CO2.SECTION1.DESC.1" },
//         descData
//       );
//     } else if (energyYear1 < energyYear3) {
//       pageData.section1.description = intl.formatMessage(
//         { id: "REPORT.CO2.SECTION1.DESC.2" },
//         descData
//       );
//     } else {
//       pageData.section1.description = intl.formatMessage(
//         { id: "REPORT.CO2.SECTION1.DESC.3" },
//         descData
//       );
//     }
//   } else {
//     let descData = {
//       year1: year3,
//       year3: year1,
//       energyYear1: Number(energyYear3).toFixed(2),
//       energyYear3: Number(energyYear1).toFixed(2)
//     };
//     if (energyYear3 > energyYear1) {
//       pageData.section1.description = intl.formatMessage(
//         { id: "REPORT.CO2.SECTION1.DESC.1" },
//         descData
//       );
//     } else if (energyYear3 < energyYear1) {
//       pageData.section1.description = intl.formatMessage(
//         { id: "REPORT.CO2.SECTION1.DESC.2" },
//         descData
//       );
//     } else {
//       pageData.section1.description = intl.formatMessage(
//         { id: "REPORT.CO2.SECTION1.DESC.3" },
//         descData
//       );
//     }
//   }
//
//   return pageData;
// }

// function prepareGraph1Data() {};

export const co2PdfPageData = (
  page1,
  title,
  desc,
  pageMainTitle,
  pageMainDescription
) => {
  // console.log("htttttttt", pageMainTitle);
  return new Promise((resolve, reject) => {
    getCacheWithoutExpiry(page1).then(chartUri => {
      let finalData = {
        title: pageMainTitle ? pageMainTitle : title,
        description: pageMainDescription ? pageMainDescription : "",
        chart1title: title,
        chart1uri: chartUri,
        count: chartUri === null || title === "" ? 0 : 1
      };
      resolve(finalData);
    });
  });
};

export const co2PdfPagePieData = (
  page1,
  page2,
  title,
  title2,
  desc,
  pageMainTitle,
  pageMainDescription
) => {
  return new Promise((resolve, reject) => {
    getCacheWithoutExpiry(page1).then(chart1Uri => {
      getCacheWithoutExpiry(page2).then(chart2Uri => {
        let finalData = {
          title: pageMainTitle ? pageMainTitle : title,
          description: pageMainDescription ? pageMainDescription : "",
          chart1title: title,
          chart1uri: chart1Uri,
          chart2title: title2,
          chart2uri: chart2Uri,
          count: 2
        };
        resolve(finalData);
      });
    });
  });
};
