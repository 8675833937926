import React, { useState, useContext, useEffect } from "react";
import { useTextdomainContext } from "app/providers";
import { Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import { useDeleteMeter } from "./hooks";

export function DeleteMeter({ show, onHide, editableRow }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { bid, type } = useParams();

  const [showErrorMessage, setshowErrorMessage] = useState(null);

  const { delete: del } = useDeleteMeter(
    payload => {
      if (payload.deleteMeter.success) onHide();
      else {
        setshowErrorMessage(payload.deleteMeter.message);
      }
    },
    payload => {
      setshowErrorMessage(payload.deleteMeter.message);
    }
  );

  const handleDelete = async () => {
    setshowErrorMessage(null);

    del({
      buildingId: bid,
      meterNo: editableRow?.meterNo,
      meterId: editableRow?.meterId,
      type: type === "heating" ? editableRow?.meterType : type
    });
  };

  useEffect(() => {
    setshowErrorMessage(null);
  }, [show]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{gettext("Confirmation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Collapse in={showErrorMessage}>
          {showErrorMessage &&
            showErrorMessage.map(msg => (
              <Alert severity="error">
                <AlertTitle>{gettext("Prohibited")}</AlertTitle>
                {gettext(msg)}
              </Alert>
            ))}
        </Collapse>
        {gettext("Are you sure you want to Delete Meter")}
        {" " + editableRow?.meterNo}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {gettext("Close")}
        </Button>
        <Button
          variant="primary"
          disabled={showErrorMessage}
          onClick={handleDelete}
        >
          {gettext("Delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
