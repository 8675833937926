import { styled, TableCell } from "@mui/material";

export const EpcBodyHeadCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 4,
  paddingBottom: 4,
  fontSize: "14px",
  fontWeight: 500,
  color: "white",
  whiteSpace: "nowrap",
  position: "relative",
  zIndex: 1,
  "&:before": {
    content: '""',
    position: "absolute",
    right: "0%",
    top: "50%",
    width: "100%",
    height: "1px",
    borderTop: "1px dashed",
    borderCollapse: "separate",
    borderSpacing: "25px",
    borderColor: theme.palette.secondary[200],
    zIndex: -1
  }
}));
