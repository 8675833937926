import { useQuery } from "@apollo/client";
import { GET_ACCOUNTS_DATA_LIST } from "./commands";

export function useAccountsDataList() {
  const { data, loading, error, refetch } = useQuery(GET_ACCOUNTS_DATA_LIST);

  return {
    accountsDataList: data?.accountsData,
    loading,
    error,
    refetch
  };
}
