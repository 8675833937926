import { gql } from "@apollo/client";

export const VERIFY_USER_TOKEN = gql`
  mutation comVerifyUserByToken($token: String!) {
    comVerifyUserByToken(token: $token) {
      success
      message
      email
    }
  }
`;
