import { Container, Grid, Stack, Typography } from "@mui/material";
// import { LoginForm } from "app/features";
import { ResetPasswordForm } from "app/features/ResetPasswordForm";
import { useTextdomainContext } from "app/providers";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

export function ResetPassword() {
  const { TextdomainContext } = useTextdomainContext();
  const qParams = useLocation().search;
  const token = new URLSearchParams(qParams).get("token");

  //component state
  const [resetPassword, setResetPassword] = useState(false);

  const { gettext } = useContext(TextdomainContext);

  return (
    <Container maxWidth="xs">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {!resetPassword ? (
            <Typography fontSize={36} fontWeight={700} color="#27272A">
              {gettext("Reset Your Password")}
            </Typography>
          ) : (
            <>
              <Typography fontSize={36} fontWeight={700} color="#015731">
                {gettext("Reset Completed")}
              </Typography>

              <Typography
                fontSize={18}
                fontWeight={500}
                color="#52525B"
                sx={{ pt: 1 }}
              >
                {gettext(
                  "You can now use your new password to sign in to your account."
                )}
              </Typography>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="baseline"
            sx={{ mb: { xs: -0.5, sm: 0.5 } }}
          >
            {/* <Typography fontSize={16} fontWeight={500} color="#3F3F46">
              {gettext("New to fabeke?")}
            </Typography>
            <Typography fontSize={16} fontWeight={500}>
              &nbsp;
            </Typography> */}
            {/* <Typography
              component={Link}
              to="/auth/registration"
              fontSize={16}
              fontWeight={500}
              sx={{ textDecoration: "none" }}
              color="primary"
            >
              {gettext("Sign up for an account.")}
            </Typography> */}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <ResetPasswordForm
            token={token}
            setResetPassword={setResetPassword}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
