import { DateInput } from "app/components";
import { useBenchmarkFilter } from "app/providers";
import React from "react";

export function SelectDate() {
  const {
    selectedDate,
    setSelectedDate,
    resolution,
    setResolution
  } = useBenchmarkFilter();
  return (
    <DateInput
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      resolution={resolution}
      setResolution={setResolution}
    />
  );
}
