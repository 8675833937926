import { gql } from "@apollo/client";

export const INVITE_CUSTOMER_USER = gql`
  mutation inviteCustomerUser(
    $customerId: String!
    $email: String!
    $userRole: String
  ) {
    comInviteUserCustomer(
      customerId: $customerId
      email: $email
      userRole: $userRole
    ) {
      success
      message
      write
      owner
    }
  }
`;
