import { Button, Stack, Typography } from "@mui/material";
import { useBuildingStep } from "app/features/RegistrationFlowAddMeterForm/providers";
import { useTextdomainContext } from "app/providers";
import React from "react";

export function Has({ type }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const question = React.useMemo(() => {
    return {
      electricity: {
        ques: gettext(
          "Do you have electricity on the following building address?"
        )
      },
      heating: {
        ques: gettext("Do you have heating on the following building address?")
      },
      gas: {
        ques: gettext(
          "Do you have natural gas on the following building address?"
        )
      },
      water: {
        ques: gettext("Do you have water on the following building address?")
      }
    };
  }, [gettext]);

  const { setHas } = useBuildingStep();

  return (
    <Stack spacing={2} marginX={10} textAlign="center">
      <Typography fontSize={18} fontWeight={500} color="#3F3F46">
        {question[type].ques}
      </Typography>
      <Stack direction={"row"} spacing={2} justifyContent="center">
        <Button
          disableElevation
          sx={{ py: 1.2, minWidth: "100px" }}
          disabled={false}
          size="large"
          variant="outlined"
          color="secondary"
          onClick={() => setHas(type, false)}
        >
          {gettext("No")}
        </Button>

        <Button
          disableElevation
          sx={{ py: 1.2, minWidth: "100px" }}
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => setHas(type, true)}
        >
          {gettext("Yes")}
        </Button>
      </Stack>
    </Stack>
  );
}
