import { gql } from "@apollo/client";

export const GET_COLOR_SETTINGS = gql`
  fragment ShadeFragment on Shade {
    shade1 {
      background
      text
    }
    shade2 {
      background
      text
    }
    __typename
  }
  query userGraphColor {
    userGraphColor {
      electricity {
        ...ShadeFragment
      }
      heating {
        ...ShadeFragment
      }
      water {
        ...ShadeFragment
      }
      gas {
        ...ShadeFragment
      }
      energy {
        ...ShadeFragment
      }
      compensation {
        ...ShadeFragment
      }
      production {
        ...ShadeFragment
      }
      greenElectricity {
        ...ShadeFragment
      }
      consumption {
        ...ShadeFragment
      }
      co2 {
        ...ShadeFragment
      }
    }
  }
`;
