import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { ADD_METER_DATA } from "./commands";

export function useAddMeterData(onCompleted = () => {}, onError = () => {}) {
  const [loadMeterData, { loading }] = useMutation(ADD_METER_DATA, {
    onCompleted,
    onError
  });

  const addMeterData = useCallback(
    async variables => {
      await loadMeterData({
        variables
      });
    },
    [loadMeterData]
  );

  return {
    addMeterData,
    loading
  };
}
