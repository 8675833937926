import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { IoCloseOutline } from "react-icons/io5";
import {
  useDashboardFilter,
  useTextdomainContext,
  useUser
} from "app/providers";
import { useDebounce } from "hooks";

export function MenuContent() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const [addMode, setAddMode] = React.useState(false);

  const { user } = useUser();

  const { teams } = user || {};

  const [teamsListOptions, setteamsListOptions] = React.useState([]);

  const [searchValue, setSearchValue] = React.useState("");

  const debounceSearchValue = useDebounce(searchValue, 500);

  const {
    selectedCompanies,
    handleAddCompanies,
    handleRemoveCompany
  } = useDashboardFilter();

  React.useEffect(() => {
    setteamsListOptions(
      teams.filter(
        team =>
          team.name.toLowerCase().includes(debounceSearchValue.toLowerCase()) &&
          !selectedCompanies.some(obj => obj.Id === team.Id)
      )
    );
  }, [teams, debounceSearchValue, selectedCompanies]);

  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleItemClick = React.useCallback(team => {
    setSelectedOptions(prevSelectedOption => {
      const index = prevSelectedOption.findIndex(item => item.Id === team.Id);
      if (index === -1) {
        return [...prevSelectedOption, team];
      } else {
        return [
          ...prevSelectedOption.slice(0, index),
          ...prevSelectedOption.slice(index + 1)
        ];
      }
    });
  }, []);

  return (
    <Stack spacing={2}>
      <OutlinedInput
        sx={{ color: "secondary.main" }}
        size="small"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        onFocus={() => setAddMode(true)}
        placeholder={gettext("Search")}
        startAdornment={
          <InputAdornment position={"start"} style={{ color: "secondary.300" }}>
            <SearchIcon />
          </InputAdornment>
        }
      />

      <Box
        sx={{
          height: 170,
          width: 250,
          maxHeight: 180,
          maxWidth: 280,
          overflow: "auto"
        }}
      >
        {addMode ? (
          teamsListOptions.map((team, index) => (
            <ListItem key={team.Id} disablePadding disableGutters>
              <ListItemButton
                role={undefined}
                onClick={() => handleItemClick(team)}
                dense
              >
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Checkbox
                    edge="start"
                    checked={Boolean(
                      selectedOptions.find(item => item.Id === team.Id)
                    )}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": team.Id }}
                  />
                </ListItemIcon>
                <ListItemText id={team.Id} primary={team.name} />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <Stack spacing={1}>
            <Typography fontSize={13} fontWeight={400} color="secondary.600">
              {gettext("Selected")}
            </Typography>

            <Box sx={{ mt: 1, display: "flex", flexWrap: "wrap", gap: 1 }}>
              {selectedCompanies.map((company, index) => (
                <Chip
                  key={company.Id}
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #B3CDC1",
                    backgroundColor: "primary.50",
                    color: "primary.main"
                  }}
                  size="small"
                  label={
                    <Typography fontWeight={500}>{company.name}</Typography>
                  }
                  onDelete={() => handleRemoveCompany(company)}
                  deleteIcon={<IoCloseOutline color="inherit" />}
                />
              ))}
            </Box>
          </Stack>
        )}
      </Box>

      {addMode ? (
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={() => {
              setAddMode(false);
              setSelectedOptions([]);
            }}
          >
            {gettext("Cancel")}
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => {
              handleAddCompanies(selectedOptions);
              setAddMode(false);
              setSelectedOptions([]);
            }}
          >
            {gettext("Add")}
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          {/* <Button size='small' color='secondary' variant='outlined'>
                            Cancel
                        </Button>
                        <Button size='small' color='secondary' variant='contained'>
                            Set
                        </Button> */}
        </Box>
      )}
    </Stack>
  );
}
