import { gql } from "@apollo/client";
// $Type:String
// ,Type:$Type
export const GET_DROPDOWN_OPTIONS = gql`
  query dropdowns(
    $collection: String!
    $level1Key: String!
    $Type: String
    $level2Key: String
    $level3Key: String
  ) {
    dropdowns(
      collection: $collection
      level1Key: $level1Key
      Type: $Type
      level2Key: $level2Key
      level3Key: $level3Key
    ) {
      name
      key
    }
  }
`;
