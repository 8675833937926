import {
  Box,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { EPCTableValues } from "app/common/Constants";
import { useTextdomainContext } from "app/providers";
import React from "react";
import { useEpcData } from "../../hooks";
import {
  EpcBodyCell,
  EpcBodyHeadCell,
  EpcHeadCell,
  EpcHeadRow
} from "./components";

export function EpcTable() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const { catagories, values } = useEpcData();

  const getValueLevel = React.useCallback((value, epc) => {
    if (value >= epc.min && value <= epc.max)
      return (
        <Box
          sx={{
            display: "inline-block",
            width: "80%",
            // transform: "translateX(20%)",
            maxWidth: "150px",
            margin: "auto",
            padding: 1,
            color: "white",
            backgroundColor: epc.color,
            borderRadius: "0 4px 4px 0",
            position: "relative",
            "&:after": {
              content: '""',
              position: "absolute",
              width: "24%",
              height: "100%",
              left: "0%",
              top: "0%",
              transform: "translate(-105%, 0%)",
              clipPath: "polygon(100% 100%, 100% 0, 0 50%)",
              backgroundColor: epc.color
            }
          }}
        >
          <Typography>{value}</Typography>
        </Box>
      );
    else return null;
  }, []);

  return (
    <Box
      sx={{
        p: 3,
        border: "1px solid",
        borderColor: "secondary.200",
        borderRadius: "8px"
      }}
    >
      <Stack spacing={1.3}>
        <Typography fontSize={20} fontWeight={700} color={"secondary"}>
          {gettext("Energy ratings")}
        </Typography>

        <Table>
          <TableHead>
            <EpcHeadRow>
              <EpcHeadCell size="small"></EpcHeadCell>
              {catagories.map(catagory => (
                <EpcHeadCell align="center" size="small" key={catagory}>
                  {catagory}
                </EpcHeadCell>
              ))}
            </EpcHeadRow>
          </TableHead>
          <Box sx={{ height: "8px" }} />
          <TableBody>
            {EPCTableValues.map(epc => (
              <TableRow>
                <EpcBodyHeadCell component="th" scope="row" align="left">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      maxWidth: "150px",
                      padding: 1,
                      color: "white",
                      backgroundColor: epc.color,
                      borderRadius: "4px"
                    }}
                  >
                    <Typography>{epc.name}</Typography>
                    <Typography>
                      {epc.min}&nbsp;-&nbsp;{epc.max}
                    </Typography>
                  </Box>
                </EpcBodyHeadCell>

                <EpcBodyCell align="center">
                  {getValueLevel(values[0], epc)}
                </EpcBodyCell>
                <EpcBodyCell align="center">
                  {getValueLevel(values[1], epc)}
                </EpcBodyCell>
                <EpcBodyCell align="center">
                  {getValueLevel(values[2], epc)}
                </EpcBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
    </Box>
  );
}
