import { FilterSelectButton } from "app/components";
import { useBenchmarkFilter, useTextdomainContext } from "app/providers";
import React from "react";

export function SelectEnergySource() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const {
    allEnergySources,
    selectedEnergySource,
    setSelectedEnergySource
  } = useBenchmarkFilter();

  return (
    <FilterSelectButton
      searchable={false}
      label={gettext("Energy Source")}
      options={allEnergySources}
      getOptionLabel={option => option.label}
      value={selectedEnergySource}
      onChange={newValue => setSelectedEnergySource(newValue)}
    />
  );
}
