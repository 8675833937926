import { useState } from "react";
import { Button, Menu, Chip, Stack, MenuItem } from "@mui/material";
import { IoChevronDown, IoCheckmark } from "react-icons/io5";
import useStyles from "../styles";

const currencies = ["DKK", "EUR", "NOK", "SEK"];

const CurrencySelection = props => {
  const { selectedCurrency, selectCurrency, gettext } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<IoChevronDown size={12} />}
        color="secondary"
        onClick={handleClick}
        className={classes.currencyDropdown}
      >
        {gettext("By Currency")}
        <Chip
          sx={{ ml: 0.5, backgroundColor: "primary.50", color: "primary.main" }}
          size="small"
          label={gettext(selectedCurrency)}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: { p: 0 }
        }}
        PaperProps={{
          sx: {
            p: 1,
            borderRadius: "8px",
            marginTop: "8px",
            overflow: "hidden"
          }
        }}
      >
        <Stack
          spacing={2}
          sx={{
            "&:focus": {
              outline: "none"
            },
            minWidth: "165px"
          }}
        >
          {currencies.map(currency => (
            <MenuItem onClick={() => selectCurrency(currency)}>
              {gettext(currency)}
              {selectedCurrency === currency ? (
                <IoCheckmark style={{ marginLeft: "auto" }} color="#015731" />
              ) : null}
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </>
  );
};

export default CurrencySelection;
