import { gql } from "@apollo/client";

export const GET_BUILDING_COORDINATES = gql`
  query comGetBuildingDetails($customerName: String) {
    comGetBuildingDetails(customerName: $customerName) {
      building
      coOrdinatesEpc {
        lat
        long
        value
        name
        color
      }
    }
  }
`;
