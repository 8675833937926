import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function PageIndicator(props) {
  const { view, setView, gettext, crumbs } = props;

  function handleClick(value) {
    if (value !== 0 && setView) {
      setView(value);
    }
  }

  const getCrumbs = () => {
    const index = crumbs.findIndex(crumb => crumb.value === view);
    const filteredCrumbs = index >= 0 ? crumbs.slice(0, index + 1) : crumbs;
    return filteredCrumbs.map((crumb, i) => (
      <React.Fragment key={crumb.label}>
        <Link
          underline="none"
          color={view === crumb.value ? "#27272A" : "inherit"}
          onClick={() => handleClick(crumb.value)}
        >
          {gettext(`${crumb.label}`)}
        </Link>
      </React.Fragment>
    ));
  };

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      fontSize="14px"
      pb={1}
    >
      {getCrumbs()}
    </Breadcrumbs>
  );
}
