import React, { Component } from "react";
import "../../../../index.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class EmissionDatePicker extends Component {
  customInputStyle = {
    height: 40,
    borderRadius: 4,
    width: 90,
    margin: 0,
    borderColor: "rgb(204,204,204)",
    borderWidth: 1.8,
    paddingLeft: 10,
    outlineColor: "rgb(46, 135,252)"
  };
  appGreenColor = "rgb(67, 105, 50)";

  constructor(props) {
    super(props);
    const { filterType, selectedSegment } = this.props;
    const localFilters = JSON.parse(localStorage.getItem(filterType));
    const value =
      localFilters && localFilters.year
        ? new Date(localFilters.year)
        : new Date();
    this.state = {
      selectedDate: value,
      selectedSegment: selectedSegment
    };
  }

  customInput = () => {
    return <input style={this.customInputStyle} />;
  };

  //top section with dropdown(s)
  renderTopSection = () => {
    const { selectedDate, selectedSegment } = this.state;
    const { dateFormat, getSelectedDate } = this.props;

    return (
      <div className="top-section-datepicke">
        <div className="date-picker m-0">
          <DatePicker
            customInput={this.customInput()}
            selected={selectedDate}
            onChange={date => {
              this.setState({ selectedDate: date });
              getSelectedDate(date, selectedSegment);
            }}
            dateFormat={
              selectedSegment === 2
                ? "yyy"
                : selectedSegment === 1
                ? "MMM YYY"
                : dateFormat
                ? dateFormat
                : "dd/MM/yyy"
            }
            showMonthYearPicker={selectedSegment === 1}
            showYearPicker={selectedSegment === 2}
          />
        </div>
      </div>
    );
  };

  render() {
    return <div className="text-right">{this.renderTopSection()}</div>;
  }
}

export default EmissionDatePicker;
