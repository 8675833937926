import { useQuery } from "@apollo/client";
import { GET_USER_ROLES } from "../commands";

export function useUserRoles(userId) {
  const { data, loading } = useQuery(GET_USER_ROLES, {
    variables: {
      userId
    }
  });

  return {
    userRoles: data?.comUserRoles || [],
    loading
  };
}
