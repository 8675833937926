// import { Breadcrumbs, Chip, IconButton } from "@mui/material";
import React, { useCallback, useState } from "react";
// import { IoLink } from "react-icons/io5";
import { LinkModal } from "./components/LinkModal";
import { useSave } from "../../hooks";
import Select from "react-select";
import { Grid } from "@mui/material";

const chosenPlanStyle = {
  control: (base, state) => ({
    ...base,
    background: "rgb(236,246,239)",
    borderColor: "lightgray",
    borderWidth: 1,
    width: "100%",
    minHeight: "40px",

    color: "white",
    boxShadow: null,
    "&:hover": {
      borderColor: "lightgray"
    }
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#66BDA9",
      borderRadius: 3
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    backgroundColor: "#66BDA9",
    color: "white"
  }),
  multiValueRemove: styles => ({ ...styles, display: "none" })
};

export function LinkComponent({ row, fileId, gettext }) {
  const [showLinkModal, setShowLinkModal] = useState(false);

  const [editableRow, setEditableRow] = useState(undefined);

  const handleLinkModalShow = useCallback(() => {
    setShowLinkModal(true);
    setEditableRow(row);
  }, [row]);

  const handleLinkModalHide = useCallback(() => {
    setShowLinkModal(false);
  }, []);

  const { save } = useSave(handleLinkModalHide);

  const getSelectedAccountsValues = row => {
    let selectedOption = [];
    if (row?.level1) {
      selectedOption.push({ key: row?.level1, label: row?.level1 });
    }

    if (row?.level2) {
      selectedOption.push({ key: row?.level2, label: row?.level2 });
    }

    if (row?.level3) {
      selectedOption.push({ key: row?.level3, label: row?.level3 });
    }

    if (row?.level4) {
      selectedOption.push({ key: row?.level4, label: row?.level4 });
    }
    return selectedOption;
  };

  return (
    <>
      {/* {row?.level1 || row?.level2 || row?.level3 || row?.level4 ? ( */}
      {/* // <Breadcrumbs separator={"›"} maxItems={2} aria-label="breadcrumb">
                    //   {row?.level1 && (
                    //     <Chip
                    //       sx={{
                    //         background: "rgb(236,246,239)",
                    //         borderColor: "lightgray",
                    //         fontSize: "12px"
                    //       }}
                    //       label={row?.level1}
                    //       size="small"
                    //     />
                    //   )}
                    //   {row?.level2 && (
                    //     <Chip
                    //       sx={{
                    //         background: "rgb(236,246,239)",
                    //         borderColor: "lightgray",
                    //         fontSize: "12px"
                    //       }}
                    //       label={row?.level2}
                    //       size="small"
                    //     />
                    //   )}
                    //   {row?.level3 && (
                    //     <Chip
                    //       sx={{
                    //         background: "rgb(236,246,239)",
                    //         borderColor: "lightgray",
                    //         fontSize: "12px"
                    //       }}
                    //       label={row?.level3}
                    //       size="small"
                    //     />
                    //   )}
                    //   {row?.level4 && (
                    //     <Chip
                    //       sx={{
                    //         background: "rgb(236,246,239)",
                    //         borderColor: "lightgray",
                    //         fontSize: "12px"
                    //       }}
                    //       label={row?.level4}
                    //       size="small"
                    //     />
                    //   )}
                    // </Breadcrumbs> */}
      <Grid
        container
        display="flex"
        flexDirection="row"
        alignItems="center"
        role="presentation"
        onClick={handleLinkModalShow}
        xs={12}
        py={1}
        rowGap={1}
      >
        <Grid item sm={2} md={1}>
          <img
            className=" ml20"
            src={"/media/svg/icons/Navigation/right-arrow.svg"}
            alt={"..."}
          ></img>
        </Grid>
        <Grid item sm={10} md={5}>
          <Select
            // className="account-plan-dropdown mr20 "
            placeholder={gettext("Link Data")}
            isMulti
            // value={getSelectedAccountsValues(row)}
            // getOptionLabel={(option) => option.label}
            // getOptionValue={(option) => option.label}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
            isSearchable={false}
            isClearable={false}
            noOptionsMessage={() => null}
            // onChange={(selected, action) =>
            //   handleRemoval(selected, action.removedValue)
            // }
            styles={chosenPlanStyle}
          />
        </Grid>
        <Grid item sm={2} md={1}>
          <img
            className=" ml20"
            src={"/media/svg/icons/Navigation/right-arrow.svg"}
            alt={"..."}
          ></img>
        </Grid>
        <Grid item sm={10} md={5}>
          <Select
            // className="account-plan-dropdown mr20"
            placeholder={gettext("No chosen account")}
            isMulti
            fullwidth
            value={getSelectedAccountsValues(row)}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.label}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
            isSearchable={false}
            isClearable={false}
            noOptionsMessage={() => null}
            // onChange={(selected, action) =>
            //   handleRemoval(selected, action.removedValue)
            // }
            styles={chosenPlanStyle}
          />
        </Grid>
        {/* // ) : ( //{" "}
                <IconButton onClick={handleLinkModalShow}>
                    // <IoLink size={18} />
                    //{" "}
                </IconButton>
                // )} */}
      </Grid>

      <LinkModal
        show={showLinkModal}
        onHide={handleLinkModalHide}
        editableRow={editableRow}
        fileId={fileId}
        onSave={save}
      />
    </>
  );
}
