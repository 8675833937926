import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { getOr } from "lodash/fp";
import { GET_WATER_QUERY, GET_METER_BROADCAST_DATA } from "GraphQL/Queries";

export const useDashboard = props => {
  const {
    customers,
    addresses,
    fromDate,
    toDate,
    resolution,
    sourceType,
    dateFrom,
    dateTo
  } = props;

  const [data, setData] = useState(null);

  const [
    fetchDashboardData,
    { data: dashboardData, loading, refetch: reFetchDashboardData }
  ] = useLazyQuery(GET_WATER_QUERY, {
    variables: {
      meterType: sourceType,
      meteringPointId: "all",
      address: addresses,
      customer: customers,
      fromDate: fromDate,
      toDate: toDate,
      resolution: resolution,
      fileType: "excel"
    }
  });

  useEffect(() => {
    if (customers && addresses && fromDate && toDate) {
      fetchDashboardData();
      reFetchDashboardData();
    }
  }, [
    fetchDashboardData,
    reFetchDashboardData,
    customers,
    addresses,
    dateFrom,
    dateTo,
    sourceType,
    fromDate,
    toDate
  ]);
  useEffect(() => {
    if (!loading) {
      const metersData = getOr(null, "metersData", dashboardData);
      setData(metersData ? metersData : "");
    }
  }, [dashboardData, loading]);

  return { data: data, loading };
};

export const useBroadCastDashboard = props => {
  const {
    customers,
    addresses,
    fromDate,
    toDate,
    resolution,
    sourceType,
    dateFrom,
    dateTo
  } = props;

  const [data, setData] = useState([]);

  const [
    fetchDashboardData,
    { data: dashboardData, loading, refetch: reFetchDashboardData }
  ] = useLazyQuery(GET_METER_BROADCAST_DATA, {
    variables: {
      meterType: sourceType,
      meteringPointId: "all",
      address: addresses,
      customer: customers,
      fromDate: fromDate,
      toDate: toDate,
      resolution: resolution,
      fileType: "excel"
    }
  });

  useEffect(() => {
    if (customers && addresses && fromDate && toDate) {
      fetchDashboardData();
      reFetchDashboardData();
    }
  }, [
    fetchDashboardData,
    reFetchDashboardData,
    customers,
    addresses,
    dateFrom,
    dateTo,
    sourceType,
    fromDate,
    toDate
  ]);
  useEffect(() => {
    if (!loading) {
      const metersData = getOr([], "metersBroadcastData", dashboardData);
      setData(metersData ? metersData : []);
    }
  }, [dashboardData, loading]);

  return { data: data, loading };
};
