import { useQuery } from "@apollo/client";
import { GET_TIMEZONES } from "./commands";

export function useTimezones() {
  const { data, loading } = useQuery(GET_TIMEZONES);

  return {
    timezones: data?.mongoTimezones || [],
    loading
  };
}
