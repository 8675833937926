import { gql } from "@apollo/client";

export const GET_COUNTRY_ADDRESSES = gql`
  query getCountryAddresses($customerName: String!, $country: String!) {
    getCountryAddresses(customerName: $customerName, country: $country) {
      name
      isPublic
    }
  }
`;
