import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { useMutation, useQuery } from "@apollo/client";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";
import { GET_ACCOUNT_GROUPS } from "../../../../GraphQL/Queries";
import { DELETE_ACCOUNT_GROUP } from "../../../../GraphQL/Mutations";
import { ChartList, TableList } from "./groupStyle";
import { IconButton, Tooltip } from "@mui/material";
import { MdModeEdit, MdDelete } from "react-icons/md";

const AccountGroupList = () => {
  const navigate = useNavigate();
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [corporateData, setCorporateData] = useState([]);
  const { data, loading } = useQuery(GET_ACCOUNT_GROUPS, {
    variables: {}
  });

  useEffect(() => {
    if (data) {
      const { accountGroups } = data;
      setCorporateData(accountGroups);
    }
  }, [data]);

  const handleNewGroup = () => {
    navigate("/admin/account-groups/new");
  };

  const handleEditGroup = item => {
    navigate(`/admin/account-groups/${item.Id}/edit`);
  };

  const [deleteAccountGroup] = useMutation(DELETE_ACCOUNT_GROUP, {
    // onCompleted(data) {
    //   const {
    //     insertAccountGroup: { success }
    //   } = data;
    // },
    // onError(error) {
    //   error.graphQLErrors.map(({ message }) => {
    //     // console.log("Error =>", error);
    //   });
    // }
  });

  const handleDeleteGroupMutation = item => {
    deleteAccountGroup({
      variables: {
        id: item.Id
      },
      refetchQueries: [{ query: GET_ACCOUNT_GROUPS }]
    });
  };

  const handleDeleteGroup = item => {
    confirmAlert({
      title: gettext("Confirmation"),
      message: `${gettext("Are you sure you want to Delete")}${" " +
        item.nameDa}`,
      buttons: [
        {
          label: gettext("Yes"),
          onClick: () => {
            handleDeleteGroupMutation(item);
          }
        },
        {
          label: gettext("No"),
          onClick: () => {
            // setDeletedId('');
          }
        }
      ]
    });
  };

  return (
    <ChartList className="card card-custom gutter-b example example-compact">
      <div className="row m-0">
        <div className="col-sm-10 pt20 pb8 title">
          {gettext("Account Group List")}
        </div>
        <div className="col-sm-2 pt20 pb8 new-account-chart">
          <button type="button" onClick={handleNewGroup}>
            {gettext("Create Account Group")}
          </button>
        </div>
      </div>
      {loading ? (
        <>
          <p>Loading</p>
        </>
      ) : (
        <TableList>
          <div className="row m-0 table-heading">
            <div className="col-sm-1">{gettext("Id")}</div>
            <div className="col-sm-4">{gettext("Name")}</div>
            <div className="col-sm-4">{gettext("Type")}</div>
            <div className="col-sm-1"></div>
            <div className="col-sm-2 action-button">{gettext("Action")}</div>
          </div>
          {corporateData.map((item, index) => (
            <div className="row m-0 top-border" key={`corporate_${index}`}>
              <div className="col-sm-1">{index + 1}</div>
              <div className="col-sm-4">{item.name}</div>
              <div className="col-sm-4">{item.type}</div>
              <div className="col-sm-1"></div>
              <div className="col-sm-1 action-button">
                <Tooltip title={gettext("Edit")} arrow>
                  <IconButton
                    className="edit"
                    onClick={() => handleEditGroup(item)}
                  >
                    <MdModeEdit size={18} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="col-sm-1 action-button">
                <Tooltip title={gettext("Delete")} arrow>
                  <IconButton
                    className="delete"
                    onClick={() => handleDeleteGroup(item)}
                  >
                    <MdDelete size={18} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ))}
        </TableList>
      )}
    </ChartList>
  );
};

export default AccountGroupList;
