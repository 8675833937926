import { Fab, Grid } from "@mui/material";
import { DashboardFilterProvider } from "app/providers";
import React from "react";
import { Filter, SelectedDashboard, ShareModal } from "./components";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

function Dashboard() {
  const [openShare, setOpenShare] = React.useState(false);
  const handleShareOpen = () => setOpenShare(true);
  const handleShareClose = () => setOpenShare(false);

  const handle = useFullScreenHandle();

  return (
    <DashboardFilterProvider>
      <Fab
        onClick={handle.enter}
        variant="circular"
        style={{
          background: "#10613e",
          color: "#fff",
          position: "fixed",
          bottom: 40,
          right: 40
        }}
      >
        <FullscreenIcon fontSize="large" />
      </Fab>

      <FullScreen handle={handle}>
        <div
          style={{
            background: "#fff",
            overflowY: "auto",
            overflowX: "hidden",
            height: "100%",
            padding: "20px"
          }}
        >
          {handle.active && (
            <>
              <Fab
                onClick={handle.exit}
                variant="circular"
                style={{
                  background: "#10613e",
                  color: "#fff",
                  position: "fixed",
                  bottom: 40,
                  right: 40
                }}
              >
                <FullscreenExitIcon fontSize="large" />
              </Fab>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Filter handleShareOpen={handleShareOpen} />
                </Grid>
                <Grid item xs={12}>
                  <SelectedDashboard />
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </FullScreen>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Filter handleShareOpen={handleShareOpen} />
        </Grid>
        <Grid item xs={12}>
          <SelectedDashboard />
        </Grid>
      </Grid>

      <ShareModal open={openShare} onClose={handleShareClose} />
    </DashboardFilterProvider>
  );
}

export default React.memo(Dashboard);
