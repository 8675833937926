import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
import CardHeader from "./CardHeader";
// import Co2SectionCol1Row1 from "./Co2SectionCol1Row1";
import { localeNumberFormat } from "../../../common/Functions";
import { DECIMAL } from "../../../common/Constants";
import {
  totalEnergyCo2,
  dataEnergyGraph2,
  dataEnergyGraph3,
  totalYearCo2
} from "../partials/Functions";
import Co2SectionCol2Row1 from "./Co2SectionCol2Row1";

function Co2Section3(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const selectedYear = props.year ? props.year : "";
  const [data1, changeData1] = useState(null);
  const [data2, changeData2] = useState(null);
  const [totalEnergyCo2Year, changeTotalEnergyCo2Year] = useState(0);
  const [totalElectricityCo2Year, changeTotalElectricityCo2Year] = useState(0);
  const [totalHeatingCo2Year, changeTotalHeatingCo2Year] = useState(0);
  const [totalWaterCo2Year, changeTotalWaterCo2Year] = useState(0);
  const [perElectricityCo2Year, changePerElectricityCo2Year] = useState(0);
  const [perHeatingCo2Year, changePerHeatingCo2Year] = useState(0);
  const [perWaterCo2Year, changePerWaterCo2Year] = useState(0);

  // const [totalEnergyCo2Year2, changeTotalEnergyCo2Year2] = useState(0);
  // const [totalEnergyCo2Year3, changeTotalEnergyCo2Year3] = useState(0);
  // const year1 = props.data.to_year?props.data.to_year:'';
  // const year2 = props.data.second_year?props.data.second_year:'';
  // const year3 = props.data.from_year?props.data.from_year:'';
  const chartId1 = "co2-chart-2-" + selectedYear;
  const chartId2 = "co2-chart-3-" + selectedYear;

  useEffect(() => {
    if (props.data.electricity || props.data.heating || props.data.water) {
      let valueEnergy = totalEnergyCo2(props.data, selectedYear);
      let valueElectricity = totalYearCo2(
        props.data,
        "electricity",
        selectedYear,
        "cons"
      );
      let valueHeating = totalYearCo2(
        props.data,
        "heating",
        selectedYear,
        "cons"
      );
      let valueWater = totalYearCo2(props.data, "water", selectedYear, "cons");
      changeTotalEnergyCo2Year(valueEnergy);
      changeTotalElectricityCo2Year(valueElectricity);
      changeTotalHeatingCo2Year(valueHeating);
      changeTotalWaterCo2Year(valueWater);
      changeData1(
        dataEnergyGraph2(chartId1, selectedYear, props.data, gettext)
      );
      changeData2(
        dataEnergyGraph3(chartId2, selectedYear, props.data, gettext)
      );
      changePerElectricityCo2Year(
        Number((valueElectricity * 100) / valueEnergy)
      );
      changePerHeatingCo2Year(Number((valueHeating * 100) / valueEnergy));
      changePerWaterCo2Year(Number((valueWater * 100) / valueEnergy));
    }
  }, [props, selectedYear, chartId1, chartId2, gettext]);

  let totalEnergyCo2YearFormatted = localeNumberFormat(
    totalEnergyCo2Year,
    DECIMAL
  );
  let totalElectricityCo2YearFormatted = localeNumberFormat(
    totalElectricityCo2Year,
    DECIMAL
  );
  let totalHeatingCo2YearFormatted = localeNumberFormat(
    totalHeatingCo2Year,
    DECIMAL
  );
  let totalWaterCo2YearFormatted = localeNumberFormat(
    totalWaterCo2Year,
    DECIMAL
  );
  let perElectricityCo2YearFormatted = localeNumberFormat(
    perElectricityCo2Year,
    DECIMAL
  );
  let perHeatingCo2YearFormatted = localeNumberFormat(
    perHeatingCo2Year,
    DECIMAL
  );
  let perWaterCo2YearFormatted = localeNumberFormat(perWaterCo2Year, DECIMAL);

  return (
    <>
      <div className={"card card-custom gutter-b"}>
        <CardHeader title={`${gettext("CO₂ accounts for")} ${selectedYear}`} />
        <div className={"card-body"}>
          <div className={"row"}>
            {`In ${selectedYear}, the total CO₂ emissions were ${totalEnergyCo2YearFormatted} tonnes of CO₂.`}
            <br />
            <br />
            {`${gettext(
              "Electricity accounted for a CO₂ emission of"
            )} ${totalElectricityCo2YearFormatted} ${gettext(
              "tonnes of CO₂ or"
            )} ${perElectricityCo2YearFormatted}% ${gettext(
              "of the total CO₂ emission."
            )}`}
            <br />
            <br />
            {`${gettext(
              "The heat consumption accounted for a CO₂ emission of"
            )} ${totalHeatingCo2YearFormatted} ${gettext(
              "tonnes of CO₂ or"
            )} ${perHeatingCo2YearFormatted}% ${gettext(
              "of the total CO₂ emission."
            )}`}
            <br />
            <br />
            {`${gettext(
              "Water and wastewater accounted for a CO₂ emission of"
            )} ${totalWaterCo2YearFormatted} ${gettext(
              "tonnes of CO₂ or"
            )} ${perWaterCo2YearFormatted}% ${gettext(
              "of the total CO₂ emission."
            )}`}
            <br />
          </div>
          <div className={"separator separator-dashed my-7"}></div>
          {data1 && data2 && (
            <Co2SectionCol2Row1
              data1={data1}
              data2={data2}
              imageUrl1={uri => {
                if (typeof props.graph1imageUrl === "function") {
                  props.graph1imageUrl(uri);
                }
              }}
              imageUrl2={uri => {
                if (typeof props.graph2imageUrl === "function") {
                  props.graph2imageUrl(uri);
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default injectIntl(Co2Section3);
