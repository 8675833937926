import { gql } from "@apollo/client";

export const REDIRECT_STATS = gql`
  mutation comRedirectStats(
    $customerKey: String
    $email: String
    $userType: String
  ) {
    comRedirectStats(
      customerKey: $customerKey
      email: $email
      userType: $userType
    ) {
      success
      message
      result {
        email
        click
        ip
        customerKey
        fromDate
        toDate
      }
    }
  }
`;
