import { gql } from "@apollo/client";

export const GET_DATAHUB_CUSTOMER_BUILDINGS = gql`
  query dataHubCustomerBuildings($id: String!) {
    dataHubCustomerBuildings(id: $id) {
      address
      country
      meterIdList
      meterCount
    }
  }
`;
