import React from "react";
import {
  Button,
  Menu,
  MenuItem,
  Grid,
  Typography,
  styled
} from "@mui/material";
import { useTextdomainContext } from "app/providers";
import { Calendar } from "comundo-icons";
import DatePicker from "react-datepicker";
import "./datepickerStyles.css";
import moment from "moment";

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .react-datepicker": {
    width: "100%"
  }
}));

export function DateInput(props) {
  const { selectedDate, setSelectedDate, resolution, setResolution } = props;

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSegmentClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleSegmentClose = () => {
    setAnchorEl(null);
  };

  const onDateChange = date => {
    setSelectedDate(date);

    handleSegmentClose();
  };

  const handleResolutionChange = resolution => {
    setResolution(resolution);
  };

  const formattedDate = () => {
    let format;

    if (resolution === "m") {
      format = "YYYY";
    } else if (resolution === "d") {
      format = "MMM YY";
    } else {
      format = "DD MMM YYYY";
    }

    return moment(selectedDate).format(format);
  };

  // const CustomHeader = (props) => {
  //   console.log(props)

  //   const { date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled } = props;

  //   return (
  //     <Stack spacing={2} justifyContent={"space-between"} alignItems={"center"} direction={"row"}>

  //       <IconButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
  //         <ArrowBack />
  //       </IconButton>

  //       <IconButton onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
  //         <ArrowForward />
  //       </IconButton>

  //     </Stack>
  //   );
  // }

  return (
    <>
      <Button
        variant="contained"
        size="small"
        color="secondary"
        endIcon={<Calendar color="inherit" size={18} />}
        onClick={handleSegmentClick}
        sx={{ px: 2 }}
      >
        <Typography fontSize="14px" fontWeight="fontWeightMedium">
          {formattedDate()}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSegmentClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{
          ".MuiMenu-list": {
            padding: 0,
            height: "100%"
          },
          "& .MuiMenu-paper": {
            width: "412px",
            height: "360px",
            borderRadius: "8px",
            boxShadow:
              "0px 10px 15px -3px #0000001A ,0px 4px 6px -2px #0000000F"
          },
          "& .MuiMenuItem-root": {
            fontWeight: 400,
            fontSize: "14px"
          },
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: "#015731",
            fontWeight: 600,
            fontSize: "14px",
            color: "white"
          }
        }}
      >
        <Grid container columnSpacing={1} xs={12} height={"100%"}>
          <Grid item xs={3.24} sx={{ py: 2, borderRight: "1px solid #E4E4E7" }}>
            <MenuItem
              selected={resolution === "h"}
              onClick={() => handleResolutionChange("h")}
            >
              {gettext("Daily")}
            </MenuItem>
            <MenuItem
              selected={resolution === "d"}
              onClick={() => handleResolutionChange("d")}
            >
              {gettext("Monthly")}
            </MenuItem>
            <MenuItem
              selected={resolution === "m"}
              onClick={() => handleResolutionChange("m")}
            >
              {gettext("Yearly")}
            </MenuItem>
          </Grid>

          <Grid
            item
            xs={8.76}
            sx={{ py: 2 }}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <StyledDatePicker
              className="datePicker-container"
              selected={selectedDate}
              onChange={onDateChange}
              // dateFormat="MMMM yyyy"
              showMonthYearPicker={resolution === "d"}
              showFourColumnMonthYearPicker
              showYearPicker={resolution === "m"}
              yearItemNumber={16}
              inline
              // renderCustomHeader={CustomHeader}
              maxDate={new Date()}
            />
          </Grid>
        </Grid>
      </Menu>
    </>
  );
}
