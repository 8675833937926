import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { UPDATE_ROLE } from "../commands";

export function useUpdateRole(onCompleted = () => null, onError = () => null) {
  const [comUpdateRole, { loading }] = useMutation(UPDATE_ROLE, {
    onCompleted: () => {
      onCompleted();
    },
    onError: () => {
      onError();
    },
    refetchQueries: ["comRoles"]
  });

  const updateRole = useCallback(
    async variables => {
      comUpdateRole({
        variables
      });
    },
    [comUpdateRole]
  );

  return {
    updateRole,
    updating: loading
  };
}
