import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "_metronic/_partials/controls";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { convertErrorObject } from "../Auth/_redux/authCrud";
import SVG from "react-inlinesvg";
import Select from "react-select";
import { useUser, useTextdomainContext } from "app/providers";
import { useUpdateProfile } from "hooks";

function SwitchTeam(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  // Fields

  const [isError, setisError] = useState(false);

  const [isSuccess, setisSuccess] = useState(false);

  const [teamName, setTeamName] = useState("");

  const { user } = useUser();

  const { teams, currentTeam, currentTeamId, demo } = user || {};

  const onSwitchTeamSuccess = data => {
    let key = "selectedCustomerDropdown";
    localStorage.setItem(
      key,
      JSON.stringify([{ value: teamName, label: teamName }])
    );

    localStorage.setItem(
      "/dashboardselectedCustomerDropdown",
      JSON.stringify([{ value: teamName, label: teamName }])
    );

    localStorage.removeItem("/dashboardselectedBuildingDropdown");

    // /dashboardselectedBuildingDropdown
    localStorage.removeItem("energyFilters");
    setisError(false);
    setisSuccess(true);
    formik.setSubmitting(false);
    formik.setStatus(data.message[0]);
    // setloading(false);
  };

  const onSwitchTeamError = err => {
    setisError(true);
    setisSuccess(false);
    formik.setSubmitting(false);
    formik.setStatus(convertErrorObject(err));
    // setloading(false);
  };

  const { updateProfile, loading } = useUpdateProfile(
    onSwitchTeamSuccess,
    onSwitchTeamError
  );

  const initialValues = {
    team: currentTeam,
    teamId: currentTeamId
  };

  const Schema = Yup.object().shape({
    team: Yup.string().required("Team is required")
  });

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      updateProfile({
        currentTeam: values.team,
        currentTeamId: values.teamId
      });
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    }
  });

  const teamChange = field => {
    setTeamName(field.name);
    formik.setFieldValue("team", field.name);
    formik.setFieldValue("teamId", field.Id);
  };

  // useEffect(() => {

  // if (user) {
  //   client.get(GET_CUSTOMERS_URL + "/" + user.id).then(
  //     response => {
  //       setTeams(team => team.concat(response.data.teams));
  //     },
  //     () => {}
  //   );

  //   user?.roles?.find(role => role.name === "Partner") &&
  //     client
  //       .get(apiRoutes.getMembers.path + `?customer_key=${user?.customerKey}`)
  //       .then(
  //         response => {
  //           setTeams(team => team.concat(response?.data?.members));
  //         },
  //         () => {}
  //       );
  // }

  //   formik.initialValues.team = currentTeam;

  // }, [currentTeam, formik.initialValues]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card card-custom switch-customer gutter-b example example-compact">
        <div className="table-div">
          <div className="table-title">
            <div className="col-7 p-0">
              <h3>{gettext("Switch Company")}</h3>
              <span className="font-size-sm w-100">
                {gettext(
                  "You can change companies if you select another company and Save changes."
                )}
              </span>
            </div>
            {/* <div className="col-5 p-0">
              <div className="action-alignment">
                <button
                  type="submit"
                  className="btn btn-success mr-2"
                  disabled={
                    formik.isSubmitting || (formik.touched && !formik.isValid)
                  }
                >
                  {gettext("Save Changes")}
                  {formik.isSubmitting}
                </button>
                <Link
                  to="/user-profile/personal-information"
                  className="btn btn-secondary"
                >
                  {gettext("Cancel")}
                </Link>
              </div>
            </div> */}
          </div>
          {/* end::Header */}
          {/* begin::Form */}
          <div className="form">
            {/* begin::Body */}
            <div className="card-body">
              {/* begin::Alert */}
              {isError && (
                <div
                  className="alert alert-custom alert-light-danger fade show mb-10"
                  role="alert"
                >
                  <div className="alert-icon">
                    <span className="svg-icon svg-icon-3x svg-icon-danger">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Info-circle.svg"
                        )}
                      ></SVG>{" "}
                    </span>
                  </div>
                  <div className="alert-text font-weight-bold">
                    {gettext("Something went wrong")}
                  </div>
                  <div
                    className="alert-close"
                    onClick={() => setisError(false)}
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i className="ki ki-close"></i>
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {isSuccess && (
                <div
                  className="alert alert-custom alert-light-success fade show mb-10"
                  role="alert"
                >
                  <div className="alert-icon">
                    <span className="svg-icon svg-icon-3x svg-icon-success">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Info-circle.svg"
                        )}
                      ></SVG>{" "}
                    </span>
                  </div>
                  <div className="alert-text font-weight-bold">
                    {gettext("Team switched successfully")}
                  </div>
                  <div
                    className="alert-close"
                    onClick={() => setisSuccess(false)}
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">
                        <i className="ki ki-close"></i>
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {/* end::Alert */}

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {gettext("Current Company")}
                </label>
                <div className="col-lg-9 col-xl-6">{currentTeam}</div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {gettext("Change Company to")}
                </label>
                <div className="col-lg-9 col-xl-6">
                  <Select
                    onChange={teamChange}
                    options={teams}
                    isDisabled={demo}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.name}
                    required
                    {...props}
                    {...props.field}
                  />
                  {formik.touched.team && formik.errors.team ? (
                    <div className="invalid-feedback">{formik.errors.team}</div>
                  ) : null}
                </div>
              </div>
            </div>
            {/* end::Body */}
          </div>
          {/* end::Form */}
        </div>

        {/* start : footer */}
        <div className="table-footer">
          <div className="col-12 p-0">
            <div className="action-alignment">
              <button
                type="submit"
                className="btn btn-success mr-2"
                disabled={
                  demo ||
                  formik.isSubmitting ||
                  (formik.touched && !formik.isValid)
                }
              >
                {gettext("Save Changes")}
                {formik.isSubmitting}
              </button>
              <Link
                to="/user-profile/personal-information"
                className="btn btn-secondary"
              >
                {gettext("Cancel")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default SwitchTeam;
