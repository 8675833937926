import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { ADD_NEW_METER } from "../commands";

export function useAddMeter(onCompleted = () => {}, onError = () => {}) {
  const [addCustomerMeter, { loading }] = useMutation(ADD_NEW_METER, {
    onCompleted: () => {
      onCompleted();
    },
    onError: () => {
      onError();
    }
  });

  const addMeter = useCallback(
    ({
      buildingId,
      label,
      meterNo,
      supplier,
      providerUsername,
      providerPassword,
      sourceType
    }) => {
      addCustomerMeter({
        variables: {
          buildingId,
          data: {
            meterNo,
            sourceType,
            supplier,
            providerUsername,
            providerPassword,
            label
          }
        },
        refetchQueries: ["getBuildingMeters"]
      });
    },
    [addCustomerMeter]
  );

  return {
    addMeter,
    loading
  };
}
