import { gql } from "@apollo/client";

export const GET_PUBLIC_DASHBOARD_DATA = gql`
  query comGetGraphImages($customerId: String) {
    comGetGraphImages(customerId: $customerId) {
      id
      customerId
      dashboard
    }
  }
`;
