import { FilterSelectButton } from "app/components";
import { useBenchmarkFilter, useTextdomainContext } from "app/providers";
import React from "react";

export function SelectBuildingTwo() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const {
    buildings,
    selectedBuildingTwo,
    setSelectedBuildingTwo,
    filtersLoading
  } = useBenchmarkFilter();

  return (
    <FilterSelectButton
      searchable={true}
      label={gettext("Building 2")}
      options={buildings}
      loading={filtersLoading}
      getOptionLabel={option => option.address}
      value={selectedBuildingTwo}
      onChange={newValue => setSelectedBuildingTwo(newValue)}
    />
  );
}
