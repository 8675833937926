import {
  Collapse,
  Divider,
  Grid,
  IconButton,
  styled,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const ExpandMore = styled(props => {
  const { ...other } = props;
  return <IconButton size="small" {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  backgroundColor: "#E4E4E7",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest
  })
}));

const CollapseCard = ({ title, content }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid
      item
      container
      sx={{
        backgroundColor: "#ffffff",
        p: 2,

        borderRadius: "8px"
      }}
      rowGap={1}
      xs={12}
    >
      <Grid
        item
        container
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        xs={12}
      >
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Grid item xs={12} pr={3} pb={2.5}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {content}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};
export default CollapseCard;
