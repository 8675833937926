import React from "react";
import publicIp from "public-ip";
import axios from "axios";
import { getById, update } from "./IndexedDb";
import md5 from "md5";
import dateFormat from "dateformat";
import { createIntl, createIntlCache } from "react-intl";
import {
  COT2_BUILDINGS_URL,
  SINGLE_SETTINGS_URL,
  MONTH_NAMES_SHORT,
  // ELECTRICITY_FORMULA,
  GRAPH_IMAGES,
  // GET_TEAM_SEF,
  POST_TEAMS_SETTINGS,
  GET_TEAM_ID,
  GET_PAGE_DATA,
  GET_GRAPHS,
  GET_GRAPH_IMAGES,
  GET_SUPPLIERS,
  ADD_METERS_DATA
} from "./Constants";
import "@formatjs/intl-displaynames/polyfill";
import "@formatjs/intl-displaynames/locale-data/en";
import "@formatjs/intl-displaynames/locale-data/da";
import "@formatjs/intl-displaynames/locale-data/de";
import "@formatjs/intl-displaynames/locale-data/es";
import "@formatjs/intl-displaynames/locale-data/fr";
import "@formatjs/intl-displaynames/locale-data/ja";
import "@formatjs/intl-displaynames/locale-data/zh";

// import "@formatjs/intl-relativetimeformat/polyfill";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/da";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/ja";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/zh";

import daMessages from "_metronic/i18n/messages/da";
import deMessages from "_metronic/i18n/messages/de";
import enMessages from "_metronic/i18n/messages/en";
import esMessages from "_metronic/i18n/messages/es";
import frMessages from "_metronic/i18n/messages/fr";
import jaMessages from "_metronic/i18n/messages/ja";
import zhMessages from "_metronic/i18n/messages/zh";
import noMessages from "_metronic/i18n/messages/no";
import svMessages from "_metronic/i18n/messages/sv";
import client from "services/apiClient";
import { LANG } from "app/providers";
import { CUSTOMER_ADDRESSES } from "services/Constant";

const allMessages = {
  da: daMessages,
  de: deMessages,
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  zh: zhMessages,
  no: noMessages,
  sv: svMessages
};

export function getGeoInfo() {
  return publicIp.v4().then(ip => {
    let ipapiKey = "ipapi.co-" + ip;
    // localStorage.removeItem(ipapiKey);
    if (localStorage.getItem(ipapiKey) === null) {
      return axios
        .get("https://ipapi.co/" + ip + "/json")
        .then(res => res.data);
    } else {
      return JSON.parse(localStorage.getItem(ipapiKey));
    }
  });
}

export function localeNumberFormat(number, decimal) {
  if (number === undefined) return;
  return number.toLocaleString(LANG, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
    useGrouping: false
  });
}

export function openInNewTab(url) {
  let win = window.open(url, "_blank");
  win.focus();
}

// export function defineAbilitiesFor() {
//   const state = null;
//   // const { can, cannot, rules } = new AbilityBuilder(Ability);

//   const { can, cannot, rules } = new AbilityBuilder(Ability);
//   if (!state?.auth?.user?.rolePermissions) {
//     //window.location.href = "/logout";
//   }

//   if (state?.auth?.user?.rolePermissions.indexOf("create") > -1) {
//     can("create", "all");
//   }
//   if (state?.auth?.user?.rolePermissions.indexOf("read") > -1) {
//     can("read", "all");
//   }
//   if (state?.auth?.user?.rolePermissions.indexOf("update") > -1) {
//     can("update", "all");
//   }
//   if (state?.auth?.user?.rolePermissions.indexOf("delete") > -1) {
//     can("delete", "all");
//   }

//   if (state?.auth?.user?.role === "admin") {
//     can("manage", "users");
//     can("manage", "settings");
//     can("create", "all");
//     can("read", "all");
//     can("update", "all");
//     can("delete", "all");
//   }

//   if (state?.auth?.user?.roles.find(role => role.name === "Partner")) {
//     can("manage", "ownusers");
//   }

//   if (state?.auth?.user?.isSuperAdmin === 1) {
//     can("manage", "all");
//     cannot("manage", "ownusers");
//   }

//   return new Ability(rules);
// }

export const buildingData = async () => {
  const response = await client.get(COT2_BUILDINGS_URL);
  return response.data;
};

export async function getBuildings4Dropdown(customerId) {
  // let key = "getBuildings4Dropdown";
  let returnData = [];
  const response = (await client.get(CUSTOMER_ADDRESSES + customerId)).data;
  if (response.data) {
    let data = response.data[0];
    data.map(resp => {
      returnData.push({
        value: resp.address,
        label: resp.address,
        electricity: resp.electricity,
        water: resp.water,
        heating: resp.heating
      });
      return false;
    });
  }
  return returnData;
}

export const escapedNewLineToLineBreakTag = string => {
  return string.split("\n").map((item, index) => {
    return index === 0 ? item : [<br key={index} />, item];
  });
};

export const translate = (id, values = {}) => {
  const intlCache = createIntlCache();
  const intl = createIntl(
    {
      locale: LANG,
      messages: allMessages[LANG],
      onError(err) {
        // console.log(err);
      }
    },
    intlCache
  );
  return intl.formatMessage({ id: id }, values);
};

export const translateMonth = (monthNumber, size = "long") => {
  const thisYear = new Date().getFullYear();
  const thisDate = new Date(thisYear, monthNumber - 1, 1);

  const intlCache = createIntlCache();
  const intl = createIntl(
    {
      locale: LANG,
      messages: allMessages[LANG],
      onError(err) {
        // console.log(err);
      }
    },
    intlCache
  );
  return intl.formatDate(thisDate, { month: size });
};

export const setWithExpiry = (key, value, ttl, unique = "") => {
  const now = new Date();
  const item = {
    value: value,
    unique: unique,
    expiry: now.getTime() + ttl * 1000
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key, unique = "") => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry || item.unique !== unique) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const setCacheWithExpiry = (key, value, ttl = 0) => {
  const now = new Date();
  key = md5(key);
  let expiry = now.getTime() + ttl * 1000;

  if (key) {
    update("cache", { key: key, value: value, expiry: expiry }, key).then(
      () => {
        // console.log('ID Generated: ', event.target.result);
        // console.log(event);
      }
      // error => {
      //   // console.log(error);
      // }
    );
  }
  return null;
};

export const getCacheWithExpiry = key => {
  return new Promise((resolve, reject) => {
    const now = new Date();
    // const [data, changeData] = useState(null)
    key = md5(key);
    getById("cache", key)
      .then(row => {
        if (row.value) {
          if (now.getTime() > row.expiry) {
            // deleteById('cache', key);
            resolve(null);
          }
          resolve(row.value);
        } else {
          reject(row);
        }
      })
      .catch(err => {
        // console.log(err);
        resolve(null);
      });
  });
};

export const getCacheWithoutExpiry = key => {
  return new Promise((resolve, reject) => {
    key = md5(key);
    getById("cache", key)
      .then(row => {
        if (row.value) {
          resolve(row.value);
        } else {
          reject(row);
        }
      })
      .catch(err => {
        // console.log(err);
        resolve(null);
      });
  });
};

export const getUserSingleSetting = (key, user) => {
  return client
    .get(SINGLE_SETTINGS_URL, {
      params: {
        user_id: user?.id,
        team_id: user?.currentTeamId,
        var: key
      }
    })
    .then(response => {
      return response.data.data;
    });
};

export const getGreenElectricityStatus = dataElectricity => {
  let greenElectricityStatus = "false";

  if (
    !dataElectricity.every(data => {
      return data?.is_green === dataElectricity[0]?.is_green;
    })
  ) {
    greenElectricityStatus = "partial";
  } else if (dataElectricity[0]?.is_green) greenElectricityStatus = "true";
  else greenElectricityStatus = "false";

  return greenElectricityStatus;
};

export const getAggregatedConsumptionKwhBuildings = (
  uniqueId,
  type,
  customers,
  addresses,
  resolution,
  dateFrom,
  dateTo,
  dataType,
  chartTitle,
  outputType = 0
) => {
  return new Promise((resolve, reject) => {
    if (type === "all" || type === "aggregated") {
      if (outputType === 0 && customers && addresses) {
        const promise1 = getByAddresses(
          "electricity",
          customers,
          addresses,
          dateFrom,
          dateTo,
          resolution
        );
        const promise2 = getByAddresses(
          "heating",
          customers,
          addresses,
          dateFrom,
          dateTo,
          resolution
        );
        const promise3 = getByAddresses(
          "water",
          customers,
          addresses,
          dateFrom,
          dateTo,
          resolution
        );
        Promise.all([promise1, promise2, promise3]).then(results => {
          const dataElectricity = results[0];
          const dataHeating = results[1];
          const dataWater = results[2];
          let parsedData = parseData4AggregatedConsumptionBuildings(
            dateFrom,
            dateTo,
            dataElectricity,
            dataHeating,
            dataWater,
            dataType
          );
          let greenElectricityStatus = getGreenElectricityStatus(
            dataElectricity
          );
          let returnData = {};
          returnData = areaGraphDataPerBuilding(
            uniqueId,
            chartTitle,
            parsedData,
            dataType,
            greenElectricityStatus
          );
          resolve(returnData);
        });
      }
    }
  });
};

export const getAggregatedConsumptionKwh = (
  uniqueId,
  type,
  customers,
  addresses,
  resolution,
  dateFrom,
  dateTo,
  dataType,
  chartTitle,
  outputType = 0
) => {
  return new Promise((resolve, reject) => {
    if (type === "all" || type === "aggregated") {
      if (outputType === 0 && customers && addresses) {
        // console.log('Start')
        getByAddresses(
          "electricity",
          customers,
          addresses,
          dateFrom,
          dateTo,
          resolution
        ).then(dataElectricity => {
          getByAddresses(
            "heating",
            customers,
            addresses,
            dateFrom,
            dateTo,
            resolution
          ).then(dataHeating => {
            // console.log('Heating Done')
            getByAddresses(
              "water",
              customers,
              addresses,
              dateFrom,
              dateTo,
              resolution
            ).then(dataWater => {
              // console.log('Water Done')
              let greenElectricityStatus = getGreenElectricityStatus(
                dataElectricity
              );

              let parsedData = parseData4AggregatedConsumption(
                dateFrom,
                dateTo,
                dataElectricity,
                dataHeating,
                dataWater,
                dataType
              );
              let returnData = {};
              if (type === "all") {
                returnData = areaGraphData3(
                  uniqueId,
                  chartTitle,
                  parsedData,
                  greenElectricityStatus
                );
              } else {
                returnData = areaGraphData1(
                  uniqueId,
                  chartTitle,
                  parsedData,
                  "aggregated",
                  greenElectricityStatus
                );
              }
              resolve(returnData);
            });
          });
        });
      }

      if (outputType === 1 && customers && addresses) {
        getByAddresses(
          "electricity",
          customers,
          addresses,
          dateFrom,
          dateTo,
          resolution
        ).then(dataElectricity => {
          getByAddresses(
            "heating",
            customers,
            addresses,
            dateFrom,
            dateTo,
            resolution
          ).then(dataHeating => {
            getByAddresses(
              "water",
              customers,
              addresses,
              dateFrom,
              dateTo,
              resolution
            ).then(dataWater => {
              let greenElectricityStatus = getGreenElectricityStatus(
                dataElectricity
              );

              let parsedData = parseData4AggregatedConsumption2Multi(
                dateFrom,
                dateTo,
                dataElectricity,
                dataHeating,
                dataWater,
                dataType
              );
              let returnData = {};
              returnData = areaGraphDataPerBuilding(
                uniqueId,
                chartTitle,
                parsedData,
                dataType,
                greenElectricityStatus
              );
              resolve(returnData);
            });
          });
        });
      }
    } else {
      if (outputType === 0 && customers && addresses) {
        getByAddresses(
          type,
          customers,
          addresses,
          dateFrom,
          dateTo,
          resolution
        ).then(data => {
          let returnData = {};
          let dataElectricity = [];
          let dataHeating = [];
          let dataWater = [];
          let greenElectricityStatus = undefined;
          switch (type) {
            case "electricity":
              dataElectricity = data;
              greenElectricityStatus = getGreenElectricityStatus(
                dataElectricity
              );
              break;
            case "heating":
              dataHeating = data;
              break;
            case "water":
              dataWater = data;
              break;
            default:
              break;
          }

          let parsedData = parseData4AggregatedConsumption(
            dateFrom,
            dateTo,
            dataElectricity,
            dataHeating,
            dataWater,
            dataType
          );
          returnData = areaGraphData1(
            uniqueId,
            chartTitle,
            parsedData,
            type,
            greenElectricityStatus
          );
          resolve(returnData);
        });
      }

      if (outputType === 1 && customers && addresses) {
        getByAddresses(
          type,
          customers,
          addresses,
          dateFrom,
          dateTo,
          resolution
        ).then(data => {
          let returnData = {};
          let parsedData = parseData4AggregatedConsumption2Single(
            dateFrom,
            dateTo,
            data,
            dataType
          );
          returnData = areaGraphDataPerBuilding(
            uniqueId,
            chartTitle,
            parsedData,
            dataType
          );
          resolve(returnData);
        });
      }
    }
  });
};

export const data4ExportBenchMarkHourly = (
  customers,
  addresses,
  meter,
  type,
  fromDate,
  toDate
) => {
  return new Promise((resolve, reject) => {
    multipleCustomerPipe(customers, addresses).then(address => {
      // Todo: Need to fix it later
      getByAddresses(
        type,
        customers,
        address,
        fromDate,
        toDate,
        "h",
        "all",
        false
      ).then(res => {
        let finalObject = {
          title: "Hourly Data",
          heading: [
            "Time",
            "Consumption (kWh)",
            "Tons carbon emission",
            "Production (kWh)",
            "Production CO2"
          ],
          content: []
        };

        let tmpObject = {};
        let tmpObjectProd = {};
        res.map((obj, index) => {
          obj.data.map((obj2, index2) => {
            if (meter === obj.meter || meter === "all" || meter === "All") {
              if (!tmpObject[obj2.ts]) {
                tmpObject[obj2.ts] = {
                  v: 0,
                  co2: 0
                };
              }
              if (!tmpObjectProd[obj2.ts]) {
                tmpObjectProd[obj2.ts] = {
                  v: 0,
                  co2: 0
                };
              }
              if (obj.type_of_mp === "E18") {
                tmpObjectProd[obj2.ts]["v"] += obj2.v;
                tmpObjectProd[obj2.ts]["co2"] += obj2.co2;
              } else {
                tmpObject[obj2.ts]["v"] += obj2.v;
                tmpObject[obj2.ts]["co2"] += obj2.co2;
              }
            } else {
              // console.log("Invalid meter number");
            }
            return false;
          });
          return false;
        });

        for (let key in tmpObject) {
          finalObject.content.push([
            key,
            tmpObject[key].v,
            tmpObject[key].co2,
            tmpObjectProd[key].v,
            tmpObjectProd[key].co2
          ]);
        }
        resolve(finalObject);
      });
    });
  });
};

export const data4ExportHourly = (
  customers,
  addresses,
  meter,
  type,
  segment,
  unit
) => {
  return new Promise((resolve, reject) => {
    let dateFromObject = new Date();
    let dateToObject = new Date();

    let fromDate = dateFormat(dateFromObject, "yyyy-mm-dd");
    let toDate = dateFormat(dateToObject, "yyyy-mm-dd");
    switch (segment) {
      case "h":
        dateFromObject = new Date(unit);
        fromDate = dateFormat(dateFromObject, "yyyy-mm-dd");
        dateToObject = new Date(
          dateFromObject.setDate(dateFromObject.getDate() + 1)
        );
        toDate = dateFormat(dateFromObject, "yyyy-mm-dd");
        break;
      case "d":
        dateFromObject = new Date(unit + "-01");
        fromDate = dateFormat(dateFromObject, "yyyy-mm-dd");
        dateToObject = new Date(
          dateFromObject.setMonth(dateFromObject.getMonth() + 1)
        );
        toDate = dateFormat(dateFromObject, "yyyy-mm-dd");
        break;
      case "m":
        dateFromObject = new Date(unit + "-01-01");
        fromDate = dateFormat(dateFromObject, "yyyy-mm-dd");
        dateToObject = new Date(
          dateFromObject.setYear(dateFromObject.getFullYear() + 1)
        );
        toDate = dateFormat(dateFromObject, "yyyy-mm-dd");
        break;
      default:
        resolve(false);
    }
    multipleCustomerPipe(customers, addresses).then(address => {
      // Todo: Need to fix it later
      getByAddressesExport(
        type,
        customers,
        address,
        fromDate,
        toDate,
        "h",
        "all",
        false,
        meter
      ).then(res => {
        let finalObject = {
          title: "Hourly Data",
          electricityGreen: res.electricity_green,
          heading: [
            "Time",
            "Consumption (kWh)",
            "Tons carbon emission",
            "Production (kWh)",
            "Production CO2"
          ],
          content: []
        };

        let tmpObject = {};
        let tmpObjectProd = {};
        const dataResult = res?.data ? res?.data : [];
        dataResult.map((obj, index) => {
          obj.data.map((obj2, index2) => {
            if (meter === obj.meter || meter === "all" || meter === "All") {
              if (!tmpObject[obj2.ts]) {
                tmpObject[obj2.ts] = {
                  v: 0,
                  co2: 0
                };
              }
              if (!tmpObjectProd[obj2.ts]) {
                tmpObjectProd[obj2.ts] = {
                  v: 0,
                  co2: 0
                };
              }
              if (obj.type_of_mp === "E18") {
                tmpObjectProd[obj2.ts]["v"] += obj2.v;
                tmpObjectProd[obj2.ts]["co2"] += obj2.co2;
              } else {
                tmpObject[obj2.ts]["v"] += obj2.v;
                tmpObject[obj2.ts]["co2"] += obj2.co2;
              }
            } else {
              // console.log("Invalid meter number");
            }
            return false;
          });
          return false;
        });

        for (let key in tmpObject) {
          finalObject.content.push([
            key,
            tmpObject[key].v,
            tmpObject[key].co2,
            tmpObjectProd[key].v,
            tmpObjectProd[key].co2
          ]);
        }
        resolve(finalObject);
      });
    });
  });
};

export const getBuildings = customers => {
  return new Promise((resolve, reject) => {
    let key = "getBuildings";
    let ttl = 1;
    let returnData = getWithExpiry(key, customers);
    if (returnData) {
      resolve(returnData);
    } else {
      client.get(CUSTOMER_ADDRESSES + customers).then(response => {
        let data = response.data.data[0];
        setWithExpiry(key, data, ttl, customers);
        resolve(data);
      });
    }
  });
};

export const getEPCData = (customer, addresses, type, from, to, resolution) => {
  // console.log("in getEnergyByAddress");
  return new Promise((resolve, reject) => {
    if (type === "energy") {
      getByAddresses(
        "electricity",
        customer,
        addresses,
        from,
        to,
        resolution,
        "m3"
      ).then(dataElectricity => {
        // console.log("Electricity: Done");
        getByAddresses(
          "heating",
          customer,
          addresses,
          from,
          to,
          resolution,
          "m3"
        ).then(dataHeating => {
          // console.log("Heating: Done");
          getByAddresses(
            "water",
            customer,
            addresses,
            from,
            to,
            resolution,
            "m3"
          ).then(dataWater => {
            // console.log("Water: Done");
            let returnData = [dataElectricity, dataHeating, dataWater];
            // let parsedData = parseData4AggregatedConsumption(from, to, dataElectricity, dataHeating, dataWater, dataType);
            // let returnData = {};
            // if (type === 'all') {
            //     returnData = areaGraphData13(uniqueId, chartTitle, parsedData);
            // } else {
            //     returnData = areaGraphData1(uniqueId, chartTitle, parsedData, 'aggregated');
            // }
            resolve(returnData);
          });
        });
      });
    } else {
      getByAddresses(
        type,
        customer,
        addresses,
        from,
        to,
        resolution,
        "m3"
      ).then(data => {
        let returnData = [data];
        resolve(returnData);
      });
    }
  });
};

export const getEnergyByAddress = (
  customer,
  addresses,
  from,
  to,
  resolution
) => {
  // console.log("in getEnergyByAddress");
  return new Promise((resolve, reject) => {
    getByAddresses(
      "electricity",
      customer,
      addresses,
      from,
      to,
      resolution
    ).then(dataElectricity => {
      // console.log("Electricity: Done");
      getByAddresses("heating", customer, addresses, from, to, resolution).then(
        dataHeating => {
          // console.log("Heating: Done");
          getByAddresses(
            "water",
            customer,
            addresses,
            from,
            to,
            resolution
          ).then(dataWater => {
            // console.log("Water: Done");
            let returnData = {
              electricity: dataElectricity,
              heating: dataHeating,
              water: dataWater
            };
            // let parsedData = parseData4AggregatedConsumption(from, to, dataElectricity, dataHeating, dataWater, dataType);
            // let returnData = {};
            // if (type === 'all') {
            //     returnData = areaGraphData13(uniqueId, chartTitle, parsedData);
            // } else {
            //     returnData = areaGraphData1(uniqueId, chartTitle, parsedData, 'aggregated');
            // }
            resolve(returnData);
          });
        }
      );
    });
  });
};

const getByAddressesExport = (
  type,
  customer,
  addresses,
  from,
  to,
  resolution,
  unit = "all",
  cached = true,
  meter
) => {
  // Example: https://api.globalgoals.io/data/v2/api/by_addresses?customer=16&type=electricity&from=2017-08-01&to=2021-09-01&resolution=y&addresses=R%C3%B8rthvej%2034A,%208300%20Odder|Skivevej%20120,%207500%20Holstebro|Lysholt%20All%C3%A9%2012,%207100%20Vejle
  const { REACT_APP_API_URL } = process.env;
  let url = REACT_APP_API_URL + "data/v2/api/by_addresses";
  let cacheTTL = 3600; // 600 seconds
  return new Promise((resolve, reject) => {
    let key = url + type + customer + addresses + from + to + resolution;
    if (type && customer && addresses && from && to && resolution) {
      client
        .post(url, {
          customer: customer,
          type: type,
          from: from,
          to: to,
          resolution: resolution,
          addresses: addresses,
          unit: unit,
          meter: meter
        })
        .then(response => {
          let data = response.data.data;
          // SetWithExpiry2(key, data, cacheTTL); // Data size is big so why I have stopped it.
          if (data.length) {
            setCacheWithExpiry(key, data, cacheTTL);
          }
          resolve(data);
        });
    } else {
      // console.log("Missing parameter in getByAddresses function");
      resolve([]);
    }
  });
};

const getByAddresses = (
  type,
  customer,
  addresses,
  from,
  to,
  resolution,
  unit = "all",
  cached = true
) => {
  // Example: https://api.globalgoals.io/data/v2/api/by_addresses?customer=16&type=electricity&from=2017-08-01&to=2021-09-01&resolution=y&addresses=R%C3%B8rthvej%2034A,%208300%20Odder|Skivevej%20120,%207500%20Holstebro|Lysholt%20All%C3%A9%2012,%207100%20Vejle
  const { REACT_APP_API_URL } = process.env;
  let url = REACT_APP_API_URL + "data/v2/api/by_addresses";
  let cacheTTL = 3600; // 600 seconds
  return new Promise((resolve, reject) => {
    let key = url + type + customer + addresses + from + to + resolution;
    if (type && customer && addresses && from && to && resolution) {
      getCacheWithExpiry(key).then(returnData => {
        if (cached && returnData) {
          resolve(returnData);
        } else {
          client
            .post(url, {
              customer: customer,
              type: type,
              from: from,
              to: to,
              resolution: resolution,
              addresses: addresses,
              unit: unit
            })
            .then(response => {
              let data = response.data.data.data;
              // SetWithExpiry2(key, data, cacheTTL); // Data size is big so why I have stopped it.
              if (data.length) {
                setCacheWithExpiry(key, data, cacheTTL);
              }
              resolve(data);
            });
        }
      });
    } else {
      // console.log("Missing parameter in getByAddresses function");
      resolve([]);
    }
  });
};

const multipleCustomerPipe = (customers, addresses) => {
  return new Promise((resolve, reject) => {
    if (addresses === "all" || addresses === "All") {
      getBuildings(customers).then(response => {
        let addressArray = response.map((obj, index) => {
          return obj.address;
        });
        resolve(addressArray.join("|"));
      });
    } else {
      resolve(addresses);
    }
  });
};

export const parseData4AggregatedConsumption = (
  dateFrom,
  dateTo,
  dataElectricity,
  dataHeating,
  dataGas,
  dataWater,
  dataType,
  gettext
) => {
  let categories = [];
  let electricity = [];
  let heating = [];
  let water = [];

  let aggregated = [];
  let dataObject = {};

  if (
    dataElectricity.length ||
    dataHeating.length ||
    dataWater.length ||
    dataGas.length
  ) {
    const fromDateObj = new Date(dateFrom);
    fromDateObj.setDate(fromDateObj.getDate() + 1);
    const toDateObj = new Date(dateTo);
    let safeTerminate = 0;

    for (
      fromDateObj;
      fromDateObj < toDateObj;
      fromDateObj.setMonth(fromDateObj.getMonth() + 1)
    ) {
      let currentDate = new Date(fromDateObj);
      let monthNumber = currentDate.getMonth() + 1;
      let dateKeyMonth = monthNumber < 10 ? "0" + monthNumber : monthNumber;
      let dateKey = currentDate.getFullYear() + "-" + dateKeyMonth;
      let dateValue =
        MONTH_NAMES_SHORT[currentDate.getMonth()] +
        " " +
        currentDate.getFullYear();
      let montDataValue = `${getMonthNames(dateValue.split(" ")[0], gettext)} ${
        dateValue.split(" ")[1]
      }`;
      dataObject[dateKey] = {
        date: montDataValue,
        electricity: 0,
        heating: 0,
        gas: 0,
        water: 0,
        aggregated: 0
      };

      if (safeTerminate++ > 100) {
        break;
      }
    }
    // console.log('2019-10-01T00:00:00 =>', dataObject)
    dataElectricity.forEach(rawData => {
      let dateKey = dateFormat(new Date(rawData.ts), "yyyy-mm");
      if (dataObject[dateKey]) {
        dataObject[dateKey].electricity += parseDataTypeValue(
          rawData,
          dataType
        );
        // dataObject[dateKey].aggregated += parseDataTypeValue(
        //     rawData,
        //     dataType
        // );
      }
    });

    dataHeating.forEach(rawData => {
      let dateKey = dateFormat(new Date(rawData.ts), "yyyy-mm");
      if (dataObject[dateKey]) {
        dataObject[dateKey].heating += parseDataTypeValue(rawData, dataType);
        // dataObject[dateKey].aggregated += parseDataTypeValue(
        //     rawData,
        //     dataType
        // );
      }
    });

    dataGas.forEach(rawData => {
      let dateKey = dateFormat(new Date(rawData.ts), "yyyy-mm");
      if (dataObject[dateKey]) {
        dataObject[dateKey].gas += parseDataTypeValue(rawData, dataType);
        // dataObject[dateKey].aggregated += parseDataTypeValue(
        //     rawData,
        //     dataType
        // );
      }
    });

    dataWater.forEach(rawData => {
      let dateKey = dateFormat(new Date(rawData.ts), "yyyy-mm");
      if (dataObject[dateKey]) {
        dataObject[dateKey].water += parseDataTypeValue(rawData, dataType);
        // dataObject[dateKey].aggregated += parseDataTypeValue(
        //     rawData,
        //     dataType
        // );
      }
    });

    Object.keys(dataObject).map((key, index) => {
      categories.push(dataObject[key].date);
      electricity.push(dataObject[key].electricity.toFixed(2));
      heating.push(
        (
          parseFloat(dataObject[key].heating) + parseFloat(dataObject[key].gas)
        ).toFixed(2)
      );

      // heating.push(dataObject[key].heating.toFixed(2));
      water.push(dataObject[key].water.toFixed(2));
      // aggregated.push(dataObject[key].aggregated.toFixed(2));

      aggregated.push(
        parseFloat(
          dataObject[key].electricity +
            dataObject[key].heating +
            dataObject[key].gas +
            dataObject[key].water
        ).toFixed(2)
      );
      return true;
    });
  }
  return {
    categories: categories,
    electricity: electricity,
    heating: heating,
    water: water,
    aggregated: aggregated
  };
};

export const parseData4AggregatedConsumption2Single = (
  dateFrom,
  dateTo,
  rawData,
  dataType,
  address,
  gettext
) => {
  let buildings = [];
  let categories = [];
  let data = [];
  let dataObject = {};

  if (rawData.length) {
    const fromDateObj = new Date(dateFrom);
    const toDateObj = new Date(dateTo);
    let safeTerminate = 0;

    rawData.forEach(meter => {
      buildings.push(address);
    });
    buildings = Array.from(new Set(buildings));
    buildings.forEach(building => {
      dataObject[building] = {};
      categories[building] = [];
      data[building] = [];
    });

    for (
      fromDateObj;
      fromDateObj < toDateObj;
      fromDateObj.setMonth(fromDateObj.getMonth() + 1)
    ) {
      let currentDate = new Date(fromDateObj);
      let monthNumber = currentDate.getMonth() + 1;
      let dateKeyMonth = monthNumber < 10 ? "0" + monthNumber : monthNumber;
      let dateKey = currentDate.getFullYear() + "-" + dateKeyMonth;
      let dateValue =
        MONTH_NAMES_SHORT[currentDate.getMonth()] +
        " " +
        currentDate.getFullYear();
      let montDataValue = `${getMonthNames(dateValue.split(" ")[0], gettext)} ${
        dateValue.split(" ")[1]
      }`;
      buildings.forEach(building => {
        dataObject[building][dateKey] = {
          date: montDataValue,
          data: 0
        };
      });

      // To avoid infinite loop
      if (safeTerminate++ > 100) {
        break;
      }
    }

    rawData.forEach(rowData => {
      let building = address;
      let dateKey = dateFormat(new Date(rowData.ts), "yyyy-mm");
      dataObject[building][dateKey].data += parseDataTypeValue(
        rowData,
        dataType
      );
    });
    Object.keys(dataObject).map((key1, index) => {
      return Object.keys(dataObject[key1]).map((key2, index) => {
        categories[key1].push(dataObject[key1][key2].date);
        data[key1].push(dataObject[key1][key2].data.toFixed(2));
        return true;
      });
    });
  }

  return {
    categories: categories,
    data: data
  };
};

let getMonthNames = (name, gettext) => {
  if (name === "Jan") {
    return gettext("Jan");
  } else if (name === "Feb") {
    return gettext("Feb");
  } else if (name === "Mar") {
    return gettext("Mar");
  } else if (name === "Apr") {
    return gettext("Apr");
  } else if (name === "May") {
    return gettext("May");
  } else if (name === "Jun") {
    return gettext("Jun");
  } else if (name === "Jul") {
    return gettext("Jul");
  } else if (name === "Aug") {
    return gettext("Aug");
  } else if (name === "Sep") {
    return gettext("Sep");
  } else if (name === "Oct") {
    return gettext("Oct");
  } else if (name === "Nov") {
    return gettext("Nov");
  } else if (name === "Dec") {
    return gettext("Dec");
  }
  return "";
};

export const parseData4AggregatedConsumptionBuildings = (
  dateFrom,
  dateTo,
  dataElectricity,
  dataHeating,
  dataWater,
  dataType,
  address,
  gettext
) => {
  let buildings = [];
  let categories = [];
  let data = [];
  let dataObject = {};
  if (dataElectricity.length || dataHeating.length || dataWater.length) {
    const fromDateObj = new Date(dateFrom);
    fromDateObj.setDate(fromDateObj.getDate() + 1);
    const toDateObj = new Date(dateTo);
    let safeTerminate = 0;

    dataElectricity.forEach(meter => {
      buildings.push(address);
    });

    dataHeating.forEach(meter => {
      buildings.push(address);
    });

    dataWater.forEach(meter => {
      buildings.push(address);
    });

    buildings = Array.from(new Set(buildings));
    buildings.forEach(building => {
      dataObject[building] = {};
      categories[building] = [];
      data[building] = [];
    });

    for (
      fromDateObj;
      fromDateObj < toDateObj;
      fromDateObj.setMonth(fromDateObj.getMonth() + 1)
    ) {
      let currentDate = new Date(fromDateObj);
      let monthNumber = currentDate.getMonth() + 1;
      let dateKeyMonth = monthNumber < 10 ? "0" + monthNumber : monthNumber;
      let dateKey = currentDate.getFullYear() + "-" + dateKeyMonth;
      let dateValue =
        MONTH_NAMES_SHORT[currentDate.getMonth()] +
        " " +
        currentDate.getFullYear();
      let montDataValue = `${getMonthNames(dateValue.split(" ")[0], gettext)} ${
        dateValue.split(" ")[1]
      }`;
      buildings.forEach(building => {
        dataObject[building][dateKey] = {
          date: montDataValue,
          data: 0
        };
      });

      if (safeTerminate++ > 100) {
        break;
      }
    }
    dataElectricity.forEach(rawData => {
      let building = address;
      let dateKey = dateFormat(new Date(rawData.ts), "yyyy-mm");
      if (dataObject[building][dateKey]) {
        dataObject[building][dateKey].data += parseDataTypeValue(
          rawData,
          dataType
        );
      }
    });

    dataHeating.forEach(rawData => {
      let building = address;
      let dateKey = dateFormat(new Date(rawData.ts), "yyyy-mm");
      if (dataObject[building][dateKey]) {
        dataObject[building][dateKey].data += parseDataTypeValue(
          rawData,
          dataType
        );
      }
    });

    dataWater.forEach(rawData => {
      let building = address;
      let dateKey = dateFormat(new Date(rawData.ts), "yyyy-mm");
      if (dataObject[building][dateKey]) {
        dataObject[building][dateKey].data += parseDataTypeValue(
          rawData,
          dataType
        );
      }
    });
    Object.keys(dataObject).map((key1, index) => {
      return Object.keys(dataObject[key1]).map((key2, index) => {
        categories[key1].push(dataObject[key1][key2].date);
        data[key1].push(dataObject[key1][key2].data.toFixed(2));
        return true;
      });
    });
  }
  return {
    categories: categories,
    data: data
  };
};

export const parseData4AggregatedConsumption2Multi = (
  dateFrom,
  dateTo,
  dataElectricity,
  dataHeating,
  dataWater,
  dataType,
  address,
  gettext
) => {
  let buildings = [];
  let categories = [];
  let data = [];
  let dataObject = {};

  if (dataElectricity.length || dataHeating.length || dataWater.length) {
    const fromDateObj = new Date(dateFrom);
    const toDateObj = new Date(dateTo);
    let safeTerminate = 0;

    dataElectricity.forEach(meter => {
      buildings.push(address);
    });

    dataHeating.forEach(meter => {
      buildings.push(address);
    });

    dataWater.forEach(meter => {
      buildings.push(address);
    });

    buildings = Array.from(new Set(buildings));
    buildings.forEach(building => {
      dataObject[building] = {};
      categories[building] = [];
      data[building] = [];
    });

    for (
      fromDateObj;
      fromDateObj < toDateObj;
      fromDateObj.setMonth(fromDateObj.getMonth() + 1)
    ) {
      let currentDate = new Date(fromDateObj);
      let monthNumber = currentDate.getMonth() + 1;
      let dateKeyMonth = monthNumber < 10 ? "0" + monthNumber : monthNumber;
      let dateKey = currentDate.getFullYear() + "-" + dateKeyMonth;
      let dateValue =
        MONTH_NAMES_SHORT[currentDate.getMonth()] +
        " " +
        currentDate.getFullYear();
      let montDataValue = `${getMonthNames(dateValue.split(" ")[0], gettext)} ${
        dateValue.split(" ")[1]
      }`;
      buildings.forEach(building => {
        dataObject[building][dateKey] = {
          date: montDataValue,
          data: 0
        };
      });

      // To avoid infinite loop
      if (safeTerminate++ > 100) {
        break;
      }
    }

    dataElectricity.forEach(rowData => {
      let building = address;
      let dateKey = dateFormat(new Date(rowData.ts), "yyyy-mm");
      dataObject[building][dateKey].data += parseDataTypeValue(
        rowData,
        dataType
      );
    });

    dataHeating.forEach(rowData => {
      let building = address;
      let dateKey = dateFormat(new Date(rowData.ts), "yyyy-mm");
      dataObject[building][dateKey].data += parseDataTypeValue(
        rowData,
        dataType
      );
    });

    dataWater.forEach(rowData => {
      let building = address;
      let dateKey = dateFormat(new Date(rowData.ts), "yyyy-mm");
      dataObject[building][dateKey].data += parseDataTypeValue(
        rowData,
        dataType
      );
    });

    Object.keys(dataObject).map((key1, index) => {
      return Object.keys(dataObject[key1]).map((key2, index) => {
        const points = dataType === "co2_m2" ? 6 : 2;
        categories[key1].push(dataObject[key1][key2].date);
        data[key1].push(dataObject[key1][key2].data.toFixed(points));
        return true;
      });
    });
  }

  return {
    categories: categories,
    data: data
  };
};

let parseDataTypeValue = (data, dataType) => {
  let returnValue = 0;
  switch (dataType) {
    case "consumption":
      returnValue = data.consumptionV;
      break;
    case "production":
      returnValue = data.vPerM2;
      break;
    case "co2":
      returnValue = data.consumptionCo2;
      break;
    case "consumption_m2":
      returnValue = data.vPerM2;
      break;
    case "co2_m2":
      returnValue = data.co2PerM2;
      break;
    default:
      returnValue = data.consumptionV;
      break;
  }
  return returnValue;
};

export const areaGraphData3 = (
  uniqueId,
  chartTitle,
  parsedData,
  greenElectricityStatus,
  gettext
) => {
  let returnData = {
    unique_id: uniqueId,
    type: "area",
    chart_title: chartTitle,
    yaxisTitleText: uniqueId === "dashboardCo2Chart" ? "Tons" : "kWh",
    categories: parsedData.categories,
    greenElectricityStatus: greenElectricityStatus,
    data: [
      {
        name: greenElectricityStatus
          ? gettext("Green Electricity")
          : gettext("Electricity"),
        data: parsedData.electricity
      },
      {
        // name: gettext("District Heating"),
        name: gettext("Heating"),
        data: parsedData.heating
      },
      {
        name: gettext("Water"),
        data: parsedData.water
      }
    ]
  };

  return returnData;
};

export const areaGraphData1 = (
  uniqueId,
  chartTitle,
  parsedData,
  type,
  greenElectricityStatus,
  gettext
) => {
  let data = [];
  let text = "";
  switch (type) {
    case "electricity":
      data = parsedData.electricity;
      text = gettext("Electricity");
      break;
    case "heating":
      data = parsedData.heating;
      text = gettext("District Heating");
      break;
    case "water":
      data = parsedData.water;
      text = gettext("Water");
      break;
    case "aggregated":
      data = parsedData.aggregated;
      text = gettext("aggregated");
      break;
    default:
      data = parsedData.data;
  }

  let returnData = {
    unique_id: uniqueId,
    type: "area",
    chart_title: chartTitle,
    yaxisTitleText: uniqueId === "dashboardCo2Chart" ? "Tons" : "kWh",
    categories: parsedData.categories,
    greenElectricityStatus: greenElectricityStatus,
    data: [
      {
        name: text,
        data: data
      }
    ]
  };

  return returnData;
};

export const areaGraphDataPerBuilding = (
  uniqueId,
  chartTitle,
  parsedData,
  dataType,
  greenElectricityStatus
) => {
  let categories = ["Loading"];
  // let data = [{'name': 'Loading', 'data': [0]}];
  let data = [];

  Object.keys(parsedData["categories"]).map((building, index) => {
    categories = parsedData["categories"][building];
    data.push({ name: building, data: parsedData["data"][building] });
    return true;
  });

  let returnData = {
    unique_id: uniqueId,
    type: "area",
    chart_title: chartTitle,
    yaxisTitleText: dataType === "co2_m2" ? "tons CO₂/m2" : "kWh",
    categories: categories,
    data: data,
    greenElectricityStatus
  };

  return returnData;
};

export const setGraphImages = (
  currentTeamId,
  title,
  dashboard,
  image_bytes
) => {
  return new Promise(async (resolve, reject) => {
    await client
      .post(GRAPH_IMAGES, {
        team_id: currentTeamId,
        title,
        dashboard,
        image_name: currentTeamId + dashboard + title,
        image_bytes
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getGraphImages = team_id => {
  return new Promise(async (resolve, reject) => {
    await client
      .get(GRAPH_IMAGES + `?team_id=${team_id}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const postPublicPageData = (currentTeamId, values) => {
  return new Promise((resolve, reject) => {
    client
      .post(POST_TEAMS_SETTINGS, {
        team_id: currentTeamId,
        values: { description: values }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getPublicPageData = id => {
  return new Promise((resolve, reject) => {
    client
      .get(GET_PAGE_DATA + id)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getPublicImage = image_name => {
  return new Promise((resolve, reject) => {
    client
      .get(GET_GRAPH_IMAGES + image_name)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getTeamId = sef => {
  return new Promise(async (resolve, reject) => {
    await client
      .get(GET_TEAM_ID + `?sef=${sef}`)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getGraphNames = id => {
  return new Promise(async (resolve, reject) => {
    await client
      .get(GET_GRAPHS + `?team_id=${id}`)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const deletePrevDash = currentTeamId => {
  return new Promise(async (resolve, reject) => {
    await client
      .delete(GRAPH_IMAGES + `?team_id=${currentTeamId}`)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getSuppliers = type => {
  return new Promise(async (resolve, reject) => {
    client
      .get(GET_SUPPLIERS + `?type=${type}`)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const addMeterData = body => {
  return new Promise(async (resolve, reject) => {
    client
      .post(ADD_METERS_DATA, body)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const sendSlackMessage = params => {
  const { REACT_APP_API_URL_GRAPHQL } = process.env;
  let fieldsString = "";

  let defaultParam = {
    channel: "react-errors",
    type: "error",
    heading: window.location.href,
    detail: "",
    fields: []
  };

  let options = {
    ...defaultParam,
    ...params
  };

  getGeoInfo().then(geoData => {
    let fields = [
      { title: "Environment", value: process.env.REACT_APP_NODE_ENV }
    ];

    if (geoData) {
      fields = [
        { title: "Environment", value: process.env.REACT_APP_NODE_ENV },
        { title: "IP Address", value: geoData.ip ? geoData.ip : "N/A" },
        {
          title: "Country Name",
          value: geoData.country_name ? geoData.country_name : "N/A"
        },
        {
          title: "City Name",
          value: geoData.city ? geoData.city : "N/A"
        }
      ];
    }

    let fieldsMerged = [...options.fields, ...fields];

    options.fields = fieldsMerged.filter(
      (v, i, a) =>
        a.findIndex(v2 => ["title", "value"].every(k => v2[k] === v[k])) === i
    );

    if (options.fields.length > 0) {
      fieldsString = "fields: [\n";
      options.fields.forEach(obj => {
        fieldsString += "{\n";
        Object.keys(obj).forEach(obj2 => {
          fieldsString += obj2 + ': "' + obj[obj2] + '"\n';
        });
        fieldsString += "},\n";
      });
      fieldsString += "]";
    }

    let data = JSON.stringify({
      query: `mutation{
        sendSlackMessage(slackData: {
            channel: "${options.channel}"
            type: "${options.type}"
            heading: "${options.heading}"
            detail: "${options.detail}"
            ${fieldsString}
        })
        {
            success
            message
        }
    }`,
      variables: {}
    });

    // let config = {
    //   method: "post",
    //   url: REACT_APP_API_URL_GRAPHQL,
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   data: data
    // };

    const Options = {
      headers: { "content-type": "application/json" }
    };

    client
      .post(REACT_APP_API_URL_GRAPHQL, data, Options)
      .then(function(response) {
        // console.log(JSON.stringify(response.data));
      })
      .catch(function(error) {
        // console.log(error);
      });

    // axios(config)
  });
};
