import React, { useEffect, useState, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import * as Yup from "yup";
import Icon from "@mdi/react";
import ReactGA from "react-ga4";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { injectIntl } from "react-intl";
// import { FaMicrosoft } from "react-icons/fa";
// import { loginWithSocialMedia } from "../_redux/authCrud";
// import { GoogleLogin } from "react-google-login";
// import { FacebookLogin } from "react-facebook-login-lite";
// import { PublicClientApplication } from "@azure/msal-browser";
import {
  mdiKeyVariant,
  mdiEmail,
  mdiEyeOffOutline,
  mdiEyeOutline
} from "@mdi/js";
import { useSubmit } from "./hooks";
import { Alert, Collapse } from "@mui/material";

const { REACT_APP_URL } = process.env;

const initialValues = {
  email: "",
  password: "",
  rememberMe: false
};

export const config = {
  clientId: "76a50c64-cedf-4e7f-922f-16151c244a04",
  authority: "https://login.microsoftonline.com/organizations",
  redirectUri: REACT_APP_URL,
  scopes: ["user.read"]
};

function Login(props) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const location = useLocation();
  // const { setToken } = useAuth();

  const [isErrorOccured, setIsErrorOccured] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const { submit, isSubmitting } = useSubmit(
    () => {},
    error => {
      if (Boolean(error)) {
        setErrorMessage(error);
      } else setErrorMessage("Unknown Error Occurred !");

      setIsErrorOccured(true);
      setTimeout(() => {
        setIsErrorOccured(false);
        setErrorMessage(null);
      }, 3000);
    }
  );

  const [passwordShown, setPasswordShown] = useState(false);

  // const [toastList, updateToastList] = useState([]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [props, location.pathname]);

  const { intl } = props;
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(255, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      submit({
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe
      });
    }
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  // const responseGoogle = (res) => {
  //     try {
  //         updateToastList([]);
  //         const { profileObj } = res;
  //         if (profileObj.email) {
  //             const params = {
  //                 email: profileObj.email,
  //                 name: profileObj.name,
  //                 language: "en",
  //                 provider: "google",
  //                 profile_image: profileObj.imageUrl,
  //                 country: "",
  //                 accessToken: res.accessToken,
  //             };
  //             loginWithSocialMedia(params)
  //                 .then((res) => {
  //                     const { data } = res;
  //                     setToken(data.token);
  //                 })
  //                 .catch((error) => {
  //                     const toastArray = [
  //                         {
  //                             title: "Error",
  //                             description:
  //                                 "Email already registered without social provider",
  //                             type: "error",
  //                         },
  //                     ];
  //                     updateToastList(toastArray);
  //                 });
  //         }
  //     } catch (error) {
  //         // console.log(error);
  //     }
  // };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-sm-12 col-md-12">
          <div className="text-center login-title p-4">
            <h3 className="font-size-h1">{gettext("Sign in to Fabeke")}</h3>
          </div>
        </div>
        <div className="col-sm-12 col-md-5 email-login">
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {
              <Collapse in={isErrorOccured} className="form-group">
                <Alert severity="error">{errorMessage}</Alert>
              </Collapse>
            }

            <div className="row mt-2">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="form-group fv-plugins-icon-container">
                  <div className="email-icon">
                    <Icon
                      path={mdiEmail}
                      title={gettext("Email")}
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="#7a6969"
                    />
                  </div>
                  <input
                    placeholder={gettext("Email")}
                    type="email"
                    className={
                      "form-control form-control-solid input-special h-auto py-2 px-6 " +
                      getInputClasses("email")
                    }
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="form-group fv-plugins-icon-container">
                  <div className="email-icon">
                    <Icon
                      path={mdiKeyVariant}
                      title={gettext("Password")}
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="#7a6969"
                    />
                  </div>
                  <input
                    placeholder={gettext("Password")}
                    type={passwordShown ? "text" : "password"}
                    className={
                      "form-control form-control-solid input-special h-auto py-2 px-6 " +
                      getInputClasses("password")
                    }
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  <button
                    type="button"
                    className="show-hide-password"
                    onClick={togglePassword}
                  >
                    <Icon
                      path={passwordShown ? mdiEyeOutline : mdiEyeOffOutline}
                      title={gettext("Password")}
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="#7a6969"
                    />
                  </button>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.password}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <div>
                    <input
                      type="checkbox"
                      {...formik.getFieldProps("rememberMe")}
                    ></input>
                    {gettext("Remember Me")}
                  </div>
                  <div>
                    <Link
                      to="/auth/forgot-password"
                      className="text-dark-50 text-primary my-5 mr-2"
                      id="kt_login_forgot"
                    >
                      &nbsp;&nbsp;
                      {gettext("Forgot Password")}?
                    </Link>
                  </div>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-primary font-weight-bold px-9 my-3 btn-block`}
                  >
                    <span>{gettext("Sign In")}</span>
                    {/* {loading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )} */}
                  </button>
                </div>
                <hr></hr>
                <div>
                  {gettext("Don't have an account?")}
                  <Link
                    to="/auth/registration"
                    className="text-dark-50 text-primary"
                    id="kt_signup_submit"
                  >
                    &nbsp;
                    {gettext("Create an account")}
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* <div className="col-sm-12 col-md-1 seperator">
                    <img
                        src="/media/bg/login_or.png"
                        alt="OR"
                        className="float-right"
                    />
                </div> */}
        {/* <div className="col-sm-12 col-md-5 social-login">
          <div className={"facebook-btn-container"}>
            <FacebookLogin
              appId={REACT_APP_APP_ID_FACEBOOK}
              onSuccess={responseFacebook}
              className={"btn-facebook"}
              size={"small"}
              btnText={gettext("Sign in with Facebook")}
              // onFailure={onFailure}
            />
           
          </div>
          */}

        {/* <div>
                    <img
                        src="/media/social/google.png"
                        className="img-responsive float-left google-icon"
                        alt="Google"
                    />
                    <GoogleLogin
                        clientId={REACT_APP_CLIENT_ID_GOOGLE}
                        buttonText={gettext("Sign in with Google")}
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                        className={"btn-google"}
                        theme={"dark"}
                    />
                </div> */}
        {/* <br />
                <button
                    className="btn btn-info btn-block btn-microsoft"
                    type="button"
                    onClick={handleMicrosoftAction}
                >
                    <FaMicrosoft />{" "}
                    <div>{gettext("Sign in with Microsoft")}</div>
                </button> */}
      </div>
    </div>
    // </div>
  );
}

export default injectIntl(Login);
