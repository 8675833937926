import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { injectIntl } from "react-intl";
import CollapseCard from "./CollapseCard";
import { styled, Typography } from "@mui/material";

const Text = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#27272A"
}));

const content = gettext => {
  return (
    <>
      <Text>
        {gettext(
          "As a basis for calculating CO₂ emissions, the 125% method as recommended by the Danish Energy Agency has been used."
        )}
      </Text>
      <Text>
        {gettext(
          "CO₂ conversion factor for EL, data is used from Energinet's data hub."
        )}
      </Text>
      <Text
        dangerouslySetInnerHTML={{
          __html: gettext(
            "For the CO₂ discharge for water and wastewater, data from Vandital from 2020 from Danva have been used. Retrieved from their website at <a target='_blank' rel='noopener noreferrer' href='https://www.danva.dk'>www.danva.dk</a>."
          )
        }}
      />
      <br />
      <br />
      <Text
        dangerouslySetInnerHTML={{
          __html: gettext(
            "District heating is converted according to the Danish Energy Agency's 125% method for each individual district heating company, and they can be obtained by following: <a target='_blank' rel='noopener noreferrer' href='https://ens.dk/service/statistik-data-noegletal-og-kort/data-oversigt-over-energisektoren'>https://ens.dk/service/statistik-data-noegletal-og-kort/data-oversigt-over-energisektoren</a>"
          )
        }}
      />
      <br />
      <br />
      <Text>
        {gettext(
          "If a year has not been updated by the Danish Energy Agency, then the previous year's conversion is used until the year is updated on the Danish Energy Agency's website."
        )}
      </Text>
      <Text
        dangerouslySetInnerHTML={{
          __html: gettext(
            "Data: Overview of the energy sector | The Danish Energy Agency <a target='_blank' rel='noopener noreferrer' href='https://ens.dk'>ens.dk</a>"
          )
        }}
      />
    </>
  );
};

function Co2Section5(props) {
  const { isLoading } = props;
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  return (
    !isLoading && (
      <CollapseCard
        title={gettext("Sources and database")}
        content={content(gettext)}
      />
    )
  );
}

export default injectIntl(Co2Section5);
