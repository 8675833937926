import { useQuery } from "@apollo/client";
import { GET_COUNTRY_PUBLIC_ADDRESSES } from "./commands";

export function useCountryPublicAddresses(country) {
  const { data, loading } = useQuery(GET_COUNTRY_PUBLIC_ADDRESSES, {
    variables: {
      country
    }
  });

  return {
    buildings: data?.getCountryPublicAddresses || [],
    loading
  };
}
