export const primary = {
  50: "#E6EEEA",
  100: "#CCDDD6",
  200: "#B3CDC1",
  300: "#99BCAD",
  400: "#80AB98",
  500: "#679A83",
  600: "#4D896F",
  700: "#34795A",
  800: "#19384d",
  900: "#015731"
};
