import React, { useMemo } from "react";
import { ChartContainer } from "../ChartContainer";
import ReactApexChart from "react-apexcharts";
import { useLang } from "app/providers";
import { LineChartLoadingState } from "./components";

export function LineChart(props) {
  const { title, data, catagories, colors, loading } = props;

  const locale = useLang();

  const options = useMemo(() => {
    return {
      chart: {
        type: "line",
        stacked: false,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      colors,
      title: {
        text: title,
        align: "left",
        floating: false,
        style: {
          fontSize: "20px",
          fontWeight: 700,
          fontFamily: "Aeonik",
          color: "#27272A"
        }
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "round",
        colors: undefined,
        width: 4,
        dashArray: 0
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        formatter: function(seriesName, opts) {
          const color = opts.w.globals.colors[opts.seriesIndex];
          return `
            <span style="display:flex;flex-direction:row;justify-content:center;align-items:center;align-self:center;gap:5px;
            padding: 5px 10px;
            border-radius: 25px;
            border: 1px solid ${color};
            background-color: ${color}14;">
            <span style="background-color:${color};width:8px;height:8px;border-radius:50%;"></span>
            <span style="font-size:12px;font-weight:400;" >${seriesName}</span>
            </span>
          `;
        },
        position: "top",
        horizontalAlign: "left",
        offsetY: -15,
        labels: {
          useSeriesColors: true
        },
        markers: {
          width: 0,
          height: 0,
          radius: 0
        },
        itemMargin: {
          horizontal: 4,
          vertical: 0
        }
      },
      markers: {
        hover: {
          size: 8,
          sizeOffset: 3
        }
      },
      xaxis: {
        type: "catagory",
        categories: catagories,
        position: "bottom",
        floating: false,
        labels: {
          show: true,
          rotate: 0,
          hideOverlappingLabels: true,
          formatter: function(value) {
            const date = new Date(value);
            return date.toLocaleDateString(locale, {
              month: "short",
              year: "numeric"
            });
          },
          style: {
            colors: "#A1A1AA",
            fontSize: "12px",
            fontWeight: 400
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          show: false
        },
        tooltip: {
          enabled: true,
          offsetY: -10,
          formatter: function(val, opts) {
            return null;
          }
        }
      },
      yaxis: {
        floating: false,
        decimalsInFloat: 2,
        forceNiceScale: true,
        labels: {
          show: true,
          align: "left",
          offsetX: 0,
          formatter: function(value) {
            // const f = new Intl.NumberFormat(locale, {
            //   notation: "compact",
            //   maximumFractionDigits: 2
            // });
            if (locale === "da") {
              const numValue = Number(value);
              // const newStringValue = numValue.toFixed(2).toLocaleString();
              const newStringValue = numValue
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              const updatedValue = newStringValue
                .replace(/\./g, "#")
                .replace(/,/g, ".")
                .replace(/#/g, ",");
              return updatedValue;
            }
            // return f.format(value);
            return value.toFixed(2);
          },
          style: {
            colors: "#A1A1AA",
            fontSize: "12px",
            fontWeight: 400
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          show: false
        }
      },
      tooltip: {
        followCursor: true,
        y: {
          formatter: function(value) {
            if (locale === "en") {
              const numValue = Number(value);
              const newStringValue = numValue
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              return newStringValue;
            } else if (locale === "da") {
              const numValue = Number(value);

              const newStringValue = numValue
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              const updatedValue = newStringValue
                .replace(/\./g, "#")
                .replace(/,/g, ".")
                .replace(/#/g, ",");
              return updatedValue;
            } else {
              const numValue = Number(value);
              const newStringValue = numValue
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              return newStringValue;
            }
          }
        }
      },
      grid: {
        show: true,
        borderColor: "#D4D4D8",
        strokeDashArray: 6,
        position: "back",
        xaxis: {
          lines: {
            show: false
          }
        }
      }
    };
  }, [title, catagories, colors, locale]);

  return (
    <ChartContainer>
      {loading ? (
        <LineChartLoadingState />
      ) : (
        <ReactApexChart
          options={options}
          series={data}
          type="line"
          width={"100%"}
          height="100%"
        />
      )}
    </ChartContainer>
  );
}
