import React from "react";
import Typography from "@mui/material/Typography";
import { Modal } from "app/components";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useDashboardFilter, useTextdomainContext } from "app/providers";
import { IoClose } from "react-icons/io5";
import { DescriptionControl, AccessControl } from "./components";

export function ShareModal({ open, onClose }) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const [loading, setLoading] = React.useState(false);

  const { publicLoading } = useDashboardFilter();

  return (
    <Modal open={open} onClose={onClose}>
      <Stack spacing={0}>
        <Box
          sx={{
            p: 1.8,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "secondary.200"
          }}
        >
          <Stack direction={"row"} spacing={2} alignItems="center">
            <Typography fontSize={14} fontWeight={400}>
              {gettext("Share")}
            </Typography>
            {(loading || publicLoading) && (
              <CircularProgress size={20} color="inherit" />
            )}
          </Stack>

          <IoClose size={18} onClick={onClose} />
        </Box>

        <Stack
          sx={{
            p: 1.8,
            width: 400
          }}
          spacing={2}
        >
          <AccessControl setLoading={setLoading} />

          <DescriptionControl />
        </Stack>
      </Stack>
    </Modal>
  );
}
