import { GoBack } from "../../components";

export const UnAuthorized = () => {
  // TODO: Translate this Page
  return (
    <section>
      <h1>Unauthorized</h1>
      <br />
      <p>You do not have access to the requested page.</p>
      <div className="flexGrow">
        <GoBack />
      </div>
    </section>
  );
};
