import React, { useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useDeleteTeamMember } from "../hooks";

export default function DeleteModel({
  show,
  onHide,
  editableRow,
  customerName
}) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const { deleteMember, deleting } = useDeleteTeamMember(
    () => {
      onHide();
    },
    () => {}
  );

  const handleDelete = async () => {
    deleteMember({
      customerName,
      email: editableRow?.email
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {gettext("Are you sure you want to Delete")} "{" " + editableRow.name}"?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {gettext("Close")}
        </Button>
        {deleting ? (
          <Spinner animation="border" variant="dark" />
        ) : (
          <Button variant="primary" onClick={handleDelete}>
            {gettext("Delete")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
