import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { INVITE_CUSTOMER_USER } from "../commands";

export function useInviteCustomerUser(
  onCompleted = () => {},
  onError = () => {}
) {
  const [comInviteUserCustomer, { loading }] = useMutation(
    INVITE_CUSTOMER_USER,
    {
      onCompleted,
      onError
    }
  );

  const invite = useCallback(
    variables => {
      comInviteUserCustomer({
        variables
      });
    },
    [comInviteUserCustomer]
  );

  return {
    invite,
    loading
  };
}
