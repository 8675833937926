import {
  Autocomplete,
  InputLabel,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useTextdomainContext } from "app/providers";
import React, { useContext } from "react";

export default function CompanyInput(props) {
  const {
    matchedCompanies,
    loading,
    companyName,
    setCompanyName,
    compmanyError,
    setCompanyError
  } = props;

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  return (
    <Stack>
      <InputLabel htmlFor="company-input">
        <Typography fontWeight={500} color="#3F3F46">
          {gettext("Company Name")}
        </Typography>
      </InputLabel>
      <Autocomplete
        id="company-input"
        disablePortal
        disableClearable
        freeSolo
        fullWidth
        loading={loading && companyName.length > 0}
        options={matchedCompanies}
        getOptionLabel={option => option}
        inputValue={companyName}
        onInputChange={(e, newValue) => {
          setCompanyName(newValue);
          setCompanyError(false);
        }}
        renderInput={params => (
          <TextField
            {...params}
            required
            placeholder={gettext("Enter or select company name")}
            error={Boolean(compmanyError)}
            helperText={compmanyError}
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
      />
    </Stack>
  );
}
