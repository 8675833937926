import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import {
  Button,
  Chip,
  InputAdornment,
  Menu,
  Select,
  MenuItem,
  OutlinedInput,
  Pagination,
  Stack,
  Tooltip,
  tooltipClasses,
  Checkbox
} from "@mui/material";

import { IoChevronDown } from "react-icons/io5";
import { useEffect, useContext, useState } from "react";
import NoResults from "./NoResult";
import NorthRoundedIcon from "@mui/icons-material/NorthRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import FilterListIcon from "@mui/icons-material/FilterList";
import tableLoadingState from "app/modules/Reports/pages/Table/components/tableLoadingState";
import { useTextdomainContext } from "app/providers";
import { FilterModal } from "./FilterModal";

export const headCells = [
  {
    id: "Id",
    label: "ID",
    tooltip: "ID"
  },
  {
    id: "scrapper_name",
    label: "Scraper Name",
    tooltip: "Scraper Name"
  },
  {
    id: "url",
    label: "URL",
    tooltip: "URL"
  },
  {
    id: "description",
    label: "Description",
    tooltip: "Description"
  },
  {
    id: "status",
    label: "Status",
    tooltip: "Status"
  },
  {
    id: "created_at",
    label: "Created at",
    tooltip: "Created at"
  }
];

const RowCell = styled(TableCell)(({ theme }) => ({
  align: "left",
  fontSize: "14px",
  fontWeight: 400,
  color: "#71717A",
  border: "none"
}));
// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
//   evenRow: {
//     backgroundColor: '#ffffff',
//   },
//   oddRow: {
//     backgroundColor: '#f2f2f2',
//   },
// });

const CustomContainer = styled(Box)(({ theme }) => ({
  padding: 12,
  paddingTop: 10,
  border: "1px solid #E4E4E7",
  borderRadius: "12px",
  height: "100%",
  width: "100%",
  alignItems: "center"
}));

const CustomFooter = styled(Box)(({ theme }) => ({
  marginTop: 25,
  paddingTop: 18,
  marginLeft: 10,
  marginRight: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  borderTop: "1px solid #E4E4E7"
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  ".MuiPaginationItem-root": {
    minHeight: "30px",
    margin: 0,
    width: "41px",
    fontSize: "14px",
    borderRadius: "0px",
    border: "1px solid #E4E4E7",
    color: "#71717A"
  },
  ".css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon": {
    fontSize: "20px"
  },
  ".MuiPaginationItem-previousNext": {
    maxWidth: "30px !important"
    // borderTopRightRadius: "8px",
    // borderBottomRightRadius: "8px",
  }
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#4D896F",
    // boxShadow: theme.shadows[1],
    fontSize: "14px",
    fontWeight: 400,
    boxShadow:
      "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    textAlign: "center",
    padding: "8px"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function convertDateTime(dateTimeStr, format) {
  if (!dateTimeStr || typeof dateTimeStr !== "string") {
    // handle invalid dateTimeStr here
    return "";
  }
  const dateObj = new Date(dateTimeStr);
  dateObj.setDate(dateObj.getDate() + 1);
  const yearPart = dateObj.getFullYear();
  const monthPart = String(dateObj.getMonth() + 1).padStart(2, "0");
  const dayPart = String(dateObj.getDate()).padStart(2, "0");
  const nextDayStr = `${yearPart}-${monthPart}-${dayPart}`;

  const [datePart, timePart] = dateTimeStr.split("T");
  const [hourPart, minutePart] = timePart.substr(0, 5).split(":");
  const timeStr = `${hourPart}:${minutePart}`;

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  if (format === "time") {
    return timeStr;
  } else if (format === "dateTime") {
    return `${datePart} ${timeStr}`;
  } else if (format === "month") {
    return monthNames[parseInt(monthPart) - 1];
  } else if (format === "NextDay") {
    return nextDayStr;
  } else {
    return datePart;
  }
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, visibility } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const renderSortIcon = headCells => {
    return (
      <>
        {headCells.label !== "" ? (
          <>
            <NorthRoundedIcon
              sx={{
                color:
                  order === "asc" && headCells.id === orderBy
                    ? "primary.800"
                    : "secondary.200",
                fontSize: "12px",
                mr: -0.8,
                ml: 0.5
              }}
            />
            <SouthRoundedIcon
              sx={{
                color:
                  order === "desc" && headCells.id === orderBy
                    ? "primary.800"
                    : "secondary.200",
                fontSize: "12px",
                mt: 0.2
              }}
            />
          </>
        ) : null}
      </>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              display: visibility.includes(headCell.id) ? "table-cell" : "none",
              backgroundColor: "#fff",
              border: "none",
              borderBottom: "1px solid #E4E4E7",
              fontSize: "12px",
              fontWeight: 500
            }}
          >
            <LightTooltip
              title={headCell.tooltip}
              TransitionComponent={Zoom}
              placement="top-start"
              textAlign="center"
              arrow
              enterDelay={700}
              leaveDelay={100}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() => renderSortIcon(headCell)}
                sx={{ whiteSpace: "nowrap" }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </LightTooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const {
    // headCells,
    visibility,
    handleChange,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    setAddModalOpen,
    filterBySearch,
    gettext
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const rowOptions = [10, 25, 50, 100, 200, 500];

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRowsPerPage = item => {
    setRowsPerPage(item);
    setPage(0);
    setAnchorEl(null);
  };

  return (
    <>
      <Toolbar sx={{ px: "15px !important" }}>
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h5"
          fontWeight="700"
          id="tableTitle"
          component="div"
        >
          {gettext("Scraper List")}
        </Typography>
        <>
          <Select
            sx={{
              visuallyHidden,
              // display: "none",
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                border: "#B3CDC1" // add border when hovered
              },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 0
              },
              "&.MuiSelect-select": {
                display: "none" // hide the select component
              }
            }}
            labelId="column-select-label"
            id="column-select"
            multiple
            IconComponent={IoChevronDown}
            label=""
            value={visibility}
            displayEmpty={true}
            onChange={event => handleChange(event.target.value)}
            renderValue={selected => {
              // Check if any options are selected
              if (selected.length === 0) {
                // If no options are selected, display an empty string
                return (
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="secondary.800"
                  >
                    {gettext("Columns")}
                  </Typography>
                );
              } else {
                // If options are selected, display a placeholder string
                return (
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    color="secondary.800"
                  >
                    {gettext("Columns")}
                  </Typography>
                );
              }
            }}
          >
            {headCells.length &&
              headCells.map(headCell => (
                <MenuItem key={headCell.id} value={headCell.id}>
                  <div style={{ display: "none" }}>{headCell.id}</div>
                  <Checkbox
                    checked={visibility.includes(headCell.id)}
                    // checked={headCell.id=== visibility.Id}
                  />
                  {headCell.label}
                </MenuItem>
              ))}
          </Select>
        </>
        <>
          <Button
            endIcon={<IoChevronDown size={12} />}
            size="small"
            aria-controls={open ? "select-company-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              width: "300px",
              "&:hover": {
                backgroundColor: "#fff",
                border: "1px solid #E4E4E7"
              }
            }}
          >
            <Typography fontSize="14px" fontWeight="500" color="secondary.800">
              {gettext("Show up to")}
            </Typography>
            <Chip
              sx={{
                ml: 0.5,
                fontSize: "14px",
                backgroundColor: "secondary.100",
                color: "primary.main"
              }}
              size="small"
              label={rowsPerPage}
            />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                px: 1,
                borderRadius: "8px",
                width: "146px",
                border: "1px solid #E4E4E7",
                boxShadow:
                  "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)"
              }
            }}
          >
            {rowOptions.map((item, index) => (
              <MenuItem
                sx={{
                  borderRadius: "4px",
                  justifyContent: "center"
                }}
                onClick={() => handleChangeRowsPerPage(item)}
                value={rowOptions[index]}
                selected={rowsPerPage === rowOptions[index]}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "14px",
                    color: "secondary.800"
                  }}
                >
                  {item}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
        {/* ) */}
        {/* } */}

        <Stack direction={"row"} spacing={1} ml={1}>
          <OutlinedInput
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "14px",
              color: "secondary.800",
              borderRadius: "8px",
              height: "30px",
              width: "208px"
            }}
            type={"text"}
            size="small"
            placeholder="Search"
            onChange={filterBySearch}
            startAdornment={
              <InputAdornment>
                <SearchIcon color="secondary.300" fontSize="medium" />
              </InputAdornment>
            }
          />
        </Stack>
        <Stack direction={"row"} spacing={1} ml={1}>
          <Button
            variant="contained"
            startIcon={<FilterListIcon size={12} />}
            size="small"
            onClick={() => setAddModalOpen()}
            sx={{
              fontSize: "14px",
              width: "94px",
              height: "32px",
              backgroundColor: "#27272A",
              "&:hover": {
                backgroundColor: "#27272A",
                border: "1px solid #E4E4E7"
              }
            }}
          >
            {gettext("Filters")}
          </Button>
        </Stack>
      </Toolbar>
    </>
  );
}
const evenRowStyle = {
  borderRadius: "4px",
  backgroundColor: "#FAFAFA"
};
export default function ScrapperListTable(props) {
  const { rows, loading, setSeletedFilters } = props;
  // let headCells = useGetHeadCells(view, segment);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customerName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredRows, setFilteredRows] = useState(rows);
  const { TextdomainContext } = useTextdomainContext();
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const [visibility, setVisibility] = useState(() => {
    const initialVisibility = [];
    headCells.forEach(headCell => {
      initialVisibility.push(headCell.id);
    });
    return initialVisibility;
  });
  const handleChange = value => {
    setVisibility(typeof value === "string" ? value.split(",") : value);
  };
  const handleOpen = () => setAddModalOpen(true);
  const handleClose = () => setAddModalOpen(false);

  const { gettext } = useContext(TextdomainContext);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const rowsShowing = () => {
    return rowsPerPage > filteredRows.length - page * rowsPerPage
      ? filteredRows.length - page * rowsPerPage
      : rowsPerPage;
  };
  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  const filterBySearch = event => {
    const query = event.target.value;
    const updatedList = [...rows];
    const NewUpdatedList = updatedList.filter(item => {
      return Object.values(item).some(
        value =>
          typeof value === "string" &&
          value.toLowerCase().search(query.toLowerCase()) !== -1
      );
    });
    setFilteredRows(NewUpdatedList);
  };

  return (
    <CustomContainer>
      <EnhancedTableToolbar
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        visibility={visibility}
        handleChange={handleChange}
        filterBySearch={filterBySearch}
        gettext={gettext}
        setAddModalOpen={handleOpen}
      />
      <TableContainer sx={{ minHeight: "70vh" }}>
        <Table
          aria-labelledby="tableTitle"
          sx={{
            minWidth: 750,
            overflow: "auto"
          }}
        >
          <>
            {filteredRows.length ? (
              <>
                <EnhancedTableHead
                  headCells={headCells}
                  visibility={visibility}
                  // handleChange={handleChange}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={onRequestSort}
                />

                <TableBody>
                  {filteredRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .sort(getComparator(order, orderBy))
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={row.id}
                          sx={{
                            backgroundColor:
                              index % 2 !== 0
                                ? evenRowStyle.backgroundColor
                                : ""
                          }}
                        >
                          <RowCell
                            style={{
                              display: visibility.includes("Id")
                                ? "table-cell"
                                : "none"
                            }}
                          >
                            {index + 1}
                          </RowCell>
                          <RowCell
                            style={{
                              display: visibility.includes("scrapper_name")
                                ? "table-cell"
                                : "none"
                            }}
                          >
                            {row.scrapperName}
                          </RowCell>
                          <RowCell
                            style={{
                              display: visibility.includes("url")
                                ? "table-cell"
                                : "none",
                              maxWidth: "250px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            {row.url}
                          </RowCell>
                          <RowCell
                            style={{
                              display: visibility.includes("description")
                                ? "table-cell"
                                : "none",
                              maxWidth: "200px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            {row.description}
                          </RowCell>
                          <RowCell
                            style={{
                              display: visibility.includes("status")
                                ? "table-cell"
                                : "none"
                            }}
                          >
                            <Typography
                              fontSize={14}
                              sx={{
                                color: "#015731",
                                backgroundColor: "#E6EEEA",
                                textAlign: "center",
                                borderRadius: "30px",
                                width: "88px",
                                height: "24px"
                              }}
                            >
                              {row.status}
                            </Typography>
                          </RowCell>
                          <RowCell
                            style={{
                              display: visibility.includes("created_at")
                                ? "table-cell"
                                : "none"
                            }}
                          >
                            {convertDateTime(row.createdAt, "dateTime")}
                          </RowCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </>
            ) : loading === true ? (
              tableLoadingState()
            ) : (
              <NoResults gettext={gettext} />
            )}
          </>
        </Table>
      </TableContainer>
      <CustomFooter>
        <Typography fontSize={14} color="secondary.500">
          {gettext(
            `Showing ${rowsShowing()} of ${filteredRows.length} results`
          )}
        </Typography>

        <CustomPagination
          rowsPerPage={rowsPerPage}
          page={page + 1}
          count={Math.ceil(filteredRows.length / rowsPerPage)}
          size="medium"
          onChangeRowsPerPage={handleChangeRowsPerPage}
          variant="outlined"
          shape="rounded"
          onChange={(event, value) => handleChangePage(event, value)}
        />
      </CustomFooter>
      <FilterModal
        open={isAddModalOpen}
        handleClose={handleClose}
        setSeletedFilters={setSeletedFilters}
      />
    </CustomContainer>
  );
}
