import React, { useEffect, useContext, useState } from "react";
import {
  useCustomerPermission,
  useTextdomainContext,
  useUser
} from "app/providers";
import { DataGridComponent } from "app/components";
import { useNavigate, useLocation } from "react-router-dom";
import { FaBuilding, FaUserEdit } from "react-icons/fa";
import "react-confirm-alert/src/react-confirm-alert.css";
import { MdDelete } from "react-icons/md";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useUserCustomers } from "./hooks";
import { useMutation } from "@apollo/client";
import { DELETE_ADMIN_CUSTOMER } from "./commands";
import EditCustomerForm from "../EditCustomerForm";
import { IconButton, Tooltip } from "@mui/material";

export function CustomerListTable(props) {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const navigate = useNavigate();

  const { user } = useUser();

  const { canDelete } = useCustomerPermission();

  const location = useLocation();

  const { userId, setUserName } = props;
  const [apiLoading, setLoading] = useState(false);
  const [editableRow, seteditableRow] = useState({});
  const [editCustomer, setEditCustomer] = useState(false);
  const handleClose_edit = () => setEditCustomer(false);
  const [delete_show, setdelete] = useState(false);
  const handleClose_delete = () => setdelete(false);

  const handleShow_delete = () => setdelete(true);
  const [DeleteAdminCustomer] = useMutation(DELETE_ADMIN_CUSTOMER, {
    onCompleted() {
      setdelete(false);
      setLoading(false);
      window.location.reload();
    },
    onError() {},
    refetchQueries: [useUserCustomers(userId)]
  });

  const { customers, userName, loading } = useUserCustomers(userId);
  const handleDelete = () => {
    setLoading(true);
    DeleteAdminCustomer({
      variables: {
        customerName: editableRow.name,
        userId: props?.userId
      }
    });
  };

  useEffect(() => {
    if (setUserName) setUserName(userName);
  }, [setUserName, userName]);

  const handleEditCustomer = customer => {
    seteditableRow(customer);
    setEditCustomer(true);
  };

  const handleDeleteCustomer = customer => {
    seteditableRow(customer);
    handleShow_delete();
  };

  // const handleConfirmDialog = customer => {

  // };

  const handleCustomerBuildings = row => {
    navigate(
      `${location.pathname}/${encodeURIComponent(row.name)}/buildings` ||
        `${location.pathname}/customers${encodeURIComponent(
          row.name
        )}/buildings`
    );
  };

  function columnFormater(params) {
    return (
      <>
        {/* <OverlayTrigger
          overlay={
            <Tooltip title={gettext("Edit")}></Tooltip>
          }
        > */}
        {canDelete("administration") && (
          <Tooltip title={gettext("Edit")} arrow>
            <IconButton onClick={() => handleEditCustomer(params.row)}>
              <FaUserEdit size={18} />
            </IconButton>
          </Tooltip>
        )}
        {/* </OverlayTrigger> */}
        {!user.roles.includes("Super Admin") && canDelete("administration") && (
          <Tooltip title={gettext("Delete")} arrow>
            <IconButton onClick={() => handleDeleteCustomer(params.row)}>
              <MdDelete size={18} />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={gettext("Buildings")} arrow>
          <IconButton onClick={() => handleCustomerBuildings(params.row)}>
            <FaBuilding size={18} />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  const columns = [
    {
      field: "name",
      headerName: gettext("Name"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "label",
      headerName: gettext("Label"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "cvr",
      headerName: gettext("CVR"),
      flex: 1,
      headerAlign: "left"
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      headerAlign: "center",
      flex: 0.4,
      renderCell: params => columnFormater(params)
    }
  ];

  return (
    <>
      <div className="card card-custom example example-compact">
        <div className="table-div m-0">
          <div className="table-title">
            <h3 className="custom-header-list">
              <span>
                {`${userName ? userName + "'s " : ""}`}
                {gettext("Companies")}
              </span>
            </h3>

            <div className="card-toolbar"></div>

            <span className="font-size-sm w-100">{gettext("Companies")}</span>
          </div>

          {/* end::Header */}

          <div className="card-body customer-list">
            <DataGridComponent
              rows={customers}
              columns={columns}
              getRowId={row => row.name}
              loading={loading}
              disableSelectionOnClick={true}
              autoHeight
            />
          </div>
        </div>
        <Modal
          show={delete_show}
          onHide={handleClose_delete}
          animation
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {gettext("Are you sure you want to Delete")} "
            {" " + editableRow.name}"?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose_delete}>
              {gettext("Close")}
            </Button>
            {loading ? (
              <Spinner animation="border" variant="dark" />
            ) : (
              <Button variant="primary" onClick={handleDelete}>
                {gettext("Delete")}
              </Button>
            )}
          </Modal.Footer>
          {apiLoading ? (
            <div className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="light" />
            </div>
          ) : null}
        </Modal>
        {editCustomer && (
          <EditCustomerForm
            editableRow={editableRow}
            onHide={handleClose_edit}
          />
        )}
      </div>
    </>
  );
}
export default CustomerListTable;
