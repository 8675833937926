import { useQuery } from "@apollo/client";
import { GET_METERS_DATA_QUERY } from "./command";

export function useMetersData(
  variables,
  onCompleted = () => {},
  onError = () => {}
) {
  const { data, loading } = useQuery(GET_METERS_DATA_QUERY, {
    variables,
    onCompleted: ({ metersData }) => {
      onCompleted(metersData);
    },
    onError
  });

  return {
    isElectricityGreen: data?.metersData?.electricityGreen || false,
    data: data?.metersData?.data || [],
    loading
  };
}
