import { gql } from "@apollo/client";

export const GET_BENCHMARK_DATA = gql`
  query comBenchmarkData(
    $meterType: String!
    $meteringPointId: String
    $firstBuilding: String
    $secondBuilding: String
    $customer: String!
    $fromDate: DateTime!
    $toDate: DateTime!
    $resolution: String!
    $fileType: String
  ) {
    comBenchmarkData(
      meterType: $meterType
      meteringPointId: $meteringPointId
      firstBuilding: $firstBuilding
      secondBuilding: $secondBuilding
      customer: $customer
      fromDate: $fromDate
      toDate: $toDate
      resolution: $resolution
      fileType: $fileType
    ) {
      fileUrl
      data {
        address
        data {
          currentData {
            ts
            consumptionV
            consumptionCo2
          }
          previousData {
            ts
            consumptionV
            consumptionCo2
          }
        }
      }
    }
  }
`;
