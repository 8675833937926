import React from "react";
// import GEnergyTypes from "./GEnergyTypes";
import EnergyCollectionTypes from "./EnergyCollectionTypes";

const Gas = () => {
  return (
    <>
      {/* <GEnergyTypes type="gas" sourceType="Gas" /> */}
      <EnergyCollectionTypes type="gas" sourceType="Gas" />
    </>
  );
};
export default Gas;
