import { gql } from "@apollo/client";

export const GET_TAB_GROUPS = gql`
  query tabGroups {
    tabGroups {
      nameEn
      nameDa
      key
      level2 {
        nameEn
        nameDa
        key
        dropdowns {
          nameEn
          nameDa
          key
        }
        textboxes {
          nameEn
          nameDa
          key
        }
        level3 {
          nameEn
          nameDa
          key
          dropdowns {
            nameEn
            nameDa
            key
          }
          textboxes {
            nameEn
            nameDa
            key
          }
          level4 {
            nameEn
            nameDa
            key
            dropdowns {
              nameEn
              nameDa
              key
            }
            textboxes {
              nameEn
              nameDa
              key
            }
          }
        }
      }
    }
  }
`;
