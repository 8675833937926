import moment from "moment";
import React, { useCallback, useEffect, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { DataGridComponent } from "../../components";
import { useAccountsDataList, useDelete } from "./hooks";
import { AiFillEye } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useConfirmation } from "../../providers";

export function AccountsDataFileListTable({ refresh, selectedCustomersIds }) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);

  const { accountsDataList, loading, refetch } = useAccountsDataList(
    selectedCustomersIds
  );

  const { deleteFile } = useDelete();

  const { confirm, closeConfirmation } = useConfirmation({ loading: false });

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    refetch();
  }, [refresh, refetch]);

  const onConfirmHandler = useCallback(() => {
    closeConfirmation();
    refetch();
  }, [closeConfirmation, refetch]);

  const getMessage = (fileName, gettext) => {
    let message = gettext("Are you sure you want to Delete");
    return message + " " + fileName;
  };

  const actionColumnFormater = params => {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Tooltip title={gettext("View")} arrow>
          <IconButton
            onClick={() =>
              navigate(location.pathname + "/" + params?.row?.Id + "/view")
            }
          >
            <AiFillEye size={18} />
          </IconButton>
        </Tooltip>

        <Tooltip title={gettext("Delete")} arrow>
          <IconButton
            onClick={() =>
              confirm({
                confirmationText: getMessage(params?.row?.fileName, gettext),
                onConfirm: () => deleteFile(params?.row?.Id, onConfirmHandler)
              })
            }
          >
            <MdDelete size={18} />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      field: "fileName",
      headerName: gettext("Name"),
      flex: 3,
      headerAlign: "left"
    },

    {
      field: "customerName",
      headerName: gettext("Company"),
      type: "text",
      flex: 1,
      headerAlign: "left",
      valueFormatter: params => params.name
    },
    {
      field: "createdAt",
      headerName: gettext("Date"),
      type: "date",
      flex: 1,
      headerAlign: "left",
      valueFormatter: params => moment(params.createdAt).format("DD/MM/YYYY")
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      flex: 1,
      headerAlign: "center",
      renderCell: params => actionColumnFormater(params)
    }
  ];

  return (
    <div className="card card-custom example example-compact">
      <div className="table-div m-0">
        {/* begin::Header */}
        <div className="table-title">
          <h3 className="custom-header-list">
            <span className="font-size-sm w-100">
              {gettext("Recent File Uploads")}
            </span>
          </h3>
          <div className="card-toolbar"></div>
        </div>
        {/* end::Header */}

        <div className="card-body customer-list">
          <DataGridComponent
            rows={accountsDataList}
            columns={columns}
            loading={loading}
            getRowId={row => row.Id}
            disableSelectionOnClick={false}
          />
        </div>
      </div>
    </div>
  );
}
