import { FilterSelectButton } from "app/components";
import { useEpcFilter, useTextdomainContext } from "app/providers";
import React from "react";

export function SelectEnergyType() {
  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = React.useContext(TextdomainContext);

  const {
    allEnergySources,
    selectedEnergySource,
    setSelectedEnergySource
  } = useEpcFilter();

  return (
    <FilterSelectButton
      searchable={false}
      label={gettext("Type")}
      options={allEnergySources}
      getOptionLabel={option => option.label}
      value={selectedEnergySource}
      onChange={newValue => setSelectedEnergySource(newValue)}
    />
  );
}
