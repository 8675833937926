import React from "react";
import { Data, Has, Mode } from "./steps";

export function AddMeterSteps(props) {
  const { type, typeInfo } = props;
  return typeInfo?.step === 1 ? (
    <Mode type={type} />
  ) : typeInfo?.step >= 2 ? (
    <Data type={type} />
  ) : (
    <Has type={type} />
  );
}
