import { useStep, useTextdomainContext } from "app/providers";
import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useContext, useMemo, useRef } from "react";

export function RFstepper() {
  const { currentStep } = useStep();

  const { TextdomainContext } = useTextdomainContext();

  const { gettext } = useContext(TextdomainContext);

  const stepperRef = useRef(null);

  const steps = useMemo(
    () => [
      {
        key: "step1",
        label: gettext("Create Account"),
        description: gettext("Well done! you are registered as our Company")
      },
      {
        key: "step2",
        label: gettext("Company Information"),
        description: gettext("Add your company name and location")
      },
      {
        key: "step3",
        label: gettext("Building Information"),
        description: gettext("Provide your building address")
      },
      {
        key: "step4",
        label: gettext("Meter Information"),
        description: gettext("Provide meter details")
      }
    ],
    [gettext]
  );

  const [onGoingStep, setOnGoingStep] = React.useState(0);
  const [totalProgressHeight, setTotalProgressHeight] = React.useState(0);
  const [progressHeight, setProgressHeight] = React.useState(0);

  React.useEffect(() => {
    function handleResize() {
      const onGoingStep = currentStep + 1;
      const totalProgressHeight = stepperRef?.current?.offsetHeight - 46;
      const progressHeight = (totalProgressHeight / 3) * onGoingStep;
      setOnGoingStep(onGoingStep);
      setTotalProgressHeight(totalProgressHeight);
      setProgressHeight(progressHeight);
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [currentStep]);

  return (
    <Container
      maxWidth="xs"
      sx={{ height: "100%", display: "flex", alignItems: "center" }}
    >
      <Box
        ref={stepperRef}
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "50%",
          zIndex: 1900
        }}
      >
        {steps.map((step, index) => {
          return (
            <Stack
              key={step.key}
              direction={"row"}
              spacing={2}
              alignItems="center"
            >
              <Box
                sx={{
                  minWidth: "46px",
                  minHeight: "46px",
                  position: "relative",
                  backgroundColor: "#FAFAFA",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "&::before": {
                    content: '""',
                    display: index === 0 ? "block" : "none",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "#E4E4E7",
                    height: totalProgressHeight || 0,
                    width: "4px",
                    zIndex: -1
                  },
                  "&::after": {
                    content: '""',
                    display: index === 0 ? "block" : "none",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "#4D896F",
                    height: progressHeight || 0,
                    width: "4px",
                    transition: "1s",
                    zIndex: -1
                  }
                }}
              >
                {onGoingStep < index ? (
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="24"
                      cy="24"
                      r="22"
                      stroke="#E4E4E7"
                      strokeWidth="4"
                    />
                  </svg>
                ) : onGoingStep === index ? (
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="24"
                      cy="24"
                      r="22"
                      fill="none"
                      stroke="#4D896F"
                      strokeWidth="4"
                    />
                    <circle
                      cx="24"
                      cy="24"
                      r="6"
                      fill="#4D896F"
                      stroke="#4D896F"
                      strokeWidth="2"
                    />
                  </svg>
                ) : (
                  <svg
                    width="47"
                    height="48"
                    viewBox="0 0 47 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.5833 0.666016C10.7175 0.666016 0.25 11.1335 0.25 23.9993C0.25 36.8652 10.7175 47.3327 23.5833 47.3327C36.4492 47.3327 46.9167 36.8652 46.9167 23.9993C46.9167 11.1335 36.4492 0.666016 23.5833 0.666016ZM35.7268 16.1793L20.6498 34.128C20.4845 34.325 20.2787 34.4842 20.0464 34.5947C19.8142 34.7052 19.5609 34.7645 19.3037 34.7686H19.2734C19.0218 34.7685 18.7731 34.7155 18.5433 34.6131C18.3135 34.5107 18.1078 34.3611 17.9396 34.174L11.478 26.9945C11.3139 26.8205 11.1863 26.6154 11.1026 26.3913C11.0189 26.1672 10.9808 25.9287 10.9906 25.6896C11.0004 25.4506 11.0579 25.216 11.1597 24.9995C11.2615 24.783 11.4055 24.5891 11.5834 24.4291C11.7612 24.2691 11.9692 24.1462 12.1951 24.0677C12.4211 23.9892 12.6605 23.9567 12.8992 23.9721C13.1379 23.9874 13.3712 24.0504 13.5852 24.1571C13.7993 24.2639 13.9898 24.4124 14.1457 24.5939L19.2263 30.2388L32.9784 13.8707C33.2868 13.514 33.7233 13.2931 34.1933 13.2556C34.6634 13.2182 35.1293 13.3672 35.4903 13.6705C35.8514 13.9738 36.0786 14.407 36.1228 14.8764C36.1671 15.3459 36.0248 15.8139 35.7268 16.1793Z"
                      fill="#4D896F"
                    />
                  </svg>
                )}
              </Box>
              <Box>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  color={
                    onGoingStep < index
                      ? "#71717A"
                      : onGoingStep === index
                      ? "#4D896F"
                      : "#27272A"
                  }
                >
                  {step.label}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={onGoingStep < index ? "#A1A1AA" : "#52525B"}
                >
                  {step.description}
                </Typography>
              </Box>
            </Stack>
          );
        })}
      </Box>
    </Container>
  );
}
