import React, { useState, useCallback, useContext } from "react";
import { useTextdomainContext } from "app/providers";
import { Spinner } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import DatePicker from "react-datepicker";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
// import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { useAddMeterData } from "hooks";
import moment from "moment";

export function AddManualMeterDataForm({
  type,
  customer,
  meterNo,
  building_id
}) {
  const { TextdomainContext } = useTextdomainContext();
  const { gettext } = useContext(TextdomainContext);
  const [inputduration, setinputduration] = useState("");

  const [inputdateTime, setinputdateTime] = useState("");

  const [inputunit, setinputunit] = useState("MWh");

  const [inputisData, setinputisData] = useState("Estimated");

  const [inputvalue, setinputvalue] = useState("");

  const [inputprice, setinputprice] = useState("");

  const [updating, setupdating] = useState(false);

  const [showSuccessMessage, setshowSuccessMessage] = useState(false);

  const [showErrorMessage, setshowErrorMessage] = useState(false);

  const reset = useCallback(() => {
    setinputduration("");
    setinputdateTime("");
    setinputunit("MWh");
    setinputisData("Estimated");
    setinputvalue("");
    setinputprice("");
  }, []);

  const { addMeterData } = useAddMeterData(
    () => {
      reset();
      setupdating(false);
      setshowSuccessMessage(true);
      setTimeout(() => {
        setshowSuccessMessage(false);
      }, 5000);
    },
    () => {
      setupdating(false);
      setshowErrorMessage(true);
      setTimeout(() => {
        setshowErrorMessage(false);
      }, 5000);
    }
  );

  const submitHandler = async e => {
    e.preventDefault();

    setshowSuccessMessage(false);

    setshowErrorMessage(false);

    setupdating(true);

    const newDate = moment(inputdateTime).format("YYYY/MM/DD hh:mm:ss a");
    const newUtcDate = moment.utc(newDate).format();

    await addMeterData({
      buildingId: building_id,
      data: {
        meterNo,
        customer,
        cvr: customer,
        type,
        dataDuration: inputduration,
        unit: inputunit,
        dataType: inputisData,
        value: inputvalue,
        price: inputprice,
        // datetime: format(inputdateTime, "yyyy-MM-dd HH")
        datetime: newUtcDate
      }
    });
  };

  return (
    <div className="card card-custom example example-compact">
      <form onSubmit={submitHandler}>
        <div className="table-div">
          {/* begin::Header */}
          <div className="table-title">
            <h3 className="col-xs-6">
              {gettext("Add Meter Data")}
              {` ( ${meterNo} ) `}
            </h3>

            <button
              className="col-xs-6 btn btn-primary d-flex align-items-center"
              type="submit"
              disabled={updating}
            >
              {updating ? (
                <Spinner animation="border" variant="light" />
              ) : (
                <>
                  <MdAdd size={20} />
                  {gettext("Add")}
                </>
              )}
            </button>
          </div>

          {/* end::Header */}
          {
            <Collapse in={showSuccessMessage}>
              <Alert severity="success">
                <AlertTitle>{gettext("Success")}</AlertTitle>

                {gettext("Data Has Been Added Successfully")}
              </Alert>
            </Collapse>
          }

          {
            <Collapse in={showErrorMessage}>
              <Alert severity="error">
                <AlertTitle>{gettext("Error")}</AlertTitle>

                {gettext("There was a problem adding Data to the Meter")}
              </Alert>
            </Collapse>
          }

          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Data Duration")}</label>

                <select
                  className="form-control"
                  id="Duration"
                  name="Duration"
                  required
                  value={inputduration}
                  onChange={e => setinputduration(e.target.value)}
                >
                  <option value="">{gettext("Select...")}</option>
                  <option value="hourly">{gettext("Hourly")}</option>
                  <option value="daily">{gettext("Daily")}</option>
                  <option value="monthly">{gettext("Monthly")}</option>
                  <option value="yearly">{gettext("Yearly")}</option>
                </select>
              </div>

              <div className="form-group col-md-6">
                <label>{gettext("Date/Time")}</label>

                <DatePicker
                  className="form-control"
                  selected={inputdateTime}
                  onChange={date => setinputdateTime(date)}
                  placeholderText={gettext("Select Date/Time")}
                  required
                  timeIntervals={60}
                  showYearPicker={inputduration === "yearly"}
                  showTimeSelect={inputduration === "hourly"}
                  showMonthYearPicker={inputduration === "monthly"}
                  dateFormat={
                    inputduration === "yearly"
                      ? "yyyy"
                      : inputduration === "monthly"
                      ? "MMMM yyyy"
                      : inputduration === "hourly"
                      ? "MMMM d, yyyy h:mm aa"
                      : "MMMM d, yyyy"
                  }
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Unit")}</label>

                <select
                  className="form-control"
                  id="Unit"
                  name="Unit"
                  required
                  value={inputunit}
                  onChange={e => setinputunit(e.target.value)}
                >
                  <option value="kWh">kWh</option>
                  <option value="MWh">MWh</option>
                  <option value="m3">m3</option>
                </select>
              </div>

              <div className="form-group col-md-6">
                <label>{gettext("Is Data")}</label>

                <div className="form-control">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="Estimated"
                      checked={inputisData === "Estimated"}
                      onChange={e => setinputisData(e.target.value)}
                    />

                    <label className="form-check-label">
                      {gettext("Estimated")}
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="Measured"
                      checked={inputisData === "Measured"}
                      onChange={e => setinputisData(e.target.value)}
                    />

                    <label className="form-check-label">
                      {gettext("Measured")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label>{gettext("Value")}</label>

                <input
                  type="number"
                  className="form-control"
                  id="Value"
                  name="Value"
                  required
                  placeholder={gettext("Enter Value")}
                  value={inputvalue}
                  onChange={e => setinputvalue(e.target.value)}
                />
              </div>

              <div className="form-group col-md-6">
                <label>{gettext("Price")}</label>

                <input
                  type="number"
                  className="form-control"
                  id="Price"
                  name="Price"
                  required
                  placeholder={gettext("Enter Price")}
                  value={inputprice}
                  onChange={e => setinputprice(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
