import { gql } from "@apollo/client";

export const FETCH_ÁCCOUNT_PLANS_LIST = gql`
  query plainLinks {
    plainLinks {
      Id
      name
      fromDate
      toDate
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PLAN_LINK_STATUS = gql`
  mutation updatePlainLinkStatus($id: String!, $status: Boolean!) {
    updatePlainLinkStatus(id: $id, status: $status) {
      message
      success
    }
  }
`;
