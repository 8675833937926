import { gql } from "@apollo/client";

export const GET_PARTNERS_LIST = gql`
  query getPartners {
    comPartners {
      id
      customerKey
      lang
      status
      name
      email
      timezone
      countryCode
    }
  }
`;
