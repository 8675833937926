import React, { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import countries from "./countries";
import { useUser } from "app/providers";

function Prices(props) {
  const navigate = useNavigate();
  const { country, date } = useParams();
  const { user } = useUser();
  const [userCountry] = countries.filter(con => con.code === user.countryCode);

  useEffect(() => {
    if (date && country) {
      navigate(`electricity/prices/${country.toLowerCase()}/${date}`);
    } else if (country) {
      navigate(`electricity/prices/${country.toLowerCase()}/`);
    } else {
      navigate(
        `electricity/prices/${userCountry?.label?.toLowerCase() || "denmark"}/`
      );
    }
  }, [navigate, props, userCountry?.label, date, country]);

  return <Outlet />;
}

export default Prices;
