import React, { useState, useEffect, forwardRef } from "react";
import { Link } from "react-router-dom";
import { AppBar as AppBarTop } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import ListItemButton from "@mui/material/ListItemButton";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import ListItemIcon from "@mui/material/ListItemIcon";
import Chip from "@mui/material/Chip";
import ProfileMenu from "./profileMenu";
import { useUser } from "app/providers";
import { LanguageSelector } from "app/components";

export const AppBar = forwardRef((props, ref) => {
  const { user } = useUser();

  const { profilePhotoUrl } = user || {};

  const [photo, setPhoto] = useState("");

  useEffect(() => {
    profilePhotoUrl && setPhoto(profilePhotoUrl);
  }, [profilePhotoUrl]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBarTop ref={ref} position={"static"} color="inherit">
      <Toolbar>
        <Box sx={{ width: 220 }}>
          <Box sx={{ minWidth: "100px", width: "150px", paddingY: 2 }}>
            <Link to="">
              <img alt="logo" src={"/media/logos/fabeke_green.png"} />
            </Link>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <LanguageSelector />

        <Box>
          <Tooltip title="Account settings">
            <ListItemButton onClick={handleClick}>
              <Avatar alt="" src={photo} />

              <div className="d-flex flex-column mx-2 text-left">
                <Typography
                  variant="body1"
                  color="grey"
                  className="font-weight-bold"
                >
                  {user?.name}
                </Typography>

                <Typography variant="subtitle2" color="inherit">
                  {user?.email}
                </Typography>
              </div>
            </ListItemButton>
          </Tooltip>
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            style: {
              width: 400
            },
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0,
              "& .MuiAvatar-root": {
                width: 82,
                height: 82,
                ml: 0,
                mr: 0
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 100,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            sx={{
              height: 150,
              overflow: "auto",
              cursor: "auto",
              background: "#F5F7F9"
            }}
          >
            <div className="d-flex flex-row ">
              <Toolbar>
                <Box>
                  <Avatar alt="avatar" src={photo} />
                </Box>
              </Toolbar>

              <Box>
                <Typography
                  variant="h5"
                  color="grey"
                  className="font-weight-bold"
                >
                  {user?.name}
                </Typography>
                <div className="d-flex flex-wrap">
                  {user?.roles?.map((role, index) => (
                    <Chip
                      className="m-1"
                      variant="outlined"
                      size="small"
                      label={role}
                      key={`Chip-${role}`}
                    />
                  ))}
                </div>

                <Box>
                  <ListItemIcon>
                    <EmailIcon fontSize="small" />
                  </ListItemIcon>
                  {user?.email}
                </Box>

                <Box>
                  <ListItemIcon>
                    <LocationOnIcon fontSize="small" />
                  </ListItemIcon>
                  {user?.countryCode}
                </Box>
              </Box>
            </div>
          </MenuItem>

          <Divider />

          <ProfileMenu />
        </Menu>
      </Toolbar>
    </AppBarTop>
  );
});
