import { useMutation } from "@apollo/client";
import { ADD_NEW_ROLE } from "../commands";

export function useAddRole() {
  const [comAddRole, { loading }] = useMutation(ADD_NEW_ROLE, {
    refetchQueries: ["comRoles"]
  });

  const create = variables => comAddRole({ variables });

  return {
    create,
    creating: loading
  };
}
