import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import {
  ADD_ELECTRICITY_PAGE_DATA,
  DELETE_ELECTRICITY_PAGE_DATA,
  GET_ELECTRICITY_PAGE_DATA,
  UPDATE_BUILDING_PUBLIC_STATUS
} from "./commands";

export function useAddPage() {
  const [addElectricityPageData, { loading }] = useMutation(
    ADD_ELECTRICITY_PAGE_DATA
  );

  const addPage = useCallback(
    async variables => {
      try {
        await addElectricityPageData({
          variables,
          refetchQueries: ["comGetElectricityPageData"]
        });
      } catch (error) {}
    },
    [addElectricityPageData]
  );

  return {
    addPage,
    isAdding: loading
  };
}

export function useDeletePage() {
  const [deleteElectricityPage, { loading }] = useMutation(
    DELETE_ELECTRICITY_PAGE_DATA
  );

  const deletePage = useCallback(
    async variables => {
      try {
        await deleteElectricityPage({
          variables,
          refetchQueries: ["comGetElectricityPageData"]
        });
      } catch (error) {}
    },
    [deleteElectricityPage]
  );

  return {
    deletePage,
    isDeleting: loading
  };
}

export function usePublicPage(customerId) {
  const { data, loading } = useQuery(GET_ELECTRICITY_PAGE_DATA, {
    variables: {
      customerId: customerId
    }
  });
  const [isPagePublic, setPagePublic] = useState(false);
  const [pageUrl, setPageUrl] = useState("");
  useEffect(() => {
    if (data?.comGetElectricityPageData?.data) {
      setPagePublic(true);
      const urlString = `${
        window.location.href.split("/p")[0]
      }/prices/public/${customerId}`;
      setPageUrl(urlString);
    } else setPagePublic(false);
  }, [customerId, data]);
  return { isPagePublic, data, loadingPublicPage: loading, pageUrl };
}

export function useBuildingAccess() {
  const [updateBuildingAccess, { loading }] = useMutation(
    UPDATE_BUILDING_PUBLIC_STATUS
  );

  const updateAccess = useCallback(
    async variables => {
      try {
        return await updateBuildingAccess({
          variables,
          refetchQueries: ["getCountryAddresses"],
          awaitRefetchQueries: true
        });
      } catch (error) {
        throw error;
      }
    },
    [updateBuildingAccess]
  );

  return {
    updateAccess,
    updating: loading
  };
}
