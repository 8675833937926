export const error = {
  50: "#FFE6E6",
  100: "#FFCCCC",
  200: "#FFB3B3",
  300: "#FF9999",
  400: "#FF8080",
  500: "#FF6666",
  600: "#FF4D4D",
  700: "#FF3333",
  800: "#FF2626",
  900: "#FF0000"
};
